// import ut from "@vuepic/vue-datepicker";

function getUTCTimestamp() {
    //Return a utc timestamp
    const currentDate = new Date();
    const utcTimestamp = currentDate.getTime();
    return utcTimestamp;
}


function getFutureUTCTimestamp(start_ts = null, days = 0, months = 0, years = 0, past = false) {
    //Get future or past timestamp based on the parametrs
    let currentDate = ""
    if (start_ts) {
        currentDate = new Date(start_ts);
    } else {
        currentDate = new Date();
    }

    if (past) {
        currentDate.setUTCDate(currentDate.getUTCDate() - days);
        currentDate.setUTCMonth(currentDate.getUTCMonth() - months);
        currentDate.setUTCFullYear(currentDate.getUTCFullYear() - years);
    } else {
        currentDate.setUTCDate(currentDate.getUTCDate() + days);
        currentDate.setUTCMonth(currentDate.getUTCMonth() + months);
        currentDate.setUTCFullYear(currentDate.getUTCFullYear() + years);
    }

    const futureTimestamp = currentDate.getTime();
    return futureTimestamp;

}


function getLocalTimezone() {
    //return the local timezone as a string.
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZoneString;
}

function formatTimestamp(timestamp, format = 4, tz = false, tzname = 'short') {
    const date = new Date(timestamp);

    let options = {}
    let tz_options = {
        timeZoneName: tzname,
        timeZone: getLocalTimezone()
    };

    if (tz) {
        options = tz_options
    } else {
        options = {}
    }


    let lang = 'en-US'

    //format examples
// 01: 10/6/2023
// 02: 10-06-2023
// 03: October 6, 2023
// 04: 2023-10-06
// 05: Friday, October 6, 2023
// 06: Fri, Oct 6, 2023
// 07: Fri, October 6, 2023 at 11:31:05 AM
// 08: Oct 6, 2023
// 09: Oct 6, 2023, 11:31:05 AM
// 10: 20231006
// 11: 20231006::11:31:05
// 12: 20231006113105
// 13: 10/6/2023, 11:31:05
// 14: 10/6/2023, 11:31:05 AM

// 01: 10/6/2023, Eastern Daylight Time
// 02: 10-06-2023, Eastern Daylight Time
// 03: October 6, 2023 at Eastern Daylight Time
// 04: 2023-10-06, Eastern Daylight Time
// 05: Friday, October 6, 2023 at Eastern Daylight Time
// 06: Fri, Oct 6, 2023, Eastern Daylight Time
// 07: Fri, October 6, 2023 at 11:34:01 AM Eastern Daylight Time
// 08: Oct 6, 2023, Eastern Daylight Time
// 09: Oct 6, 2023, 11:34:01 AM Eastern Daylight Time
// 10: 20231006, Eastern Daylight Time
// 11: 20231006::11:34:01, Eastern Daylight Time
// 12: 20231006113401, Eastern Daylight Time
// 13: 10/6/2023, 11:34:01
// 14: 10/6/2023, 11:34:01 AM

    //get tz string
    let ts_str = ""
    if (tz) {
        ts_str = date.toLocaleDateString(lang, {
            ...options,
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
        }).split(',')[1].trim()
    }

    function type4(ts_str_for_type_4) {
        let just_date1 = convertTimestampToLocalDate(ts_str_for_type_4)
        let aaa2 = just_date1.split('/')
        let month1, day1, year1
        month1 = aaa2[0]
        day1 = aaa2[1]
        year1 = aaa2[2]

        if (tz) {
            return `${year1}-${month1}-${day1}` + `, ${ts_str}`
        } else {
            return `${year1}-${month1}-${day1}`
        }
    }

    function type_compact(ts_str_for_type_compact) {
        let just_date1 = convertTimestampToLocalDate(ts_str_for_type_compact)
        let aaa2 = just_date1.split('/')
        let month1, day1, year1
        month1 = aaa2[0]
        day1 = aaa2[1]
        year1 = aaa2[2]

        if (tz) {
            return `${year1}${month1}${day1}` + `, ${ts_str}`
        } else {
            return `${year1}${month1}${day1}`
        }
    }

    function type_compact_time(ts_str_for_type_compact, compact = false) {
        let t_obj = new Date(ts_str_for_type_compact)
        let just_time1 = get_military_time(t_obj, compact)
        let just_date1 = convertTimestampToLocalDate(ts_str_for_type_compact)
        let aaa2 = just_date1.split('/')
        let month1, day1, year1
        month1 = aaa2[0]
        day1 = aaa2[1]
        year1 = aaa2[2]
        let divider = "::"
        if (compact) {
            divider = ""
        }

        if (tz) {
            return `${year1}${month1}${day1}${divider}${just_time1}` + `, ${ts_str}`
        } else {
            return `${year1}${month1}${day1}${divider}${just_time1}`
        }
    }

    function get_military_time(the_date_obj, compact = false) {
        if (compact) {
            return get_military_time_compact(the_date_obj)
        } else {
            let orig = the_date_obj.toLocaleDateString(lang, {
                hour12: false,
                hour: '2-digit', // numeric, 2-digit
                minute: '2-digit', // numeric, 2-digit
                second: '2-digit', // numeric, 2-digit
            });
            const timeComponents = orig.split(' ');

            return timeComponents[1]
        }
    }

    function get_military_time_compact(the_date_obj) {
        let orig = the_date_obj.toLocaleDateString(lang, {
            hour12: false,
            hour: '2-digit', // numeric, 2-digit
            minute: '2-digit', // numeric, 2-digit
            second: '2-digit', // numeric, 2-digit
        });
        const dateComponents = orig.split(' ');
        const time = dateComponents[1]
        const timeComponents = time.split(':');
        let res = `${timeComponents[0]}${timeComponents[1]}${timeComponents[2]}`
        //  console.log(`Compact military time result was ${res}`)
        return res
    }


    switch (format) {
        case 1: {
            return date.toLocaleDateString(lang, {...options, month: 'numeric', day: 'numeric', year: 'numeric'});
        }
        case 2: {
            let just_date = convertTimestampToLocalDate(timestamp)
            let aaa = just_date.split('/')
            let month, day, year
            month = aaa[0]
            day = aaa[1]
            year = aaa[2]

            if (tz) {
                return `${month}-${day}-${year}` + `, ${ts_str}`
            } else {
                return `${month}-${day}-${year}`
            }
        }
        case 3: {
            return date.toLocaleDateString(lang, {...options, year: 'numeric', month: 'long', day: 'numeric'});
        }
        case 4: {
            return type4(timestamp)
        }
        case 5: {
            return date.toLocaleDateString(lang, {
                ...options,
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }
        case 6: {
            return date.toLocaleDateString(lang, {
                ...options,
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }
        case 7: {
            return date.toLocaleDateString(lang, {
                ...options,
                weekday: 'short', // long, short, narrow
                day: 'numeric', // numeric, 2-digit
                year: 'numeric', // numeric, 2-digit
                month: 'long', // numeric, 2-digit, long, short, narrow
                hour: 'numeric', // numeric, 2-digit
                minute: 'numeric', // numeric, 2-digit
                second: 'numeric', // numeric, 2-digit

            });
        }
        case 8: {
            return date.toLocaleDateString(lang, {
                ...options,
                day: 'numeric', // numeric, 2-digit
                year: 'numeric', // numeric, 2-digit
                month: 'short', // numeric, 2-digit, long, short, narrow
            });
        }
        case 9: {
            return date.toLocaleDateString(lang, {
                ...options,
                day: 'numeric', // numeric, 2-digit
                year: 'numeric', // numeric, 2-digit
                month: 'short', // numeric, 2-digit, long, short, narrow
                hour: 'numeric', // numeric, 2-digit
                minute: 'numeric', // numeric, 2-digit
                second: 'numeric', // numeric, 2-digit
            });
        }
        case 10: {
            return type_compact(timestamp)
        }
        case 11: {
            return type_compact_time(timestamp)
        }
        case 12: {
            return type_compact_time(timestamp, true)
        }
        case 13: {
            return date.toLocaleDateString(lang, {
                hour12: false,
                hour: '2-digit', // numeric, 2-digit
                minute: '2-digit', // numeric, 2-digit
                second: '2-digit', // numeric, 2-digit
            });
        }
        case 14: {
            return date.toLocaleDateString(lang, {
                hour12: true,
                hour: '2-digit', // numeric, 2-digit
                minute: '2-digit', // numeric, 2-digit
                second: '2-digit', // numeric, 2-digit

            });
        }
        default: {
            return type4(timestamp)
        }
    }
}

function getDayOfWeek(dateString) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
}

function getDayAndTime(dateString) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    const dayOfWeek = daysOfWeek[dayOfWeekIndex];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const time = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    return `${dayOfWeek}, ${time}`;
}

function parseDateStringToTimestamp(dateString) {
    //Accept a date string and return a timestamp. Timezone must be short form only if included.

    // console.log(`The datestring received was ${dateString}`)
    const parsedDate = new Date(dateString);

    // Check if the parsed date is valid
    if (isNaN(parsedDate)) {
        // console.log("parseDateStringToTimestamp ran into trouble.")
        return null; // Return null for invalid dates
    }

    const utcTimestamp = parsedDate.getTime();
    // console.log(`parseDateStringToTimestamp returning ${utcTimestamp}`)
    return utcTimestamp;
}


function unixTimestampToString(timestamp) {
    // Create a new Date object with the Unix timestamp
    var date = new Date(timestamp);

    // Extract the year, month, day, hours, minutes, and seconds from the Date object
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero if month is a single digit
    var day = ('0' + date.getDate()).slice(-2); // Add leading zero if day is a single digit
    var hours = ('0' + date.getHours()).slice(-2); // Add leading zero if hours is a single digit
    var minutes = ('0' + date.getMinutes()).slice(-2); // Add leading zero if minutes is a single digit
    var seconds = ('0' + date.getSeconds()).slice(-2); // Add leading zero if seconds is a single digit

    // Construct the string in the desired format
    // Output: "20231203:16:07:07"
    var dateString = year + month + day + ':' + hours + ':' + minutes + ':' + seconds;

    return dateString;
}

function convertUtcToLocal(utcTimeString) {
  // Parse the input string into a Date object where input looks like: 20231203:16:07:07 and is in UTC tz/
  // Return a string like 12/03/20 09:37:07 PM
  // This was made to convert dates from the Assistant that are shown in the dashboard.

  const utcDate = new Date(
    `${utcTimeString.slice(0, 4)}-${utcTimeString.slice(4, 6)}-${utcTimeString.slice(6, 8)}T${utcTimeString.slice(9)}Z`
  );

  // Get the local time in the format YYYYMMDD:HH:MM:SS
  const localTimeString = utcDate.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  // Extract and return the relevant part without the hours offset
    return localTimeString.slice(0, 8) + " " + localTimeString.slice(11);
}


function convertTimestampToLocalDate(timestamp) {
    //convert ts to local date string like '10/22/2023'
    const date = new Date(timestamp);
    let ltz = getLocalTimezone()
    let localDateWithTime = date.toLocaleString('en-US',
        {
            timeZone: ltz,
            day: '2-digit', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: '2-digit', // numeric, 2-digit, long, short, narrow

        })
//   console.log(`Here is the local date with time......`)
//   console.log(localDateWithTime)
    let localDateOnly = localDateWithTime.split(', ')[0]
    return localDateOnly
}

function get_default_expiration_date() {
    let m, d, y, the_past, show_tz, tz_type
    m = 0
    d = 0
    y = 1
    the_past = false
    show_tz = false
    tz_type = 'short'

    let future_ts = getFutureUTCTimestamp(null, d, m, y, the_past)
    // console.log(`The future timestamp I have come up with is ${future_ts}`)
    let res = formatTimestamp(future_ts, 1, show_tz, tz_type)
    // console.log(`The resulting string I have come up with is ${res}`)
    return res

}

function hours_and_minutes_until(timestamp) {
    // Get current timestamp in seconds
    var currentTimestamp = Math.floor(Date.now() / 1000);

    // Calculate time difference in seconds
    var timeDifference = timestamp - currentTimestamp;

    if(timeDifference < 0){
         return "00:00"
    }


    // Calculate hours and minutes
    var hours = Math.floor(timeDifference / 3600);
    var minutes = Math.floor((timeDifference % 3600) / 60);

    // Format hours and minutes
    var formattedHours = hours < 10 ? '0' + hours : hours;
    var formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Return formatted time
    return formattedHours + ':' + formattedMinutes;
}


function daysUntilTimestamp(timestamp) {
    // Get the current timestamp in milliseconds
    const currentTimestamp = Date.now();

    // Calculate the difference in milliseconds
    const differenceMilliseconds = timestamp - currentTimestamp;

    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
    const daysUntil = Math.ceil(differenceMilliseconds / (24 * 60 * 60 * 1000));

    return daysUntil;
}

let north_american_timezones = [
    'America/New_York',
    'America/Toronto',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
]

let all_timezones = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Nelson',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'CET',
    'CST6CDT',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Cuba',
    'EET',
    'EST',
    'EST5EDT',
    'Egypt',
    'Eire',
    'Etc/GMT',
    'Etc/GMT+0',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-0',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/GMT0',
    'Etc/Greenwich',
    'Etc/UCT',
    'Etc/UTC',
    'Etc/Universal',
    'Etc/Zulu',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'GB',
    'GB-Eire',
    'GMT',
    'GMT+0',
    'GMT-0',
    'GMT0',
    'Greenwich',
    'HST',
    'Hongkong',
    'Iceland',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Iran',
    'Israel',
    'Jamaica',
    'Japan',
    'Kwajalein',
    'Libya',
    'MET',
    'MST',
    'MST7MDT',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'NZ',
    'NZ-CHAT',
    'Navajo',
    'PRC',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'Poland',
    'Portugal',
    'ROC',
    'ROK',
    'Singapore',
    'Turkey',
    'UCT',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa',
    'UTC',
    'Universal',
    'W-SU',
    'WET',
    'Zulu',
]


export {
    getUTCTimestamp,
    getFutureUTCTimestamp,
    getLocalTimezone,
    formatTimestamp,
    parseDateStringToTimestamp,
    convertTimestampToLocalDate,
    daysUntilTimestamp,
    get_default_expiration_date, convertUtcToLocal,hours_and_minutes_until,unixTimestampToString,getDayOfWeek,
    getDayAndTime,
    north_american_timezones,
    all_timezones
}