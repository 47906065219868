/* eslint-disable */
import store from "@/store";

function v_print(msg, verbose=false, meta=""){
    //Takes msg string, boolean, and optional meta string. Prints console log.
    //If msg is object, just print the object.
    //You can use meta to give info about the function/code location where this function is being called from.

    let flag_str = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX:  "

    if(typeof msg === "object"){
        console.log(flag_str + "Printing object")
        console.log(msg)
    }else if(verbose && meta){
        console.log(flag_str + msg + ": " + meta)
    }else if(verbose && !meta){
        console.log(flag_str + msg)
    }
}

function _get_p_name(user_profile_dict, verbose=false) {
    //Accepts a dict of user profile text data and returns a string for a name.

    // v_print("Inside _get_p_name.", verbose)
    // v_print(user_profile_dict)
    if(!user_profile_dict['first_name'] || !user_profile_dict['last_name']){
        // v_print("Profile data does not contain either a first or last name. Returning an empty string.", verbose)
        return ""
    }

    let start_name = ""
    if (user_profile_dict['nick_name'] !== "") {
        start_name = user_profile_dict['nick_name']
    } else {
        start_name = user_profile_dict['first_name']

    }
    let p = start_name + " " + user_profile_dict['last_name']
    // v_print(`Build a p_name: ${p}`, verbose)
    return p
}



async function get_preferred_name(db, tmh_user_name, verbose=false){

    // console.log("^^^^^^^^^^^^^^^^^^^^Calling get_preferred_name in library2")
    //Takes tmh_user name and returns a preferred name string if possible or returns the tmh_user name otherwise.
    let save_key = "cached_p_name:" + tmh_user_name
    let cached_p_name = await store.dispatch("get_cache_value", save_key)
    if(cached_p_name){
        // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^6Found a cached p_name: ${cached_p_name}`)
        return cached_p_name
    }

    // v_print("Inside get preferred Name. Calling db", verbose)
    let profile_data = await _get_user_profile_data(db, tmh_user_name,verbose)
    // v_print("Called db for profile data and got...", verbose)
    // v_print(profile_data, verbose)

    if(!profile_data){
        return tmh_user_name
    }

    let p_name = _get_p_name(profile_data, verbose)
    if(!p_name){
        return tmh_user_name
    }else{
        let c = {
            'key': save_key,
            'value': p_name,
        }
        // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Caching a p_name: ${p_name}`)
        store.commit("set_cache_value", c)
        return p_name
    }
}

async function _get_user_profile_data(db, tmh_user_name, verbose=false){

    //Takes db instance, tmh_user_name string, returns dict of strings with keys of first_name, last_name, middle_name,
    // nick_name, city, state, zip, street_address, street_address2, phone_number, email

    // v_print(`Looking in local db for profile data for ${tmh_user_name}.`, verbose, "get_user_profile_data, library2")
    let base_context_to_use = store.getters.find_current_preamble

    if(tmh_user_name === store.getters.my_name){
        // v_print(`${store.getters.my_name} is looking up ${store.getters.my_name}. Alter base context.`, verbose)
        base_context_to_use = [['people', tmh_user_name]]
    }else{
        // v_print(`store.getters.my_name is ${store.getters.my_name} and tmh_user_name is ${tmh_user_name}`,verbose)
    }

     let search = {
        key: 'private_profile_data',
        additional_context: [['div', 'profile'], ['div', 'text_data']],
        base_context: base_context_to_use
    }

    let db_target_item = await db.db_search(search)

    // v_print(`DB search for ${tmh_user_name} returned...`,verbose)
    // v_print(db_target_item, verbose)
    return db_target_item[0]
}


export {
    _get_user_profile_data, get_preferred_name
}