<template>

  <div class="whole_question flex_wrap ">
    <div class="text_no_answers">
      <p class="the_q_num"><strong>{{ question_num }}</strong></p>
      <div class="spacer"></div>
      <p class="question">{{ question_text.question }}</p>
      <div class="spacer_small"></div>
      <p v-if="a_value" class="question"><strong>A.</strong> {{ question_text.answers['a'] }}</p>
      <div class="spacer_small"></div>
      <p v-if="b_value" class="question"><strong>B.</strong> {{ question_text.answers['b'] }}</p>
      <div class="spacer_small"></div>
      <p v-if="c_value" class="question"><strong>C.</strong> {{ question_text.answers['c'] }}</p>
      <div class="spacer_small"></div>
      <p v-if="d_value" class="question"><strong>D.</strong> {{ question_text.answers['d'] }}</p>
      <div class="spacer_small"></div>
      <p v-if="e_value" class="question"><strong>E.</strong> {{ question_text.answers['e'] }}</p></div>

    <div class="flex_centered_row">
      <div class=" yes_no_boxes">

      <div v-if="a_value" class="flex_centered_column ans_col cursor_ptr" :id=this.a_id
           @click=a_clicked>
        <p><strong>A</strong></p>
      </div>

      <div class="spacer"></div>
      <div v-if="b_value" class="flex_centered_column ans_col cursor_ptr" :id=this.b_id
           @click=b_clicked>
        <p><strong>B</strong></p>
      </div>

      <div class="spacer"></div>
      <div v-if="c_value" class="flex_centered_column ans_col cursor_ptr" :id=this.c_id
           @click=c_clicked>
        <p><strong>C</strong></p>
      </div>

      <div class="spacer"></div>
      <div v-if="d_value" class="flex_centered_column ans_col cursor_ptr" :id=this.d_id
           @click=d_clicked>
        <p><strong>D</strong></p>
      </div>

      <div class="spacer"></div>
      <div v-if="e_value" class="flex_centered_column ans_col cursor_ptr" :id=this.e_id
           @click=e_clicked>
        <p><strong>E</strong></p>
      </div>

    </div>
    </div>
<!--    <div class=" yes_no_boxes">-->

<!--      <div v-if="a_value" class="flex_centered_column ans_col cursor_ptr" :id=this.a_id-->
<!--           @click=a_clicked>-->
<!--        <p><strong>A</strong></p>-->
<!--      </div>-->

<!--      <div class="spacer"></div>-->
<!--      <div v-if="b_value" class="flex_centered_column ans_col cursor_ptr" :id=this.b_id-->
<!--           @click=b_clicked>-->
<!--        <p><strong>B</strong></p>-->
<!--      </div>-->

<!--      <div class="spacer"></div>-->
<!--      <div v-if="c_value" class="flex_centered_column ans_col cursor_ptr" :id=this.c_id-->
<!--           @click=c_clicked>-->
<!--        <p><strong>C</strong></p>-->
<!--      </div>-->

<!--      <div class="spacer"></div>-->
<!--      <div v-if="d_value" class="flex_centered_column ans_col cursor_ptr" :id=this.d_id-->
<!--           @click=d_clicked>-->
<!--        <p><strong>D</strong></p>-->
<!--      </div>-->

<!--      <div class="spacer"></div>-->
<!--      <div v-if="e_value" class="flex_centered_column ans_col cursor_ptr" :id=this.e_id-->
<!--           @click=e_clicked>-->
<!--        <p><strong>E</strong></p>-->
<!--      </div>-->

<!--    </div>-->
  </div>

</template>

<script>
import {colors, get_uuid} from "@/library";
// import top_label_value_non_editable from "@/components/parts/top_label_value_non_editable";

export default {
  name: "multiple_choice_and_question",
  props: [

    'number_of_choices',
    'question_text',
    'question_num'

  ],
  emits: [
    'true_value_update',
  ],
  async mounted() {
    this.a_id = get_uuid()
    this.b_id = get_uuid()
    this.c_id = get_uuid()
    this.d_id = get_uuid()
    this.e_id = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {
        let a_box, b_box, c_box, d_box, e_box
        a_box = document.getElementById(this.a_id)
        b_box = document.getElementById(this.b_id)
        c_box = document.getElementById(this.c_id)
        d_box = document.getElementById(this.d_id)
        if (this.e_value) {
          e_box = document.getElementById(this.e_id)
        }

        a_box.style.background = this.default_color
        b_box.style.background = this.default_color
        c_box.style.background = this.default_color
        d_box.style.background = this.default_color
        if (this.e_value) {
          e_box.style.background = this.default_color
        }

        a_box.children[0].style.color = colors.morgie_gray
        b_box.children[0].style.color = colors.morgie_gray
        c_box.children[0].style.color = colors.morgie_gray
        d_box.children[0].style.color = colors.morgie_gray
        if (this.e_value) {
          e_box.children[0].style.color = colors.morgie_gray
        }

      })
    } catch (error) {
      console.log(error)
    }
  },

  data() {
    return {
      a_id: "",
      b_id: "",
      c_id: "",
      d_id: "",
      e_id: "",
      default_color: colors.lauren_gray,
      yes_color: colors.primary_purple,
      selected_text_color: colors.white,
      answer_value: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
      },
    }
  },
  methods: {
    recolor(mc_values) {
      let a_box, b_box, c_box, d_box, e_box
      if (this.a_value) {
        a_box = document.getElementById(this.a_id)
      }
      if (this.b_value) {
        b_box = document.getElementById(this.b_id)
      }
      if (this.c_value) {
        c_box = document.getElementById(this.c_id)
      }
      if (this.d_value) {
        d_box = document.getElementById(this.d_id)
      }
      if (this.e_value) {
        e_box = document.getElementById(this.e_id)
      }

      // let b_box = document.getElementById(this.b_id)
      // let c_box = document.getElementById(this.c_id)
      // let d_box = document.getElementById(this.d_id)
      // let e_box = document.getElementById(this.e_id)

      if (mc_values['a'] === true) {
        a_box.style.background = this.yes_color
        b_box.style.background = this.default_color
        c_box.style.background = this.default_color
        d_box.style.background = this.default_color
        if (this.e_value) {
          e_box.style.background = this.default_color
        }

        a_box.children[0].style.color = this.selected_text_color
        b_box.children[0].style.color = colors.morgie_gray
        c_box.children[0].style.color = colors.morgie_gray
        d_box.children[0].style.color = colors.morgie_gray
        if (this.e_value) {
          e_box.children[0].style.color = colors.morgie_gray
        }

      } else if (mc_values['b'] === true) {
        a_box.style.background = this.default_color
        b_box.style.background = this.yes_color
        c_box.style.background = this.default_color
        d_box.style.background = this.default_color
        if (this.e_value) {
          e_box.style.background = this.default_color
        }

        a_box.children[0].style.color = colors.morgie_gray
        b_box.children[0].style.color = this.selected_text_color
        c_box.children[0].style.color = colors.morgie_gray
        d_box.children[0].style.color = colors.morgie_gray
        if (this.e_value) {
          e_box.children[0].style.color = colors.morgie_gray
        }

      } else if (mc_values['c'] === true) {
        a_box.style.background = this.default_color
        b_box.style.background = this.default_color
        c_box.style.background = this.yes_color
        d_box.style.background = this.default_color
        if (this.e_value) {
          e_box.style.background = this.default_color
        }

        a_box.children[0].style.color = colors.morgie_gray
        b_box.children[0].style.color = colors.morgie_gray
        c_box.children[0].style.color = this.selected_text_color
        d_box.children[0].style.color = colors.morgie_gray
        if (this.e_value) {
          e_box.children[0].style.color = colors.morgie_gray
        }

      } else if (mc_values['d'] === true) {
        a_box.style.background = this.default_color
        b_box.style.background = this.default_color
        c_box.style.background = this.default_color
        d_box.style.background = this.yes_color
        if (this.e_value) {
          e_box.style.background = this.default_color
        }

        a_box.children[0].style.color = colors.morgie_gray
        b_box.children[0].style.color = colors.morgie_gray
        c_box.children[0].style.color = colors.morgie_gray
        d_box.children[0].style.color = this.selected_text_color
        if (this.e_value) {
          e_box.children[0].style.color = colors.morgie_gray
        }

      } else if (mc_values['e'] === true) {
        a_box.style.background = this.default_color
        b_box.style.background = this.default_color
        c_box.style.background = this.default_color
        d_box.style.background = this.default_color
        if (this.e_value) {
          e_box.style.background = this.yes_color
        }

        a_box.children[0].style.color = colors.morgie_gray
        b_box.children[0].style.color = colors.morgie_gray
        c_box.children[0].style.color = colors.morgie_gray
        d_box.children[0].style.color = colors.morgie_gray
        if (this.e_value) {
          e_box.children[0].style.color = this.selected_text_color
        }
      }
    },
    a_clicked() {
      console.log("a was clicked")
      this.answer_value['a'] = true
      this.answer_value['b'] = false
      this.answer_value['c'] = false
      this.answer_value['d'] = false
      this.answer_value['e'] = false
      this.recolor(this.answer_value)
      this.$emit('true_value_update', ['a', this.question_num])
    },
    b_clicked() {
      console.log("b was clicked")
      this.answer_value['a'] = false
      this.answer_value['b'] = true
      this.answer_value['c'] = false
      this.answer_value['d'] = false
      this.answer_value['e'] = false
      this.recolor(this.answer_value)
      this.$emit('true_value_update', ['b', this.question_num])
    },
    c_clicked() {
      console.log("c was clicked")
      this.answer_value['a'] = false
      this.answer_value['b'] = false
      this.answer_value['c'] = true
      this.answer_value['d'] = false
      this.answer_value['e'] = false
      this.recolor(this.answer_value)
      this.$emit('true_value_update', ['c', this.question_num])
    },
    d_clicked() {
      console.log("d was clicked")
      this.answer_value['a'] = false
      this.answer_value['b'] = false
      this.answer_value['c'] = false
      this.answer_value['d'] = true
      this.answer_value['e'] = false
      this.recolor(this.answer_value)
      this.$emit('true_value_update', ['d', this.question_num])
    },
    e_clicked() {
      console.log("e was clicked")
      this.answer_value['a'] = false
      this.answer_value['b'] = false
      this.answer_value['c'] = false
      this.answer_value['d'] = false
      this.answer_value['e'] = true
      this.recolor(this.answer_value)
      this.$emit('true_value_update', ['e', this.question_num])
    },

    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

  },
  computed: {
    choices() {
      return this.number_of_choices
    },
    a_value() {
      console.log("in a value with answers................................................")
      // console.log(this.question_text.answers)
      // console.log(this.question_text.answers['a'])
      // console.log(this.question_text.answers['b'])
      // console.log(this.question_text.answers['c'])
      // console.log(this.question_text.answers['d'])
      // console.log(this.question_text.answers['a'])
      if (this.question_text.answers['a']) {
        return true
      } else {
        return false
      }
      // return this.number_of_choices > 0
    },
    b_value() {
      if (this.question_text.answers['b']) {
        return true
      } else {
        return false
      }
    },
    c_value() {
      if (this.question_text.answers['c']) {
        return true
      } else {
        return false
      }
    },
    d_value() {
      if (this.question_text.answers['d']) {
        return true
      } else {
        return false
      }
    },
    e_value() {
      if (this.question_text.answers['e']) {
        return true
      } else {
        return false
      }
    },

  },
}
</script>

<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .whole_question {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    align-content: flex-start;
    /*width: 1300px;*/
  }

  .question {
    font-size: 22px;
  }

  .ans_col {
    width: 60px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    /*background-color: yellow;*/
  }

  .spacer {
    height: 20px;
  }

  .spacer_small {
    height: 15px;
    /*width: 100px;*/
    /*background-color: red;*/
  }

  .yes_no_boxes {
    margin-top: 30px;
    width: 100vw;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
  .yes_no_boxes {
    margin-top: 30px;
    width: 450px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 700px) {
  .yes_no_boxes {
    margin-top: 30px;
    width: 500px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .ans_col {
    width: 80px;
    height: 50px;
    margin-left: 8px;
    margin-right: 8px;
    /*background-color: yellow;*/
  }
}

@media (min-width: 1200px) {
   .the_q_num{
    font-size: 30px;
  }

  .question{
     font-size: 26px;
  }
}

@media (min-width: 1600px) {

  /*.whole_question {*/
  /*  display: flex;*/
  /*  justify-content: flex-start;*/
  /*  width: 1300px;*/
  /*}*/

  .question {
    width: 810px;
    white-space: pre;
  }

  .yes_no_boxes {
    align-items: center;
    margin-left: 80px;
  }

  .mid_spacer {
    width: 200px;
    background-color: red;
  }

  .spacer {
    width: 30px;
    /*background-color: green;*/
  }

  .the_q_num {
    width: 100px;
  }

  .ans_col {
    width: 100px;
    height: 60px;
    /*background-color: yellow;*/
  }
}


@media (min-width: 2000px) {
  .whole_question {
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    /*align-items: center;*/
    align-content: flex-start;
    /*width: 1300px;*/
  }

  /*.ans_col {*/
  /*  width: 80px;*/
  /*  height: 50px;*/
  /*  margin-left: 5px;*/
  /*  margin-right: 5px;*/
  /*  !*background-color: yellow;*!*/
  /*}*/

}


</style>