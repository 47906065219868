<template>
  <module_main_page
      module_download_name="06000f31-5580-43a7-90dd-831ccd37da56"
      module_logo_name="sd_first_aid.svg"
      :module_desc_text=module_text
      module_headline="Basic First Aid"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "first_aid",
  data() {
    return {
      module_text: "Learning basic first aid is crucial in the homecare context as it enables caregivers to respond effectively in the event of an emergency. Caregivers often work with clients who have complex medical needs and may be at a higher risk for accidents or medical emergencies. Knowing how to administer first aid can be the difference between life and death, especially if emergency medical services are delayed. Knowing first aid can also help caregivers to identify potential hazards and take steps to prevent accidents or injuries from occurring in the first place.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>

