<template>

  <section class="core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <div class="flex_centered_start_column">

<!--        <audio v-if="no_sms_opt_in_warning" autoplay>-->
<!--          <source src="@/assets/sounds/notification.mp3" type="audio/mpeg">-->
<!--          Your browser does not support the audio element.-->
<!--        </audio>-->

      <h4 class="flex_centered_row create_your_account">Create Your Account</h4>
      <h5 class="cursor_ptr"
          @click="video_modal_true"><strong>Help Video >></strong>
      </h5>
      <video_modal
          :show_modal=modal_up
          video_id="781256511"
          base_height="930"
          base_width="450"
          @bg_clicked=video_modal_false
      ></video_modal>

      <div class="flex_centered_row">
        <div class="flex_fully_centered_column margin_t_40 main_column">

          <input_with_label
              ref="register_name_box"
              :focus="true"
              class="login_input"
              label="Create User Name"
              @emit_state="catch_username"
          ></input_with_label>

          <input_with_label
              ref="register_phone_number_box"
              class="login_input"
              label="Phone Number"
              @emit_state="catch_phone_number"
          ></input_with_label>

          <input_with_label
              ref="register_email_box"
              class="login_input"
              label="Email"
              @emit_state="catch_email"
          ></input_with_label>


          <input_with_label
              ref="register_password_box"
              class="login_input"
              label="Password"
              @emit_state="catch_password"
          ></input_with_label>


          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_tos_opt_in">
              <div v-if="!tos_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="tos_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer_opt_in"></div>
            <p v-if="tos_opt_in" class="opt_in_text_yes">I agree to Tiny Magic Hat's updated
              <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple font_weight_800">Terms of Service</span>.
            </p>
            <p v-if="!tos_opt_in" class="opt_in_text_no">I agree to Tiny Magic Hat's updated
              <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple ">Terms of Service</span>.
            </p>

          </div>

          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_sms_opt_in">
              <div v-if="!sms_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="sms_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer_opt_in"></div>
            <p v-if="sms_opt_in" class="opt_in_text_yes">{{ sms_opt_in_message }}
            </p>
            <p v-if="!sms_opt_in" class="opt_in_text_no">{{ sms_opt_in_message }}
            </p>

          </div>

          <basic_text_button
              class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state="all_opt_ins"
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="SIGN UP"
              text_size="14px"
              :bold="true"
              event_name="register_button_clicked"
              @register_button_clicked="register"
              v-on:keyup.enter="register"
          ></basic_text_button>

          <p v-if="no_sms_opt_in_warning" class="no_sms_warning">If you don't want to get a text message,
            you can use an
            <span @click='simple_route_prep("mfa_register")' class="cursor_ptr text_primary_purple font_weight_800">Secure Account alternative</span>
            sign up method.
            It's more secure, but more complicated with more requirements. Otherwise, you'll need to accept the
            verification text message.</p>


          <div class="flex_space_between_row  " id="links_row">
            <!--            <div class="flex_centered_row links cursor_ptr" @click='simple_route_prep("login")'>Login</div>-->
            <div class="flex_centered_row links cursor_ptr" @click='simple_route_prep("register_confirm")'>Confirm
              Registration
            </div>
            <div class="flex_centered_row links cursor_ptr" @click='simple_route_prep("mfa_register")'>Secure Account
              Creation
            </div>
          </div>


        </div>
      </div>
    </div>
    <!--  </div>-->

  </section>
  <outside_footer id="footer" class="footer_c">
  </outside_footer>


</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';

import {
  parse_internal_json,
  be_main_address,
  validate_phonenumber,
  scroll_to_top, validate_email, colors, play_sound, validate_basic_name,
} from "@/library";

import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
// import sms_opt_in from "@/components/basics/sms_opt_in";


export default {
  components: {
    notification_popup,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    basic_text_button,
    input_with_label,
    video_modal,
  },

  data() {
    return {
      colors: colors,
      name: '',
      password: '',
      email: '',
      phone_number: '',
      next_route: '',
      sms_opt_in_message: 'I agree to get a text message to verify my account during the sign up process. I understand I can opt out of text messages at any time.',
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 700,
      tos_opt_in_: false,
      sms_opt_in_: false,
      no_sms_opt_in_warning_: false,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    this.scroll_to_top()

    // try {
    //   await this.$nextTick(function () {
    //    this.$refs.register_name_box.focus()
    //   })
    // } catch (error) {
    //   console.error(error)
    // }

    // get_element_by_id
    this.run_window_width_adjustments(this.windowWidth)

  },
  computed: {

    all_opt_ins() {
      return this.tos_opt_in &&
          this.phone_number_present &&
          this.email_present &&
          this.password_present &&
          this.name_present
    },

    //  all_opt_ins_but_sms() {
    //   return this.tos_opt_in &&
    //       this.phone_number_present &&
    //       this.email_present &&
    //       this.password_present &&
    //       this.name_present
    //
    // },

    no_sms_opt_in_warning() {
      let res = this.no_sms_opt_in_warning_ && !this.sms_opt_in && this.all_opt_ins
      if(res){
        console.log("Current directory for registration play sound:", __dirname);
        play_sound('/notification.mp3')
      }
      return res
    },
    email_present() {
      if (this.email.length > 0) {
        return true
      } else {
        return false
      }
    },
    name_present() {
      if (this.name.length > 0) {
        return true
      } else {
        return false
      }
    },
    password_present() {
      if (this.password.length > 0) {
        return true
      } else {
        return false
      }
    },
    phone_number_present() {
      if (this.phone_number.length > 0) {
        return true
      } else {
        return false
      }
    },

    tos_opt_in() {
      return this.tos_opt_in_
    },
    sms_opt_in() {
      return this.sms_opt_in_
    },

    windowWidth() {
      return store.state.windowWidth;
    },
    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  methods: {
    tester() {
      // console.log("button was pressed.")
    },
    //  close_spinner_wrapper(){
    //   close_spinner_slow_connection()
    // },

    // play_notification(){
    //   let n =
    // },

    toggle_no_sms_opt_in_warning() {
      this.no_sms_opt_in_warning_ = !this.no_sms_opt_in_warning_
    },

    toggle_tos_opt_in() {
      this.tos_opt_in_ = !this.tos_opt_in_
    },

    toggle_sms_opt_in() {
      console.log("SMS toggle clicked............................")
      this.sms_opt_in_ = !this.sms_opt_in_
    },


    video_modal_false() {
      this.video_modal.state = false
    },

    video_modal_true() {
      console.log("changing video modal to true")
      this.video_modal.state = true
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.v_scale = 1.2
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        this.v_scale = 1.5
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        this.v_scale = 1.75
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        this.v_scale = 2.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(901, 9000, new_w)) {
        console.log("in last section")
        this.v_scale = 3.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        console.log(`Height: ${this.video.height}`)
        console.log(`Width: ${this.video.width}`)
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },

    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },

    catch_password(pw) {
      this.password = pw.trim()
      store.commit('save_password', this.password)
    },

    catch_phone_number(phone_number) {
      let phone = phone_number.trim()
      //  remove all non-digits
      phone = phone.replace(/\D/g, '');
      // validate
      if (validate_phonenumber(phone)) {
        this.phone_number = phone
      } else {
        let m = phone_number + " is not a valid phone number."
        store.commit('error_enqueue', m)
        return null
      }
    },

    catch_email(content) {

      if (validate_email(content)) {
        this.email = content.trim().slice(0, 100)
      } else {
        let m = content + "is not a valid email address."
        store.commit('error_enqueue', m)
        return null
      }
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
      if(!validate_basic_name(name)){
        store.commit('error_enqueue', 'User names may only contain numbers, letters and underscores.')
        return null
      }
      store.commit('save_name', this.name)
    },
    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async register() {
      // console.log("Getting ready to launch register...")
      // let the_router = this.$router

       if (!this.sms_opt_in && this.no_sms_opt_in_warning) {
        return null
      }

      if (!this.sms_opt_in) {
        this.toggle_no_sms_opt_in_warning()
        return null
      }


      this.$refs.register_name_box.emit_state()
      this.$refs.register_password_box.emit_state()
      this.$refs.register_phone_number_box.emit_state()
      this.$refs.register_email_box.emit_state()

      // this.$refs.register_email.emit_state()

      let error_out
      if (!this.tos_opt_in) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must accept the Terms of Service before you can register.")
        error_out = true
      }
      if (this.name.length === 0) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must provide a user name to register.")
        error_out = true
      }
      if (this.password.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a password to register.")
        error_out = true
      }
      if (this.email.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a valid email to register.")
        error_out = true
      }
      if (this.phone_number.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a phone number to register.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "create_new_user"
      console.log("create new user address")
      console.log(target)

      console.log(`Phone Number: ${this.phone_number}`)
      console.log(`TOS opt in? ${this.tos_opt_in}`)
      await axios.post(target, {
        name: this.name,
        password: this.password,
        phone_number: this.phone_number,
        email: this.email,
        tos_agree: this.tos_opt_in
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad registration. Check name, password and phone.")
          console.log(error)
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          error_out = true
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              this.$store.commit('save_name', this.name)
              this.$store.commit('save_phone', this.phone_number)
              this.name = ''
              this.phone_number = ''
              this.password = ''
              this.$router.push('/register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },

    async process_asset_package(asset_package) {
      // console.log("inside processing asset package.")

      //save jwt authentication token in vuex
      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])
      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      // console.log("done with processing the asset package")
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>
@import '../../common.css';


/*.methods_intro h6{*/
/*  color: white;*/
/*  font-family: 'Akshar', sans-serif;*/
/*  font-size: 26px;*/
/*}*/

/*.standard_method p {*/
/*  color: white;*/
/*}*/

/*.mfa_method p {*/
/*  color: white;*/
/*}*/

/*.opt_in_sizing {*/
/*  width: 75%;*/
/*  margin: auto;*/
/*  text-align: center;*/
/*}*/

.main_column {
  max-width: 75%;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


.spacer {
  margin-left: 2vw;
  margin-right: 2vw;
}


@media (min-width: 100px) {

  .create_your_account {
    margin-top: 75px;
    margin-bottom: 30px;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }


  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
    color: var(--primary-purple);
  }

  .main_column {
    max-width: 75%;
  }

  .methods_intro h6 {
    color: white;
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
    color: white;
  }

  .mfa_method p {
    color: white;
  }

  .opt_in_sizing {
    width: 90%;
    margin: auto;
    text-align: center;

  }

  .msg_box {
    padding: 80px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
    max-width: 85vw;
  }

  .spacer_opt_in {
    width: 20px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_row p {
    font-size: 18px;
  }

  .opt_in_text_no {
    width: 200px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }

  .no_sms_warning {
    color: red;
    /*text-align: center;*/
    font-size: 20px;
    margin-bottom: 20px;
  }

}


@media (min-width: 360px) {
  #links_row {
    width: 87vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }
}

@media (min-width: 400px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
    color: var(--primary-purple);
  }
}

@media (min-width: 500px) {

  .hat_size {
    width: 182px;
    margin-top: 20px;

  }

  .spacer {
    margin-left: 2vw;
    margin-right: 50px;
  }

  .main_column {
    max-width: 75%;
  }

  .mfa_login {
    /*height: 100vh;*/
  }

  .r_footer {
    margin-left: 50px;
    margin-top: 20px;
    width: 350px;

  }

}


@media (min-width: 770px) {


  .hat_size {
    width: 182px;
    margin-top: 150px;

  }

  .spacer {
    margin-left: 2vw;
    margin-right: 50px;
  }

  .main_column {
    max-width: 75%;
  }

  .mfa_login {
    /*height: 100vh;*/
  }

  .r_footer {
    margin-left: 50px;
    margin-top: 20px;
    width: 350px;
  }

  /*  .r_entry1 {*/
  /*   font-size: 10px;*/
  /*}*/
  .r_entry2 {

  }

  .r_entry3 {

  }

}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}

@media (min-width: 1200px) {

  h4{
    font-size: 30px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 360px;
  }

  .spacer_opt_in {
    width: 30px;
  }

  .opt_in_text_yes {
    width: 350px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 350px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }
}


@media (min-width: 2000px) {
  .login_input {
    width: 85vw;
    max-width: 600px;
  }

  .methods_intro h6 {
    color: white;
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
    color: white;
  }

  .mfa_method p {
    color: white;
  }

  .opt_in_sizing {
    width: 50%;
    margin: auto;
    text-align: center;

  }

  .msg_box {
    padding: 80px;
  }


}

</style>