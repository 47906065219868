<template>
  <div>
    <label_value_non_editable
        :label=label
        :value=value
        :bg_color=bg_color
        :label_color=label_color
        :value_color=value_color
        :value_bg_color=value_bg_color
        :letter_spacing_px="letter_spacing_px"
    ></label_value_non_editable>

    <div class="value_adjuster flex_space_around_row padding_tb_10" >
      <img class="arrow_size" src="@/assets/lt_arrow.svg" alt="Left Arrow" @click ="left_arrow_big">
      <img class="arrow_size" src="@/assets/lt_arrow.svg" alt="Left Arrow" @click ="left_arrow_small">
      <img class="arrow_size" src="@/assets/rt_arrow.svg" alt="Left Arrow" @click ="right_arrow_small">
      <img class="arrow_size" src="@/assets/rt_arrow.svg" alt="Left Arrow" @click ="right_arrow_big">
    </div>
  </div>
</template>

<script>
import {colors } from "@/library";
import label_value_non_editable from "@/components/parts/label_value_non_editable";

export default {
  name: "label_value_adjustable",
  props: ['label', 'value', 'bg_color', 'label_color', 'value_color', 'value_bg_color','letter_spacing_px'],
  components: {
    label_value_non_editable
  },
  data() {
    return {
      colors: colors,
    }
  },
  methods: {
    left_arrow_big(){
      this.$emit('left_arrow_big')
    },
    left_arrow_small(){
       this.$emit('left_arrow_small')
    },
    right_arrow_small(){
       this.$emit('right_arrow_small')
    },
    right_arrow_big(){
       this.$emit('right_arrow_big')
    },
  },
  async mounted() {
    // console.log("running mounted")
    let adjuster_box = this.$el.querySelector('.value_adjuster')
    adjuster_box.style.background = this.bg_color

    // label.style.color = this.label_color
    //
    // value.style.background = this.value_bg_color
    // value.style.color = this.value_color


  },
}
</script>

<style scoped>

.arrow_size{
  width: 20px;
}

</style>