<template>
  <div class="slide_over_item circle_smaller flex_centered_column shadow_3d" @click="launch"
       :class="{bg_cyanic: button_state, bg_gray: !button_state}">
    <div class="text_10" :class="{text_white: !button_state, text_black: button_state}" >{{ button_text }}</div>
  </div>
</template>

<script>
export default {
  name: "launch_button",
  props: ['uuid','button_state_start', 'button_text'],
  data(){
    return {
      button_status_: this.button_state_start,
      orig_button_status: this.button_state_start

    }
  },
  methods: {
    button_state_to_false(){
      this.button_status_ = false
    },
    button_state_to_true(){
      this.button_status_ = true
    },
    button_state_toggle(){
      this.button_status_ = !this.button_status_
    },
    launch(){
      if(this.button_state){
        this.$emit('launch', true)
      }
    },
    reset(){
      this.button_status_ = this.orig_button_status
    },
  },
  computed: {
    button_state(){
      return this. button_status_
    },
  },

}
</script>

<style scoped>

</style>