<template>
  <div class="screen_block">
    <div class="flex_centered_column margin_tb_10">

      <div class="margin_b_20">{{ label }}</div>

      <drop_down_select
          class="margin_b_10"
          ref="drop_down_child"
          title="Select PDA"
          :values="pda_values"
          @value-selected="candidate_selected"
      ></drop_down_select>

      <drop_down_select
          class="margin_b_10"
          ref="drop_down_child"
          title="Select Country"
          :values="country_values"
          @value-selected="country_candidate_selected"
      ></drop_down_select>

      <drop_down_select
          class="margin_b_10"
          ref="drop_down_child"
          title="Select State"
          :values="state_values"
          @value-selected="state_candidate_selected"
      ></drop_down_select>

      <drop_down_select
          class="margin_b_10"
          ref="drop_down_child"
          title="Select Market"
          :values="market_values"
          @value-selected="market_candidate_selected"
      ></drop_down_select>

      <div class="flex_space_around_row margin_tb_10">
        <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :uuid="child_toggle_uuid"
                      :state_disabled="toggle_state"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        ></basic_toggle>

        <basic_text_button
            ref="assoc_to_market_button"
            class="shadow_3d"
            :button_state="assoc_to_market_button_state"
            :button_color="colors.new_cyanic_main_light"
            button_color_not_ready="gray"
            :button_not_ready_text_color="colors.whitesmoke"
            :hover_color="hover_color"
            button_height="50px"
            button_width="50px"
            border_rad_pct="50%"
            text="Link"
            :text_color="colors.black"
            text_size="12px"
            :bold="true"
            event_name="assoc_to_market"
            @assoc_to_market="associate_pda_to_market"
        ></basic_text_button>

      </div>
    </div>
  </div>

</template>

<script>
import basic_toggle from "@/components/parts/basic_toggle";
import drop_down_select from "@/components/parts/drop_down_select";
import basic_text_button from "@/components/parts/basic_text_button";
import {colors, get_uuid} from "@/library";
// import launch_button from "@/components/parts/launch_button";

export default {
  name: "market_associator",
  components: {
    basic_toggle,
    drop_down_select,
    basic_text_button
    // launch_button
  },
  props: ['label', 'event_name', 'button_text', 'label_color', 'country_values', 'state_values', 'selection_title',
    'market_values', 'pda_values', 'country_selection_event', 'state_selection_event', 'market_selection_event',
    'pda_selection_event', 'associate_pda_to_market_event','toggle_state', 'hover_color'],
  data() {
    return {
      colors: colors,
      candidate_: "",
      child_toggle_uuid: get_uuid(),
      child_button_uuid: get_uuid(),
      button_state_start: false,
      assoc_to_market_button_state_: false
    }
  },
  methods: {
    do_nothing() {
      console.log("You clicked a button.")
    },
    reset(){
        this.do_nothing()
    },
    associate_pda_to_market() {
      this.do_nothing()
      this.$emit(this.associate_pda_to_market_event, true, this.uuid)
    },
    country_candidate_selected(state) {
      this.$emit(this.country_selection_event, state, this.uuid)
    },
    state_candidate_selected(state) {
      this.$emit(this.state_selection_event, state, this.uuid)
    },
    market_candidate_selected(state) {
      this.$emit(this.market_selection_event, state, this.uuid)
    },
    candidate_selected(state) {
      if (state === `---- ${this.selection_title} ----`) {
        this.candidate_ = ""
      } else {
        this.candidate_ = state
        this.$emit(this.pda_selection_event, state, this.uuid)
      }
    },
    toggle_is_true() {
      this.assoc_to_market_button_state_ = true
    },
    toggle_is_false() {
      this.$refs.toggle_child.toggle_state_to_false();
      this.assoc_to_market_button_state_ = false
    },
  },
  computed: {
    candidate() {
      return this.candidate_
    },
    assoc_to_market_button_state() {
      return this.assoc_to_market_button_state_
    },

  },
}
</script>

<style scoped>

</style>