<template>
  <universal_invisible_backer
      :show_backer="show_popup"
      :backer_color="backer_color"
      event_name="backer_clicked"
      @backer_clicked="emit_bg_state"
      :no_blur="no_blur"
  ></universal_invisible_backer>

  <div @click="emit_bg_state" v-if="(show_popup)" class="p_fx z_401 flex_fully_centered_column col_box" id="whole_thing">
    <div class="flex_fully_centered_row">
      <div @click.stop="do_nothing" class="do_nothing flex_fully_centered_column">
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import {v4 as uuidv4} from "uuid";
import {colors} from "@/library";

export default {
  name: "universal_pop_up_slot",
  props: ['show_popup', 'backer_color', 'p_top','p_bottom','p_left','p_right', 'no_blur'],
  async mounted() {
    this.id = uuidv4();
  },
  emits: ['universal_popup_bg_clicked'],
  components: {
    universal_invisible_backer,
  },
  async updated() {

    await this.$nextTick(function () {
      try {
        let whole_thing = document.getElementById("whole_thing")

        if(this.p_top){
          this.p_top_ = this.p_top
        }
        if(this.p_bottom){

          this.p_bottom_ = this.p_bottom
        }
        if(this.p_left){
          this.p_left_ = this.p_left
        }
        if(this.p_right){
          this.p_right_ = this.p_right
        }

        whole_thing.style.top = this.p_top_
        whole_thing.style.bottom = this.p_bottom_
        whole_thing.style.left = this.p_left_
        whole_thing.style.right = this.p_right_
      } catch (error) {
        // console.error(error)
      }

    })
  },
  data() {
    return {
      colors: colors,
      id: "",
      p_top_: 0,
      p_bottom_: 0,
      p_left_: 0,
      p_right_: 0,
    }
  },
  methods: {
    emit_bg_state() {
      this.$emit("universal_popup_bg_clicked", true)
    },
    do_nothing() {
    },

  },
  computed: {},
}
</script>

<style scoped>

.col_box {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.do_nothing{
  margin: 0;
}

</style>