<template>
  <section class="flex_left_aligned_column font_raleway a_forum_post" :id="post_id">
    <div>
      <div class="flex_start_row comment_topper">
        <div class="flex_centered_column" id="user_tag">
          <basic_text_button
              class=""
              v-if="!mobile_mode"
              :button_state="true"
              :button_color="colors.new_cyanic_mid"
              :text_color="colors.wyatt_gray"
              :button_height="name_tag_size"
              :button_width="name_tag_size"
              border_rad_pct="50%"
              :text="first_initial"
              :text_size="other_buttons_text_size"
              :bold="true"
              event_name="non_event"
              @non_event="do_nothing"
          ></basic_text_button>
        </div>
        <div class="flex_left_aligned_centered_column" id="comment_meta_container">

          <div class="flex_start_row flex_wrap" id="meta_user_name">

            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <div>
                  {{ posting_user }}
                </div>
              </div>
            </div>


            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <div class="admin_tag" v-if="poster_is_admin === 1 || poster_is_admin === true">
                  Admin
                </div>
              </div>
            </div>


<!--            <div class="flex_centered_row">-->
<!--              <div class="flex_centered_column">-->
<!--                <div class="badge_tags moderator_tag" v-if="user_badge_data['moderator'] === true">-->
<!--                  Mod-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <div class=" badge_tags joined_tag">
                  Since: {{ join_date }}
                </div>
              </div>
            </div>

            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <div class="badge_tags ft_hours_tag" v-if="ft_hours_int > 0">
                  FT Hours: {{ ft_hours_int }}
                </div>
              </div>
            </div>


          </div>
          <div class="flex_start_row" id="meta_post_ts">{{ ts_string }}</div>
        </div>
        <div>
          <div id="the_hider" v-if="user_is_admin">
            <basic_text_button
                :button_state="true"
                :button_color="colors.pennie_gray"
                :text_color="colors.eli_gray"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                :button_height="other_buttons_h"
                :button_width="other_buttons_w"
                border_rad_pct="5px"
                :text="hide_button_text"
                :text_size="other_buttons_text_size"
                :bold="true"
                event_name="hide_button_clicked"
                @hide_button_clicked="hide_toggle"
            ></basic_text_button>
          </div>
        </div>
      </div>
    </div>
    <div id="comment_text_container">


      <div v-if="hidden && user_is_admin" class="word_break_word hidden_admin_viewable">
        {{ comment_content }}
      </div>

      <div v-else-if="hidden">This comment is hidden because it violates Tiny Magic Hat user policy.</div>
      <!--      <div v-else id="comment_text" class="word_break_word">{{ comment_content }}</div>-->
      <div v-else id="comment_text" class="word_break_word"><span v-html="comment_content"></span></div>
      <!--      <div class="word_break_word">Parent: {{parent_id}}</div>-->
      <!--      <div class="word_break_word">Post: {{post_id_num}}</div>-->
      <!--      <div class="word_break_word">Thread: {{thread_id}}</div>-->
      <!--      <div class="word_break_word">TabCount: {{ indent_level }}</div>-->
      <!--      <div class="word_break_word">Likes: {{ number_of_likes }}</div>-->
      <!--      <div class="word_break_word">Admin Hide: {{ hidden }}</div>-->

    </div>
    <div class="flex_start_row" id="buttons_row">


      <basic_slot_button
          class=""
          v-if="!hidden"
          :button_state="true"
          :button_color="colors.white"
          button_color_not_ready="gray"
          :button_height="other_buttons_h"
          :button_width="other_buttons_w"
          border_rad_pct="0"
          :text_color="colors.white"
          :bold="true"
          event_name="open_reply_toggle"
          @open_reply_toggle="open_reply_toggle"
      >
        <div class="flex_centered_end_row action_button_text">
          <!--          <span class="material-symbols-outlined material_icon neutral">quick_phrases</span>-->
          <img class="material_icon"
               :src="image_name('quick_phrases.svg')"
               alt="Reply Button">


          <!--          <div class="material_icon_bg  flex_centered_row">-->
          <!--          <div class="flex_centered_column">-->
          <!--            <img class="material_icon"-->
          <!--                :src="image_name('quick_phrases.svg')"-->
          <!--                :alt=desc>-->
          <!--          </div>-->

          <!--        </div>-->


          <div class="margin_r_20">reply</div>
        </div>
      </basic_slot_button>
      <div class="flex_centered_column">
        <basic_icon_button
            v-if="is_post_liked && !hidden"
            class="cursor_ptr"
            :button_color="colors.transparent"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            button_height="30px"
            button_width="30px"
            border_rad_pct="50%"
            image_path='heart_red.svg'
            image_width="25px"
            alt_text="like button"
            event_name="heart_unclicked"
            @heart_unclicked="unlike"
        ></basic_icon_button>
        <basic_icon_button
            v-if="!is_post_liked && !hidden"
            class="cursor_ptr"
            :button_color="colors.transparent"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            button_height="30px"
            button_width="30px"
            border_rad_pct="50%"
            image_path='heart_outline.svg'
            image_width="25px"
            alt_text="like button"
            event_name="heart_clicked"
            @heart_clicked="like"
        ></basic_icon_button>
      </div>
      <div class="flex_centered_column" v-if="!hidden">
        {{ number_of_likes }}
      </div>

    </div>


    <section v-if="open_reply" id="reply_to_comment">
      <div id="reply_break_line" class="flex_end_row">
        <basic_text_button
            class=""
            :button_state="true"
            button_color="lightgray"
            text_color="red"
            button_height="30px"
            button_width="30px"
            border_rad_pct="50%"
            text="X"
            text_size="17px"
            :bold="true"
            event_name="close_reply"
            @close_reply="open_reply_false"
        ></basic_text_button>
      </div>

      <div class=" flex_centered_column" id="reply_label_container">
        <label for="reply_comment_input" id="reply_comment_label">
          <div class="flex_centered_row ">Comment:</div>
        </label>
      </div>

      <div class="text_white flex_centered_row" id="reply_comment_input_container">
        <textarea v-model="comment_text" name="reply_to_comment" id="reply_comment_input" :cols="comment_cols"
                  rows="10"></textarea>
      </div>

      <div class="flex_end_row" id="post_reply_button">
        <basic_text_button
            class=""
            :button_state="true"
            :button_color="colors.new_primary_purple"
            :text_color="colors.white"
            :button_height="other_buttons_h"
            :button_width="other_buttons_w"
            border_rad_pct="5px"
            text="Post"
            :text_size="other_buttons_text_size"
            :bold="true"
            event_name="post_reply"
            @post_reply="create_and_post_reply"
        ></basic_text_button>
      </div>

    </section>


  </section>
</template>

<script>
/* eslint-disable */
import {
  colors,
  num_is_between_inclusive,
  good_token_now,
  scroll_to_element,
  dual_route,
  timestamp
} from "@/library";
import basic_slot_button from "@/components/parts/basic_slot_button";
import basic_text_button from "@/components/parts/basic_text_button";
import basic_icon_button from "@/components/parts/basic_icon_button";
import {v4 as uuidv4} from 'uuid';
import store from "@/store";

export default {
  async mounted() {
    this.post_id = uuidv4();
    let base_post_indent = this.base_post_indent
    this.run_window_width_adjustments(this.windowWidth)
    await this.$nextTick(function () {
      try {
        let indent
        let per_indent_amt
        if (this.windowWidth < 501) {
          per_indent_amt = 0
        } else {
          per_indent_amt = 80
        }

        indent = (this.indent_level * per_indent_amt) + base_post_indent
        let the_post = document.getElementById(this.post_id)
        the_post.style.marginLeft = indent + "px"

        let me = store.getters.my_name
        //Non-logged is users have a 'name' of "".
        if (this.likes_obj['name'] === me && store.getters.my_name !== "") {
          // console.log("investigating the likes object on mount")
          // console.log(this.likes_obj)
          this.post_is_liked_ = true
        } else {
          // console.log("incoming post data does not have my name on it.")
          this.post_is_liked_ = false
        }

      } catch (error) {
        console.error(error)
      }
    })
  },


  async updated() {
    console.log("RUNNING UPDATED on forum post")
    console.log(`PRETICK: And now, HIDDEN is ${this.hidden} and admin hide is ${this.admin_hide}`)
    let base_post_indent = this.base_post_indent
    await this.$nextTick(function () {
      try {
        let indent
        let per_indent_amt
        if (this.windowWidth < 501) {
          per_indent_amt = 0
        } else {
          per_indent_amt = 80
        }

        indent = (this.indent_level * per_indent_amt) + base_post_indent
        let the_post = document.getElementById(this.post_id)
        the_post.style.marginLeft = indent + "px"

        try {
          let text_area = document.getElementById("reply_comment_input")
          text_area.focus()
        } catch (error) {
          //pass
        }

        console.log(`POSTTICK: And now, HIDDEN is ${this.hidden} and admin hide is ${this.admin_hide}`)


      } catch (error) {
        console.error(error)
      }
    })
  },

  name: "forum_post",
  props: ['posting_user', 'comment_content', 'ts_string', 'user_is_admin',
    'indent_level', 'hidden', 'poster_is_admin', 'likes_obj', 'post_id_num', 'thread_id', 'parent_id', 'user_badge_data',
  'joined_date', 'ft_hours'],
  data() {
    return {
      post_id: "",
      colors: colors,
      base_post_indent: 30,
      post_is_liked_: false,
      open_reply_: false,
      mobile_mode_x: true,
      admin_hide_: this.hidden,
      heart_timer: "",
      desktop_cutoff: 800,
      name_tag_size_: 50,
      other_buttons_h_: 50,
      other_buttons_w_: 125,
      other_buttons_text_size_: 20,
      comment_text_area_col: 35,
      comment_text: "",
      // is_liked_: false,
      // remove_button_text_: "REMOVE",
    }
  },
  components: {
    basic_text_button,
    basic_icon_button,
    basic_slot_button
  },
  methods: {
    image_name(icon_file) {
      return require(`@/assets/${icon_file}`);
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    register_like(value) {
      console.log(`Root register like call is made`)
      let outs = {
        post_id: this.post_id_num,
        thread_id: this.thread_id,
        caller: store.getters.my_name,
        actions: {'command': 'register_like'},
        like: value
      }
      console.log(outs)

      this.$emit('register_like', outs)
    },
    like() {
      // this.is_liked_ = true
      console.log("Like is clicked")
      if (!good_token_now()) {
        // let message = "Log in to participate on the forums."
        // store.commit('notification_enqueue', message)
        this.dual_route_wrapper('register')
      } else {
        let timer = this.heart_timer
        let now = timestamp(true)
        // console.log(`in like: Timer: "${timer}", Now: ${now}`)
        if (timer) {
          let diff = now - timer
          if (diff >= 3) {
            // console.log("proceed")
            this.heart_timer = now
          } else {
            //Heart is getting clicked too fast.  Don't continue.
            // store.commit('notification_enqueue', "Clicking too fast.")
            return null
          }
        } else {
          this.heart_timer = now
        }
        // console.log(`What is the timer?  ${timer}`)
        let me = store.getters.my_name
        this.likes_obj['name'] = me
        this.likes_obj['likes'] = this.likes_obj['likes'] + 1
        this.post_is_liked_ = true

        //make an api call to tell backend of the like.
        this.register_like(true)


      }
    },
    async create_and_post_reply() {
      let p = {
        'parent_id': this.post_id_num,
        'post_text': this.comment_text
      }
      this.$emit('create_and_post_reply', p)
      this.comment_text = ""
      this.open_reply_false()
    },


    unlike() {
      // this.is_liked_ = false
      // this.is_liked_ = true
      // console.log("UNlike is clicked")
      if (!good_token_now()) {
        // let message = "Log in to participate on the forums."
        // store.commit('notification_enqueue', message)
        this.dual_route_wrapper('register')
      } else {
        let timer = this.heart_timer
        let now = timestamp(true)
        console.log(`in unlike: Timer: "${timer}", Now: ${now}`)
        if (timer) {
          let diff = now - timer
          if (diff >= 3) {
            this.heart_timer = now
          } else {
            //Heart is getting clicked too fast.  Don't continue.
            // store.commit('notification_enqueue', "Clicking too fast.")
            return null
          }
        } else {
          this.heart_timer = now
        }
        // let me = store.getters.my_name
        this.likes_obj['name'] = ""
        this.likes_obj['likes'] = this.likes_obj['likes'] - 1
        this.post_is_liked_ = false
        this.register_like(false)

      }
    },
    do_nothing() {
      console.log("nothing")
    },
    // hide_toggle() {
    //   this.admin_hide = !this.admin_hide
    // },

    hide_toggle() {
      console.log(`Hide post call originates.`)
      let outs = {
        post_id: this.post_id_num,
        thread_id: this.thread_id,
        caller: store.getters.my_name,
        actions: {'command': 'hide_post'},
      }
      console.log(outs)

      this.$emit('hide_post', outs)
    },

    open_reply_toggle() {

      if (good_token_now()) {
        this.open_reply_ = true
      } else {
        // let message = "Log in to participate on the forums."
        //   store.commit('notification_enqueue', message)
        this.dual_route_wrapper('register')
        // let message = "Log in to participate on the forums."
        //   store.commit('notification_enqueue', message)
      }

    },
    open_reply_false() {
      this.open_reply_ = false
    },

    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      // this.adjust_post_indents()
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        this.name_tag_size_ = 25
        this.other_buttons_h_ = 25
        this.other_buttons_w_ = 70
        this.other_buttons_text_size_ = 15
        this.comment_text_area_col = 20
        // this.remove_button_text_ = "REM"
      }
      if (num_is_between_inclusive(100, 1000, new_w)) {

        try {
          let per_indent_amt = 80
          let base_post_indent = 50
          let max_indentions = 2
          let indent_to_use = Math.min(this.indent_level, max_indentions)
          let indent = (indent_to_use * per_indent_amt) + base_post_indent
          let the_post = document.getElementById(this.post_id)
          the_post.style.marginLeft = indent + "px"
        } catch (error) {
          //pass
        }
      }
      if (num_is_between_inclusive(1001, 2000, new_w)) {
        try {
          let per_indent_amt = 80
          let base_post_indent = 50
          let max_indentions = 4
          // this.other_buttons_h_ = 25
          // this.other_buttons_w_ = 70
          let indent_to_use = Math.min(this.indent_level, max_indentions)
          let indent = (indent_to_use * per_indent_amt) + base_post_indent
          let the_post = document.getElementById(this.post_id)
          the_post.style.marginLeft = indent + "px"
        } catch (error) {
          //pass
        }
      }

      if (num_is_between_inclusive(2001, 3000, new_w)) {
        try {
          let per_indent_amt = 80
          let base_post_indent = 50
          let max_indentions = 5
          let indent_to_use = Math.min(this.indent_level, max_indentions)
          let indent = (indent_to_use * per_indent_amt) + base_post_indent
          let the_post = document.getElementById(this.post_id)
          the_post.style.marginLeft = indent + "px"
        } catch (error) {
          //pass
        }
      }
      if (num_is_between_inclusive(3001, 5000, new_w)) {
        try {
          let per_indent_amt = 80
          let base_post_indent = 50
          let max_indentions = 8
          let indent_to_use = Math.min(this.indent_level, max_indentions)
          let indent = (indent_to_use * per_indent_amt) + base_post_indent
          let the_post = document.getElementById(this.post_id)
          the_post.style.marginLeft = indent + "px"
        } catch (error) {
          //pass
        }
      }
      if (num_is_between_inclusive(301, 1199, new_w)) {
        this.name_tag_size_ = 25
        this.other_buttons_h_ = 25
        this.other_buttons_w_ = 70
        this.other_buttons_text_size_ = 15
        this.comment_text_area_col = 20
      }
      if (num_is_between_inclusive(1200, 5000, new_w)) {
        this.name_tag_size_ = 50
        this.other_buttons_h_ = 50
        this.other_buttons_w_ = 125
        this.other_buttons_text_size_ = 18
        this.comment_text_area_col = 35
        // this.remove_button_text_ = "REMOVE"
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },
  },
  computed: {
    ft_hours_int(){
       if(!this.ft_hours){
        return "0"
      }
      return this.ft_hours
    },

    join_date() {
      console.log('the join date is:')
      // console.log(this.user_badge_data['joined'])
      // return this.user_badge_data['joined']
      if(!this.joined_date){
        return "1/1/23"
      }else{
         return this.joined_date
      }

    },



    mobile_mode() {
      return this.mobile_mode_x
    },
    first_initial() {
      return this.posting_user.charAt(0).toUpperCase()
    },
    hide_button_text() {
      if (this.hidden) {
        return "UNHIDE"
      } else {
        return "HIDE"
      }
    },
    user_is_forum_admin() {
      //I need a db query that searches for a perm associated with this particular forum.
      return this.user_is_forum_admin
      //
      // let me = store.getters.my_name
      // if (me === "paulq" || me === 'wyatt') {
      //   return true
      // } else {
      //   return false
      // }
    },

    open_reply() {
      return this.open_reply_
    },
    admin_hide() {
      console.log(`Admin Hide is ....${this.admin_hide_}`)
      if (this.admin_hide_ === 0 || this.admin_hide_ === false) {
        return false
      } else {
        return true
      }
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    name_tag_size() {
      // let num = this.name_tag_size_ - 10
      // return num + 'px'
      return this.name_tag_size_ + 'px'
    },
    other_buttons_h() {
      return this.other_buttons_h_ + 'px'
    },
    other_buttons_w() {
      return this.other_buttons_w_ + 'px'
    },
    other_buttons_text_size() {
      return this.other_buttons_text_size_ + 'px'
    },
    comment_cols() {
      return this.comment_text_area_col
    },
    number_of_likes() {
      return this.likes_obj['likes']
    },

    // a_message() {
    //   console.log("INSIDE secret message")
    //   return this.likes_obj.name !== ""
    //   // return "here is the secret message"
    // },
    is_post_liked() {
      // console.log(`INSIDE IS LIKED........${this.likes_obj.name !== ""}`)
      // return this.likes_obj['name']==="" ? false : true
      // return this.like_name ==="" ? false : true
      return this.post_is_liked_
      // let me = store.getters.my_name
      // console.log(`Is liked: ${this.likes_obj['name'] === me}`)
      // if(this.likes_obj['name'] !== ""){
      //   return true
      // }else{
      //   return false
      // }
      // return this.likes_obj['name'] === me;

    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>


@media (min-width: 100px) {

  .admin_tag {
    margin-left: 10px;
    background-color: var(--pennie_gray);
    color: var(--eli_gray);
    font-size: 12px;
    padding: 3px;
    /*line-height: 2em;*/
  }

  .badge_tags {
    margin-left: 10px;
    background-color: var(--pennie_gray);
    color: var(--eli_gray);
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
  }

  .moderator_tag {
    background: var(--new-orange-main);
    color: white;
  }

  .joined_tag {
    background: var(--new-primary-purple-deep);
    color: white;
  }

  .ft_hours_tag {
    background: var(--new-gold);
    color: var(--darker_gray);
  }

  .material_icon {
    color: var(--morgie_gray);
    height: 20px;
    margin-right: 5px;
  }

  .hidden_admin_viewable {
    background-color: var(--pennie_gray);
    color: var(--morgie_gray);
  }


  #user_tag {
    /*margin-right: 10px;*/
    color: red;
  }

  .comment_topper {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  #meta_user_name {
    font-weight: 800;
    font-size: 18px;
    color: var(--primary-purple)
  }

  #meta_post_ts {
    font-weight: 800;
    font-size: 15px;
    margin-right: 10px;
  }

  #the_hider {
    margin-left: 10px;
    margin-right: 10px;
  }

  #comment_text_container {
    /*margin-bottom: 10px;*/
  }

  #comment_text {
    margin-right: 10px;
  }

  #post_reply_button {
    margin-right: 25px;
    margin-top: 10px;
  }

  #reply_break_line {
    margin-right: 10px;
    margin-top: 10px;
  }

  #reply_comment_input {
    margin-right: 10px;
  }

}


@media (min-width: 400px) {

  .material_icon {
    height: 30px;
  }
}

@media (min-width: 1200px) {

  .a_forum_post {
    /*margin-bottom: 40px;*/
    margin-top: 20px;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
  }

  #meta_user_name {
    font-weight: 800;
    font-size: 20px;
  }

  #meta_post_ts {
    font-weight: 800;
    font-size: 17px;
  }

  #user_tag {
    margin-right: 10px;
  }

  #comment_text_container {
    padding-top: 20px;
    width: 670px;
  }

  #comment_text {
    white-space: pre-wrap;
    font-size: 19px;
    line-height: 26px;

  }

  #buttons_row {
    /*margin-top: 30px;*/
  }

  #the_hider {
    margin-left: 20px;
  }


  #reply_comment_label {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  #reply_to_comment {
    margin-bottom: 10px;
  }

  #reply_comment_input {
    width: 670px;
    border-radius: 10px;
    font-size: 20px;
    padding: 20px;
  }

  #reply_break_line {
    border-top: 1px solid gray;
    padding-top: 10px;
    margin-top: 20px;
  }

  #post_reply_button {
    margin-top: 20px;
  }

  .badge_tags {
    /*margin-left: 10px;*/
    /*background-color: var(--pennie_gray);*/
    /*color: var(--eli_gray);*/
    font-size: 18px;
    /*text-align: center;*/
    /*border-radius: 10px;*/
    /*padding: 3px;*/
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    padding: 10px;
    /*margin-bottom: 5px;*/
  }

   .moderator_tag {
    background: var(--new-orange-main-light_50);
    color: var(--darker_gray);
  }

  .joined_tag {
    background: var(--new-primary-purple-very-deep_50);
    color: white;
  }

  .ft_hours_tag {
    background: var(--new-light-gold);
    color: var(--darker_gray);
  }


}

@media (min-width: 2000px) {

}

</style>








<!--<template>-->
<!--  <section class="flex_left_aligned_column font_raleway a_forum_post" :id="post_id">-->
<!--    <div>-->
<!--      <div class="flex_start_row comment_topper">-->
<!--        <div class="flex_centered_column" id="user_tag">-->
<!--          <basic_text_button-->
<!--              class=""-->
<!--              v-if="!mobile_mode"-->
<!--              :button_state="true"-->
<!--              :button_color="colors.new_cyanic_mid"-->
<!--              :text_color="colors.wyatt_gray"-->
<!--              :button_height="name_tag_size"-->
<!--              :button_width="name_tag_size"-->
<!--              border_rad_pct="50%"-->
<!--              :text="first_initial"-->
<!--              :text_size="other_buttons_text_size"-->
<!--              :bold="true"-->
<!--              event_name="non_event"-->
<!--              @non_event="do_nothing"-->
<!--          ></basic_text_button>-->
<!--        </div>-->
<!--        <div class="flex_left_aligned_centered_column" id="comment_meta_container">-->

<!--          <div class="flex_start_row" id="meta_user_name">-->
<!--            <div>-->
<!--              {{ posting_user }}-->
<!--            </div>-->
<!--            <div class="admin_tag" v-if="poster_is_admin === 1 || poster_is_admin === true">-->
<!--              Admin-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex_start_row" id="meta_post_ts">{{ ts_string }}</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div id="the_hider" v-if="user_is_admin">-->
<!--            <basic_text_button-->
<!--                :button_state="true"-->
<!--                :button_color="colors.pennie_gray"-->
<!--                :text_color="colors.eli_gray"-->
<!--                :button_color_not_ready="colors.eli_gray"-->
<!--                :button_not_ready_text_color="colors.pennie_gray"-->
<!--                :button_height="other_buttons_h"-->
<!--                :button_width="other_buttons_w"-->
<!--                border_rad_pct="5px"-->
<!--                :text="hide_button_text"-->
<!--                :text_size="other_buttons_text_size"-->
<!--                :bold="true"-->
<!--                event_name="hide_button_clicked"-->
<!--                @hide_button_clicked="hide_toggle"-->
<!--            ></basic_text_button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div id="comment_text_container">-->


<!--      <div v-if="hidden && user_is_admin" class="word_break_word hidden_admin_viewable">-->
<!--        {{ comment_content }}-->
<!--      </div>-->

<!--      <div v-else-if="hidden">This comment is hidden because it violates Tiny Magic Hat user policy.</div>-->
<!--      &lt;!&ndash;      <div v-else id="comment_text" class="word_break_word">{{ comment_content }}</div>&ndash;&gt;-->
<!--      <div v-else id="comment_text" class="word_break_word"><span v-html="comment_content"></span></div>-->
<!--      &lt;!&ndash;      <div class="word_break_word">Parent: {{parent_id}}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="word_break_word">Post: {{post_id_num}}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="word_break_word">Thread: {{thread_id}}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="word_break_word">TabCount: {{ indent_level }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="word_break_word">Likes: {{ number_of_likes }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="word_break_word">Admin Hide: {{ hidden }}</div>&ndash;&gt;-->

<!--    </div>-->
<!--    <div class="flex_start_row" id="buttons_row">-->


<!--      <basic_slot_button-->
<!--          class=""-->
<!--          v-if="!hidden"-->
<!--          :button_state="true"-->
<!--          :button_color="colors.white"-->
<!--          button_color_not_ready="gray"-->
<!--          :button_height="other_buttons_h"-->
<!--          :button_width="other_buttons_w"-->
<!--          border_rad_pct="0"-->
<!--          :text_color="colors.white"-->
<!--          :bold="true"-->
<!--          event_name="open_reply_toggle"-->
<!--          @open_reply_toggle="open_reply_toggle"-->
<!--      >-->
<!--        <div class="flex_centered_end_row action_button_text">-->
<!--&lt;!&ndash;          <span class="material-symbols-outlined material_icon neutral">quick_phrases</span>&ndash;&gt;-->
<!--           <img class="material_icon"-->
<!--                :src="image_name('quick_phrases.svg')"-->
<!--                alt="Reply Button">-->


<!--&lt;!&ndash;          <div class="material_icon_bg  flex_centered_row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="flex_centered_column">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="material_icon"&ndash;&gt;-->
<!--&lt;!&ndash;                :src="image_name('quick_phrases.svg')"&ndash;&gt;-->
<!--&lt;!&ndash;                :alt=desc>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--&lt;!&ndash;        </div>&ndash;&gt;-->


<!--          <div class="margin_r_20">reply</div>-->
<!--        </div>-->
<!--      </basic_slot_button>-->
<!--      <div class="flex_centered_column">-->
<!--        <basic_icon_button-->
<!--            v-if="is_post_liked && !hidden"-->
<!--            class="cursor_ptr"-->
<!--            :button_color="colors.transparent"-->
<!--            :button_color_not_ready="colors.pennie_gray"-->
<!--            :ready="true"-->
<!--            button_height="30px"-->
<!--            button_width="30px"-->
<!--            border_rad_pct="50%"-->
<!--            image_path='heart_red.svg'-->
<!--            image_width="25px"-->
<!--            alt_text="like button"-->
<!--            event_name="heart_unclicked"-->
<!--            @heart_unclicked="unlike"-->
<!--        ></basic_icon_button>-->
<!--        <basic_icon_button-->
<!--            v-if="!is_post_liked && !hidden"-->
<!--            class="cursor_ptr"-->
<!--            :button_color="colors.transparent"-->
<!--            :button_color_not_ready="colors.pennie_gray"-->
<!--            :ready="true"-->
<!--            button_height="30px"-->
<!--            button_width="30px"-->
<!--            border_rad_pct="50%"-->
<!--            image_path='heart_outline.svg'-->
<!--            image_width="25px"-->
<!--            alt_text="like button"-->
<!--            event_name="heart_clicked"-->
<!--            @heart_clicked="like"-->
<!--        ></basic_icon_button>-->
<!--      </div>-->
<!--      <div class="flex_centered_column" v-if="!hidden">-->
<!--        {{ number_of_likes }}-->
<!--      </div>-->

<!--    </div>-->


<!--    <section v-if="open_reply" id="reply_to_comment">-->
<!--      <div id="reply_break_line" class="flex_end_row">-->
<!--        <basic_text_button-->
<!--            class=""-->
<!--            :button_state="true"-->
<!--            button_color="lightgray"-->
<!--            text_color="red"-->
<!--            button_height="30px"-->
<!--            button_width="30px"-->
<!--            border_rad_pct="50%"-->
<!--            text="X"-->
<!--            text_size="17px"-->
<!--            :bold="true"-->
<!--            event_name="close_reply"-->
<!--            @close_reply="open_reply_false"-->
<!--        ></basic_text_button>-->
<!--      </div>-->

<!--      <div class=" flex_centered_column" id="reply_label_container">-->
<!--        <label for="reply_comment_input" id="reply_comment_label">-->
<!--          <div class="flex_centered_row ">Comment:</div>-->
<!--        </label>-->
<!--      </div>-->

<!--      <div class="text_white flex_centered_row" id="reply_comment_input_container">-->
<!--        <textarea v-model="comment_text" name="reply_to_comment" id="reply_comment_input" :cols="comment_cols"-->
<!--                  rows="10"></textarea>-->
<!--      </div>-->

<!--      <div class="flex_end_row" id="post_reply_button">-->
<!--        <basic_text_button-->
<!--            class=""-->
<!--            :button_state="true"-->
<!--            :button_color="colors.new_primary_purple"-->
<!--            :text_color="colors.white"-->
<!--            :button_height="other_buttons_h"-->
<!--            :button_width="other_buttons_w"-->
<!--            border_rad_pct="5px"-->
<!--            text="Post"-->
<!--            :text_size="other_buttons_text_size"-->
<!--            :bold="true"-->
<!--            event_name="post_reply"-->
<!--            @post_reply="create_and_post_reply"-->
<!--        ></basic_text_button>-->
<!--      </div>-->

<!--    </section>-->


<!--  </section>-->
<!--</template>-->

<!--<script>-->
<!--/* eslint-disable */-->
<!--import {-->
<!--  colors,-->
<!--  num_is_between_inclusive,-->
<!--  good_token_now,-->
<!--  scroll_to_element,-->
<!--  dual_route,-->
<!--  timestamp-->
<!--} from "@/library";-->
<!--import basic_slot_button from "@/components/parts/basic_slot_button";-->
<!--import basic_text_button from "@/components/parts/basic_text_button";-->
<!--import basic_icon_button from "@/components/parts/basic_icon_button";-->
<!--import {v4 as uuidv4} from 'uuid';-->
<!--import store from "@/store";-->
<!--export default {-->
<!--  async mounted() {-->
<!--    this.post_id = uuidv4();-->
<!--    let base_post_indent = this.base_post_indent-->
<!--    this.run_window_width_adjustments(this.windowWidth)-->
<!--    await this.$nextTick(function () {-->
<!--      try {-->
<!--        let indent-->
<!--        let per_indent_amt-->
<!--        if (this.windowWidth < 501) {-->
<!--          per_indent_amt = 0-->
<!--        } else {-->
<!--          per_indent_amt = 80-->
<!--        }-->
<!--        indent = (this.indent_level * per_indent_amt) + base_post_indent-->
<!--        let the_post = document.getElementById(this.post_id)-->
<!--        the_post.style.marginLeft = indent + "px"-->
<!--        let me = store.getters.my_name-->
<!--        //Non-logged is users have a 'name' of "".-->
<!--        if (this.likes_obj['name'] === me && store.getters.my_name !== "") {-->
<!--          // console.log("investigating the likes object on mount")-->
<!--          // console.log(this.likes_obj)-->
<!--          this.post_is_liked_ = true-->
<!--        } else {-->
<!--          // console.log("incoming post data does not have my name on it.")-->
<!--          this.post_is_liked_ = false-->
<!--        }-->
<!--      } catch (error) {-->
<!--        console.error(error)-->
<!--      }-->
<!--    })-->
<!--  },-->
<!--  async updated() {-->
<!--    console.log("RUNNING UPDATED on forum post")-->
<!--    console.log(`PRETICK: And now, HIDDEN is ${this.hidden} and admin hide is ${this.admin_hide}`)-->
<!--    let base_post_indent = this.base_post_indent-->
<!--    await this.$nextTick(function () {-->
<!--      try {-->
<!--        let indent-->
<!--        let per_indent_amt-->
<!--        if (this.windowWidth < 501) {-->
<!--          per_indent_amt = 0-->
<!--        } else {-->
<!--          per_indent_amt = 80-->
<!--        }-->
<!--        indent = (this.indent_level * per_indent_amt) + base_post_indent-->
<!--        let the_post = document.getElementById(this.post_id)-->
<!--        the_post.style.marginLeft = indent + "px"-->
<!--        try {-->
<!--          let text_area = document.getElementById("reply_comment_input")-->
<!--          text_area.focus()-->
<!--        } catch (error) {-->
<!--          //pass-->
<!--        }-->
<!--        console.log(`POSTTICK: And now, HIDDEN is ${this.hidden} and admin hide is ${this.admin_hide}`)-->
<!--      } catch (error) {-->
<!--        console.error(error)-->
<!--      }-->
<!--    })-->
<!--  },-->
<!--  name: "forum_post",-->
<!--  props: ['posting_user', 'comment_content', 'ts_string', 'user_is_admin',-->
<!--    'indent_level', 'hidden', 'poster_is_admin', 'likes_obj', 'post_id_num', 'thread_id', 'parent_id'],-->
<!--  data() {-->
<!--    return {-->
<!--      post_id: "",-->
<!--      colors: colors,-->
<!--      base_post_indent: 30,-->
<!--      post_is_liked_: false,-->
<!--      open_reply_: false,-->
<!--      mobile_mode_x: true,-->
<!--      admin_hide_: this.hidden,-->
<!--      heart_timer: "",-->
<!--      desktop_cutoff: 800,-->
<!--      name_tag_size_: 50,-->
<!--      other_buttons_h_: 50,-->
<!--      other_buttons_w_: 125,-->
<!--      other_buttons_text_size_: 20,-->
<!--      comment_text_area_col: 35,-->
<!--      comment_text: "",-->
<!--      // is_liked_: false,-->
<!--      // remove_button_text_: "REMOVE",-->
<!--    }-->
<!--  },-->
<!--  components: {-->
<!--    basic_text_button,-->
<!--    basic_icon_button,-->
<!--    basic_slot_button-->
<!--  },-->
<!--  methods: {-->
<!--    image_name(icon_file) {-->
<!--       return require(`@/assets/${icon_file}`);-->
<!--    },-->
<!--    dual_route_wrapper(route, inner = null) {-->
<!--      // this.hamburger_menu_false()-->
<!--      let current = localStorage.getItem('dev_latest_route')-->
<!--      current = current.substring(1)-->
<!--      if (current === route) {-->
<!--        scroll_to_element(inner)-->
<!--        return null-->
<!--      }-->
<!--      dual_route(this.$router, route, inner)-->
<!--    },-->
<!--    register_like(value) {-->
<!--      console.log(`Root register like call is made`)-->
<!--      let outs = {-->
<!--        post_id: this.post_id_num,-->
<!--        thread_id: this.thread_id,-->
<!--        caller: store.getters.my_name,-->
<!--        actions: {'command': 'register_like'},-->
<!--        like: value-->
<!--      }-->
<!--      console.log(outs)-->
<!--      this.$emit('register_like', outs)-->
<!--    },-->
<!--    like() {-->
<!--      // this.is_liked_ = true-->
<!--      console.log("Like is clicked")-->
<!--      if (!good_token_now()) {-->
<!--        // let message = "Log in to participate on the forums."-->
<!--        // store.commit('notification_enqueue', message)-->
<!--        this.dual_route_wrapper('register')-->
<!--      } else {-->
<!--        let timer = this.heart_timer-->
<!--        let now = timestamp(true)-->
<!--        // console.log(`in like: Timer: "${timer}", Now: ${now}`)-->
<!--        if (timer) {-->
<!--          let diff = now - timer-->
<!--          if (diff >= 3) {-->
<!--            // console.log("proceed")-->
<!--            this.heart_timer = now-->
<!--          } else {-->
<!--            //Heart is getting clicked too fast.  Don't continue.-->
<!--            // store.commit('notification_enqueue', "Clicking too fast.")-->
<!--            return null-->
<!--          }-->
<!--        } else {-->
<!--          this.heart_timer = now-->
<!--        }-->
<!--        // console.log(`What is the timer?  ${timer}`)-->
<!--        let me = store.getters.my_name-->
<!--        this.likes_obj['name'] = me-->
<!--        this.likes_obj['likes'] = this.likes_obj['likes'] + 1-->
<!--        this.post_is_liked_ = true-->
<!--        //make an api call to tell backend of the like.-->
<!--        this.register_like(true)-->
<!--      }-->
<!--    },-->
<!--    async create_and_post_reply() {-->
<!--      let p = {-->
<!--        'parent_id': this.post_id_num,-->
<!--        'post_text': this.comment_text-->
<!--      }-->
<!--      this.$emit('create_and_post_reply', p)-->
<!--      this.comment_text = ""-->
<!--      this.open_reply_false()-->
<!--    },-->
<!--    unlike() {-->
<!--      // this.is_liked_ = false-->
<!--      // this.is_liked_ = true-->
<!--      // console.log("UNlike is clicked")-->
<!--      if (!good_token_now()) {-->
<!--        // let message = "Log in to participate on the forums."-->
<!--        // store.commit('notification_enqueue', message)-->
<!--        this.dual_route_wrapper('register')-->
<!--      } else {-->
<!--        let timer = this.heart_timer-->
<!--        let now = timestamp(true)-->
<!--        console.log(`in unlike: Timer: "${timer}", Now: ${now}`)-->
<!--        if (timer) {-->
<!--          let diff = now - timer-->
<!--          if (diff >= 3) {-->
<!--            this.heart_timer = now-->
<!--          } else {-->
<!--            //Heart is getting clicked too fast.  Don't continue.-->
<!--            // store.commit('notification_enqueue', "Clicking too fast.")-->
<!--            return null-->
<!--          }-->
<!--        } else {-->
<!--          this.heart_timer = now-->
<!--        }-->
<!--        // let me = store.getters.my_name-->
<!--        this.likes_obj['name'] = ""-->
<!--        this.likes_obj['likes'] = this.likes_obj['likes'] - 1-->
<!--        this.post_is_liked_ = false-->
<!--        this.register_like(false)-->
<!--      }-->
<!--    },-->
<!--    do_nothing() {-->
<!--      console.log("nothing")-->
<!--    },-->
<!--    // hide_toggle() {-->
<!--    //   this.admin_hide = !this.admin_hide-->
<!--    // },-->
<!--    hide_toggle() {-->
<!--      console.log(`Hide post call originates.`)-->
<!--      let outs = {-->
<!--        post_id: this.post_id_num,-->
<!--        thread_id: this.thread_id,-->
<!--        caller: store.getters.my_name,-->
<!--        actions: {'command': 'hide_post'},-->
<!--      }-->
<!--      console.log(outs)-->
<!--      this.$emit('hide_post', outs)-->
<!--    },-->
<!--    open_reply_toggle() {-->
<!--      if (good_token_now()) {-->
<!--        this.open_reply_ = true-->
<!--      } else {-->
<!--        // let message = "Log in to participate on the forums."-->
<!--        //   store.commit('notification_enqueue', message)-->
<!--        this.dual_route_wrapper('register')-->
<!--        // let message = "Log in to participate on the forums."-->
<!--        //   store.commit('notification_enqueue', message)-->
<!--      }-->
<!--    },-->
<!--    open_reply_false() {-->
<!--      this.open_reply_ = false-->
<!--    },-->
<!--    run_window_width_adjustments(new_w) {-->
<!--      // console.log("Running window adjustments")-->
<!--      // this.adjust_post_indents()-->
<!--      if (new_w >= this.desktop_cutoff) {-->
<!--        this.mobile_mode_x = false-->
<!--      } else {-->
<!--        this.mobile_mode_x = true-->
<!--      }-->
<!--      if (num_is_between_inclusive(100, 300, new_w)) {-->
<!--        this.name_tag_size_ = 25-->
<!--        this.other_buttons_h_ = 25-->
<!--        this.other_buttons_w_ = 70-->
<!--        this.other_buttons_text_size_ = 15-->
<!--        this.comment_text_area_col = 20-->
<!--        // this.remove_button_text_ = "REM"-->
<!--      }-->
<!--      if (num_is_between_inclusive(100, 1000, new_w)) {-->
<!--        try {-->
<!--          let per_indent_amt = 80-->
<!--          let base_post_indent = 50-->
<!--          let max_indentions = 2-->
<!--          let indent_to_use = Math.min(this.indent_level, max_indentions)-->
<!--          let indent = (indent_to_use * per_indent_amt) + base_post_indent-->
<!--          let the_post = document.getElementById(this.post_id)-->
<!--          the_post.style.marginLeft = indent + "px"-->
<!--        } catch (error) {-->
<!--          //pass-->
<!--        }-->
<!--      }-->
<!--      if (num_is_between_inclusive(1001, 2000, new_w)) {-->
<!--        try {-->
<!--          let per_indent_amt = 80-->
<!--          let base_post_indent = 50-->
<!--          let max_indentions = 4-->
<!--          // this.other_buttons_h_ = 25-->
<!--          // this.other_buttons_w_ = 70-->
<!--          let indent_to_use = Math.min(this.indent_level, max_indentions)-->
<!--          let indent = (indent_to_use * per_indent_amt) + base_post_indent-->
<!--          let the_post = document.getElementById(this.post_id)-->
<!--          the_post.style.marginLeft = indent + "px"-->
<!--        } catch (error) {-->
<!--          //pass-->
<!--        }-->
<!--      }-->
<!--      if (num_is_between_inclusive(2001, 3000, new_w)) {-->
<!--        try {-->
<!--          let per_indent_amt = 80-->
<!--          let base_post_indent = 50-->
<!--          let max_indentions = 5-->
<!--          let indent_to_use = Math.min(this.indent_level, max_indentions)-->
<!--          let indent = (indent_to_use * per_indent_amt) + base_post_indent-->
<!--          let the_post = document.getElementById(this.post_id)-->
<!--          the_post.style.marginLeft = indent + "px"-->
<!--        } catch (error) {-->
<!--          //pass-->
<!--        }-->
<!--      }-->
<!--      if (num_is_between_inclusive(3001, 5000, new_w)) {-->
<!--        try {-->
<!--          let per_indent_amt = 80-->
<!--          let base_post_indent = 50-->
<!--          let max_indentions = 8-->
<!--          let indent_to_use = Math.min(this.indent_level, max_indentions)-->
<!--          let indent = (indent_to_use * per_indent_amt) + base_post_indent-->
<!--          let the_post = document.getElementById(this.post_id)-->
<!--          the_post.style.marginLeft = indent + "px"-->
<!--        } catch (error) {-->
<!--          //pass-->
<!--        }-->
<!--      }-->
<!--      if (num_is_between_inclusive(301, 1199, new_w)) {-->
<!--        this.name_tag_size_ = 25-->
<!--        this.other_buttons_h_ = 25-->
<!--        this.other_buttons_w_ = 70-->
<!--        this.other_buttons_text_size_ = 15-->
<!--        this.comment_text_area_col = 20-->
<!--      }-->
<!--      if (num_is_between_inclusive(1200, 5000, new_w)) {-->
<!--        this.name_tag_size_ = 50-->
<!--        this.other_buttons_h_ = 50-->
<!--        this.other_buttons_w_ = 125-->
<!--        this.other_buttons_text_size_ = 18-->
<!--        this.comment_text_area_col = 35-->
<!--        // this.remove_button_text_ = "REMOVE"-->
<!--        // this.video.height = this.v_def_height * this.v_scale-->
<!--        // this.video.width = this.v_def_width * this.v_scale-->
<!--      }-->
<!--    },-->
<!--  },-->
<!--  computed: {-->
<!--    mobile_mode() {-->
<!--      return this.mobile_mode_x-->
<!--    },-->
<!--    first_initial() {-->
<!--      return this.posting_user.charAt(0).toUpperCase()-->
<!--    },-->
<!--    hide_button_text() {-->
<!--      if (this.hidden) {-->
<!--        return "UNHIDE"-->
<!--      } else {-->
<!--        return "HIDE"-->
<!--      }-->
<!--    },-->
<!--    user_is_forum_admin() {-->
<!--      //I need a db query that searches for a perm associated with this particular forum.-->
<!--      return this.user_is_forum_admin-->
<!--      //-->
<!--      // let me = store.getters.my_name-->
<!--      // if (me === "paulq" || me === 'wyatt') {-->
<!--      //   return true-->
<!--      // } else {-->
<!--      //   return false-->
<!--      // }-->
<!--    },-->
<!--    open_reply() {-->
<!--      return this.open_reply_-->
<!--    },-->
<!--    admin_hide() {-->
<!--      console.log(`Admin Hide is ....${this.admin_hide_}`)-->
<!--      if (this.admin_hide_ === 0 || this.admin_hide_ === false) {-->
<!--        return false-->
<!--      } else {-->
<!--        return true-->
<!--      }-->
<!--    },-->
<!--    windowWidth() {-->
<!--      return store.state.windowWidth;-->
<!--    },-->
<!--    name_tag_size() {-->
<!--      // let num = this.name_tag_size_ - 10-->
<!--      // return num + 'px'-->
<!--      return this.name_tag_size_ + 'px'-->
<!--    },-->
<!--    other_buttons_h() {-->
<!--      return this.other_buttons_h_ + 'px'-->
<!--    },-->
<!--    other_buttons_w() {-->
<!--      return this.other_buttons_w_ + 'px'-->
<!--    },-->
<!--    other_buttons_text_size() {-->
<!--      return this.other_buttons_text_size_ + 'px'-->
<!--    },-->
<!--    comment_cols() {-->
<!--      return this.comment_text_area_col-->
<!--    },-->
<!--    number_of_likes() {-->
<!--      return this.likes_obj['likes']-->
<!--    },-->
<!--    // a_message() {-->
<!--    //   console.log("INSIDE secret message")-->
<!--    //   return this.likes_obj.name !== ""-->
<!--    //   // return "here is the secret message"-->
<!--    // },-->
<!--    is_post_liked() {-->
<!--      // console.log(`INSIDE IS LIKED........${this.likes_obj.name !== ""}`)-->
<!--      // return this.likes_obj['name']==="" ? false : true-->
<!--      // return this.like_name ==="" ? false : true-->
<!--      return this.post_is_liked_-->
<!--      // let me = store.getters.my_name-->
<!--      // console.log(`Is liked: ${this.likes_obj['name'] === me}`)-->
<!--      // if(this.likes_obj['name'] !== ""){-->
<!--      //   return true-->
<!--      // }else{-->
<!--      //   return false-->
<!--      // }-->
<!--      // return this.likes_obj['name'] === me;-->
<!--    },-->
<!--  },-->
<!--  watch: {-->
<!--    windowWidth(new_w) {-->
<!--      this.run_window_width_adjustments(new_w)-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--@media (min-width: 100px) {-->
<!--   .material_icon {-->
<!--    color: var(&#45;&#45;morgie_gray);-->
<!--    height: 20px;-->
<!--    margin-right: 5px;-->
<!--  }-->
<!--  .hidden_admin_viewable {-->
<!--    background-color: var(&#45;&#45;pennie_gray);-->
<!--    color: var(&#45;&#45;morgie_gray);-->
<!--  }-->
<!--  .admin_tag {-->
<!--    margin-left: 10px;-->
<!--    background-color: var(&#45;&#45;pennie_gray);-->
<!--    color: var(&#45;&#45;eli_gray);-->
<!--    /*font-size: .8em;*/-->
<!--    /*line-height: 2em;*/-->
<!--  }-->
<!--  #user_tag {-->
<!--    /*margin-right: 10px;*/-->
<!--    color: red;-->
<!--  }-->
<!--  .comment_topper {-->
<!--    margin-bottom: 15px;-->
<!--    margin-top: 15px;-->
<!--  }-->
<!--  #meta_user_name {-->
<!--    font-weight: 800;-->
<!--    font-size: 18px;-->
<!--    color: var(&#45;&#45;primary-purple)-->
<!--  }-->
<!--  #meta_post_ts {-->
<!--    font-weight: 800;-->
<!--    font-size: 15px;-->
<!--    margin-right: 10px;-->
<!--  }-->
<!--  #the_hider {-->
<!--    margin-left: 10px;-->
<!--    margin-right: 10px;-->
<!--  }-->
<!--  #comment_text_container {-->
<!--    /*margin-bottom: 10px;*/-->
<!--  }-->
<!--  #comment_text {-->
<!--    margin-right: 10px;-->
<!--  }-->
<!--  #post_reply_button {-->
<!--    margin-right: 25px;-->
<!--    margin-top: 10px;-->
<!--  }-->
<!--  #reply_break_line {-->
<!--    margin-right: 10px;-->
<!--    margin-top: 10px;-->
<!--  }-->
<!--  #reply_comment_input {-->
<!--    margin-right: 10px;-->
<!--  }-->
<!--}-->
<!--@media (min-width: 400px) {-->
<!--  .material_icon {-->
<!--    height: 30px;-->
<!--  }-->
<!--}-->
<!--@media (min-width: 1200px) {-->
<!--  .a_forum_post {-->
<!--    /*margin-bottom: 40px;*/-->
<!--    margin-top: 20px;-->
<!--    border-bottom: 1px solid gray;-->
<!--    padding-bottom: 15px;-->
<!--  }-->
<!--  #meta_user_name {-->
<!--    font-weight: 800;-->
<!--    font-size: 20px;-->
<!--  }-->
<!--  #meta_post_ts {-->
<!--    font-weight: 800;-->
<!--    font-size: 17px;-->
<!--  }-->
<!--  #user_tag {-->
<!--    margin-right: 10px;-->
<!--  }-->
<!--  #comment_text_container {-->
<!--    padding-top: 20px;-->
<!--    width: 670px;-->
<!--  }-->
<!--  #comment_text {-->
<!--    white-space: pre-wrap;-->
<!--    font-size: 19px;-->
<!--    line-height: 26px;-->
<!--  }-->
<!--  #buttons_row {-->
<!--    /*margin-top: 30px;*/-->
<!--  }-->
<!--  #the_hider {-->
<!--    margin-left: 20px;-->
<!--  }-->
<!--  #reply_comment_label {-->
<!--    font-size: 25px;-->
<!--    margin-top: 20px;-->
<!--    margin-bottom: 10px;-->
<!--  }-->
<!--  #reply_to_comment {-->
<!--    margin-bottom: 10px;-->
<!--  }-->
<!--  #reply_comment_input {-->
<!--    width: 670px;-->
<!--    border-radius: 10px;-->
<!--    font-size: 20px;-->
<!--    padding: 20px;-->
<!--  }-->
<!--  #reply_break_line {-->
<!--    border-top: 1px solid gray;-->
<!--    padding-top: 10px;-->
<!--    margin-top: 20px;-->
<!--  }-->
<!--  #post_reply_button {-->
<!--    margin-top: 20px;-->
<!--  }-->
<!--}-->
<!--</style>-->