<template>

  <div class="screen_block_no_shadows">
    <div class="flex_centered_row">
      <div class="flex_centered_row padding_t_20 label_regular " :id="label_id">
        {{ label }}
      </div>
    </div>

    <div class="flex_space_around_row flex_wrap padding_tb_5">

      <div class="flex_left_aligned_column">

        <drop_down_select class="padding_tb_10" ref="drop_down_child1"
                          :title="selection_title1"
                          :values="values1"
                          @value-selected="candidate1_selected"
        >
        </drop_down_select>

        <drop_down_select class="padding_tb_10" ref="drop_down_child2"
                          :title="selection_title2"
                          :values="values2"
                          @value-selected="candidate2_selected"
        >
        </drop_down_select>

      </div>


      <div class="flex_space_around_row">

         <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :state_disabled="!both_candidates_selected"
                       @toggle-state_true="armed_true"
                       @toggle-state_false="armed_false"
        ></basic_toggle>

        <basic_text_button
            class="margin_t_20 shadow_3d"
            :button_state=armed_and_both_candidates_selected
            :button_color="colors.new_cyanic_main"
            :button_color_not_ready="colors.gray"
            :button_not_ready_text_color="colors.white"
            :hover_color="hover_color"
            button_height="65px"
            button_width="65px"
            border_rad_pct="50%"
            text="Promote"
            :text_color="colors.black"
            text_size="13px"
            :bold="true"
            event_name="promotion_clicked"
            @promotion_clicked="emit_state"
        ></basic_text_button>


      </div>


    </div>
  </div>


</template>

<script>
import drop_down_select from "@/components/parts/drop_down_select";
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  colors
} from "@/library";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "select_and_launch",
  props: [
    'event_name',
    'ready_state',
    'label',
    'label_text_ready',
    'label_text_color_ready',
    'label_font_size_ready',
    'label_text_not_ready',
    'label_color_not_ready',
    'label_font_size_not_ready',
    'selection_title1',
    'selection_title2',
    'button_text',
    'values1',
    'values2',
    'hover_color'
  ],
  components: {
    basic_toggle,
    drop_down_select,
    basic_text_button
  },
  data() {
    return {
      launcher_armed_: false,
      candidate1_: "",
      candidate2_: "",

      colors: colors,
      label_id: "",
      second_id: "",
      third_id: "",
      fourth_id: "",
      label_text: ""
    }
  },
  async mounted() {
    try {
      this.label_id = uuidv4();
      this.second_id = uuidv4();
      this.third_id = uuidv4();
      this.fourth_id = uuidv4();

    } catch (error) {
      // console.error("Expected premature loading error in banner component.")
    }

  },
  async updated() {

    await this.$nextTick(function () {
      try {
        let the_label = document.getElementById(this.label_id)
        if (this.ready_state === true) {
          the_label.style.color = this.label_text_color_ready
          the_label.style.fontSize = this.label_font_size_ready
          this.label_text = this.label_text_ready
        } else {
          the_label.style.background = this.label_color_not_ready
          the_label.style.fontSize = this.label_font_size_not_ready
          this.label_text = this.label_text_not_ready
        }
      } catch (error) {
        // console.error(error)
      }

    })
  },
  methods: {

    emit_state() {
      console.log("inside dual select and launch emit state.")
      this.$emit(this.event_name, this.candidate1, this.candidate2)
    },

    toggle_arm_launcher() {
      this.launcher_armed_ = !this.launcher_armed_
    },
    armed_false(){
      this.launcher_armed_ = false
    },
    armed_true(){
      this.launcher_armed_ = true
    },

    candidate1_selected(state) {

      if (state === `---- ${this.selection_title1} ----`) {
        this.candidate1_ = ""
        this.$refs.toggle_child.toggle_state_to_false()
        this.armed_false()
      }else{
        this.candidate1_ = state
      }
    },
    candidate2_selected(state) {

      if (state === `---- ${this.selection_title2} ----`) {
        this.candidate2_ = ""
        this.$refs.toggle_child.toggle_state_to_false()
        this.armed_false()
      }else {
         this.candidate2_ = state
      }
    },

    launch() {
      // console.log("in Launch")
      this.$emit(this.event_name, this.candidate1, this.candidate2)
      this.reset()
      this.$refs.drop_down_child1.reset();
      this.$refs.drop_down_child2.reset();
      this.$refs.toggle_child.toggle_toggle();
      this.toggle_arm_launcher()

    },
    reset() {
      // console.log("inside select and launch reset")
      this.launcher_armed_ = false
      this.candidate1_ = ""
      this.candidate2_ = ""

    }

  },
  computed: {
    armed() {
      return this.launcher_armed_
    },
    candidate1() {
      return this.candidate1_
    },
    candidate2() {
      return this.candidate2_
    },
    both_candidates_selected(){
      if(this.candidate1 !== "" && this.candidate2 !== ""){
        return true
      }else{
        return false
      }
    },
    armed_and_both_candidates_selected(){
      if(this.both_candidates_selected && this.armed){
        return true
      }else{
        return false
      }
    },

  },
  watch: {}
}
</script>

<style scoped>

</style>