<template>

  <div class="flex_space_between_row  flex_align_items_center wid_100vw" id="banner">
     <div class="flex_centered_column banner_logo">
      <img src="@/assets/rabbit_1_line_edits.svg" id="top_rabbit_logo" @click="dual_route_wrapper('','page_top')"
           alt="Tiny Magic Hat Main Logo" class="cursor_ptr">
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  num_is_between_inclusive, colors, scroll_to_element, dual_route, reentry_route,
  good_token_now, analytic
} from "@/library";
import store from "@/store";

export default {
  name: "landing_banner",
  components: {
    universal_invisible_backer,
    basic_slide_over_menu,
    basic_icon_button,
    basic_text_button
  },
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  data() {
    return {
      colors: colors,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      button_color: colors.new_primary_purple_50,
      button_text_size_: "15px"
    }
  },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      analytic("01:01:01:01")
    },
    dual_route_wrapper(route, inner = null) {
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')

        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // console.log("size is greater that 500")
        // let e = document.getElementById('desktop_lnk')
        // console.log(e)
        // add_class_to_id('desktop_lnk', 'links')
      }


    },


  },
  computed: {
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_text_size() {
      return this.button_text_size_
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  #banner {
    height: 75px;
  }
   #about_link{
    margin-right: 20px;
  }

  #top_rabbit_logo {
    width: 150px;
    padding-left: 15px;
  }

   .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
  }

  #about_link {
    margin-right: 20px;
  }


}

@media (min-width: 400px) {
}

@media (min-width: 700px) {

}

@media (min-width: 1200px) {
  .banner_logo{
    margin-left: 30px;
  }
}


@media (min-width: 2000px) {
  #banner {
    height: 150px;
  }

  #top_rabbit_logo {
    width: 250px;
    padding-left: 20px;
  }

 .links {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 30px;
    text-decoration: none;
  }

   #about_link {
    margin-right: 40px;
  }
}

</style>