<template>
  <div class="flex_centered_row flex_wrap padding_b_5 backdrop font_raleway label font_weight_800">
    <div class="padding_t_10 label_regular margin_lr_10 margin_b_20 text_align_center">{{ label }}</div>
    <input v-if="!input_disabled" autofocus="autofocus" :type=i_type @focus="on_focus"  @blur="on_blur" class="margin_tb_10 text_input  text_align_center" v-model="text_value_"
           :placeholder=placeholder_text>
    <div v-if="input_disabled" class="margin_tb_10 text_input text_align_center padding_tb_5">{{ phone_format }}</div>

  </div>

</template>

<script>

export default {
  name: "label_with_input_text",
  props: [
    'label',
    'label_size',
    'bg_color',
    'label_color',
    'value_color',
    'value_bg_color',
    'value',
    'placeholder_text',
    'event_name',
    'present_event',
    'input_b_rad',
    'input_char_spacing',
    'input_size',
     'input_weight',
    'input_disabled',
    'input_type',
    'autofocus'
  ],
  components: {},
  async mounted() {
    try {

      let bg = this.$el
      let text_input = this.$el.querySelector('.text_input')
      if(this.autofocus){
        text_input.focus()
      }
      if(this.input_type){
        this.input_type_ = this.input_type
      }

      let label = this.$el.querySelector('.label_regular')

      bg.style.background = this.bg_color

      label.style.color = this.label_color
      label.style.fontSize = this.label_size

      text_input.style.background = this.value_bg_color
      text_input.style.color = this.value_color
      text_input.style.fontSize = this.input_size
      this.text_value_ = this.value
      text_input.style.borderRadius = this.input_b_rad
      text_input.style.letterSpacing = this.input_char_spacing
      text_input.style.fontWeight = this.input_weight
    } catch (error) {
      console.log(error)
    }


  },
  data() {
    return {
      text_value_: "",
      input_type_: "text"
    }
  },
  methods: {
    on_focus(){
      // console.log("This element has focus.")
      this.emit_focus()
    },
    on_blur(){
      // console.log("This element lost focus.")
      this.emit_blur()
    },
    emit_state() {
      this.$emit(this.event_name, this.text_value)
    },
    emit_focus(){
      // console.log("emit focus_active")
      this.$emit("focus_active", true)
    },
     emit_blur(){
      // console.log("emit blur_active")
      this.$emit("blur_active", true)
    },

  },
  computed: {
    i_type(){
      return this.input_type_
    },
    text_value() {
      // console.log("inside the return text value function")
      return this.text_value_
    },
    phone_format() {
      // console.log(`in phone format with ${this.text_value}, a ${typeof this.text_value}`)
      let num = this.text_value.toString()
      try {
        if (num === "") {
          return "No phone available"
        }
        let area_code = num.slice(0, 3)
        let exchange = num.slice(3, 6)
        let number = num.slice(6, 10)
        // console.log(`PHone formatter returning ${"(" + area_code + ")" + "" + exchange + "-" + number}`)
        return "(" + area_code + ")" + " " + exchange + "-" + number
      } catch (error) {
        // console.log(error)
        // console.log(`num was "${num}"`)
        return "Error"
      }


    },
  },
  watch: {
    text_value() {
      // console.log("inside text_value watcher")
      if (this.text_value !== "") {
        this.$emit(this.present_event, true)
      } else {
        this.$emit(this.present_event, false)
      }
    }
  }
}
</script>

<style scoped>

.big {
  font-size: 3rem;
}

.wide {
  width: 80%;
}

.input_width {
  width: 50px;
}

.text_input {
  width: 150px;
}



.backdrop {
}


/*.value {*/
/*  min-width: 3rem;*/
/*  font-weight: bold;*/
/*  font-size: .8rem;*/
/*  padding: .25rem;*/
/*  text-align: center;*/
/*  !*color: var(--light-text);*!*/
/*  margin-top: .5rem;*/
/*  font-family: 'Raleway', sans-serif;*/
/*  border-radius: 2em;*/
/*  background-color: gray;*/
/*  color: var(--cyanic-blue);*/
/*}*/

</style>