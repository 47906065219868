<template>

  <universal_pop_up_slot
      :show_popup="show_modal"
      @universal_popup_bg_clicked="bg_clicked"
      :backer_color="colors.dark_glass"
  >
    <video_dynamic
        :vimeo_id=video_id
        :base_height=base_height
        :base_width=base_width
    ></video_dynamic>

  </universal_pop_up_slot>

</template>

<script>
import {colors} from "@/library";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import video_dynamic from "@/components/parts/video_dynamic";

export default {
  name: "video_modal",
  emits: ["bg_clicked"],
  components: {
    universal_pop_up_slot,
    video_dynamic
  },
  props: ['show_modal', 'video_id', 'base_height', 'base_width'],
  data() {
    return {
      colors: colors,
    }
  },
  methods: {
    bg_clicked() {
      this.$emit('bg_clicked', true)
    },
  },
  computed: {
    // v_width() {
    //   return "1778px"
    // },
    // v_height() {
    //   return "1000px"
    //
    // },
  },
}
</script>

<style scoped>

</style>