<template>
  <div class="flex_centered_column  padding_b_5 backdrop">
    <div class="flex_space_around_row flex_grow">
      <div class="padding_t_10 label_regular margin_lr_10 margin_b_10 font_raleway" :id="id_label">{{ label }}</div>
      <basic_icon_button
          v-if="show_edit_button !== false"
          class="margin_b_10"
          :ready="read_only"
          :button_color="colors.new_cyanic_main"
          :button_color_not_ready="colors.transparent"
          button_height="35px"
          button_width="35px"
          border_rad_pct="50%"
          :image_path=pen_path
          image_width="18px"
          alt_text="Edit button"
          event_name="edit_clicked"
          @edit_clicked="edit_button_clicked"
      ></basic_icon_button>
    </div>
    <textarea class="padding_lr_6 font_raleway main_text_area" v-model="text_value_" @keyup="report_current"
              :placeholder="ph_text"
              :cols="columns"
              :rows="rows"
              :id="id_textarea"
              :readonly="read_only">

    </textarea>
  </div>

</template>

<script>
import {v4 as uuidv4} from "uuid";
import {
  colors,

} from "@/library";
import basic_icon_button from "@/components/parts/basic_icon_button";

export default {
  name: "label_with_input_text_area",
  props: [
    'label',
    'label_color',
    'label_text_size',
     'show_edit_button',

    'value_bg_color_ready',
    'value_bg_color_not_ready',
    'value_text_color_ready',
    'value_text_color_not_ready',
    'value_text_size_ready',
    'value_text_size_not_ready',


    'value',
    'rows',
    'columns',
    'placeholder_text',
    'placeholder_text_not_ready',
    'char_length_event',
    'event_name',
    'present_event',
    'ro_status'
  ],
  components: {
    basic_icon_button,
  },
  async mounted() {
    this.id_textarea = uuidv4();
    this.id_label = uuidv4();
    this.read_only_ = this.ro_status
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        let text_block = document.getElementById(this.id_textarea)
        let the_label = document.getElementById(this.id_label)
        if (this.read_only === false) {
          text_block.removeAttribute('readonly')
          text_block.style.background = this.value_bg_color_ready
          the_label.style.color = this.label_color
          text_block.style.color = this.value_text_color_ready
          if(this.placeholder_text){
            this.ph_text_ = this.placeholder_text
          }else{
            this.ph_text_ = `This message will be published to the caregivers along with your FreeTime bonus.`
          }

        } else {
          text_block.setAttribute('readonly', "true")

          if(this.placeholder_text_not_ready){
            this.ph_text_ = this.placeholder_text_not_ready
          }else{
            this.ph_text_ = `Click the pencil icon to start editing this message.`
          }


          this.ph_text_ = `Click the pencil icon to start editing this message.`

          text_block.style.background = this.value_bg_color_not_ready
          the_label.style.color = this.label_color
          text_block.style.color = this.value_text_color_not_ready
        }
      })
    }
    catch
      (error)
      {
        console.error(error)
      }
  },
  data() {
    return {
      id_textarea: "",
      id_label: "",
      ph_text_: "",
      edit_button_ready_: true,
      colors: colors,
      text_value_: this.value,
      read_only_: "",
    }
  },
  methods: {
    tester() {
      console.log("edit button clicked.")
    },
    reset(){
      this.text_value_ = ""
    },

    report_current(){
      try{
        this.$emit(this.char_length_event, this.text_value)
      }catch(error){
        //pass
      }

    },

    emit_state() {
      this.$emit(this.event_name, this.text_value)
    },
    get_text_value(){
      return this.text_value
    },
    edit_button_clicked(){
      this.toggle_read_only()
      this.$emit("edit_button_clicked", true)


    },
    toggle_read_only() {
      try {
        // console.log(`toggling read only to ${!this.read_only_}`)
        let text_block = document.getElementById(this.id_textarea)
        if (this.read_only === true) {
          text_block.removeAttribute('readonly')
          text_block.style.background = this.value_bg_color_ready
          this.read_only_ = false
        } else {
          text_block.setAttribute('readonly', "true")
          text_block.style.background = this.value_bg_color_not_ready
          this.read_only_ = true
        }
      } catch (error) {
        console.error(error)
      }
    },
    // toggle_icon_ready_button(){
    //   this.icon_button_ready_ = !this.icon_button_ready_
    //.
    // },

  },
  computed: {
    text_value() {
      return this.text_value_
    },
    read_only() {
      return this.read_only_
    },
    ph_text() {
      return this.ph_text_
    },
    // icon_button_ready(){
    //   return this.icon_button_ready_
    // },
    pen_path() {
      if (this.read_only === true) {
        return 'edit-pen_dark.svg'
      } else {
        return 'edit-pen_light.svg'
      }
    },


  },
  watch: {
    // text_value() {
    //   // console.log("inside text_value watcher")
    //   if (this.text_value !== "") {
    //     this.$emit(this.present_event, true)
    //   } else {
    //     this.$emit(this.present_event, false)
    //   }
    // }
  }
}
</script>

<style scoped>

.main_text_area{
    white-space: pre-line;
}

.big {
  font-size: 3rem;
}

.wide {
  width: 80%;
}

.input_width {
  width: 50px;
}

.text_input {

}

.backdrop {
}


.value {
  min-width: 3rem;
  font-weight: bold;
  font-size: .8rem;
  padding: .25rem;
  text-align: center;
  /*color: var(--light-text);*/
  margin-top: .5rem;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  background-color: gray;
  color: var(--cyanic-blue);
}

</style>