<template>


  <section class="flex_left_aligned_column font_raleway font_weight_800">
    <div class="flex_start_row admin_button_row">

      <basic_text_button
          v-if="thread_open && user_is_admin"
          class="margin_lr_10"
          :button_state="true"
          :button_color="colors.pennie_gray"
          :text_color="colors.eli_gray"
          :button_height="other_buttons_h"
          :button_width="other_buttons_w"
          border_rad_pct="5px"
          :text="remove_button_text"
          :text_size="other_buttons_text_size"
          :bold="true"
          event_name="close_thread"
          @close_thread="remove_thread"
      ></basic_text_button>
      <basic_text_button
          v-if="thread_open && user_is_admin"
          class="margin_lr_10"
          :button_state="true"
          :button_color="colors.pennie_gray"
          :text_color="colors.eli_gray"
          :button_height="other_buttons_h"
          :button_width="other_buttons_w"
          border_rad_pct="5px"
          :text="sticky_text"
          :text_size="other_buttons_text_size"
          :bold="true"
          event_name="close_thread"
          @close_thread="toggle_sticky"
      ></basic_text_button>

    </div>
    <div class="flex_start_row ">
      <div class="flex_start_column">
        <basic_text_button
            v-if="!thread_open"
            class=""
            :button_state="true"
            :button_color=collapse_color_closed.bg
            :text_color="collapse_color_closed.text"
            :button_height="plus_minus_size"
            :button_width="plus_minus_size"
            border_rad_pct="50%"
            text="+"
            text_size="20px"
            :bold="true"
            event_name="open_thread"
            @open_thread="thread_open_true"
        ></basic_text_button>
        <basic_text_button
            v-if="thread_open"
            class=""
            :button_state="true"
            :button_color=collapse_color_open.bg
            :text_color="collapse_color_open.text"
            :button_height="plus_minus_size"
            :button_width="plus_minus_size"
            border_rad_pct="50%"
            text="-"
            text_size="20px"
            :bold="true"
            event_name="close_thread"
            @close_thread="thread_open_false"
        ></basic_text_button>
      </div>
      <div class="thread_title flex_centered_column">{{ title }}</div>
      <!--      <div class="flex_centered_column margin_l_20">-->
      <!--        <span class="material-symbols-outlined material_icon">schedule</span>-->
      <!--      </div>-->
      <div class="material_icon_bg  flex_centered_row">
        <div class="flex_centered_column">
          <img class="material_icon"
               :src="image_name('schedule.svg')"
               :alt=desc>
        </div>
      </div>
      <div class="flex_centered_column margin_r_20">{{ time_since }}</div>
      <div v-if="!mobile_mode" class="flex_centered_row">
        <!--        <div class="flex_centered_column">-->
        <!--          <span class="material-symbols-outlined material_icon">quick_phrases</span>-->
        <!--        </div>-->
        <div class="material_icon_bg  flex_centered_row">
          <div class="flex_centered_column">
            <img class="material_icon"
                 :src="image_name('quick_phrases.svg')"
                 alt="Reply button">
          </div>

        </div>
        <div class="flex_centered_column">replies {{ number_of_replies }}</div>
      </div>
    </div>
    <div>
      <div v-if="thread_open">
        <div v-for="post in list_of_posts" :key="post.message">

                    <forum_post
                        class="forum_post"
                        :posting_user=post.creator
                        :ts_string=to_hours_since_wrapper(post.timestamp)
                        :comment_content=post.post_text
                        :indent_level=post.tabcount
                        :hidden="post.post_hidden"
                        :poster_is_admin=post.admin
                        :user_is_admin=this.user_is_admin
                        :ft_hours=post.ft_hours
                        :joined_date=post.joined
                        :likes_obj=post.likes
                        :post_id_num="post.post_id"
                        :thread_id="post.thread_id"
                        :parent_id="post.parent_id"
                        @create_and_post_reply="create_and_post_reply"
                        @register_like="register_like"
                        @hide_post="hide_post"
                    ></forum_post>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
/* eslint-disable */
import basic_text_button from "@/components/parts/basic_text_button";
import forum_post from "@/components/basics/forum_post";
import {
  colors,
  num_is_between_inclusive,
  translate_unix_timestamp_to_datestring,
  to_hours_since
} from "@/library";
import store from "@/store";

export default {
  name: "comment_thread",
  props: ['title', 'list_of_posts', 'sticky', 'thread_id', 'user_is_admin', 'thread_timestamp', 'desc', 'icon'],
  components: {
    basic_text_button,
    forum_post
  },
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  unmounted() {
  },
  data() {
    return {
      colors: colors,
      thread_open_: false,
      plus_minus_size_: 50,
      other_buttons_h_: 50,
      other_buttons_w_: 125,
      other_buttons_text_size_: 20,
      remove_button_text_: "REMOVE",
      sticky_text_: "Toggle Sticky",
      material_icon_name: "",

      mobile_mode_x: true,
      desktop_cutoff: 800,
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    image_name(icon_file) {
      return require(`@/assets/${icon_file}`);
    },

    register_like(value) {
      console.log("emit register like from comment-thread")
      this.$emit('register_like', value)
    },
    toggle_sticky() {
      this.$emit('toggle_sticky', true)
    },
    async create_and_post_reply(data) {
      this.$emit('create_and_post_reply', data)
    },

    hide_post(out) {
      console.log("hide post at comment thread.")
      this.$emit('hide_post', out)
    },

    async remove_thread() {
      console.log(`Thread ID from form comment thread bubble up: ${this.thread_id}`)
      this.$emit('remove_thread', this.thread_id,)
    },

    ts_string_wrapper(ts) {
      // console.log(`In date wrapper with ${ts}`)
      return translate_unix_timestamp_to_datestring(ts * 1000, 3)
    },

    to_hours_since_wrapper(ts) {
      console.log(`ts: ${ts}: type is ${typeof ts}............................`)
      return to_hours_since(parseFloat(ts) * 1000, true) + " ago"
    },

    thread_open_true() {
      this.thread_open_ = true
    },
    thread_open_false() {
      this.thread_open_ = false
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        this.plus_minus_size_ = 25
        this.other_buttons_h_ = 25
        this.other_buttons_w_ = 30
        this.other_buttons_text_size_ = 15
        this.remove_button_text_ = "R"
        this.sticky_text_ = "TS"

        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 1199, new_w)) {
        // console.log("comment thread 300 to 500")
        this.plus_minus_size_ = 25
        this.other_buttons_h_ = 25
        this.other_buttons_w_ = 30
        this.other_buttons_text_size_ = 15
        this.remove_button_text_ = "R"
        this.sticky_text_ = "TS"
      }
      if (num_is_between_inclusive(1200, 5000, new_w)) {
        this.plus_minus_size_ = 50
        this.other_buttons_h_ = 50
        this.other_buttons_w_ = 125
        this.other_buttons_text_size_ = 18
        this.remove_button_text_ = "REMOVE"
        this.sticky_text_ = "Toggle Sticky"
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },
  },
  computed: {


    mobile_mode() {
      return this.mobile_mode_x
    },
    number_of_replies() {
      return this.list_of_posts.length - 1
    },


    time_since() {
      // console.log(`Thread time stamp is ${this.thread_timestamp} type ${typeof  this.thread_timestamp}`)
      return to_hours_since(parseFloat(this.thread_timestamp) * 1000, true) + " ago"
    },


    thread_open() {
      return this.thread_open_
    },

    collapse_color_open() {
      if (this.sticky_thread) {
        return {
          text: colors.white,
          bg: colors.primary_purple
        }
      } else {
        return {
          text: colors.white,
          bg: colors.cyanic_blue
        }
      }
    },
    collapse_color_closed() {
      if (this.sticky_thread) {
        return {
          text: colors.white,
          bg: colors.new_primary_purple_light
        }
      } else {
        return {
          text: colors.white,
          bg: colors.extra_light_purple
        }
      }
    },

    plus_minus_size() {
      return this.plus_minus_size_ + 'px'
    },
    other_buttons_h() {
      return this.other_buttons_h_ + 'px'
    },
    other_buttons_w() {
      return this.other_buttons_w_ + 'px'
    },
    other_buttons_text_size() {
      return this.other_buttons_text_size_ + 'px'
    },
    remove_button_text() {
      return this.remove_button_text_
    },
    sticky_text() {
      return this.sticky_text_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    sticky_thread() {

      if (this.sticky === 1) {
        return true
      } else {
        return false
      }

    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>


@media (min-width: 100px) {

  .forum_post {
    margin-bottom: 30px;
  }

  .admin_button_row {
    margin-bottom: 10px;
  }

  .material_icon {
    color: var(--morgie_gray);
    height: 20px;
    margin-right: 5px;
  }

  .material_icon_bg {
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }

  .thread_title {
    font-size: 15px;
    margin-left: 10px;
    margin-right: 5px;
    font-weight: 800;
  }
}

@media (min-width: 400px) {

  .material_icon {
    height: 30px;
  }

  .thread_title {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
}


@media (min-width: 1200px) {

  .admin_button_row {
    margin-bottom: 15px;
  }


  .thread_title_container {
    margin-bottom: 20px;

    /*margin-left: 20px;*/
  }

  .forum_thread {
    /*width: 670px;*/
    margin-bottom: 30px;
  }

  .thread_title {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 20px;
  }
}

</style>