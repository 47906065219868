<template>

  <universal_pop_up_slot
      :show_popup="continues_available"
      @universal_popup_bg_clicked="cancel_clicked"
      :backer_color="colors.near_transparent"
  >

    <section>
      <div class="flex_start_row notification_box font_raleway font_weight_800 z_500 cursor_ptr ">
        <div class="flex_centered_column">
           <img class="material_icon"
                         :src="image_name('tips_and_updates.svg')"
                         alt="Notification Icon">
        </div>

        <div class="flex_centered_column scrollable_div_with_scrollbar">
          <div class="flex_centered_row"><span v-html="continue_peek"></span></div>
          <div class="flex_space_around_row buttons_row">

             <basic_text_button
              class="padding_lr_6 padding_tb_6 bottom_button z_500"
              :hover_color=colors.new_orange_main_light
              :hover_text_color=colors.black
              :button_state="true"
              :button_color="colors.primary_purple"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              :button_height=button_height
              :button_width=button_width
              border_rad_pct="0"
              text="Cancel"
              text_size="12px"
              :bold="true"
              event_name="cancel_clicked"
              @cancel_clicked=cancel_clicked
          ></basic_text_button>
             <basic_text_button
              class="padding_lr_6 padding_tb_6 bottom_button z_500"
              :hover_color=colors.new_orange_main_light
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.black
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              :button_height=button_height
              :button_width=button_width
              border_rad_pct="0"
              text="Yes"
              text_size="12px"
              :bold="true"
              event_name="continue_clicked"
              @continue_clicked=continue_clicked
          ></basic_text_button>

          </div>
        </div>
      </div>
    </section>
  </universal_pop_up_slot>


</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {Database} from "@/client_db";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import basic_text_button from "@/components/parts/basic_text_button";
import {colors, image_name} from "@/library";
export default {
  name: "continue_enqueue",
  beforeMount() {
    // console.log("Running beforeMount an notification popup component")
    this.db = new Database();
    store.dispatch('continue_restart')
    this.run_window_width_adjustments(this.window_width)
  },
  emits: ['continue_clicked'],
  components: {
    universal_pop_up_slot,
    basic_text_button
  },
  data() {
    return {
      colors: colors,
      button_width_: "",
      button_height_: ""
    }
  },
  methods: {
    do_nothing(){
      console.log("Continue enqueue do nothing was triggered.")
    },
    continue_clicked(){
      console.log(`You clicked to continue on ${this.continue_peek}`)
      console.log(this.continue_peek_payload)
      this.$emit('continue_clicked', this.continue_peek_payload)
      this.local_dequeue_continue_message()
    },
    cancel_clicked(){
      console.log("You clicked to cancel.")
      this.local_dequeue_continue_message()
    },
     image_name(name){
      return image_name(name)
    },
    local_dequeue_continue_message() {
      store.dispatch('continue_dequeue')
    },
     run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // console.log(`New size is ${new_w}`)
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "50px"
        this.button_img_size_ = "50px"
      }
      if (new_w > 500) {
        // console.log(`New size is ${new_w}`)
        this.button_width_ = "60px"
        this.button_height_ = "25px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
         this.button_width_ = "70px"
        this.button_height_ = "25px"
      }

      if (new_w > 1200) {
        // console.log(`New size is ${new_w}`)
        this.button_width_ = "120px"
        this.button_height_ = "30px"
      }

    },
  },
  watch: {
    window_width(new_w){
      this.run_window_width_adjustments(new_w)
    },
  },
  computed: {
    ...mapGetters([
        "window_width",
      'continue_peek',
      'continue_peek_payload',
      'continue_length'
    ]),
    button_width(){
      return this.button_width_
    },
    button_height(){
      return this.button_height_
    },
    continues_available() {
      if (this.continue_length) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped>

.notification_box{
  /*max-height: 30vh;*/
  /*height: 50vh;*/
}



@media (min-width: 100px) {

  .buttons_row{
    width: 105%;
    margin-top: 20px;
  }

  .notification_box {
    background-color: var(--continue-color);
    /*border: 3px solid black;*/
    /*padding-bottom: 20px;*/
    /*padding-top: 20px;*/
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    padding: 20px;
    width: 300px;
    box-sizing: border-box;
    max-width: 99vw;
    /*height: 300px;*/
  }

  .material_icon {
    /*font-size: 50px;*/
    top: -3%;
    //border-radius: 50%;
    margin-right: 20px;
    /*background-color: var(--primary-purple);*/
  }
}


@media (min-width: 500px) {
    .notification_box {
    width: 300px;
  }

}
@media (min-width: 700px) {

  .notification_box{
    padding: 30px;
  }
}

@media (min-width: 800px) {
  .buttons_row{
    width: 110%;
    margin-top: 40px;
  }
}


@media (min-width: 1000px) {
  .buttons_row{
    width: 110%;
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .notification_box {
    width: 500px;
    font-size: 20px;
    /*padding-top: 40px;*/
    /*padding-bottom: 40px;*/
    padding: 50px;
  }

  .material_icon {
    font-size: 80px;
    top: -3%;
    //border-radius: 50%;
    padding-right: 20px;
  }

}
</style>