<template>

  <section class=" mfa_register flex_centered_start_column core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>

    <div class="flex_centered_column over_column">
      <div class="flex_fully_centered_column margin_t_40 main_column">
        <h4 class="margin_b_20 login text_align_center">Secure Sign Up</h4>

        <div class="flex_centered_row opt_in_row">
          <div class="flex_centered_column" @click="toggle_authenticator_yes">
            <div v-if="!authenticator_yes" class="check_box shadow_inner cursor_ptr"></div>
            <div v-if="authenticator_yes">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer_opt_in"></div>
          <p v-if="authenticator_yes" class="opt_in_text_yes">{{ authenticator_message }}</p>
          <p v-if="!authenticator_yes" class="opt_in_text_no">{{ authenticator_message }}</p>

        </div>

        <div class="flex_centered_row opt_in_row">
          <div class="flex_centered_column" @click="toggle_separate_device_yes">
            <div v-if="!separate_device_yes" class="check_box shadow_inner cursor_ptr"></div>
            <div v-if="separate_device_yes">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer_opt_in"></div>
          <p v-if="separate_device_yes" class="opt_in_text_yes">{{ separate_device_message }}</p>
          <p v-if="!separate_device_yes" class="opt_in_text_no">{{ separate_device_message }}</p>

        </div>

        <div class="flex_centered_row opt_in_row">
          <div class="flex_centered_column" @click="toggle_mobile_phone_yes">
            <div v-if="!mobile_phone_yes" class="check_box shadow_inner cursor_ptr"></div>
            <div v-if="mobile_phone_yes">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer_opt_in"></div>
          <p v-if="mobile_phone_yes" class="opt_in_text_yes">{{ mobile_phone_message }}</p>
          <p v-if="!mobile_phone_yes" class="opt_in_text_no">{{ mobile_phone_message }}</p>

        </div>



        <input_with_label
            ref="register_name_box"
            class="login_input margin_t_30"
            label="Create User Name"
            @emit_state="catch_username"
        ></input_with_label>


        <input_with_label
            ref="register_password_box"
            class="login_input"
            label="Create Password"
            @emit_state="catch_password"
        ></input_with_label>


        <input_with_label
            ref="register_email_box"
            class="login_input"
            label="Your email address"
            @emit_state="catch_email"
        ></input_with_label>


        <input_with_label
            ref="register_phone_number_box"
            class="login_input"
            label="Your mobile phone number"
            @emit_state="catch_phone_number"
        ></input_with_label>


        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state=all_opt_ins
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="SIGN UP"
            text_size="14px"
            :bold="true"
            event_name="register_button_clicked"
            @register_button_clicked="register"
            v-on:keyup.enter="register"
            tabindex="0"
        ></basic_text_button>

        <div class="flex_centered_row opt_in_row">
          <div class="flex_centered_column" @click="toggle_tos_opt_in">
            <div v-if="!tos_opt_in" class="check_box shadow_inner cursor_ptr"></div>
            <div v-if="tos_opt_in">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer_opt_in"></div>
          <p v-if="tos_opt_in" class="opt_in_text_yes">I agree to Tiny Magic Hat's updated
            <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple font_weight_800">Terms of Service</span>.
          </p>
          <p v-if="!tos_opt_in" class="opt_in_text_no">I agree to Tiny Magic Hat's updated
            <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple ">Terms of Service</span>.</p>

        </div>

      </div>


      <div class="flex_space_between_row  " id="links_row">
        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_register_confirm")'>Confirm Sign
          Up
        </div>
        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_verify_totp")'>Secure
          Verification
        </div>
      </div>

    </div>

  </section>
  <outside_footer class="footer_c">
  </outside_footer>
</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {
  parse_internal_json,
  be_main_address,
  add_class_to_body,
  validate_phonenumber,
  validate_email,
  remove_class_to_body,
  scroll_to_top, validate_basic_name
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";
import {
  colors
} from "@/library";

export default {
  components: {
    notification_popup,
    basic_text_button,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label
  },
  data() {
    return {
      colors: colors,
      name: '',
      password: '',
      email: '',
      phone_number: '',
      next_route: '',
      all_opt_ins_: false,
      tos_opt_in_: false,
      authenticator_yes_: false,
      mobile_phone_yes_: false,
      separate_device_yes_: false,
      authenticator_message: "I have Google Authenticator or some like software installed on my phone.",
      separate_device_message: "I am registering this account from device other than my primary smart phone, like a laptop or desktop computer.",
      mobile_phone_message: "I have a smart phone available at this time.",
    }
  },
  mounted() {
    // let m = "Secure Sign Up requires the use of a separate authenticator app like Google Authenticator " +
    //     "or something similar that can read QR codes and provide TOTP codes.  If you are not sure what these are, " +
    //     "you should use the regular sign up option."
    scroll_to_top()
    let m = "Secure sign up requires you to use your mobile phone camera to scan a QR code that will be " +
        "shown on the screen during the registration process, therefore you will need to use a " +
        "separate device other than your phone to register your account using this method."
    store.commit('notification_enqueue', m)

  },
  unmounted() {
    remove_class_to_body('bg_white')
  },
  created() {
    add_class_to_body('bg_white')
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  computed: {
    all_opt_ins(){
      return this.tos_opt_in &&
          this.authenticator_yes &&
          this.separate_device_yes &&
          this.mobile_phone_yes &&
          this.phone_number_present &&
          this.email_present &&
          this.password_present &&
          this.name_present

    },
    tos_opt_in() {
      return this.tos_opt_in_
    },
    authenticator_yes() {
      return this.authenticator_yes_
    },
    mobile_phone_yes() {
      return this.mobile_phone_yes_
    },
    separate_device_yes() {
      return this.separate_device_yes_
    },
    email_present() {
      if(this.email.length > 0){
        return true
      }else{
        return false
      }
    },
    name_present() {
      if(this.name.length > 0){
        return true
      }else{
        return false
      }
    },
    password_present() {
      if(this.password.length > 0){
        return true
      }else{
        return false
      }
    },
     phone_number_present() {
      if(this.phone_number.length > 0){
        return true
      }else{
        return false
      }
    },
  },
  methods: {
    tester() {
      // console.log("button was pressed.")
    },
    toggle_tos_opt_in() {
      this.tos_opt_in_ = !this.tos_opt_in_
    },

    toggle_mobile_phone_yes() {
      this.mobile_phone_yes_ = !this.mobile_phone_yes_
    },

    toggle_separate_device_yes() {
      this.separate_device_yes_ = !this.separate_device_yes_
    },

    toggle_authenticator_yes() {
      this.authenticator_yes_ = !this.authenticator_yes_
    },


    catch_password(pw) {
      this.password = pw.trim()
      store.commit('save_password', this.password)
    },
    catch_email(em) {
      let email = em.trim()
      // validate
      if (validate_email(email)) {
        this.email = email
      } else {
        let m = email + "is not valid."
        store.commit('error_enqueue', m)
      }
    },
    catch_phone_number(phone_number) {
      let phone = phone_number.trim()
      //  remove all non-digits
      phone = phone.replace(/\D/g, '');
      // validate
      if (validate_phonenumber(phone)) {
        this.phone_number = phone
      } else {
        let m = phone_number + " is not valid."
        store.commit('error_enqueue', m)
      }
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
       if(!validate_basic_name(name)){
        store.commit('error_enqueue', 'User names may only contain numbers, letters and underscores.')
        return null
      }
      store.commit('save_name', this.name)
    },
    simple_route_prep(r) {
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async register() {
      // console.log("Getting ready to launch register...")
      // let the_router = this.$router

      this.$refs.register_name_box.emit_state()
      this.$refs.register_password_box.emit_state()
      this.$refs.register_phone_number_box.emit_state()
      this.$refs.register_email_box.emit_state()

      let error_out
      if (this.name.length === 0) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must provide a user name to register.")
        error_out = true
      }
      if (this.password.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a password to register.")
        error_out = true
      }
      if (this.email.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a email to register for MFA.")
        error_out = true
      }
      if (this.phone_number.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a phone number to register.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "mfa_create_new_user"
      await axios.post(target, {
        name: this.name,
        password: this.password,
        phone_number: this.phone_number,
        email: this.email,
        tos_agree: this.tos_opt_in
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad registration. Check name, password, phone and email.")
          console.log(error)
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          error_out = true
          return null
        }
      }).then((response) => {
            // console.log("Got good response to mfa register.");
            // console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              this.$store.commit('save_name', this.name)
              this.$store.commit('save_email', this.email)
              this.$store.commit('save_phone', this.phone_number)
              this.name = ''
              this.phone_number = ''
              this.password = ''
              this.$router.push('/mfa_register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },

    async process_asset_package(asset_package) {
      // console.log("inside processing asset package.")

      //save jwt authentication token in vuex
      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])
      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      // console.log("done with processing the asset package")
    },
  }
}
</script>

<style scoped>
@import '../../common.css';


.main_column {
  max-width: 200px;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}

@media (min-width: 100px) {

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
    max-width: 85vw;
  }

  .spacer_opt_in {
    width: 20px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_row p {
    font-size: 18px;
  }


  .opt_in_text_no {
    width: 200px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }


  .over_column {
    width: 75%;
  }


  .main_column {
    max-width: 75%;
  }

}

@media (min-width: 300px) {
  .main_column {
    max-width: 200px;
  }

}

@media (min-width: 500px) {

}

@media (min-width: 770px) {


  .hat_size {
    width: 182px;
    margin-top: 100px;
  }

  .big_spacer {
    margin-left: 50px;
    margin-right: 50px;
  }

}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}

@media (min-width: 1200px) {

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 360px;
  }

  .spacer_opt_in {
    width: 30px;
  }

  .opt_in_text_yes {
    width: 350px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 350px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }

  h4{
    font-size: 30px;
  }

}

</style>