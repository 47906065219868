<template>
  <div class="core_c">

    <section>
       <h5 class="margin_b_10">Ad Click Statistics Last 7 Days</h5>
       <div class="outline padding_tb_40">
         <p class="font_weight_800 font_raleway margin_b_10">Market page clicks: {{clicks_data['market_clicks']}}</p>
         <p class="font_weight_800 font_raleway margin_b_10">Your discount registration page clicks: {{clicks_data['agency_clicks']}}</p>
       </div>
    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";

export default {
  name: "click_stats_main",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
      "clicks_data",
  ],
  emits: ['name_of_event'],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      data1: null,
      data2: null
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w){
        if(new_w > this.max_mobile_size){
            return false
        }else{
            return true
        }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
computed: {
...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
      id() {
        return this.id_
      },
      spinner_up() {
        return store.getters.get_spinner_state
      },
      good_token() {
        return good_token_now()
      },

      mobile_mode() {
        return this.mobile_mode_x
      },

      button_height(){
        return this.button_height_
      },
      button_width(){
        return this.button_width_
      },
    },
watch: {
      window_width(new_w) {
        this.run_window_width_adjustments(new_w)
      },
      async socket_connection_counter(old_val, new_val) {
         await prime_socket(this.local_message_handler)
    },
    },
}
</script>
<style scoped>
@import '../../../common.css';
@media (min-width: 100px) {
   .outline {
    border: 1px solid var(--black);
    padding: 40px;

  }
  h1 {

  }
  h2 {

  }
  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }
  h2 {

  }
  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }
  h2 {

  }
  h3 {

  }
}

@media (min-width: 2000px) {
}
h1 {

  }
  h2 {

  }
  h3 {

  }
</style>