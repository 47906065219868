<template>
  <div class="core_c">

    <section>
      <editing_controls_main
        @text_update="text_update"
        @logo_update="logo_update"
        @photo_update="photo_update"
        @caption_update="caption_update"
        @hours_update="hours_update"
        :current_data=current_data
      ></editing_controls_main>
      <editing_viewer_main
         :body_text="body"
         :headline_text="headline"
         :caption_text=caption
         :hours=hours
         :weighted_avg=current_data.weighted_avg
         :base_rate=current_data.base_rate
         :agency_photo="current_data.photo"
         :agency_since="current_data.since"

      ></editing_viewer_main>
      <div class="flex_centered_row  ">
        <basic_text_button
            class="padding_lr_6 padding_tb_6 margin_b_40"
            :hover_color=colors.primary_purple
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_cyanic_mid"
            :text_color=colors.darker_gray
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="62px"
            button_width="220px"
            border_rad_pct="0"
            text="Save and Publish Changes"
            text_size="17px"
            :bold="true"
            event_name="save_changes"
            @save_changes="save_changes"
        ></basic_text_button>
      </div>
    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";
import Editing_controls_main from "@/components/Vortex/editing_pages/editing_controls/editing_controls_main.vue";
import Editing_viewer_main from "@/components/Vortex/editing_pages/editing_viewer/editing_viewer_main.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";

export default {
  name: "editing_main",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text","current_data"
  ],
  emits: ['save_and_publish', 'text_update', 'logo_update', 'photo_update', 'caption_update', 'hours_update'],
  components: {
    basic_text_button,
    Editing_viewer_main,
    Editing_controls_main,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      headline_: "",
      body_: "",
      caption_: "",
      hours_: "",
      weighted_avg_: "",
      agency_photo_: ""
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    save_changes(){
       this.$emit("save_and_publish", true)
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    text_update(e){
      console.log("editing main level")
      console.log(e)
      this.headline_ = e['headline']
      this.body_ = e['body']
      this.$emit("text_update", e)
    },
     photo_update(e){
      this.$emit("photo_update", e)
    },
    hours_update(e){
      console.log("At editing_main hours update catcher")
      console.log(e)
      this.hours_ = e
      this.$emit("hours_update", e)
    },
     caption_update(e){
      console.log("At editing_main caption update catcher")
      console.log(e)
      this.caption_ = e
      this.$emit("caption_update", e)
    },
     logo_update(e){
      this.$emit("logo_update", e)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    body(){
      return this.body_
    },
    headline(){
      return this.headline_
    },
    caption(){
      return this.caption_
    },
    weighted_avg(){
      return this.weighted_avg_
    },
    agency_photo(){
      return this.agency_photo_
    },
    hours(){
      return this.hours_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>