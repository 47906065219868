<template>
  <div class="b_box_all">
    <div class="gbox1 ">
      <slot name="box1"></slot>
    </div>
    <div class="gbox2 ">
      <slot name="box2"></slot>
    </div>
    <div class="gbox3 ">
      <slot name="box3"></slot>
    </div>
    <div class="gbox4 ">
      <slot name="box4"></slot>
    </div>
    <div class="gbox5 ">
      <slot name="box5"></slot>
    </div>
    <div class="gbox6 ">
      <slot name="box6"></slot>
    </div>
    <div class="gbox7 ">
      <slot name="box7"></slot>
    </div>
    <div class="gbox8 ">
      <slot name="box8"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "outside_bounce_box",
}
</script>

<style scoped>
@media (min-width: 100px) {
  .b_box_all {
    display: grid;
    /*width: 100%;*/
    grid-template-columns: 1fr;
    /*grid-template-areas: "box1 box2"*/
    /*                     "box3 box4"*/
    /*                     "box5 box6;*/
    /*                     "box7 box8;*/

     grid-template-areas: "box1"
                          "box2"
                          "box4"
                          "box3"
                          "box5"
                          "box6"
                          "box8"
                          "box7";
  }




  .gbox1 {
    grid-area: box1;
  }
  .gbox2{
    grid-area: box2;
  }
  .gbox3 {
    grid-area: box3;
  }
  .gbox4 {
    grid-area: box4;
  }
  .gbox5 {
    grid-area: box5;
  }
  .gbox6 {
    grid-area: box6;
  }
  .gbox7 {
    grid-area: box7;
  }
  .gbox8 {
    grid-area: box8;
  }
}

@media (min-width: 720px) {
  .b_box_all {

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "box1 box2"
                         "box3 box4"
                         "box5 box6"
                         "box7 box8";
  }

  .gbox1 {
    grid-area: box1;
    margin-right: 15px;
  }
  .gbox2{
    grid-area: box2;
  }
  .gbox3 {
    grid-area: box3;
  }
  .gbox4 {
    grid-area: box4;
  }
  .gbox5 {
    grid-area: box5;
  }
  .gbox6 {
    grid-area: box6;
  }
  .gbox7 {
    grid-area: box7;
  }
  .gbox8 {
    grid-area: box8;
  }
}

</style>