<template>
  <module_main_page
      module_download_name="a01649eb-1a65-43e0-82ee-97e545617f17"
      module_logo_name="sd_copd.svg"
      :module_desc_text=module_text
      module_headline="Understanding COPD"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "copd",
  data() {
    return {
      module_text: "Chronic Obstructive Pulmonary Disease (COPD) is a progressive lung condition characterized by " +
          "chronic inflammation and airflow obstruction, primarily caused by long-term exposure to irritants, " +
          "most commonly cigarette smoke. COPD includes two main conditions, chronic bronchitis and emphysema, " +
          "both of which lead to breathing difficulties. Caregivers should be aware that COPD is a chronic condition " +
          "that can worsen over time. Caregivers need to understand the client's specific COPD diagnosis, " +
          "prescribed medications, and the importance of adherence to treatment plans.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



