<template>
  <div class="flex_centered_row flex_wrap wide padding_b_5 backdrop font_raleway label_regular font_weight_800" :id="the_whole">
    <div class="padding_t_10 label_regular margin_lr_10 margin_b_10" :id="the_label">{{ label }}</div>
    <div v-if="value" class="padding_t_10 margin_b_10 value font_raleway" :id="the_value">{{ value }}</div>
  </div>

</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "label_value_non_editable",
  props: [
    'label',
    'label_font_size',
    'value',
    'bg_color',
    'label_color',
    'value_color',
    'value_bg_color',
    'value_width',
    'value_font_size',
    'letter_spacing_px'
  ],
  data() {
    return {
      the_label: "",
      the_value: "",
      the_whole: "",
    }
  },
  async mounted() {

    try {
      this.the_label = uuidv4();
      this.the_value = uuidv4();
      this.the_whole = uuidv4();
      // console.log("running mounted")
      // console.log("starting value is...")
      // console.log(typeof this.value)
      // console.log(this.value)

      let the_whole = document.getElementById(this.the_whole)
      let the_label = document.getElementById(this.the_label)
      // let bg = this.$el
      // let label = this.$el.querySelector('.label_regular')
      the_whole.style.background = this.bg_color

      the_label.style.color = this.label_color
      //
      // value.style.background = this.value_bg_color
      // value.style.color = this.value_color
      // value.style.width = this.value_width
      // // value.style.height = "18px"
      // value.style.fontSize = "18px"
    } catch (error) {
      // console.error("expected premature loading error.")
      // console.error(error)
    }


  },
  async updated() {
    try {
      // console.log(`The new value is ${this.value}`)
      // console.log(`The new value type is ${typeof this.value}`)
      // console.log(`The new value is length is ${this.value.length}`)
      // let the_whole = document.getElementById(this.the_whole)
      // let the_label = document.getElementById(this.the_label)

      await this.$nextTick(function () {
        let the_label = document.getElementById(this.the_label)
        let the_value = document.getElementById(this.the_value)
        the_value.style.background = this.value_bg_color
        the_value.style.color = this.value_color
        the_value.style.width = this.value_width
        // value.style.height = "18px"
        the_value.style.fontSize = this.value_font_size
        the_label.style.fontSize = this.label_font_size
        let letter_space = "5px"
        if(this.letter_spacing_px){
          letter_space = this.letter_spacing_px
        }
        the_value.style.letterSpacing = letter_space

      })


    } catch (error) {
      // console.error("expected premature loading error.")
      // console.log(error)
    }

  },

}
</script>

<style scoped>

.big {
  font-size: 3rem;
}

.wide {
  /*min-width: 25%;*/
  max-width: 100%;
  /*min-width: 240px;*/

}

.backdrop {
}


.value {
  min-width: 3rem;
  font-weight: bold;
  font-size: .8rem;
  padding: .25rem;
  text-align: center;
  /*color: var(--light-text);*/
  margin-top: .5rem;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  background-color: gray;
  color: lightgray;
}

</style>