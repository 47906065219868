<template>
  <div class="core_c">
    <inside_banner
        mode="spyglass_interested_caregiver_plus_ad"
        :owner_perm="false"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="local_page_update"
        @continue_clicked="do_nothing"
    ></basic_page_infrastructure>

    <section class="flex_centered_column">
      <!--      <h1 class="flex_centered_start_column bg_primary_purple">-->
      <!--        this is an interested view test.-->
      <!--      </h1>-->

      <img @click="go_to_spyglass_admin_page" class="cursor_ptr spyglass_icon margin_tb_30"
           :src="require('@/assets/' + 'spyglass2_color.svg')" alt="Center icon">

      <h2 class="text_align_center margin_tb_15">{{ full_name }}</h2>

      <camera_widget
          ref="camera_widgit"
          :personal_gear_available="false"
          :btn1_c="colors.new_orange_main"
          :btn2_c="colors.new_orange_main"
          :btn3_c="colors.new_cyanic_main"
          :btn4_c="colors.new_cyanic_main"
          btn1_icon='camera_on.svg'
          btn2_icon='delete.svg'
          btn3_icon='camera_snap.svg'
          btn4_icon='cloud-upload.svg'
          image1_w="50px"
          image2_w="40px"
          image3_w="55px"
          image4_w="55px"
          button_border_rad="50%"
          button_h="80px"
          button_w="80px"
          :frame_text_color="colors.pennie_gray"
          :frame_color="colors.eli_gray"
          :old_pic="profile_image"
          :disable_controls="true"
          :my_profile_page="false"
          event_name="save_pic"
          @save_pic="do_nothing"
      ></camera_widget>


      <p class="font_raleway font_weight_800 margin_t_20">{{phone_number}}</p>
      <p class="font_raleway font_weight_800 margin_tb_5">{{city}}</p>
      <p class="font_raleway font_weight_800 ">{{zip}}</p>

      <call_us_box
          v-if="mobile_mode"
          class="margin_lr_10 margin_t_20"
          :click_to_call_mode=true
          :phone_number="phone_number_unf"
          @call_clicked=call_interested
      ></call_us_box>

      <div class="flex_space_between_row">
        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40 margin_r_10"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="Previous"
            text_size="14px"
            :bold="true"
            event_name="confirm_button_clicked"
            @confirm_button_clicked="get_previous_interested_cg_tmh_name"
            v-on:keyup.enter="get_previous_interested_cg_tmh_name"
            tabindex="0"
        ></basic_text_button>
        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="Next"
            text_size="14px"
            :bold="true"
            event_name="confirm_button_clicked"
            @confirm_button_clicked="get_next_interested_cg_tmh_name"
            v-on:keyup.enter="get_next_interested_cg_tmh_name"
            tabindex="0"
        ></basic_text_button>
      </div>

    </section>

  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  parse_internal_json,
  colors,
  desktop_size,
  scroll_to_top,
  load_ad_handles_from_local_db,
    load_trad_ad_handles_from_local_db,
  good_token_now,
  receive_over_socket,
  be_main_address,
  prime_socket,
  MD5,
  formatPhoneNumber
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";
import camera_widget from "@/components/parts/camera_widget.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import call_us_box from "@/components/SpyGlass/call_us_box.vue";

export default {
  name: "interested_view",
  async mounted() {
    // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
    this.db = new Database();
    scroll_to_top()
    // this.ad_handles_ = await load_plus_ad_handles_from_local_db(this.db)
    // console.log("Here are the ad handles value I just loadede......")
    // console.log(this.ad_handles)
    this.id_ = get_uuid()
    // this.get_interested_cg_tmh_name()
    // await this.get_interested_caregiver_data()
    // this.run_window_width_adjustments(this.windowWidth)
    // await prime_socket(this.local_message_handler)
    await this.local_page_update()

    // try {
    //   await this.$nextTick(function () {
    //
    //     if (this.bg_color) {
    //       let box = document.getElementById(this.id)
    //       box.style.background = this.bg_color
    //     }
    //
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  },
  async updated() {
    // try {
    //   await this.$nextTick(function () {
    //     if (this.bg_color) {
    //       let box = document.getElementById(this.id)
    //       box.style.background = this.bg_color
    //     }
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  },

  props: [
    "button_text", "cg_data"
  ],
  components: {
    call_us_box,
    basic_text_button,
    camera_widget,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      ad_handles_: "",
      interested_caregiver_: "",
      interesting_ad_id_: "",
      profile_image_: "",
      full_name_: "",
      phone_number_: "",
      city_: "",
      zip_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      data1: null,
      data2: null
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    call_interested() {
      // console.log("in caller")
      let num = this.phone_number_unf
      let full_num = 'tel:' + num
      window.open(full_num);
    },

    findObjectByUUID(array, uuid) {
      console.log("Looking for the target object through this array....")
      console.log(array)
      console.log("looking for id....")
      console.log(uuid)

      return array.find(obj => obj.hasOwnProperty('ad_id') && obj.ad_id === uuid);
    },

    findNextString(array, searchString) {
      const index = array.indexOf(searchString);
      if (index !== -1) { // If the string is found in the array
        // Return the next string
        if (index === array.length - 1) {
          // If the found string is the last element, return the first element
          return array[0];
        } else {
          // Otherwise, return the string next to the found string
          return array[index + 1];
        }
      } else {
        // If the string is not found in the array, return null
        return null;
      }
    },

    findPreviousString(array, searchString) {
      const index = array.indexOf(searchString);
      if (index !== -1) { // If the string is found in the array
        // Return the previous string
        if (index === 0) {
          // If the found string is the first element, return the last element
          return array[array.length - 1];
        } else {
          // Otherwise, return the string before the found string
          return array[index - 1];
        }
      } else {
        // If the string is not found in the array, return null
        return null;
      }
    },


    async get_next_interested_cg_tmh_name() {
      // console.log("Let's search for the next interested caregiver.")
      // console.log(this.interested_caregiver)
      // console.log(this.interesting_ad_id)

      let current_handle = this.findObjectByUUID(this.ad_handles_, this.interesting_ad_id_)
      // console.log("Here is the current ad handle")
      // console.log(current_handle)
      let next_caregiver = this.findNextString(current_handle.clicks, this.interested_caregiver)
      // console.log("Here is the name of the next caregiver on the list.")
      // console.log(next_caregiver)
      // console.log("Here is teh agency context")
      let org = store.getters.org_context
      // console.log(org)

      // let latest = localStorage.getItem('dev_latest_route')
      store.commit('update_context_changing_route', false)
      let this_route = `/pda/${org}/spyglass/standard/interested/${next_caregiver}/${this.interesting_ad_id}`
      // console.log("Here is the attempted route.")
      // console.log(this_route)
      store.commit('save_latest_route', this_route)
      // console.log(`Going to ${this_route}`)
      await this.$router.push({path: this_route})
      await this.local_page_update()
    },

    async get_previous_interested_cg_tmh_name() {
      console.log("Let's search for the previous interested caregiver.")
      console.log(this.interested_caregiver)
      console.log(this.interesting_ad_id)



      let current_handle = this.findObjectByUUID(this.ad_handles_, this.interesting_ad_id)
      console.log("Here is the current ad handle")
      console.log(current_handle)
      let previous_caregiver = this.findPreviousString(current_handle.clicks, this.interested_caregiver)
      console.log("Here is the name of the previous caregiver on the list.")
      console.log(previous_caregiver)
      console.log("Here is teh agency context")
      let org = store.getters.org_context
      console.log(org)

      // let latest = localStorage.getItem('dev_latest_route')
      store.commit('update_context_changing_route', false)
      let this_route = `/pda/${org}/spyglass/standard/interested/${previous_caregiver}/${this.interesting_ad_id}`
      // console.log("Here is the attempted route.")
      // console.log(this_route)
      store.commit('save_latest_route', this_route)
      // console.log(`Going to ${this_route}`)
      await this.$router.push({path: this_route})
      await this.local_page_update()
    },
    async go_to_spyglass_admin_page() {
      // console.log("Going back to SPygall admin")
      let org = store.getters.org_context
      // console.log(`Org is ${org}`)
      let this_route = `/pda/${org}/spyglass`
      store.commit('update_context_changing_route', false)
      await this.$router.push({path: this_route})
    },

    get_interested_cg_tmh_name() {
      let last_route = localStorage.getItem('dev_latest_route')
      const parts = last_route.split('/');
      this.interested_caregiver_ = parts[parts.length - 2];
      this.interesting_ad_id_ = parts[parts.length - 1];
      // console.log(`The interested caregiver on this page is ${this.interested_caregiver}`)
      // console.log(`The interested ad id is ${this.interesting_ad_id}`)
    },
    async get_interested_caregiver_data() {
      //make a call to spyglass and get what you need for an individual caregiver.
      console.log("Call SpyGlass and get interested caregiver data...")
      let target_url = be_main_address('sg') + 'get_interested_caregiver_data'

      let the_agency = store.getters.org_context

      console.log(`The agency organizational context is ${the_agency}`)
      if (!the_agency) {
        console.log("Cannot call get_interested_caregiver_data function outside of agency context.")
        return null
      }

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: the_agency,
        name: this.interested_caregiver,
      }

      let h_str = `${store.getters.my_name}:${store.getters.my_id_token}:${the_agency}:${this.interested_caregiver}:"get_interested_caregiver_data"`

      console.log("The hash str for get_interested_caregiver_data")
      // console.log(h_str)
      let hash_value = MD5(h_str)
      console.log(`Here is the hash value from get_interested_caregiver_data:  ${hash_value}`)
      console.log("Here is the data1")

      let cached_res = await store.dispatch("get_cache_value", hash_value)
      if (cached_res) {
        console.log(`Found cached interested caregiver data for ${this.interested_caregiver}`)
        console.log(cached_res)
        this.profile_image_ = cached_res.data['profile_image']
        this.full_name_ = cached_res.data['full_name']
        this.phone_number_ = cached_res.data['phone_number']
        this.city_ = cached_res.data['city']
        this.zip_ = cached_res.data['zip']
        return cached_res
      }


      axios.post(target_url, data1)
          .then((response) => {
                console.log("Here is response from get_interested_caregiver_data call...")
                console.log(response)
                let res = parse_internal_json(response.data)
                // console.log(response.data['error'])
                console.log(res)
                // console.log(res['data']['profile_image'])

                if (response.status === 200) {
                  // console.log(res.data['profile_image'])
                  this.profile_image_ = res.data['profile_image']
                  this.full_name_ = res.data['full_name']
                  this.phone_number_ = res.data['phone_number']
                  this.city_ = res.data['city']
                  this.zip_ = res.data['zip']
                  // return response.data.data['profile_image']
                  let c = {
                    'key': hash_value,
                    'value': res,
                    'cache_time': 600
                  }
                  // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Caching a p_name: ${p_name}`)
                  store.commit("set_cache_value", c)

                } else {
                  // console.log(response.data)
                  store.commit("error_enqueue", "Unable to retrieve data for this caregiver.")
                }
              }
          )
    },

    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      // console.log('Inside interested local page update...')
      //  console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
      this.ad_handles_ = await load_trad_ad_handles_from_local_db(this.db)


      // console.log("Here are the ad handles value I just loadede......")
      // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
      // console.log(this.ad_handles)
      this.id_ = get_uuid()
      this.get_interested_cg_tmh_name()
      await this.get_interested_caregiver_data()
      this.run_window_width_adjustments(this.windowWidth)
      await prime_socket(this.local_message_handler)
      // console.log('Local page update complete...')
      //  console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    ad_handles() {
      return this.ad_handles_
    },
    interested_caregiver() {
      return this.interested_caregiver_
    },
    interesting_ad_id() {
      return this.interesting_ad_id_
    },
    profile_image() {
      return this.profile_image_
    },
    full_name() {
      return this.full_name_
    },
    phone_number() {
      return formatPhoneNumber(this.phone_number_)
    },
    phone_number_unf() {
      return this.phone_number_
    },
    city() {
      return this.city_
    },
    zip() {
      return this.zip_
    },
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {
  .spyglass_icon {
    width: 150px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  .spyglass_icon {
    width: 250px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>