<template>
  <!--  :class="{bg_cyanic: button_state, bg_gray: !button_state}"-->
<!--  :class="{text_white: !button_state, text_black: button_state}"-->
  <div class="slide_over_item circle_smaller flex_centered_column the_button">
    <div class="btn_text" @click="launch">{{button_text}}</div>
  </div>
</template>

<script>

export default {
  name: "launch_button2",
  props: ['button_state', 'button_text', 'button_color', 'text_color', 'button_color_not_ready'],
  async mounted() {
    // console.log("launch button updater")

    try {
      let the_button = document.querySelector('.the_button')
      // console.log('the_button')
      // console.log(the_button)
      if (this.button_state === true) {

        the_button.style.background = this.button_color
      } else {
        the_button.style.background = this.button_color_not_ready
      }
      let the_text = the_button.querySelector('.btn_text')
      the_text.style.color = this.text_color
    } catch (error) {
      console.error("Nothing to see here.")
      console.log(error)
    }

  },
  data() {
    return {}
  },
  methods: {
    launch() {
      if (this.button_state) {
        this.$emit('launch', true)
      }
    },
  },
  computed: {},

}
</script>

<style scoped>

</style>