<template>
  <div class="bg_whitesmoke main_tab margin_tb_20" :class="{flex: variant === 'horizontal',}">
    <div class="flex_start_row margin_tb_10">
      <div v-for="(tab, index) in tabList" :key="index">
        <div class="margin_lr_10 tabs font_raleway b_rad_10px cursor_ptr" :class="{tab_selection: activeTab === index + 1,}"
             @click="set_active_tab(index)">{{ tab }}
        </div>
      </div>
    </div>



    <template v-for="(tab, index) in tabList">
      <div :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: () => "vertical",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    set_active_tab(index) {
      this.activeTab = index + 1
    },
  },
};
</script>

<style>


.flex {
  display: flex;
}

@media (min-width: 100px) {
  .main_tab {
    width: 98vw;
    max-width: 720px;
    box-shadow: 2px 2px 2px var(--eli_gray);
  }

  .tabs {
    font-size: 15px;
    font-weight: bolder;
    padding: 10px;
    margin-top: 10px
  }

  .tab_selection {
    background-color: var(--cyanic-blue);
    color: black;

  }
}


</style>