<template>
  <section class=" core_c">
    <inside_banner
        mode="spyglass_agency"
    >
    </inside_banner>
    <div class="flex_centered_row">
      <section class="flex_centered_start_column">
        <div class=" margin_tb_10 padding_lr_15 padding_tb_20  lineh_20 ">

          <div v-if="ready_to_save" class=" flex_centered_row margin_b_30">
            <basic_text_button
                class="margin_b_20 margin_t_10"
                :button_state="true"
                :button_color="colors.new_cyanic_main"
                :text_color="colors.black"
                :button_color_not_ready="colors.gray"
                :button_not_ready_text_color="colors.morgie_gray"
                button_height="40px"
                button_width="80px"
                border_rad_pct="10%"
                text="Save"
                button_not_ready_text="Save"
                text_size="14px"
                :bold="true"
                event_name="save_to_backend"
                @save_to_backend="save_landing_texts"
            ></basic_text_button>
          </div>

          <!--          <div class="main_agency_desc">-->
          <!--            <img class="agency_landing_logo " :src="agency_logo" @click="get_new_route">-->
          <!--            <div class="text_40 font_raleway font_weight_600 agency_title">{{ landing_headline }}</div>-->
          <!--            <div class="text_30 font_raleway margin_tb_10 margin_t_20 agency_desc">{{ landing_body }}-->
          <!--            </div>-->
          <!--          </div>-->

          <div class=" flex_wrap agency_content">
            <div class="flex_centered_column">
              <img class="agency_landing_logo " :src="agency_logo" @click="get_new_route">
              <div class="flex_centered_row">
                <h5 class="text_align_center">{{ landing_headline }}</h5>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="agency_landing_text">
              <p>{{ landing_body }}</p>
            </div>
          </div>

          <!--          <div class="main_agency_desc">-->
          <!--            <img class="agency_landing_logo " :src="agency_logo" @click="get_new_route">-->
          <!--            <div class="text_40 font_raleway font_weight_600 agency_title">{{ landing_headline }}</div>-->
          <!--            <div class="text_30 font_raleway margin_tb_10 margin_t_20 agency_desc">{{ landing_body }}-->
          <!--            </div>-->
          <!--          </div>-->
        </div>


        <div class="flex_centered_column">
          <div class="flex_centered_row flex_wrap">
            <div class="" v-for="item in ads" :key="item.message">

              <schedule_ad_public
                  class="margin_tb_10 margin_lr_10 wid_95"
                  :neighborhood="item.neighborhood"
                  :headline="item.headline"
                  :body_text="item.body"
                  :posted="item.posted"
                  :freetime_stats="item.freetime_stats"
                  :expires="item.expires"
                  :ad_id="item.ad_id"
                  :phone_number="agency_phone"

                  event_name="ad_clicked"
                  @ad_clicked="do_nothing"
              ></schedule_ad_public>

            </div>
          </div>
        </div>

      </section>
      <error_popup_queue></error_popup_queue>
      <notification_popup></notification_popup>
    </div>
  </section>
  <outside_footer class="local_footer footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import image_upload_widgit from "@/components/parts/image_upload_widgit";
import schedule_ad from "@/components/SpyGlass/schedule_ad";
import schedule_ad_public from "@/components/SpyGlass/schedule_ad_public";
import basic_text_button from "@/components/parts/basic_text_button";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_footer from "@/components/parts/outside_footer";
import outside_banner_std from "@/components/parts/outside_banner_std";
import inside_banner from "@/components/parts/inside_banner";
import {
  formatPhoneNumber, colors, be_main_address, parse_internal_json,
  get_logo_image_from_local_db, get_agency_landing_text_from_graph
} from "@/library";
import store from "@/store";
import {Database} from "@/client_db";
import axios from "axios";
// import store from "@/store";

export default {
  name: "spyglass_agency_landing_page",
  components: {
    image_upload_widgit,
    schedule_ad,
    schedule_ad_public,
    basic_text_button,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner
  },
  async mounted() {
    // console.log("Running mounted")
    this.db = new Database();
    // this.landing_body_ = store.getters.spyglass_get_agency_landing_body
    // this.landing_headline_ = store.getters.spyglass_get_agency_landing_headline
    this.ready_to_save_ = store.getters.spyglass_agency_landing_get_ready_to_save
    // console.log("In mounted")
    // console.log(`Ready to save? ${this.ready_to_save}`)
    await get_logo_image_from_local_db(this.db)
    // await this.get_agency_phone_number()

    if (!this.ready_to_save) {
      console.log("Not ready to save")
      let r = await this.load_from_localdatabase_to_local_variable()
      console.log(r)
      this.landing_text['headline'] = r['headline']
      this.landing_text['body'] = r['body']
      // await this.get_agency_landing_text(store.getters.org_context)
      // await this.load_from_localvariable_to_vuex()
    } else {
      await this.load_from_vuex_to_local_variable()
    }

    this.ads = await this.get_list_of_ads()

    console.log(`Landing headline is ${this.landing_headline}`)

    if (!this.landing_body) {
      this.landing_body_ = 'No body'
    }
    if (!this.landing_headline) {
      this.landing_headline_ = 'No headline'
    }
  },
  data() {
    return {
      colors: colors,
      landing_body_: "",
      landing_headline_: "",
      landing_text_: {},
      agency_image_: "",
      ready_to_save_: false,
      ads: [],

    }
  },
  computed: {
    // agency_image() {
    //   // console.log("inside the profile-image computed prop bc of image chang.")
    //   return this.agency_image_
    // },
    landing_text() {
      return this.landing_text_
    },


    ready_to_save() {
      return this.ready_to_save_
    },
    agency_logo() {
      return store.getters.spyglass_get_agency_landing_logo
    },
    agency_phone() {
      return store.getters.spyglass_agency_phone
    },
    // agency_email() {
    //   return store.getters.spyglass_agency_email
    // },
    // preview_published_state() {
    //   return store.getters.spyglass_get_preview_published_status
    // },
    landing_body() {
      return this.landing_text['body']
    },
    landing_headline() {
      return this.landing_text['headline']
    },
    // agency_name() {
    //   return store.getters.org_context
    // },
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async get_agency_landing_text(agency = null) {
      if (agency) {
        //pass
      } else {
        agency = store.getters.spyglass_agency_landing_public_get_agency_name
      }

      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_landing_text'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.landing_text_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
        // console.log('HERE IS IS.............................')
        // console.log(this.landing_text_)
      })
    },


    async get_list_of_ads() {
      let search = {
        key: '',
        additional_context: [['div', store.getters.org_context], ['div', 'ads']],
        base_context: store.getters.find_current_preamble
      }
      let result_array = await this.db.db_search(search)
      // console.log("Here is what I found when I went searching for the ads.")
      // console.log(JSON.stringify(result_array))
      return result_array
      // let hl = headline_array[0]

      // let search_body = {
      //   key: 'body',
      //   additional_context: [['div', 'blurbs']],
      //   base_context: store.getters.find_current_preamble
      // }
      // let body_array = await this.db.db_search(search_body)
      //
      // return {
      //   "body": body_array[0],
      //   "headline": headline_array[0]
      // }
    },

    async load_from_localdatabase_to_local_variable() {
      //  pull landing page data from localdatabase.
      // let pda = store.getters.org_context

      let search_hl = {
        key: 'headline',
        additional_context: [['div', store.getters.org_context], ['div', 'blurbs']],
        base_context: store.getters.find_current_preamble
      }
      let headline_array = await this.db.db_search(search_hl)
      // let hl = headline_array[0]

      let search_body = {
        key: 'body',
        additional_context: [['div', store.getters.org_context], ['div', 'blurbs']],
        base_context: store.getters.find_current_preamble
      }
      let body_array = await this.db.db_search(search_body)
      // this.landing_body_ = body_array[0]
      // this.landing_headline_ = hl
      return {
        "body": body_array[0],
        "headline": headline_array[0]
      }
    },

    async load_from_vuex_to_local_variable() {
      // this.landing_headline_ = store.getters.spyglass_get_agency_landing_headline
      // this.landing_body_ = store.getters.spyglass_get_agency_landing_body
      this.landing_text_['headline'] = store.getters.spyglass_get_agency_landing_headline
      this.landing_text_['body'] = store.getters.spyglass_get_agency_landing_body
    },

    async load_from_localvariable_to_vuex() {
      // write data to vuex
      store.commit('spyglass_set_agency_landing_headline', this.landing_headline)
      store.commit('spyglass_set_agency_landing_body', this.landing_body)
    },

    prepare_landing_texts() {
      let hl = store.getters.spyglass_get_agency_landing_headline
      let body = store.getters.spyglass_get_agency_landing_body
      let out = {
        "headline": hl,
        "body": body
      }
      return out
    },
    async save_landing_texts() {
      console.log("you just clicked to save to the back end.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: this.prepare_landing_texts()
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'save_agency_landing_texts'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          this.ready_to_save_ = false
          store.commit('spyglass_agency_landing_set_ready_to_save', false)
          let message = parse_internal_json(response.data)['message']
          store.commit('notification_enqueue', message)
        }
      }, (error) => {
        console.log('Error on save_agency_landing_texts.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to save your landing page content.")
      });


    },


    async get_new_route() {
      let agency = store.getters.org_context

      let new_url = `/pda/${agency}/spyglass`
      console.log("Returned new url is...")
      console.log(new_url)
      await this.$router.push({path: new_url})
    },

    local_phone_format(phone) {
      return formatPhoneNumber(phone)
    },
    async upload_logo_image() {
      console.log("inside upload image")

    },
  }
}
</script>

<style scoped>


@media (min-width: 100px) {
  .agency_landing_logo {
    height: 80px;
    margin-bottom: 20px;
  }

  .spacer {
    width: 50px;
  }

  .agency_content {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    /*justify-content: space-between;*/
    align-items: center;

  }

  .agency_landing_text {
    max-width: 95%;
    padding: 0;
  }

  .agency_content h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .agency_landing_text p {
    font-size: 14px;
    max-width: 500px;
  }


  @media (min-width: 800px) {
    .local_footer {
      margin-top: 100px;
    }

    .agency_content {
      margin-bottom: 40px;
    }

    .agency_landing_logo {
      height: 110px;
      margin-bottom: 20px;
    }

    .agency_landing_text {
      max-width: 65%;
      padding: 0 30px;
    }

    .agency_landing_text p {
      font-size: 18px;
      max-width: 700px;
    }

    .agency_content h5 {
      font-size: 26px;
      margin-bottom: 20px;
    }

  }



  @media (min-width: 2000px) {


    .agency_landing_logo {
      height: 150px;
      margin-bottom: 20px;
    }

    .agency_content {
      margin-bottom: 40px;
    }

    .agency_landing_text {
      max-width: 65%;
      padding: 0 30px;
    }

    .agency_landing_text p {
      font-size: 26px;
      max-width: 900px;
    }

    .agency_content h5 {
      font-size: 30px;
      margin-bottom: 20px;
    }

  }
}

</style>