<template>
  <div class="core_c">

    <section class="margin_t_20">
      <div class="flex_centered_row">
        <div class="flex_space_between_row opt_in_row margin_tb_15">
          <div class="flex_centered_column" @click="set_new_client_discount_hours_to_0">
            <div v-if="this.discount_hours !== 0"  class="check_box shadow_inner cursor_ptr"></div>
            <div v-else>
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <p v-if="true" class="opt_in_text_no text_align_center font_weight_800">No bonus hours</p>
        </div>
      </div>
      <div class="flex_centered_row">
        <div class="flex_space_between_row opt_in_row margin_tb_15">
          <div class="flex_centered_column" @click="set_new_client_discount_hours_to_3">
            <div v-if="this.discount_hours !== 3" class="check_box shadow_inner cursor_ptr"></div>
            <div v-else>
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <p v-if="true" class="opt_in_text_no text_align_center font_weight_800">3 bonus hours</p>
        </div>
      </div>
      <div class="flex_centered_row">
        <div class="flex_space_between_row opt_in_row margin_tb_15">
          <div class="flex_centered_column" @click="set_new_client_discount_hours_to_5">
            <div v-if="this.discount_hours !== 5" class="check_box shadow_inner cursor_ptr"></div>
            <div v-else>
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <p v-if="true" class="opt_in_text_no text_align_center font_weight_800">5 bonus hours</p>
        </div>
      </div>
      <div class="flex_centered_row">
        <div class="flex_space_between_row opt_in_row margin_tb_15">
          <div class="flex_centered_column" @click="set_new_client_discount_hours_to_10">
            <div v-if="this.discount_hours !== 10" class="check_box shadow_inner cursor_ptr"></div>
            <div v-else>
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <p v-if="true" class="opt_in_text_no text_align_center font_weight_800">10 bonus hours</p>
        </div>
      </div>
    </section>


  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";

export default {
  name: "selection_widget",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
  ],
  emits: ['update_discount_hours'],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      discount_hours: 0,
      data2: null
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_hours(hours) {
      this.$emit("update_discount_hours", hours)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },

    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

    set_new_client_discount_hours_to_0() {
      this.discount_hours = 0
      this.emit_hours(this.discount_hours)
    },

    set_new_client_discount_hours_to_3() {
      this.discount_hours = 3
      this.emit_hours(this.discount_hours)
    },

    set_new_client_discount_hours_to_5() {
      this.discount_hours = 5
      this.emit_hours(this.discount_hours)
    },
    set_new_client_discount_hours_to_10() {
      this.discount_hours = 10
      this.emit_hours(this.discount_hours)
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {



  }
   .check_box {
      background-color: var(--lauren_gray);
      //background-color: red;
      height: 30px;
      width: 30px;
      border: 1px solid var(--black);

    }

  .check_box_icon {
    height: 30px;


  }

  .opt_in_row {
    width: 240px;
  }

  .spacer {
    width: 20px;
  }


}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>