<template>
  <div v-show="show_selection_indicator" class="flex_centered_row">
    <!--  <div v-for="s in parseInt(selections)" :key="s">-->
    <!--    <div class="dot"></div>-->
    <!--  </div>-->

    <div v-if="selected1" class="dot_on cursor_ptr" @click="$emit('card_picked', 'speedydelivery')"></div>
    <div v-else class="dot_off cursor_ptr" @click="$emit('card_picked', 'speedydelivery')"></div>

    <div v-if="selected2" class="dot_on cursor_ptr" @click="$emit('card_picked', 'spyglass')"></div>
    <div v-else class="dot_off cursor_ptr" @click="$emit('card_picked', 'spyglass')"></div>

    <div v-if="selected3" class="dot_on cursor_ptr" @click="$emit('card_picked', 'freetime')"></div>
    <div v-else class="dot_off cursor_ptr" @click="$emit('card_picked', 'freetime')"></div>

  </div>
</template>

<script>
export default {
  name: "selection_indicator",
  props: [
    "pick",
    "show"

  ],
  async mounted(){
     try {
           console.log(`Pick equals ${this.pick}`)
           if(this.pick === 1){
             this.sel1 = true
             this.sel2 = false
             this.sel3 = false
           }else if(this.pick === 2){
             console.log("pick was 2")
             this.sel1 = false
             this.sel2 = true
             this.sel3 = false
           }else{
             this.sel1 = false
             this.sel2 = false
             this.sel3 = true
           }
      } catch (error) {
        // console.error(error)
      }
  },
  async updated() {

    await this.$nextTick(function () {
      try {
           console.log(`update Pick equals ${this.pick}`)
           if(this.pick === 1){
             this.sel1 = true
             this.sel2 = false
             this.sel3 = false
           }else if(this.pick === 2){
             this.sel1 = false
             this.sel2 = true
             this.sel3 = false
           }else{
             this.sel1 = false
             this.sel2 = false
             this.sel3 = true
           }
      } catch (error) {
        // console.error(error)
      }

    })
  },
  data() {
    return {
      sel1: true,
      sel2: false,
      sel3: false,
    }
  },
  computed: {
    show_selection_indicator(){
      return this.show
    },
    selected1() {
      return this.sel1
    },
    selected2() {
      return this.sel2
    },
    selected3() {
      return this.sel3
    },

  }
}
</script>


<style scoped>
@media (min-width: 100px) {
  .dot_on {
    height: 20px;
    width: 20px;
    background-color: var(--eli_gray);
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .dot_off {
    height: 20px;
    width: 20px;
    background-color: var(--pennie_gray);
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
  }
}


</style>