<template>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_whitesmoke">
    <img class="freetime_color padding_tb_20"
         src="@/assets/forums_icon.svg"
         alt="Forums Logo">
  </div>


  <div class="screen_block margin_tb_10 flex_centered_column bg_whitesmoke">
    <select_and_launch_2
        class="margin_auto bg_whitesmoke font_raleway font_weight_800"
        label='Subscribe a PDA to Forums '
        :label_color=colors.black
        selection_title="PDAs"
        button_text="Subscribe"
        :values="non_subscribing_pdas"
        event_name="create_pda_subscription_to_forums"
        @create_pda_subscription_to_forums="do_nothing"
    ></select_and_launch_2>
  </div>

  <dual_select_and_launch class="margin_auto bg_whitesmoke font_raleway font_weight_800  margin_t_20 b_rad_10px"
                          label="Subscribe an agency to a particular forum"
                          selection_title1="Select PDA"
                          selection_title2="Select Forum"
                          button_text="Subscribe"
                          :values1="[]"
                          :values2="[]"
                          event_name="subscribe_pda_to_particular_forum"
                          @subscribe_pda_to_particular_forum="do_nothing"
  >
  </dual_select_and_launch>

  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_whitesmoke">
    <div class="flex_centered_column res_width margin_lr_10 ">
      <div class="font_weight_800 font_raleway" id="create_forum_label">Create a new forum</div>
      <input class="margin_b_10 b_rad_10 text_align_center" v-model="text1_" :placeholder="placeholder1">
      <div class="flex_space_around_row flex_wrap margin_tb_10">
        <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :uuid="child_toggle_uuid"
                      :state_disabled="!(text1)"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        ></basic_toggle>

        <launch_button
            ref="button_child"
            :uuid="child_button_uuid"
            :button_state_start=button_state_start
            :button_text=button_text
            @launch="launch"
        >
        </launch_button>
      </div>
    </div>
  </div>

<!--  <div>-->
<!--    <div class="margin_t_40 flex_centered_row  font_weight_800 text_30 font_raleway">Agency Forum</div>-->
<!--    <new_conversation id="new_conversation"></new_conversation>-->
<!--  <div class="flex_centered_column">-->
<!--    <section class="flex_left_aligned_column" id="threads_container">-->

<!--      <comment_thread-->
<!--          title="I have the greatest dog ever!!!!!"-->
<!--          :list_of_posts="a_test_list"-->
<!--      ></comment_thread>-->

<!--      <comment_thread-->
<!--          title="Eli truly is the King Bear!!!"-->
<!--          :list_of_posts="a_test_list2"-->
<!--      ></comment_thread>-->


<!--    </section>-->
<!--  </div>-->
<!--  </div>-->


<!--  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white">-->
<!--    <div>Unsubscribe an agency from Forums</div>-->
<!--  </div>-->


<!--  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white">-->
<!--    <div>Unsubscribe an agency from a particular forum</div>-->
<!--  </div>-->

</template>

<script>
import basic_toggle from "@/components/parts/basic_toggle";
import launch_button from "@/components/parts/launch_button";
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import dual_select_and_launch from "@/components/parts/dual_select_and_launch";
// import new_conversation from "@/components/basics/new_conversation";
// import comment_thread from "@/components/basics/comment_thread";
import {colors} from "@/library";
import {v4 as uuidv4} from "uuid";

export default {
  name: "forums_control",
  components: {
    basic_toggle,
    launch_button,
    select_and_launch_2,
    dual_select_and_launch,
    // new_conversation,
    // comment_thread
  },
  props: [],
  data() {
    return {
      colors: colors,
      text1_: "",
      child_button_uuid: uuidv4(),
      child_toggle_uuid: uuidv4(),
      placeholder1_: "New Forum Name",
      button_text_: "Create",
      non_subscribing_pdas_: [],
      button_state_start_: false,
      a_test_list : [
          {
              user: 'wyatt',
              timestamp: 'August 23, 2022 at 10:55 am',
              comment: 'I have the world\'s best hund. \n' +
                  'He also happens to be the most beautiful as well.',
              level: 0
          },
           {
              user: 'eli',
              timestamp: 'August 23, 2022 at 10:58 am',
              comment: 'I am so happy you said that. We should celebrate with a pork chop!',
              level: 1
          }
      ],
      a_test_list2 : [
          {
              user: 'wyatt',
              timestamp: 'August 23, 2022 at 11:15 am',
              comment: 'There can be no hund that rivals the glory of that of the Shoog Sha Sheesh.',
              level: 0
          },
           {
              user: 'eli',
              timestamp: 'August 23, 2022 at 11:18 am',
              comment: 'Eye! So true.  Toshanay Shushasheesh!! Indeed.',
              level: 1
          },
           {
              user: 'wyatt',
              timestamp: 'August 23, 2022 at 11:19 am',
              comment: 'I hear ya noog.  No doubt!',
              level: 2
          },
           {
              user: 'lauren',
              timestamp: 'August 23, 2022 at 11:22 am',
              comment: 'You are definitely the best hundicus, boogie bear!',
              level: 1
          },
      ]


    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    toggle_is_true() {
      console.log("Child toggle reports true, emitting precheck.")
      // store.commit('notification_enqueue', this.toggle_true_message)
      this.$emit(this.precheck_event_name, this.text1, this.uuid)

    },
    toggle_is_false() {
      console.log("Child toggle reports false")
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_state_to_false();
    },
    launch() {
      this.$emit(this.event_name, this.text1, this.uuid)
    },
    reset() {
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_state_to_false();
      this.reset_text1()
      // this.reset_text2()
      // this.reset_text3()
      // this.reset_text4()
    },
    reset_text1() {
      this.text1_ = ""
    },

    toggle_state_to_false() {
      this.$refs.toggle_child.toggle_state_to_false();
    },
    toggle_state_to_true() {
      this.$refs.toggle_child.toggle_state_to_true();
    },
    button_state_to_false() {
      this.$refs.button_child.button_state_to_false();
    },
    button_state_to_true() {
      this.$refs.button_child.button_state_to_true();
    },
  },
  computed: {
    text1() {
      return this.text1_
    },
    placeholder1() {
      return this.placeholder1_
    },
    button_text() {
      return this.button_text_
    },
    non_subscribing_pdas() {
      return this.non_subscribing_pdas_
    },
    button_state_start() {
      return this.button_state_start_
    },

  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  #create_forum_label {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;

  }
   #new_conversation{
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
   #new_conversation {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}


</style>