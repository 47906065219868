<template>
  <div class="screen_block text_white padding_tb_20">
    <div class="flex_centered_column">

      <div class="flex_centered_row padding_b_20 label_regular top_label">
        {{ label }}
      </div>

      <div class="flex_space_around_row res_width margin_lr_10 flex_wrap">


        <drop_down_select ref="drop_down_child"
            :title="selection_title"
            :values="values"
            @value-selected="candidate_selected"
        ></drop_down_select>



         <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :uuid="child_toggle_uuid"
                      :state_disabled="!candidate"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        ></basic_toggle>


         <launch_button
            ref="button_child"
            :uuid="child_button_uuid"
            :button_state_start=button_state_start
            :button_text=button_text
            @launch="launch"
        >
        </launch_button>
      </div>
    </div>
  </div>
</template>

<script>
import drop_down_select from "@/components/parts/drop_down_select";
import basic_toggle from "@/components/parts/basic_toggle";
import launch_button from "@/components/parts/launch_button";
import {get_uuid} from "@/library";

export default {
  name: "select_and_launch",
  props: ['label', 'event_name', 'selection_title', 'button_text', 'values', 'label_color'],
  components: {
    basic_toggle,
    drop_down_select,
    launch_button
  },
  async mounted() {
    // console.log("running mounted")
    // let bg = this.$el
    let label_text = this.$el.querySelector('.top_label')
    // let label = this.$el.querySelector('.label_regular')
    // bg.style.background = this.bg_color
    //
    // label.style.color = this.label_color
    //
    label_text.style.color= this.label_color
    // value.style.color = this.value_color


  },
  data() {
    return {
      // launcher_armed_: false,
      candidate_: "",
      child_toggle_uuid: get_uuid(),
      child_button_uuid: get_uuid(),
      button_state_start: false
    }
  },
  methods: {

     toggle_is_true() {
      // console.log("Child toggle reports true, emitting precheck.")
      this.$refs.button_child.button_state_to_true();
      this.$emit('precheck', this.text1, this.text2, this.text3, this.text4, this.uuid)
    },
    toggle_is_false() {
      // console.log("Child toggle reports false")
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_toggle();
    },

    candidate_selected(state) {

      if (state === `---- ${this.selection_title} ----`) {
        this.candidate_ = ""
      }else{
        this.candidate_ = state
      }
    },

    launch(){
      // console.log("in Launch")
      this.$emit(this.event_name, this.candidate)
      this.reset()

    },
    reset(){
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_toggle();
      this.$refs.drop_down_child.reset();
      this.candidate_ = ""
    }

  },
  computed: {
    candidate() {
      return this.candidate_
    },

  },
  watch: {
  }
}
</script>

<style scoped>

.top_label{}

.res_width{
  width: 100%;
}

@media (min-width: 2000px) {
  .res_width{
    width: 80%
  }

}

</style>