<template>


    <section  v-if="click_to_call_mode" @click="emit_clicked" class="main_box flex_centered_column">
      <div  class="cursor_ptr">
        <h6>Click to Call Now</h6>
      </div>
    </section>
   <section v-else  class="main_box flex_centered_column">
      <div class="text_align_center">
        <h6 class="margin_b_10">Call us at</h6>
        <h6>{{ formatted_phone_number }}</h6>
      </div>
    </section>


</template>

<script>
import {formatPhoneNumber} from "@/library";

export default {
  props: ['phone_number', 'click_to_call_mode'],
  name: "call_us_box",
  emits: ['call_clicked', ],
  methods: {
    emit_clicked(){
      this.$emit('call_clicked', true)
    },
  },
  computed: {
     formatted_phone_number() {
      // console.log("in formatted phone")
      // console.log(formatPhoneNumber(this.phone_number))
      return formatPhoneNumber(this.phone_number)
    }
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  .main_box{
    background-color: var(--cyanic_background);
    padding: 30px 15px;
    width: 159px;
  }
  h6{
    color: var(--primary-purple);
    font-size: 20px;
    font-weight: 800;
  }
}

@media (min-width: 360px) {
    .main_box{
    background-color: var(--cyanic_background);
    padding: 30px 15px;
    width: 175px;
  }
  h6{
    color: var(--primary-purple);
    font-size: 20px;
    font-weight: 800;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 1200px) {
   .main_box{
    background-color: var(--cyanic_background);
    padding-top: 60px;
    padding-bottom: 60px;
    /*padding-left: 40px;*/
    /*padding-right: 70px;*/
    width: 347px;
  }
  h6{
    color: var(--primary-purple);
    font-size: 30px;
    font-weight: 800;
  }
}

@media (min-width: 1400px){
   .main_box{
    background-color: var(--cyanic_background);
    padding-top: 60px;
    padding-bottom: 60px;
    /*padding-left: 40px;*/
    /*padding-right: 70px;*/
    width: 357px;
  }
  h6{
    color: var(--primary-purple);
    font-size: 30px;
    font-weight: 800;
  }
}
</style>