<template>
  <div class="">

    <section>
      <h4 class="padding_t_20 margin_l_10">Active Discounts</h4>
      <active_discount_item
          @retire_discount=retire_discount
          :discount_values=discount_values
      ></active_discount_item>
    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import outside_banner_std from "@/components/parts/outside_banner_std.vue";
import outside_footer from "@/components/parts/outside_footer.vue";
import inside_banner from "@/components/parts/inside_banner.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket, parse_internal_json
} from "@/library";
import {Database} from "@/client_db";
import Active_discount_item from "@/components/Vortex/discount_pages/active_discount_item.vue";
import axios from "axios";

export default {
  name: "active_discount_widget",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text", "discount_values"
  ],
  components: {
    Active_discount_item,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      // discount_values: undefined,
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      data1: null,
      data2: null
    }
  },
  methods: {
    do_nothing(item) {
      console.log("Busy doing something close to nothing.")
      console.log(item)
    },
    derive_discount_name(clientInfo) {
      console.log(`Inside the retirement function.`)
      console.log(clientInfo)

      // Extract the client name and phone from the discount section
      // const clientInfo = client_discount_data['Mildred Williams'].discount;
      const clientName = clientInfo.client_name;
      const clientPhone = clientInfo.client_phone;

      // Return the formatted string
      return `${clientName}:${clientPhone}`;
    },

    processAgencyVortexData(inputData) {
      const resultData = {};

      Object.keys(inputData).forEach(clientName => {
        console.log(`Starting client data work with ${clientName}`)
        const clientData = inputData[clientName];
        const invoices = clientData.invoices || [];
        const transactions = clientData.transactions || [];

        const totalDiscountAmtHonored = transactions.reduce((sum, txn) => sum + txn.discount_amount, 0);
        const currentInvoiceHours = invoices.reduce((sum, inv) => sum + inv.inv_hours, 0);
        const minHoursThreshold = clientData.discount.discount_hours * 3;

        resultData[clientName] = {
          client_name: clientName,
          client_phone: clientData.discount.client_phone,
          creation_date: clientData.discount.creation_date,
          expiration_date: clientData.discount.expiration_date,
          discount_amount: clientData.discount.discount_amount,
          total_discount_amt_honored: totalDiscountAmtHonored,
          current_invoice_hours: currentInvoiceHours,
          min_hours_threshold: minHoursThreshold,
          invoices: invoices.map(inv => ({
            invoice_number: inv.inv_num,
            invoice_amount: inv.inv_amt,
            invoice_hours: inv.inv_hours,
            invoice_all_hours: inv.all_hours,
            min_hours_threshold_met: inv.min_met,
            invoice_credit_applied: inv.credited_amt,
            uid: inv.uid
          })),
          transactions: transactions.map(txn => ({
            invoice_number: txn.invoice_number,
            discount_amt_honored: txn.discount_amount,
            transaction_date: txn.transaction_date
          }))
        };
      });

      return resultData;
    },

    async get_agency_vortex_data() {
      let target_url = be_main_address('vortex') + 'get_agency_vortex_data'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
      }

      console.log(data1)

      console.log("Calling backend to get vortex data.")

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response from vortex.")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                let message = parse_internal_json(response.data)['data']['message']
                console.log("Here is the response from vortex.")
                console.log(typeof response.data.data)
                console.log(response.data.data)
                this.discount_values = this.processAgencyVortexData(response.data.data)
                console.log("OK here is what came back out the other side.")
                console.log(this.discount_values)

              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },


    async retire_discount(client_discount_data) {
      let target_url = be_main_address('vortex') + 'retire_vortex_discount';

      console.log('Here is the passed in data...')
      console.log(client_discount_data)

      // Derive the discount name and phone
      let discount_name_and_phone = this.derive_discount_name(client_discount_data);

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
        discount_name: discount_name_and_phone
      };

      try {
        let response = await axios.post(target_url, data1);
        console.log("Just got a response from vortex.");
        console.log(response);

        if (response.statusText === "OK" || response.status === 200) {
          if (response.data['error'] === true) {
            let message = parse_internal_json(response.data)['payload']['content']['message'];
            store.commit('error_enqueue', message);
          } else {
            let message = parse_internal_json(response.data)['data']['message'];
            console.log("Here is the response from vortex.");
            // console.log(typeof response.data.data);
            console.log(message)
            console.log(response);
            console.log(response.data);
            console.log(response.data.data);
            store.commit('notification_enqueue', message);
          }
        } else {
          console.log("SERVER ERROR");
          console.log(response);
        }
      } catch (error) {
        console.error("Error occurred while making the request:", error);
      }
    },


    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_honor_discount(disc) {
      console.log("Still at active widget but with emit honor discount.")
      this.$emit('honor_discount', disc)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  }
  ,
  computed: {
    ...
        mapGetters([
          'window_width',
          'socket_connection_counter'
        ]),
    id() {
      return this.id_
    }
    ,
    spinner_up() {
      return store.getters.get_spinner_state
    }
    ,
    good_token() {
      return good_token_now()
    }
    ,

    mobile_mode() {
      return this.mobile_mode_x
    }
    ,

    button_height() {
      return this.button_height_
    }
    ,
    button_width() {
      return this.button_width_
    }
    ,
  }
  ,
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    }
    ,
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    }
    ,
  }
  ,
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }

  h4 {
    font-family: Raleway, sans-serif;
    font-size: 45px;
    font-weight: 700;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>