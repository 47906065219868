<template>
   <headers
      title="Caregiver Forum"
   ></headers>
  <section class="core_c">
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>

    <forum_widget
        class="forum_widget"
        forum_name="main_forum"
        :threads="this.current_threads_list"
        :posts="this.current_posts_list"
        :available_sets=this.available_sets
        :current_set=this.current_set
        :user_is_admin=this.user_is_admin
        @thread_set_request="get_threads_from_server"
        @toggle_sticky="toggle_sticky"
        @create_and_post_reply="create_and_post_reply"
        @remove_thread="remove_thread"
        @register_like="register_like"
        @hide_post="hide_post"
    ></forum_widget>


    <error_popup_queue></error_popup_queue>
  </section>


  <outside_footer class="footer_c">
  </outside_footer>
</template>

<script>
/* eslint-disable */
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import forum_widget from "@/components/parts/forum_widget";
import headers from "@/components/parts/headers";
import store from "@/store";
import {
  reentry_route, good_token_now, scroll_to_top,
  num_is_between_inclusive, colors, be_main_address, gather_threads, select_portion_of_list, main_forum_thread_count,
  prime_socket, receive_over_socket, timestamp
} from "@/library";
import axios from "axios";
export default {
  name: "forums",
  async mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    await main_forum_thread_count()
    await this.user_is_admin_on_forum()
    // this.available_sets_ = Math.ceil(await this.main_forum_thread_count()/this.block_size)
    // console.log(`Available Sets: ${this.available_sets}`)
  },
  updated() {
  },
  data() {
    return {
      colors: colors,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      current_set_: 1,
      available_sets_: 1,
      block_size: 10,
      pages_already_loaded: [1],
      remove_thread_orders: [],
      required_click_interval: 3,
      required_clicks_number: 4,
      user_is_admin_: false
    }
  },
  props: [
    'forum_name'
  ],
  components:
      {
        outside_footer,
        notification_popup,
        error_popup_queue,
        outside_banner_std,
        forum_widget,
        headers
      },
  methods: {
    add_to_set(the_array, thing) {
      the_array.push(thing)
      return [...new Set(the_array)];
    },
    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      // if (update) {
      //   store.commit('increment_universal_page_update', true)
      // }
    },
    user_is_admin_on_forum(){
       console.log("Run admin check from top of forums")
      // value['forum_name'] = 'main_forum'
      //   Call back end
      let target_url = be_main_address('forums') + 'user_is_admin_on_forum'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        forum_name: 'main_forum'
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            // console.log("Got back response on update post on main")
            console.log(response.data)
            if (response.data.error === false) {
              this.user_is_admin_ = response.data['data']['admin']
              // let d = {
              //   'forum_name': 'main_forum',
              //   'thread_id': this.thread_id
              // }
              // store.dispatch('remove_thread_from_forum', d)
              // store.commit('notification_enqueue', response.data['message'])
              // this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with remove_thread_on_main.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('error_enqueue', error_message)
            }
          }
      )
    },
    register_like(value) {
      console.log("Run register like from top of forums")
      // value['forum_name'] = 'main_forum'
      //   Call back end
      let target_url = be_main_address('forums') + 'update_post_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        post_id: value['post_id'],
        thread_id: value['thread_id'],
        actions: {'command': 'register_like'},
        like: value['like'],
        forum_name: 'main_forum'
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            // console.log("Got back response on update post on main")
            console.log(response.data)
            if (response.data.error === false) {
              // let d = {
              //   'forum_name': 'main_forum',
              //   'thread_id': this.thread_id
              // }
              // store.dispatch('remove_thread_from_forum', d)
              // store.commit('notification_enqueue', response.data['message'])
              // this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with remove_thread_on_main.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('error_enqueue', error_message)
            }
          }
      )
    },
    hide_post(value){
      console.log("Run hide post from the top...")
      // value['forum_name'] = 'main_forum'
      //   Call back end
      let target_url = be_main_address('forums') + 'hide_post_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        post_id: value['post_id'],
        thread_id: value['thread_id'],
        actions:  value['actions'],
        forum_name: 'main_forum'
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            // console.log("Got back response on update post on main")
            console.log(response.data)
            if (response.data.error === false) {
              // let d = {
              //   'forum_name': 'main_forum',
              //   'thread_id': this.thread_id
              // }
              // store.dispatch('remove_thread_from_forum', d)
              // store.commit('notification_enqueue', response.data['message'])
              // this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with hide_post_on_main.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('error_enqueue', error_message)
            }
          }
      )
    },
    order_thread_remove() {
      let orders = this.remove_thread_orders
      let required_interval = this.required_click_interval
      // console.log("Current ORders")
      // console.log(orders)
      let now = timestamp(true)
      if (orders.length > 0) {
        orders.reverse()
        let oldest = orders[0]
        // console.log(`Now: ${now} ==== Oldest: ${oldest}`)
        if (now - oldest > required_interval) {
          //The oldest order is more that three seconds old.  delete all, replate with now
          // console.log(`deleting all old orders, adding current now: ${now}`)
          this.remove_thread_orders = [now]
        } else {
          // console.log("pushing additional orders")
          this.remove_thread_orders.push(now)
        }
      }else{
        // console.log("pushing first order")
        this.remove_thread_orders.push(now)
      }
      // console.log("leaving orders function")
      // console.log(this.remove_thread_orders)
    },
    verify_removal_order() {
      let required_clicks = this.required_clicks_number - 1
      let required_interval = this.required_click_interval
      let orders = this.remove_thread_orders
      let count = orders.length
      if (count <= required_clicks) {
        // We need at least three valid orders to continue
        // console.log("Not enough orders")
        return false
      }
      orders.reverse()
      let oldest = orders[0]
      let now = timestamp(true)
      if (now - oldest > required_interval) {
        //THese orders are too old
        // console.log("these orders are too old")
        return false
      }else{
        // console.log("Remove order accepted")
        return true
      }
    },
    remove_thread(thread_id) {
      this.order_thread_remove()
      if (!this.verify_removal_order()) {
        return null
      }
      console.log(`Thread ID from bubbleup: ${thread_id}`)
      let target_url = be_main_address('forums') + 'remove_thread_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        thread_id: thread_id,
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            console.log("Got back response on remove_thread_on_main")
            console.log(response.data)
            if (response.data.error === false) {
              let d = {
                'forum_name': 'main_forum',
                'thread_id': this.thread_id
              }
              store.dispatch('remove_thread_from_forum', d)
              // store.commit('notification_enqueue', response.data['message'])
              // this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with remove_thread_on_main.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('error_enqueue', error_message)
            }
          }
      )
    },
    async create_and_post_reply(post_data) {
      let comment = post_data['post_text']
      if (comment.length === 0) {
        store.commit("notification_enqueue", "You cannot post an empty comment.")
        return null
      }
      let target_url = be_main_address('forums') + 'create_reply_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        post_data: post_data,
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            console.log("Got back response on posting a response")
            console.log(response.data)
            if (response.data.error === false) {
              // store.commit('notification_enqueue', response.data['message'])
              this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with register method.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('notification_enqueue', error_message)
            }
          }
      )
    },
    toggle_sticky(thread_id) {
      //   Toggle sticky on the selected thread.
      let target_url = be_main_address('forums') + 'update_thread_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        forum_name: 'main_forum',
        thread_id: thread_id,
        actions: {
          command: 'toggle_sticky'
        }
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              return null
            } else if (error.request) {
              return null
            } else {
              return null
            }
          }).then((response) => {
            console.log("Got back response on toggling sticky")
            console.log(response.data)
            if (response.data.error === false) {
              // store.commit('notification_enqueue', response.data['message'])
              // this.comment_text = ""
              // this.open_reply_false()
            } else {
              console.log("Something went wrong with register method.")
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('notification_enqueue', error_message)
            }
          }
      )
    },
    async get_threads_from_server(set_num) {
      //call server, get threads, append to threads list
      // console.log(`Forums widget requests set number ${set_num}`)
      this.current_set_ = set_num
      let main_forum_data = store.getters.get_main_forum_data
      let mf_block_size = store.getters.get_main_forum_block_size
      let direct = true
      // if (good_token_now()) {
      //   direct = false
      // }
      //Do we have the requested page already in vuex?
      // let res = select_portion_of_list(main_forum_data, set_num, mf_block_size)
      let res = this.current_threads_list
      if (res.length === 0) {
        //We do not have the currently requested page.  Request the threads.
        // console.log(`We do not have page ${set_num}.  Request the threads where direct is ${direct}.`)
        await gather_threads("main_forum", set_num, direct)
      } else {
        //We have the current page in vuex already, pass
        // console.log(`We already have page ${set_num}. No request needed.`)
      }
      //Do we already have the page following the requested page already in vuex?
      if (set_num + 1 <= this.available_sets) {
        // console.log(`Page ${set_num + 1} is a possibility. Check in vuex.`)
        let res2 = select_portion_of_list(main_forum_data['threads'], set_num + 1, mf_block_size)
        // console.log(`Ok, back from vuex. I got ${res2.toString()}`)
        if (res2.length === 0) {
          //We do not have the currently requested page.  Request the threads.
          // console.log(`We do not have page ${set_num + 1}.  Request the threads.`)
          await gather_threads("main_forum", set_num + 1, direct)
        } else {
          //We have the current page in vuex already, pass
          // console.log(`We already have page ${set_num + 1}. No request needed.`)
        }
      } else {
        // console.log(`Page ${set_num + 1} exceeds the available sets. No request needed.`)
      }
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (num_is_between_inclusive(100, 300, new_w)) {
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
    },
  },
  computed: {
    user_is_admin(){
      return this.user_is_admin_
    },
    current_set() {
      return this.current_set_
    },
    available_sets() {
      return store.getters.get_main_forum_available_sets
    },
    current_threads_list() {
      let main_forum = store.getters.get_main_forum_data
      // console.log("inside current threads list...here are the threads.")
      // console.log(JSON.stringify(main_forum))
      // console.log(JSON.stringify(main_forum['threads']))
      return select_portion_of_list(main_forum['threads'], this.current_set, this.block_size)
    },
    current_posts_list() {
      let main_forum = store.getters.get_main_forum_data
      return main_forum['posts']
    },
    // the_posts_list(){
    //
    //   if(this.current_set===1){
    //      return [this.post1, this.post2, this.post3, this.post4, this.post5, this.post6,
    //     this.post1a, this.post2a, this.post3a, this.post1b, this.post2b, this.post3b]
    //   }else{
    //      return [this.post1_2, this.post2_2, this.post3_2, this.post4_2, this.post5_2, this.post6_2,
    //     this.post1a_2, this.post2a_2, this.post3a_2, this.post1b_2, this.post2b_2, this.post3b_2]
    //   }
    //
    //
    // },
    // the_threads_list(){
    //   if(this.current_set === 1){
    //      return [this.thread1, this.thread2, this.thread3]
    //   }else{
    //      return [this.thread1_2, this.thread2_2, this.thread3_2]
    //   }
    // },
    windowWidth() {
      return store.state.windowWidth;
    }
    ,
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    }
    ,
  }
  ,
}
</script>

<style scoped>
@media (min-width: 100px) {
}
</style>