<template>
  <section class=" core_c">
    <outside_banner_std></outside_banner_std>
    <div class="flex_centered_row">
      <section class="flex_centered_start_column">
        <div class=" margin_tb_10 padding_lr_15 padding_tb_20  lineh_20 ">
          <!--          <div class="flex_space_between_row">-->
          <!--            <img :src="require('@/assets/' + 'homepage_fill.svg' + '')" class="back_out_nav" alt="Go to Home icon"-->
          <!--                 @click="go_to_home">-->
          <!--            <img :src="require('@/assets/' + 'profile_icon_fill.svg' + '')" class="back_out_nav"-->
          <!--                 alt="Go to profile icon" @click="go_to_profile">-->
          <!--          </div>-->

          <div class="agency_content ">
            <div class="flex_centered_column ">
              <!--              <div class="flex_centered_row">-->
              <img class="agency_landing_logo " :src="agency_logo" @click="do_nothing">
<!--              <div class="flex_centered_row">-->
                <h5 class="text_align_center ">{{ landing_headline }}</h5></div>
<!--            </div>-->
            <!--            </div>-->
            <div class="spacer"></div>
            <div class="agency_landing_text ">
              <p>{{ landing_body }}</p>
            </div>
          </div>

          <!--          <div class="main_agency_desc">-->
          <!--            <img class="agency_landing_logo " :src="agency_logo" @click="simple_route_prep('spyglass')">-->
          <!--            <div class="text_40 font_raleway font_weight_600 agency_title">{{ landing_headline }}</div>-->
          <!--            <div class="text_30 font_raleway margin_tb_10 margin_t_20 agency_desc">{{ landing_body }}-->
          <!--            </div>-->
          <!--          </div>-->

        </div>


        <div class="flex_centered_column">
          <div class="flex_centered_row flex_wrap">
            <div class="" v-for="item in agency_ads" :key="item.message">

              <schedule_ad_public
                  class="margin_tb_10 margin_lr_10 wid_95"
                  :neighborhood="item.neighborhood"
                  :headline="item.headline"
                  :body_text="item.body"
                  :posted="item.posted"
                  :freetime_stats="item.freetime_stats"
                  :expires="item.expires"
                  :ad_id="item.ad_id"
                  :phone_number="agency_phone"
                  :email_address="agency_email"
                  :agency_name=agency_name
                  event_name="ad_clicked"
                  @ad_clicked="do_nothing"

              ></schedule_ad_public>

              <!--          <schedule_ad-->
              <!--              class="margin_tb_10 margin_lr_10"-->
              <!--              :body_text="item.body"-->
              <!--              :headline="item.headline"-->
              <!--              :expires="item.expires"-->
              <!--              :neighborhood="item.neighborhood"-->
              <!--              :user_publisher="item.published_by"-->
              <!--              :posted="item.posted"-->
              <!--              :phone_number="agency_phone"-->
              <!--              :freetime_stats="item.freetime_stats"-->
              <!--              :ad_id="item.ad_id"-->
              <!--              @click_to_call="click_to_call"-->
              <!--          ></schedule_ad>-->
            </div>
          </div>
        </div>

      </section>
      <error_popup_queue></error_popup_queue>
      <notification_popup></notification_popup>
    </div>

  </section>
  <outside_footer class="footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import image_upload_widgit from "@/components/parts/image_upload_widgit";
import schedule_ad from "@/components/SpyGlass/schedule_ad";
import schedule_ad_public from "@/components/SpyGlass/schedule_ad_public";
import basic_text_button from "@/components/parts/basic_text_button";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_footer from "@/components/parts/outside_footer";
import outside_banner_std from "@/components/parts/outside_banner_std";
import {
  formatPhoneNumber,
  colors,
  be_main_address,
  parse_internal_json,
  analytic,
  get_profile_route, scroll_to_top,
  route_and_log, good_token_now
} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "spyglass_agency_landing_page_public",
  components: {
    image_upload_widgit,
    schedule_ad,
    schedule_ad_public,
    basic_text_button,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std
  },
  async mounted() {
    // add_class_to_body('bg_morgie_gray')
    scroll_to_top()
    await this.get_agency_from_ad_id_from_local()
    await this.get_agency_logo()
    await this.get_agency_landing_text(store.getters.org_context)

    // this.landing_text_ = await get_agency_landing_text_from_graph(store.getters.org_context)
    // console.log('HERE IS IS ON MOUNT.............................')
    // console.log(this.landing_text_)
    await this.get_agency_ads()
    await this.get_agency_phone_number()
    await this.get_agency_email()

    // analytic()

  },
  async updated() {
    //  try {
    //   await this.$nextTick(function () {
    //
    //     this.landing_text_ = get_agency_landing_text_from_graph(store.getters.org_context)
    //     console.log('HERE IS IS ON MOUNT.............................')
    //     console.log(this.landing_text)
    //
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  },
  data() {
    return {
      colors: colors,
      logo_: "",
      ads_: "",
      landing_text_: {},
      agency_name_: "",
      agency_phone_: "",
      agency_email_: "",
      // landing_body_: "Default Body",
      // landing_headline_: "Default Headline",
      agency_image_: "",
      ready_to_save_: false,
      phone_number_: ""
    }
  },
  computed: {
    agency_logo() {
      return this.logo_
    },
    agency_ads() {
      return this.ads_
    },
    landing_text() {
      return this.landing_text_
    },
    agency_name() {
      return this.agency_name_
    },
    agency_phone() {
      return this.agency_phone_
    },
     agency_email() {
      return this.agency_email_
    },


    landing_body() {
      // return this.landing_text['body']
       try{
       return this.landing_text['body']
      }catch(error){
        return ""
      }
    },
    landing_headline() {
      try{
        return this.landing_text['headline']
      }catch(error){
        return ""
      }

    },
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async click_to_call(ad_id) {
      analytic({
        agency: this.agency_name,
        agency_phone: this.agency_phone,
        user: store.getters.my_name,
        ad_id: ad_id,
        event: "click to call agency"
      })
    },
    async go_to_profile() {
      // this.hamburger_menu_false()
      await this.$router.push({path: `${get_profile_route()}`})
    },
    async go_to_home() {
      // this.hamburger_menu_false()
      await this.$router.push({path: '/'})
    },

    async get_agency_landing_text(agency=null) {
      if(agency){
        //pass
      }else{
         agency = store.getters.spyglass_agency_landing_public_get_agency_name
      }

      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_landing_text'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.landing_text_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
        // console.log('HERE IS IS.............................')
        // console.log(this.landing_text_)
      })
    },
    async get_agency_phone_number() {
      // console.log("Calling to get the agency phone number.")
      let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_phone_number'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.agency_phone_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
      })
    },

    async get_agency_email() {
      // console.log("Calling to get the agency phone number.")
      let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_email'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.agency_email_ = all['data']['data']
        // console.log('Agency email address is .................................................')
        // console.log(this.agency_email_)
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
      })
    },

    async get_agency_from_ad_id_from_local() {
      // console.log("in get_agency_from_ad_id")
      // console.log(`Ad Id is ${ad_id}`)
      let ad_id_data_json = localStorage.getItem('clicked_sg_ad')
      let ad_id_data = JSON.parse(ad_id_data_json)
      let ad_id = ad_id_data['ad_id']

      if (!good_token_now) {
        let message = 'Create a free account to view available schedules. <br><br> <a href="/register">Register</a> or <a href="/login">Log in</a>'
        store.commit('notification_enqueue', message)
        return null
      }

      let data1 = {
        ad_id: ad_id
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_from_ad_id'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        let agency = all['data']['data']
        this.agency_name_ = agency
        store.commit('spyglass_agency_landing_public_set_agency_name', agency)
        analytic({
          'event': 'spyglass agency landing page loaded',
          'agency': agency,
          'ad_id': ad_id,
        })
      })
    },
    async get_agency_ads() {
      let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_ads'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.ads_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)

        // console.log(this.ads_)
      })
    },
    async get_agency_logo() {
      // console.log("in get agency logo")
      // console.log("Checkpoint 1")
      let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let agency_logo = await store.dispatch('get_spyglass_agency_landing_public_logo', agency)
      // console.log("Checkpoint 2")
      // console.log(agency_logo)
      if (agency_logo) {
        // console.log("Checkpoint 3")
        this.logo_ = agency_logo
        return null
      }
      // console.log("Checkpoint 4")
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_logo'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.logo_ = all['data']['data']
        let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)

        // console.log(this.logo_)
      })
    },

    async save_landing_texts() {
      console.log("you just clicked to save to the back end.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: this.prepare_landing_texts()
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'save_agency_landing_texts'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          this.ready_to_save_ = false
          store.commit('spyglass_agency_landing_set_ready_to_save', false)
          let message = parse_internal_json(response.data)['message']
          store.commit('notification_enqueue', message)
        }
      }, (error) => {
        console.log('Error on save_agency_landing_texts.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to save your landing page content.")
      });
    },

    local_phone_format(phone) {
      return formatPhoneNumber(phone)
    },
    simple_route_prep(r, analytic_log = null) {
      // console.log("in simple route prep")
      // this.hamburger_menu_false()
      route_and_log(this.$router, r, analytic_log)
    },

  }
}
</script>

<style scoped>


@media (min-width: 100px) {

  .agency_landing_logo {
    height: 80px;
    margin-bottom: 20px;
  }

  .spacer {
    width: 50px;
  }

  .agency_content {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    /*justify-content: space-between;*/
    align-items: center;

  }

  .agency_landing_text {
    max-width: 95%;
    padding: 0;
    line-height: 1.5rem;
  }

  .agency_content h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .agency_landing_text p {
    font-size: 14px;
    max-width: 500px;
  }

  .agency_landing_logo {
    height: 80px;
    /*margin-left: 0px;*/

    margin-bottom: 20px;
  }

}

@media (min-width: 800px) {
  .agency_content {
    /*max-width: 100vw;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    margin-bottom: 40px;

  }

  .agency_landing_logo {
    height: 110px;
    margin-bottom: 20px;
  }

  .agency_landing_text {
    max-width: 65%;
    padding: 0 30px;
  }

  .agency_landing_text p {
    font-size: 18px;
    max-width: 700px;
  }

  .agency_content h5 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .agency_content {
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

  }
}


@media (min-width: 2000px) {
  .agency_landing_logo {
    height: 150px;
    margin-bottom: 20px;
  }

  .agency_content {
    margin-bottom: 40px;
  }

  .agency_landing_text {
    max-width: 65%;
    padding: 0 30px;
  }

  .agency_landing_text p {
    font-size: 26px;
    max-width: 900px;
  }

  .agency_content h5 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

</style>