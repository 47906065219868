<template>
  <section>
    <div class="left-inner-addon">
      <img role="img" style="width:16px;" src="@/assets/arrow_down_gray.svg"/>
      <input class="the_input" type="text" v-model="datum" :list=unique_id :placeholder=label @change="onChange($event)">
      <datalist :id=unique_id @change="onChange($event)">
        <option v-for="datum in data_list" :key=datum>{{ datum }}</option>
      </datalist>
    </div>

  </section>
</template>

<script>

import {v4 as uuidv4} from "uuid";

export default {
  name: "dropdown_datalist",

  props: {
    data_list: Array,
    label: String,
    start_value: String,
  },

  async mounted() {
    this.unique_id = uuidv4();
    if (this.start_value) {
      this.datum = this.start_value
    }
  },
  async updated() {

    await this.$nextTick(function () {
      try {
        if (this.start_value) {
          this.seed = this.start_value
        }
      } catch (error) {
        // console.error(error)
      }

    })
  },
  data() {
    return {
      unique_id: "",
      datum: "",
      seed: "",
      a_state: "",
      the_data: this.data_list

    }
  },
  methods: {
    onChange(e) {
      console.log("in drop down on change")
      console.log(e)

      this.$emit('value-selected', e.target.value)
    },
    reset_state() {
      this.datum = ""
    },
    get_value() {
      return this.datum
    },
    emit_state(){
      this.$emit('emit_state',this.datum)
    }
  },
}
</script>

<style scoped>


/*Thanks to ATP from https://stackoverflow.com/questions/67786076/input-with-list-datalist-remove-dropdown-arrow-in-chrome-version-91*/
.the_input::-webkit-calendar-picker-indicator {
  display:none !important;
}

@media (min-width: 100px) {


  /*.dropdown-container select {*/
  /*  !* for Firefox *!*/
  /*  -moz-appearance: none;*/
  /*  !* for Safari, Chrome, Opera *!*/
  /*  -webkit-appearance: none;*/
  /*}*/

  /*!* for IE10 *!*/
  /*.dropdown-container select::-ms-expand {*/
  /*  display: none;*/
  /*}*/


  .left-inner-addon {
    position: relative;
    /*width: 200px;*/
  }

  .left-inner-addon input {
    padding-left: 24px;
    -moz-appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
    /*width: 170px; !* 200px - 30px *!*/
  }

  .left-inner-addon img {
    position: absolute;
    right: 20px;
    top: 1.5rem;
    pointer-events: none;
  }


  input {
    margin-bottom: 10px;
    width: calc(100% - 4px);
    height: 30px;
    padding: 26px;
    padding-left: 46px;
    box-sizing: border-box;
    /*border: 1px solid var(--cyanic-blue) !important;*/
    border: 1px solid var(--morgie_gray) !important;
  }

  /*input:focus {*/
  /*  border: 1px solid var(--primary-purple);*/
  /*}*/
  input:focus {
    border: 1px solid var(--primary-purple) !important;
    /*background-color: var(--new-cyanic-main-light_50);*/
    box-shadow: 0 0 3px var(--primary-purple) !important;
    -moz-box-shadow: 0 0 3px var(--primary-purple) !important;
    -webkit-box-shadow: 0 0 3px var(--primary-purple) !important;
    outline-offset: 0 !important;
    outline: none !important;
  }

  /*input:focus {*/
  /*  outline-offset: 0 !important;*/
  /*  outline: none !important;*/
  /*}*/
}
</style>