<template>
  <section v-if="!mobile_mode" class="flex_start_row  whole_unit cursor_ptr bg_lauren_gray  " >

    <div class="flex_start_row" >
      <div class="module_icon">
        <basic_icon_button
            ref="module_icon_image"
            class="margin_tb_20 margin_lr_10"
            :ready="true"
            button_color="transparent"
            :button_color_not_ready="colors.pennie_gray"
            :button_height=module_button_size
            :button_width=module_button_size
            border_rad_pct='button_border_rad'
            event_name="module_clicked"
            @module_clicked="emit_state"
            :image_path=module_icon
            :image_width=module_image_size
            alt_text="Module Icon Image"
        ></basic_icon_button>
      </div>
      <div class="flex_left_aligned_centered_column main_text_column">
        <div class="module_name font_raleway font_weight_800">{{ module_title }}</div>
      </div>

    </div>
    <div v-if="show_del_button" class="  ">
      <div class="flex_start_column" @click="delete_agency_local_document_module()">
        <basic_icon_button
            ref=""
            class="  cursor_ptr x_del_button p_rel z_300 "

            :ready="true"
            button_color="transparent"
            :button_color_not_ready="colors.pennie_gray"
            button_height=5px
            button_width=5px
            border_rad_pct='button_border_rad'
            event_name="delete_module_clicked"
            @delete_module_clicked="delete_agency_local_document_module()"
            image_path='gray_exit.svg'
            image_width=20px
            alt_text="Module Icon Image"
        ></basic_icon_button>
      </div>


    </div>

  </section>

  <section v-if="mobile_mode" class="flex_centered_column flex_wrap whole_unit bg_lauren_gray whole_unit_mobile"
           @click="emit_state_whole">
    <div v-if="show_del_button" class="flex_end_row wid_100"  @click="delete_agency_local_document_module()">
      <div class="flex_start_column" >
        <basic_icon_button
            ref=""
            class="  cursor_ptr x_del_button"
            :ready="true"
            button_color="transparent"
            :button_color_not_ready="colors.pennie_gray"
            button_height=5px
            button_width=5px
            border_rad_pct='button_border_rad'
            event_name="delete_module_clicked"
            @delete_module_clicked="delete_agency_local_document_module"
            image_path='red_x.svg'
            image_width=20px
            alt_text="Module Icon Image"
        ></basic_icon_button>
      </div>


    </div>

    <div @click="emit_state">
      <div class="module_icon flex_centered_column ">
        <basic_icon_button
            ref="module_icon_image"
            class="margin_tb_20 margin_lr_10 cursor_ptr"
            :ready="true"
            button_color="transparent"
            :button_color_not_ready="colors.pennie_gray"
            :button_height=module_button_size
            :button_width=module_button_size
            border_rad_pct='button_border_rad'
            event_name="module_clicked"
            @module_clicked="emit_state"

            :image_path=module_icon
            :image_width=module_image_size
            alt_text="Module Icon Image"
        ></basic_icon_button>
      </div>
      <div class="flex_centered_column main_text_column">
        <div class="module_name_mobile font_raleway font_weight_800">{{ module_title }}</div>
      </div>
    </div>

  </section>


</template>

<script>
import {mapGetters} from "vuex";
import basic_icon_button from "@/components/parts/basic_icon_button";
import {colors} from "@/library";
// import {Database} from "@/client_db";
// import {prime_socket} from "@/library";

export default {
  name: "agency_local_document_display_unit",
  emits: ['module_clicked', 'delete_module_clicked'],
  props: {
    module_data: Object,
    del_button: Boolean

  },
  async mounted() {
    // this.db = new Database();
    // await this.load_completed_modules()
    // await prime_socket(this.local_message_handler)
    console.log(`Mountintg with show module of ${this.module_data}`)
    this.run_window_width_adjustments(this.window_width)
  },
  components: {
    basic_icon_button,
  },
  data() {
    return {
      button_size_: null,
      button_img_size_: "60px",
      colors: colors,
      mobile_mode_: true,
      desktop_cutoff: 999,

    }
  },
  methods: {
    emit_state_whole() {
      // console.log("The module has been clcicked 2")
      // console.log(this.module_data)
      if (!this.del_button) {
        this.$emit('module_clicked', this.module_data)
      }

    },
    emit_state() {
      // console.log("The module has been clcicked")
      // console.log(this.module_data)
      this.$emit('module_clicked', this.module_data)
    },
    delete_agency_local_document_module() {
      console.log(`You clicked to delete agency local document ${this.module_data}`)
      console.log(JSON.stringify(this.module_data))
      this.$emit('delete_module_clicked', this.module_data)
    },
    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // console.log(`New size is ${new_w}`)
        this.mobile_mode_ = new_w < this.desktop_cutoff;

        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "50px"
        this.button_img_size_ = "60px"
      }
      if (new_w > 500) {
        this.mobile_mode_ = new_w < this.desktop_cutoff;
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 700) {
        this.mobile_mode_ = new_w < this.desktop_cutoff;
        // console.log(`New size is ${new_w}. Desktop mode active`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 1000) {
        this.mobile_mode_ = new_w < this.desktop_cutoff;
        // console.log(`New size is ${new_w}. Desktop mode active`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        this.mobile_mode_ = new_w < this.desktop_cutoff;
        // console.log(`New size is ${new_w}. Desktop mode active`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },
  },
  computed: {
    ...mapGetters([
      'window_width',
    ]),
    mobile_mode() {
      return this.mobile_mode_
    },


    show_del_button() {
      return this.del_button

    },
    module_icon_file() {
      return this.module_data['icon_file']
    },
    module_active() {
      return this.module_data['document_active']
    },
    module_title() {
      return this.module_data['title']
    },
    module_id() {
      return this.module_data['document_id']
    },
    module_creator() {
      return this.module_data['creator']
    },
    module_doc_type() {
      return this.module_data['doc_type']
    },
    module_icon() {
      let file_name = this.module_data['icon_file']
      return file_name

    },
    module_button_size() {
      console.log("Running the module size computed property.")
      return this.button_size_
    },
    module_image_size() {
      return this.button_img_size_
    },
  },

  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {

  .x_del_button {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .whole_unit {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .stats_column_mobile {
    margin-top: 20px;
  }

  .stats_row_mobile {
    width: 165px;
    font-size: 15px;
  }

  .whole_unit_mobile {
    margin-bottom: 10px;
    padding-bottom: 30px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .main_text_column {
    width: 190px;
  }

  .module_name_mobile {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .module_desc_mobile {
    font-size: 10px;
    font-weight: bold;
    max-width: 180px;
  }
}

@media (min-width: 500px) {
  .main_text_column {
    width: 200px;
  }

  .module_name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .module_desc {
    font-size: 12px;
    font-weight: bold;
  }
}

@media (min-width: 1000px) {
   .x_del_button {
    margin-bottom: 10px;
    margin-right: 10px;
     margin-left: 10px;
    margin-top: 10px;
  }

  .main_text_column {
    width: 350px;
  }

  .module_name {
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
  }


  .module_desc {
    font-size: 14px;
    font-weight: bold;
  }

  .module_icon {
    /*margin-right: 30px;*/
  }

  .stats_column {
    margin-left: 20px;
    width: 160px;
  }

}

/*@media (min-width: 100px) {}*/
/*@media (min-width: 100px) {}*/

</style>