<template>
  <section class="core_c flex_centered_start_column" >
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>

    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column text_align_center">
        <h4 class="resend">Resend Registration Confirmation Code</h4>
        <input_with_label
            ref="resend_confirmation_box"
            class="login_input"
            label="Your User Name"
            @emit_state="catch_resend_confirmation_reset"
        ></input_with_label>



        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="CONFIRM"
            text_size="14px"
            :bold="true"
            event_name="confirm_button_clicked"
            @confirm_button_clicked="resend_registration_confirmation_code"
            tabindex="0"
        ></basic_text_button>

        <div class="flex_centered_row  " id="links_row">
          <div class="flex_centered_row links cursor_ptr" @click='simple_route_prep("register_confirm")'>Confirm
            Registration
          </div>
        </div>

      </div>
    </div>
  </section>
  <outside_footer id="footer" class="footer_c">
  </outside_footer>


</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {be_main_address, validate_basic_name} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";

import {
  colors
} from "@/library";

export default {
  components: {

    notification_popup,
    basic_text_button,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label
  },
  data() {
    return {
      colors: colors,
      name: '',
      next_route: '',
      error_out: false,
      mobile_mode: true
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  methods: {
    tester() {
      // console.log("QR CODE WAS CLICKED.")
    },

    catch_resend_confirmation_reset(username_in) {
      console.log(`Requesting new confirmation code for ${username_in}`)
      let username = username_in.trim()
      // validate
      if (validate_basic_name(username)) {
        this.name = username
      } else {
        let m = "The user name you entered is not valid."
        this.error_out = true
        store.commit('error_enqueue', m)
      }
    },
    simple_route_prep(r) {
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },


    async resend_registration_confirmation_code() {
      console.log("Getting ready to confirm new user...")
      console.log(`Current password from state is: ${this.password}`)

      // let the_router = this.$router

      this.$refs.resend_confirmation_box.emit_state()
      if (this.error_out) {
        this.error_out = false
        return null
      }

      // let error_out
      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "resend_registration_confirmation_code"
      await axios.post(target, {
        name: this.name,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad confirmation code resend attempt")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          return null
        }
      }).then((response) => {
        console.log(response)
            // console.log("Got good response toconfirmation. Should get back qr code string.");
            if (response.data.error === false) {
              store.commit('notification_enqueue', response.data['message'])

              this.$router.push('/register_confirm');
            } else {
              console.log("Something went wrong with trying to get a new confirmation code.")
              console.log(response.data)
            }
          }
      )
    },


  },
  computed: {
    show_qr_code_backer() {
      return this.show_qr_code_backer_x
    },
  }
}
</script>

<style scoped>
@import '../../common.css';


.main_column {
  max-width: 75%;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .resend {
    margin-bottom: 40px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }


  .main_column {
    max-width: 75%;
  }

}

@media (min-width: 500px) {


  .main_column {
    max-width: 75%;
  }


}

@media (min-width: 770px) {


  .main_column {
    max-width: 75%;
  }


}

@media (min-width: 770px) {


  .main_column {
    max-width: 75%;
  }


}


</style>