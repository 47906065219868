<template>
  <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column net_box">

     <div class="flex_centered_row">
      <h3>Network Status</h3>
    </div>


<!--    <h3 class="  ">Basic Agency Info</h3>-->
    <div  class="non_clickable_context_item"> Socket Connection: {{ socket_connection }}</div>
    <div class="non_clickable_context_item">Token Time Remaining: {{ token_time }}</div>
    <div class="non_clickable_context_item ">Network Speed: {{network_speed}}</div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {colors} from "@/library";
import store from "@/store";
export default {
  name: "network_status_box",
  props: [],
  async mounted() {
    this.id = uuidv4();
  },
  data() {
    return {
      colors: colors,
      id: "",
    }
  },
  methods: {
    //  image_name(name){
    //   return image_name(name)
    // },
    async non_contextual_route(route = null) {
      // this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
  },

  computed: {
     network_speed(){
      return localStorage.measured_kbps
    },

    token_time(){
      // return this.token_time
      return store.getters.get_token_time
    },

    socket_connection(){
       return store.getters.get_socket_connection_status
    },

  },
  watch: {
    token_time(new_t){
      return new_t
    },

  },
}
</script>

<style scoped>
@media (min-width: 100px) {

}


@media (min-width: 1200px) {

  .net_box{
    padding-bottom: 30px;
    padding-top: 20px;
  }

}
</style>