<template>
  <section class="flex_centered_row  flex_wrap card p_rel">
    <div class="flex_space_between_column   card_shape bg_white
                   margin_t_10   wid_85 card_body">
      <div class="flex_centered_start_column card_col flex_grow padding_t_20">
<!--        <span class="material-symbols-outlined material_icon" :id="this.icon_id"></span>-->
        <img class="material_icon"
                :src="image_name"
                :alt=desc>
        <!--        <span class="material-symbols-outlined material_icon p_abs" :id="this.icon_id"></span>-->
        <h3 class="text_align_center" :id="this.headline_id">
          <slot name="headline"></slot>
        </h3>
        <p class="  lineh_35 ">
          <slot name="body"></slot>
        </p>
        <h4 v-if="video_id" class="watch_vid cursor_ptr " :id="this.watch_id"
            @click="video_modal_true"><strong>Watch Video >></strong>
        </h4>
      </div>


      <video_modal
          v-if="video_id"
          :show_modal=modal_up
          :video_id="video_id"
          :base_height="base_video_height"
          :base_width="base_video_width"
          @bg_clicked="video_modal_false"
      ></video_modal>

      <div class="flex_centered_row" v-if="show_button">
        <basic_text_button
            class="padding_lr_6 padding_tb_6  let_spa3 learn_button home_card_btn_border"
            :hover_color=hover_color
            :hover_text_color=hover_text_color
            :button_state="true"
            :button_color="colors.white"
            :text_color="button_text_color"
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            :button_height="button_hei"
            :button_width="button_wid"
            border_rad_pct="0"
            :text="button_text"
            :text_size="button_text_size"
            :bold="true"
            event_name="learn_more_button_clicked"
            @learn_more_button_clicked="main_button_clicked"
        ></basic_text_button>
      </div>
    </div>
  </section>
</template>

<script>
import {analytic, colors, image_name} from "@/library";
import {v4 as uuidv4} from "uuid";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import store from "@/store";

export default {
  name: "home_card2",
  emits: ["bg_clicked", "main_btn_clicked"],
  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 700,
      hide_button_: false,
      button_wid_: '100px',
      button_height_: '50px',
      button_text_size_: '17px',
      video_modal: {
        state: false
      },
      watch_id: "",
      icon_id: "",
      headline_id: "",
      material_icon_name: "",
      // id_text: "",
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        let watch
        let icon
        let headline
        watch = document.getElementById(this.watch_id)
        icon = document.getElementById(this.icon_id)
        headline = document.getElementById(this.headline_id)

         // console.log("in the updated part")
         // console.log(`Header color is ${this.headline_color}`)

        if (this.hide_button === true) {
          this.hide_button_ = true
        }

        if (this.headline_color) {
          // console.log("in change header color")
          headline.style.color = this.headline_color
        }



        // console.log(`In home card, hover color ${this.hover_color} `)
        // console.log(`In home card, icon color ${this.icon_color} `)

        icon.style.color = this.icon_color
        icon.style.backgroundColor = this.icon_bg_color
        // icon.style.color = this.button_color
        try {
          // watch.style.color = this.button_color
          watch.style.color = colors.wyatt_gray
        } catch (error) {
          //pass
        }
        icon.textContent = this.material_icon_name
      })
    } catch (error) {
      //pass
    }
  },
  async mounted() {
    this.icon_id = uuidv4();
    this.watch_id = uuidv4();
    this.headline_id = uuidv4();
    this.material_icon_name = this.icon

    if (this.hide_button === true) {
      this.hide_button_ = true
    }

    this.run_window_width_adjustments(this.windowWidth)
  },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    //  image_name(name){
    //   return image_name(name)
    // },
    async video_modal_true() {
      analytic(`${this.desc}::video modal opened:CTA clicked`)
      this.video_modal.state = true
    },
    video_modal_false() {
      this.video_modal.state = false
    },
    main_button_clicked() {
      this.$emit('main_btn_clicked', true)
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 369, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "16px"
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(370, 399, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(400, 800, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(501, 1499, new_w)) {
        this.button_wid_ = "180px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(1500, 6000, new_w)) {
        this.button_wid_ = "190px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }


    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
  },
  props: [
    'video_id',
    'base_video_height',
    'base_video_width',
    'button_text',
    'button_text_color',
    'button_color',
    'icon',
    'icon_color',
    'icon_bg_color',
    'hover_color',
    'hover_text_color',
    'headline_color',
    'desc',
    'hide_button'
  ],

  components: {
    basic_text_button,
    video_modal
  },
  computed: {
    image_name() {
      if (this.material_icon_name !== '') {
        // return require(`@/assets/${this.material_icon_name}`);
        return image_name(this.material_icon_name)
      }else{
        return ""
      }
    },
    modal_up() {
      return this.video_modal.state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return this.button_wid_
    },
    button_hei() {
      return this.button_height_
    },
    button_text_size() {
      return this.button_text_size_
    },
    show_button() {
      return !this.hide_button_
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

h4 {
  color: var(--eli_gray);
}

.card {
  max-width: 100vw;
}

.home_card_btn_border {
  border: 2px solid var(--eli_gray);
  /*border: 1px solid red;*/
}

.card_body {
  padding-bottom: 15%;
  padding-top: 10%;
}

@media (min-width: 100px) {

  .card_shape {
    width: 100vw;
    max-width: 400px;
    /*border-radius: 20px;*/
    /*background: linear-gradient(90deg, var(--primary-purple-mid-light), var(--primary-purple-mid) 50.0%);*/
  }

  /*.material_icon_bg {*/
  /*  border-radius: 50%;*/
  /*  height: 70px;*/
  /*  width: 70px;*/
  /*}*/

  .material_icon{
     height: 67px;
     width: 67px;
  }

  .card_col {
    padding-left: 12px;
    padding-right: 12px;
  }

  h3 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .learn_button {
    margin-top: 20px;
  }


}

@media (min-width: 400px) {

    .material_icon{
     height: 77px;
     width: 77px;
  }
    /*.material_icon_bg {*/
    /*margin-top: -94px;*/
  /*}*/

  .card_col {
    padding-left: 12px;
    padding-right: 12px;
  }

  h3 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .learn_button {
    margin-top: 20px;
  }


}

@media (min-width: 500px) {
  .card_col {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 700px) {
  .card_col {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1500px) {

  .card_shape {
    width: 100vw;
    max-width: 450px
  }
}

@media (min-width: 2000px) {

  .card_shape {
    width: 100vw;
    max-width: 500px
  }
}
</style>