<template>
  <div class="core_c">
    <inside_banner
        mode="speedydelivery_user"
    ></inside_banner>
    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>

    <div class="exam_form_wrapper">
      <exam_with_slots_form
          :questions_texts="questions_texts"
          :header_text=get_test_name_header()
          module_name="dd7a984c-4cc7-47c6-9272-776d2d6cf0a2"
      >

        <multiple_choice_and_question
            ref="q_one"
            number_of_choices=4
            question_num="one"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.one
        ></multiple_choice_and_question>


        <div class="spacer"></div>

        <multiple_choice_and_question
            ref="q_two"
            number_of_choices=4
            question_num="two"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.two
        ></multiple_choice_and_question>

        <div class="spacer"></div>

        <multiple_choice_and_question
            ref="q_three"
            number_of_choices=4
            question_num="three"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.three
        ></multiple_choice_and_question>

        <div class="spacer"></div>

        <multiple_choice_and_question
            ref="q_four"
            number_of_choices=4
            question_num="four"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.four
        ></multiple_choice_and_question>

        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_five"
            question_num="five"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.five
        ></true_false_and_question2>

        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_six"
            question_num="six"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.six
        ></true_false_and_question2>

        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_seven"
            question_num="seven"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.seven
        ></true_false_and_question2>

        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_eight"
            question_num="eight"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.eight
        ></true_false_and_question2>

        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_nine"
            question_num="nine"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.nine
        ></true_false_and_question2>

        <div class="spacer"></div>

        <fill_in_the_blank_question
            class="a_question"
            ref="q_ten"
            question_num="ten"
            :question_text=this.questions_texts.ten
            @true_value_update="update_answer_value"
        ></fill_in_the_blank_question>

        <div class="spacer"></div>

      </exam_with_slots_form>
    </div>


    <div class="flex_centered_row">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 submit_ans_button"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="all_questions_answered"
          :button_color="colors.primary_purple"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="200px"
          border_rad_pct="0"
          text="Submit Your Answers"
          text_size="17px"
          :bold="true"
          event_name="submit_answers_button_clicked"
          @submit_answers_button_clicked="post_test_answers"
      ></basic_text_button>

    </div>

  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import multiple_choice_and_question from "@/components/parts/multiple_choice_and_question";
import exam_with_slots_form from "@/components/basics/exam_with_slots_form";
import true_false_and_question2 from "@/components/parts/true_false_and_question2";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import fill_in_the_blank_question from "@/components/parts/fill_in_the_blank_question.vue";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  be_main_address,
  parse_internal_json, get_speedy_user_interface_route,
  non_contextual_route, getmodulename, getmodulename_and_module_test, get_sd_module_meta
} from "@/library";
import axios from "axios";
import inside_banner from "@/components/parts/inside_banner.vue";
import double_fill_in_the_blank_question from "@/components/parts/double_fill_in_the_blank_question.vue";

export default {
  name: "abuse",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    double_fill_in_the_blank_question,
    fill_in_the_blank_question,
    inside_banner,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner,
    exam_with_slots_form,
    multiple_choice_and_question,
    basic_text_button,
    true_false_and_question2,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      module_name: "dd7a984c-4cc7-47c6-9272-776d2d6cf0a2",
      answers_value: {
        'one': null,
        'two': null,
        'three': null,
        'four': null,
        'five': null,
        'six': null,
        'seven': null,
        'eight': null,
        'nine': null,
        'ten': null,
      },
      questions_texts: {
        one: {
          'question': "Hepatitis means inflammation of the:",
          'answers': {
            a: "Kidneys",
            b: "Gallbladder",
            c: "Liver",
            d: "Stomach",
          },
        },
        two: {
          'question': "Hepatitis A is spread by:",
          'answers': {
            a: "Fecal-Oral route.",
            b: "Breathing airborne virus.",
            c: "Exposure to infected blood.",
            d: "An accidental needlestick injury.",
          },
        },
        three: {
          'question': "Which of the following is at risk of contracting Hepatitis B?",
          'answers': {
            a: "Healthcare workers.",
            b: "Drug users who share needles.",
            c: "People with diabetes.",
            d: "All of the above.",
          },
        },
        four: {
          'question': "Hepatitis A and B are known as 'silent diseases'. This means:",
          'answers': {
            a: "They cause hearing loss.",
            b: "People can have and spread HAV or HBV without knowing it.",
            c: "People are embarrassed to talk about them.",
            d: "None of the above.",
          },
        },
        five: "Encourage clients with HAV or HBV to take Tylenol for pain, as needed.",
        six: "The Hepatitis B virus can live outside the body for up to a month.",
        seven: "One way te prevent the spread of HBV is to use Standard Precautions with all your clients, all the time ... even if the don't seem sick.",
        eight: "There is no cure for Hepatitis A or B.",
        nine: "Lancets for diabetic glucose testing should never be shared.",
        ten: "The best way to prevent both HAV and HBV is to get __________.",
      },

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
     get_module_name(){
      return getmodulename(this.module_name)
    },

    get_test_name_header(){
      let e = get_sd_module_meta(this.module_name)
      return e.name
    },
    update_answer_value(values) {
      let value = values[0]
      let question = values[1]
      this.answers_value[question] = value
      // console.log("current values are.....")
      // console.log(this.answers_value)

    },
    async post_test_answers() {
      store.commit('set_spinner_state', true)
      let module_name = this.module_name
      let answers = this.answers_value
      console.log(`Answers.....`)
      console.log(answers)
      console.log("you just clicked to save to the back end.")
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_id: module_name,
        answers: answers
      }
      // await this.get_new_route()
      let endpoint = be_main_address('speedydelivery') + 'submit_test_results'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        let message = response.data['message']
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('set_spinner_state', false)
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          store.commit('set_spinner_state', false)
          store.commit('notification_enqueue', message)

          console.log(message)
          if (message.includes('Congratulations')) {
            console.log("The message contained congratulations.")
            store.commit('set_spinner_state', false)
            this.$router.push({path: `${get_speedy_user_interface_route()}`})
          } else {
            store.commit('set_spinner_state', false)
            console.log("The message did not contain congratulations.")
          }
        }
      }, (error) => {
        console.log('Error on submission of test results.')
        console.log(error);
        store.commit('set_spinner_state', false)
        store.commit('error_enqueue', "Something went wrong trying to submit your test results.")
      });

    },
    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

  },
  computed: {
    id() {
      return this.id_
    },
    // spinner_up() {
    //   return store.getters.get_spinner_state
    // },
    windowWidth() {
      return store.state.windowWidth;
    },
    update_page() {
      console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    all_questions_answered() {
      console.log('in all questions answered.')
      if (
          this.answers_value['one'] !== null &&
          this.answers_value['two'] !== null &&
          this.answers_value['three'] !== null &&
          this.answers_value['four'] !== null &&
          this.answers_value['five'] !== null &&
          this.answers_value['six'] !== null &&
          this.answers_value['seven'] !== null &&
          this.answers_value['eight'] !== null &&
          this.answers_value['nine'] !== null &&
          this.answers_value['ten'] !== null
      ) {
        console.log("all are")
        return true
      } else {
        return false
      }
    },
    ans_one() {
      return this.answers_value['one']
    },
    ans_two() {
      return this.answers_value['two']
    },
    ans_three() {
      return this.answers_value['three']
    },
    ans_four() {
      return this.answers_value['four']
    },
    ans_five() {
      return this.answers_value['five']
    },
    ans_six() {
      return this.answers_value['six']
    },
    ans_seven() {
      return this.answers_value['seven']
    },
    ans_eight() {
      return this.answers_value['eight']
    },
    ans_nine() {
      return this.answers_value['nine']
    },
    ans_ten() {
      return this.answers_value['ten']
    },
  },
  watch: {

    async update_page() {
      console.log("update_page watcher is triggered")
      // await this.local_page_update()
    },

    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },

  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  .exam_form_wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
  }

  .spacer {
    height: 50px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {
}

@media (min-width: 1200px) {

  .spacer {
    height: 50px;
    /*background-color: green;*/
  }

  .submit_ans_button {
    margin-bottom: 100px;
  }

}

@media (min-width: 2000px) {
}
</style>