<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <div class="flex_centered_row margin_b_40">
      <div class="flex_centered_start_column">


        <div class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/istock/help_sign.jpg"
                     alt="Picture of a young brown-haired female sitting on a light gray couch wearing teal colored scrubs
                   and smiling while looking down at a notebook that she is holding. Beside her sits an elderly woman
                   with a light colored dress holding a cup and saucer, smiling and also looking at the notebook.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h3 class="text_align_center">Have a question about Tiny Magic Hat or about finding a job?</h3>
              <!--              <h3 class="text_align_center">$18+ per hour for Full Time Caregivers</h3>-->
              <p class="text_align_center top_text">

                Tell us what you are looking for and we will try to help.
              </p>
            </div>
          </div>
        </div>

        <h3 class="text_align_center">How can we help you?</h3>

        <div v-for="(item, index) of chat_messages" :key="`item-${index}`" class="flex_start_column wid_85 margin_b_40 chat_messages">
          <p v-if="item[0] === 'You'" class="flex_align_self_end max_w_95vw">
            <strong>{{ item[1] }}</strong>
          </p>
          <p v-if="item[0] === 'Shiloh'" class="flex_align_self_start max_w_95vw">
            {{ item[1] }}
          </p>
        </div>

        <input_with_label_text_area
            ref="chat_prompt_box"
            class="form_input"
            place_holder_text="Just ask and we will try to help."
            :columns="columns_num"
            :rows="rows_num"
            @emit_state="catch_prompt"
            @enter_pressed="submit_prompt"
        >
        </input_with_label_text_area>


        <basic_text_button
            class="margin_t_40"
            :button_state="true"
            :button_color="colors.primary_purple"
            :text_color="colors.white"
            :button_color_not_ready="colors.gray"
            :button_not_ready_text_color="colors.morgie_gray"
            :hover_color="colors.cyanic_blue"
            :hover_text_color="colors.white"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="SUBMIT"
            button_not_ready_text="Retire Ad"
            text_size="14px"
            :bold="true"
            event_name="save_job_request"
            @save_job_request="submit_prompt"
        ></basic_text_button>

      </div>


    </div>

  </div>
  <outside_footer class="footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import local_pda_featured from "@/components/orgs/pda/local_pda_featured";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  validate_phonenumber, dual_route,
  validate_email,
  be_main_address, scroll_to_element, get_ip_from_header, isEqual, num_is_between_inclusive, play_sound
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "chat_bot",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    // console.log("Current directory:", __dirname);
    // store.commit('notification_enqueue', `This is a test.`)
    this.id_ = get_uuid()
    this.scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)

    let ip_string = false
    ip_string = await get_ip_from_header()
    localStorage.setItem('chat_id', get_uuid())

    let default_market = 'Myrtle Beach'
    // this.update_approved_agency_names_in_market()
    // await this.populate_local_agency_data(default_market)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    local_pda_featured,
    dropdown_datalist,
    input_with_label_text_area,
    spinner

  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      chat_messages_: [],
      // spinner_up_ = false,
      last_prompt: "",
      max_msg_len: 500,
      name: '',
      password: '',
      email: '',
      job_request: {
        type: "",
        neighborhood: "",
        days: "",
        times: "",
        details: "",
        email: ""
      },
      p_or_f: ['Part-Time', 'Full-Time'],
      days: ['Weekdays', 'Weekends', 'Either'],
      times: ['Mornings', 'Afternoons', 'Evenings', 'Late Nights', 'Over Night'],

      neighborhoods_in_market: ['Myrtle Beach', 'Surfside', 'Murrells Inlet', 'Garden City',
        'Pawleys Island', 'Georgetown', 'Longs', 'North Myrtle Beach', 'Little River', 'Conway', 'Aynor', 'Other'],

      agencies_in_market: {
        'Myrtle Beach': ['rah1185']
      },
      columns_num_: 15,
      rows_num_: 5,
      job_email_request: "",
      approved_agencies_: [],
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 700,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
      // my_slides: [
      //   {text1: 'FreeTime bonuses have really make a big difference on my paycheck.', text2: 'Darcie P.', id: 1},
      //   {text1: 'Who\'s there?', text2: 'More text', id: 2},
      //   {text1: 'Tank.', text2: 'More text', id: 3},
      //   {text1: 'Tank who?', text2: 'More text', id: 4},
      //   {text1: 'You\'re welcome', text2: 'More text', id: 5},
      //   {text1: 'Oh, stop it, you\'re just killing me!', text2: '--Wyatt', id: 6},
      // ],

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    add_to_chat_user(msg) {
      this.chat_messages_.push(["You", msg])
    },
    add_to_chat_bot(msg) {
      this.chat_messages_.push(["Shiloh", msg])
    },
    job_email_request_entered(email) {
      // console.log("caught email")
      // console.log(email)
      if (validate_email(email)) {
        // console.log("email is valid")
        return email

      } else if (email !== "") {
        // console.log("in the else if")
        store.commit('error_enqueue', `${email} does not appear to be a valid email address.`)
        return ""
      } else {
        console.log("in the else")
        return ""
      }
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    save_job_request() {
      let m = "Your request is almost ready. Now just create an account and log in and we'll look for a " +
          "matching caregiver job for you."
      store.commit('notification_enqueue', m)
    },

    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (num_is_between_inclusive(100, 679, new_w)) {
        console.log("inside the 100 to 679 set")

        this.columns_num_ = 15
        this.rows_num_ = 5
      }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        console.log("setting to large")
        this.columns_num_ = 60
        this.rows_num_ = 10
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

    catch_job_type(name) {
      this.job_request.type = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },
    catch_job_neighborhood(name) {
      this.job_request.neighborhood = name.trim().slice(0, 100)
      // store.commit('save_name', this.name)
    },
    catch_job_days(name) {
      this.job_request.days = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },
    catch_job_time(name) {
      this.job_request.times = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },

    catch_email(name) {
      let email = this.job_email_request_entered(name)
      this.job_request.email = email.trim().slice(0, 100)
      // store.commit('save_name', this.name)
    },

    catch_prompt(prompt_msg) {
      if (prompt_msg.length > this.max_msg_len) {
        store.commit('notification_enqueue', "Your message exceeds the maximum length. Please shorten it.")
        this.last_prompt = "MESSAGE TOO LONG"
        return null
      }
      let msg = prompt_msg.trim().slice(0, this.max_msg_len)
      this.add_to_chat_user(msg)
      this.last_prompt = msg
      // store.commit('save_name', this.name)
      // this.do_nothing()
    },

    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },

    submit_prompt() {
      this.$refs.chat_prompt_box.emit_state()
      if (this.last_prompt !== "MESSAGE TOO LONG") {
        this.$refs.chat_prompt_box.reset_state()
        this.get_reply_from_chatbot()
      }

    },

    async get_reply_from_chatbot() {
      console.log("Sending off for chatbot reply.")
      // this.add_to_chat_bot("Hey, this is Shiloh.")
      // return null
      store.commit('set_spinner_state', true)
      let error_out
      let target = be_main_address('main_api') + "chat_help"
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        message: this.last_prompt,
        chat_id: localStorage.getItem('chat_id') || undefined
      }
      // console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            store.commit('set_spinner_state', false)
            if (error.response) {
              // console.log("Something went wrong with your login.")
              store.commit('error_enqueue',  "It looks like something went wrong with the chat message or the server is very busy")
              console.log(error)
              error_out = true
              return null
            } else if (error.request) {
              let online = window.navigator.onLine;
              let message
              if (online) {
                message = "There are problems with your network connection, or Tiny Magic Hat may be down."
              } else {
                message = "You appear to have lost your network connection."
              }
              store.commit('error_enqueue', message)
              error_out = true
              return null
            } else {
              // console.log("in the error catch all")
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message);
              store.commit('set_spinner_state', false)
              error_out = true
              return null
            }
          }).then((response) => {
                play_sound('simple_notification_short.ogg')
                console.log("Got good response from chatbot.");
                let reply = response.data.data
                console.log(reply);
                this.add_to_chat_bot(reply)
               store.commit('set_spinner_state', false)
              }
          )

    },

  },
  computed: {
    chat_messages() {
      return this.chat_messages_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },

    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../common.css';


header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .chat_messages{
   max-width: 1200px;
   width: 85%;
 }

  .form_input {
    width: 273px;
  }


  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    /*margin-top: -30px;*/
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .market_box {
    font-size: 20px;
    border-color: var(--eli_gray);
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 30px;

  }

  .market {
    font-size: 22px;
    font-weight: 800;
    padding: 10px;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}

@media (min-width: 680px) {
  .form_input {
    width: 600px;
  }

  #cg_topper_img {
    width: 350px;
  }
}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 350px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {

  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 650px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 500px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0;
  }

  .top_text {
    max-width: 850px;
  }


}

@media (min-width: 2000px) {


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;
  }

  #cg_topper_img {
    width: 800px;
  }

}

</style>