<template>
  <div @click="emit_state" class="flex_centered_column whole_button" :id="id_button_body">
    <div class="flex_centered_row">
      <img :src="require('@/assets/' + image_path + '')" class="icon_on_button" :alt=alt_text :id="id_button_icon">
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {
  colors,
} from "@/library";

export default {
  name: "basic_icon_button",
  props: [
    'ready',
    'button_color',
    'button_color_not_ready',
    'button_height',
    'button_width',
    'border_rad_pct',
    'image_path',
    'image_width',
    'alt_text',
    'event_name'],
  async mounted() {
    this.id_button_body = uuidv4();
    this.id_button_icon = uuidv4();
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        let button_body = document.getElementById(this.id_button_body)
        let button_icon = document.getElementById(this.id_button_icon)
        // console.log(`button_icon is ${button_icon}`)
        // console.log(`button_body is ${button_body}`)
        button_body.style.height = this.button_height
        button_body.style.minHeight = this.button_height
        button_body.style.width = this.button_width
        button_body.style.minWidth = this.button_width

        button_body.style.borderRadius = this.border_rad_pct

        button_icon.style.width = this.image_width
        // console.log("Getting ready to set button body color.")
        if (this.ready === true) {
          // console.log("set body to ready color")
          button_body.style.background = this.button_color
        } else {
          // console.log("set body to NOT ready color")
          button_body.style.background = this.button_color_not_ready
        }
      })
    } catch (error) {
      console.error(error)
    }

  },
  data() {
    return {
      colors: colors,
      id_button_body: "",
      id_button_icon: ""
    }
  },
  methods: {
    emit_state() {
      console.log("Emitting state for the button.")
      if(this.event_name !== undefined){
        this.$emit(this.event_name, true)
      }else{
        console.log("The event name for the icon button was undefined...")

      }

    },
  },
}
</script>

<style scoped>

</style>