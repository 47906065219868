<template>
  <section class="doc_upload_widget flex_centered_column">
    <input type="file" style="display: none" ref="fileInput_caregiver_document"
           @change="onDocumentUpload($event)">
    <div class="doc_title_box flex_centered_row">
      <h1>{{ heading_text }}</h1>
    </div>
    <div class="doc_expiration_picker flex_centered_column">
      <div class="font_raleway font_weight_800 margin_b_5 margin_t_15">Expiration: {{ expires_in_days }}</div>
      <flat-pickr
          class="text_align_center"
          id="fp_field"
          ref="flat_pickr"
          v-model="date_"
          model-value
          :config="config"
          @on-change="date_picker_changed"
      />
    </div>


    <div class="flex_centered_row ">
      <div class="flex_centered_column ">
        <div class="flex_fully_centered_space_around_bottom_row doc_selected_indicator margin_b_10">
          <p>Document Selected</p>
          <img class="material_icon" v-if="document_selected"
               :src="image_name('check_circle.svg')"
               alt="Match saved Icon">
          <img class="material_icon" v-if="!document_selected"
               :src="image_name('warning.svg')"
               alt="Match not saved icon">

        </div>
        <div v-if="document_selected" class="font_weight_800">
          {{ document_selected_name }}
        </div>
      </div>
    </div>


    <div class="flex_centered_row">
      <basic_text_button
          v-if="!document_selected"
          class=margin_tb_20
          :button_state="true"
          :button_color="colors.new_cyanic_main"
          :hover_color="colors.new_cyanic_main"
          :text_color="colors.black"
          :hoven_text_color="colors.black"
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          :button_height=action_button_height
          :button_width=action_button_width
          border_rad_pct="0%"
          :text=button_text
          text_size="14px"
          :bold="true"
          event_name="doc_select_clicked"
          @doc_select_clicked="choose_document()"
      ></basic_text_button>

      <basic_text_button
          v-if="document_selected"
          class=margin_tb_20
          :button_state="true"
          :button_color="colors.new_orange_main"
          :hover_color="colors.new_cyanic_main"
          :text_color="colors.black"
          :hoven_text_color="colors.black"
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          :button_height=action_button_height
          :button_width=action_button_width
          border_rad_pct="0%"
          :text=button_text2
          text_size="14px"
          :bold="true"
          event_name="doc_upload_clicked"
          @doc_upload_clicked="emit_selection()"
      ></basic_text_button>


    </div>
  </section>


</template>

<script>
/* eslint-disable */
import store from "@/store";
import basic_text_button from "@/components/parts/basic_text_button";

import {
  getUTCTimestamp,
  getFutureUTCTimestamp,
  getLocalTimezone,
  formatTimestamp,
  parseDateStringToTimestamp,
  convertTimestampToLocalDate,
  daysUntilTimestamp,
} from '@/dates_library'

import {
  get_uuid,
  num_is_between_inclusive,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now,
  be_main_address, translate_dateobj_to_datestring,
  parse_internal_json,
  reset_current_speedydelivery_upload_data_vuex, calculateFutureDate,
  fileToDataUri,
  image_name, formatPhoneNumber
} from "@/library";
import axios from "axios";

import {ref} from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from "moment-timezone";

// const date = ref(null);


export default {
  name: "document_upload_widget",
  async mounted() {
    // console.log(`The calculated date is ${calculateFutureDate()}`)
    // console.log(`Using one of the new functions... ${getUTCTimestamp()}`)
    scroll_to_top()
    this.id_ = get_uuid()
    this.reset_date_picker()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color

        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
    "button_text2",
    "heading_text",
    "document_type",
    "pretty_doc_name",
    "deactivate"
  ],
  components: {
    basic_text_button,
    flatPickr

  },
  data() {
    return {
      id_: "",
      event_name_: this.event_name,
      colors: colors,
      mobile_mode_x: true,
      button_height_: "80px",
      button_width_: "100px",
      doc_type_: this.document_type,
      deactivate_: this.deactivate,
      the_file_base64: "",
      document_selected_: false,
      document_selected_name_: "",
      pretty_name_: this.pretty_doc_name,
      date_: "",
      config: {
        dateFormat: 'm/d/Y',
        defaultDate: this.get_default_expiration_date(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(),
        allowInput: false,
      },

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    get_min_expiration_date() {
      let ts = getFutureUTCTimestamp(null, 1, 0, 0)
      return formatTimestamp(ts, 1)
    },
    get_max_expiration_date() {
      let ts
      if (this.document_type === 'drivers_license') {
        // console.log('the doc type was drivers license.')
        ts = getFutureUTCTimestamp(null, 0, 0, 10)
      } else {
        // console.log(`the doc type was NOT drivers license it was ${this.doc_type}`)
        ts = getFutureUTCTimestamp(null, 0, 0, 3)
      }
      return formatTimestamp(ts, 1)
    },
    reset() {
      this.the_file_base64 = ""
      this.document_selected_ = false
      this.document_selected_name_ = ""
      this.reset_date_picker()
    },
    change_date_format(old_date_str) {
      let ts = parseDateStringToTimestamp(old_date_str)
      return formatTimestamp(ts)
    },
    get_processed_expiration_date() {
      let old_str = this.date_ || this.get_default_expiration_date()
      return this.change_date_format(old_str)

    },
    emit_selection() {
      let res = {
        'doc_name': this.document_selected_name_,
        'doc_content': this.the_file_base64,
        'doc_type': this.doc_type,
        'doc_expiration': this.get_processed_expiration_date(),
        'doc_pretty_name': this.pretty_name
      }
      this.$emit('upload_doc', res)
      this.date_ = this.get_default_expiration_date()
    },

    reset_date_picker() {
      const my_fp = document.querySelector("#fp_field")._flatpickr;
      my_fp.clear();
      my_fp.setDate(this.get_default_expiration_date())
    },

    get_default_expiration_date() {
      let m, d, y, the_past, show_tz, tz_type
      m = 0
      d = 0
      y = 1
      the_past = false
      show_tz = false
      tz_type = 'short'

      let future_ts = getFutureUTCTimestamp(null, d, m, y, the_past)
      // console.log(`The future timestamp I have come up with is ${future_ts}`)
      let res = formatTimestamp(future_ts, 1, show_tz, tz_type)
      // console.log(`The resulting string I have come up with is ${res}`)
      return res

    },
    date_picker_changed(e) {
      console.log('Date picker changed')
      console.log(e)
      let obj = e[0]
      if (!obj) {
        let str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        this.date_ = str
      } else {
        let str = translate_dateobj_to_datestring(obj, 4)
        this.date_ = str
      }

    },

    image_name(name) {
      return image_name(name)
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        // this.button_size_ = "100px"
        this.button_img_size_ = "80px"
        this.button_height_ = "60px"
        this.button_width_ = "250px"

      }
      if (new_w >= 280) {
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w >= 400) {
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w > 500) {

        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        // this.button_height = "80px"
        // this.button_width_ = "100px"
      }
    },
    // emit_state() {
    //   this.$emit(this.event_name, null)
    // },
    choose_document() {
      // this.current_document_type_ = 'drivers_license'
      console.log(`Has this document been deactivated? ${this.deactivate}`)
      if(this.deactivated === 'true'){
          console.log("INside deactivated is True")
          let message = "This feature has not yet been implemented."
          store.commit('notification_enqueue', message)
      }else{
        console.log("INside deactivated is False")
        this.$refs.fileInput_caregiver_document.click()

      }

    },

    async onFilePicked(event, name_saver = false) {
      // await analytic("01:01:16:916" + "::" + f_type)
      let files = event.target.files
      let the_file = files[0]
      let the_file_name = the_file.name
      this.document_selected_name_ = the_file_name


      const myArray = the_file_name.split(".");
      let file_ext = myArray[1];
      // let exts = ["jpeg",  "jpg", "pdf"]
      let exts = ["pdf"]
      if (exts.includes(file_ext)) {
        // console.log("ok file")
      } else {
        let m = `Caregiver document uploads must be in a PDF file format. Your file was a "${file_ext}".`
        store.commit('error_enqueue', m)
        // console.log("NOT OK")
        return null
      }

      //Translate the pdf file into a base64 string
      this.the_file_base64 = await fileToDataUri(the_file).then(orig_src => {
        console.log("File translated to base64 is")
        console.log(orig_src)
        this.$refs.fileInput_caregiver_document.value = ''
        return orig_src
      })

      this.document_selected_ = true
      return true


    },

    async onDocumentUpload(event) {
      let res = await this.onFilePicked(event, true)
      if (!res) {
        return null
      }

      let message = `${this.pretty_name} upload selected: ${this.document_selected_name}`

      store.commit("notification_enqueue", message)
    },

  },
  computed: {
    id() {
      return this.id_
    },

    expires_in_days() {
      //Take a datestring like 2024-10-06 and calculate how many days it is from now.
      let d = ""
      // console.log(`Inside expires_in_days and the date is set to....`)
      // console.log(this.date_)
      if (!this.date_) {
        d = this.get_default_expiration_date()
      } else {
        d = this.date_
      }
      // console.log(`The date in expires_in_days is ${d}`)
      let ts = parseDateStringToTimestamp(d)
      let days = daysUntilTimestamp(ts)
      if (days === 1) {
        return `${days.toString()} Day`
      } else {
        return `${days.toString()} Days`
      }
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    action_button_width() {
      return this.button_width_
    },
    action_button_height() {
      return this.button_height_
    },
    document_selected() {
      return this.document_selected_
    },
    document_selected_name() {
      return this.document_selected_name_.substring(0, 25)
    },
    pretty_name() {
      return this.pretty_name_
    },
    doc_type() {
      return this.doc_type_
    },
    deactivated() {
      return this.deactivate_
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },

  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  p {
    font-size: 22px;
  }

  .material_icon {
    height: 30px;

  }

  .doc_selected_indicator {
    width: 270px;
  }

  .saved_indicator p {
    font-weight: 800;
    font-size: 16px;
    line-height: 1.6;
  }

  .saved_indicator .material_icon {
    /*margin-left: 15px;*/
  }

  h1 {
    font-size: 24px;
  }

  h2 {

  }

  h3 {

  }

  .doc_upload_widget {
    background-color: var(--lauren_gray);
    height: 250px;
    width: 280px;
    padding: 30px;
    margin-bottom: 20px;
    /*box-sizing: border-box;*/
  }

  .doc_title_box {

  }

  .doc_expiration_picker {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1000px) {
  .doc_upload_widget {
    background-color: var(--lauren_gray);
    height: 250px;
    width: 500px;
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    /*box-sizing: border-box;*/
  }

  h1{
    font-size: 30px;
  }
}

@media (min-width: 1200px) {

  .material_icon {
    height: 36px;

  }

  p {
    font-weight: 800;
    font-size: 20px;
  }

  h1 {
    font-size: 34px;
  }

  h2 {

  }

  h3 {

  }

  .doc_upload_widget {
    margin-top: 40px;
    background-color: var(--lauren_gray);
    height: 300px;
    width: 500px;
    padding: 30px;
    /*box-sizing: border-box;*/
  }

  .doc_title_box {

  }

  .doc_expiration_picker {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>