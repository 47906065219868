<template>
  <div class="core_c  screen_block">


    <section class="whole_widget">
      <div class="outline margin_lr_5 flex_centered_column">
        <div class="flex_centered_row">
<!--                    <img class="agency_logo cursor_ptr "-->
<!--                         src="@/assets/rah_teal.jpg"-->
<!--                         alt="Agency Logo Image">-->


          <img class="logo_image cursor_ptr"
                     :src=logo_str
                     alt="Agency Staff Photo">

<!--          <image_upload_widgit-->
<!--              ref="upload_widgit"-->
<!--              class=" margin_t_40"-->
<!--              :image_value=logo_str-->
<!--              image_width="260"-->
<!--              image_height="145"-->
<!--              frame_text="No agency logo present."-->
<!--              event_name="save_pic"-->
<!--              :owners_perm="false"-->
<!--              @save_pic="do_nothing"-->
<!--          ></image_upload_widgit>-->
        </div>
        <h5 class="margin_tb_20">{{ headline }}</h5>
        <p class="ad_body_text">{{ body }}</p>

                <img class="staff_photo cursor_ptr"
                     :src=photo_str
                     alt="Agency Staff Photo">

        <p class="photo_caption">{{ caption }}</p>
        <div v-if="data['founding_year']" class="since outline">
          <h5 class="text_align_center">Providing Care Since</h5>
          <h5 class="text_align_center">{{ founding_year }}</h5>
        </div>
        <div class="rate_box outline ">
          <h5 class="margin_b_20">Hourly Rates as of {{ pricing_week }}</h5>
          <table class="font_raleway rate_text">
            <tr>
              <td class="font_weight_800">Base Rate:</td>
              <td>${{ base_rate }}</td>
            </tr>
            <tr>
              <td class="font_weight_800">Weighted Avg:</td>
              <td>${{ weighted_avg }}</td>
            </tr>
          </table>
        </div>
        <div class="discount_box outline">
          <h3 class="margin_t_30">New Client Discount</h3>
          <h3>${{ discount_amount }}</h3>
          <p v-if="(data['discount'])" class="margin_t_30">Right at home is currently offering a new client discount
            worth
            ${{ discount_amount }}. Register your
            discount and apply it against your invoices.</p>
          <p v-else class="margin_t_30">There is no new client discount being offered at this time.</p>
          <div class="flex_centered_row">
            <basic_text_button
                class="padding_lr_6 padding_tb_6  margin_t_50 margin_b_40"
                :hover_color=colors.cyanic_blue
                :hover_text_color=colors.white
                :button_state=true
                :button_color="colors.new_orange_main"
                :text_color=colors.white
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="70px"
                button_width="180px"
                border_rad_pct="0"
                text="Register Discount"
                text_size="14px"
                :bold="true"
                event_name="register_discount"
                @register_discount="register_agency_discount"
                v-on:keyup.enter="register_agency_discount"
            ></basic_text_button>
          </div>


        </div>
<!--        <div class="contact_info_box flex_centered_row">-->
<!--          <div class="flex_centered_column">-->
<!--            <call_us_box-->
<!--                class="margin_lr_10 margin_t_20"-->
<!--                :click_to_call_mode=mobile_mode-->
<!--                :phone_number="formatted_agency_phone"-->
<!--                @call_clicked=do_nothing-->
<!--            ></call_us_box>-->
<!--          </div>-->

<!--        </div>-->

      </div>


    </section>


  </div>
  <!--  <outside_footer class="footer_c margin_t_50"></outside_footer>-->
</template>

<script>
/* eslint-disable */
// import outside_footer from "@/components/parts/outside_footer.vue";

import basic_text_button from "@/components/parts/basic_text_button.vue";
import image_upload_widgit from "@/components/parts/image_upload_widgit.vue";
import call_us_box from "@/components/SpyGlass/call_us_box.vue";
import {mapGetters} from "vuex";
import store from "@/store";
import {Database} from "@/client_db";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import {colors, formatPhoneNumber, image_name, isEmpty} from "@/library";

export default {

  mounted() {
    console.log("In agency ad widget mounted...")
    console.log(this.data)
    this.run_window_width_adjustments(this.windowWidth)
    console.log("In agency ad widget mounted at the end...")
  },
  props: ['data'],
  name: "agency_ad_widget",
  components: {call_us_box, image_upload_widgit, basic_text_button},
  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 700,
      button_wid_: ""
    }
  },
  methods: {
    do_nothing() {
      console.log("Doing nothing.")
    },
    ensure_preliminary_data_present() {
      let v_state = store.getters.get_current_vortex_collected_data
      console.log("Here is teh VSTATE")
      console.log(v_state)
      if (v_state.client_phone && v_state.searcher_phone && v_state.client_name && v_state.searcher_name) {
        return true
      } else {
        return false
      }
    },

    register_agency_discount() {
      console.log("Register the agency discount")
      if (!this.ensure_preliminary_data_present()) {
        store.commit("error_enqueue", "There is a problem with your selection data.")
        store.commit('set_current_vortex_agency', "")
        store.commit('set_current_vortex_searcher_name', "")
        store.commit('set_current_vortex_searcher_phone', "")
        store.commit('set_current_vortex_client_name', "")
        store.commit('set_current_vortex_client_phone', "")
        this.$router.push('/markets');
      }
      console.log(this.data['agency'])
      console.log(this.data['discount'])
      // let discount_data = {
      //   'agency': this.data['agency'],
      //   'discount': this.data['discount'],
      //   'searcher': ""
      // }
      console.log("Just set Agency name and discount data on the current vortex collected")
      // console.log(discount_data)
      let prelim_data = store.getters.get_current_vortex_collected_data
      prelim_data['agency'] = this.data['agency']
      prelim_data['discount'] = this.data['discount']
      console.log(prelim_data)
      store.commit('set_current_vortex_collected_data', prelim_data)

      this.$router.push('/register_discount');
    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    run_window_width_adjustments(new_w) {
      console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.button_wid_ = 250
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.button_wid_ = 300
      }

    },
    formatToCurrency(input) {
    try {
        // Attempt to convert input to a number
        let number = parseFloat(input);

        // Check if the number is valid and not NaN (Not a Number)
        if (isNaN(number)) {
            throw new Error('Invalid number');
        }

        // Return the number formatted to two decimal places as a string
        return number.toFixed(2);
    } catch (e) {
        // If any error occurs, return "0.00"
        return "0.00";
    }
}

  },
  computed: {
    // id() {
    //   return this.id_
    // },
    data_down(){
      return this.data
    },

    logo_str(){
      console.log("inside logo string comptuted property")
      return this.data['logo']
    },
    photo_str(){
      return this.data_down['photo']
    },

    weighted_avg(){
      return this.formatToCurrency(this.data_down['pricing']['weighted_avg'])
    },

    base_rate(){
      return this.formatToCurrency(this.data_down['pricing']['base_rate'])
    },

    discount_amount(){
      // console.log(this.data_down[)
      return this.formatToCurrency(this.data_down['discount'])
    },

     headline(){
      return this.data_down['headline']
    },

     body(){
      return this.data_down['body']
    },

     caption(){
      return this.data_down['caption']
    },

     founding_year(){
      return this.data_down['founding_year']
    },
    pricing_week(){
      return this.data_down['pricing_week']
    },


    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },
    // modal_up() {
    //   return this.video_modal.state
    // },
    // show_hamburger_menu() {
    //   return this.show_hamburger_menu_
    // },
    mobile_mode() {
      return this.mobile_mode_x
    },
    formatted_agency_phone() {
      return formatPhoneNumber(this.data['agency_phone'])
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>

<style scoped>
@media (min-width: 100px) {

  .logo_image{
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .whole_widget {
    transition: all 0.2s;
  }

  .contact_info_box {
    background-color: var(--cyanic-blue-light);
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .outline {
    border: 1px solid var(--black);
    padding: 10px;

  }

  .ad_body_text {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
  }

  .staff_photo {
    width: 99%;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .photo_caption {
    font-size: 15px;
    margin-bottom: 30px;
  }

  .agency_logo {
    width: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .discount_box {
    background-color: var(--lauren_gray);
    padding: 20px;
    min-width: 236px;
    max-width: 98%;
  }

  .rate_box {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    min-width: 95%;
  }

  .rate_text {
    font-size: 22px;
    width: 90%;
  }

  .since {
    min-width: 95%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
  .rate_text {
    font-size: 22px;
    width: 70%;
  }
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>