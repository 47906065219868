<template>
  <section v-if="(show_backer) " @click="emit_state"
  >
    <div class="base_backer p_fx z_10000 cursor_ptr" :id="id"></div>
  </section>
</template>

<script>
import {v4 as uuidv4} from "uuid";

export default {
  name: "universal_invisible_fronter",
  props: ['show_backer', 'event_name'],

  async mounted() {
    this.id = uuidv4();
  },

  data() {
    return {
      id: "",
    }
  },
  methods: {
    emit_state() {
      this.$emit(this.event_name)
    },
  },
  computed: {},

}
</script>

<style scoped>

.base_backer {
  /*position: fixed;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--new-cyanic-main-light_50);
  /*background-color: green;*/
}

</style>