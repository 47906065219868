<template>
  <div class="core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>

    <div class="wid_100vw bg_primary_purple">
       <h1 class="flex_centered_start_column margin_lr_5">
      {{header_text}}
    </h1>
    </div>


    <div v-if="!good_token" class="flex_centered_column log_in_warn_text_wrapper">
      <p class="log_in_warn_text">You must be logged in to submit your answers. Log in and then click
        your browser's back button to return here.</p>
      <basic_text_button
          class="padding_lr_6 padding_tb_6 log_in_warn_text_button"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.new_orange_main"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="200px"
          border_rad_pct="0"
          text="Log In"
          text_size="17px"
          :bold="true"
          event_name="go_to_login"
          @go_to_login="go_to_login"
      ></basic_text_button>
    </div>


    <div class="flex_centered_row instructions_wrapper">
      <h5 class="flex_centered_row instructions">Please select answers for all of the following true/false, multiple-choice or
        fill-in-the-blank questions and then submit your answers. You need to get at least 8 out of 10 answers correct to pass.</h5>
    </div>


    <div class="flex_centered_column">
      <div class="flex_left_aligned_column all_questions">

        <slot></slot>


      </div>
    </div>

    <div class="flex_centered_row submit_button">

    </div>


  </div>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import {get_uuid, round, colors, desktop_size, scroll_to_top, good_token_now} from "@/library";

export default {
  name: "test_with_slots_form",
  async mounted() {
    scroll_to_top()
    if (!good_token_now()) {
      let the_message = 'You must be logged in to submit your answers.  Log in and then click your browser\'s back button to return here.'
      store.commit('notification_enqueue', the_message)
    }
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    'questions_texts',
    'header_text',
    'module_name'
  ],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner,
    basic_text_button,
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      // answers_value: {
      //   'one': null,
      //   'two': null,
      //   'three': null,
      //   'four': null,
      //   'five': null,
      //   'six': null,
      //   'seven': null,
      //   'eight': null,
      //   'nine': null,
      //   'ten': null,
      // },

    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
    },
    async go_to_login() {
      // this.hamburger_menu_false()
      await this.$router.push({path: '/login'})
    },
    // async post_test_answers() {
    //   this.$emit('answers_submitted', [this.module_name, this.answers_value])
    // },
    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },
    update_answer_value(values) {
      let value = values[0]
      let question = values[1]
      this.answers_value[question] = value
      console.log("current values are.....")
      console.log(this.answers_value)

    }

  },
  computed: {
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    // all_questions_answered() {
    //   console.log('in all questions answered.')
    //   if (
    //       this.answers_value['one'] !== null &&
    //       this.answers_value['two'] !== null &&
    //       this.answers_value['three'] !== null &&
    //       this.answers_value['four'] !== null &&
    //       this.answers_value['five'] !== null &&
    //       this.answers_value['six'] !== null &&
    //       this.answers_value['seven'] !== null &&
    //       this.answers_value['eight'] !== null &&
    //       this.answers_value['nine'] !== null &&
    //       this.answers_value['ten'] !== null
    //   ) {
    //     console.log("all are")
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // ans_one() {
    //   return this.answers_value['one']
    // },
    // ans_two() {
    //   return this.answers_value['two']
    // },
    // ans_three() {
    //   return this.answers_value['three']
    // },
    // ans_four() {
    //   return this.answers_value['four']
    // },
    // ans_five() {
    //   return this.answers_value['five']
    // },
    // ans_six() {
    //   return this.answers_value['six']
    // },
    // ans_seven() {
    //   return this.answers_value['seven']
    // },
    // ans_eight() {
    //   return this.answers_value['eight']
    // },
    // ans_nine() {
    //   return this.answers_value['nine']
    // },
    // ans_ten() {
    //   return this.answers_value['ten']
    // },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  h1 {
    color: white;
    font-size: 30px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
  }

  .log_in_warn_text_wrapper{

  }
  .log_in_warn_text{
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
  }
  .log_in_warn_text_button{
    margin-bottom: 40px;
  }

  .instructions{
    text-align: center;
  }

  .instructions_wrapper{
    margin-bottom: 30px;
  }

  .instructions_wrapper h5{
    font-size: 18px;
  }

}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 700px) {
 .all_questions{
   max-width: 95vw;
 }
}

@media (min-width: 800px) {
}

@media (min-width: 1200px) {
  .a_question {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .all_questions {
    margin-top: 50px;
    max-width: 70vw;
  }

  .instructions {
    width: 800px;
  }

   .instructions_wrapper h5{
    font-size: 26px;
  }

  .instructions_wrapper {
    margin-top: 100px;
  }

  .submit_button {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .log_in_warn_text {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 500px;
    text-align: center;
    font-size: 26px;
  }
}

@media (min-width: 2000px) {
}
</style>