<template>
  <module_main_page
      module_download_name="a97a5e9b-aa11-4539-8637-b0716b5a4815"
      module_logo_name="sd_shingles.svg"
      :module_desc_text=module_text
      module_headline="Understanding Shingles"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "shingles",
  data() {
    return {
      module_text: "Shingles, also known as herpes zoster, is a viral infection caused by the varicella-zoster virus, which is the same virus responsible for chickenpox. After a person recovers from chickenpox, the virus can lie dormant in the body and reactivate years later as shingles. Caregivers should be aware of shingles as it can be quite painful and, if not managed properly, can lead to complications. It typically presents as a painful rash that often appears on one side of the body, along with flu-like symptoms. Caregivers should understand that shingles is contagious, and direct contact with the rash can transmit the virus to individuals who have not had chickenpox or the varicella vaccine. Therefore, they should take precautions such as wearing gloves and maintaining good hand hygiene when caring for a client with shingles.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



