<template>

  <div class="whole_question flex_wrap ">
    <p class="the_q_num"><strong>{{ question_num }}</strong></p>
    <div class="spacer"></div>
    <p class="question">{{ question_text }}</p>
    <div class="mid_spacer"></div>


    <div class="yes_no_boxes_wrapper">
      <div class=" yes_no_boxes">
         <input_with_label
            ref="fill_in_the_blank_box"
            class="login_input"
            label="Type your answer"
            @emit_state="emit_the_state"
        ></input_with_label>

      </div>
    </div>
  </div>

</template>

<script>
import input_with_label from "@/components/parts/input_with_label";
import {colors, get_uuid} from "@/library";

export default {
  name: "fill_in_the_blank_question",
  props: [
    'question_text',
    'question_num'
  ],
  components: {
    input_with_label,
  },
  emits: [
    'emit_state',
  ],
  async mounted() {
    this.the_true_id = get_uuid()
    this.the_false_id = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        let the_true_box = document.getElementById(this.the_true_id)
        let the_false_box = document.getElementById(this.the_false_id)
        the_true_box.style.background = this.default_color
        the_false_box.style.background = this.default_color
        the_true_box.children[0].style.color = colors.morgie_gray
        the_false_box.children[0].style.color = colors.morgie_gray

      })
    } catch (error) {
      console.log(error)
    }
  },
  data() {
    return {
      the_true_id: "",
      the_false_id: "",
      type_: "text",
      value_: "",
      default_color: colors.lauren_gray,
      yes_color: colors.primary_purple,
      no_color: colors.primary_purple,
      selected_text_color: colors.white,
      true_value_start: this.value1,
      false_value_start: this.value2,
      answer_values: {
        the_true: null,
        the_false: null
      },
    }
  },
  methods: {

    emit_state(){
      this.$refs.fill_in_the_blank_box.emit_state()
    },

    emit_the_state(ans){
       this.$emit('emit_state', [ans, this.question_num])
    },
    recolor(the_ans) {
      let the_true_box = document.getElementById(this.the_true_id)
      let the_false_box = document.getElementById(this.the_false_id)
      if (the_ans.the_true === true) {
        the_true_box.style.background = this.yes_color
        the_true_box.children[0].style.color = colors.white
        the_false_box.children[0].style.color = colors.morgie_gray
        the_false_box.style.background = this.default_color
      } else if (the_ans.the_true === false) {
        the_true_box.style.background = this.default_color
        the_false_box.style.background = this.no_color
        the_true_box.children[0].style.color = colors.morgie_gray
        the_false_box.children[0].style.color = colors.white
      } else {
        the_true_box.style.background = this.default_color
        the_false_box.style.background = this.default_color
        the_true_box.children[0].style.color = colors.morgie_gray
        the_false_box.children[0].style.color = colors.morgie_gray
      }
    },
    // true_clicked() {
    //   console.log("true was clicked")
    //   this.answer_values['the_true'] = true
    //   this.answer_values['the_false'] = false
    //   this.recolor(this.answer_values)
    //   this.$emit('true_value_update', [true, this.question_num])
    //
    // },
    // false_clicked() {
    //   console.log("false was clicked")
    //   this.answer_values['the_true'] = false
    //   this.answer_values['the_false'] = true
    //   this.recolor(this.answer_values)
    //   this.$emit('true_value_update', [false, this.question_num])
    // },
    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

  },
  computed: {
    input_type() {
      return this.type_
    },
  },
}
</script>

<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .whole_question {
    margin-bottom: 50px;
  }

  .question {
    font-size: 22px;
  }

  .yes_no_boxes_wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }

  .yes_no_boxes {
    display: flex;
    justify-content: space-around;
    width: 225px;
  }

  .ans_col {
    width: 100px;
    height: 60px;
    /*background-color: yellow;*/
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 1000px) {
  .yes_no_boxes_wrapper {
    display: flex;

    justify-content: flex-start;
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .the_q_num {
    font-size: 30px;
  }

  .question {
    font-size: 26px;
  }

  .yes_no_boxes_wrapper {
    display: flex;

    justify-content: flex-start;
    margin-top: 15px;
  }
}

@media (min-width: 1600px) {

  .whole_question {
    display: flex;
    justify-content: flex-start;
  }

  .question {
    width: 600px;
  }

  .yes_no_boxes {
    align-items: center;
  }

  .mid_spacer {
    width: 240px;
    /*background-color: red;*/
  }

  .spacer {
    width: 30px;
    /*background-color: green;*/
  }

  .the_q_num {
    width: 100px;
  }

  .ans_col {
    width: 100px;
    height: 60px;
    /*background-color: yellow;*/
  }
}


@media (min-width: 2000px) {
}


</style>