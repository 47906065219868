<template>
  <headers
      title="Helping Agencies"
      meta_description="Tiny Magic Hat helps Home Care Agencies by making it easier to find and retain caregivers."
  ></headers>

  <section class="flex_centered_column">
    <div id="page_top_x"></div>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>

    <section class="wid_100vw flex_centered_row" id="top_selector_section">
      <div class=" flex_wrap" id="top_selector_row_wrapper">
        <div class="flex_centered_column" id="tmh_agency_features_intro">
          <Transition name="entry_fade_in">
            <h1 v-if="show_fade" class="">Tools to make your agency stronger.</h1>
          </Transition>
          <div class="flex_left_aligned_column" id="top_selector_text">
            <p>Running a home care agency is more challenging than ever.</p>
            <p>Increasing wages. Limited Staff. Advertising with traditional methods has marginal results.</p>
            <p>Tiny Magic Hat helps solve these problems.</p>
          </div>

        </div>
        <div class="flex_centered_column feature_cards_coll">
          <feature_card5
              class="cursor_ptr"
              bg_color="white"
              icon="icon_good_docs_black.svg"
              desc="Good docs icon"
              :icon_color="colors.black"
              :icon_bg_color="colors.transparent"
              :icon_width="40"
              arrow_size="40"
              arrow_color="blue"
              :arrow="true"
              text_logo="speedy_delivery_purple_text_only.svg"
              :active="feature_card_picked==='speedydelivery'"
              text_logo_width="150"

              :hover_color="colors.cyanic_background"
              @main_btn_clicked="activate_section_vis('speedydelivery')"
          >
            <template v-slot:body>
              <p>Download required documentation before even meeting new candidates.</p>
            </template>
          </feature_card5>

          <feature_card5
              class="cursor_ptr"
              bg_color="white"
              icon="icon_group_black.svg"
              desc="Caregivers icon"
              :icon_color="colors.black"
              :icon_bg_color="colors.transparent"
              :icon_width="40"
              arrow_size="40"
              arrow_color="blue"
              :arrow="true"
              text_logo="spyglass2_color_text_only.svg"
              :active="feature_card_picked==='spyglass'"
              text_logo_width="150"

              :hover_color="colors.cyanic_background"
              @main_btn_clicked="activate_section_vis('spyglass')"
          >
            <template v-slot:body>
              <p>Find caregivers that want schedules you have.</p>
            </template>
          </feature_card5>

          <feature_card5
              class="cursor_ptr"
              bg_color="white"
              icon="icon_cash_black.svg"
              desc="Cash icon"
              :icon_color="colors.black"
              :icon_bg_color="colors.transparent"
              :icon_width="40"
              arrow_size="40"
              arrow_color="blue"
              :arrow="true"
              text_logo="freetime_primary_purple_text_only.svg"
              :active="feature_card_picked==='freetime'"
              text_logo_width="150"

              :hover_color="colors.cyanic_background"
              @main_btn_clicked="activate_section_vis('freetime')"
          >
            <template v-slot:body>
              <p>Attract more caregivers with the highest pay in your market.</p>
            </template>
          </feature_card5>
        </div>
      </div>
    </section>


    <selection_indicator
        v-if="!mobile_mode"
        class="sel_indicator"
        :pick="box_picked"
        @card_picked="set_feature_card_picked"
    ></selection_indicator>


    <div class="feature_page_main">


      <section class="flex_centered_column ">

        <div id="sd_marker"></div>
        <Transition name="entry_fade_in">


          <div v-show="active_section_speedydelivery" class="flex_centered_column"
               id="for_agencies_content_speedydelivery">


            <div id="speedydelivery_top_section" class="flex_centered_column">
              <img class=" padding_b_20" id="speedydelivery_content_logo"
                   src="@/assets/speedydelivery_logo.svg"
                   alt="SpeedyDelivery Logo" @click="activate_section_vis('speedydelivery')">
              <div class="feature_heading_text">
                <h2 id="speedydelivery_heading_text">Front-load the onboarding process</h2>
              </div>
              <p>Onboarding caregivers takes a lot of time. SpeedyDelivery helps get a new caregiver in the field
                immediately.</p></div>


            <div class="flex_centered_row all_check_boxes flex_wrap">


              <div class="flex_space_between_row check_box_group">
                <img class="check_box_icon"
                     src="@/assets/check-mark-black.svg"
                     alt="check box image">
                <div class="flex_left_aligned_column check_box_set">
                  <h3>Provide Study Materials</h3>
                  <p>Educational modules for your state are emailed to the candidates before setting foot in your
                    agency.</p>
                </div>
              </div>

              <div class="flex_space_between_row check_box_group">
                <img class="check_box_icon"
                     src="@/assets/check-mark-black.svg"
                     alt="check box image">
                <div class="flex_left_aligned_column check_box_set">
                  <h3>Criminal Background Checks</h3>
                  <p>Candidates are able to order criminal background checks that are made available to agencies at
                    much lower prices.</p>
                </div>
              </div>

              <div class="flex_space_between_row check_box_group">
                <img class="check_box_icon"
                     src="@/assets/check-mark-black.svg"
                     alt="check box image">
                <div class="flex_left_aligned_column check_box_set ">
                  <h3>Complete local documentation</h3>
                  <p>Agencies that require on-premises records can download and print copies.</p>
                </div>
              </div>

              <div class="flex_space_between_row check_box_group">
                <img class="check_box_icon"
                     src="@/assets/check-mark-black.svg"
                     alt="check box image">
                <div class="flex_left_aligned_column check_box_set">
                  <h3>Onboarding Efficiency</h3>
                  <p>Finding and onboarding new caregivers is now faster and easier than ever!</p>
                </div>
              </div>
            </div>

            <agency_cta_form
                feature="speedydelivery"
                :splash_color=colors.new_orange_main
                :button_color="colors.primary_purple"
                h_text="Ready to grow your business?"
                p_text="If you would like to learn more about how Tiny Magic Hat can grow your business, please drop us a line."
            ></agency_cta_form>

          </div>
        </Transition>


        <div id="sg_marker"></div>
        <Transition name="entry_fade_in">
          <div v-show="active_section_spyglass" class="flex_centered_column" id="for_agencies_content_spyglass">
            <!--              <div id="">-->
            <Transition>
              <img class=" padding_tb_20" id="spyglass_content_logo"
                   src="@/assets/spyglass2_color.svg"
                   alt="SpyGlass Logo">
            </Transition>
            <div class="feature_heading_text"><h2 class="spyglass_heading_text">Spot Opportunity</h2></div>

            <div class="flex_centered_row flex_wrap" id="sg_main_content">

              <div class="flex_centered_start_column">
                <div class=" spyglass_cards_header">
                  <h2 v-if="spyglass_full_desktop" class="spyglass_heading_text">Caregivers when you need them</h2>
                  <p class=" font_weight_800">Post schedules you need filled now.</p>
                </div>

              </div>


              <div class="flex_centered_row flex_wrap spyglass_cards_coll">

                <feature_card4
                    class="spyglass_card"
                    bg_color="transparent"
                    icon="icon_month_black.svg"
                    desc="Month icon"
                    :icon_color="colors.black"
                    :icon_bg_color="colors.transparent"
                    icon_scale="1.5"
                    @main_btn_clicked="do_nothing"
                >
                  <template v-slot:body>
                    <h3>Post Dates & Times Needed</h3>
                    <p>Advertise your needs to potential caregivers: days, times, approximate locations</p>
                  </template>
                </feature_card4>


                <feature_card4
                    class="spyglass_card"
                    bg_color="transparent"
                    icon="icon_person_pin_black.svg"
                    desc="Caregiver location pin icon"
                    :icon_color="colors.black"
                    :icon_bg_color="colors.transparent"
                    icon_scale="1.3"
                    @main_btn_clicked="do_nothing"
                >
                  <template v-slot:body>
                    <h3>Find a Nearby Caregiver</h3>
                    <p>Provides locality for caregivers to know what truly fits their availability.</p>
                  </template>
                </feature_card4>


                <feature_card4
                    class="spyglass_card"
                    bg_color="transparent"
                    icon="icon_cg_client_match_black.svg"
                    desc="Caregiver Client Match icon"
                    :icon_color="colors.black"
                    :icon_bg_color="colors.transparent"
                    icon_scale="1.5"
                    @main_btn_clicked="do_nothing"
                >
                  <template v-slot:body>
                    <h3>Match Caregivers and Clients</h3>
                    <p>Many caregivers have limited availability and need clients close by.
                      SpyGlass simplifies the entire search.</p>
                  </template>
                </feature_card4>


                <feature_card4
                    class="spyglass_card"
                    bg_color="transparent"
                    icon="icon_expand_black.svg"
                    desc="Expand icon"
                    :icon_color="colors.black"
                    :icon_bg_color="colors.transparent"
                    icon_scale="1.5"
                    @main_btn_clicked="do_nothing"
                >
                  <template v-slot:body>
                    <h3>Grow your Business</h3>
                    <p>Posting schedules is fast and easy. Now you can find a caregiver right when you need one.</p>
                  </template>
                </feature_card4>

              </div>
            </div>

            <section class="flex_centered_row flex_wrap" id="spyglass_lower_content">

              <div>
                <img class="cursor_ptr video_thumbnail"
                     src="@/assets/spyglass_play_comp-min.jpg"
                     alt="SpyGlass Video for caregivers thumbnail" @click="spyglass_video_make_true">
                <video_modal
                    :show_modal="spyglass_vid_up"
                    :video_id="videos.spyglass.video_id"
                    :base_height="videos.base_video_height"
                    :base_width="videos.base_video_width"
                    @bg_clicked="videos.spyglass.modal_up = false"
                ></video_modal>
              </div>


              <agency_cta_form
                  feature="spyglass"
                  :splash_color=colors.dark_gold
                  :button_color="colors.new_primary_purple_deep"
                  h_text="Ready to grow your business?"
                  p_text="If you would like to learn more about how Tiny Magic Hat can grow your business, please drop us a line."
              ></agency_cta_form>

            </section>

          </div>
        </Transition>


        <div id="ft_marker"></div>
        <Transition name="entry_fade_in">
          <div v-show="active_section_freetime" class="flex_centered_column" id="for_agencies_content_freetime">
            <img class=" " id="freetime_content_logo"
                 src="@/assets/freetime_sunset.svg"
                 alt="FreeTime Logo">
            <div class="feature_heading_text "><h2 class="">Pay more, Affordably.</h2></div>
            <div id="top_side_content_freetime">
            </div>


            <section class="flex_left_aligned_column" id="cg_conclusion_freetime">

              <div class="flex_centered_row flex_wrap" id="freetime_content_part2">
                <div class="flex_start_column" id="freetime_content_part2_A">
                  <div class="flex_centered_column">
                    <div id="get_your_bonus" class="font_akshar font_weight_800">Stretch your payroll dollars
                    </div>
                  </div>
                </div>
                <div class="flex_centered_row flex_wrap" id="freetime_content_part2_B">

                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_arrow_up_white.svg"
                      desc="Arrow up icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.cyanic_blue"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Pay more with less</h3>
                      <p>We allocate your payroll dollars to benefit your hardest working caregivers.</p>
                    </template>
                  </feature_card4>
                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_money_white.svg"
                      desc="Money icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.new_gold"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>What's the Cost?</h3>
                      <p>You decide! Funds are pooled and distributed to your hardest working caregivers.</p>
                    </template>


                  </feature_card4>
                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_over_time_white.svg"
                      desc="Hourly increase icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.new_orange_main"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Hourly Rates Go Up</h3>
                      <p>Pooled funds are paid as bonuses, increasing caregivers effective hourly rates.</p>
                    </template>


                  </feature_card4>
                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_horn_white.svg"
                      desc="Horn icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.primary_purple"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Advertise higher pay rates</h3>
                      <p>Capitalize on your investment. Your premium rates are reflected in your SpyGlass ads.</p>

                    </template>

                  </feature_card4>

                </div>

              </div>

            </section>

            <carousel_container
                class="carousel"
                :my_slides="get_carousel_quotes()"
                :body_color="this.colors.primary_purple"
            ></carousel_container>

            <div class="flex_space_between_row" id="freetime_content_part1">
              <div class="flex_centered_column" id="freetime_content_part1_A">
                <div class="flex_centered_column">
                  <h3>A $1 base rate raise</h3>
                  <div class="">
                    <p> With funds pooled, a $1 raise can yield <strong>$3-$4 dollar per hours raises</strong> for
                      your hardest working caregivers.</p>
                  </div>
                </div>
                <div class="flex_centered_column">
                  <h3>Who gets bonuses?</h3>
                  <p> FreeTime rewards caregivers who work more than 32 hours, with top bonuses to those working at
                    least 38 hours.</p>

                  <basic_text_button
                      class="padding_lr_6 padding_tb_6 go_to_freetime_calcs_proof"
                      :hover_color=colors.cyanic_blue
                      :hover_text_color=colors.white
                      :button_state="true"
                      :button_color="colors.primary_purple"
                      :text_color=colors.white
                      :button_color_not_ready="colors.eli_gray"
                      :button_not_ready_text_color="colors.pennie_gray"
                      button_height="52px"
                      button_width="150px"
                      border_rad_pct="0"
                      text="Show Me"
                      text_size="17px"
                      :bold="true"
                      event_name="learn_more_button_clicked"
                      @learn_more_button_clicked="show_agency_freetime_calcs_toggle"
                  ></basic_text_button>


                  <freetime_calcs_agency
                      :show_modal="show_agency_freetime_calcs"
                      @bg_clicked="show_agency_freetime_calcs_toggle"
                  ></freetime_calcs_agency>

                </div>
              </div>
              <div class="flex_centered_column" id="freetime_content_part1_B">
                <img class="cursor_ptr video_thumbnail"
                     src="@/assets/freetime_owners_play_comp-min.jpg"
                     alt="FreeTime Video for caregivers thumbnail" @click="freetime_video_make_true">

                <video_modal
                    :show_modal="freetime_vid_up"
                    :video_id="videos.freetime.video_id"
                    :base_height="videos.base_video_height"
                    :base_width="videos.base_video_width"
                    @bg_clicked="videos.freetime.modal_up = false"
                ></video_modal>
              </div>


            </div>
            <div class="flex_centered_row">
              <div class="flex_centered_row">


                <agency_cta_form
                    feature="freetime"
                    :splash_color=colors.new_primary_purple_deep
                    :button_color="colors.new_orange_main"
                    h_text="Ready to grow your business?"
                    p_text="If you would like to learn more about how Tiny Magic Hat can grow your business, please drop us a line."
                ></agency_cta_form>

              </div>
            </div>

          </div>


        </Transition>

      </section>

      <div class="flex_centered_row">
        <div class="flex_centered_column text_primary_purple padding_tb_30 font_raleway font_weight_800 cursor_ptr"
             @click="scroll_to_top">
          TOP
        </div>
      </div>

    </div>

    <error_popup_queue></error_popup_queue>
  </section>

  <outside_footer
      @link_clicked="hamburger_menu_false()">
  </outside_footer>

</template>

<script>
/* eslint-disable */
import video_modal from "@/components/parts/video_modal";
import outside_footer from "@/components/parts/outside_footer";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import selection_indicator from "@/components/parts/selection_indicator";
import feature_card2 from "@/components/parts/feature_card2";
import feature_card4 from "@/components/parts/feature_card4";
import feature_card5 from "@/components/parts/feature_card5";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import basic_text_button from "@/components/parts/basic_text_button";
import freetime_calcs from "@/components/parts/freetime_calcs";
import input_with_label from "@/components/parts/input_with_label";
import outside_banner_std from "@/components/parts/outside_banner_std";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import freetime_calcs_agency from "@/components/parts/freetime_calcs_agency";
import agency_cta_form from "@/components/parts/agency_cta_form";
import headers from "@/components/parts/headers";

import store from "@/store";
import {
  reentry_route,
  good_token_now,
  scroll_to_top,
  dual_route,
  scroll_to_element,
  analytic,
  add_class_to_body,
  remove_class_to_body,
  num_is_between_inclusive,
  colors,
  states,
  get_counties,
  form_to_email_info_account,
  validate_email
} from "@/library";

export default {
  name: "for_agencies",
  mounted() {

    add_class_to_body('bg_white')
    this.set_feature_card_picked(store.getters.get_for_agencies_active_section)
    this.run_window_width_adjustments(this.windowWidth)
    window.prerenderReady = true
    this.toggle_fade()
    scroll_to_element("page_top_x")
  },
  unmounted() {
    remove_class_to_body('bg_white')
  },

  data() {
    return {
      show_agency_freetime_calcs_: false,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      show_fade_: false,
      colors: colors,
      feature_card_picked: "speedydelivery",
      spyglass_full_desktop_break: 1305,
      states: states,
      spyglass_request: {
        first: "",
        last: "",
        city: "",
        state: "",
        email: "",
        agency: "",
        bad_email: true
      },
      speedydelivery_request: {
        first: "",
        last: "",
        city: "",
        state: "",
        email: "",
        agency: "",
        bad_email: true
      },
      a_state: "",
      a_city: "",
      a_email: "",
      a_agency: "",
      my_slides: [
        {text1: 'FreeTime bonuses have really make a big difference on my paycheck.', text2: 'Darcie P.', id: 1},
        {
          text1: 'I appreciate FreeTime, it\'s really helped me. I tell a lot of other people about this agency because of it.',
          text2: 'Kathy M.',
          id: 2
        },
        {text1: 'FreeTime has been very good for me.', text2: 'Nancy K.', id: 3},
        {text1: 'Yes, FreeTime has been pretty helpful to me.', text2: 'Naomi L.', id: 4},

      ],
      // my_slides: this.get_carousel_quotes,

      videos: {
        base_video_height: 180,
        base_video_width: 320,
        freetime: {
          video_id: "755302861",
          modal_up: false
        },
        spyglass: {
          video_id: "755298908",
          modal_up: false
        },
        speedydelivery: {
          video_id: "755297326",
          modal_up: false
        },
      },

    }
  },
  components: {
    outside_footer,
    notification_popup,
    error_popup_queue,
    video_modal,
    selection_indicator,
    feature_card2,
    feature_card4,
    feature_card5,
    carousel_container,
    basic_text_button,
    freetime_calcs,
    input_with_label,
    outside_banner_std,
    dropdown_datalist,
    freetime_calcs_agency,
    agency_cta_form,
    headers
  },
  methods: {
    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_for_agencies_carousel_quotes
    },
    do_nothing() {
      console.log("do nothing")
    },
    // async speedy_delivery_video_make_true(){
    //   await analytic(`SpeedyDelivery|So how can you prequalify?::video modal opened:CTA clicked`)
    //   this.videos.speedydelivery.modal_up=true
    // },
    async freetime_video_make_true() {
      await analytic(`FreeTime|Agency page video::video modal opened:CTA clicked`)
      this.videos.freetime.modal_up = true
    },
    async spyglass_video_make_true() {
      await analytic(`SpyGlass|OAgency page video::video modal opened:CTA clicked`)
      this.videos.spyglass.modal_up = true
    },
    request_speedydelivery() {

      this.$refs.contact_first.emit_state()
      this.$refs.contact_last.emit_state()
      this.$refs.contact_city.emit_state()
      this.$refs.contact_state.emit_state()
      this.$refs.contact_email.emit_state()
      this.$refs.contact_text_area.emit_state()


      if (!validate_email(this.a_email)) {
        store.commit('error_enqueue', `${this.a_email} is not valid.`)
        return null
      }


      let data = {
        state: this.a_state,
        city: this.a_city,
        email: this.a_email,
        agency: this.a_agency,
      }
      form_to_email_info_account(data)

      this.a_state = ""
      this.a_city = ""
      this.a_email = ""
      this.a_agency = ""

    },


    show_agency_freetime_calcs_toggle() {
      this.show_agency_freetime_calcs_ = !this.show_agency_freetime_calcs_
    },

    async go_to_schedules() {
      // this.hamburger_menu_false()
      await this.$router.push({path: 'spyglass'})
    },
    async go_to_register() {
      // this.hamburger_menu_false()
      await this.$router.push({path: 'register'})
    },
    set_feature_card_picked(s) {
      console.log(`Setting feature card to ${s}`)
      this.feature_card_picked = s
      this.activate_section_vis(s)
    },
    scroll_to_top() {
      scroll_to_top()
    },
    toggle_fade() {
      this.show_fade_ = !this.show_fade_
    },
    get_marker(m) {
      if (m === 'freetime') {
        return 'ft_marker'
      } else if (m === 'spyglass') {
        return 'sg_marker'
      } else {
        return 'sd_marker'
      }
    },
    activate_section_vis(section) {
      console.log(`${section} selection was clicked.`)
      this.feature_card_picked = section
      store.commit('activate_section_for_agencies', section)
      if (this.mobile_mode) {
        let marker = this.get_marker(section)
        scroll_to_element(marker, 0)
      }
    },
    dual_route_wrapper(route, inner = null) {
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      await analytic("01:01:01:01")
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },
  },
  computed: {

    // get_carousel_quotes(){
    //   return  store.commit('get_carousel_quotes', 'for_agencies')
    // },

    show_agency_freetime_calcs() {
      return this.show_agency_freetime_calcs_
    },

    counties() {
      if (this.a_state !== "") {
        return get_counties(this.a_state)
      } else {
        return []
      }
    },
    spyglass_full_desktop() {
      return this.windowWidth >= this.spyglass_full_desktop_break
    },
    box_picked() {
      let picked = this.feature_card_picked
      if (picked === 'speedydelivery') {
        return 1
      } else if (picked === 'spyglass') {
        return 2
      } else {
        return 3
      }
    },
    freetime_vid_up() {
      return this.videos.freetime.modal_up
    },
    spyglass_vid_up() {
      return this.videos.spyglass.modal_up
    },
    speedydelivery_vid_up() {
      return this.videos.speedydelivery.modal_up
    },


    active_section() {
      return store.getters.get_for_agencies_active_section
    },
    active_section_spyglass() {
      console.log("Inside active_section_spyglass")
      let target = 'spyglass'
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_agencies_active_section === target;
      }
    },
    active_section_speedydelivery() {
      console.log("Inside active_section_speedydelivery")
      let target = 'speedydelivery'
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_agencies_active_section === target;
      }
    },
    active_section_freetime() {
      let target = 'freetime'
      console.log("Inside active_section_freetime")
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_agencies_active_section === target;
      }
    },

    show_fade() {
      return this.show_fade_
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>


<style scoped>


@media (min-width: 100px) {

  .sg_input_form {
    width: 270px;
  }


  h2 {
    /*color: var(--new-primary-purple-deep);*/
  }

  .feature_cards_coll {
    margin: auto;
  }


  #top_selector_row_wrapper {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #speedydelivery_heading_text {
    color: var(--new-orange-main);
  }

  .spyglass_heading_text {
    color: var(--dark-gold);
  }

  #top_selector_text {
    margin-bottom: -35px;
  }

  #feature-buttons_set img {
    transition: scale 1s ease-out;
  }

  #feature-buttons_set img:hover {
    transform: scale(1.25);
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);*/
  }

  #tmh_agency_features_intro {
    max-width: 800px;
    /*margin-bottom: 30px;*/
    margin-top: 60px;
    padding-bottom: 50px;
  }

  #tmh_agency_features_intro h1 {
    padding: 10px;
    text-align: center;
    color: var(--cyanic-blue-mid);
    font-size: 30px;
    max-width: 560px;
    margin-bottom: 15px;
  }

  #tmh_agency_features_intro p {
    padding: 10px;
    font-size: 15px;
    color: white;
  }


  #freetime_content_part2_B {
    margin-top: 25px;
    align-items: start;
  }


  #freetime_content_part2_B p {
    max-width: 375px;
    font-size: 15px;
  }

  #freetime_content_part2_B h3 {
    font-size: 22px;
    color: var(--new-primary-purple-deep);
  }


  #get_your_bonus {
    font-size: 25px;
    color: var(--new-primary-purple-deep);
    text-align: center;
    margin-top: 32px;
    max-width: 300px;
  }

  .go_to_freetime_calcs_proof {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #freetime_content_part1 {
    /*width: 260px;*/
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #freetime_content_part1 h3 {
    font-size: 22px;
    margin-top: 30px;
  }


  #freetime_content_part2_A h3 {
    /*font-size: 26px;*/
    width: 286px;
    margin-top: 40px;
  }

  .ok_show_me_col h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    max-width: 95%;
    color: var(--new-orange-main);
  }

  .ok_show_me_col p {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .spyglass_cards_header {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .spyglass_cards_header h2 {
    max-width: 235px;
    /*font-size: 20px;*/
  }

  .spyglass_cards_coll h3 {
    font-size: 22px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .spyglass_cards_coll p {
    text-align: left;
  }

  .spyglass_cards_header p {
    /*width: 68%;*/
  }

  #speedydelivery_top_section {
    margin-top: 60px;
  }

  .feature_cards_coll p {
    font-size: 13px;
    text-align: left;
  }

  .feature_cards_coll {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #cg_conclusion_speedydelivery div {
    padding: 5px;
  }


  .all_check_boxes {
    max-width: 1900px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    margin-bottom: 5px;
    max-width: 523px;
    align-items: start;
  }

  .check_box_icon {
    width: 42px;
    margin-right: 10px;
  }

  .check_box_set h3 {
    font-size: 22px;
  }


  p {
    max-width: 95vw;
  }

  .feature_page_main {
    white-space: normal;
    word-break: break-word;
    width: 100vw;
  }

  h2 {
    font-size: 25px;
    text-align: center;
    padding-bottom: 20px;
  }

  .feature_heading_text {
    text-align: center;
    display: flex;
    justify-content: center;

  }

  #top_selector_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    /*padding-bottom: 50px;*/
  }

  #tmh_cg_features_intro h1 {
    color: var(--cyanic-blue);
    max-width: 250px;
  }

  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 100vw;
  }


  #spyglass_content_logo {
    width: 65%;
    max-width: 250px;
    margin-bottom: 0;
    /*margin-top: 40px;*/
  }

  #speedydelivery_content_logo {
    width: 250px;
  }

  #freetime_content_logo {
    width: 78%;
    max-width: 250px;
    padding-bottom: 20px;
  }


  #tmh_cg_features_intro h1 {
    text-align: center;
    font-size: 30px;
  }

  #tmh_cg_features_intro p {
    margin-top: 20px;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }


  #top_side_content_freetime {
    /*width: 100vw;*/
  }

  #for_agencies_content_freetime {
    padding-top: 80px;
  }

  #for_agencies_content_freetime h1 {
    font-size: 25px;
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_agencies_content_freetime h2 {
    width: 100%;
    text-align: center;
    color: var(--new-primary-purple-deep)
  }

  #freetime_content_part1_A {
    width: 260px;

  }

  #freetime_content_part1_A p {
    margin-bottom: 20px;
    font-size: 15px;
    text-align: left;
  }

  #freetime_content_part1_A h3 {
    margin-bottom: 20px;
    font-size: 26px;
    text-align: center;
    color: var(--new-primary-purple-deep);
  }

  #for_agencies_content_spyglass {
    box-sizing: border-box;
    background-color: var(--off-white);
    width: 100vw;
    padding-top: 50px;
  }

  #for_agencies_content_spyglass h1 {
    font-size: 25px;
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_agencies_content_spyglass h2 {
    width: 100%;
    text-align: center;
  }

  #for_agencies_content_spyglass p {
    margin-bottom: 20px;
    font-size: 15px;
    color: var(--new-primary-purple-very-deep);
  }

  #spyglass_content_logo img {
    width: 250px;
  }


  #for_agencies_content_speedydelivery {
    padding: 10px 10px;
  }

  #for_agencies_content_speedydelivery h1 {
    font-size: 25px;
    /*width: 280px;*/
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_agencies_content_speedydelivery h2 {
    max-width: 95%;
    text-align: center;
  }

  #for_agencies_content_speedydelivery p {
    margin-bottom: 20px;
    font-size: 15px;
  }

  h3 {
    color: var(--new-primary-purple-very-deep);
    /*color: black;*/
  }

  .a_bounce_box h3 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

  }

  .a_bounce_box p {
    padding-left: 20px;
    padding-right: 20px;
  }


  #box2 img {
    width: 350px;
    position: absolute;
    z-index: 110;
  }

  #box3 img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box6 img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }

  #box7 img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }


  #box2_sg img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box3_sg img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box6_sg img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }

  #box7_sg img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }


  #box2_sd img {
    width: 300px;
  }

  #box3_sd img {
    width: 300px;
  }

  #box6_sd img {
    width: 300px;
  }

  #box7_sd img {
    width: 200px;
  }

  #box2 img {
    width: 300px;
  }

  #box3 img {
    width: 300px;
  }

  #box6 img {
    width: 300px;
  }

  #box7 img {
    width: 200px;
  }


  #box2_sg img {
    width: 300px;
  }

  #box3_sg img {
    width: 300px;
  }

  #box6_sg img {
    width: 300px;
  }

  #box7_sg img {
    width: 200px;
  }


  #box2_sd img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box3_sd img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box6_sd img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }

  #box7_sd img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }

  #cg_conclusion_spyglass h3 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 25px;
  }

  #cg_conclusion_spyglass p {
    max-width: 650px;
  }


  #cg_conclusion_speedydelivery h3 {
    padding-bottom: 20px;
    text-align: center;
    font-size: 31px;
  }

  #cg_conclusion_speedydelivery p {
    max-width: 650px;
  }


  .carousel {
    padding-bottom: 30px;
  }

}

@media (min-width: 400px) {

  .feature_cards_coll p {
    font-size: 16px;
    text-align: left;
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    max-width: 300px;
  }
}

@media (min-width: 500px) {

  .feature_cards_coll p {
    font-size: 18px;
    text-align: left;
  }

  #freetime_content_part1_B {
  }

  .spyglass_cards_coll h3 {
    font-size: 26px;
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    font-size: 43px;
    text-align: center;
    max-width: 350px;
  }

  #tmh_cg_features_intro p {
    font-size: 25px;
  }

  #for_agencies_content_speedydelivery p {
    max-width: 500px;
  }
}

@media (min-width: 680px) {


  #freetime_content_part1_B {
  }
}

@media (min-width: 720px) {

  #spyglass_lower_content {
    align-items: center;
    margin-top: 60px;
  }

  .ok_show_me_col h3 {
    margin-bottom: 20px;
  }


  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 776px;
  }
}


@media (min-width: 800px) {

  .spyglass_cards_header p {
    /*width: 75%;*/
  }

  #for_agencies_content_spyglass {
    padding: 20px;
    background-color: white;
  }

  .sel_indicator {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #tmh_cg_features_intro h1 {
    max-width: 400px;
  }


  .all_check_boxes {
    max-width: 1000px;
  }

  #freetime_content_part1 {
    padding: 20px;
  }
}


@media (min-width: 1000px) {

  .carousel {
    padding-bottom: 30px;
    margin-top: 50px;
  }

  #spyglass_lower_content {
    margin-top: 0;
  }

  #spyglass_lower_content img {
    margin-bottom: 0;
  }


}

@media (min-width: 1175px) {


  #freetime_content_part2_A h3 {
    font-size: 25px;
  }

}

@media (min-width: 1200px) {


  #tmh_agency_features_intro h1 {
    font-size: 50px;
  }

  #tmh_agency_features_intro p {
    /*padding: 10px;*/
    font-size: 30px;
    /*color: white;*/
  }

  .spyglass_cards_coll {
    margin-bottom: 40px;
  }

  .spyglass_card {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  #for_agencies_content_spyglass {
    margin: 50px 50px;
  }

  #freetime_content_part2_B {
    width: 1080px;
  }

  #freetime_content_part2_A h3 {
    font-size: 25px;
    width: 300px;
    margin-top: 40px;
  }

  #freetime_content_part1 {
    width: 1100px;
    justify-content: space-between;
  }

  .go_to_freetime_calcs_proof {
    margin-top: 30px;
    align-self: flex-end;
  }

  .feature_cards_coll {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: unset;
    margin-right: unset;
  }

  .spyglass_cards_coll {
    width: 1020px;
  }


  #speedydelivery_content_logo {
    width: 300px;
  }

  #speedydelivery_top_section {
    margin-bottom: 40px;
  }

  #for_cgs_content_speedydelivery p {
    font-size: 20px;
  }

  #for_cgs_content_speedydelivery h2 {
    width: 100%;
  }


  #tmh_cg_features_intro h1 {
    /*color: var(--new-primary-purple-deep);*/
    font-size: 50px;
  }

  #tmh_cg_features_intro p {
    font-size: 30px;
  }

}


@media (min-width: 1305px) {
  .spyglass_cards_header {
    margin-right: 30px;
  }
}


@media (min-width: 1380px) {
  #get_your_bonus {
    text-align: right;
  }
}


@media (min-width: 1568px) {


  #top_selector_row_wrapper {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1300px;
  }

  #top_selector_text {
    width: 690px;
  }

  .spyglass_cards_header {
    margin-top: 30px;
  }

  .spyglass_cards_header p {
    /*width: 59%;*/
    /*text-align: right;*/
  }

  #cg_conclusion_spyglass h3 {
    margin-top: 40px;
  }

  #freetime_content_part1 {
    width: 1470px;
    justify-content: space-around;
  }


}


@media (min-width: 1600px) {

  .all_check_boxes {
    max-width: 1500px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    max-width: 523px;
    margin: 20px;
    margin-bottom: 40px;
  }

  .check_box_set h3 {
    font-size: 22px;
    color: var(--primary-purple);
  }
}

@media (min-width: 2000px) {


  #sg_main_content {
    margin-top: 40px;
  }

  .ok_show_me_col h3 {
    margin-bottom: 30px;
  }

  .ok_show_me_col p {
    max-width: 450px;
  }

  #top_selector_row_wrapper {
    /*width: 50%;*/
  }


  .all_check_boxes {
    max-width: 1500px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    margin-bottom: 30px;
    max-width: 523px;
  }

  .check_box_set h3 {
    font-size: 22px;
  }

  #for_cgs_content_speedydelivery p {
    font-size: 24px;
  }

  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 800px;
    padding-top: 40px;
  }

  #speedydelivery_content_logo {
    max-width: 500px;
  }

  #spyglass_content_logo {
    /*width: 280px;*/
    max-width: 320px;
  }

  #freetime_content_logo {
    max-width: 300px;
  }

  #for_agencies_content_freetime p {
    margin-bottom: 20px;
    font-size: 20px;
  }


  #tmh_cg_features_intro h1 {
    margin-bottom: 30px;
    max-width: 800px;
    font-size: 50px;
  }

  #tmh_cg_features_intro p {
    font-size: 25px;
  }

  #for_agencies_content_freetime {
    margin-bottom: 100px;
  }

  #for_agencies_content_freetime h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }

  #for_agencies_content_spyglass {
    margin-bottom: 100px;
  }

  #for_agencies_content_spyglass h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }

  #for_agencies_content_spyglass img {
    /*margin-top: 80px;*/
  }

  #cg_conclusion_spyglass a {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 300;

  }

  #cg_conclusion_spyglass h3 {
    margin-top: 80px;
    padding-bottom: 35px;
  }

  #cg_conclusion_spyglass p {
    font-size: 25px;
    max-width: 800px;
  }


  #for_cgs_content_speedydelivery h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }


  #cg_conclusion_speedydelivery a {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 300;

  }

  #cg_conclusion_speedydelivery h3 {
    padding-bottom: 35px;
  }

  #cg_conclusion_speedydelivery p {
    font-size: 25px;
    max-width: 800px;
  }


  #top_side_content_freetime p {
    font-size: 25px;
  }

  #top_side_content_spyglass p {
    font-size: 25px;
    max-width: 1000px;
  }

  #top_side_content_speedydelivery p {
    font-size: 25px;
    max-width: 1000px;
  }


  .a_bounce_box h3 {
    margin-bottom: 25px;
  }


  #box1 p, #box4 p, #box5 p, #box8 p {
    padding-left: 60px;
    padding-right: 60px;
  }

  #box1_sg p, #box4_sg p, #box5_sg p, #box8_sg p {
    padding-left: 60px;
    padding-right: 60px;

  }

  #box2_sg img {
    width: 350px;
  }

  #box1_sd p, #box4_sd p, #box5_sd p, #box8_sd p {
    padding-left: 60px;
    padding-right: 60px;

  }


}


@media (min-width: 3000px) {
  #top_selector_row_wrapper {
    width: 45%;
    max-width: 1350px;
  }

}

</style>

