<template>
  <section class="flex_centered_start_column core_c">
    <outside_banner_std></outside_banner_std>
    <notification_popup></notification_popup>
    <div id="page_top"></div>
    <div class="flex_centered_row">
      <section class="flex_centered_column about_main">

        <div class="about_top_wrapper flex_wrap">
          <div class="about_top">
            <div class="about_main_left ">
              <h3 class="about_us">About Us</h3>
              <p class="text_white">At Tiny Magic Hat, we strive to make the lives of caregivers and their teams
                better while helping to make things easier for those who need home care.</p>
            </div>
            <div class="about_main_right">
              <img v-if="desktop_mode" class="cursor_ptr video_thumbnail about_us_img"
                   src="@/assets/istock/coffee_couch-min.jpg"
                   alt="Picture of a yellow flower">
            </div>
          </div>
        </div>



        <div class="first_row flex_wrap row">
          <div class="img_wrapper">
            <img class="cursor_ptr video_thumbnail house"
                 src="@/assets/istock/toy_house-min.jpg"
                 alt="Hands of two people holding a toy small business.">
          </div>
          <div class=" row_text">
            <h5>Small company with big ideas and excellent customer service</h5>
            <p>For us this means acting with kindness, integrity and being
              here when you need help. Caregiving is about helping and we are all caregivers.</p>
          </div>
        </div>

        <div class=" second_row  flex_wrap">
          <div class="flex_centered_column height_100p">
            <h3>Agencies are foundational to the home care ecosystem.</h3>
<!--            <h5>Working with agencies, not against them.</h5>-->
            <h5>We integrate with them instead of competing against them.</h5>
          </div>

        </div>

        <div class=" third_row flex_wrap row">
          <div class=" row_text">
            <h5>Solving the staffing dilemma</h5>
            <p>Caregiving agencies have struggled for years to hire enough caregivers to
              meet the needs of their clients. Despite the proliferation of online job boards,
              hiring is harder than ever.</p>
            <p>It was from these struggles the ideas behind Tiny Magic Hat were conceived.</p>
          </div>
          <img class="cursor_ptr video_thumbnail calendar"
               src="@/assets/istock/dual_monitors.jpg"
               alt="Picture of a busy on screen calendar">
        </div>

        <div class=" fourth_row flex_wrap row">
          <img v-if="desktop_mode" class="cursor_ptr video_thumbnail hands"
               src="@/assets/istock/old_hands.jpg"
               alt="Picture of many hands with a red heart painted on top of all of them.">
          <div class=" row_text">
            <h5>Working hand in hand with Agencies</h5>
            <p>Finding ways to optimize and fully utilize all available caregivers, our industries most precious
              resource.</p>
            <p>Tools designed to work in tandem with agencies to optimize all aspects of staffing - from placement to
              retention.</p>
            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <img v-if="!desktop_mode" class="cursor_ptr video_thumbnail hands"
                     src="@/assets/istock/old_hands.jpg"
                     alt="Picture of the hand of an elderly woman being held by a pair of younger hands..">
              </div>
            </div>

          </div>
        </div>

        <div class=" third_row flex_wrap row">
          <div class=" row_text">
           <h5>Listening to those who need home care</h5>
            <p>We make it simple for those in need of help to connect with home care agencies
              in our network so they can get the care they need.</p>
          </div>
          <img class="cursor_ptr video_thumbnail calendar"
               src="@/assets/istock/consultation-min.jpg"
               alt="Picture of a busy on screen calendar">
        </div>

      </section>
      <error_popup_queue></error_popup_queue>
    </div>
  </section>
  <outside_footer class="footer_c"></outside_footer>


</template>
<script>
/* eslint-disable */
import {
  colors, num_is_between_inclusive, scroll_to_top
} from "@/library";

import outside_footer from "@/components/parts/outside_footer";
import outside_banner_std from "@/components/parts/outside_banner_std";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import store from "@/store";

export default {
  name: "hello_landing",
  components: {
    outside_footer,
    outside_banner_std,
    notification_popup,
    error_popup_queue
  },
  async mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
    // add_class_to_body('bg_eli_gray')
  },
  async unmounted() {
    // remove_class_to_body('bg_eli_gray')
  },
  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 1200,
    }
  },
  methods: {
    do_nothing() {
      console.log('doing nothing')
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },


  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    desktop_mode() {
      return !this.mobile_mode_x
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>


<style scoped>

@media (min-width: 100px) {


  /*h3 {*/
  /*  margin-top: 40px;*/
  /*}*/
  h4 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  /*h5 {*/
  /*  margin-top: 50px;*/
  /*  font-size: 20px;*/
  /*  margin-bottom: 15px;*/
  /*  padding-left: 10px;*/
  /*  padding-right: 10px;*/
  /*}*/
  p {
    font-size: 14px;
    margin-bottom: 10px;
    /*padding-right: 10px;*/
    /*padding-left: 10px;*/
  }


  .about_main {
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
  }

  .about_top_wrapper {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
  }

  .about_top {
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /*align-content: center;*/
  }


  .about_top h3 {
    color: var(--cyanic-blue-mid);
    font-size: 26px;
    /*margin-top: 40px;*/
    margin-bottom: 20px;
    /*text-align: center;*/
    /*margin-top: 60px;*/
  }

  .about_top p {
    /*text-align: center;*/
    /*margin-left: 20px;*/
    /*margin-right: 20px;*/
    /*margin-bottom: 70px;*/
    /*margin-top: 20px;*/
  }

  .about_main_left {
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .about_main_left p {
    max-width: 90%;
    font-size: 16px;
  }


  .house {
    margin-top: 60px;
  }


  .calendar {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .hands {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .first_row {
    /*min-width: 100vw;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .first_row .row_text h5 {
    max-width: 90vw;
  }

  .row_text h5 {
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .row_text p {
    padding-right: 10px;
    padding-left: 10px;
  }

  /*.first_row h5 {*/
  /*  text-align: center;*/
  /*  font-size: 22px;*/
  /*}*/
  /*.first_row p {*/
  /*  padding-right: 10px;*/
  /*  padding-left: 10px;*/
  .second_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 20px;
    padding-bottom: 20px;


    /*margin-top: 40px;*/
    /*margin-bottom: 10px;*/
    /*padding-left: 10px;*/
    /*padding-bottom: 30px;*/
    width: 100vw;
    background-color: var(--cyanic-blue-light);
    /*background-color: var(--new-primary-purple-lighter);*/
    text-align: center;
  }

  .second_row h3{
    font-size: 32px;
  }

  .second_row h5 {
    font-size: 22px;
  }

  .third_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*align-content: center;*/
  }


  .third_row h5 {
    /*text-align: center;*/
  }

  .fourth_row h5 {
    text-align: center;
  }


  .video_thumbnail {
    max-width: 99%;
    height: 125px;
    width: auto;
  }

}

@media (min-width: 400px) {
  p {
    max-width: 90%;
  }

  .about_main {
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
  }

  .about_top p {
    max-width: 80%;
  }


}

@media (min-width: 500px) {
  .about_top h3 {
    font-size: 36px;
  }

  h4 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  h5 {
    /*margin-bottom: 15px;*/
  }

  .second_row h5 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  p {
    font-size: 16px;
    /*margin-bottom: 10px;*/
    /*padding-right: 10px;*/
    /*padding-left: 10px;*/
  }

  .about_main {
    max-width: 450px;
  }

  .about_main_left p {
    font-size: 18px;
    max-width: 390px;
  }

  .house {
    margin-top: 80px;
  }

  .video_thumbnail {
    max-width: 99%;
    height: 200px;
    width: auto;
  }

  .row_text h5 {
    font-size: 22px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}

@media (min-width: 700px) {
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .about_main {
    max-width: 550px;
  }
}



@media (min-width: 1200px) {
  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 26px;
  }

  p {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .about_main {
    max-width: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
  }


  .about_main_left {
    max-width: 490px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: flex-start;
    margin-right: 125px;
  }


  .about_main_left p {
    font-size: 26px;
  }

  .about_top_wrapper {
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*max-width: 1100px;*/
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .about_top {
    /*max-width: 1100px;*/
    width: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .about_top h3 {
    font-size: 50px;
  }


  .about_top p {
    max-width: 500px;
  }


  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 400px;
  }

  .about_us {
    margin-bottom: 35px;
  }

  .house {
    margin-right: unset;
    margin-top: unset;
  }

  .calendar {
    margin-left: 80px;
  }

  .hands {
    margin-right: 80px;
  }

  .row {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .row p {
    margin-top: 20px;
  }


  .row_text {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /*align-content: center;*/
  }

  .row_text h5 {
    font-size: 30px;
    margin: unset;
  }

  .first_row {
    min-width: 1150px;
    margin-top: 150px;
    /*background-color: red;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .first_row .row_text {
    /*min-width: 1150px;*/
    /*background-color: red;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .first_row .row_text h5 {
    max-width: 60vw;
    text-align: left;
  }

  .img_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .row_text {

  }

  .row_text p {
    padding-left: 0;
    padding-right: 0;
  }

  .second_row {
    width: 100vw;
  }

 .second_row h3{
    font-size: 42px;
  }

  .second_row h5 {
    font-size: 22px;
  }


  /*.second_row h5 {*/
  /*  font-size: 30px;*/
  /*}*/

  .third_row {
    min-width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .third_row .row_text {
    max-width: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .third_row p {
    text-align: right;
    max-width: 600px;
  }

  .fourth_row {
    min-width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .fourth_row .row_text {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }


  .fourth_row .row_text p {
    max-width: 500px;
  }


  .fourth_row p {
    text-align: left;
  }

  .second_row {
    padding-top: 80px;
    padding-bottom: 80px;
  }


}

@media (min-width: 2000px) {
  h4 {
    font-size: 32px;
  }

  p {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .about_main {
    max-width: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
  }


  .about_main_left {
    max-width: 630px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: flex-start;
    margin-right: 125px;
  }


  .about_main_left p {
    font-size: 26px;
  }


  .about_top h3 {
    font-size: 50px;
  }


  .about_top p {
    max-width: unset;
  }


  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 600px;
  }

   .about_us_img{
    width: 1000px;
     /*z-index: 200; */
  }


  .about_us {
    margin-bottom: 35px;
  }

  .house {
    margin-right: 80px;
  }

  .calendar {
    margin-left: 80px;
  }

  .hands {
    margin-right: 80px;
  }

  .row {
    margin-top: 160px;
  }

  .row p {
    margin-top: 20px;
  }


  .row_text {
    max-width: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /*align-content: center;*/
  }

  .first_row {
    min-width: unset;
    width: 1660px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .third_row {
    min-width: unset;
    width: 1520px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .third_row .row_text {
    max-width: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }



  .third_row p {
    text-align: right;
  }

  .fourth_row {
     min-width: unset;
    width: 1440px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .fourth_row .row_text {
    max-width: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .fourth_row p {
    text-align: left;
  }

  .second_row {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 100px;
  }

}
</style>