<template>
  <section class="core_c flex_centered_start_column ">

    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>


    <h3>Say Hello!</h3>
    <p class="text_align_center">We'd love to hear from you. Go ahead, ask us anything.</p>


    <div class="flex_centered_column">
      <input_with_label
          ref="contact_first"
          class="form_input"
          label="First Name"
          @emit_state="catch_first"
      ></input_with_label>

      <input_with_label
          ref="contact_last"
          class="form_input"
          label="Last Name"
          @emit_state="catch_last"
      ></input_with_label>

      <input_with_label
          ref="contact_city"
          class="form_input"
          label="City"
          @emit_state="catch_city"
      ></input_with_label>

<!--      <input_with_label-->
<!--          ref="contact_state"-->
<!--          class="form_input"-->
<!--          label="State"-->
<!--          @emit_state="catch_state"-->
<!--      ></input_with_label>-->

      <dropdown_datalist
          ref="contact_state"
          class="form_input"
          label="State"
          :data_list=states
          @value-selected="catch_state"
      ></dropdown_datalist>

      <input_with_label
          ref="contact_email"
          class="form_input"
          label="Email"
          @emit_state="catch_email"
      ></input_with_label>

      <input_with_label
          ref="contact_phone"
          class="form_input"
          label="Phone Number"
          @emit_state="catch_phone"
      ></input_with_label>

      <input_with_label_text_area
          ref="contact_text_area"
          class="form_input"
          :columns="columns_num"
          :rows="rows_num"
          @emit_state="catch_text_content"
      >
      </input_with_label_text_area>

      <basic_text_button
          class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.new_orange_main"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="150px"
          border_rad_pct="0"
          text="SEND"
          text_size="14px"
          :bold="true"
          event_name="confirm_button_clicked"
          @confirm_button_clicked="send_message_to_info"
          v-on:keyup.enter="send_message_to_info"
      ></basic_text_button>
    </div>

    <!--    <div class="text_40">Contact form page</div>-->
  </section>
  <outside_footer
      class="footer_c"
  ></outside_footer>
</template>

<script>
/* eslint-disable */
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import input_with_label from "@/components/parts/input_with_label";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import dropdown_datalist from "@/components/parts/dropdown_datalist";

import {
  analytic,
  get_uuid,
    scroll_to_top,
  colors,
  form_to_email_info_account,
  num_is_between_inclusive,
  validate_email,
  validate_phonenumber, states
} from "@/library";
import store from "@/store";

export default {
  name: "contact_form",
  mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
  },

  components: {
    basic_text_button,
    notification_popup,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label,
    input_with_label_text_area,
    dropdown_datalist
  },
  data() {
    return {
      colors: colors,
      states: states,
      columns_num_: "",
      rows_num_: "",
      desktop_cutoff: 700,
      bad_email: true,
      bad_phone: true,
      input: {
        first: "",
        last: "",
        city: "",
        state: "",
        email: "",
        phone: "",
        text_content: "",
      }
    }
  },
  methods: {

    do_nothing() {
      console.log("do nothing")
    },

    run_window_width_adjustments(new_w) {
      // new_w = store.state.windowWidthOuter
      // console.log("Running window adjustments on contact page")
      console.log(`Contact page width is ${store.state.windowWidth}`)
      console.log(`Contact page outer width is ${store.state.windowWidthOuter}`)
      // console.log("setting to small")
      //  this.columns_num_ = 32
      //   this.rows_num_ = 5


      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 679, new_w)) {
        console.log("inside the 100 to 679 set")

        this.columns_num_ = 15
        this.rows_num_ = 5
      }
      // if (num_is_between_inclusive(301, 500, new_w)) {
      //   this.columns_num_ = 26
      //   this.rows_num_ = 5
      // }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        console.log("setting to large")
        this.columns_num_ = 60
        this.rows_num_ = 10
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },

    catch_first(content) {
      this.input.first = content.trim().slice(0, 50)
    },
    catch_last(content) {
      this.input.last = content.trim().slice(0, 50)
    },
    catch_city(content) {
      this.input.city = content.trim().slice(0, 50)
    },

    catch_state(the_state) {
      console.log(`${the_state} was selected as the state.`)
      this.input.state = the_state.trim().slice(0, 50)
    },
    catch_email(content) {
      if (!validate_email(content)) {
        this.bad_email = true
      }
      this.input.email = content.trim().slice(0, 100)
      this.bad_email = false

    },
    catch_phone(content) {
      let t_content = content.trim().slice(0, 50)
      if (validate_phonenumber(t_content)) {
        this.input.phone = t_content
        this.bad_phone = false
      }
    },
    catch_text_content(content) {
      this.input.text_content = content.trim().slice(0, 1010)
    },
    send_message_to_info() {
      this.$refs.contact_first.emit_state()
      this.$refs.contact_last.emit_state()
      this.$refs.contact_city.emit_state()
      // this.$refs.contact_state.emit_state()
      this.$refs.contact_email.emit_state()
      this.$refs.contact_phone.emit_state()
      this.$refs.contact_text_area.emit_state()

      if (this.bad_email) {
        store.commit('error_enqueue', `Please provide your email.`)
        return null
      }

      if (this.bad_phone) {
        store.commit('error_enqueue', `Please provide your phone number.`)
        return null
      }

       if (!this.input.first) {
        store.commit('error_enqueue', `First Name requires a value.`)
        return null
      }
      if (!this.input.last) {
        store.commit('error_enqueue', `Last name requires a value.`)
        return null
      }

      if (!this.input.city) {
        store.commit('error_enqueue', `City requires a value.`)
        return null
      }

       if (!this.input.state) {
        store.commit('error_enqueue',`State requires a value.`)
        return null
      }

      form_to_email_info_account(
          {
            first: this.input.first,
            last: this.input.last,
            city: this.input.city,
            state: this.input.state,
            email: this.input.email,
            phone: this.input.phone,
            text_content: this.input.text_content,
          }
      )
      this.input.first = ""
      this.input.last = ""
      this.input.city = ""
      this.input.state = ""
      this.input.email = ""
      this.input.phone = ""
      this.input.text_content = ""
      this.$refs.contact_first.reset_state()
      this.$refs.contact_last.reset_state()
      this.$refs.contact_city.reset_state()
      this.$refs.contact_state.reset_state()
      this.$refs.contact_email.reset_state()
      this.$refs.contact_phone.reset_state()
      this.$refs.contact_text_area.reset_state()
      this.bad_email = true
    },

    async simple_route_prep(r, analytic_log = null) {
      console.log("in simple route prep")
      if (analytic_log) {
        // console.log("Internal Routing log recorded.")
        analytic(analytic_log)
      }
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      console.log(`Going to ${this_route}`)
      await this.$router.push({path: this_route})
    },
  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>


@media (min-width: 100px) {

  .form_input {
    width: 273px;
  }

  h3 {
    margin-top: 40px;
  }

  p {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 680px) {
  .form_input {
    width: 600px;
  }
}

</style>