<template>

  <section class="core_c flex_centered_start_column " >
    <notification_popup></notification_popup>
    <inside_banner
        mode="profileagency_info"
    >
    </inside_banner>

    <div class="flex_centered_row">
      <div class="flex_centered_column info_form">
        <h1 class="text_align_center">Agency Information</h1>
        <p>The information on this page is visible to those associated with your agency.</p>
        <div class="indiv_inputs">
          <input_with_label
              ref="input_agency_name"
              class="info_input margin_t_25"
              label="Agency Name"
              :start_value="profile_agency_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_agency_address1"
              class="info_input"
              label="Street Address"
              :start_value="profile_address_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_agency_address2"
              class="info_input"
              label="Suite Number"
              :start_value="profile_address2_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_agency_city"
              class="info_input"
              label="City"
              :start_value="profile_city_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <dropdown_datalist
              ref="input_agency_state"
              label="State"
              class="info_input"
              :start_value="profile_state_name"
              :data_list=states
              @value-selected="state_selected"
          ></dropdown_datalist>

          <input_with_label
              ref="input_agency_zip"
              class="info_input"
              label="Zip"
              :start_value="profile_zip_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_agency_email"
              class="info_input"
              label="Email"
              :start_value="profile_email"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_agency_phone"
              class="info_input"
              label="Phone Number"
              :start_value="profile_phone"
              @emit_state="do_nothing"
          ></input_with_label>
          <basic_text_button
              class="padding_lr_6 padding_tb_6 s_button "
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="Submit"
              text_size="17px"
              :bold="true"
              event_name="submit_personal_info"
              @submit_personal_info="catch_agency_profile_data"
          ></basic_text_button>
        </div>
      </div>
    </div>
     <error_popup_queue></error_popup_queue>

  </section>

  <outside_footer id="footer" class="footer_c">
  </outside_footer>

</template>

<script>
import input_with_label from "@/components/parts/input_with_label";
import inside_banner from "@/components/parts/inside_banner";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import outside_footer from "@/components/parts/outside_footer";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  scroll_to_top, states, colors, be_main_address, parse_internal_json, validate_email, validate_phonenumber,
   get_pda_home_route
} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "pda_info",

  beforeMount() {
    this.profile_text = store.getters.get_agency_profile_text
    console.log(`Inside before mount`)
    console.log(this.profile_text)
  },

  async mounted() {
    scroll_to_top()
  },
  data() {
    return {
      states: states,
      colors: colors,
      a_state: "",
      profile_text: {},
      // agency_text: {}
    }
  },
  components: {
    input_with_label,
    inside_banner,
    notification_popup,
    error_popup_queue,
    outside_footer,
    dropdown_datalist,
    basic_text_button
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },


    back_to_agency_profile(){
       this.$router.push({path: get_pda_home_route()})
    },

    state_selected(the_state) {
      console.log(`${the_state} was selected as the state.`)
      this.a_state = the_state
    },
    async catch_agency_profile_data() {

      let valid_email = validate_email(this.$refs.input_agency_email.get_value())
      if (!valid_email) {
        store.commit("error_enqueue", "The agency email address you entered is invalid.")
        this.$refs.input_agency_email.text_value_ = ""
        this.agency_profile_text.email = ""
        // this.toggle_profile_form()
        return null
      }
      let valid_phone_number = validate_phonenumber(this.$refs.input_agency_phone.get_value())
      if (!valid_phone_number) {
        store.commit("error_enqueue", "The agency phone number you entered is invalid.")
        this.$refs.input_agency_phone.text_value_ = ""
        this.agency_profile_text.phone_number = ""
        // this.toggle_profile_form()
        return null
      }


      this.profile_text = {
        "agency_name": this.$refs.input_agency_name.get_value(),
        "street_address": this.$refs.input_agency_address1.get_value(),
        "street_address2": this.$refs.input_agency_address2.get_value(),
        "city": this.$refs.input_agency_city.get_value(),
        "state": this.$refs.input_agency_state.get_value(),
        "zip": this.$refs.input_agency_zip.get_value(),
        "phone_number": this.$refs.input_agency_phone.get_value(),
        "email": this.$refs.input_agency_email.get_value(),
      }
      console.log(JSON.stringify(this.profile_text))
      // this.toggle_profile_form()
      // console.log(`Org Context is: ${this.org_context}`)
      await this.save_profile_info()
    },
    async save_profile_info() {

      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        profile_text: this.profile_text,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('pda_profiles') + 'save_agency_profile'
      axios.post(target_url, data1)
          .then((response) => {
            let the_data_only = parse_internal_json(response.data)

            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)
            }
            // this.local_page_update()

          });
      this.back_to_agency_profile()
    },
  },
  computed: {



    profile_agency_name() {
      try {
        // console.log("in first name.......")
        // console.log(this.profile_text.first_name)
        return this.profile_text.agency_name
      } catch (error) {
        return ""
      }

    },
    // pda_profile_route() {
    //   return get_pda_home_route()
    // },
    profile_address_name() {
      try {
        return this.profile_text.street_address
      } catch (error) {
        return ""
      }
    },
    profile_address2_name() {
      try {
        return this.profile_text.street_address2
      } catch (error) {
        return ""
      }
    },
    profile_city_name() {
      try {
        return this.profile_text.city
      } catch (error) {
        return ""
      }
    },
    profile_state_name() {
      try {
        return this.profile_text.state
      } catch (error) {
        return ""
      }
    },
    profile_zip_name() {
      try {
        return this.profile_text.zip
      } catch (error) {
        return ""
      }
    },
    profile_email() {
      try {
        return this.profile_text.email
      } catch (error) {
        return ""
      }
    },
     profile_phone() {
      try {
        // console.log("inside phone computed property")
        // console.log(this.pro)

        return this.profile_text.phone_number
      } catch (error) {
        return ""
      }
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  .info_form {
    width: 260px
  }

  h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    margin-left: 20px;
    /*text-align: center;*/

  }

  .s_button {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

@media (min-width: 500px) {
  .info_form {
    width: 380px
  }

  .info_input {
    width: 380px;
  }

  h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    margin-left: 20px;
    /*text-align: center;*/

  }

  .s_button {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .info_form {
    width: 1000px;
  }

  .indiv_inputs {
    padding: 30px;
  }

  .info_input {
    width: 500px;
  }

  .s_button {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  h1 {
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    margin-left: 20px;
    width: 500px;
    /*text-align: center;*/

  }
}

</style>
