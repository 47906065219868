<template>

  <div class="slide_over_general z_400 flex_start_column the_menu" v-if="show_hamburger_menu">
    <slot></slot>
  </div>

</template>

<script>
export default {
  name: "basic_slide_over_menu",
  props: ["show_hamburger_menu", "mobile_mode", "left", "bg_color", "bg_color2"],
  async updated() {
    try {
      // console.log("running text button adjustments")
      let whole_menu = document.querySelector('.the_menu')

      if (this.bg_color2) {
        if (this.mobile_mode) {
          whole_menu.style.background = this.bg_color
        } else {
          whole_menu.style.background = this.bg_color2
        }
      } else {
        whole_menu.style.background = this.bg_color
      }

      if (this.left === false) {
        whole_menu.style.right = 0
      } else {
        whole_menu.style.left = 0
      }
    } catch (error) {
      // console.error(error)
    }


  },
}
</script>

<style scoped>


@media (min-width: 100px) {
  .slide_over_general {
    position: fixed;
    width: 60%;
    max-width: 225px;
    /*height: 100%;*/
    /*background-color: var(--eli_gray);*/
    box-shadow: 3px 3px 3px black;
    top: 0;
  }
}


</style>