<template>
  <module_main_page
      module_download_name="f47c5e92-3f87-4aa9-957f-ba3887e1959f"
      module_logo_name="sd_hippa.svg"
      :module_desc_text=module_text
      module_headline="Maintaining Confidentiality"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "hippa",
  data() {
    return {
      module_text: "Maintaining client confidentiality is of utmost importance in the homecare context as it is essential to build\n" +
          "        trust and maintain a professional relationship between the caregiver and the client. Confidentiality refers to\n" +
          "        the obligation of the caregiver to keep all personal and medical information about the client private and only\n" +
          "        share it with authorized individuals, such as other healthcare providers or family members with the client's\n" +
          "        consent. Caregivers must also comply with applicable laws and regulations, such as HIPAA, which mandate strict\n" +
          "        confidentiality safeguards for all patient health information. ",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>


