<template>
  <section class="core_c flex_centered_start_column">
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>


    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column">

         <h4 class="enter_your_code">Confirm Sign Up</h4>
         <input_with_label
              ref="confirmation_code_enter_box"
              class="login_input"
              label="Enter your 6 Digit Code"
              @emit_state="catch_confirm_code"
          ></input_with_label>


         <basic_text_button
              class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="CONFIRM"
              text_size="14px"
              :bold="true"
              event_name="confirm_button_clicked"
            @confirm_button_clicked="submit_confirmation_code"
              v-on:keyup.enter="submit_confirmation_code"
            tabindex="0"
          ></basic_text_button>


<!--        <basic_text_button-->
<!--            class="margin_t_40 shadow_3d"-->
<!--            :button_state="true"-->
<!--            :button_color="colors.new_primary_purple"-->
<!--            :text_color="colors.white"-->
<!--            :button_color_not_ready="colors.eli_gray"-->
<!--            :button_not_ready_text_color="colors.pennie_gray"-->
<!--            button_height="90px"-->
<!--            button_width="90px"-->
<!--            border_rad_pct="50%"-->
<!--            text="Confirm"-->
<!--            text_size="17px"-->
<!--            :bold="true"-->
<!--            event_name="confirm_button_clicked"-->
<!--            @confirm_button_clicked="submit_confirmation_code"-->
<!--        ></basic_text_button>-->

      </div>
    </div>
    <div class="flex_centered_row  " id="links_row">
      <div class="flex_centered_row links cursor_ptr" @click='simple_route_prep("resend_registration_confirmation")'>Resend
        Sign Up Confirmation
      </div>
<!--      <div class="flex_centered_row links" @click='simple_route_prep("login")'>Login</div>-->

    </div>
  </section>
  <outside_footer id="footer" class="footer_c">
  </outside_footer>


</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {
  be_main_address,
  validate_confirmation_code,
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";
import {
  colors
} from "@/library";

export default {
  components: {
    notification_popup,
    basic_text_button,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label
  },
  data() {
    return {
      colors: colors,
      confirmation_code: '',
      next_route: '',
      error_out: false,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 700,
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  methods: {
    tester() {
      console.log("PAGE WAS CLICKED.")
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

    catch_confirm_code(confirm_code_in) {
      let confirmation_code = confirm_code_in.trim()
      // validate
      if (validate_confirmation_code(confirmation_code)) {
        this.confirmation_code = confirmation_code
      } else {
        let m = "The confirmation code you entered is not valid."
        this.error_out = true
        store.commit('error_enqueue', m)
      }
    },
    simple_route_prep(r) {
      // console.log("in simple route prep")
      this.hamburger_menu_false()
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async submit_confirmation_code() {
      // console.log("Getting ready to confirm new user...")
      // console.log(`Current password from state is: ${this.password}`)

      // let the_router = this.$router

      // this.$refs.confirmation_code_enter.emit_state()
      this.$refs.confirmation_code_enter_box.emit_state()
      if (this.error_out) {
        this.error_out = false
        return null
      }

      // let error_out
      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "confirm_user"
      await axios.post(target, {
        name: this.$store.getters.my_name,
        password: this.$store.getters.my_password,
        code: this.confirmation_code
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Something went wrong with your account confirmation attempt.")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // error_out = true
          return null
        }
      }).then((response) => {
            console.log("Got good response to confirmation.");
            console.log(response)
            if (response.data.error === false) {
              console.log("Putting notification on the queue");
              store.commit('notification_enqueue', response.data['message'])
              console.log("Going to the login page");
              this.$router.push('/login');
            } else {
              console.log("Something went wrong with the confirmation method.")
              console.log(response.data)
              store.commit('error_enqueue', response.data['message'])
            }
          }
      )

    },


  },
  computed: {
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>

<style scoped>
@import '../../common.css';

.form_unit {
  /*height: 30px;*/
}

/**/

.main_grid {
  display: grid;
  width: 100vw;
  height: 100vh;
  max-width: 700px;
  grid-template-rows: 1fr 2fr 3fr 3fr 3fr;
  grid-template-areas: "top"
                         "logo"
                         "form"
                         "links"
                         "bottom";
}

.main_column {
  max-width: 75%;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}



form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {


  .enter_your_code{
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }


  #links_row {
    width: 70vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
    color: var(--primary-purple);
  }

}

@media (min-width: 500px) {


  form label {
    align-self: center;
    margin-bottom: .75vh;
    font-size: 25px
  }

  form input {
    margin-bottom: 2.25vh;
    align-self: center;
    font-size: 18px
  }


  form button {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 1vh 0.1em 0.1em 0;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    /*color: var(--light-text);*/
    color: yellow;

    /*font-family:'Lobster', cursive;*/
    /*font-weight:300;*/
    /*color: var(--light-text);*/
    background-color: var(--new-primary-purple);
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    font-size: 20px;
  }


}

@media (min-width: 800px) {
 .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}


</style>