<template>
  <module_main_page
      module_download_name="22aec800-3839-4b15-8168-1266c9704df3"
      module_logo_name="sd_abuse.svg"
      :module_desc_text=module_text
      module_headline="Understanding Abuse"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "abuse",
  data() {
    return {
      module_text: "Caregiver abuse is a form of mistreatment that occurs when a caregiver intentionally harms or causes distress to the client they are responsible for. Abuse can take many forms, including physical, emotional, sexual, and financial abuse. It is essential for caregivers to provide the necessary care and support required to meet the needs of their clients and report any concerns or suspicions of abuse to the appropriate authorities.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



