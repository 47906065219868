<template>
  <section>
    <img class="padding_tb_20 ft_logo"
         src="@/assets/freetime_sunset.svg"
         alt="Freetime Feature Logo">
  </section>
</template>

<script>
export default {
  name: "freetime_admin_help"
}
</script>

<style scoped>

</style>