<template>
  <section class="flex_start_row main_banner z_300 p_fx" :id="main_banner_id">
    <div class="flex_start_row first flex_grow" :id="left_button_id" >
      <slot name="left_button"></slot>
    </div>
    <div class="flex_centered_row middle" :id="center_icon_id" >
      <slot name="icon"></slot>
    </div>
    <div class="flex_end_row right flex_grow" :id="right_button_id">
      <slot name="right_button">

      </slot>
    </div>
  </section>

</template>

<script>
import {
  colors
} from "@/library";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "basic_banner_new",
  props: [
    'banner_bg',
    'banner_width',
    'banner_height',
    'icon_width'

  ],
  data() {
    return {
      colors: colors,
      left_button_id: "",
      right_button_id: "",
      center_icon_id: "",
      main_banner_id: ""

    }
  },
  async mounted() {
    try {
      this.left_button_id = uuidv4();
      this.right_button_id = uuidv4();
      this.center_icon_id = uuidv4();
      this.main_banner_id = uuidv4();
      let banner = document.getElementById(this.main_banner_id)
      banner.style.background = this.banner_bg
      banner.style.width = this.banner_width
      banner.style.height = this.banner_height
    } catch (error) {
        // console.error("Expected premature loading error in banner component.")
    }

  },
  async updated() {
    try{
       await this.$nextTick(function () {
      let banner = document.getElementById(this.main_banner_id)
      let icon = document.getElementById(this.center_icon_id)
      banner.style.background = this.banner_bg
      banner.style.width = this.banner_width
      banner.style.height = this.banner_height
      icon.style.width = this.icon_width
    })
    }catch (error){
      console.error("Expected premature loading error in banner component.")
    }

  },
  methods: {
  },
}
</script>

<style scoped>

.main_banner {
  /*max-width: 100vw;*/
  max-width: 700px;
  box-shadow: 3px 3px 3px black;
  /*padding-bottom: 10px;*/
  top: 0;
}

.first {
  max-width: 50%;
}

.middle {

}

.right {
  max-width: 50%;
}


</style>