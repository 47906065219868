<template>
  <module_main_page
      module_download_name="a91648ec-32a1-4a53-9d80-95654b210abe"
      module_logo_name="sd_transfers.svg"
      :module_desc_text=module_text
      module_headline="Performing Safe Transfers"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "transfers_2",
  data() {
    return {
      module_text: "Safe transfer techniques are essential for caregivers when assisting individuals who require help with mobility.\n" +
          "        While not always needed, one critical aspect of transfer technique is the proper use of lifts, which are\n" +
          "        designed to assist with the transfer of individuals who cannot bear weight on their own. Proper body mechanics\n" +
          "        are essential whether using a lift or not to ensure the safety and well-being of both the caregiver and the\n" +
          "        individual being assisted.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>