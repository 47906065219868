<template>
  <div></div>
</template>

<script>
import store from "@/store";

export default {
  name: "headers",
  props: [
    'title',
    'meta_description',
    'og_title',
    'og_url',
    'og_description',
    'og_image',
    'twitter_title',
    'twitter_description',
    'twitter_image',
    'twitter_card',
  ],
  mounted() {
    // console.log('This title is...')
    // console.log(this.title)

    if (document.title !== this.title) {
      document.title = this.title
    } else if (this.title === "" | this.title === undefined) {
      document.title = store.state.main_title
    }

    if (this.meta_description === "" | this.meta_description === undefined) {
      document.querySelector('meta[name="description"]').setAttribute("content", store.state.main_meta_description);
    } else {
      document.querySelector('meta[name="description"]').setAttribute("content", this.meta_description);
    }

    if (this.og_image === "" | this.og_image === undefined) {
      document.querySelector('meta[property="og:image"]').setAttribute("content", store.state.og_main_image);
    } else {
      document.querySelector('meta[property="og:image"]').setAttribute("content", this.og_image);
    }

    if (this.og_description === "" | this.og_description === undefined) {
      document.querySelector('meta[property="og:description"]').setAttribute("content", store.state.og_main_meta_description);
    } else {
      document.querySelector('meta[property="og:description"]').setAttribute("content", this.og_description);
    }

    if (this.og_title === "" | this.og_title === undefined) {
      document.querySelector('meta[property="og:title"]').setAttribute("content", store.state.og_main_meta_title);
    } else {
      document.querySelector('meta[property="og:title"]').setAttribute("content", this.og_title);
    }

    if (this.og_url === "" | this.og_url === undefined) {
      document.querySelector('meta[property="og:url"]').setAttribute("content", store.state.og_main_meta_url);
    } else {
      document.querySelector('meta[property="og:title"]').setAttribute("content", this.og_url);
    }

    if (this.twitter_title === "" | this.twitter_title === undefined) {
      document.querySelector('meta[name="twitter:title"]').setAttribute("content", store.state.og_main_meta_title);
    } else {
      document.querySelector('meta[name="twitter:title"]').setAttribute("content", this.twitter_title);
    }

    if (this.twitter_description === "" | this.twitter_description === undefined) {
      document.querySelector('meta[name="twitter:description"]').setAttribute("content", store.state.og_main_meta_description);
    } else {
      document.querySelector('meta[name="twitter:description"]').setAttribute("content", this.twitter_description);
    }

    if (this.twitter_image === "" | this.twitter_image === undefined) {
      document.querySelector('meta[name="twitter:image"]').setAttribute("content", store.state.og_main_image);
    } else {
      document.querySelector('meta[name="twitter:image"]').setAttribute("content", this.twitter_image);
    }

    if (this.twitter_card === "" | this.twitter_card === undefined) {
      document.querySelector('meta[name="twitter:card"]').setAttribute("content", "summary_large_image");
    } else {
      document.querySelector('meta[name="twitter:card"]').setAttribute("content", "summary_large_image");
    }


  },
  unmounted() {
    document.title = 'Tiny Magic Hat'
  }
}
</script>

<style scoped>

</style>