<template>
  <div class="flex_centered_row">
    <!--            <img @click="emit_state_left_side_click(-1)" src="../../../public/img/images/lt_arrow.svg"-->
    <img @click="set_current_history_report(-1)" :src="require('@/assets/' + left_arrow_image + '')"
         alt="Left Arrow"
         class="ehr_arrows left_arrows cursor_ptr">

    <div class="history_current flex_centered_column">
      <h3 class="flex_centered_row   " :id="history_selection">{{
          current_selection
        }}
      </h3>
    </div>


    <img @click="set_current_history_report(1)" :src="require('@/assets/' + right_arrow_image + '')"
         alt="Right Arrow"
         class="ehr_arrows right_arrows cursor_ptr">
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {translate_date_string} from "@/library";

export default {
  name: "report_selector",
  props: ['left_arrow_image', 'right_arrow_image', 'label_list', 'bg_color'],
  async beforeMount() {
    // console.log("report selector before mount...")
    // console.log(`label list is ${this.label_list}`)
    this.history_selection = uuidv4();
    // let temp_labels = this.label_list
    // let s_labels = temp_labels.sort()
    // this.current_selection_x = s_labels[s_labels.length - 1]
    // console.log(`Current History selection is ${this.current_selection_x}`)

    try {

      await this.$nextTick(function () {
        // console.log("inside report selector next tick.")
        let history_selection = document.getElementById(this.history_selection)
        history_selection.style.background = this.bg_color
        let temp_labels = this.label_list
        let s_labels = temp_labels.sort()
        console.log(`Next tick labels_list is ${JSON.stringify(s_labels)}`)
        this.current_selection_x = s_labels[s_labels.length - 1]
        // console.log(`Next tick setting current selection to ${this.current_selection_x}`)
      })
    } catch (error) {
      // console.log(error)
    }
  },
  async updated() {
    // console.log("inside updated in report selector.")
    let temp_labels = this.label_list
    let count = this.label_list.length
    // console.log(`Current number is ${count}`)

    let s_labels = temp_labels.sort()
    // console.log(JSON.stringify(s_labels))
    // console.log("and the current selection x is...")
    // console.log(this.current_selection_x)
    // console.log(JSON.stringify(s_labels[s_labels.length - 1]))
    // console.log(`Current selection x is ${this.current_selection_x}`)
    // this.current_selection_x = s_labels[s_labels.length - 1]
    if (this.current_selection_x === undefined) {
      this.current_selection_x = s_labels[s_labels.length - 1]
    }
    if (count !== this.current_number) {
      this.current_number = count
      this.current_selection_x = s_labels[s_labels.length - 1]
    }


  },
  data() {
    return {
      current_selection_x: null,
      history_selection: "",
      current_number: 0
    }
  },
  computed: {
    current_selection() {
      return translate_date_string(this.current_selection_x, 1)
    },
  },
  methods: {

    set_to_most_current() {
      // console.log("set_to_most_current history report")
      let temp_labels = this.label_list
      let s_labels = temp_labels.sort()
      // console.log(`Next tick labels_list is ${JSON.stringify(s_labels)}`)
      this.current_selection_x = s_labels[s_labels.length - 1]
    },

    set_current_history_report(direction) {
      // console.log(`You clicked the arrow with ${direction}.`)
      let temp_labels = this.label_list
      let history_array = temp_labels.sort()

      // console.log("the history array is...")
      // console.log(JSON.stringify(history_array))

      for (let index = 0; index < history_array.length; ++index) {
        // console.log(`Iterating.  Index is ${index}, value is ${history_array[index]}, Current selection is ${this.current_selection_x}`)
        if (this.current_selection_x === history_array[index]) {
          // console.log("current selection equals the indexed value")
          if (index + direction < 0) {
            // console.log("You are falling off the left side of the array")
          } else if (index + direction > (history_array.length - 1)) {
            // console.log("You are falling off the right side of the array")
          } else {
            // console.log('you got a hit.')
            this.current_selection_x = history_array[index + direction]
            // console.log("just picked the current selection, which is...")
            // console.log(this.current_selection_x)
            this.$emit("selection_chosen", this.current_selection)
            break
          }
        } else {
          //pass
        }
      }
    },
  },
}

</script>

<style scoped>
h3 {
  font-family: 'Raleway', sans-serif;
  color: black;
}

.left_arrows {
  margin-right: 15px;
}

.right_arrows {
  margin-left: 15px;
}

.ehr_arrows {
  width: 1.8rem;
  margin-top: .4rem;
}

.history_current {
  padding-top: .1rem;

  margin-top: .2rem;
  width: 8rem;
  /*border-radius: 2rem;*/
  /*font-size: 1.2rem;*/
  /*font-weight: bold;*/
  /*font-family: 'Raleway', sans-serif;*/
  border: 2px solid var(--primary-purple);
}

/*@media (min-width: 100px) {*/
/*  .history_current {*/
/*    padding: 30px;*/
/*    width: 150px;*/
/*    height: 20px;*/
/*  }*/
/*}*/
@media (min-width: 100px) {
  h3{
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .history_current {
    padding: 30px;
    width: 230px;
    height: 20px;

  }
  .history_current h3{
    font-size: 30px;
  }
}
</style>