<template>

  <section>
    <div class="mod_card bg_pennie_gray flex_centered_column">
      <basic_icon_button
          ref="btn4"
          class="margin_lr_10 cursor_ptr"
          :ready="true"
          button_color="transparent"
          :button_color_not_ready="colors.pennie_gray"
          :button_height=module_button_size
          :button_width=module_button_size
          border_rad_pct='button_border_rad'
          :image_path=image_path
          :image_width=module_image_size
          alt_text="Module Card"
          event_name="mod_card_clicked"
          @mod_card_clicked="emit_state"
      ></basic_icon_button>
      <div class="mod_card_text_box font_raleway" >
        <p>{{image_text}}</p>
      </div>
    </div>
  </section>

</template>

<script>
import basic_icon_button from "@/components/parts/basic_icon_button";
import {
  colors,
  speedy_icon_data,
  // receive_over_socket, compare_timestamp, analytic
} from "@/library";
import {mapGetters} from "vuex";
export default {
  name: "module_card",
   props: [
    'image_path',
    'image_text',
  ],
  emits: ['mod_card_clicked', ],
  components: {
    basic_icon_button
  },
  data() {
    return {
      db: "",
      button_size_: "100px",
      button_img_size_: "80px",
      colors: colors,
      completed_modules: {},
      icon_data: speedy_icon_data,

    }
  },
  methods: {
    do_nothing() {
      // pass
    },
    emit_state() {
      this.$emit('mod_card_clicked', true)
    },
    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 500) {

        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },
    async simple_go_to_route(route) {
      await this.$router.push({path: '/' + route})
    },
  },
  watch: {

    // async update_page() {
    //   console.log("update_page watcher is triggered")
    //   await this.local_page_update()
    // },

    window_width(new_w) {
      console.log("Running Window width watch..")
      this.run_window_width_adjustments(new_w)
    },

  },
   computed: {
    ...mapGetters([
      'window_width',
    ]),


    module_button_size() {
      console.log("Running the module size computed property.")
      return this.button_size_
    },
    module_image_size() {
      return this.button_img_size_
    },
  },


}
</script>


<style scoped>
@media (min-width: 100px) {
   .mod_card{
     padding-top: 20px;
     padding-bottom: 20px;
     padding-left: 10px;
     padding-right: 10px;
     max-width: 200px;
     width: 180px;
     height: 150px;
     margin-left: 5px;
     margin-right: 5px;

   }

  .mod_card_text_box{
    max-width: 150px;
  }

  .mod_card_text_box p {
    font-size: 14px;
    text-wrap: normal;
    text-align: center;
    font-weight: bold;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {



}

@media (min-width: 1200px) {

}

@media (min-width: 2000px) {
}

</style>