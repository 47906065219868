<template>
  <section class="forum_widget">

    <div class="flex_centered_column">
      <div class="flex_centered_row text_align_center">
        <h2>{{ this.forum_name_str }}</h2>
      </div>

      <div class="flex_space_between_row next_and_back font_raleway font_weight_800">
        <p v-if="this.last_set > 1" class="text_primary_purple cursor_ptr" @click="regress_set"> &lt;&lt;&lt; Back</p>
        <p v-else class="text_pennie_gray">&lt;&lt;&lt; Back</p>
        <p class="">{{ this.last_set }}</p>

        <p v-if="this.available_sets - this.current_set > 0" class="text_primary_purple cursor_ptr"
           @click="advance_set">Next >>></p>
        <p v-else class="text_pennie_gray">Next >>></p>
      </div>

      <section class="flex_left_aligned_column all_threads">
        <div v-for="thread in this.thread_content" :key=thread.thread_id class=""
             id="threads_container">
          <comment_thread
              :title=thread.thread_title
              :list_of_posts=thread.posts
              :sticky=thread.sticky
              :thread_id=thread.thread_id
              :user_is_admin=this.user_is_admin
              :thread_timestamp=thread.timestamp
              @toggle_sticky="toggle_sticky(thread.thread_id)"
              @create_and_post_reply="create_and_post_reply"
              @remove_thread="remove_thread"
              @register_like="register_like"
              @hide_post="hide_post"
          ></comment_thread>

        </div>
      </section>

      <div class="flex_space_between_row next_and_back">
        <p v-if="this.last_set > 1" class="text_primary_purple cursor_ptr" @click="regress_set"> &lt;&lt;&lt; Back</p>
        <p v-else class="text_pennie_gray">&lt;&lt;&lt; Back</p>
        <p>{{ this.last_set }}</p>

        <p v-if="this.available_sets - this.current_set > 0" class="text_primary_purple cursor_ptr"
           @click="advance_set">Next >>></p>
        <p v-else class="text_pennie_gray">Next >>></p>
      </div>

      <new_conversation id="new_conversation"
                        @create_new_thread="create_new_thread"
      ></new_conversation>

    </div>
  </section>

</template>

<script>
/* eslint-disable */
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import new_conversation from "@/components/basics/new_conversation";
import comment_thread from "@/components/basics/comment_thread";
import store from "@/store";
import axios from "axios";
import {
  reentry_route, good_token_now, dual_route, scroll_to_element, num_is_between_inclusive,
  colors, scroll_to_top, be_main_address, prime_socket, receive_over_socket, compare_timestamp, main_forum_thread_count
} from "@/library";

export default {
  name: "forum_widget",
  async mounted() {
    await prime_socket(this.local_message_handler)
    // console.log("inside forum widget mounted...")
    if (this.current_set) {
      // console.log(`current set is a ${typeof this.current_set} equal to ${this.current_set}`)
      this.last_set_ = this.current_set
    }
    this.emit_set_request(this.last_set)
    let sec = 1000
    let min = sec * 60
    setInterval(function () {
      console.log("Running on a timer for each minute.")
    }, min);
    this.run_window_width_adjustments(this.windowWidth)
  },
  async updated() {
    // console.log(`In updated current_set starts as ${this.current_set} and is a ${typeof this.current_set}`)
    if (this.current_set) {
      // console.log(`In updated current_set and found current set at ${this.current_set} and is a ${typeof this.current_set}`)
      this.last_set_ = this.current_set
    }
    await main_forum_thread_count()
  },
  unmounted() {
    // remove_class_to_body('bg_white')
  },
  data() {
    return {
      colors: colors,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      // comment_set: 1,
      last_set_: 1,
      // available_sets: 8,
      // set_size: 10,
      // threads_collection: {},
      // posts_collection: {},
    }
  },
  props: [
    'forum_name',
    'threads',
    'posts',
    'available_sets',
    'current_set',
    'user_is_admin'
  ],
  components:
      {
        outside_footer,
        new_conversation,
        comment_thread,
        outside_banner_std
      },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    register_like(value) {
      console.log("emit register like from forum_widget")
      this.$emit('register_like', value)
    },
    hide_post(out) {
      console.log("hide post at widget.")
      this.$emit('hide_post', out)
    },
    async remove_thread(thread_id) {
      console.log(`Thread ID from form widget bubble up: ${thread_id}`)
      this.$emit('remove_thread', thread_id,)
    },
    async create_and_post_reply(data) {
      this.$emit('create_and_post_reply', data)
    },
    toggle_sticky(thread_id) {
      this.$emit('toggle_sticky', thread_id)
    },
    emit_set_request(set) {
      this.$emit('thread_set_request', set)
    },
    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      // if (update) {
      //   store.commit('increment_universal_page_update', true)
      // }
    },
    advance_set() {
      if (this.last_set_ + 1 <= this.available_sets) {
        // console.log(`Last set starts at ${this.last_set}`)
        // console.log(`Last set is ${typeof this.last_set}`)
        // let n = this.last_set
        // let x = n + 1
        this.last_set_ = this.last_set_ + 1
        // this.last_set = x
        // console.log(`Last set plus 1  is ${this.last_set + 1}`)
        // console.log(`Last set is now ${this.last_set}`)
        // console.log(`X is now ${x}`)
        this.emit_set_request(this.last_set)
        scroll_to_top()
        // let have_them = this.do_i_have_the_threads(this.comment_set)
        // if(!have_them){
        //   this.emit_set_request(this.comment_set)
        // }
      }
    },
    regress_set() {
      this.last_set_ = this.last_set - 1
      this.emit_set_request(this.last_set)
      scroll_to_top()
    },
    async create_new_thread(title, comment) {
      console.log(`Title: ${title}`)
      console.log(`Comment: ${comment}`)
      let p_data = {
        'post_text': comment,
      }
      //
      let t_data = {
        'title': title,
      }
      let target_url = be_main_address('forums') + 'create_thread_on_main'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        post_data: p_data,
        thread_data: t_data,
      }
      axios.post(target_url, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            if (error.response) {
              // // console.log("Something went wrong with your login.")
              // store.commit('error_enqueue', "Bad verification. Try again.")
              // console.log(error)
              // // error_out = true
              return null
            } else if (error.request) {
              // let online = window.navigator.onLine;
              // let message
              // if (online) {
              //   message = "There are problems with your network connection, or Tiny Magic Hat may be down."
              // } else {
              //   message = "You appear to have lost your network connection."
              // }
              // store.commit('error_enqueue', message)
              // // error_out = true
              return null
            } else {
              // console.log("in the error catch all")
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message);
              // error_out = true
              return null
            }
          }).then((response) => {
            console.log("Got back verification response of")
            console.log(response.data)
            if (response.data.error === false) {
              // console.log("Got good response to mfa verification.");
              store.commit('notification_enqueue', response.data['message'])
              // this.$router.push('/mfa_login');
              // this.$store.commit('save_name', this.name)
              // this.$store.commit('save_email', this.email)
              // this.$store.commit('save_phone', this.phone_number)
              // this.name = ''
              // this.phone_number = ''
              // this.password = ''
              // this.$router.push('/mfa_register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              // console.log(response.data)
              // console.log(JSON.stringify(response.data.payload))
              let error_message = JSON.parse(response.data.payload).content.message
              store.commit('notification_enqueue', error_message)
              // store.commit('notification_enqueue', response.data['message'])
              // console.log(JSON.parse(response.data.payload).content.message)
              // console.log(JSON.stringify(response.data.payload['message']))
            }
          }
      )
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    thread_title(threadid) {
      for (const thread of this.threads_list) {
        if (threadid === thread.thread_id) {
          return thread.title
        }
      }
    },
    find_parent_post(posts) {
      // console.log("Looking for parent post.e..")
      // console.log(`Number of posts: ${posts.length}`)
      for (const post of posts) {
        // console.log(`Post: ${JSON.stringify(post)}`)
        if (!post['parent_id'].length) {
          // console.log("This post has no parent...")
          return post
        }
      }
    },
    // compare_ts(a, b) {
    //   if (a.timestamp < b.timestamp) {
    //     return -1;
    //   }
    //   if (a.timestamp > b.timestamp) {
    //     return 1;
    //   }
    //   return 0;
    // },
    render_threads(threads_list, posts_lists) {
      console.log("")
      // Create a dict of threads, thread_id: thread object
      //  collect posts for each thread into groups
      let post_collection = {}

      console.log("Start the iteration of the threads list...")
      for (const t of threads_list) {
        console.log("->t ",JSON.stringify(t) );
        let out = []
        // Collect all the posts for this thread
        console.log("Start interation of posts...")
        for (const p of posts_lists) {
          console.log("-> p", JSON.stringify(p));
          if (p['thread_id'] === t['thread_id']) {
            out.push(p)
          }
        }
        post_collection[t['thread_id']] = out
      }
      // console.log(post_collection)
      // Group thread title with its associated posts
      let threads_and_posts = {}
      for (const [thread_id, posts] of Object.entries(post_collection)) {
        let parent = this.find_parent_post(posts)
        // console.log("in the iterator...... parent post is is")
        // console.log(JSON.stringify(parent))
        let rendered_posts = this.render_posts(parent['post_id'], posts)
        threads_and_posts[thread_id] = {
          'thread_id': thread_id,
          'posts': rendered_posts
        }
        // console.log("threads and posts")
        // console.log(threads_and_posts)
      }
      // Sticky threads are displayed before regular threads.
      // const threads_to_display = stickys_sorted.concat(regulars_sorted);
      // console.log(`THREADS TO DISPLAY JUST CREATED- Type:${typeof threads_to_display}`)
      // console.log(threads_to_display)
      // console.log(`THREADS and Posts- Type:${typeof threads_and_posts}`)
      // console.log(threads_and_posts)
      let new_out = []
      for (const t of threads_list) {
        let o = {}
        o['thread_title'] = t['thread_title']
        o['thread_id'] = t['thread_id']
        o['thread_creator'] = t['creator']
        o['sticky'] = t['sticky']
        o['timestamp'] = t['timestamp']
        o['posts'] = threads_and_posts[t['thread_id']]['posts']
        new_out.push(o)
      }
      return new_out
    },
    render_posts(post_id_of_parent, the_posts, the_out = null, trips = 0) {
      //post_id_of_parent: the id of the first post in a series
      //the_posts: the entire collection of posts in a thread
      //the_out: a running list of posts generated to be returned
      trips = trips + 1
      //console.log(`Trips: ${trips}`)
      let parent_found = false
      let parent = null
      // Find the parent post object
      for (const apost of the_posts) {
        if (apost['post_id'] === post_id_of_parent) {
          // We found parent
          //console.log("we found a parent")
          parent_found = true
          parent = apost
          if (!the_out) {
            the_out = [parent]
          }
          break
        }
      }
      if (!parent_found) {
        console.log("The indicated parent post does not exist.")
        return null
      }
      // Find all direct children of the parent and sort by timestamp sting
      let children = []
      for (const apost of the_posts) {
        if (apost['parent_id'] === parent['post_id']) {
          // We have found a child of the parent.
          children.push(apost)
        }
      }
      children.sort(compare_timestamp);
      let children_sorted = children
      //console.log(`Parent: ${parent['post_id']}`)
      //console.log(`Children: ${children_sorted}`)
      for (const child of children_sorted) {
        the_out.push(child)
        //Then the cycle repeats looking for the new parents children
        the_out = this.render_posts(child['post_id'], the_posts, the_out, trips)
      }
      return the_out
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (num_is_between_inclusive(100, 300, new_w)) {
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
    },
  },
  computed: {
    last_set() {
      // console.log(`in last set computed prop with ${this.last_set_} as a  ${typeof this.last_set_}`)
      return this.last_set_
    },
    threads_list() {
      // let t = [this.thread1, this.thread2, this.thread3].sort(this.compare_ts);
      let t = this.threads.sort(compare_timestamp);
      console.log(t)
      return t
      // return [this.thread1, this.thread2, this.thread3]
    },
    thread_content() {
      console.log("Getting ready to render the thread contents")
      let t = this.threads
      let p = this.posts
      // console.log(JSON.stringify(this.threads))
      console.log(JSON.stringify(this.posts))
      if (t && p) {
        return this.render_threads(t, p)
      } else {
        // console.log("No threads and posts to render")
      }
    },
    windowWidth() {
      return store.state.windowWidth;
    }
    ,
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    forum_name_str() {
      if (this.forum_name === "main_forum") {
        return "Main Forum"
      } else if (this.forum_name) {
        return this.forum_name
      } else {
        return "Main Forum"
      }
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    }
    ,
  }
  ,
}
</script>

<style scoped>
@media (min-width: 100px) {
  .all_threads {
    width: 100vw;
    max-width: 1000px;
  }

  .next_and_back {
    width: 90vw;
    max-width: 500px;
  }

  .next_and_back p {
    font-size: 16px;
  }

  .forum_widget {
    margin-top: 40px;
  }

  #threads_container {
    margin-left: 10px;
    margin-top: 20px;
  }

  #new_conversation {
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 400px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
  }
}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }
}

@media (min-width: 1200px) {
  .next_and_back {
    width: 500px;
    margin-top: 100px;
  }

  #new_conversation {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #threads_container {
    width: 100vw;
    max-width: 1000px;
  }

  .all_threads {
    width: 100vw;
    max-width: 1000px;
  }
}

@media (min-width: 2000px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 30px;
    text-decoration: none;
  }
}
</style>