<template>
  <module_main_page
      module_download_name="26c0534f-9cdb-4ca1-8c66-ca80be52b78c"
      module_logo_name="sd_infection_control.svg"
      :module_desc_text=module_text
      module_headline="Infection Control In Home Care"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "infection_control",
  data() {
    return {
      module_text: "Infection control helps to prevent the spread of infectious diseases and promote the health and well-being of both the caregiver and the client. Homecare clients may be more vulnerable to infections due to underlying medical conditions or weakened immune systems, and it is the responsibility of the caregiver to take all necessary precautions to prevent the spread of infection. This includes practices such as regular hand hygiene, wearing personal protective equipment (PPE) when necessary, and proper disposal of medical waste. ",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>

