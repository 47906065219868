<template>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_cyanic ">
    <div class="flex_centered_column ">
      <img class="tmh_color_gradient padding_tb_30"
           src="@/assets/geography.svg"
           alt="TMH Logo">
      <div class="font_raleway font_weight_800 margin_b_20 text_25">Geography Services</div>
    </div>

  </div>

  <div class=" margin_tb_20 flex_centered_column ">

    <geography_creator
        label="Create a Neighborhood"
        ref="neighborhood_create"
        button_text="Create"
        :button_state_start="false"
        :country_listing=countries_list
        :state_listing=states_list
        :market_listing=markets_list
        placeholder1="Neighborhood Name"
        toggle_true_message="Validating the neighborhood name you entered."
        purpose="neighborhood"
        country_selection_event="country_selection_event"
        @country_selection_event="remember_country"
        state_selection_event="state_selection_event"
        @state_selection_event="remember_state"
        market_selection_event="market_selection_event"
        @market_selection_event="remember_market"
        precheck_event_name="precheck_create_neighborhood"
        @precheck_create_neighborhood="create_neighborhood_button_status_true"
        event_name="create_state"
        @create_state="create_neighborhood"
    ></geography_creator>

    <geography_creator
        label="Create a Market"
        ref="market_create"
        button_text="Create"
        :button_state_start="false"
        :country_listing=countries_list
        :state_listing=states_list
        placeholder1="Market Name"
        toggle_true_message="Validating the market name you entered."
        purpose="market"
        country_selection_event="country_selection_event"
        @country_selection_event="remember_country"
        state_selection_event="state_selection_event"
        @state_selection_event="remember_state"
        precheck_event_name="precheck_create_market"
        @precheck_create_market="create_market_button_status_true"
        event_name="create_state"
        @create_state="create_market"
    ></geography_creator>


    <geography_creator
        label="Create a State"
        ref="state_create"
        :country_listing=countries_list
        button_text="Create"
        :button_state_start="false"
        placeholder1="State Name"
        toggle_true_message="Validating the state name you entered."
        purpose="state"
        country_selection_event="country_selection_event"
        @country_selection_event="remember_country"
        precheck_event_name="precheck_create_state"
        @precheck_create_state="create_state_button_status_true"
        event_name="create_state"
        @create_state="create_state"
    ></geography_creator>

    <geography_creator
        label="Create a Country"
        ref="country_create"
        button_text="Create"
        :button_state_start="false"
        placeholder1="Country Name"
        toggle_true_message="Validating the country name you entered."
        purpose="country"
        event_name="create_country"
        precheck_event_name="precheck_create_country"
        @precheck_create_country="create_country_button_status_true"
        @create_country="create_country"
    ></geography_creator>

  </div>
</template>
<script>
/* eslint-disable */
import geography_creator from "@/components/orgs/tmh_admin/admin_local_components/geography_creator";
import {be_main_address, parse_internal_json, validate_place_name} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "tmh_geography_controls",
  components: {geography_creator},
  mounted() {
    this.get_countries()
    // this.get_states()
    // this.get_markets()
    // console.log("here are the markets...")
    // console.log(this.markets_list)
  },
  data() {
    return {
      create_country_button_status_: false,
      countries_list_: [],
      states_list_: [],
      markets_list_: [],
      neighborhood_list_: [],
      place: {
        country: "",
        state: "",
        market: "",
        neighborhood: "",
      },
    }
  },
  computed: {
    remembered_country() {
      return this.place.country
    },
    remembered_state() {
      return this.place.state
    },
    remembered_market() {
      return this.place.market
    },
    countries_list() {
      return this.countries_list_
    },
    states_list() {
      return this.states_list_
    },
    markets_list() {
      return this.markets_list_
    },
    // create_country_button_status(){
    //   console.log(`in computed create_country_button_status ${this.create_country_button_status_}`)
    //
    //   return this.create_country_button_status_
    // },
  },
  methods: {
    forget_place() {
      this.place.neighborhood = ""
      this.place.market = ""
      this.place.state = ""
      this.place.country = ""
    },
    refresh_country_list(){
      this.countries_list_ = this.get_countries()
    },
    reset_all_lists(){
      //except for the country list
      this.neighborhood_list_ = []
      this.markets_list_ = []
      this.states_list_ = []
    },
    remember_country(state) {
      console.log("inside remember country")
      console.log(state)
      this.place.country = state
      this.states_list_ = this.get_states(this.place.country)
    },
    remember_state(state) {
      console.log("inside remember state")
      console.log(state)
      this.place.state = state
      this.markets_list_ = this.get_markets(this.place.state)
    },

    remember_market(state) {
      console.log("inside remember market")
      console.log(state)
      this.place.market = state
      this.neighborhood_list_ = this.get_neighborhoods(this.place.market)
    },
    async get_countries() {
      let target_url = be_main_address('geography') + 'country_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.countries_list_ = response.data.data.sort()
                console.log("Here is the list of countries")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    async get_states(filter) {
      let target_url = be_main_address('geography') + 'state_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.states_list_ = response.data.data.sort()
                console.log("Here is the list of states")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    async get_markets(filter){
      let target_url = be_main_address('geography') + 'market_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.markets_list_ = response.data.data.sort()
                console.log("Here is the list of markets")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async get_neighborhoods(filter){
      let target_url = be_main_address('geography') + 'neighborhood_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.neighborhood_list_ = response.data.data.sort()
                console.log("Here is the list of markets")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async create_country(state) {
      let target_url = be_main_address('geography') + 'create_country'
        if(!state){
        return null
      }
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        country: state
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('notification_enqueue', `${state} has been created.`)
                this.$refs.country_create.reset()
                this.forget_place()
                this.reset_all_lists()
                this.refresh_country_list()
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    async create_state(state) {
      let target_url = be_main_address('geography') + 'create_state'

      console.log("in create state")
      console.log(state)
       if(!state){
        return null
      }

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        country: this.remembered_country,
        state: state
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('notification_enqueue', `${state} has been created.`)
                this.$refs.state_create.reset()
                this.forget_place()
                this.reset_all_lists()
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async create_market(state) {
      let target_url = be_main_address('geography') + 'create_market'

      console.log("in create market")
      console.log(state)
       if(!state){
        return null
      }

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        country: this.remembered_country,
        state: this.remembered_state,
        market: state
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('notification_enqueue', `${state} has been created.`)
                this.$refs.market_create.reset()
                this.forget_place()
                this.reset_all_lists()
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async create_neighborhood(state) {
      let target_url = be_main_address('geography') + 'create_neighborhood'

      console.log("in create neighborhood")
      console.log(state)
       if(!state){
        return null
      }

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        country: this.remembered_country,
        state: this.remembered_state,
        market: this.remembered_market,
        neighborhood: state
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('notification_enqueue', `${state} has been created.`)
                this.$refs.neighborhood_create.reset()
                this.forget_place()
                this.reset_all_lists()
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    create_neighborhood_button_status_true(state) {
      console.log(`Setting the launch button status to true: state is ${state}`)
      if (!validate_place_name(state)) {
        let message = `${state} is not a valid name.`
        store.commit('error_enqueue', message)
        this.$refs.neighborhood_create.reset()

      } else {
        this.$refs.neighborhood_create.button_state_to_true();
        // this.create_market_button_status_ = true
      }
    },

    create_market_button_status_true(state) {
      console.log(`Setting the launch button status to true: state is ${state}`)
      if (!validate_place_name(state)) {
        let message = `${state} is not a valid name.`
        store.commit('error_enqueue', message)
        this.$refs.market_create.reset()

      } else {
        this.$refs.market_create.button_state_to_true();
        // this.create_market_button_status_ = true
      }
    },

    create_state_button_status_true(state) {
      console.log(`Inside STATE BUTTON STATUS TO TRUE: ${state}`)
      console.log(`Setting the launch button status to true: state is ${state}`)
      if (!validate_place_name(state)) {
        let message = `${state} is not a valid name.`
        store.commit('error_enqueue', message)
        this.$refs.state_create.reset()

      } else {
        this.$refs.state_create.button_state_to_true();
        this.create_state_button_status_ = true
      }
    },

    create_country_button_status_true(state) {
      console.log(`Setting the launch button status to true: state is ${state}`)
      if (!validate_place_name(state)) {
        let message = `${state} is not a valid name.`
        store.commit('error_enqueue', message)
        this.$refs.country_create.reset()

      } else {
        this.$refs.country_create.button_state_to_true();
        this.create_country_button_status_ = true
      }
    },


  },
}
</script>

<style scoped>

</style>