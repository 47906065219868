<template>
  <div class="main_display">
    <top_label_value_non_editable
        class="margin_r_10 margin_auto_lr"
        id="top_left"
        :label=label1
        :label_color=label_color1
        :label_size=label_size1
        :value=value1
        :bg_color=bg_color1
        :value_color=value_color1
        :value_bg_color=value_bg_color1
        :value_font_size=value_size1
    ></top_label_value_non_editable>
    <top_label_value_non_editable
        class="margin_l_10 margin_auto_lr"
        id="top_right"
        :label="label2"
        :label_color=label_color2
        :label_size=label_size2
        :value=value2
        :bg_color=bg_color2
        :value_color=value_color2
        :value_bg_color=value_bg_color2
        :value_font_size=value_size2
    ></top_label_value_non_editable>
    <top_label_value_non_editable
        class="margin_r_10 margin_auto_lr"
        id="bottom_left"
        :label="label3"
        :label_color=label_color3
        :label_size=label_size3
        :value=value3
        :bg_color=bg_color3
        :value_color=value_color3
        :value_bg_color=value_bg_color3
        :value_font_size=value_size3
    ></top_label_value_non_editable>
    <top_label_value_non_editable
        class="margin_l_10 margin_auto_lr"
        :label=label4
        id="bottom_right"
        :label_color=label_color4
        :label_size=label_size4
        :value=value4
        :bg_color=bg_color4
        :value_color=value_color4
        :value_bg_color=value_bg_color4
        :value_font_size=value_size4
    ></top_label_value_non_editable>
    <top_label_value_non_editable
        :v-if="label5"
        class="margin_l_10 margin_auto_lr"
        :label=label5
        id="left_very_bottom"
        :label_color=label_color5
        :label_size=label_size5
        :value=value5
        :bg_color=bg_color5
        :value_color=value_color5
        :value_bg_color=value_bg_color5
        :value_font_size=value_size5
    ></top_label_value_non_editable>
    <top_label_value_non_editable
        :v-if="label6"
        class="margin_l_10 margin_auto_lr"
        :label=label6
        id="right_very_bottom"
        :label_color=label_color6
        :label_size=label_size6
        :value=value6
        :bg_color=bg_color6
        :value_color=value_color6
        :value_bg_color=value_bg_color6
        :value_font_size=value_size6
    ></top_label_value_non_editable>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-unused-vars */
import {colors} from "@/library";
import top_label_value_non_editable from "@/components/parts/top_label_value_non_editable";

export default {
  name: "caregiver_main_display",
  components: {
    top_label_value_non_editable
  },
  props: [
    'label1', 'label_color1', 'label_size1', 'value1', 'bg_color1', 'value_color1', 'value_size1', 'value_bg_color1',
    'label2', 'label_color2', 'label_size2', 'value2', 'bg_color2', 'value_color2', 'value_size2', 'value_bg_color2',
    'label3', 'label_color3', 'label_size3', 'value3', 'bg_color3', 'value_color3', 'value_size3', 'value_bg_color3',
    'label4', 'label_color4', 'label_size4', 'value4', 'bg_color4', 'value_color4', 'value_size4', 'value_bg_color4',
    'label5', 'label_color5', 'label_size5', 'value5', 'bg_color5', 'value_color5', 'value_size5', 'value_bg_color5',
    'label6', 'label_color6', 'label_size6', 'value6', 'bg_color6', 'value_color6', 'value_size6', 'value_bg_color6',
  ],
  data() {
    return {
      colors: colors
    }
  },

}
</script>

<style scoped>

.main_display {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "left_top    right_top"
                       "left_bottom right_bottom"
                       "left_very_bottom right_very_bottom";
}

#top_left {
  grid-area: left_top;

}

#top_right {
  grid-area: right_top;
}

#bottom_left {
  grid-area: left_bottom;
}

#bottom_right {
  grid-area: right_bottom;
}

#left_very_bottom {
  grid-area: left_very_bottom;
}

#right_very_bottom {
  grid-area: right_very_bottom;
}

</style>