<template>
  <div v-if="show_people">
    <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column">
      <div class="flex_space_between_row wid_100 flex">
        <div class="spacer"></div>
        <h3 class="  ">People</h3>
        <div class="flex_centered_column" @click="toggle_show_people">
          <div>
            <img class="check_box_icon cursor_ptr"
                 src="@/assets/check-mark.svg"
                 alt="check box image">
          </div>
        </div>
      </div>
      <div class="flex_centered_row wide_button" v-for="item in my_people" :key="item.message">
        <div class="context_item  cursor_ptr margin_t_15"
             @click="get_new_route('people', item)">{{ translate_tmh_to_pname_local(item) }}
        </div>
      </div>

    </div>
  </div>
  <div v-if="!show_people">
    <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column">
      <div class="flex_space_between_row wid_100 flex">
        <div class="spacer"></div>
        <h3 class="  ">People</h3>
        <div class="flex_centered_column" @click="toggle_show_people">
          <div v-if="!show_people" class="check_box shadow_inner cursor_ptr"></div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import {
  analytic,
  create_tmh_user_to_pname_map,
  get_new_url,
  sorted_usernames_last_first,
  translate_tmh_to_pname
} from "@/library";
import {Database} from "@/client_db";
import store from "@/store";

export default {
  name: "people",

  data() {
    return {
      db: null,
      my_people_: [],
      show_people_: false,
      hamburger_menu_up_x: false,
    }
  },
  async mounted() {

    this.db = new Database();
    this.my_people_ = await this.get_people(this.db)
    localStorage.setItem("my_people", JSON.stringify(this.my_people_))
    analytic({
                event: "PEOPLE MOUNTED GOT...",
                message: "Here are the people.",
                data: JSON.stringify(this.my_people_)
            })
    let the_map = store.getters.get_pname_to_tmhname_map
    if (!the_map) {
      let the_map = await create_tmh_user_to_pname_map(this.db)
      console.log("MAP is....")
      console.log(the_map)
      store.commit('set_pname_tmhname_map', the_map)
    }
  },
  methods: {
    async get_people(db) {
      return await sorted_usernames_last_first(db)
    },
    toggle_show_people() {
      this.show_people_ = !this.show_people_
    },
    async get_new_route(envelope, context) {
      const preamble = [envelope, context]
      await store.dispatch('stack_proposed', preamble)
      let new_url = await get_new_url(this.db)
      this.hamburger_menu_false()
      await this.$router.push({path: new_url})
    },
    translate_tmh_to_pname_local(tmh_name) {
      return translate_tmh_to_pname(tmh_name)
    },

    toggle_hamburger_menu() {
      // console.log(`In toggle hamburger menu. Before toggle Hamburger menu is ${this.hamburger_menu_up}.`)
      this.hamburger_menu_up_x = !this.hamburger_menu_up_x
      // console.log(`After toggle Hamburger menu is ${this.hamburger_menu_up}.`)
    },
    hamburger_menu_false() {
      // console.log(`In toggle hamburger menu. Before toggle Hamburger menu is ${this.hamburger_menu_up}.`)
      this.hamburger_menu_up_x = false
      // console.log(`After toggle Hamburger menu is ${this.hamburger_menu_up}.`)
    },
  },
  computed: {
    my_people() {
      return this.my_people_
    },
    show_people() {
      return this.show_people_
    },
  },
}
</script>


<style scoped>
/*@import '../../common.css';*/
@media (min-width: 100px) {

  .wide_button {
    width: 85%;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
    margin-right: 20px;

  }

  .check_box_icon {
    height: 30px;
    margin-right: 20px;
  }


  .spacer {
    position: relative;
    top: 30px;
    width: 60px;
  }


}
</style>