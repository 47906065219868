
<template>
  <module_main_page
      module_download_name="f86b3ced-c2e1-4287-b0a0-bbc407339f58"
      module_logo_name="sd_alzheimers.svg"
      :module_desc_text=module_text
      module_headline="Understanding Alzheimer's Disease"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "alzheimers",
  data() {
    return {
      module_text: "Alzheimer's disease is a progressive and irreversible brain disorder that affects memory, thinking, and behavior. As the disease progresses, it can significantly impact a client's ability to function independently and carry out daily activities. Clients with Alzheimer's disease require specialized care that is tailored to their specific needs. The caregiver must have a thorough understanding of the disease, including its symptoms and stages, and be trained in techniques for managing challenging behaviors such as agitation, confusion, and wandering. Providing care for a client with Alzheimer's disease requires patience, compassion, and a commitment to maintaining their quality of life despite the challenges posed by the disease.",
    }
  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>
