<template>
  <div :id=id class="main_box font_weight_800 text_align_center" >
    {{ processed_value }}
    <!--    $153.12-->
  </div>
</template>

<script>

import {get_uuid, round} from "@/library";

export default {
  name: "data_box",
  props: ['value', 'prefix', 'suffix', 'round_digits', 'bg_color'],
  async mounted() {
    this.id_ = get_uuid()

    try {
      await this.$nextTick(function () {
        // console.log("in box")
        // console.log(`value is ${this.value}`)
        // console.log(`value is ${this.value.toString()}`)

        this.value_ = this.value
        if (this.round_digits) {
          this.value_ = round(this.value, this.round_digits)
          if(isNaN(this.value_)){
               console.log('Turns out the result is NaN...................')
               this.value_ = this.value
          }
          // this.value_ = round(this.value, this.round_digits)
        }
        if (this.text_color) {
          let box = document.getElementById(this.id)
          box.style.color = this.text_color
        }
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
        if (this.prefix) {
          this.value_ = this.prefix + this.value_
        }
        if (this.suffix) {
          this.value_ = this.value_ + this.suffix
        }
        // console.log(`The final processed value is ${this.value_}`)
        this.processed_value_ = this.value_
      })
    } catch (error) {
      console.log(error)
    }


  },
  async updated(){
     try {
      await this.$nextTick(function () {
        // console.log("in box")
        // console.log(`value is ${this.value}`)

        this.value_ = this.value
        if (this.round_digits) {
          this.value_ = round(this.value, this.round_digits)
          if(isNaN(this.value_)){
               // console.log('Turns out the result is NaN...................')
               this.value_ = this.value
          }
          // this.value_ = round(this.value, this.round_digits)
        }

        if (this.text_color) {
          let box = document.getElementById(this.id)
          box.style.color = this.text_color
        }
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
        if (this.prefix) {
          this.value_ = this.prefix + this.value_
        }
        if (this.suffix) {
          this.value_ = this.value_ + this.suffix
        }

        this.processed_value_ = this.value_
      })
    } catch (error) {
      console.log(error)
    }
  },
  data() {
    return {
      id_: "",
      processed_value_: "",
      value_: "",
    }
  },
  methods: {},
  computed: {
    id() {
      return this.id_
    },
    processed_value() {
      return this.processed_value_
    },
  }
}
</script>

<style scoped>

@media (min-width: 100px) {
  .main_box {
    font-family: Raleway, sans-serif;
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}



@media (min-width: 2000px) {
  .main_box {
    padding: 15px 30px;
    font-size: 18px;
  }

}


</style>