<template>
  <div class="flex_centered_column " id="mainframe">
<!--    <div @click="$refs.image_to_upload.click()" class=" flex_fully_centered_column cursor_ptr" >-->
    <div @click="main_click_wrapper" class=" flex_fully_centered_column cursor_ptr" >

      <div v-if="!image_is_saved">
         {{ frame_text }}
      </div>

      <input type="file" style="display: none" ref="image_to_upload"
             @change="onImageFileChosen($event)">

      <div id="original_image"></div>

      <img v-show="image_is_saved" id="resizedImage" :src="the_image">
    </div>

  </div>


</template>

<script>
/* eslint-disable */
import store from "@/store";
// import basic_icon_button from "@/components/parts/basic_icon_button";
import {
  colors
} from "@/library";
export default {
  name: "image_upload_widgit_general",
  props: ['event_name', 'image_value', 'frame_text', 'image_width', 'image_height', 'owners_perm'
  ],
  components: {
    // basic_icon_button
  },
  async mounted(){
   try {
      await this.$nextTick(function () {
        if(this.image_value){
          // let org = store.getters.org_context
          // store.commit('set_agency_logo', {'agency': org, 'image': this.image_value})
        }
        // let frame = document.getElementById("mainframe")
        // frame.style.width = this.image_width * 1.1
        // frame.style.height = this.image_height * 1.1
        this.set_the_image()
      })
    } catch (error) {
      //pass
      // console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // console.log("in updated next tick for logo widget")
        // console.log(`Owners perm is ${this.owners_perm}`)
        if(this.image_value){
          // let org = store.getters.org_context
          // store.commit('set_agency_logo', {'agency': org, 'image': this.image_value})
        }
        // let frame = document.getElementById("mainframe")
        // frame.style.width = this.image_width * 1.3
        // frame.style.height = this.image_height * 1.3
        this.set_the_image()
      })
    } catch (error) {
      //pass
      // console.log(error)
    }
  },
  data() {
    return {
      colors: colors,
      image_saved_: false,
      the_image: this.get_current_logo_image(),

    }
  },
  methods: {
    do_nothing() {
      //pass
    },
    main_click_wrapper(){
      // console.log("you clicked it")
      // this.$refs.image_to_upload.value = ''
      if(this.owners_perm){
        this.$refs.image_to_upload.click()
      }else{
        console.log("no owners perm")
        return null
      }
    },
    set_the_image() {
      console.log("inside set the image")
      // let img = this.get_current_logo_image()
      let img = null
      if (img) {
        // console.log(`Image value NOT nothing`)
        let still = document.getElementById("resizedImage");
        still.src = img
        still.style.display = "block"
        this.image_saved_ = true
      }
    },
    get_current_logo_image() {
      let logos = store.getters.get_agency_logos
      let org = store.getters.org_context
      try {
        return logos[org]
      } catch (e) {
        return false
      }
    },
    test_func(something, more) {
      console.log("button pressed")
      console.log(something)
      console.log(more)
    },
    resizeImage(base64Str) {
      console.log("starting resizeImage")
      try {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = this.image_width; // target width
        canvas.height = this.image_height; // target height

        // canvas.width = 260; // target width
        // canvas.height = 145; // target height
        let image = new Image();
        // document.getElementById("original_image").appendChild(image);
        image.onload = function () {
          ctx.drawImage(image,
              0, 0, image.width, image.height,
              0, 0, canvas.width, canvas.height
          );
          // create a new base64 encoding
          // let resampledImage = new Image();
          let resampledImage = document.getElementById("resizedImage")
          let org = store.getters.org_context
          // console.log(`The org is ${org}`)
          resampledImage.src = canvas.toDataURL();
          store.commit('set_agency_logo', {'agency': org, 'image': resampledImage.src})
          console.log("Setting new logo available to true.")
          store.state.new_logo_available = true
          this.image_saved_ = true
        }
        console.log("gettintg ready to load the image")
        image.src = base64Str
        // console.log("resizeImage is done")
        // console.log(image.src)
        // return
      } catch (error) {
        // console.log("in the error catch")
        // console.log(error)
      }
    },
    fileToDataUri(field) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(field);
      });
    },
    async onImageFileChosen(event) {
      console.log("in onImageFileChosen")
      const files = event.target.files
      this.the_file = files[0]
      let img_file_name = this.the_file['name']
      const myArray = img_file_name.split(".");
      let file_ext = myArray[1];
      let exts = ["jpeg", "gif", "png", "svg", "bmp", "jpg", 'pdf']
      if (exts.includes(file_ext)) {
        // console.log("ok file")
      } else {
        let m = `Image file must be of one of the following types:
        "jpeg", "gif", "png", "svg", "bmp", "jpg", "pdf". Your file was a "${file_ext}".`
        store.commit('error_enqueue', m)
        // console.log("NOT OK")
        return null
      }
      // console.log("start fileToDataUri")
      // let orig_src = await this.fileToDataUri(this.the_file)
      this.fileToDataUri(this.the_file).then(orig_src => {
        console.log("orig scr from filetoDataurs is")
        console.log(orig_src)
        // console.log("start resizeImage.  ")

        this.resizeImage(orig_src)
        // console.log("Back from resizeImage")
        this.$refs.image_to_upload.value = ''
        // console.log("about to call emit state")
        // this.emit_state()
      })
    },
    emit_state() {
      // console.log("Image widget emitting state of TRue")
      this.$emit(this.event_name, true)
    },
  },
  computed: {
    logo_saved(){
      return store.state.new_logo_available
    },
    controls_active() {
      console.log(`controls are active: ${this.controls_active_}`)
      return this.controls_active_
    },
    image_is_saved() {
      return this.image_saved_
    },
  },
}
</script>

<style scoped>
.logo_frame {
  width: 260px;
  height: 145px;
  background: var(--pennie_gray);
}
</style>