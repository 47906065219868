<template>
  <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_lauren_gray flex_centered_column profile_box">
    <div class="flex_centered_row">
      <h3 v-if="personal_gear_available" >Your Profile</h3>
      <h3 v-else >User Info</h3>

      <div class="flex_centered_column">
<!--         <span v-if="personal_gear_available" @click="non_contextual_route('info')" class="material-symbols-outlined material_icon cursor_ptr">edit_square</span>-->
          <img class="material_icon" v-if="personal_gear_available" @click="non_contextual_route('info')"
                         :src="image_name('edit_square.svg')"
                         alt="Edit icon.">
      </div>

    </div>

    <div v-if="full_name" class="non_clickable_context_item">{{ full_name }}</div>
    <div v-else class="non_clickable_context_item">Nothing Provided</div>

    <div v-if="full_street_address" class="non_clickable_context_item">
      {{ full_street_address }}
    </div>
    <div v-else class="non_clickable_context_item">Nothing Provided</div>

    <div v-if="city_state_zip" class="non_clickable_context_item">
      {{ city_state_zip }}
    </div>
    <div v-else class="non_clickable_context_item">Nothing Provided</div>

    <div v-if="phone_number" class="non_clickable_context_item">{{formatPhoneNumber}}
    </div>
    <div v-else class="non_clickable_context_item">Nothing Provided</div>

    <div v-if="email" class="non_clickable_context_item">{{ email }}</div>
    <div v-else class="non_clickable_context_item">Nothing Provided</div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {colors, image_name} from "@/library";
import store from "@/store";


export default {
  name: "basic_personal_info_box",
  props: ['full_name', 'full_street_address', 'phone_number', 'email', 'city_state_zip', 'personal_gear_available'],

  async mounted() {
    this.id = uuidv4();
  },
  data() {
    return {
      colors: colors,
      id: "",
    }
  },
  methods: {
    image_name(name){
      return image_name(name)
    },
    async non_contextual_route(route = null) {
      // this.hamburger_menu_false()
      // console.log("Current Context")
      // console.log("")
      const preamble = ['people', localStorage.getItem('username')]
      // this.next_route = `/${this.name}/profile`
      await store.dispatch('stack_proposed', preamble)


      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
  },
  computed: {
    formatPhoneNumber() {
      let phoneNumberString = this.phone_number
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    }
  },

}
</script>

<style scoped>
h3{
  color: var(--wyatt_gray);
}



@media (min-width: 100px) {
  .profile_box{
    /*margin-bottom: 120px;*/
    padding-bottom: 50px;
  }
  .material_icon{
     margin-left: 10px;
    height: 30px;
  }
}


@media (min-width: 1200px) {
  .profile_box{
    margin-bottom: 20px;
    padding-bottom: 50px;
  }

    .material_icon{
     margin-left: 10px;
      height: 30px;
     margin-bottom: 10px;
  }

}

</style>