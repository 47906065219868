<template>

  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white ">
    <div class="flex_centered_column ">
      <img class="tmh_color_gradient padding_tb_30"
           src="@/assets/rabbit_2_line.svg"
           alt="TMH Logo">
      <!--      <div class="label_regular_plus text_black padding_b_20">-->
      <!--        Tiny Magic Hat-->
      <!--      </div>-->
    </div>
  </div>

  <div class="screen_block bg_lauren_gray margin_tb_10 padding_tb_30">
    <h3 class="text_align_center">Launch Manual Fix</h3>
    <p class="text_align_center">{{ fix_description }}</p>
    <div class="flex_centered_row">
      <basic_text_button
          class="margin_lr_10 margin_tb_20"
          :button_state="true"
          :button_color="colors.new_orange_main"
          :text_color="colors.white"
          :button_height="other_buttons_h"
          :button_width="other_buttons_w"
          border_rad_pct="5px"
          text="Run Fix"
          text_size="20px"
          :bold="true"
          event_name="run_fix"
          @run_fix="run_fix"
      ></basic_text_button>
    </div>

  </div>

  <div class="screen_block bg_lauren_gray padding_tb_30">
    <h3 class="text_align_center">Users on System</h3>
    <h3 class="text_align_center">{{ count }}</h3>
  </div>

  <div class="screen_block bg_lauren_gray margin_t_20 margin_b_50 padding_tb_30">
    <!--      <div class="">-->
    <h3 class="text_align_center">Notification Subscriptions</h3>
    <div class="flex_centered_row">
      <div class="flex_left_aligned_column">
        <div v-for="(value, index) of subscriptions_dict" :key="`item-${index}`" class="">
          <p class="margin_tb_20"><strong> {{ index }}:</strong> <span class="text_30">{{ value }}</span></p>

        </div>
        <p><strong>Total Count</strong> <span class="text_40">{{ sub_count }}</span></p>
      </div>
    </div>
    <!--      </div>-->


    <!--    <h3 class="text_align_center">{{ value }}</h3>-->
  </div>

  <div class="screen_block bg_lauren_gray padding_tb_30 margin_b_30">
    <h3 class="text_align_center flex_centered_column">Test Notification Launch</h3>
    <div class="flex_centered_column">
      <p class="margin_tb_20">Default Neighborhood: Murrells Inlet</p>
      <input_with_label
          ref="contact_title"
          class="form_input"
          label="Title"
          @emit_state="catch_title"
      ></input_with_label>

      <input_with_label
          ref="contact_body"
          class="form_input"
          label="Body"
          @emit_state="catch_body"
      ></input_with_label>

      <basic_text_button
          class="padding_lr_6 padding_tb_6 bottom_button"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.cyanic_blue"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="125px"
          border_rad_pct="0"
          text="backend push"
          text_size="14px"
          :bold="true"
          event_name="backend_test_clicked"
          @backend_test_clicked=server_notification_test
      ></basic_text_button>
    </div>

  </div>


  <div class="screen_block bg_lauren_gray padding_tb_30 margin_b_30 flex_centered_column">
    <h3 class="text_align_center flex_centered_column">TMH User by Phone Lookup</h3>
        <input_with_label
          ref="user_name_lookup"
          class="form_input margin_tb_30 "
          label="Phone Number"
          @emit_state="catch_lookup_phone_number"
      ></input_with_label>

       <basic_text_button
          class="padding_lr_6 padding_tb_6 bottom_button"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.cyanic_blue"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="125px"
          border_rad_pct="0"
          text="Lookup"
          text_size="14px"
          :bold="true"
          event_name="lookup_name_selected"
          @lookup_name_selected=find_username_by_phone
      ></basic_text_button>

    <h4 class="margin_tb_30 font_akshar">{{lookup_user_result}}</h4>


  </div>

  <dual_select_and_launch class="margin_auto bg_whitesmoke font_raleway font_weight_800 b_rad_10px"
                          label="Promote User"
                          selection_title1="Select User"
                          selection_title2="Select New Rank"
                          button_text="Promote"
                          :values1="users"
                          :values2="ranks"
                          event_name="promote_a_user"
                          @promote_a_user="promote_user"
  >
  </dual_select_and_launch>

  <dual_select_and_launch class="margin_auto bg_whitesmoke margin_tb_10  font_raleway font_weight_800"
                          label="Demote User"
                          selection_title1="Select User"
                          selection_title2="Select New Rank"
                          button_text="Demote"
                          :values1="users"
                          :values2="ranks"
                          event_name="demote_user"
                          @demote_user="demote_user"
  >
  </dual_select_and_launch>

  <select_and_launch class="margin_auto bg_whitesmoke margin_tb_10  font_raleway font_weight_800"
                     label="Promote User to Owner"
                     selection_title="Select User"
                     button_text="Owner"
                     :values="users"
                     event_name="promote_to_owner"
                     @promote_to_owner="promote_owner"
  ></select_and_launch>

  <select_and_launch class="margin_auto bg_whitesmoke margin_tb_10  font_raleway font_weight_800"
                     label="Step Down as Owner"
                     selection_title="Select User"
                     button_text="Step Down"
                     :values="users"
                     event_name="step_down"
                     @step_down="step_down_as_owner"
  ></select_and_launch>


</template>

<script>
/* eslint-disable */
import dual_select_and_launch from "@/components/parts/dual_select_and_launch";
import select_and_launch from "@/components/parts/select_and_launch";
import basic_text_button from "@/components/parts/basic_text_button";
import input_with_label from "@/components/parts/input_with_label";
import {be_main_address, num_is_between_inclusive, parse_internal_json, colors} from "@/library";
import axios from "axios";
import store from "@/store";

export default {
  name: "tmh_internal_controls",
  components: {
    dual_select_and_launch,
    select_and_launch,
    basic_text_button,
    input_with_label
  },
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
    this.get_user_count()
    this.get_notification_subscription_count()

    this.get_fix_desc()
  },


  data() {
    return {
      colors: colors,
      users: ['Kevin', 'Thomas', 'John', 'Paul'],
      ranks: ['admin0', 'admin1',],
      selected_user_: "",
      selected_rank_: "",
      count_: 0,
      sub_dict_: "",
      sub_count_: 0,
      fix_description_: "",
      other_buttons_h_: 25,
      other_buttons_w_: 30,
      whiskeys: ['Bakers', 'Bookers', 'Shine', 'Jim Beam Black', 'Scotch'],
      beers: ['Bud', 'Sam Adams', 'Corona', 'Magic Hat', 'Pabst'],
      cars: ['Ferrari', 'Lamborghini', 'McLaren', 'Porsche', 'Corvette'],
      lookup_name_: "",
      lookup_number_: "",
      input: {
        title: "",
        body: "",
        neighborhood: "",
      }
    }
  },
  methods: {
    catch_body(content) {
      this.input.body = content.trim().slice(0, 50)
    },
    catch_title(content) {
      this.input.title = content.trim().slice(0, 50)
    },

    catch_lookup_phone_number(content){
      // this.$refs.user_name_lookup.reset_state()
      this.lookup_number_ = content.trim().slice(0, 10)


    },
    async server_notification_test() {
      // const options = {
      //   body: 'Simple piece of body text.\nSecond line of body text 👍'
      // };
      // let body = 'Check out the posting @\nhttps://tinymagichat.com/spyglass'
      // let title_text = 'This is the default title.'
      if (this.input.body === "") {
        this.input.body = 'This is the default test body.'
      }

      if (this.input.title === "") {
        this.input.title = 'This is the default title.'
      }

      let body = {
        body: this.input.body,
        title: this.input.title
      }
      let body_json = JSON.stringify(body)

      let target = be_main_address('main_api') + "push_demo"

      await axios.post(target, {
        neighborhood: "Murrells Inlet",
        body: body_json,

      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad Push Test Attempt.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              this.input.body = ""
              this.input.title = ""
              this.$refs.contact_body.reset_state()
              this.$refs.contact_title.reset_state()
            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )

    },
    promote_owner(candidate) {
      console.log(`Promote to owner launched for ${candidate}.`)
    },
    step_down_as_owner(candidate) {
      console.log(`Step down as owner launched for ${candidate}.`)
    },
    promote_user(candidate, rank) {
      console.log("Promote user launched.")
      console.log(`${candidate} to ${rank}`)
    },
    demote_user(candidate, rank) {
      console.log("Demote user launched.")
      console.log(`${candidate} to ${rank}`)
    },

    async find_username_by_phone() {

      this.$refs.user_name_lookup.emit_state()
      let phone_number = this.lookup_number_
      this.$refs.user_name_lookup.reset_state()

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        phone_number: phone_number
      }
      let endpoint = be_main_address('main') + 'get_user_name_from_phone_number'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        console.log("Got back result")
        console.log(all)
        console.log(all['data']['data'])

        if(all['data']['data'].length > 0){
          console.log("We found a name matching the phone number")
          this.lookup_name_ = all['data']['data']
        }else{
          console.log("We could not find a name to match the phone number")
          store.commit('notification_enqueue',`Sorry, but no username matches ${phone_number}.`)

          return null
        }
      })
    },


    async get_notification_subscription_count() {
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }
      let endpoint = be_main_address('tmh_admin') + 'notification_subscription_count'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        console.log("Got back subscription count")
        console.log(all)
        let res = all['data']['data']
        this.sub_count_ = res[1]
        this.sub_dict_ = res[0]
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)

        // console.log(this.logo_)
      })
    },

    async get_user_count() {
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }
      let endpoint = be_main_address('tmh_admin') + 'user_count'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        console.log("Got back user count")
        console.log(response)
        this.count_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)

        // console.log(this.logo_)
      })
    },
    async get_fix_desc() {
      // let data1 = {
      //   caller: store.getters.my_name,
      //   id_token: store.getters.my_id_token,
      //   refresh_token: store.getters.my_refresh_token,
      // }
      let endpoint = be_main_address('main_api') + 'manual_fix'
      //
      await axios({
        method: 'get',
        url: endpoint,
        // data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        console.log("Got back manual fix description")
        console.log(response)
        this.fix_description_ = all['data']['message']
        // store.commit('notification_enqueue', all['data']['message'])
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)

        // console.log(this.logo_)
      })
    },
    async run_fix() {
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }
      let endpoint = be_main_address('main_api') + 'manual_fix'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        console.log("Ran manual fix")
        console.log(response)
        let res = all['data']['message']
        console.log(res)
        store.commit('notification_enqueue', res)
      })
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      // if (new_w >= this.desktop_cutoff) {
      //   this.mobile_mode_x = false
      // } else {
      //   this.mobile_mode_x = true
      // }

      if (num_is_between_inclusive(100, 300, new_w)) {
        this.other_buttons_h_ = 40
        this.other_buttons_w_ = 70
      }
      if (num_is_between_inclusive(301, 1199, new_w)) {
        // console.log("comment thread 300 to 500")
        this.other_buttons_h_ = 40
        this.other_buttons_w_ = 70
      }
      if (num_is_between_inclusive(1200, 5000, new_w)) {
        this.other_buttons_h_ = 50
        this.other_buttons_w_ = 125
      }


    },
  },
  computed: {
    count() {
      return this.count_
    },
    sub_count() {
      return this.sub_count_
    },
    lookup_user_result(){
      if(this.lookup_name_){
        return this.lookup_name_
      }else{
        return "TMH Name"
      }
    },
    subscriptions_dict() {
      return this.sub_dict_
    },
    fix_description() {
      return this.fix_description_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    other_buttons_h() {
      return this.other_buttons_h_ + 'px'
    },
    other_buttons_w() {
      return this.other_buttons_w_ + 'px'
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },


}
</script>

<style scoped>

.tmh_color_gradient {
  width: 150px;
}

</style>