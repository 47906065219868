<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <headers
        title="Find a Caregiver"
        meta_description="Describe your home care needs and start the process of finding your perfect at home caregiver."
        og_title="Find a Caregiver"
        og_url="https://tinymagichat.com/find-a-caregiver"
        og_description="Describe your home care needs and start the process of finding your perfect at home caregiver."
        og_image=""
        twitter_title="Find a Caregiver"
        twitter_description="Describe your home care needs and start the process of finding your perfect at home caregiver."
        twitter_image=""
        twitter_card=""
    ></headers>

    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <div class="flex_centered_row margin_b_40">
      <div class="flex_centered_start_column">


        <section class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div class="flex_centered_column right_side">
              <h1 class="text_align_center">Need a Caregiver?</h1>
              <!--              <h3 class="text_align_center">$18+ per hour for Full Time Caregivers</h3>-->
              <p class="text_align_center top_text" id="top_sub_heading">
                Are you looking for help with things like:</p>

              <div class="flex_space_between_row flex_wrap max_w_95vw icon_unit_container">

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/mop_white.svg"
                           alt="Housekeeping image">
                    </div>
                  </div>
                  <p class="icon_unit_text">Light Housekeeping</p>
                </div>

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/wash_white.svg"
                           alt="Personal care image">
                    </div>
                  </div>
                  <p class="icon_unit_text">Personal Care</p>
                </div>

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/lunch_dining_white.svg"
                           alt="meal preparation image">
                    </div>
                  </div>
                  <p class="icon_unit_text">Meal Preparation</p>
                </div>

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/medication_white.svg"
                           alt="medication image">
                    </div>
                  </div>
                  <p class="icon_unit_text">Medication Reminders</p>
                </div>

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/group_white.svg"
                           alt="Companionship image">
                    </div>
                  </div>
                  <p class="icon_unit_text">Companionship</p>
                </div>

                <div class="flex_centered_column icon_unit">
                  <div class="flex_centered_row">
                    <div class="flex_centered_column">
                      <img class="task_icon"
                           src="@/assets/person_play_white.svg"
                           alt="And more image">
                    </div>
                  </div>
                  <p class="icon_unit_text">And More!</p>
                </div>

              </div>


              <!--              <p class="text_align_center bottom_text">We can help with all of these and much more.</p>-->


            </div>

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/istock/consultation-min.jpg"
                     alt="Elderly Asian man sitting on couch smiling at young woman with brown hair and glass whose back
                          is turned to the camera. She is writing notes with a pen on a clipboard collecting information
                          so that she can help provide him with the best elder care services he can use at home.">
              </div>
            </div>


          </div>
        </section>

        <section class="flex_centered_start_column" id="chat_box">

          <div>
            <figure class="flex_centered_column">
              <img class="padding_t_20" id="chat_img"
                   src="@/assets/istock/operator_standing_by-min.jpg"
                   alt="A smiling woman wearing a headset with a microphone about home care service.">
              <!--              <figcaption class="margin_t_10 font_raleway font_weight_800">We would love to speak with you.</figcaption>-->
            </figure>
          </div>

          <h3 class="text_align_center max_w_95vw subheadings" id="tell_us">Start a chat to find your
            caregiver.</h3>

          <div v-if="!chat_started">
            <div class="flex_centered_row">
              <h4 class="text_align_center" id="not_sure">Not sure what you want to say? Just choose one of these:</h4>
            </div>

            <div v-for="(item, index) of chat_seeds" :key="`item-${index}`">
              <p class="seed_questions" @click="chat_seed_clicked(index, item[0])" :id=item[0]>{{ item[1] }}</p>
            </div>
            <p class="seed_questions" id='i_have_another_question' @click="i_have_another_question_clicked()">I have
              something else I want to ask.</p>
          </div>

          <!--===========================================================================================================-->
          <!--          <div v-for="(item, index) of chat_messages" :key="`item-${index}`"-->
          <!--               class="flex_start_column chat_messages margin_b_40">-->
          <!--            <p v-if="item[0] === 'You'" class="flex_align_self_end max_w_95vw text_align_right">-->
          <!--              <span class="you_comment base_comment_label">YOU<br></span>-->
          <!--              <strong>{{ item[1] }}</strong>-->
          <!--            </p>-->
          <!--            <p v-if="item[0] === 'Shiloh'" class="flex_align_self_start max_w_95vw text_align_left">-->
          <!--              <span class="agent_comment base_comment_label">AGENT<br></span>-->
          <!--              {{ item[1] }}-->
          <!--            </p>-->
          <!--          </div>-->
          <!--===========================================================================================================-->


          <div v-for="(item, index) of chat_messages" :key="`item-${index}`"
               class="flex_start_column chat_messages margin_b_40">

            <div v-if="item[0] === 'You'" class="flex_right_aligned_column">
              <div class="you_comment base_comment_label">YOU</div>
              <p class="flex_align_self_end max_w_95vw text_align_right">
                <strong>{{ item[1] }}</strong>
              </p>
            </div>

            <div v-if="item[0] === 'Shiloh'" class="flex_left_aligned_column shiloh_res">
              <div class="agent_comment base_comment_label">SHILOH</div>
              <p class="flex_align_self_end max_w_95vw text_align_left">
                {{ item[1] }}
              </p>
            </div>
          </div>


          <!--          <p v-if="!chat_started" class="text_align_center margin_b_10 text_eli_gray margin_t_40 perfect_cg">-->
          <!--            <strong>Start chatting about finding your perfect caregiver.</strong></p>-->

          <!--          <basic_text_button-->
          <!--              v-if="chat_started"-->
          <!--              class="padding_lr_6 padding_tb_6 "-->
          <!--              :hover_color=colors.primary_purple-->
          <!--              :hover_text_color=colors.white-->
          <!--              :button_state="true"-->
          <!--              :button_color="colors.new_orange_main"-->
          <!--              :text_color=colors.darker_gray-->
          <!--              :button_color_not_ready="colors.eli_gray"-->
          <!--              :button_not_ready_text_color="colors.pennie_gray"-->
          <!--              button_height="52px"-->
          <!--              button_width="190px"-->
          <!--              border_rad_pct="0"-->
          <!--              text="Start Chat"-->
          <!--              text_size="17px"-->
          <!--              :bold="true"-->
          <!--              event_name="easy_chat_start_clicked"-->
          <!--              @easy_chat_start_clicked="easy_chat_start_clicked"-->
          <!--          ></basic_text_button>-->


          <basic_text_button
              v-if="chat_started && resend"
              class="padding_lr_6 padding_tb_6 "
              :hover_color=colors.primary_purple
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.darker_gray
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="190px"
              border_rad_pct="0"
              text="RESEND"
              text_size="17px"
              :bold="true"
              event_name="resend_message_clicked"
              @resend_message_clicked="resend_message"
          ></basic_text_button>


          <input_with_label_text_area
              id="chat_box_form"
              v-if="chat_started && !resend"
              ref="chat_prompt_box"
              class="form_input"
              place_holder_text="Type your home care question here, then click submit."
              :columns="columns_num"
              :rows="rows_num"
              @emit_state="catch_prompt"
              @enter_pressed="submit_prompt"
          >
          </input_with_label_text_area>
          <basic_text_button
              v-if="chat_started  && !resend"
              class="margin_t_30"
              :button_state="true"
              :button_color="colors.primary_purple"
              :text_color="colors.white"
              :button_color_not_ready="colors.gray"
              :button_not_ready_text_color="colors.morgie_gray"
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="SUBMIT"
              button_not_ready_text="Retire Ad"
              text_size="14px"
              :bold="true"
              event_name="save_job_request"
              @save_job_request="submit_prompt"
          ></basic_text_button>

          <p class="contact_agree">By providing your contact information you agree to be contacted directly by either a
            representative of Tiny Magic Hat or of a home care agency.</p>
        </section>

        <section class="flex_centered_row bg_lauren_gray padding_tb_30 what_does_it_cost_wrapper"
                 id="ok_how_does_this_work">

          <div class="flex_centered_column what_does_it_cost">

            <h3 class="text_align_center max_w_95vw subheadings">How to Use this Service</h3>
            <p id="this_is_a_free">This is a free service that allows you to easily connect to a home care provider.</p>


            <div class="flex_start_row works_item">
              <div class="flex_centered_row">
                <div class="flex_centered_column">
                  <div class="flex_centered_column big_nums">1</div>
                </div>

              </div>


              <p class="works_text">Start a chat and tell us about your home care needs.
              </p>
            </div>


            <div class="flex_start_row works_item">
              <div class="flex_centered_row">
                <div class="flex_centered_column">
                  <div class="flex_centered_column big_nums">2</div>
                </div>

              </div>


              <p class="works_text">We assess your case and find a local home care agency for you.
              </p>
            </div>


            <div class="flex_start_row works_item">
              <div class="flex_centered_row">
                <div class="flex_centered_column">
                  <div class="flex_centered_column big_nums">3</div>
                </div>

              </div>


              <p class="works_text">An agent contacts you to discuss service.
              </p>
            </div>

            <section class="flex_centered_column" id="the_video_container">
              <h3 class="margin_b_10 max_w_95vw text_align_center subheadings">Want to See an Example Chat First?</h3>
              <p class="watch_demo_p">Click the button below to start an interactive example chat to learn how you can
                ask questions and find out more about home care. It should only take about a minute to complete.
                Just click when prompted to keep the tutorial moving.
              </p>

              <basic_text_button

                  class="padding_lr_6 padding_tb_6 watch_demo"
                  :hover_color=colors.primary_purple
                  :hover_text_color=colors.black
                  :button_state="true"
                  :button_color="colors.cyanic_blue"
                  :text_color=colors.black
                  :button_color_not_ready="colors.white"
                  :button_not_ready_text_color="colors.white"
                  button_height="52px"
                  button_width="190px"
                  border_rad_pct="0"
                  :text=demo_button_text
                  text_size="17px"
                  :bold="true"
                  event_name="show_help_video"
                  @show_help_video="show_help_video_clicked"
              ></basic_text_button>

              <section v-if="show_help_video" id="help_video">
                <div
                    style="position: relative;
              padding-bottom: calc(205.55555555555554% + 41px);
              height: 0;">
                  <iframe
                      src="https://demo.arcade.software/3SUScOdDjZm98vVG1DEB?embed&force_no_mobile_view=true"
                      frameborder="0" loading="lazy"
                      webkitallowfullscreen mozallowfullscreen allowfullscreen
                      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;
                        color-scheme: light;" title="Find a Caregiver">
                  </iframe>
                </div>
              </section>
              <div id="video_bottom"></div>
            </section>

          </div>


        </section>


        <section class="flex_centered_row  padding_tb_30 what_does_it_cost_wrapper" id="things_to_understand">
          <div class="flex_centered_column what_does_it_cost">
            <h3 class="text_align_center subheadings">Things to Understand Before Hiring a Caregiver</h3>
            <p>Learn something about caregiving and the home care industry. See the work from a caregiver's viewpoint.
              Learn a new perspective.</p>
            <basic_text_button
                class="padding_lr_6 padding_tb_6 margin_t_20 learn_more_button"
                :hover_color=colors.primary_purple
                :hover_text_color=colors.white
                :button_state="true"
                :button_color="colors.cyanic_blue"
                :text_color=colors.darker_gray
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="52px"
                button_width="190px"
                border_rad_pct="0"
                text="Learn More"
                text_size="17px"
                :bold="true"
                event_name="learn_about_home_care_clicked"
                @learn_about_home_care_clicked="go_to_learn_about_homecare_page"
            ></basic_text_button>
          </div>
        </section>


      </div>

    </div>


  </div>
  <outside_footer class="footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import local_pda_featured from "@/components/orgs/pda/local_pda_featured";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import spinner from "@/components/parts/spinner";
import headers from "@/components/parts/headers";
import store from "@/store";
import {
  get_uuid,
  colors,
  scroll_to_top,
  dual_route,
  be_main_address,
  scroll_to_element,
  get_ip_from_header,
  num_is_between_inclusive,
  play_sound,
  analytic,
  detect_connection_speed
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "needing_caregiver",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    // console.log("Current directory:", __dirname);
    // store.commit('notification_enqueue', `This is a test.`)
    this.id_ = get_uuid()
    this.scroll_to_top()
    console.log('What is the current session id for this chat?')
    console.log(store.getters.universal_user_session_id)
    if (!store.getters.universal_user_session_id) {
      store.commit('universal_user_session_id', get_uuid())
    }
    const options = {
      root: null, // it is the viewport
      threshold: .2, //0 to 1 scale, 0 default
      // rootMargin: ""
    }
    const sections = document.querySelectorAll('section')
    let observer = new IntersectionObserver(entries => {
      // console.log(entries);
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        if (entry.target.id.length > 0) {
          // console.log(`This sections id is ${entry.target.id}`)
          analytic({
            'event': 'Scroll Landmark',
            'message': `User scrolled to section id: ${entry.target.id} on ${localStorage.getItem('dev_latest_route')}`,
            'uuid': store.getters.universal_user_session_id
          })
        }
        observer.unobserve(entry.target)
      })
    }, options);
    sections.forEach(section => {
      observer.observe(section);
    })
    console.log("Printing the session id....")
    console.log(store.getters.universal_user_session_id)
    this.run_window_width_adjustments(this.windowWidth)
    await this.log_time()
    this.repeat_on_interval()
    let ip_string = false
    ip_string = await get_ip_from_header()
    // localStorage.setItem('chat_id_cg', get_uuid())
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
    await detect_connection_speed()
  },
  unmounted() {
    console.log("running unmounted")
    this.clearAlert()
    // window.removeEventListener('popstate')
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    local_pda_featured,
    dropdown_datalist,
    input_with_label_text_area,
    spinner,
    headers
  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      chat_messages_: [],
      chat_seeds: [
        ["q0", "I need help with things like meal preparation, medication reminders, running errands and other things."],
        ["q1", "I recently had surgery and I need some help at home while I recover."],
        ["q2", "I have a chronic illness and need additional care that I am not getting from my medical providers."],
        ["q3", "How much does it cost to hire a home care agency?"],
        ["q3", "What are the advantages of using a home care agency versus directly hiring a caregiver?"],
        ["q4", "What is Tiny Magic Hat and how can it help me find home care?"],
      ],
      resend_: false,
      chat_started_: false,
      show_help_video_: false,
      // spinner_up_ = false,
      time_log_interval: 3000,
      intervalID: "",
      counter: -1,
      last_prompt: "",
      last_response: "",
      max_msg_len: 500,
      name: '',
      password: '',
      email: '',
      job_request: {
        type: "",
        neighborhood: "",
        days: "",
        times: "",
        details: "",
        email: ""
      },
      columns_num_: 15,
      rows_num_: 5,
      job_email_request: "",
      approved_agencies_: [],
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 700,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },


    async show_help_video_clicked() {
      if (!this.show_help_video_) {
        analytic({
          'event': 'find a caregiver demo video button clicked',
          'message': `Show Video`,
          'uuid': store.getters.universal_user_session_id
        })
        scroll_to_element('video_bottom')
      } else {
        analytic({
          'event': 'find a caregiver demo video button clicked',
          'message': `Hide Video`,
          'uuid': store.getters.universal_user_session_id
        })
      }


      this.show_help_video_ = !this.show_help_video_
    },
    async resend_message() {
      let res = await this.get_reply_from_chatbot()
      if (res === 'good') {
        this.resend_ = false
      }
    },

    async chat_seed_clicked(index, the_id) {
      console.log(`Chat seed ${index} was clicked with id of ${the_id}.`)
      let ques = document.getElementById(the_id);
      ques.style.background = colors.morgie_gray
      this.last_prompt = this.chat_seeds[index][1]
      let res = await this.get_reply_from_chatbot()
      if (res === 'good') {
        this.chat_started_ = true
        this.add_to_chat_user(this.last_prompt)
        this.add_to_chat_bot(this.last_response)
        analytic({
          'event': `Chat Seed Clicked`,
          'message': `Seed: ${this.last_prompt}`,
          'uuid': store.getters.universal_user_session_id
        })
      } else if (res === 'resend') {
        this.resend_ = true
      }
    },

    async i_have_another_question_clicked() {
      //Pull up box, and button
      this.chat_started_ = true

    },

    async easy_chat_start_clicked() {
      this.last_prompt = "I think I need a caregiver."
      this.add_to_chat_user(this.last_prompt)
      let res = await this.get_reply_from_chatbot()
      console.log(`Chat response from chatbot function was ${res}=====================================`)
      if (res === 'good') {
        this.chat_started_ = true
      } else if (res === 'resend') {
        this.resend_ = true
      }

    },
    go_to_contacts_page(msg = true) {
      // this.hamburger_menu_false()
      this.simple_route_prep('contact')
    },
    go_to_learn_about_homecare_page(msg = true) {
      // this.hamburger_menu_false()
      this.simple_route_prep('blog/home-care-and-caregiving-things-you-should-know')
    },
    clearAlert() {
      console.log("Turning off the timer.")
      clearTimeout(this.intervalID);
    },
    repeat_on_interval() {
      this.intervalID = setInterval(this.log_time, 3000);
    },
    async log_time() {
      this.counter += 1
      let seconds = this.time_log_interval / 1000
      let duration = seconds * this.counter
      if (this.counter <= 40) {
        analytic({
          'event': 'find a caregiver log time',
          'message': `Find a Caregiver visit duration seconds: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      } else if (this.counter === 41) {
        analytic({
          'event': 'find a caregiver log time',
          'message': `Last log for this user and uuid: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      }
    },
    add_to_chat_user(msg) {
      this.chat_messages_.push(["You", msg])
    },
    add_to_chat_bot(msg) {
      this.resend_ = false
      this.chat_messages_.push(["Shiloh", msg])
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (num_is_between_inclusive(100, 679, new_w)) {
        console.log("inside the 100 to 679 set")
        this.columns_num_ = 15
        this.rows_num_ = 5
      }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        console.log("setting to large")
        this.columns_num_ = 60
        this.rows_num_ = 10
      }
    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    catch_prompt(prompt_msg) {
      prompt_msg = prompt_msg.trim()
      if (prompt_msg.length > this.max_msg_len) {
        store.commit('notification_enqueue', "Your message exceeds the maximum length. Please shorten it.")
        this.last_prompt = "MESSAGE TOO LONG"
        return null
      } else if (prompt_msg.length === 0 | (prompt_msg === '\n')) {
        store.commit('notification_enqueue', "We want to help you, but you will need " +
            "to type a question into the text box first.")
        this.last_prompt = "MESSAGE NONEXISTENT"
        return null
      }
      let msg = prompt_msg.trim().slice(0, this.max_msg_len)
      this.add_to_chat_user(msg)
      this.last_prompt = msg
      // store.commit('save_name', this.name)
      // this.do_nothing()
    },
    simple_route_prep(r) {
      // this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async submit_prompt() {
      this.$refs.chat_prompt_box.emit_state()
      if (this.last_prompt === "MESSAGE NONEXISTENT") {
        return null
      }
      if (this.last_prompt !== "MESSAGE TOO LONG") {
        this.$refs.chat_prompt_box.reset_state()
        let res = await this.get_reply_from_chatbot()
        if (res === 'resend') {
          this.resend_ = true
        } else if (res === 'good') {
          this.resend_ = false
          this.add_to_chat_bot(this.last_response)
        }
      }
    },
    async get_reply_from_chatbot() {
      console.log("Sending off for chatbot reply.")
      // this.add_to_chat_bot("Hey, this is Shiloh.")
      // return null
      store.commit('set_spinner_state', true)
      let error_out
      let target = be_main_address('main_api') + "chat_assessment"
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        message: this.last_prompt,
        chat_id_cg: store.getters.universal_user_session_id || undefined
      }
      // localStorage.setItem('redialer_count', this.redialer_count)
      // console.log(`Phone Number: ${this.phone_number}`)
      return await axios.post(target, data1)
          .catch(function (error) {
            console.log("in the catch")
            console.log(error.response)
            // store.commit('set_spinner_state', false)
            if (error.response) {
              console.log("Something went wrong with your login.")
              store.commit('set_spinner_state', false)
              store.commit('error_enqueue', "We are sorry, but it looks like things are very busy. Click to resend your message.")
              error_out = true
              return 'resend'

            } else if (error.request) {
              console.log("Inside the network failure fail")
              let online = window.navigator.onLine;
              let message
              if (online) {
                message = "There are problems with your network connection, or Tiny Magic Hat may be down."
              } else {
                message = "You appear to have lost your network connection."
              }
              store.commit('error_enqueue', message)
              store.commit('set_spinner_state', false)
              error_out = true
              return 'resend'
            } else {
              console.log("in the error catch all")
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message);
              store.commit('set_spinner_state', false)
              error_out = true
              return 'resend'
            }
          }).then((response) => {
                // play_sound('simple_notification.mp3')

                console.log("Got good response from chatbot.");
                let reply
                try {
                  reply = response.data.data
                  play_sound('simple_notification_short.ogg')
                } catch (error) {
                  store.commit('set_spinner_state', false)
                  error_out = true
                  return 'resend'
                }

                console.log(reply);
                // this.add_to_chat_bot(reply)
                this.last_response = reply
                store.commit('set_spinner_state', false)
                scroll_to_element('chat_box_form')
                analytic({
                  'event': `Response received from bot`,
                  'message': ``,
                  'uuid': store.getters.universal_user_session_id
                })
                return 'good'
              }
          )
      // console.log("Fell out of whole thingt")
    },
  },
  computed: {

    show_help_video() {
      return this.show_help_video_
    },

    demo_button_text() {
      if (!this.show_help_video) {
        return 'Watch Demo'
      } else {
        return "Hide Demo"
      }
    },

    chat_messages() {
      return this.chat_messages_
    },
    resend() {
      return this.resend_
    },
    chat_started() {
      return this.chat_started_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },
    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}

form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}

form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}

@media (min-width: 100px) {

  .subheadings {
    /*color: var(--new-primary-purple-deep);*/
    color: var(--primary-purple);
  }

  .shiloh_res {
    background: var(--light_gray);
    padding-top: 20px;
    padding-bottom: 20px;
    /*padding-right: ;*/
    padding-left: 5px;
  }

  .chat_messages {
    max-width: 1200px;
    width: 98%;
  }

  .icon_unit_container {
    width: 266px;
  }

  .icon_unit {
    width: 125px;
    margin-top: 15px;
  }

  .icon_unit_text {
    font-size: 16px;
    font-weight: 800;
    line-height: 23px;
    margin-top: 5px;
  }

  h1 {
    color: white;
    line-height: 50px;
    font-size: 45px;
    margin-bottom: 40px;
  }

  #top_sub_heading {
    font-size: 27px;
    font-weight: 800;
    line-height: 40px;
  }

  h3 {
    font-size: 29px;
    padding-top: 50px;
    padding-bottom: 10px;
    line-height: 33px;
  }

  h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .icon_unit {
    width: 125px;
    text-align: center;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
  }


  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1.5rem;
  }

  #not_sure {
    max-width: 75vw;
  }

  .seed_questions {
    cursor: pointer;
    padding: 20px 20px;
    margin: 25px 5px;
    border-color: var(--morgie_gray);
    border-style: solid;
    border-width: 2px;
    background-color: var(--super_light_gray);
    font-weight: 800;
    max-width: 650px;
  }

  .seed_questions:hover {
    background: var(--pennie_gray);
    transition: background-color 0.5s ease;
  }

  #ok_how_does_this_work {
    margin-top: 50px;
  }

  .learn_more_button {
    margin-top: 40px;
  }

  .base_comment_label {
    color: black;
    border-radius: 20px !important;
    width: 70px !important;
    height: 1.5rem !important;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .you_comment {
    background-color: var(--primary-purple);
    color: white;
    font-weight: 800;
  }

  .agent_comment {
    background-color: var(--morgie_gray);
    color: black;
    font-weight: 800;
  }

  .watch_demo {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #help_video {
    /*height: 200px;*/
    margin-top: 50px;
    width: 90vw;
    max-width: 600px;
  }

  #things_to_understand {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  #chat_img {
    width: 98vw;
    max-width: 1000px;
    margin-top: 30px;
  }

  .check_row {
    margin-bottom: 10px;
  }

  .big_nums {
    border-radius: 50%;
    background-color: var(--primary-purple);
    color: white;
    font-size: 25px;
    font-family: 'Akshar', sans-serif;
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }

  #this_is_a_free {
    margin-bottom: 30px;
  }

  .works_text {
    font-weight: 800;
  }

  .works_item {
    margin-bottom: 30px;
    width: 85%;
  }


  .check_box_icon {
    width: 38px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .check_box_text {
    font-size: 25px;
    font-weight: 800;
  }

  .top_text {
    margin-bottom: 30px;
    font-weight: 800;
  }

  .bottom_text {
    margin-top: 30px;
    font-weight: 800;
    line-height: 30px;
  }

  .contact_agree {
    font-size: 15px;
    width: 95%;
    max-width: 500px;
    margin-top: 50px;
    text-align: center;
    /*margin-bottom: 30px;*/
    font-style: italic;
    line-height: 1.0rem;
    /*text-align: center;*/
  }

  #tell_us {
    padding-bottom: 0;
    margin-bottom: 30px;
    max-width: 250px;
  }

  strong {
    line-height: 1rem;
  }

  .what_does_it_cost_wrapper {
    width: 100vw;
  }

  .what_does_it_cost {
    max-width: 1000px;
    text-align: center;
  }

  .works_item .works_text {
    text-align: left;
  }

  /*.what_does_it_cost h3 {*/
  /*  padding-top: 20px;*/
  /*}*/
  .what_does_it_cost p {
    /*padding-bottom: 30px;*/
  }

  .form_input {
    width: 273px;
  }


  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .top_section_content {
    padding-bottom: 80px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }


  figcaption {
    font-size: 15px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }
}

@media (min-width: 360px) {


  #things_to_understand {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  p {
    font-size: 21px;
  }

  figcaption {
    font-size: 17px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  h1 {
    color: white;
    width: 300px;
    font-size: 50px;
    margin-bottom: 40px;
  }

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }


}

@media (min-width: 500px) {

  #tell_us {
    padding-bottom: 0;
    margin-bottom: 30px;
    max-width: 320px;
  }


  .watch_demo {
    /*margin-top: -20px;*/
  }

  .watch_demo_p {
    max-width: 800px;
    width: 85%;
    margin-bottom: 40px;
  }

  figcaption {
    font-size: 19px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  h3 {
    line-height: 39px;
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .perfect_cg {
    width: 410px;
    margin-bottom: 30px;
  }

  /*p {*/
  /*  font-size: 25px;*/
  /*  padding-right: 10px;*/
  /*  padding-left: 10px;*/
  /*}*/


}

@media (min-width: 680px) {
  .form_input {
    width: 600px;
  }

  #cg_topper_img {
    width: 670px;
  }

  h1 {
    color: white;
    width: 500px;
    font-size: 50px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media (min-width: 700px) {

  .contact_agree {
    font-size: 18px;
    width: 95%;
    max-width: 500px;
    margin-top: 50px;
    text-align: left;
    /*margin-bottom: 30px;*/
    font-style: italic;
    line-height: 1.0rem;
    /*text-align: center;*/
  }

  .icon_unit_container {
    width: 320px;
    margin-bottom: 40px;
  }

  .icon_unit {
    width: 125px;
    margin-top: 15px;
  }

  .icon_unit_text {
    font-size: 25px;
    font-weight: 800;
    line-height: 29px;
    margin-top: 5px;
  }

  #top_sub_heading {
    font-size: 27px;
    font-weight: 800;
    line-height: 40px;
  }

  h1 {
    color: white;
    width: 300px;
    font-size: 70px;
    margin-bottom: 40px;
    line-height: 75px;
  }

  .what_does_it_cost {
    /*max-width: 1000px;*/
    text-align: left;
  }

}

@media (min-width: 750px) {
  .top_text {
    max-width: 85%;
  }
}

@media (min-width: 1000px) {
  .top_text {
    margin-top: -20px;
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    /*width: 1320px;*/
    /*max-width: 80%;*/
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .second_card h3 {
    max-width: 250px;
  }

  #cg_topper_img {
    width: 500px;
    margin-right: 20px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }
}

@media (min-width: 1200px) {
  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 800px;
  }

  .right_side h3 {
    width: 600px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 550px;
    margin-right: 20px;
    margin-left: 40px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0;
  }

  .top_text {
    max-width: 850px;
  }
}

@media (min-width: 1500px) {
  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1390px;
  }

  #cg_topper_img {
    width: 800px;
  }
}

@media (min-width: 2000px) {

  #tell_us {
    padding-bottom: 0;
    margin-bottom: 50px;
    max-width: 320px;
  }


  #help_video {
    /*height: 200px;*/
    margin-top: 50px;
    width: 90vw;
    max-width: 800px;
  }

  figcaption {
    font-size: 22px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .icon_unit_container {
    width: 650px;
    margin-bottom: 40px;
  }

  .icon_unit {
    width: 125px;
    margin-top: 60px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .task_icon {
    width: 100px;
  }

  .icon_unit_text {
    font-size: 27px;
    font-weight: 800;
    line-height: 32px;
    margin-top: 15px;
  }

  #top_sub_heading {
    font-size: 40px;
    margin-top: 5px;
    font-weight: 800;
    line-height: 40px;
  }

  h1 {
    color: white;
    width: 400px;
    font-size: 90px;
    margin-bottom: 40px;
    line-height: 95px;
  }

  /* */
  /*h1 {*/
  /*  color: white;*/
  /*  width: 700px;*/
  /*  font-size: 70px;*/
  /*  margin-top: 30px;*/
  /*  margin-bottom: 40px;*/
  /*}*/
  h3 {
    line-height: 55px;
    font-size: 50px;
    padding-top: 80px;
    padding-bottom: 30px;
  }

  h4 {
    font-size: 38px;
  }

  #not_sure {
    max-width: 600px;
    line-height: 45px;
    margin-bottom: 30px;
  }

  #tell_us {
    max-width: 450px;
  }

  p {
    font-size: 30px;
    line-height: 35px;
  }

  .learn_more_button {
    margin-top: 60px;
  }
}
</style>