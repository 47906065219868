<template>
  <section class=" ">
    <universal_pop_up_slot
        :show_popup="show_modal"
        @universal_popup_bg_clicked="bg_clicked"
        :backer_color="colors.white"
    >

      <div class="cursor_ptr  ft_calc_main" @click="bg_clicked">

        <div class="flex_end_row margin_r_10 exit">
          <img class="exit_img"
               src="@/assets/gray_exit.svg"
               alt="exit button">
        </div>

        <div class="flex_centered_row">
          <h2>FreeTime Calculations</h2>
        </div>

        <div class="" id="ft_conclusion">
          <div class="flex_centered_column">


            <h3>A 38 hour work week</h3>
            <p>Imagine in a given week you work 38 hours at $13 per hour.</p>
            <h3>Generates a bonus</h3>
            <p>FreeTime bonuses will vary between agencies and calendar weeks, but a realistic bonus could be $225,
              increasing your overall effective pay rate to $18.92 per hour.</p>
            <div class="flex_centered_row wid_100">
              <div class="font_raleway formula" id="avg_formula" ><strong>($13 × 38 + $225)/38 = $18.92</strong></div>
            </div>
            <h3>FreeTime pays more</h3>
            <p>In this example, the six hours earning a FreeTime bonus are paid at over $50 per hour.</p>
            <div class="flex_centered_row wid_100">
              <div class="font_raleway formula" ><strong>$225 ÷ 6 + $13 = $50.50</strong></div>
            </div>
          </div>
        </div>


      </div>

    </universal_pop_up_slot>
  </section>
</template>

<script>
/* eslint-disable */
import {colors} from "@/library";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import outside_bounce_box from "@/components/parts/outside_bounce_box";
import universal_invisible_fronter from "@/components/parts/universal_invisible_fronter";

export default {
  name: "freetime_calcs",
  components: {
    universal_pop_up_slot,
    outside_bounce_box,
    universal_invisible_fronter
  },
  props: ['show_modal'],
  emits: ["bg_clicked"],
  data() {
    return {
      colors: colors,
      show_fronter_: false
    }
  },
  methods: {
    bg_clicked() {
      console.log("background was clicked.")
      this.fronter_off()
      this.$emit('bg_clicked', true)
    },
    fronter_off() {
      this.show_fronter_ = false
    },
  },
  computed: {
    show_fronter() {
      return this.show_fronter_
    },
  },
}
</script>

<style scoped>


@media (min-width: 100px) {

  #avg_formula{
    margin-bottom: 30px;
  }

  .formula{
    font-size: 20px;
  }

  .exit_img {
    width: 40px;
    padding-top: 20px;
  }

  .exit {
    max-width: 95vw;

  }

  .ft_calc_main {
    height: 1410px;
    max-height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
  }



  h2 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 5px;
    padding-top: 75px;
    font-size: 30px;
    word-wrap: anywhere;
    max-width: 275px;
    text-align: center;
  }

  h3 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 8px;
    font-size: 25px;
  }

p {
    font-size: 18px;
    word-wrap: anywhere;
    width: 80%;
    max-width: 550px;
    margin-bottom: 40px;
  }



  #ft_conclusion {
    padding-top: 50px;
    width: 100%;
    padding-bottom: 100px;
  }

  #ft_conclusion h3 {
    margin-bottom: -30px;
  }

  #ft_conclusion p {
    padding-top: 30px;
  }
}

@media (min-width: 500px) {


  h2 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 5px;
    font-size: 45px;
    word-wrap: anywhere;
    max-width: 450px;
    text-align: center;
  }

  h3 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 8px;
    font-size: 35px;
  }

  p {
    font-size: 24px;
    word-wrap: anywhere;
    width: 70%;
    max-width: 550px;
  }




  #box1 img {
    width: 183px;
  }

  #box4 img {
    height: 200px;
  }


}

@media (min-width: 1200px) {



  h2 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 75px;
  }

  h3 {
    color: var(--new-primary-purple-deep);
    padding-bottom: 8px;
  }


  #box1 img {
    width: 350px;
  }

  #box4 img {
    height: 300px;
  }

  #ft_conclusion {
    /*padding-top: 100px;*/
    width: 100vw;
  }

}

@media (min-width: 2000px) {

}

@media (min-width: 3000px) {



}
</style>