<template>
 <!--        //History caregiver report-->
<!--            <div>TEST</div>-->
            <table v-if="current_caregiver_history_report.length" class="report_table">
              <tr v-for="(item, index) of current_caregiver_history_report" :key="`item-${index}`">
                <td class="table_key">{{ item[0] }}</td>
                <td class="table_value">{{ item[1] }}</td>
                <td class="table_value">{{ item[2] }}</td>
                <td class="table_value">{{ item[3] }}</td>
              </tr>
            </table>
            <table v-else>
              <tr>
                <td colspan="6">No tasks found.</td>
              </tr>

            </table>
</template>

<script>
export default {
  name: "caregiver_report",
  props: ['current_caregiver_history_report'],
}
</script>

<style scoped>
.report_table {
  max-width: 95%;
  margin-top: 1rem;
  font-family: 'Raleway', sans-serif
}

.table_key th {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 1rem;
}

.table_value {
  text-align: center;
  /*font-size: 4.5vw;*/
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

td {
  text-align: center;
  /*font-size: 4.5vw;*/
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

tr:nth-child(even) {
  background-color: white;
}

tr:nth-child(odd) {
  /*background-color: var(--primary-purple-light);*/
  /*background-color: var(--pennie_gray);*/
  /*background-color: #cbc9c9;*/
  background-color: var(--eli_gray);
  /*background-color: var(--cyanic-blue);*/
}

</style>