<template>
  <div class="screen_block  padding_tb_20">
    <div class="flex_centered_column">

      <div class="flex_centered_row padding_b_10 label_regular">
        {{ label }}
      </div>

      <div class="flex_space_around_row res_width margin_lr_10 flex_wrap">
        <drop_down_select ref="drop_down_child"
            :title="selection_title"
            :values="values"
            @value-selected="candidate_selected"
        ></drop_down_select>

        <basic_toggle ref="toggle_child"
            class="margin_lr_10"
            :state_disabled="!candidate"
            @toggle-state="toggle_arm_launcher"
        ></basic_toggle>

<!--        <div class="slide_over_item circle_smaller flex_centered_column"-->
<!--             :class="{bg_cyanic: armed, bg_gray: !armed}">-->

          <div class="text_10 " :class="{text_white: !armed, text_black: armed}" @click="launch">{{button_text}}</div>
<!--          <basic_text_button-->
<!--            class="margin_t_20 shadow_3d"-->
<!--            :button_state="launcher_armed_"-->
<!--            :button_color="colors.new_cyanic_main"-->
<!--            :button_color_not_ready="colors.gray"-->
<!--            :button_not_ready_text_color="colors.white"-->
<!--            button_height="65px"-->
<!--            button_width="65px"-->
<!--            border_rad_pct="50%"-->
<!--            :text="button_text"-->
<!--            :text_color="colors.black"-->
<!--            text_size="13px"-->
<!--            :bold="true"-->
<!--            event_name="button_clicked"-->
<!--            @button_clicked="launch"-->
<!--        ></basic_text_button>-->

<!--        </div>-->

      </div>
<!--      <div>Selection: {{candidate}}</div>-->
    </div>

  </div>
</template>

<script>
import drop_down_select from "@/components/parts/drop_down_select";
import basic_toggle from "@/components/parts/basic_toggle";
// import basic_text_button from "@/components/parts/basic_text_button";
import {colors} from "@/library";

export default {
  name: "select_and_launch",
  props: ['label', 'event_name', 'selection_title', 'button_text', 'values'],
  components: {
    basic_toggle,
    drop_down_select,
    // basic_text_button
  },
  data() {
    return {
      launcher_armed_: false,
      candidate_: "",
      colors: colors,
    }
  },
  methods: {

    toggle_arm_launcher(){
       this.launcher_armed_ = !this.launcher_armed_
    },

    candidate_selected(state) {
      this.candidate_ = state
      if (state === `---- ${this.selection_title} ----`) {
        this.candidate_ = ""
      }
    },

    launch(){
      // console.log("in Launch")
      this.$emit(this.event_name, this.candidate)
      this.reset()
      this.$refs.drop_down_child.reset();
      this.$refs.toggle_child.toggle_toggle();
      this.toggle_arm_launcher()

    },
    reset(){
      // console.log("inside select and launch reset")
      this.launcher_armed_ = false
      this.candidate_ = ""

    }

  },
  computed: {
    armed() {
      return this.launcher_armed_
    },
    candidate() {
      return this.candidate_
    },

  },
  watch: {
  }
}
</script>

<style scoped>

.res_width{
  width: 100%;
}

@media (min-width: 2000px) {
  .res_width{
    width: 80%
  }

}

</style>