<template>
  <headers
      title="SpyGlass"
  ></headers>
  <section class="core_c">
    <meta property="og:title" content="SpyGlass"/>
    <title>Tiny Magic Hat</title>
    <div id="page_top_x"></div>
    <!--    <link rel="canonical" href="https://tinymagichat.com/outpost/spyglass"/>-->
    <notification_popup></notification_popup>
    <div>
      <outside_banner_std></outside_banner_std>
    </div>
    <spinner
        :spinner_up="spinner_up"
        @network_problem="close_spinner"
    ></spinner>

    <div class="flex_centered_row">
      <div class="flex_centered_column">
        <img class="padding_tb_20 spyglass_content_logo"
             src="@/assets/spyglass2_color.svg"
             alt="SpyGlass Logo"
             @click="go_to_your_profile">
      </div>
    </div>

    <div class="flex_centered_row">
      <div class="border_1  flex_centered_start_row flex_wrap" id="market_box">
        <div class="text_black font_raleway ">Market</div>

        <div v-if="true" @click="market_drop_down_false"
             class="market_drop_down margin_lr_20 margin_tb_10 font_raleway  text_25" id="market">Myrtle Beach
        </div>
      </div>
    </div>
    <div class="flex_centered_row">
      <h4 class="more_ad_info margin_t_50">Learn More:</h4>

    </div>
    <div class="margin_t_20 flex_centered_row ">

      <div class="flex_centered_column">
        <basic_icon_button
            class="cursor_ptr"
            :ready="true"
            :button_color="colors.cyanic_background"
            :button_color_not_ready="colors.cyanic_background"
            button_height="66px"
            button_width="190px"
            border_rad_pct="0"
            image_path='freetime_primary_purple_text_only.svg'
            image_width="130px"
            alt_text="FreeTime button"
            event_name="what_is_freetime_button_clicked"
            @what_is_freetime_button_clicked="freetime_landing"
        ></basic_icon_button>
      </div>


      <basic_text_button
          v-if="!mobile_mode"
          class="padding_lr_6 padding_tb_6 what_is_video_button margin_l_20"
          :hover_color=colors.new_orange_main
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.primary_purple"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="190px"
          border_rad_pct="0"
          text="VISIT THE FORUM"
          text_size="17px"
          :bold="true"
          event_name="what_is_freetime_button_clicked"
          @what_is_freetime_button_clicked="forum_landing"
      ></basic_text_button>
    </div>

    <div v-if="Object.keys(ads_list).length === 0" class="flex_centered_row margin_t_40">
      <div class=" margin_t_40 new_ad flex_centered_column ">

        <p class="margin_b_10"><strong>Get the job sent directly to you!</strong></p>
        <p>Sign up for notifications and we'll tell you when a job in your neighborhood opens up.</p>
        <div class="margin_tb_20">
          <basic_text_button
              class="padding_lr_6 padding_tb_6 what_is_video_button "
              :hover_color=colors.primary_purple
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="190px"
              border_rad_pct="0"
              text="NOTIFICATIONS"
              text_size="17px"
              :bold="true"
              event_name="go_to_notifications_button_clicked"
              @go_to_notifications_button_clicked="simple_route_prep('spyglass_notifications')"
          ></basic_text_button>
        </div>


        <p>There are no ads currently published for this market. Check back a bit later.</p>
        <br>
        <p>Meanwhile, why not describe your ideal caregiving job. We'll try to find it for you.</p>
        <basic_text_button
            class="padding_lr_6 padding_tb_6 what_is_video_button margin_t_20"
            :hover_color=colors.primary_purple
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.cyanic_blue"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="190px"
            border_rad_pct="0"
            text="REQUEST"
            text_size="17px"
            :bold="true"
            event_name="what_is_freetime_button_clicked"
            @what_is_freetime_button_clicked="request_landing"
        ></basic_text_button>
        <br>
        <br>
        <p>Have questions about finding a caregiving job or about Tiny Magic Hat's features? Go to the chat.</p>
        <basic_text_button
            class="padding_lr_6 padding_tb_6 what_is_video_button margin_t_20"
            :hover_color=colors.primary_purple
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.cyanic_blue"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="190px"
            border_rad_pct="0"
            text="HELP"
            text_size="17px"
            :bold="true"
            event_name="chat_button_clicked"
            @chat_button_clicked="chat_landing"
        ></basic_text_button>
      </div>
    </div>

    <div v-else class="flex_centered_column">
      <h4 class="more_ad_info text_align_center margin_t_40 margin_b_20">Register & Connect to Agencies</h4>
      <div class="flex_centered_row flex_wrap">
        <!--          <div class="flex_space_between_row " v-for="item in ads_list" :key="item.ad_id">-->
        <div class="flex_space_between_row " v-for="(item, index) in ads_list" :key="item.ad_id">
          <!--            <div>{{ // index }}</div>-->
          <div class="flex_centered_column">
            <schedule_ad_public
                class="margin_tb_10 margin_lr_10 wid_95"
                :neighborhood="item.neighborhood"
                :headline="item.headline"
                :body_text="item.body"
                :posted="item.posted"
                :freetime_stats="item.freetime_stats"
                :expires="item.expires"
                :ad_id="item.ad_id"
                event_name="ad_clicked"
                @ad_clicked="go_to_agency_profile_with_ad_id_wrapper"
            ></schedule_ad_public>

            <div v-if="index == 0" class="new_ad flex_centered_column">
              <p class="margin_b_10"><strong>Get the job sent directly to you!</strong></p>
              <p>Sign up for notifications and we'll tell you when a job in your neighborhood opens up.</p>
              <basic_text_button
                  class="padding_lr_6 padding_tb_6 what_is_video_button margin_t_20"
                  :hover_color=colors.primary_purple
                  :hover_text_color=colors.white
                  :button_state="true"
                  :button_color="colors.new_orange_main"
                  :text_color=colors.white
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="52px"
                  button_width="190px"
                  border_rad_pct="0"
                  text="NOTIFICATIONS"
                  text_size="17px"
                  :bold="true"
                  event_name="go_to_notifications_button_clicked"
                  @go_to_notifications_button_clicked="simple_route_prep('spyglass_notifications')"
              ></basic_text_button>
            </div>

            <div v-if="index == 0" class="new_ad flex_centered_column">
              <p>Don't see what you're looking for?</p>
              <p>Describe your ideal caregiving job and we'll try to find it for you.</p>
              <basic_text_button
                  class="padding_lr_6 padding_tb_6 what_is_video_button margin_t_20"
                  :hover_color=colors.primary_purple
                  :hover_text_color=colors.white
                  :button_state="true"
                  :button_color="colors.cyanic_blue"
                  :text_color=colors.white
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="52px"
                  button_width="190px"
                  border_rad_pct="0"
                  text="REQUEST"
                  text_size="17px"
                  :bold="true"
                  event_name="what_is_freetime_button_clicked"
                  @what_is_freetime_button_clicked="request_landing"
              ></basic_text_button>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!--    </div>-->


    <div v-if="mobile_mode" class="margin_t_40 flex_centered_row ">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 what_is_video_button"
          :hover_color=colors.new_orange_main
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.primary_purple"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="190px"
          border_rad_pct="0"
          text="VISIT THE FORUM"
          text_size="17px"
          :bold="true"
          event_name="what_is_freetime_button_clicked"
          @what_is_freetime_button_clicked="forum_landing"
      ></basic_text_button>
    </div>

    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
  </section>
  <outside_footer
      class="margin_t_25 footer_c"
  >
  </outside_footer>
</template>

<script>
/* eslint-disable */
import {
  log_sg_ad_click,
  be_main_address,
  colors,
  parse_internal_json,
  route_and_log,
  num_is_between_inclusive,
  scroll_to_top,
  get_agency_from_ad_id,
  dual_route,
  reentry_route,
  good_token_now,
  analytic,
  write_last_clicked_ad_to_local_storage,
  get_ip_from_header,
  scroll_to_element,
  get_uuid, detect_connection_speed
} from "@/library";

import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_icon_button from "@/components/parts/basic_icon_button";
import schedule_ad_public from "@/components/SpyGlass/schedule_ad_public";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import axios from "axios";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import headers from "@/components/parts/headers";

export default {
  name: "sg_outside",
  components: {
    notification_popup,
    error_popup_queue,
    universal_invisible_backer,
    basic_icon_button,
    schedule_ad_public,
    outside_banner_std,
    outside_footer,
    spinner,
    basic_text_button,
    headers
  },
  async mounted() {
    // this.time_log_interval_uuid = get_uuid()
    // if (store.getters.spyglass_user_session_id === "") {
    //   store.commit('spyglass_unique_user_session_id', get_uuid())
    // }
    if (!store.getters.universal_user_session_id) {
      store.commit('universal_user_session_id', get_uuid())
    }
    await this.log_time()
    this.repeat_on_interval()


    console.log("running mounted")
    this.run_window_width_adjustments(this.windowWidth)
    window.prerenderReady = true
    await this.get_market_ads()
    scroll_to_top()
    let ip_string = await get_ip_from_header()

    await detect_connection_speed()


  },
  unmounted() {
    console.log("running unmounted")

    this.clearAlert()

    // window.removeEventListener('popstate')
  },

  data() {
    return {
      intervalID: "",
      counter: -1,
      time_log_interval: 3000,
      colors: colors,
      mobile_mode_: false,
      desktop_cutoff: 700,
      market_drop_down_: false,
      ads_list_: "",
      show_hamburger_menu_: false

    }
  },
  methods: {
    // hamburger_menu_false() {
    // this.show_hamburger_menu_ = false
    // },

    do_nothing() {
      console.log("do nothing")
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    repeat_on_interval() {
      this.intervalID = setInterval(this.log_time, 3000);
    },

    clearAlert() {
      console.log("Turning off the timer.")
      clearTimeout(this.intervalID);
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      // console.log(`Width: ${new_w} px`)
      // if (new_w >= this.desktop_cutoff) {
      //   this.mobile_mode_ = false
      // } else {
      //   this.mobile_mode_ = true
      // }

      if (num_is_between_inclusive(100, 300, new_w)) {
        this.mobile_mode_ = true
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(301, 700, new_w)) {
        this.mobile_mode_ = true
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (num_is_between_inclusive(701, 5000, new_w)) {
        this.mobile_mode_ = false
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }

    },

    async go_to_your_profile(){

      if(this.reentry_route && this.good_token){
        await this.dual_route_wrapper(this.reentry_route)
      }else{
        console.log(`ReEntry Route: ${this.reentry_route}`)
        console.log(`Good Token: ${this.good_token}`)
      }

    },


    async freetime_landing() {
      // localStorage.setItem('from_route','spyglass')
      // console.log("freetime_landing clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      store.commit('activate_section_for_caregivers', 'freetime')
      await this.dual_route_wrapper('for-caregivers')
    },

    async request_landing() {
      console.log("request jqb button clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      await this.dual_route_wrapper('make-caregiver-job-request')
    },
    async chat_landing() {
      console.log("chat button clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      await this.dual_route_wrapper('help')
    },

    async forum_landing() {
      console.log("forums button clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      await this.dual_route_wrapper('main-forum')
    },

    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    async get_market_ads(market = "Myrtle Beach") {
      console.log("in get market ads")
      store.commit('set_spinner_state', true)
      let data1 = {
        market: market
      }
      let endpoint = be_main_address('spyglass') + 'get_all_ads'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.ads_list_ = all['data']['data']
        // console.log(this.ads_list)
      })
      store.commit('set_spinner_state', false)
    },

    async log_time() {
      this.counter += 1
      let seconds = this.time_log_interval / 1000
      let duration = seconds * this.counter
      if (this.counter <= 40) {
        analytic({
          'event': 'spyglass log time',
          'message': `SpyGlass ad visit duration seconds: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      } else if (this.counter === 41) {
        analytic({
          'event': 'spyglass log time',
          'message': `Last log for this user and uuid: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      }

    },

    async go_to_agency_profile_with_ad_id_wrapper(ad_id) {
      store.commit('set_spinner_state', true)
      let agency

      console.log("You clicked on a schedule ad")
      console.log(`The ad id is ${ad_id}`)

      await write_last_clicked_ad_to_local_storage(ad_id)

      if (good_token_now()) {
        agency = await get_agency_from_ad_id(ad_id)
        // console.log(`Checkpoint 2`)
      } else {
        // let message = '<h5 class="almost_there">Almost there!</h5> <div><div> <p><strong>Sign up and tell the agency you want this job now.</strong></p><br> <p><strong>It\'s easy!</strong></p> <br><br><a href="/sms_opt_in"><h5>Sign up</h5></a> '
        // store.commit('notification_enqueue', message)
        analytic({
          'event': 'spyglass ad clicked anonymous user',
          'agency': 'no token',
          'ad_id': ad_id,
        })
        console.log("calling log_sg_ad_click")
        // let r = `spyglass/${agency}/landing`
        let r = `register`
        this.simple_route_prep(r)
        store.commit('set_spinner_state', false)
        return null
      }

      console.log("back from get agency from ad id call")
      console.log(`Agency is ${agency}`)

      if (agency) {
        analytic({
          'event': 'spyglass ad clicked known user',
          'agency': agency,
          'ad_id': ad_id,
        })
        console.log(`calling log_sg_ad_click for ${ad_id}`)
        await log_sg_ad_click(agency, ad_id)
        let r = `spyglass/${agency}/landing`
        this.simple_route_prep(r)
        store.commit('set_spinner_state', false)
      } else {
        analytic({
          'event': 'spyglass ad clicked known user - expired or retired ad',
          'agency': agency,
          'ad_id': ad_id,
        })
        store.commit('error_enqueue', "Your ad has expired or has been retired by the agency.")
        localStorage.setItem("clicked_sg_ad", "")
        store.commit('set_spinner_state', false)
      }
    },


    market_drop_down_true() {
      this.market_drop_down_ = true
    },
    market_drop_down_false() {
      this.market_drop_down_ = false
    },
    simple_route_prep(r, analytic_log = null) {
      // console.log("in simple route prep")
      // this.hamburger_menu_false()
      route_and_log(this.$router, r, analytic_log)
    },
  },
  computed: {
    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },

    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    // show_backer() {
    //   return this.show_backer_
    // },
    mobile_mode() {
      return this.mobile_mode_
    },
    // market_drop_down() {
    //   return this.market_drop_down_
    // },
    ads_list() {
      return this.ads_list_
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>

<style scoped>


.market_drop_down {
  /*min-width: 300px;*/
}


@media (min-width: 100px) {

  .spyglass_content_logo {
    width: 51%;
    max-width: 250px;
    margin-bottom: 0;
    /*margin-top: 40px;*/
  }

  .new_ad {
    width: 95vw;
    max-width: 560px;
    /*border-color: var(--pennie_gray);*/
    border: 2px solid var(--wyatt_gray);
    margin-top: 10px;
    padding: 3px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    /*text-align: center;*/
  }

  .new_ad p {
    /*font-weight: 800;*/
    font-size: 18px;

    font-family: 'Raleway', sans-serif;
  }


  #market_box {
    font-size: 20px;
    border-color: var(--eli_gray);
    padding-left: 10px;
    padding-right: 10px;

  }

  #market {
    font-size: 22px;
    font-weight: 800;
  }
}


@media (min-width: 400px) {
  .spyglass_content_logo {
    width: 65%;
    max-width: 250px;
    margin-bottom: 0;
    /*margin-top: 40px;*/
  }
}

@media (min-width: 800px) {

  .spyglass_content_logo {
    width: 80%;
    max-width: 250px;
    margin-bottom: 0;
    /*margin-top: 40px;*/
  }

  #market {
    font-size: 40px;
  }

  #market_box {
    width: 750px;
    padding: 20px;
    font-size: 25px;
  }

}

@media (min-width: 1200px) {
  .new_ad {
    width: 560px;
    border-color: var(--pennie_gray);
    padding: 25px;
  }

  .new_ad p {
    font-size: 22px;
  }
}

@media (min-width: 400px) {
  .spyglass_content_logo {
    width: 90%;
    max-width: 250px;
    margin-bottom: 20px;
    /*margin-top: 40px;*/
  }
}
</style>