<template>
  <div class="">

    <section class="padding_tb_10 font_raleway">
      <!-- Use v-for to loop through the filtered data -->
      <div v-for="(item, index) in discount_values_without_invoices" :key="`item-${index}`"
           class="flex_space_between_row bg_pennie_gray padding_tb_10 discount_list margin_t_5">

        <div class="text_align_center">
          <div class="font_weight_800">Client Name</div>
          <div>{{ item.client_name }}</div>
        </div>
        <div class="text_align_center">
          <div class="font_weight_800">Creation</div>
          <div>{{ item.creation_date }}</div>
        </div>
        <div class="text_align_center">
          <div class="font_weight_800">Expires</div>
          <div>{{ item.expiration_date }}</div>
        </div>
        <div class="text_align_center">
          <div class="font_weight_800">Amount</div>
          <div>${{ item.discount_amount.toFixed(2) }}</div>
        </div>
        <div class="text_align_center">
          <div class="font_weight_800">Min Hours</div>
          <div>{{ item.min_hours_threshold }}</div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import outside_banner_std from "@/components/parts/outside_banner_std.vue";
import outside_footer from "@/components/parts/outside_footer.vue";
import inside_banner from "@/components/parts/inside_banner.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";
import Potential_discount from "@/components/Vortex/discount_pages/potential_discount_widget.vue";
import Active_discount from "@/components/Vortex/discount_pages/active_discount_widget.vue";

export default {
  name: "potential_discount_item",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text", "discount_values"
  ],
  components: {
    Active_discount,
    Potential_discount,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      // local_discount_values: this.discount_values

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    discount_values_without_invoices() {
      let clientRecords = this.discount_values
      let result = {};
      for (let client in clientRecords) {
        if (!clientRecords[client].invoices || clientRecords[client].invoices.length === 0) {
          result[client] = clientRecords[client];
        }
      }
      return result;
    },

    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {

  .discount_list {
    padding-left: 5px;
    padding-right: 5px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
  .discount_list {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 500px) {
  .discount_list {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>