<template>

  <section>


    <div class="screen_block margin_t_20  flex_centered_row margin_auto bg_white ">
      <div class="flex_centered_column ">
        <img class="tmh_color_gradient padding_tb_30 logo"
             src="@/assets/dashboard.svg"
             alt="Dashboard Icon">
        <!--      <div class="font_raleway font_weight_800 margin_b_20 text_25">SpyGlass Services</div>-->
      </div>

    </div>

    <div class=" flex_centered_column">
      <h1>Dashboard</h1>
    </div>

  </section>

  <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 text_align_center padding_tb_40 ">
    <h2>The Assistant Summary</h2>
    <div>
      <table class="assistant_table margin_t_40">
        <tr>
          <th>AGENCY</th>
          <th>RESV TS</th>
          <th>LOGS TS</th>
          <th>DURATION SECS</th>
          <th>LOGS MINUTES AGO</th>
          <th>STATUS CODE</th>
        </tr>
        <tr>n
          <th>rah1185</th>
          <td>2023-11-15:16:08:35</td>
          <td>2023-11-15:16:08:35</td>
          <td>382</td>
          <td>48</td>
          <td>data already complete</td>
        </tr>
        <tr>
          <th>hi1234</th>
          <td>2023-11-15:16:08:35</td>
          <td>2023-11-15:16:08:35</td>
          <td>295</td>
          <td>48</td>
          <td>data already complete</td>
        </tr>
      </table>
    </div>
  </section>
  <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 padding_tb_40">
    <h2 class="text_align_center">Select Agency</h2>
    <div>
      <drop_down_select
          :v-if=true
          class="margin_b_10 margin_t_40"
          ref="drop_down_agency"
          title="Select Agency"
          :values=agencies_list()
          @value-selected=set_agency_to_study
      ></drop_down_select>
      <drop_down_select
          :v-if=true
          class="margin_b_10"
          ref="drop_down_week"
          title="Select Week"
          :values=weeks_list()
          @value-selected=set_week_to_study
      ></drop_down_select>

    </div>
    <h3 v-if="stats_agency_selected">
      {{ stats_agency_selected }}
    </h3>

    <basic_text_button
        class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_20 padding_tb_40"
        :hover_color=colors.cyanic_blue
        :hover_text_color=colors.white
        :button_state="true"
        :button_color="colors.cyanic_blue"
        :text_color=colors.white
        :button_color_not_ready="colors.eli_gray"
        :button_not_ready_text_color="colors.pennie_gray"
        button_height="52px"
        button_width="150px"
        border_rad_pct="0"
        text="Download Agency Data"
        text_size="14px"
        :bold="true"
        event_name="confirm_button_clicked"
        @confirm_button_clicked="get_agency_data"
    ></basic_text_button>
    <basic_text_button
        class="padding_lr_6 padding_tb_6   margin_b_40"
        :hover_color=colors.cyanic_blue
        :hover_text_color=colors.white
        :button_state="true"
        :button_color="colors.new_orange_main"
        :text_color=colors.white
        :button_color_not_ready="colors.eli_gray"
        :button_not_ready_text_color="colors.pennie_gray"
        button_height="52px"
        button_width="150px"
        border_rad_pct="0"
        text="Download Data All Agencies"
        text_size="14px"
        :bold="true"
        event_name="confirm_button_clicked"
        @confirm_button_clicked="get_all_agency_data"
    ></basic_text_button>

  </section>
  <section v-if="!mobile_mode" class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 text_align_center padding_tb_40 ">
    <h2>The Assistant Details</h2>
    <h3 v-if="week_selected_">{{week_selected_}}</h3>
    <div>
      <table v-if="agency_data_returned" class="assistant_table margin_t_40">
        <tr>
          <th>LOGS TS</th>
<!--          <th>SESSION</th>-->
<!--          <th>LOG TYPE</th>-->
          <th>RUN STATUS</th>
          <th>HINT RIPE</th>
          <th>HINT STATUS</th>
        </tr>

        <tr  v-for="log in agency_data[`${stats_agency_selected}:${week_selected_}`]" :key=log>
          <td>{{convert_ts_to_local(log['timestamp'])}}</td>
<!--          <td>{{log['session_id']}}</td>-->
<!--          <td>{{log['log_type']}}</td>-->
          <td>{{log['run_status']}}</td>
          <td>{{log['hint_ripe']}}</td>
          <td>{{log['hint_status']}}</td>
        </tr>


      </table>
      <p v-else>
        No Agency Data to Display
      </p>
    </div>
  </section>
  <section v-if="mobile_mode" class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 text_align_center padding_tb_40 ">
    <h2>The Assistant Details</h2>
    <div>
      <table v-if="agency_data_returned" class="assistant_table margin_t_40">
        <tr>
          <th>LOGS TS</th>
          <th>RUN STATUS</th>
        </tr>

        <tr  v-for="log in agency_data[`${stats_agency_selected}:${week_selected_}`]" :key=log>
          <td>{{convert_ts_to_local(log['timestamp'])}}</td>
          <td>{{log['run_status']}}</td>
        </tr>


      </table>
      <p v-else>
        No Agency Data to Display
      </p>
    </div>
  </section>

<!--   <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 padding_tb_20 padding_tb_40">-->
<!--    <h2>FreeTime</h2>-->
<!--    <table class="freetime_table margin_t_40">-->
<!--      <tr>-->
<!--        <th>STATISTIC</th>-->
<!--        <th>VALUE</th>-->
<!--        <th>RNK</th>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Caregivers per Week</td>-->
<!--        <td>31</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Clients per Week (Agency)</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Bill Rate</td>-->
<!--        <td>31</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Pay Rate</td>-->
<!--        <td>31</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Hours per Week (Agency)</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Hours per Week Latest (Agency)</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Hours per Week (Caregiver)</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->

<!--      <tr>-->
<!--        <td>Avg. Stake Amount</td>-->
<!--        <td>1.56</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Highest Stake Amount</td>-->
<!--        <td>1.56</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Lowest Stake Amount</td>-->
<!--        <td>1.56</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Caregiver Participation</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Highest FreeTime Bonus</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. CG FreeTime checks per month.</td>-->
<!--        <td>5</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Hours per Week</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Avg. Hours per Week</td>-->
<!--        <td>600</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>Total Num. FreeTime Weeks</td>-->
<!--        <td>156</td>-->
<!--        <td>1</td>-->
<!--      </tr>-->
<!--    </table>-->
<!--  </section>-->

  <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 padding_tb_20 padding_tb_40">
    <h2>FreeTime</h2>
    <table class="freetime_table margin_t_40">
      <tr>
        <th>STATISTIC</th>
        <th>VALUE</th>
        <th>RNK</th>
      </tr>
      <tr>
        <td>Avg. Caregivers per Week</td>
        <td>31</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Clients per Week (Agency)</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Bill Rate</td>
        <td>31</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Pay Rate</td>
        <td>31</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Hours per Week (Agency)</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Hours per Week Latest (Agency)</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Hours per Week (Caregiver)</td>
        <td>600</td>
        <td>1</td>
      </tr>

      <tr>
        <td>Avg. Stake Amount</td>
        <td>1.56</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Highest Stake Amount</td>
        <td>1.56</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Lowest Stake Amount</td>
        <td>1.56</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Caregiver Participation</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Highest FreeTime Bonus</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. CG FreeTime checks per month.</td>
        <td>5</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Hours per Week</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Hours per Week</td>
        <td>600</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Total Num. FreeTime Weeks</td>
        <td>156</td>
        <td>1</td>
      </tr>
    </table>
  </section>
  <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 padding_tb_20">
    <h2>SpyGlass</h2>
    <table class="freetime_table margin_t_40">
      <tr>
        <th>STATISTIC</th>
        <th>VALUE</th>
        <th>RNK</th>
      </tr>
      <tr>
        <td>Total Number of Ads</td>
        <td>31</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. Ads per Week</td>
        <td>4</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Total Number Ad clicks</td>
        <td>175</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Ads to Associations</td>
        <td>4</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Percent Caregivers with Notifications</td>
        <td>36%</td>
        <td>1</td>
      </tr>

    </table>
  </section>
  <section class="data_section bg_lauren_gray  flex_centered_column margin_tb_15 padding_tb_20 padding_tb_40">
    <h2>SpeedyDelivery</h2>
    <table class="freetime_table margin_t_40">
      <tr>
        <th>STATISTIC</th>
        <th>VALUE</th>
        <th>RNK</th>
      </tr>
      <tr>
        <td>Num. Modules Downloaded (Agency)</td>
        <td>18</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Module Tests Taken(Agency)</td>
        <td>214</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Passed Tests (Caregiver)</td>
        <td>203</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Failed Attempts(Agency)</td>
        <td>11</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Num. SD Report Downloads</td>
        <td>43</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Num. SD Docs uploaded</td>
        <td>56</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Percent cg with SD docs (Agency)</td>
        <td>78%</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Avg. CG FreeTime checks per month.</td>
        <td>5</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Num. Pre-boarded caregivers associated.</td>
        <td>4</td>
        <td>1</td>
      </tr>

    </table>
  </section>
  <section class="data_section bg_lauren_gray  flex_centered_row margin_tb_15 padding_tb_20 padding_tb_40">
    <div class="flex_centered_column">
      <div>
      <h2>Tiny Magic Hat</h2>
    </div>

    <table class="freetime_table margin_t_40">
      <tr>
        <th>STATISTIC</th>
        <th>VALUE</th>
        <th>RNK</th>
      </tr>
      <tr>
        <td>Percent User with Profile Pics(Agency)</td>
        <td>72%</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Owner log ins (Agency)</td>
        <td>214</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Admin log ins (Agency)</td>
        <td>214</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Number of Caregiver log ins (Agency)</td>
        <td>214</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Num. of Forum Posts created</td>
        <td>4</td>
        <td>1</td>
      </tr>
      <tr>
        <td>Num. of click-to-call caregivers usages</td>
        <td>4</td>
        <td>1</td>
      </tr>

    </table>
    </div>


  </section>

</template>
<script>
/* eslint-disable */
import drop_down_select from "@/components/parts/drop_down_select.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import {be_main_address, parse_internal_json, colors, open_spinner, close_spinner} from "@/library";
import {convertUtcToLocal, parseDateStringToTimestamp, unixTimestampToString} from "@/dates_library"
import {mapGetters} from "vuex";
import store from "@/store";
import axios from "axios";

export default {
  name: "tmh_dashboard",
  components: {basic_text_button, drop_down_select},
  data() {
    return {
      agency_selected_: "",
      week_selected_: "",
      colors: colors,
      agency_data: {},
      agency_data_returned: false,
      agency_data_sundays: {},
      mobile_mode_x: false,
      button_height_:  "60px",
      button_width_: "220px",
      max_mobile_size: 1600,
    }
  },
  methods: {
    do_nothing() {
      console.log("Doing nothing.")
    },
    get_agency_data() {
      let agency = this.stats_agency_selected
      open_spinner()
      //call backend
      close_spinner()
    },

    convert_ts_to_local(timestamp){
      let ds = parseDateStringToTimestamp(timestamp)
       let ts = unixTimestampToString(ds)
       return convertUtcToLocal(ts)
    },

    get_all_agency_data() {

      open_spinner()
      //call backend
      close_spinner()
    },


    agencies_list() {
      return ["rah1185"]
    },

    weeks_list() {
      if(this.agency_data_sundays[this.stats_agency_selected]){
        return this.agency_data_sundays[this.stats_agency_selected]
      }else{
        return ['2023-11-26']
      }
    },
    set_agency_to_study(agency) {
      this.agency_data_returned = false
      this.agency_selected_ = agency
      this.get_available_sundays_for_agency()
    },
    set_week_to_study(week) {
      this.week_selected_ = week
      // this.get_agency_stats()
    },
    async get_agency_assistant_logs() {
      let target_url = be_main_address('ft') + 'get_assistant_log_details'
      let the_agency = this.stats_agency_selected
      let key = `${the_agency}:${this.week_selected_}`
       if(this.agency_data[key]){
         return this.agency_data[key]
       }



      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        agency: the_agency,
        week_str: this.week_selected_
      }
      // console.log("Here is the data1 for the get assistant log details call.")
      // console.log(data1)

      // this.stats_agency_selected
      await axios.post(target_url, data1)
          .then((response) => {
            // console.log("This is the response from user exists name check")
            //  store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              console.log(response.data)
              console.log(response.data.data['assistant_logs'])
              let key = `${the_agency}:${this.week_selected_}`
              this.agency_data[key] = response.data.data['assistant_logs']
              console.log(this.agency_data[key])
              this.agency_data_returned = true
            } else {
              console.log("Something went wrong with Get assistant logs. attempt.")
              console.log(response.data)
            }
          }

          )

    },


    async get_agency_freetime_stats() {
      let target_url = be_main_address('ft') + 'get_ft_dashboard_details'
      let the_agency = this.stats_agency_selected
      let key = `${the_agency}:${this.week_selected_}:freetime`
       if(this.agency_data[key]){
         return this.agency_data[key]
       }



      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        agency: the_agency,
        week_str: this.week_selected_
      }
      // console.log("Here is the data1 for the get assistant log details call.")
      // console.log(data1)

      // this.stats_agency_selected
      await axios.post(target_url, data1)
          .then((response) => {
            // console.log("This is the response from user exists name check")
            //  store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              console.log(response.data)
              console.log(response.data.data['freetime_stats'])
              let key = `${the_agency}:${this.week_selected_}:freetime`
              this.agency_data[key] = response.data.data['freetime_stats']
              console.log(this.agency_data[key])
              this.agency_data_returned = true
            } else {
              console.log("Something went wrong with Get assistant logs. attempt.")
              console.log(response.data)
            }
          }

          )

    },

    async get_available_sundays_for_agency() {
      let target_url = be_main_address('ft') + 'get_available_sundays_for_agency'
      let the_agency = this.stats_agency_selected
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        agency: the_agency,
      }
      // console.log("Here is the data1 for the get assistant log details call.")
      // console.log(data1)

      // this.stats_agency_selected
      await axios.post(target_url, data1)
          .then((response) => {
            // console.log("This is the response from user exists name check")
            //  store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              console.log(response.data)
              console.log(response.data.data['assistant_logs'])
              this.agency_data_sundays[the_agency] = response.data.data['assistant_agency_sundays']
              console.log(this.agency_data_sundays[the_agency])
            } else {
              console.log("Something went wrong with Get available Sundays attempt.")
              console.log(response.data)
            }
          }

          )

    },

    current_size_is_mobile(new_w){
        if(new_w > this.max_mobile_size){
            return false
        }else{
            return true
        }
    },

    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'socket_connection_counter',
      'window_width',
    ]),

    ready_to_get_agency_stats(){
      if(this.week_selected_ && this.stats_agency_selected){
        return true
      }else{
        return false
      }
    },

    stats_agency_selected() {
      return this.agency_selected_
    },
    mobile_mode() {
        return this.mobile_mode_x
      },
  },
  watch: {
    ready_to_get_agency_stats(new_v) {
      if(new_v === true){
        this.get_agency_assistant_logs()
        // this.get_agency_freetime_stats()
      }

    },
    window_width(new_w) {
        this.run_window_width_adjustments(new_w)
      },
  },
}
</script>


<style scoped>
@media (min-width: 100px) {
  .assistant_table {
    text-align: center;
    width: 260px;
  }
  .data_section{
    width: 100vh;
  }
}


@media (min-width: 700px) {
  .assistant_table {
    text-align: center;
    width: 500px;
  }
}


@media (min-width: 1200px) {
  table th {
    padding: 5px;
  }

  .freetime_table {
    text-align: left;
    width: 430px;
  }


}

@media (min-width: 1601px) {
  table th {
    padding: 5px;
  }

  .freetime_table {
    text-align: left;
    width: 430px;
  }

  .assistant_table {
    text-align: center;
    width: 1200px;
  }
}

</style>