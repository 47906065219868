<template>
  <spinner
      :spinner_up="spinner_up"
  ></spinner>
  <notification_popup></notification_popup>
  <error_popup_queue></error_popup_queue>
  <continue_enqueue
  @continue_clicked="continue_clicked"
  ></continue_enqueue>
  <socket_handler
      @local_page_update="emit_local_page_update"
  ></socket_handler>
</template>

<script>
import spinner from "@/components/parts/spinner";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import continue_enqueue from "@/components/basics/continue_enqueue";
import socket_handler from "@/components/parts/socket_handler";
import store from "@/store";

export default {
  name: "basic_page_infrastructure",
  // async mounted() {
  // },
  emits: ['local_page_update', 'continue_clicked'],
  components: {
    spinner,
    notification_popup,
    error_popup_queue,
    socket_handler,
    continue_enqueue
  },

  methods: {
    async emit_local_page_update(){
      // console.log('EMITTING LOCAL PAGE UPDATE FROM BASIC PAGE INFRASTRUCTURE MODULE***************************************************')
      this.$emit('local_page_update', true)
    },
    continue_clicked(payload){
      console.log(`Passing Continue Click through basic infrastructure.`)
      this.$emit('continue_clicked', payload)
    },

  },
  computed: {
    spinner_up() {
      return store.getters.get_spinner_state
    },
  },

}
</script>

<style scoped>

</style>