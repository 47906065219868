<template>
  <div class="">
    <continue_enqueue
        @continue_clicked="retire_discount"
    ></continue_enqueue>

    <div v-if="!isEmpty(discount_values_with_invoices)" class="flex_centered_row">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 margin_b_30 margin_t_40"
          :hover_color=colors.new_orange_main
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.new_cyanic_mid"
          :text_color=colors.darker_gray
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="190px"
          border_rad_pct="0"
          text="History"
          text_size="17px"
          :bold="true"
          event_name="create_transaction"
          @create_transaction="get_agency_vortex_history"
      ></basic_text_button>
    </div>

    <section class="padding_tb_10  font_raleway">
      <div v-for="(item, index) of discount_values_with_invoices" :key="`item-${index}`" id="active_discount_item"
           class="flex_wrap flex_centered_column  bg_lauren_gray margin_b_10 padding_tb_10">
        <div v-if="hasInvoices(item)">
          <h4 class="text_align_center padding_t_20">{{ item.client_name }}</h4>

          <div class="flex_centered_row">
            <h5 class="margin_b_10">Summary</h5>
          </div>


          <div class="flex_centered_column wid_100 padding_l_10">

            <table class="active_discount_table">
              <tr>
                <td class="font_weight_800">Creation</td>
                <td class="data_column">{{ item.creation_date }}</td>
              </tr>
              <tr>
                <td class="font_weight_800">Expires</td>
                <td class="data_column">{{ item.expiration_date }}</td>
              </tr>
              <tr>
                <td class="font_weight_800">Total</td>
                <td class="data_column">${{ item.discount_amount }}</td>
              </tr>
              <tr>
                <td class="font_weight_800">Honored</td>
                <td class="data_column">${{ item.total_discount_amt_honored }}</td>
              </tr>
              <tr>
                <td class="font_weight_800">Min Hours</td>
                <td class="data_column">{{ item.min_hours_threshold }}</td>
              </tr>
              <tr>
                <td class="font_weight_800">Current Hours</td>
                <td class="data_column">{{ item.current_invoice_hours }}</td>
              </tr>
            </table>

          </div>

          <div v-if="!mobile_mode">
            <div class="flex_centered_row">
              <h5 class="margin_t_40">Client Invoices</h5>
            </div>

            <div class="current_data bg_lauren_gray">
              <table class="bg_white">
                <tr>
                  <th>Invoice Number</th>
                  <th>Invoice Amount</th>
                  <th>Invoice Hours</th>
                  <th>All Hours</th>
                  <th>Min. Met</th>
                  <th>Credited Amount</th>
                </tr>
                <tr v-for="invoice in item.invoices" :key="invoice.invoice_number">
                  <td>{{ invoice.invoice_number }}</td>
                  <td>${{ formatFloat(invoice.invoice_amount) }}</td>
                  <td>{{ invoice.invoice_hours }}</td>
                  <td>{{ invoice.invoice_all_hours }}</td>
                  <td>{{ invoice.min_hours_threshold_met }}</td>
                  <td>{{ invoice.invoice_credit_applied }}</td>
                </tr>
              </table>
            </div>

            <div class="flex_centered_row">
              <h5 class="margin_t_40">Discount Transactions</h5>
            </div>
            <div class="current_data bg_lauren_gray flex_centered_row">
              <table class="bg_white">
                <tr>
                  <th>Invoice</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
                <tr v-for="transaction in item.transactions" :key="transaction.invoice_number">
                  <td>{{ transaction.invoice_number }}</td>
                  <td>${{ transaction.discount_amt_honored }}</td>
                  <td>{{ transaction.transaction_date }}</td>
                </tr>
              </table>
            </div>

          </div>
        </div>


      </div>
      <div v-if="isEmpty(discount_values)">
        <div class="flex_centered_row">
          <h3>There are no active discounts.</h3>
        </div>

      </div>
    </section>

  </div>

</template>


<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import outside_banner_std from "@/components/parts/outside_banner_std.vue";
import outside_footer from "@/components/parts/outside_footer.vue";
import inside_banner from "@/components/parts/inside_banner.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now,
  receive_over_socket,
  be_main_address,
  prime_socket,
  add_class_to_id,
  get_element_by_class,
  formatFloat,
  isEmpty, parse_internal_json
} from "@/library";
import {Database} from "@/client_db";
import Potential_discount from "@/components/Vortex/discount_pages/potential_discount_widget.vue";
import Active_discount from "@/components/Vortex/discount_pages/active_discount_widget.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import continue_enqueue from "@/components/basics/continue_enqueue.vue";
import axios from "axios";

export default {
  name: "honored_discount_item",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        // if (this.discount_values['ripe'] === true) {
        //   console.log("THE DISCOUNT IS RIPE ON MOUNT.")
        //   // let the_class = get_element_by_id('active_discount_item')
        //   // add_class_to_id("active_discount_item", 'bg_cyanic')
        //   // let box = document.getElementById(this.id)
        //   // box.style.background = this.bg_color
        // }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "discount_values",
  ],
  emits: ['retire_discount'],
  components: {
    continue_enqueue,
    basic_text_button,
    Active_discount,
    Potential_discount,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      // discount_values_local: this.discount_values,
      colors: colors,
      mobile_mode_x: false,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",

    }
  },
  methods: {
    isEmpty,
    formatFloat,
    do_nothing() {
      console.log("do nothing at the active item level.")
    },

   async get_agency_vortex_history() {


      let endpoint = 'get_agency_honored_discounts_csv'
      let target_url = be_main_address('vortex') + endpoint

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
      }

      console.log(data1)

      console.log("Calling backend to get vortex data.")

      await axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
                console.log("Attempt to print headers")
                // console.log(response.headers['content-disposition'].split("=")[1])
                console.log(response.headers)

                // console.log(response.headers['filename'])

                if (response.data['error'] === true) {
                  let message = parse_internal_json(response.data)['payload']['content']['message']
                  store.commit('error_enqueue', message)
                } else {
                  // let filename = response.headers['content-disposition'].split("=")[1]
                  var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                  var fileLink = document.createElement('a');

                  fileLink.href = fileURL;
                  fileLink.setAttribute('download', 'Honored_History' + '.xlsx');
                  document.body.appendChild(fileLink);

                  fileLink.click();
                }
              }
          )

    },

    hasInvoices(clientData) {
      return clientData.invoices && clientData.invoices.length > 0;
    },

    retire_discount(disc) {
      console.log("At item level. Honoring of discount for client accepted.")
      this.$emit('retire_discount', disc)

    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    // make_ripe(){
    //   if(!this.ripeness){
    //     this.ripeness = true
    //   }
    //
    // },
    // reset_ripeness(){
    //   console.log(`Running reset ripeness from the item. Current value is ${this.ripeness}`)
    //
    //   this.$emit('reset_ripeness', !this.ripeness)
    // },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },


  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },

    discount_values_with_invoices() {
      let clientRecords = this.discount_values;
      let result = {};

      // Get the current date
      const now = new Date();

      // Calculate the date 6 months ago
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(now.getMonth() - 6);

      for (let client in clientRecords) {
        const record = clientRecords[client];
        const creationDate = new Date(record.creation_date);

        // Check if the record has invoices and creation date is within the last 6 months
        if (record.invoices && record.invoices.length > 0 && creationDate >= sixMonthsAgo) {
          result[client] = record;
        }
      }

      return result;
    },

    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },

    //  is_ripe(){
    //   console.log("INSIDE DISCOUNT RIPENESS COMPUTED")
    //   return this.ripeness
    // },

  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
    // ripeness(new_v){
    //   console.log("INSIDE DISCOUNT RIPENESS WATCH")
    //   if(new_v === true){
    //   // chantge the color on active_discount_item
    //     add_class_to_id("active_discount_item", 'bg_cyanic')
    //
    //   }
    // },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {


  .active_discount_table {
    width: 100%;
    border-collapse: collapse;
  }

  .active_discount_table td {
    padding: 8px 16px; /* Adding padding for space between columns */
    border: 1px solid black;
  }

  .data_column {
    background-color: #ffffff; /* Data column background color */
  }


  .active_discount_table {
    width: 65%;
  }

  .current_data {
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid black;
    padding: 8px 16px; /* Adding more padding to create more space between columns */
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }

  h1 {

  }

  h4 {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h5 {
    font-family: Raleway, sans-serif;
    font-size: 25px;
    font-weight: 700;
  }
}

@media (min-width: 400px) {
  .active_discount_table {
    width: 55%;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>