<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    window.addEventListener('resize', this.setWindowWidth_commit)
  },
  beforeUnmount() {
    window.removeEventListener('resize',this.setWindowWidth_commit )
  },

  data() {
    return {};
  },
  methods: {
    setWindowWidth_commit(){
      this.$store.commit('setWindowWidth');
    },
  },
  computed: {}
};
</script>

<style scoped>

</style>