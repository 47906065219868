<template>
  <section class="flex_centered_column">
    <inside_banner
        mode="speedydelivery_user"
    ></inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>

  </section>


  <section class="flex_centered_start_column">
    <div class="flex_centered_row">
      <basic_icon_button
          ref="btn4"
          class="margin_lr_10 cursor_ptr"
          :ready="true"
          button_color="transparent"
          :button_color_not_ready="colors.pennie_gray"
          :button_height=module_button_size
          :button_width=module_button_size
          border_rad_pct='button_border_rad'
          :image_path=module_logo_file_name
          :image_width=module_image_size
          alt_text="Module Icon Image"
          event_name="go_back_home"
          @go_back_home="go_to_speedydelivery_main_user_interface()"
      ></basic_icon_button>
    </div>
  </section>

  <section class="flex_centered_row">
    <div class="flex_centered_column">
      <h1>{{ module_headline }}</h1>
    </div>
  </section>

<!--  <section class="desc_text flex_centered_row">-->
<!--    <div class="flex_left_aligned_column what_is_sd_text">-->
<!--      <p>{{ module_desc_text }}</p>-->
<!--    </div>-->
<!--  </section>-->

  <section class="flex_centered_row">
    <div class="flex_centered_column desc_text">
      <div class="flex_left_aligned_column what_is_sd_text">
        <p>{{ module_desc_text }}</p>
      </div>
    </div>

  </section>


  <section class="flex_centered_column get_the_module">
    <div class="flex_centered_row">
<!--      <h1>Get the Module</h1>-->
    </div>
    <basic_text_button
        class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
        :hover_color=colors.primary_purple
        :hover_text_color=colors.white
        :button_state="true"
        :button_color="colors.new_cyanic_mid"
        :text_color=colors.darker_gray
        :button_color_not_ready="colors.eli_gray"
        :button_not_ready_text_color="colors.pennie_gray"
        button_height="52px"
        button_width="190px"
        border_rad_pct="0"
        text="Get the Module"
        text_size="17px"
        :bold="true"
        event_name="download_module"
        @download_module="email_module(module_download_name)"
    ></basic_text_button>
  </section>

  <section class="flex_centered_column take_the_test ">
    <div class="flex_centered_row">
      <h1>Ready for  the Test?</h1>
    </div>
    <basic_text_button
        class="padding_lr_6  margin_t_10 "
        :hover_color=colors.primary_purple
        :hover_text_color=colors.white
        :button_state="true"
        :button_color="colors.new_orange_main"
        :text_color=colors.darker_gray
        :button_color_not_ready="colors.eli_gray"
        :button_not_ready_text_color="colors.pennie_gray"
        button_height="63px"
        button_width="190px"
        border_rad_pct="0"
        text="Start Test"
        text_size="17px"
        :bold="true"
        event_name="go_to_the_test"
        @go_to_the_test="go_to_module_test"
    ></basic_text_button>
  </section>

</template>

<script>
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import inside_banner from "@/components/parts/inside_banner";
import basic_text_button from "@/components/parts/basic_text_button";
import basic_icon_button from "@/components/parts/basic_icon_button";
import {non_contextual_route, colors, email_speedydelivery_module, scroll_to_top,route_and_log} from "@/library";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
  name: "module_main_page.vue",
  data() {
    return {
      colors: colors,
      button_size_: "100px",
      button_img_size_: "80px",
    }
  },
  props: [
    'module_download_name',
    'module_logo_name',
    'module_desc_text',
    'module_headline',
  ],
  components: {
    inside_banner,
    basic_text_button,
    basic_page_infrastructure,
    basic_icon_button
  },
   async mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.window_width)
    // add_class_to_body('bg_eli_gray')
  },
  methods: {
    do_nothing() {
      // console.log('Just got a message from local page update event ***********************************************************')
    },
    async email_module(module_name) {
      await email_speedydelivery_module(module_name)
    },

   async go_to_speedydelivery_main_user_interface(){
      console.log("Go back home clicked.")
     let me = store.getters.my_name
     let r = me + '/' + 'speedydelivery'
     let l = `Going back from ${this.module_headline} module page to main speedydelivery user interface.`

     await route_and_log(this.$router, r, l)


   },

    async go_to_module_test() {
      await non_contextual_route(this.$router, 'test')
    },
    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 400) {

        this.button_size_ = "110x"
        this.button_img_size_ = "90px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "150px"
        this.button_img_size_ = "130px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        // this.button_size_ = "110px"
        // this.button_img_size_ = "90px"
      }

    },
  },
  computed: {
    ...mapGetters([
      'window_width'
    ]),
    spinner_up() {
      return store.getters.get_spinner_state
    },
    module_logo_file_name() {
      return this.module_logo_name
    },
    module_button_size() {
      console.log("Running the module size computed property.")
      return this.button_size_
    },
    module_image_size() {
      return this.button_img_size_
    },
    // module_button_size() {
    //   return this.button_size_
    // },
  },
  watch: {

    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>
@media (min-width: 100px) {

  h1 {
    font-size: 32px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .desc_text {
    max-width: 350px;
  }
  p {
    font-size: 22px;
  }

  .get_the_module {
    margin-top: 50px;
  }

  .take_the_test {
    margin-top: 50px;
    padding-bottom: 100px;

  }
}

@media (min-width: 400px) {
  .desc_text {
    max-width: 90%;
    font-size: 22px;
  }
}


@media (min-width: 1000px) {
  .desc_text {
    width: 90%;
    max-width: 1100px;
  }
}
@media (min-width: 1000px) {
  /*.desc_text {*/
  /*  width: 90%;*/
  /*  max-width: 1100px;*/
  /*}*/
  h1{
    font-size: 40px;
  }
  p{
    font-size: 25px;
  }
}

@media (min-width: 2000px) {

  .what_is_sd p {
    max-width: 800px;
    line-height: 2.0rem;
  }

  h1 {
    margin-top: 40px;
  }

  .module_logo {
    width: 400px;
  }


}

</style>