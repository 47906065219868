<template>
  <headers
      title="Tiny Magic Hat"
  ></headers>
  <notification_popup></notification_popup>
  <error_popup_queue></error_popup_queue>
  <div class="home_page">
    <div id="page_top"></div>
    <outside_banner_std></outside_banner_std>


    <div class="">

      <!--<editor-fold desc="Above the fold">-->
      <section class="bg_primary_purple_grad flex_centered_column  splash" id="splash">
        <h1
            class="text_align_center padding_lr_6 margin_t_30  tag_line1 text_white ">
          You're a caring person.
        </h1>
        <!--        <div id="splash_line"></div>-->
        <img id="logo_top_main" src="@/assets/rabbit_2_line_white.svg" alt="Tiny Magic Hat Main Logo">
        <div
            class="text_align_center padding_lr_6 margin_b_30 margin_t_5 text_white tag_line2  lineh_15">
          <!--          <p>Browse convenient caregiving jobs</p>-->
          <!--          <p>Choose what fits your schedule and </p>-->
          <!--          <p>Choose what fits your schedule and location</p>-->
          <!--          <p>Choose what fits your schedule and location</p>-->
          <!--          <p>Work part time or full time</p>-->
          <!--          <p>Tiny Magic Hat helps those who need a caregiver, those who want to work professionally as a caregiver,-->
          <!--          and those who operate home care agencies.</p>-->
          <!--           <p>If you need a caregiver, seek employment as a caregiver, or operate home care agency, you are in the right place.</p>-->
          <p>We help caregivers, those seeking caregivers, and home care agencies.</p>
        </div>



      </section>
      <!--</editor-fold>-->

      <section class="flex_centered_row  padding_tb_40 go_to_jobs" id="need_to_hire_a_caregiver">
        <div class="flex_centered_column ">
          <h2 class="text_align_center">Need to hire a caregiver?</h2>
          <div class="flex_centered_row">
            <p class="check_out_positions">We're listening! Tell us about your situation and let us help you.</p>
          </div>
          <basic_text_button
              class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
              :hover_color=colors.primary_purple
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_cyanic_mid"
              :text_color=colors.darker_gray
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="190px"
              border_rad_pct="0"
              text="Tell Us"
              text_size="17px"
              :bold="true"
              event_name="need_a_caregiver_clicked"
              @need_a_caregiver_clicked="go_to_find_a_caregiver_page"
          ></basic_text_button>

        </div>

      </section>

      <section class="flex_centered_row bg_lauren_gray padding_tb_40 go_to_jobs" id="looking_for_caregiver_job">
        <div class="flex_centered_column ">
          <h2 class="text_align_center">Looking for a caregiver job?</h2>
          <div class="flex_centered_row">
            <p class="check_out_positions">Check out what positions home care agencies in Tiny Magic Hat's network are
              currently trying to fill.</p>
          </div>
          <basic_text_button
              class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
              :hover_color=colors.primary_purple
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.darker_gray
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="190px"
              border_rad_pct="0"
              text="SpyGlass"
              text_size="17px"
              :bold="true"
              event_name="go_to_jobs_clicked"
              @go_to_jobs_clicked="go_to_spyglass_ads_page"
          ></basic_text_button>

        </div>

      </section>

      <section class="flex_centered_column bg_white  padding_t_20" id="what_is_tmh">
        <div class="flex_centered_row flex_wrap" id="what_is_tmh_main_content_container">

          <img class=" padding_tb_20" id="what_is_img"
               src="@/assets/stairs2.svg"
               alt="Drawn image of four smiling people, possibly caregivers and home care agency staff, holding hands
               while ascending a staircase. The imagery is meant to suggest people helping each other
               to achieve a goal together.">
          <div class="flex_centered_column">
            <div id="about_marker"></div>
            <h2
                class="text_align_center  margin_b_20 margin_t_30 padding_lr_15">
              How can Tiny Magic Hat help your home care agency?
            </h2>
            <div class="flex_centered_row">
              <div
                  class="wid_85  lineh_30 what_is_tmh_text margin_b_30 lineh_35">
                <p>Tiny Magic Hat is a suite of tools designed to help caregivers and agencies who provide private
                  duty
                  home care.</p>
                <p>With SpeedyDelivery, SpyGlass and FreeTime, we can help optimize the caregiving profession.</p>
                  <div id="pixel-to-watch"></div>
                <div class="flex_centered_row" id="what_is_modal_link">

                  <basic_text_button
                      class="padding_lr_6 padding_tb_6 what_is_video_button"
                      :hover_color=colors.primary_purple
                      :hover_text_color=colors.white
                      :button_state="true"
                      :button_color="colors.new_orange_main"
                      :text_color=colors.darker_gray
                      :button_color_not_ready="colors.eli_gray"
                      :button_not_ready_text_color="colors.pennie_gray"
                      button_height="52px"
                      button_width="190px"
                      border_rad_pct="0"
                      text="Watch Video"
                      text_size="17px"
                      :bold="true"
                      event_name="learn_more_button_clicked"
                      @learn_more_button_clicked="what_is_tiny_magic_hat_video_make_true"
                  ></basic_text_button>



                </div>


                <video_modal
                    :show_modal=what_is_tiny_magic_hat_video_true
                    video_id="755293203"
                    :base_height="v_def_height"
                    :base_width="v_def_width"
                    @bg_clicked="what_is_tiny_magic_hat_video_make_false"
                ></video_modal>

              </div>
            </div>


          </div>

        </div>


      </section>

      <section class="flex_centered_column  flex_wrap for_cgs" id="caregivers">
        <img class="padding_tb_20" id="cg_topper_img"
             src="@/assets/purple_girl.svg"
             alt="Drawn image of an attractive, smiling caregiver wearing purple scrubs.">

        <!--        <h1 class="text_align_center solutions_txt" @click="dual_route_wrapper('/hello_landing')">Caregivers</h1>-->
        <h1 class="text_align_center solutions_txt">Caregivers</h1>

        <section class="flex_centered_row  flex_wrap">

          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id="755297326"
              button_text="Learn More"
              @main_btn_clicked="speedydelivery_landing"
              :button_color="cg_color"
              icon="icon_good_docs_white.svg"
              desc="SpeedyDelivery-caregiver"

              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.cyanic_blue"
          >
            <template v-slot:headline>
              <div>Get Qualified for Caregiving</div>
            </template>
            <template v-slot:body>
              <div>Complete your qualifications from home so you're ready when your perfect client needs you.
              </div>
            </template>
          </home_card>



          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id="755298908"
              button_text="Learn More"
              @main_btn_clicked="spyglass_landing"
              :button_color="cg_color"
              icon="icon_happy_client_white.svg"
              desc="SpyGlass-caregiver"

              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.primary_purple"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.primary_purple"
          >
            <template v-slot:headline>
              <div>Find Your Perfect Client</div>
            </template>
            <template v-slot:body>
              <div>Easily connect to agencies with clients near you that fit your schedule, all from within our app.
              </div>
            </template>
          </home_card>


          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id="755300990"
              button_text="Learn More"
              @main_btn_clicked="freetime_landing"
              :button_color="cg_color"
              icon="icon_cash_white.svg"
              desc="FreeTime-caregiver"

              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.new_gold"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Give Yourself a Raise</div>
            </template>
            <template v-slot:body>
              <div>Increase your take home pay by several dollars per hour as a full-time caregiver with FreeTime.</div>
            </template>
          </home_card>
        </section>

        <div>
          <carousel_container
              class="carousel"
              :my_slides="get_carousel_quotes()"
              :body_color="colors.primary_purple"
          ></carousel_container>
        </div>


      </section>

      <section class="flex_centered_column  flex_wrap for_agencies " id="agencies">
        <img class=" padding_tb_20" id="agencies_topper_img"
             src="@/assets/agency.svg"
             alt="Drawn image of three smiling home care agency staffers. Two staffers sit at desks, one working with a
             smart phone, the other with a laptop on either side of a woman wearing glasses and holding a clipboard or
             notebook. All three are looking at the viewer. The scene suggests a busy home care agency office.">
        <h1 class="text_align_center solutions_txt ">Agencies</h1>

        <section class="flex_centered_row flex_wrap agency_cards">

          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id="755297326"
              button_text="Learn More"
              @main_btn_clicked="speedydelivery_landing_agencies"
              :button_color="agency_color"
              icon="icon_good_docs_white.svg"
              desc="SpeedyDelivery-icon"

              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.new_gold"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Rapid Onboarding</div>
            </template>
            <template v-slot:body>
              <div>Candidates completed training and documentation is available before your first meeting.</div>
            </template>
          </home_card>

          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="spyglass_landing_agencies"
              :button_color="agency_color"
              icon="icon_group_white.svg"
              desc="SpyGlass-icon"
              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.cyanic_blue"
          >
            <template v-slot:headline>
              <div>Find Caregivers</div>
            </template>
            <template v-slot:body>
              <div>Quickly post times and locations you need filled. Candidates choose what works for them.</div>
            </template>
          </home_card>

          <home_card
              class="margin_lr_20 margin_tb_20"
              base_video_height="180"
              base_video_width="320"
              video_id="755302861"
              button_text="Learn More"
              @main_btn_clicked="freetime_landing_agencies"
              :button_color="agency_color"
              icon="icon_cash_white.svg"
              desc="FreeTime-icon"
              :button_text_color="home_card_btn_text_color"
              :hover_color="colors.new_orange_main"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_orange_main"
          >
            <template v-slot:headline>
              <div>Pay More, Affordably</div>
            </template>
            f
            <template v-slot:body>
              <div>Reward your hardest working employees at well above market rates without breaking the bank.
              </div>
            </template>
          </home_card>

        </section>

      </section>

    </div>
    <outside_footer
        @link_clicked="hamburger_menu_false()"
    ></outside_footer>
  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import store from "@/store";
import basic_text_button from "@/components/parts/basic_text_button";
import basic_icon_button from "@/components/parts/basic_icon_button";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import home_card from "@/components/parts/home_card";
import outside_footer from "@/components/parts/outside_footer";
import video_modal from "@/components/parts/video_modal";
import outside_banner_std from "@/components/parts/outside_banner_std";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import headers from "@/components/parts/headers";

import {
  analytic,
  inner_route,
  attempt_save_reentry_route,
  colors,
  current_vtag,
  remove_class_to_body,
  good_token_now,
  get_ip_from_header,
  message_sw,
  detect_connection_speed,
  scroll_to_element, dual_route, gather_threads, main_forum_thread_count, get_uuid
} from "@/library";

export default {

  async mounted() {
    // console.log("RUNNING MOUNTED")
    if (!store.getters.universal_user_session_id) {
      store.commit('universal_user_session_id', get_uuid())
    }

    // window.addEventListener("scroll", () => {
    //   console.log(window.scrollY)
    // });

    const options = {
      root: null, // it is the viewport
      threshold: .2, //0 to 1 scale, 0 default
      // rootMargin: ""

    }
    const sections = document.querySelectorAll('section')
    let observer = new IntersectionObserver(entries => {
      // console.log(entries);
      entries.forEach(entry => {
        if(!entry.isIntersecting){
          return;
        }
        if(entry.target.id.length > 0){
          // console.log(`This sections id is ${entry.target.id}`)
          analytic({
          'event': 'Scroll Landmark',
          'message': `User scrolled to section id: ${entry.target.id} on ${localStorage.getItem('dev_latest_route')}`,
          'uuid': store.getters.universal_user_session_id
        })
        }
        observer.unobserve(entry.target)
      })
    }, options);
    sections.forEach(section => {
      observer.observe(section);
    })

    await this.log_time()
    this.repeat_on_interval()

    inner_route()
    // detect_connection_speed()

    // Get first page of threads for the Forums
    await gather_threads()
    await main_forum_thread_count()

    if (store.getters.get_activate_log_out) {
      store.commit("set_activate_log_out", false)
      await this.log_out_user()
    }
    attempt_save_reentry_route()
    if (this.windowWidth > this.desktop_cutoff) {
      this.mobile_mode_x = false
    } else {
      this.mobile_mode_x = true
    }
    this.run_window_width_adjustments(this.windowWidth)

    if (this.windowWidth > this.desktop_cutoff) {
      remove_class_to_body('disable-scrollbars::-webkit-scrollbar')
    }

    window.prerenderReady = true


    if (localStorage.getItem('update_available') === 'pending') {
      // localStorage.setItem('update_available', 'false')
      store.commit('set_update_available', 'false')
      store.commit('notification_enqueue', "Update complete.")
    }


    if (localStorage.getItem('update_available') === 'true') {
      // localStorage.setItem('update_available', 'false')
      await this.clear_caches_and_update()

    }


    store.commit('create_initial_defaults')
    await store.dispatch('notification_restart')
    let ip_string = false
    ip_string = await get_ip_from_header()

    if (store.getters.get_outside_inner_route) {
      console.log("inside checking for inner route")
      let inner_route = store.getters.get_outside_inner_route
      this.scroll_to_element(inner_route)
    }


    await detect_connection_speed()


  },
  async unmounted() {
    console.log("home page unmounted")
    this.clearAlert()


  },

  updated() {
    if (store.getters.get_outside_inner_route) {
      console.log("inside checking for inner route")
      let inner_route = store.getters.get_outside_inner_route
      this.scroll_to_element(inner_route)
    }
  },

  data() {
    return {
      colors: colors,
      time_log_interval: 3000,
      counter: -1,
      intervalID: "",
      home_card_btn_text_color: colors.darker_gray,
      cg_color: colors.new_primary_purple,
      agency_color: colors.new_cyanic_main,
      attempt_reentry_route: "",
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      update_is_available_: false,
      reload_msg: "Please wait while we reload an update",
      v_def_height: 180,
      v_def_width: 320,
      v_scale: 1,
      what_is_tiny_magic_hat_video_true_: false,
      video: {
        height: "",
        width: ""
      },
    }
  },
  components: {
    basic_text_button,
    basic_icon_button,
    universal_invisible_backer,
    basic_slide_over_menu,
    notification_popup,
    home_card,
    outside_footer,
    video_modal,
    outside_banner_std,
    carousel_container,
    error_popup_queue,
    headers
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
  methods: {
    repeat_on_interval() {
      this.intervalID = setInterval(this.log_time, 3000);
    },
    clearAlert() {
      console.log("Turning off the timer.")
      clearTimeout(this.intervalID);
    },

    async log_time() {
      this.counter += 1
      let seconds = this.time_log_interval / 1000
      let duration = seconds * this.counter
      if (this.counter <= 40) {
        analytic({
          'event': 'home page log time',
          'message': `Home Page visit duration seconds: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      } else if (this.counter === 41) {
        analytic({
          'event': 'home page log time',
          'message': `Last log for this user and uuid: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      }

    },


    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_home_carousel_quotes
    },
    async what_is_tiny_magic_hat_video_make_true() {
      analytic(`How can Tiny Magic Hat Help?::video modal opened:CTA clicked`)
      this.what_is_tiny_magic_hat_video_true_ = true
    },
    what_is_tiny_magic_hat_video_make_false() {
      this.what_is_tiny_magic_hat_video_true_ = false
    },

    scroll_to_element(e) {
      this.hamburger_menu_false()
      document.getElementById(e).scrollIntoView();
    },
    dual_route_wrapper(route, inner = null) {
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },


    async go_to_dev_page() {
      // this.hamburger_menu_false()
      await this.$router.push({path: `/hello_landing`})
    },
    async clear_caches_and_update() {
      store.commit('set_update_available', 'pending')
      // localStorage.setItem('update_available', 'pending')
      this.hamburger_menu_false()
      try {
        message_sw("DELETE_SW_CACHE", "Service Worker deleting all SW caches.")
      } catch (error) {
        console.log(error)
      }

      setTimeout(function () {
        message_sw("PRECACHE_ASSETS", "Calling sw to precache.")
        location.reload()
      }, 5000);
    },
    go_to_reenter() {
      this.hamburger_menu_false()
      this.simple_route_prep(this.reentry_route, '01:01:01:213')
    },
    go_to_register() {
      this.hamburger_menu_false()
      this.simple_route_prep('register', '01:01:01:202')
    },
    go_to_login(msg = true) {
      this.hamburger_menu_false()
      this.simple_route_prep('login', '01:01:01:201')
    },

    go_to_find_a_caregiver_page() {
      this.hamburger_menu_false()
      this.simple_route_prep('find-a-caregiver')
    },
    go_to_spyglass_ads_page() {
      this.hamburger_menu_false()
      this.simple_route_prep('spyglass')
    },
    go_to_mfa_register() {
      this.hamburger_menu_false()
      this.simple_route_prep('mfa_register', '01:01:01:204')
    },
    go_to_mfa_login() {
      this.hamburger_menu_false()
      this.simple_route_prep('mfa_login', '01:01:01:203')
    },
    show_me() {
      console.log("show me clicked")
      let analytic = '01:01:13:208'
      this.simple_route_prep('local_agencies', analytic)
    },
    async freetime_landing() {
      console.log("freetime_landing clicked")
      let analytic = '01:01:13:208'
      console.log("running dual route wrapper")
      store.commit('activate_section_for_caregivers', 'freetime')
      await this.dual_route_wrapper('for-caregivers')
    },
    async spyglass_landing() {
      console.log("spyglass_landing clicked")
      let analytic = '01:01:13:208'
      store.commit('activate_section_for_caregivers', 'spyglass')
      await this.dual_route_wrapper('for-caregivers')
    },
    async speedydelivery_landing() {
      console.log("speedydelivery_landing clicked")
      let analytic = '01:01:13:208'
      store.commit('activate_section_for_caregivers', 'speedydelivery')
      await this.dual_route_wrapper('for-caregivers')
    },


    async freetime_landing_agencies() {
      console.log("freetime_landing clicked")
      let analytic = '01:01:13:208'
      store.commit('activate_section_for_agencies', 'freetime')
      console.log("running dual route wrapper")
      await this.dual_route_wrapper('for-agencies')
    },
    async spyglass_landing_agencies() {
      console.log("spyglass_landing clicked")
      store.commit('activate_section_for_agencies', 'spyglass')
      let analytic = '01:01:13:208'
      await this.dual_route_wrapper('for-agencies')
    },
    async speedydelivery_landing_agencies() {
      console.log("speedydelivery_landing clicked")
      store.commit('activate_section_for_agencies', 'speedydelivery')
      let analytic = '01:01:13:208'
      await this.dual_route_wrapper('for-agencies')
    },


    do_nothing() {
      console.log("that was easy.")
    },

    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      analytic("01:01:01:01")
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },

    async que_analytic(message) {
      analytic(message)
    },
    async simple_route_prep(r, analytic_log = null) {
      console.log("in simple route prep")
      this.hamburger_menu_false()
      if (analytic_log) {
        // console.log("Internal Routing log recorded.")
        analytic(analytic_log)
      }
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      console.log(`Going to ${this_route}`)
      await this.$router.push({path: this_route})
    },
    async learn_more() {
      // console.log("Learn more!")
      let analytic = '01:01:01:207'
      await this.simple_route_prep('what-is-freetime', analytic)
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 300, new_w)) {
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        // this.v_scale = .75
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 5000, new_w)) {
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    button_size_multiplier(count) {
      let wid = this.windowWidth
      let mul = 1
      if (count === 4) {
        if (this.num_is_between_inclusive(100, 400, wid)) {
          mul = 1
        } else if (this.num_is_between_inclusive(401, this.desktop_cutoff, wid)) {
          mul = 1.2
        }
      }
      if (count === 5) {
        if (this.num_is_between_inclusive(100, 400, wid)) {
          mul = 1
        } else if (this.num_is_between_inclusive(401, this.desktop_cutoff, wid)) {
          mul = 1.2
        }
      }
      if (count === 6) {
        if (this.num_is_between_inclusive(100, 400, wid)) {
          mul = 1
        } else if (this.num_is_between_inclusive(401, this.desktop_cutoff, wid)) {
          mul = 1.2
        }
      }
      return mul

    },
  },
  computed: {
    ...mapGetters([
      'update_status'
    ]),


    what_is_tiny_magic_hat_video_true() {
      return this.what_is_tiny_magic_hat_video_true_
    },

    // caller_ip(){
    //   return state.store.ip_address
    // },

    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
    windowWidth() {
      return store.state.windowWidth;
    },

    update_available() {
      // return localStorage.getItem('update_available') === true;
      console.log("in update available computed")
      console.log(`Result is ${this.update_status}`)
      console.log(`Type is ${typeof this.update_status}`)
      if (this.update_status === true || this.update_status === 'true') {
        return 'true'
      } else {
        return 'false'
      }
    },


    dynamic_button_text_size() {
      let add_pix = 0
      let base_text_size = 15
      let b_count = this.button_count
      let mul = this.button_size_multiplier(b_count)
      if (b_count === 4) {
        if (mul === 1) {
          add_pix = 2
        } else if (mul === 1.1) {
          add_pix = 3
        } else if (mul === 1.2) {
          add_pix = 5
        } else {
          add_pix = 2
        }
      }
      if (b_count === 5) {
        if (mul === 1) {
          add_pix = 2
        } else if (mul === 1.1) {
          add_pix = 3
        } else if (mul === 1.2) {
          add_pix = 5
        } else {
          add_pix = 2
        }
      }
      if (b_count === 6) {
        if (mul === 1) {
          add_pix = 1
        } else if (mul === 1.1) {
          add_pix = 3
        } else if (mul === 1.2) {
          add_pix = 4
        } else {
          add_pix = 2
        }
      }
      return (base_text_size + add_pix).toString() + "px"
    },

    button_count() {
      let base_list = ['MFA Login', 'MFA Register', 'Login', 'Register']

      if (this.good_token) {
        base_list.push('Logout')
      }
      if (this.reentry_route && this.good_token) {
        base_list.push('Reenter')
      }
      return base_list.length
    },

    dynamic_button_size() {

      let base_count = this.button_count
      let base_size = 80
      let mul = this.button_size_multiplier(base_count)

      if (base_count === 4) {
        base_size = 100
      } else if (base_count === 5) {
        base_size = 90
      } else if (base_count === 6) {
        base_size = 80
      }
      return (base_size * mul).toString() + "px"
    },

    reentry_route() {
      let r = store.getters.reentry_route
      if (r) {
        return r
      } else {
        return null
      }
    },

    good_token() {
      return good_token_now()
    },
    current_v() {
      return current_vtag()
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

  }
}
</script>

<style scoped>
@import '../../common.css';


.home_page {
  min-height: 100vh;

}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}


@media (min-width: 100px) {

  #pixel-to-watch {
    /*position: absolute;*/
    width: 1px;
    height: 1px;
    background-color: transparent;
    /*top: 100px;*/
    /*left: 0;*/
  }

  .check_out_positions {
    text-align: center;
    max-width: 660px;
  }

  .what_is_video_button {
    /*border: 2px solid var(--lauren_gray);*/
  }

  .agency_cards {
    margin-bottom: 50px;
  }

  #logo_top_main {
    width: 38%;
    max-width: 185px;
    padding: 20px;
  }

  .solutions_txt {
    background-color: var(--lauren_gray);
    margin-bottom: 30px;
  }

  #cg_topper_img {
    height: 300px;
  }

  #agencies_topper_img {
    width: 300px;
  }

  #what_is_img {
    width: 98%;
  }

  #what_is_modal_link {
    margin-top: 2em;
  }

  #splash p {
    font-size: 20px;
    margin: 10px;
  }

  #splash h1 {
    font-family: 'Oleo Script Swash Caps', cursive;
    font-size: 40px;
    color: white;
    font-weight: 400;
    margin-top: 40px;
  }

  #caregivers h1 {
    font-family: 'Oleo Script Swash Caps', cursive;
    font-weight: 400;
    font-size: 50px;
    /*color: var(--cyanic-blue);*/
   color: var(--new-primary-purple-deep);
    margin-top: -72px;
    /*width: 310px;*/
  }

  #agencies h1 {
    font-family: 'Oleo Script Swash Caps', cursive;
    font-size: 50px;
    font-weight: 400;
    color: var(--new-primary-purple-deep);
    margin-top: -33px;
  }

  .splash {
    height: 95vh;
  }

  #agencies {
    padding-bottom: 45px;
  }

  #caregivers {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #what_is_tmh_main_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #what_is_tmh {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .go_to_jobs {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  #agencies h2 {
    padding: 1px;
  }

  #agencies h3 {
    padding: 1px;
  }

  #agencies h2 {
    font-family: 'Akshar', sans-serif;
  }

  #agencies h3 {
    font-family: 'Akshar', sans-serif;
  }

  #agencies p, #caregivers p {
    font-size: 20px;
  }

  #caregivers h2 {
    font-family: 'Akshar', sans-serif;
  }

  #caregivers h3 {
    font-family: 'Akshar', sans-serif;
  }

  #what_is_tmh h2 {
    color: var(--new-primary-purple-deep);
    font-size: 30px;
  }

  #what_is_tmh h3 {
    font-family: 'Raleway', sans-serif;
  }

  #what_is_tmh p {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .go_to_jobs h2 {
    color: var(--new-primary-purple-deep);
    font-size: 30px;
  }

  .go_to_jobs h3 {
    font-family: 'Raleway', sans-serif;
  }

  .go_to_jobs p {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .for_cgs {
    background-color: var(--lauren_gray);
  }

  .for_agencies {
    background-color: var(--lauren_gray);
  }

  .tag_line1 {
    margin-top: 0;
    font-size: 45px;
    hyphens: none;
  }

  .tag_line2 {
    font-size: 45px;

  }

}

@media (min-width: 370px) {

  #logo_top_main {
    width: 30%;
  }

  #splash h1 {
    font-size: 45px;
    margin-top: 40px;
  }


}

@media (min-width: 400px) {

  #splash p {
    font-size: 22px;
    margin: 10px;
  }

  #what_is_img {
    width: 80%;
  }


  #splash h1 {
    font-family: 'Oleo Script Swash Caps', cursive;
    font-size: 48px;
    color: white;
    font-weight: 400;
    margin-top: 40px;
  }

  #agencies {
    padding-bottom: 60px;
  }

  #caregivers {
    padding-top: 30px;
  }

  #what_is_tmh {
    padding-top: 40px;
    padding-bottom: 25px;
  }

  .go_to_jobs {
    padding-top: 40px;
    padding-bottom: 25px;
  }

  #what_is_tmh h2 {
    font-size: 32px;
  }

  #what_is_tmh p {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .go_to_jobs h2 {
    font-size: 32px;
  }

  .go_to_jobs p {
    margin-bottom: 15px;
    font-size: 20px;
  }


  .tag_line1 {
    margin-top: 0px;
    font-size: 45px;
    hyphens: none;
  }

  .tag_line2 {
    font-size: 45px;
  }
}

@media (min-width: 500px) {


  .tag_line1 {
    font-size: 60px;
  }

  .tag_line2 {
    font-size: 60px;
  }

  #what_is_tmh {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .go_to_jobs {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}

@media (min-width: 800px) {

  #what_is_img {
    width: 40em;
  }

  .what_is_tmh_text {
    font-size: 25px;
    max-width: 1000px;
  }

  .home_page {
    /*position: relative;*/
    max-height: 80vh;
  }

  .tag_line1 {
    font-size: 80px;
  }

  .tag_line2 {
    font-size: 80px;
  }


}

@media (min-width: 1200px) {

  .splash {
    height: 80vh;
  }

  #what_is_tmh_main_content_container {
    max-width: 2050px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #what_is_img {
    margin-left: 50px;
  }
}


@media (min-width: 1500px) {

  #splash h1 {
    font-size: 80px;
  }

  #splash p {
    font-size: 30px;
  }

  #agencies {
    padding-bottom: 50px;
  }

  #what_is_tmh p {
    font-size: 28px;
  }

  .go_to_jobs p {
    font-size: 28px;
  }

}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 100px;
  }


  #splash h1 {
    font-size: 120px;
  }

  #caregivers h1 {

  }

  #what_is_tmh {
    padding-bottom: 150px;
    padding-top: 150px;
  }

  .go_to_jobs {
    padding-bottom: 150px;
    padding-top: 150px;
  }

  #what_is_tmh h2 {
    font-size: 38px;
  }

  .go_to_jobs h2 {
    font-size: 38px;
  }


}
</style>