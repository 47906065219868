<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <landing_banner></landing_banner>
    <!--    <meta name="og:image" property="og:image" content="https://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"/>-->
    <!--     og_image="http://localhost:8089/img/old_hands.de2922bb.jpg"-->
    <headers
        title="SpyGlass Job Notifications"
        meta_description="Tiny Magic Hat makes it easy to find the perfect caregiving job."
        og_title="SpyGlass Job Notifications"
        og_url="https://tinymagichat.com/spyglass_notifications"
        og_description="Get notified when a caregiving job opens in your neighborhood."
        og_image="http://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"
    ></headers>

    <div class="flex_centered_row ">
      <div class="flex_centered_start_column">


        <section class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/stairs2.svg"
                     alt="Four people holding hands on stairs.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h1 class="text_align_center">Let the job find you.</h1>
              <p class="text_align_center top_text">
                Pick local neighborhoods and Subscribe to get notified!
              </p>
              <div class="flex_centered_row flex_wrap neighborhood_list margin_t_20">
                <indicator_label
                    class="cursor_ptr"
                    v-for="(item, index) of local_neighborhoods" :key=index
                    @click="select_neighborhood(item)"
                    :loaded_message_text=item
                    :loaded_message_text_color="colors.black"
                    :loaded_message_bg="colors.new_gold"
                    loaded_font_size="18px"

                    :ready_message_text=item
                    :ready_message_text_color="colors.black"
                    :ready_message_bg="colors.eli_gray"

                    ready_font_size="18px"
                    :state="check_if_neighborhood_selected(item) ? 'loaded' : 'ready'"
                ></indicator_label>
              </div>

              <basic_text_button
                  class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
                  :hover_color=colors.cyanic_blue
                  :hover_text_color=colors.white
                  :button_state="true"
                  :button_color="colors.new_orange_main"
                  :text_color=colors.white
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="52px"
                  :button_width=button_wid
                  border_rad_pct="0"
                  text="SUBSCRIBE"
                  text_size="14px"
                  :bold="true"
                  event_name="accept_notifications_clicked"
                  @accept_notifications_clicked=accept_notifications
              ></basic_text_button>
            </div>
          </div>
        </section>

        <div class="share_row_wrap flex_centered_row">
          <div class="flex_fully_centered_space_around_row share_row">
            <ShareNetwork
                network="facebook"
                url="tinymagichat.com/spyglass_notifications"
                title="Get notified when a caregiving job opens up in your neighborhood."
                description="SpyGlass is where the best local home care agencies post the highest paying caregiving jobs."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 fb_share"
                   src="@/assets/facebook-share-button-icon.svg"
                   alt="Facebook share icon">
            </ShareNetwork>

            <ShareNetwork
                network="sms"
                url="https://tinymagichat.com/spyglass_notifications"
                title="Get notified when a caregiving job opens up in your neighborhood."
                description="SpyGlass is where the best local home care agencies post the highest paying caregiving jobs."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 mail_share"
                   src="@/assets/sms_icon.svg"
                   alt="Text Message share icon">
            </ShareNetwork>

            <ShareNetwork
                network="email"
                url="tinymagichat.com/spyglass_notifications"
                title="Get notified when a caregiving job opens up in your neighborhood."
                description="Pick the neighborhoods close to your house and when a caregiving job gets posted in one of them, you get a notification on your phone."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 mail_share"
                   src="@/assets/email_icon.svg"
                   alt="Email share icon">
            </ShareNetwork>

          </div>
        </div>


        <!--        <ShareNetwork-->
        <!--            network="facebook"-->
        <!--            url="https://google.com"-->
        <!--            title="This is a test title"-->
        <!--            description="This is a test description."-->
        <!--            quote=""-->
        <!--            hashtags=""-->
        <!--        >-->
        <!--          Share on Facebook-->
        <!--        </ShareNetwork>-->

        <section class="flex_centered_row  top_explainer">
          <div class="flex_centered_column">
            <h2 class="text_align_center">How does it work? Easy.</h2>
            <p>Select nearby neighborhoods and click Subscribe.</p>
            <br>
            <p>When an agency in Tiny Magic Hat's network posts a job in those neighborhoods you're the first to
              know.</p>
          </div>
        </section>

        <section class="cards">
          <home_card2
              class=" margin_t_20 first_card"
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
              icon="bell.svg"
              desc="Job notifications"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_orange_main_light"
          >
            <template v-slot:headline>
              <div>Get Notified</div>
            </template>
            <template v-slot:body>
              <p class="get_notified_text">When you see the job notification on your phone, click to get more details.
              </p>
            </template>
          </home_card2>
          <home_card2
              class=" second_card "
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
              icon="icon_group_black.svg"
              desc="Find a job Easy"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Connect with the Agency</div>
            </template>
            <template v-slot:body>
              <p class="talking">Then you can talk to or message someone at the agency within a few seconds.
              </p>
              <!--              <br>-->
              <!--              <p><strong>*You do need to register your free account first.</strong></p>-->
            </template>
          </home_card2>
        </section>

        <section>
          <div class="flex_centered_row">
            <div class="flex_centered_column">
              <h3 class="mailing_list_header text_primary_purple">Join Our Mailing List</h3>
              <p class="mailing_list_text">We don't send regular emails, so we won't be spamming your inbox,
                but if you want to hear about the latest happenings and important announcements from Tiny Magic Hat,
                this is
                a great way to stay in the know.</p>
              <input_with_label
                  ref="mailing_list_email"
                  class="form_input"
                  label="Email"
                  @emit_state="catch_email"
              ></input_with_label>

              <basic_text_button
                  class="padding_lr_6 padding_tb_6 bottom_button mailing_list_button"
                  :hover_color=colors.cyanic_blue
                  :hover_text_color=colors.white
                  :button_state="true"
                  :button_color="colors.new_orange_main"
                  :text_color=colors.white
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="52px"
                  :button_width=button_wid
                  border_rad_pct="0"
                  text="Join our List"
                  text_size="14px"
                  :bold="true"
                  event_name="join_mailing_list_clicked"
                  @join_mailing_list_clicked=join_mailing_list_clicked
              ></basic_text_button>
            </div>
          </div>


        </section>


        <div class="flex_centered_row">
          <h3 class="learn_more_heading text_primary_purple">Earn Top Pay</h3>

        </div>
        <div class="learn_more_text t">
          <p>Tiny Magic Hat Agencies pay the highest rates in the market with FreeTime.
            Interested? Click FreeTime to learn more.</p>
        </div>
        <br>
        <div class=" flex_centered_row ">

          <div class="flex_centered_column">
            <basic_icon_button
                class="cursor_ptr"
                :ready="true"
                :button_color="colors.cyanic_background"
                :button_color_not_ready="colors.cyanic_background"
                button_height="66px"
                button_width="190px"
                border_rad_pct="0"
                image_path='freetime_primary_purple_text_only.svg'
                image_width="130px"
                alt_text="FreeTime button"
                event_name="what_is_freetime_button_clicked"
                @what_is_freetime_button_clicked="freetime_landing"
            ></basic_icon_button>
          </div>


          <!--          <basic_text_button-->
          <!--              v-if="!mobile_mode"-->
          <!--              class="padding_lr_6 padding_tb_6 what_is_video_button margin_l_20"-->
          <!--              :hover_color=colors.new_orange_main-->
          <!--              :hover_text_color=colors.white-->
          <!--              :button_state="true"-->
          <!--              :button_color="colors.primary_purple"-->
          <!--              :text_color=colors.white-->
          <!--              :button_color_not_ready="colors.eli_gray"-->
          <!--              :button_not_ready_text_color="colors.pennie_gray"-->
          <!--              button_height="52px"-->
          <!--              button_width="190px"-->
          <!--              border_rad_pct="0"-->
          <!--              text="VISIT THE FORUM"-->
          <!--              text_size="17px"-->
          <!--              :bold="true"-->
          <!--              event_name="what_is_freetime_button_clicked"-->
          <!--              @what_is_freetime_button_clicked="forum_landing"-->
          <!--          ></basic_text_button>-->
        </div>
<!--               <h1>Hello world</h1>-->
<!--                <basic_text_button-->
<!--                    class="padding_lr_6 padding_tb_6 bottom_button"-->
<!--                    :hover_color=colors.cyanic_blue-->
<!--                    :hover_text_color=colors.white-->
<!--                    :button_state="true"-->
<!--                    :button_color="colors.new_orange_main"-->
<!--                    :text_color=colors.white-->
<!--                    :button_color_not_ready="colors.eli_gray"-->
<!--                    :button_not_ready_text_color="colors.pennie_gray"-->
<!--                    button_height="52px"-->
<!--                    :button_width=button_wid-->
<!--                    border_rad_pct="0"-->
<!--                    text="local"-->
<!--                    text_size="14px"-->
<!--                    :bold="true"-->
<!--                    event_name="accept_notifications_clicked"-->
<!--                    @accept_notifications_clicked=local_notification_test-->
<!--                ></basic_text_button>-->

<!--                <basic_text_button-->
<!--                    class="padding_lr_6 padding_tb_6 bottom_button"-->
<!--                    :hover_color=colors.cyanic_blue-->
<!--                    :hover_text_color=colors.white-->
<!--                    :button_state="true"-->
<!--                    :button_color="colors.cyanic_blue"-->
<!--                    :text_color=colors.white-->
<!--                    :button_color_not_ready="colors.eli_gray"-->
<!--                    :button_not_ready_text_color="colors.pennie_gray"-->
<!--                    button_height="52px"-->
<!--                    :button_width=button_wid-->
<!--                    border_rad_pct="0"-->
<!--                    text="backend push"-->
<!--                    text_size="14px"-->
<!--                    :bold="true"-->
<!--                    event_name="backend_test_clicked"-->
<!--                    @backend_test_clicked=server_notification_test-->
<!--                ></basic_text_button>-->

<!--                <basic_text_button-->
<!--                    class="padding_lr_6 padding_tb_6 bottom_button"-->
<!--                    :hover_color=colors.cyanic_blue-->
<!--                    :hover_text_color=colors.white-->
<!--                    :button_state="true"-->
<!--                    :button_color="colors.cyanic_blue"-->
<!--                    :text_color=colors.white-->
<!--                    :button_color_not_ready="colors.eli_gray"-->
<!--                    :button_not_ready_text_color="colors.pennie_gray"-->
<!--                    button_height="52px"-->
<!--                    :button_width=button_wid-->
<!--                    border_rad_pct="0"-->
<!--                    text="delete all cache"-->
<!--                    text_size="14px"-->
<!--                    :bold="true"-->
<!--                    event_name="static_delete"-->
<!--                    @static_delete=static_delete-->
<!--                ></basic_text_button>-->


      </div>

    </div>


  </div>
  <outside_footer_landing class="footer_c"></outside_footer_landing>

</template>
<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import indicator_label from "@/components/parts/indicator_label";
import basic_icon_button from "@/components/parts/basic_icon_button";
import headers from "@/components/parts/headers";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  handle_notification_subscription,
  showLocalNotification,
  scroll_to_top,
  mailing_list_signup,
  be_main_address,
  parse_internal_json,
  get_ip_from_header,
  detect_connection_speed,
  message_sw, scroll_to_element, dual_route, validate_email
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "notfications_campaign",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    this.id_ = get_uuid()
    this.scroll_to_top()

    this.state = 'SC'
    this.market = 'Myrtle Beach'
    // console.log("Make neighborhood call......")
    await this.get_local_neighborhoods(this.market)
    this.run_window_width_adjustments(this.windowWidth)
    let ip_string = false
    ip_string = await get_ip_from_header()

    await detect_connection_speed()
  },


  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    indicator_label,
    basic_icon_button,
    headers
  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      notification_soft_perm_: false,
      local_neighborhoods: [],
      selected_neighborhoods: [],
      bad_email: true,
      state: "",
      market: "",
      name: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 1000,
      input: {
        email: "",
      }
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async join_mailing_list_clicked() {
      console.log("You joined the mailing list")
      let res = await mailing_list_signup(this, this.input.email, 'caregiver').then(function (res) {
        if (res[0] === "OK") {
          console.log("Email mailing list sign up was successful.")
          let context = res[1]
          context.input.email = ""
          context.$refs.mailing_list_email.reset_state()
          return true
        } else {
          console.log("Email mailing list sign up did not go as planned.")
          return false
        }
      })


    },
    catch_email(content) {
      // if (!validate_email(content)) {
      //   console.log("this email is bad")
      //   this.bad_email = true
      // }
      this.input.email = content.trim().slice(0, 100)
      // this.bad_email = false

    },
    async freetime_landing() {
      // localStorage.setItem('from_route','spyglass')
      // console.log("freetime_landing clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      store.commit('activate_section_for_caregivers', 'freetime')
      await this.dual_route_wrapper('for-caregivers')
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },


    async static_delete() {

      store.commit('set_update_available', 'pending')
      // localStorage.setItem('update_available', 'pending')
      this.hamburger_menu_false()
      try {
        message_sw("DELETE_SW_CACHE", "Service Worker deleting all SW caches.")
        setTimeout(function () {
          message_sw("PRECACHE_ASSETS", "Calling sw to precache.")
          location.reload()
        }, 5000);
      } catch (error) {
        console.log(error)
      }

    },

    check_if_neighborhood_selected(item) {
      return this.sel_neighborhoods.includes(item);
    },

    select_neighborhood(item, index) {
      console.log(`Just picked ${item} with index of ${index}`)
      if (this.selected_neighborhoods.includes(item)) {
        //Remove the item, change the styling
        this.selected_neighborhoods = this.selected_neighborhoods.filter(e => e !== item);
        // let neighborhood = document.getElementById(`n-${index}`)
        // neighborhood.style.background = colors.new_orange_main

      } else {
        //Remove the item, change the styling
        this.selected_neighborhoods.push(item)
        // let neighborhood = document.getElementById(`n-${index}`)
        // neighborhood.style.background = colors.red
      }


      // this.$refs.neighborhood_[index].button_color = colors.whitesmoke

    },
    get_uuid() {
      return this.get_uuid()
    },
    async get_local_neighborhoods(market = 'Myrtle Beach') {
      console.log("STARTING TO GET THE NEIGHBORHOODS...........................")
      let target = be_main_address('geography') + "neighborhood_listing_open"
      // console.log("create new user address")
      console.log(target)

      // console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, {
        filter: market,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Something went wrong at the server. Please reload the page.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          return null
        }
      }).then((response) => {
            console.log("Got good response from geography.");
            console.log(response.data);
            // store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              console.log("Here are the neighborhoods.")
              console.log(response.data.data)
              this.local_neighborhoods = response.data.data
              // this.local_neighborhoods.push("Tester1 aoeu")
              // this.local_neighborhoods.push("Tester2 htee ")
              // this.local_neighborhoods.push("Tester3hnhn")
              // this.local_neighborhoods.push("Tester4 nthoeu hntoeu ")
              // this.local_neighborhoods.push("Tester5 theu")
              // this.local_neighborhoods.push("Tester6 thoa")
              // this.local_neighborhoods.push("Tester7")
              // this.local_neighborhoods.push("Tester8")
              // this.local_neighborhoods.push("Tester9 ahc ohce")
              // this.local_neighborhoods.push("Tester10 thec")
            } else {
              console.log("Something went wrong getting the neighborhood listing.")
              console.log(response.data)
            }
          }
      )
    },
    async accept_notifications() {

      if (this.selected_neighborhoods.length === 0) {
        store.commit('notification_enqueue', "You need to select at least one neighborhood for notifications.")
        return null
      }

      let market = 'Myrtle Beach'
      let state = 'SC'
      let country = 'US'
      // console.log('setting soft notifications_ to true in method')
      let subscription_info = await handle_notification_subscription(true)
      // console.log(`The subscription info is ${subscription_info}`)

      let values = {
        country: country,
        state: state,
        market: market,
        neighborhoods: this.selected_neighborhoods,
        subscription_info: subscription_info,
      }

      console.log('sending over the following dict.....')
      console.log(values)

      let res = await this.save_notification_subscription(values)

    },

    async save_notification_subscription(params) {
      let endpoint = be_main_address('main_api') + 'save_notifications_subscription'
      let data1 = {
        country: params['country'],
        market: params['market'],
        neighborhoods: params['neighborhoods'],
        state: params['state'],
        subscription_info: params['subscription_info']

      }
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          // let message = parse_internal_json(response.data)['payload']['content']['message']
          let message = parse_internal_json(response.data)['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          let message = parse_internal_json(response.data)['message']
          // let m = "That was easy! Now you will get a notification ever time a caregiving job opens up in your " +
          //     "selected neighborhoods."
          store.commit('notification_enqueue', message)
          this.selected_neighborhoods = []
          return true
        }
      }, (error) => {
        console.log('Error on save notification subscription.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong with your subscription. " +
            "Please try again.")
      });
    },

    async local_notification_test() {
      // const options = {
      //   body: 'Simple piece of body text.\nSecond line of body text 👍'
      // };
      // let body = 'Check out the posting @\nhttps://tinymagichat.com/spyglass'

      let body = 'Agency FreeTime Rate: $18.21/hr'
      await showLocalNotification("Caregiver job in Murrells Inlet just posted!", body)

    },
    async server_notification_test() {
      // const options = {
      //   body: 'Simple piece of body text.\nSecond line of body text 👍'
      // };
      // let body = 'Check out the posting @\nhttps://tinymagichat.com/spyglass'
      let title = 'Hello Wyatt.'
      let body = 'Hope all is well today.'
      let body_obj = {
        'title': title,
        'body': body
      }
      let body_json = JSON.stringify(body_obj)
      let target = be_main_address('main_api') + "push_demo"
      let data_obj = {
        url: "https://tinymagichat.com/contact",
        status: true
      }
      let data_json = JSON.stringify(data_obj)
      await axios.post(target, {
        neighborhood: "Murrells Inlet",
        body: body_json,
        data: data_json,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad Push Test Attempt.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )

    },
    tester() {
      // console.log("button was pressed.")
    },
    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_home_carousel_quotes
    },
    video_modal_false() {
      this.video_modal.state = false
    },

    video_modal_true() {
      console.log("changing video modal to true")
      this.video_modal.state = true
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.button_wid_ = 250
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.button_wid_ = 300
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    // hamburger_menu_toggle() {
    //   this.show_hamburger_menu_ = !this.show_hamburger_menu_
    // },
    //
    // hamburger_menu_false() {
    //   this.show_hamburger_menu_ = false
    // },

    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },

  },
  computed: {
    id() {
      return this.id_
    },
    sel_neighborhoods() {
      return this.selected_neighborhoods
    },

    notification_soft_perm() {
      return this.notification_soft_perm_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },
    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../../../common.css';


/*header {*/
/*  grid-area: top;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

/*figure {*/
/*  margin: 0;*/
/*}*/


/*form label {*/
/*  font-family: 'Raleway', sans-serif;*/
/*  font-weight: 800;*/
/*  color: var(--black);*/
/*}*/


/*form label[for=user-name] {*/
/*  margin-top: 2vh;*/
/*}*/

/*form input {*/
/*  margin-bottom: 2.25vh;*/
/*  font-size: 6vw;*/
/*  padding-left: 17px;;*/
/*  font-family: 'Raleway', sans-serif;*/
/*  border-radius: 2em;*/
/*  height: 22px;*/
/*  width: 50vw;*/
/*}*/


@media (min-width: 100px) {
  
  .share_row_wrap{
    width: 100vw;
     background: var(--lauren_gray);
     margin-bottom: 60px;
  }
  .share_row{
    width: 90vw;
    max-width: 450px;

   
  }
  .fb_share {
    width: 80px;
  }

  .mail_share {
    width: 40px
  }

  .mailing_list_text {
    max-width: 800px;
    line-height: 2rem;
    margin-bottom: 30px;
    margin-top: -30px;
  }

  .mailing_list_button {
    margin-top: 40px;
  }

  .learn_more_heading {
    /*margin-top: -50px;*/
    max-width: 500px;
    text-align: center;
  }

  .learn_more_text {
    text-align: center;
    max-width: 500px;
    margin-bottom: 20px;
    margin-top: -30px;
    line-height: 2rem;
  }

  h1 {
    font-size: 40px;
    color: white;
    width: 75%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 32px;
    color: var(--primary-purple);
    width: 75%;
    margin-bottom: 30px;
  }

  .top_explainer p {
    text-align: center;
  }

  .top_explainer {
    max-width: 850px;
    margin-top: 10px;
  }

  .get_notified_text {
    text-align: center;
  }

  .talking {
    text-align: center;
  }

  .neighborhood_list {
    max-width: 90%;
  }

  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    /*margin-top: -50px;*/
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {

  /*.top_explainer {*/
  /*  margin-top: 80px;*/
  /*}*/
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .learn_more {
    margin-top: unset;
    /*max-width: ;*/
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 450px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {


  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 390px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 500px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0px;
  }

  .top_text {
    max-width: 800px;
  }


}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 150px;
  }

  .fb_share {
    width: 100px;
  }

  .mail_share {
    width: 50px
  }


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>