<template>

  <transition :name="transitionEffect" class="">
    <div class="carousel_item flex_centered_row b_rad_20px" v-show="current_slide===index">
      <div class="inner_item font_raleway font_weight_800 b_rad_10px flex_centered_column wid_100">
        <div class="text_content">
          <div>{{ item.text1 }}</div>
        </div>
        <div class="text_content text_content_name">
          <div>  {{ item.text2 }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "carousel_item",
  props: [
    "item",
    "current_slide",
    "index",
     "direction"
  ],
  computed: {
     transitionEffect() {
      return this.direction === "right" ? "slide-out" : "slide-in";
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {

  .text_content{
    color: white;
    /*text-shadow: 1px 1px var(--wyatt_gray) rgba(0,0,0,0.1);*/
    /*text-shadow: 0px 5px 5px rgba(0,0,0,0.1),*/
    /*             10px 10px 5px rgba(0,0,0,0.05),*/
    /*             -10px 10px 5px rgba(0,0,0,0.05);*/
  }

  .text_content_name{
    margin-top: 15px;
  }
  .inner_item{
    padding: 0px 25px 25px 25px;
    white-space: pre-wrap;
  }
  .carousel_item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 1s ease-in-out;
}
.slide-in-enter-from {
  transform: translateX(-100%);
}
.slide-in-leave-to {
  transform: translateX(100%);
}
.slide-out-enter-from {
  transform: translateX(100%);
}
.slide-out-leave-to {
  transform: translateX(-100%);
}

}

@media (min-width: 800px) {
  .text_content{
    padding-left: 125px;
    padding-right: 125px;
  }
  .text_content_name{
    margin-top: 15px;
    font-size: 25px;
  }
}

</style>