<template>

  <spinner
      :spinner_up="spinner_up"
  ></spinner>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_primary_purple">
    <div class="label_bolder text_white">Mother PDA</div>
  </div>

  <!--  <div class="screen_block margin_tb_20 bg_primary_purple_mid_light_responsive margin_auto">-->

  <four_texts_dropdown_and_launch
      ref="four_text_child"
      class="font_raleway font_weight_800 bg_whitesmoke margin_b_20"
      :uuid="four_text_and_launch_uuid"
      label="Create a PDA"
      :label_color=colors.black
      placeholder1="PDA Name"
      placeholder2="Email email@example.com"
      placeholder3="Phone 1112223333"
      placeholder4="User to make owner"
      button_text="Create"
      :button_state_start="button_state_start"
      :toggle_start_value="toggle_start_value"
      :state_disabled="state_disabled"
      @precheck="four_text_precheck"
      event_name="create_pda_assign_owner"
      @create_pda_assign_owner="four_text_launch"
  >
  </four_texts_dropdown_and_launch>

  <five_texts_dropdown_and_launch
      ref="five_text_child"
      class="font_raleway font_weight_800 bg_whitesmoke margin_b_20"
      :uuid="five_text_and_launch_uuid"
      label="Create a PDA"
      :label_color=colors.black
      placeholder1="PDA Name"
      placeholder2="Email email@example.com"
      placeholder3="Phone 1112223333"
      placeholder4="User to make owner"
      placeholder5="Founding Year"
      button_text="Create"
      :button_state_start="button_state_start"
      :toggle_start_value="toggle_start_value"
      :state_disabled="state_disabled"
      @precheck="five_text_precheck"
      event_name="create_pda_assign_owner"
      @create_pda_assign_owner="five_text_launch"
  >
  </five_texts_dropdown_and_launch>


  <market_associator
      class="margin_auto bg_whitesmoke font_weight_800 font_raleway margin_b_20"
      label='Associate PDA to Market'
      ref="pda_to_market_associator"
      :label_color=colors.black
      selection_title="Select PDA"
      button_text="Break"
      :hover_coler="colors.new_orange_main"
      :toggle_state="ready_to_assoc_pda_to_market"
      :country_values="countries_list"
      :state_values="states_list"
      :market_values="markets_list"
      :pda_values="all_pdas"
      pda_selection_event="pda_selection_event"
      @pda_selection_event="remember_pda"
      country_selection_event="country_selection_event"
      @country_selection_event="remember_country"
      state_selection_event="state_selection_event"
      @state_selection_event="remember_state"
      market_selection_event="market_selection_event"
      @market_selection_event="remember_market"
      associate_pda_to_market_event="associate_pda_to_market_event"
      @associate_pda_to_market_event="associate_pda_to_market"
  ></market_associator>


  <select_and_launch_2
      class="margin_auto bg_whitesmoke font_weight_800 font_raleway margin_b_20"
      label='Disassociate PDA Owner(s)'
      :label_color=colors.black
      selection_title="Select PDA"
      button_text="Break"
      :values="all_pdas"
      event_name="disassociate_owners"
      @disassociate_owners="disassociate_owners"
  ></select_and_launch_2>

  <select_and_launch_2
      class="margin_auto bg_whitesmoke font_weight_800 font_raleway margin_b_20"
      label='Disassociate All PDA Admins'
      :label_color=colors.black
      selection_title="Select PDA"
      button_text="Break"
      :values="all_pdas"
      event_name="disassociate_admins"
      @disassociate_admins="disassociate_admins"
  ></select_and_launch_2>

  <type_select_and_launch class="margin_auto margin_tb_10 bg_whitesmoke font_weight_800 font_raleway margin_b_20"
                          ref="type_select_and_launch_child"
                          label="Promote User to PDA Owner"
                          :label_color=colors.black
                          selection_title1="User Name"
                          selection_title2="Select PDA"
                          :text_entry="owner_name_to_send"
                          button_text="Promote"
                          :toggle_start_value="toggle_start_value"
                          :values="all_pdas"
                          precheck_event_name="precheck_values"
                          @precheck="promote_to_owner_precheck"

                          event_name="promote_to_owner"
                          @promote_to_owner="promote_to_pda_owner"
  >
  </type_select_and_launch>

</template>

<script>
/* eslint-disable */
import type_select_and_launch from "@/components/parts/type_select_and_launch";
import four_text_and_launch from "@/components/parts/four_text_and_launch";
import four_texts_dropdown_and_launch from "@/components/parts/four_texts_dropdown_and_launch";
import five_texts_dropdown_and_launch from "@/components/parts/five_texts_dropdown_and_launch.vue";
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import market_associator from "@/components/orgs/tmh_admin/admin_local_components/market_associator";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import axios from "axios";
import {
  add_class_to_body, desktop_size, be_main_address,
  parse_internal_json,
  prime_socket,
  receive_over_socket,
  remove_class_to_body, user_exists_check,
  get_uuid, validate_phonenumber, validate_email, validate_basic_name, colors
} from "@/library";
import input_with_label from "@/components/parts/input_with_label.vue";

export default {
  name: "tmh_mother_pda_controls",
  components: {
    input_with_label,
    type_select_and_launch,
    four_text_and_launch,
    select_and_launch_2,
    market_associator,
    spinner,
    four_texts_dropdown_and_launch,
    five_texts_dropdown_and_launch
  },
  data() {
    return {
      colors: colors,
      countries_list_: [],
      states_list_: [],
      markets_list_: [],
      place: {
        country: "",
        state: "",
        market: "",
        neighborhood: "",
        pda: ""
      },
      all_pdas_: [],
      // promote_to_owner_toggle_increment_: 0,
      owner_name_to_send: "",

      button_state_start: false,
      toggle_start_value: false,
      state_disabled: true,

      four_text_and_launch_uuid: get_uuid(),
      five_text_and_launch_uuid: get_uuid(),
      // promote_to_owner_id: getRandomInt(1000).toString()
      // promote_to_owner_precheck_event_name: "pre_check_" + this.promote_to_owner_id
    }
  },
  async beforeMount() {
    // await this.local_view_update_all()
    store.commit('mother_pda_dropbox_update_against_backend', true)
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    await this.pda_listing()
  },

  methods: {
    do_nothing() {
      console.log("It seems that I was busy doin' somethin' next ta nothin', but diffrent than the day before.")
    },
    forget_place() {
      this.place.neighborhood = ""
      this.place.market = ""
      this.place.state = ""
      this.place.country = ""
      this.place.pda = ""
    },
    refresh_country_list() {
      this.countries_list_ = this.get_countries()
    },
    reset_pda_to_market_widget() {
      this.forget_place()
      this.refresh_country_list()
      this.$refs.pda_to_market_associator.reset();
    },
    async associate_pda_to_market() {

      let target_url = be_main_address('pda_mother') + 'associate_to_market'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        country: this.place.country,
        state: this.place.state,
        market: this.place.market,
        pda: this.place.pda
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('notification_enqueue', `${this.place.pda} has been linked to ${this.place.market}.`)
                this.reset_pda_to_market_widget()
                // this.$refs.country_create.reset()
                // this.forget_place()
                // this.reset_all_lists()
                this.refresh_country_list()
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },
    remember_pda(state) {
      console.log("inside remember pda")
      console.log(state)
      this.place.pda = state
      this.get_countries()
    },

    remember_country(state) {
      console.log("inside remember country")
      console.log(state)
      this.place.country = state
      this.states_list_ = this.get_states(this.place.country)
    },

    remember_state(state) {
      console.log("inside remember state")
      console.log(state)
      this.place.state = state
      this.markets_list_ = this.get_markets(this.place.state)
    },

    remember_market(state) {
      console.log("inside remember market")
      console.log(state)
      this.place.market = state
      this.neighborhood_list_ = this.get_neighborhoods(this.place.market)
    },

    async get_countries() {
      let target_url = be_main_address('geography') + 'country_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.countries_list_ = response.data.data.sort()
                console.log("Here is the list of countries")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    async get_states(filter) {
      let target_url = be_main_address('geography') + 'state_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.states_list_ = response.data.data.sort()
                console.log("Here is the list of states")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    async get_markets(filter) {
      let target_url = be_main_address('geography') + 'market_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.markets_list_ = response.data.data.sort()
                console.log("Here is the list of markets")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async get_neighborhoods(filter) {
      let target_url = be_main_address('geography') + 'neighborhood_listing'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        filter: filter
      }

      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                //got good response
                this.neighborhood_list_ = response.data.data.sort()
                console.log("Here is the list of neighborhoods")
                console.log(response.data.data)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    validateAndFormatYear(input) {
      // Convert the input to an integer
      const year = parseInt(input, 10);

      // Check if the year is a 4-digit number between 1900 and 2100
      if (!isNaN(year) && year >= 1900 && year <= 2100) {
        return year.toString();
      } else {
        return false;
      }
    },

    async five_text_precheck(pda_name, pda_email, pda_phone, pda_owner, founding, uuid) {
      // store.commit('set_spinner_state', true)
      // let precheck_result = false
      if (uuid !== this.five_text_and_launch_uuid) {
        console.log("precheck event from four texts has different uuid that provided originally.")
      }

      let bad_pda_name_message = `${pda_name} is not valid. Use only upper and
      lowercase letters, numbers and underscore.`
      if (!validate_basic_name(pda_name)) {
        store.commit('error_enqueue', bad_pda_name_message)
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        return false
      } else {
        //   pass
      }

      let bad_email_message = `${pda_email} is not a valid email address.`
      if (!validate_email(pda_email)) {
        store.commit('error_enqueue', bad_email_message)
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        return false
      } else {
        //   pass
      }

      let bad_phone_message = `${pda_phone} is not a valid. Format phone numbers like: '1112223456'`
      if (!validate_phonenumber(pda_phone)) {
        store.commit('error_enqueue', bad_phone_message)
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        return false
      } else {
        // this.launcher_armed_ = true
      }

      // console.log("check if pda candidate already exists")
      await this.pda_name_check(pda_name)
      // console.log(`back from if candidate exists with value of ${candidate_already_exists}`)
      if (store.getters.candidate_already_exists) {
        //  candidate already exists
        store.commit('error_enqueue', this.pda_name_already_used(pda_name))
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        store.commit('set_spinner_state', false)
        return false
      } else {
        //   pass
      }

      let bad_founding_year = `${founding} is not a valid year`
      if (!this.validateAndFormatYear(founding)) {
        store.commit('error_enqueue', bad_founding_year)
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        return false
      } else {
        //   pass
      }

      //    A function that calls (should be mother user) to see if a user is on the board
      // console.log(`User around check for ${pda_owner}`)
      let state_depot_mutation = "mother_pda_update_user_exists"
      await user_exists_check(pda_owner, state_depot_mutation)
      // console.log("back form user_around with value of")
      let user_around = store.getters.user_exists
      // console.log(user_around)
      if (!user_around) {
        // console.log(`${pda_owner} does not exist on the graph.`)
        let no_such_user = `${pda_owner} does not exist on the graph.`
        store.commit('error_enqueue', no_such_user)
        this.$refs.five_text_child.toggle_state_to_false();
        this.$refs.five_text_child.button_state_to_false();
        store.commit('set_spinner_state', false)
        return false
      } else {
        //   pass
      }
      store.commit('set_spinner_state', false)
      this.$refs.five_text_child.button_state_to_true();

    },

    async five_text_launch(new_pda_name, email, phone, owner, founding_year, timezone) {

      store.commit("notification_enqueue", `${new_pda_name},${email},${phone},${owner},${founding_year},${timezone}`)
      return null

      console.log('&&&&&&******&&&&&&&&&&**********INSIDE FOUR TEXT AND LAUNCH')
      console.log(new_pda_name)
      console.log(email)
      console.log(phone)
      console.log(owner)
      console.log(timezone)
      console.log(founding_year)
      store.commit('set_spinner_state', true)
      await this.create_pda(new_pda_name, email, phone, timezone, founding_year)
      await this.promote_to_pda_owner(owner, new_pda_name)
      store.commit('mother_pda_dropbox_update_against_backend', true)
      store.commit('set_spinner_state', false)
    },


    async four_text_precheck(pda_name, pda_email, pda_phone, pda_owner, uuid) {
      store.commit('set_spinner_state', true)
      // let precheck_result = false
      if (uuid !== this.four_text_and_launch_uuid) {
        console.log("precheck event from four texts has different uuid that provided originally.")
      }

      let bad_pda_name_message = `${pda_name} is not valid. Use only upper and
      lowercase letters, numbers and underscore.`
      if (!validate_basic_name(pda_name)) {
        store.commit('error_enqueue', bad_pda_name_message)
        this.$refs.four_text_child.toggle_state_to_false();
        this.$refs.four_text_child.button_state_to_false();
        return false
      } else {
        //   pass
      }

      let bad_email_message = `${pda_email} is not a valid email address.`
      if (!validate_email(pda_email)) {
        store.commit('error_enqueue', bad_email_message)
        this.$refs.four_text_child.toggle_state_to_false();
        this.$refs.four_text_child.button_state_to_false();
        return false
      } else {
        //   pass
      }

      let bad_phone_message = `${pda_phone} is not a valid. Format phone numbers like: '1112223456'`
      if (!validate_phonenumber(pda_phone)) {
        store.commit('error_enqueue', bad_phone_message)
        this.$refs.four_text_child.toggle_state_to_false();
        this.$refs.four_text_child.button_state_to_false();
        return false
      } else {
        // this.launcher_armed_ = true
      }

      // console.log("check if pda candidate already exists")
      await this.pda_name_check(pda_name)
      // console.log(`back from if candidate exists with value of ${candidate_already_exists}`)
      if (store.getters.candidate_already_exists) {
        //  candidate already exists
        store.commit('error_enqueue', this.pda_name_already_used(pda_name))
        this.$refs.four_text_child.toggle_state_to_false();
        this.$refs.four_text_child.button_state_to_false();
        store.commit('set_spinner_state', false)
        return false
      } else {
        //   pass
      }

      //    A function that calls (should be mother user) to see if a user is on the board
      // console.log(`User around check for ${pda_owner}`)
      let state_depot_mutation = "mother_pda_update_user_exists"
      await user_exists_check(pda_owner, state_depot_mutation)
      // console.log("back form user_around with value of")
      let user_around = store.getters.user_exists
      // console.log(user_around)
      if (!user_around) {
        // console.log(`${pda_owner} does not exist on the graph.`)
        let no_such_user = `${pda_owner} does not exist on the graph.`
        store.commit('error_enqueue', no_such_user)
        this.$refs.four_text_child.toggle_state_to_false();
        this.$refs.four_text_child.button_state_to_false();
        store.commit('set_spinner_state', false)
        return false
      } else {
        //   pass
      }
      store.commit('set_spinner_state', false)
      this.$refs.four_text_child.button_state_to_true();

    },

    async four_text_launch(new_pda_name, email, phone, owner, timezone) {

      // console.log('&&&&&&******&&&&&&&&&&**********INSIDE FOUR TEXT AND LAUNCH')
      // console.log(new_pda_name)
      // console.log(email)
      // console.log(phone)
      // console.log(owner)
      // console.log(timezone)
      store.commit('set_spinner_state', true)
      await this.create_pda(new_pda_name, email, phone, timezone)
      await this.promote_to_pda_owner(owner, new_pda_name)
      store.commit('mother_pda_dropbox_update_against_backend', true)
      store.commit('set_spinner_state', false)
    },

    async promote_to_owner_precheck(owner_name) {
      let state_depot_mutation = "mother_pda_update_user_exists"
      await user_exists_check(owner_name, state_depot_mutation)
      let user_is_here = store.getters.user_exists
      console.log(`User ${owner_name} is here check: ${user_is_here}.`)
      if (!user_is_here) {
        console.log("The user did not exist.")
        let message = `${owner_name} is not in the database.`
        store.commit('notification_enqueue', message)
        this.$refs.type_select_and_launch_child.toggle_state_to_false()
        return null
      } else {
        console.log("attempt to toggle promote button")
        this.$refs.type_select_and_launch_child.button_state_to_true()
      }
      store.commit(state_depot_mutation, "")
    },

    run_window_width_adjustments(new_w) {
      // if (new_w >= 280) {
      //   this.mobile_mode_x = true
      // }
      //
      if (new_w < 450) {
        // remove_class_to_body('content_no_radial')
        remove_class_to_body('content_large_screen')
      }
      if (new_w >= 450) {

        remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        // this.mobile_mode_x = true
      }
      // if (new_w >= 375) {
      //   // message = 'The window width >= 375'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 640) {
      //   // message = 'The window width >= 640'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w <= desktop_size) {
        remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        this.mobile_mode_x = true
        const matches = document.querySelectorAll(".screen_block_no_shadows");
        matches.forEach(function (match) {
          match.classList.remove('screen_block_no_shadows');
          match.classList.add('screen_block');
        });

      }
      if (new_w >= desktop_size) {
        // remove_class_to_body('content_no_radial')
        add_class_to_body('content_large_screen')
        this.mobile_mode_x = false

        // const matches = document.querySelectorAll(".screen_block");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block');
        //   match.classList.add('screen_block_no_shadows');
        // });

      }
    },

    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      if (update) {
        store.commit('mother_pda_dropbox_update_against_backend', true)
      }
    },

    async local_view_update_all() {
      await this.pda_listing()
    },

    async disassociate_admins(pda) {
      // let pda_rank_list = ['owner', 'admin0', 'admin1']
      let target_url = be_main_address('pda_mother') + 'disassociate_admins'
      // let target_url = 'http://192.168.1.10:8083/pda_mother/disassociate_admins'
      return this.disassociate_users_from_pda(pda, target_url)
    },

    async disassociate_owners(pda) {
      // let pda_rank_list = ['owner']
      let target_url = be_main_address('pda_mother') + 'disassociate_owners'
      // let target_url = 'http://192.168.1.10:8083/pda_mother/disassociate_owners'
      let message = await this.disassociate_users_from_pda(pda, target_url)
      console.log("Message returned from server is...")
      console.log(message)
      // return this.disassociate_users_from_pda(pda, target_url)
    },

    async disassociate_users_from_pda(pda, url) {
      let target_url = url
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        pda: pda
      }

      // console.log("making the name check call")
      await axios.post(target_url, data1)
          .then((response) => {
            console.log("pda disassociation results")
            console.log(response)
            if (response.statusText === "OK" | response.status === 200) {
              if (response.data['error'] === true) {
                // console.log("Server says there was an error with the name check call.")
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                console.log("PDA users disassociated")
                console.log(response)
                store.commit('notification_enqueue', response.data['message'])
                return response.data['message']
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async pda_listing() {
      // let target_url = 'http://192.168.1.10:8083/pda_mother/pda_list'
      let target_url = be_main_address('pda_mother') + 'pda_list'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }

      // console.log("making the name check call")
      await axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              if (response.data['error'] === true) {
                // console.log("Server says there was an error with the name check call.")
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                store.commit('mother_pda_update_all_pdas_list', response.data)
                // console.log(response['data'])
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async promote_to_pda_owner(owner_name, pda_name) {

      // let target_url2 = 'http://192.168.1.10:8083/pda_mother/promote_to_owner'
      let target_url2 = be_main_address('pda_mother') + 'promote_to_owner'
      let data2 = {
        name: owner_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        caller: store.getters.my_name,
        pda: pda_name,
      }

      await axios.post(target_url2, data2)
          .then((response) => {
            // console.log("results from owner promotion call.")
            // console.log(response)
            if (response.statusText === "OK" | response.status === 200) {
              let message = `${owner_name} has been promoted to the owner
              rank of ${pda_name}.`
              store.commit('notification_enqueue', message)
              this.$refs.type_select_and_launch_child.reset()
              return null
            } else {
              console.log("SERVER ERROR")
              console.log(response)
              if (response.data['error'] === true) {
                // console.log("Server says there was an error with the name check call.")
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              }
            }
          })
    },

    async create_pda(new_pda_name, email, phone, timezone, founding_year) {
      console.log(`PDA ${new_pda_name} is to be created with ${email}, ${phone} and ${timezone} .`)
      this.$refs.four_text_child.reset();
      this.$refs.five_text_child.reset();
      // let target_url = 'http://192.168.1.10:8083/pda_mother/create_new_pda'
      let target_url = be_main_address('pda_mother') + 'create_new_pda'
      let data1 = {
        name: new_pda_name,
        email: email,
        phone_number: phone,
        timezone: timezone,
        founding_year: founding_year,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        caller: store.getters.my_name,
      }

      await axios.post(target_url, data1)
          .then((response) => {
            // console.log(response)
            if (response.statusText === "OK" | response.status === 200) {
              try {
                if (response.data['error'] === true) {
                  // console.log("Server says there was an error with the name check call.")
                  let message = parse_internal_json(response.data)['payload']['content']['message']
                  store.commit('error_enqueue', message)
                }
              } catch (error) {
                // console.log("PDA creation results from server")
                // console.log(response)
                let message = `${new_pda_name} has been created.`
                store.commit('notification_enqueue', message)
                this.pda_listing()
                return null
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
      // await this.promote_to_pda_owner(new_pda_name,new_owner_name)
      // store.commit('mother_pda_dropbox_update_against_backend', true)

    },

    async create_pda_assign_owner(new_pda_name, new_owner_name, email, phone) {

      await this.create_pda(new_pda_name, new_owner_name, email, phone)
      await this.promote_to_pda_owner(new_pda_name, new_owner_name)
      store.commit('mother_pda_dropbox_update_against_backend', true)

    },

    pda_name_already_used(pda) {
      return `The name ${pda} is already used.  Choose another name.`
    },

    async pda_name_check(pda) {
      // let target_url = 'http://192.168.1.10:8083/pda_mother/pda_name_check'
      let target_url = be_main_address('pda_mother') + 'pda_name_check'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        pda_candidate: pda
      }

      // console.log("making the name check call")
      await axios.post(target_url, data1)
          .then((response) => {
            // console.log("pda name check results")
            // console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                // console.log("Server says there was an error with the name check call.")
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                // console.log("PDA name check results from server")
                // console.log(response)
                // console.log(response.data['result'])
                // return JSON.stringify(response.data['result'])
                store.commit('mother_pda_update_candidate_already_exists',
                    response.data['result'])
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },
  },
  computed: {
    spinner_up() {
      return store.getters.get_spinner_state
    },
    ready_to_assoc_pda_to_market() {
      console.log("current values")
      console.log(this.place.country, this.place.state, this.place.market, this.place.pda)
      if (this.place.country !== "" && this.place.state !== "" && this.place.market !== "" && this.place.pda !== "") {
        console.log("toggle evaluates to false")
        return false
      } else {
        console.log("toggle evaluates to true")
        return true
      }
    },
    promote_to_owner_toggle_increment() {
      // console.log("in promote_to_owner_toggle_increment computed")
      return this.promote_to_owner_toggle_increment_
    },
    countries_list() {
      return this.countries_list_
    },
    states_list() {
      return this.states_list_
    },
    markets_list() {
      return this.markets_list_
    },

    all_pdas() {
      // console.log("all pdas has changed to")
      // console.log(this.all_pdas_)
      return this.all_pdas_
    },
    backend_has_changed() {
      return store.getters.mother_pda_controls_backend_update
    },
    pda_listing_has_changed() {
      return store.getters.mother_pda_all_pdas
    },
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    async pda_listing_has_changed() {
      this.all_pdas_ = store.getters.mother_pda_all_pdas
    },
    async backend_has_changed() {
      await this.local_view_update_all()
    },
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  }
}
</script>

<style scoped>

</style>