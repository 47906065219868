<template>
  <module_main_page
      module_download_name="d1e5d303-e043-4839-a150-ac0aac056574"
      module_logo_name="sd_professionalism.svg"
      :module_desc_text=module_text
      module_headline="Professionalism and Work Ethic"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "professionalism",
  data() {
    return {
      module_text: "Professionalism and work ethic are essential in the homecare context as they help to build trust, foster positive relationships, and provide high-quality care to clients. Caregivers in the homecare context are responsible for providing a range of services, from personal care to medical assistance, and must conduct themselves in a professional and ethical manner at all times. This includes arriving on time for appointments, communicating clearly and effectively with clients and their families, and adhering to all relevant laws and regulations governing their practice. Caregivers must also maintain appropriate boundaries with clients and avoid any actions or behaviors that may be perceived as unprofessional or unethical. ",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>

