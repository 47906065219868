import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'


// import {ENVIRON} from "@/library";
import {determine_env} from "@/library";

//This code needs to be commented out for dev, but in for a production build

/* eslint-disable */


let sw_registration_object = null

let current_env = determine_env()

if (current_env === 'production') {
// if (true) {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};

    if ('serviceWorker' in navigator) {
        console.log("===[Service Worker]=== Attempting to register TMH service worker..")
        navigator.serviceWorker
            .register("/sw.js")
            .then(function (registration) {
                registration.update();
                sw_registration_object = registration
                console.log("===[Service Worker]=== Just ran update the service worker registration..")
                return registration

            })
            .catch(function (error) {
                console.error('Service Worker Error', error);
            });
    } else {
        console.log("NO SERVICE WORKER IN NAVIGATOR")
    }
} else {
    console.log("NO SERVICE WORKER REGISTERED IN DEVELOPMENT")
}


function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

if (hasGetUserMedia()) {
    // Good to go!
} else {
    alert("getUserMedia() is not supported by your browser");
}

//This involves the chrome app install banner. See max PWA lecture 41 to defer install
// and rerun at a potentially better time.
// var deferredPrompt;
// window.addEventListener('beforeinstallprompt', function (event) {
//     console.log("beforeinstallprompt fired")
//     event.preventDefault();
//     deferredPrompt = event;
//     console.log("beforeinstallprompt event is : " + deferredPrompt)
//     return false
// })

const app = createApp(App)
app.use(store).use(router).use(VueSocialSharing).mount('#app')


