

<template>
  <potential_discount_widget
      :discount_values=discount_values
  ></potential_discount_widget>
</template>

<script>
import potential_discount_widget from "@/components/Vortex/discount_pages/potential_discount_widget.vue";

export default {
  name: "potential_main",
  components: {potential_discount_widget},
  props: ["discount_values"],
}
</script>

<style scoped>

</style>