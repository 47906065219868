<template>
  <universal_invisible_backer
      :show_backer="show_hamburger_menu"
      :backer_color="colors.dark_glass"
      event_name="backer_clicked"
      @backer_clicked="hamburger_menu_toggle"
  ></universal_invisible_backer>
  <!--  Basic Hamburger slide menu-->

  <!--  Default Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='default'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        v-if="!reentry_route"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SIGN UP"
        :text_size=button_text_size
        :bold="true"
        event_name="register_button_clicked"
        @register_button_clicked="dual_route_wrapper('register')"
    ></basic_text_button>
    <basic_text_button
        class=""
        v-if="!(reentry_route && good_token)"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG IN"
        :text_size=button_text_size
        :bold="true"
        event_name="log_in_button_clicked"
        @log_in_button_clicked="dual_route_wrapper('login')"
    ></basic_text_button>
    <basic_text_button
        class=""
        v-if="reentry_route && good_token"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="log_in_button_clicked"
        @log_in_button_clicked="dual_route_wrapper(reentry_route)"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="CAREGIVERS"
        :text_size=button_text_size
        :bold="true"
        event_name="caregivers_button_clicked"
        @caregivers_button_clicked="dual_route_wrapper('for-caregivers','')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCIES"
        :text_size=button_text_size
        :bold="true"
        event_name="agencies_button_clicked"
        @agencies_button_clicked="dual_route_wrapper('for-agencies', '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SCHEDULES"
        :text_size=button_text_size
        :bold="true"
        event_name="schedules_button_clicked"
        @schedules_button_clicked="dual_route_wrapper('spyglass')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="CONTACT"
        :text_size=button_text_size
        :bold="true"
        event_name="contact_button_clicked"
        @contact_button_clicked="dual_route_wrapper('contact')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="ABOUT"
        :text_size=button_text_size
        :bold="true"
        event_name="about_button_clicked"
        @about_button_clicked="dual_route_wrapper('', 'about_marker')"
    ></basic_text_button>
  </basic_slide_over_menu>

  <!--  profileyou_info Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='profileyou_info'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_profile_clicked"
        @back_to_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  profileyou_home Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='profileyou_home'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

<!--    <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        button_color_not_ready="gray"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="MEET AGENCY"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="meet_agency_button_clicked"-->
<!--        @meet_agency_button_clicked="custom_emit('meet_button_clicked')"-->
<!--    ></basic_text_button>-->
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="PERSONAL INFO"
        :text_size=button_text_size
        :bold="true"
        event_name="personal_info_button_clicked"
        @personal_info_button_clicked="non_contextual_route('info')"
    ></basic_text_button>

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="FORUM"
        :text_size=button_text_size
        :bold="true"
        event_name="forum_button_clicked"
        @forum_button_clicked="dual_route_wrapper('main-forum')"
    ></basic_text_button>

     <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SPYGLASS"
        :text_size=button_text_size
        :bold="true"
        event_name="forum_button_clicked"
        @forum_button_clicked="dual_route_wrapper('spyglass')"
    ></basic_text_button>

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  profileother_home Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='profileother_home'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="CALL USER"
        :text_size=button_text_size
        :bold="true"
        event_name="call_user"
        @call_user="custom_emit('call_user')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SMS USER"
        :text_size=button_text_size
        :bold="true"
        event_name="sms_user"
        @sms_user="custom_emit('sms_user')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SPEEDYDELIVERY"
        :text_size=button_text_size
        :bold="true"
        event_name="speedydelivery_button_clicked"
        @speedydelivery_button_clicked="dual_route_wrapper(pda_speedy_admin_page_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="agency_profile_button_clicked"
        @agency_profile_button_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="your_profile_button_clicked"
        @your_profile_button_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  profileagency_home Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='profileagency_home'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        v-if="owner_perm"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY INFO"
        :text_size=button_text_size
        :bold="true"
        event_name="agency_info_clicked"
        @agency_info_clicked="non_contextual_route('info')"
    ></basic_text_button>
    <basic_text_button
        class=""
        v-if="owner_perm"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="INVITE"
        :text_size=button_text_size
        :bold="true"
        event_name="invite_clicked"
        @invite_clicked="custom_emit('invite_clicked')"
    ></basic_text_button>
    <basic_text_button
        class=""
        v-if="owner_perm"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="PROMOTE"
        :text_size=button_text_size
        :bold="true"
        event_name="promote_clicked"
        @promote_clicked="custom_emit('promote_clicked')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="your_profile_button_clicked"
        @your_profile_button_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  profileagency_info Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='profileagency_info'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_profile_clicked"
        @back_to_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_profile_clicked"
        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  freetime_cg Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='freetime_cg'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        v-if="owner_perm"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="HELP"
        :text_size=button_text_size
        :bold="true"
        event_name="agency_info_clicked"
        @agency_info_clicked="non_contextual_route('help')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_profile_clicked"
        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_profile_clicked"
        @back_to_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

   <!--  freetime_cg_help Hamburger Menu Items-->
  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='freetime_cg_help'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="FREETIME"
        :text_size=button_text_size
        :bold="true"
        event_name="back_button_clicked"
        @back_button_clicked="go_back()"
    ></basic_text_button>

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_profile_clicked"
        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_profile_clicked"
        @back_to_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>

  </basic_slide_over_menu>

  <!--  freetime_agency Hamburger Menu Items-->
   <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='freetime_agency'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

<!--       <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="BASELINE"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="baseline_button_clicked"-->
<!--        @baseline_button_clicked="custom_emit('baseline_button_clicked')"-->
<!--    ></basic_text_button>-->

     <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
       :button_color_not_ready="button_color"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="ELIGIBILITY"
        :text_size=button_text_size
        :bold="true"
        event_name="get_matches_template"
        @get_matches_template="custom_emit('get_eligibility')"
    ></basic_text_button>

<!--      <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--       :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="GET MATCHES"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="get_matches_template"-->
<!--        @get_matches_template="custom_emit('get_matches_template')"-->
<!--    ></basic_text_button>-->

<!--      <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="UPLOAD MATCHES"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="match_template_button_clicked"-->
<!--        @match_template_button_clicked="custom_emit('match_template_button_clicked')"-->
<!--    ></basic_text_button>-->

      <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
       :button_color_not_ready="button_color"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="GET HOURS"
        :text_size=button_text_size
        :bold="true"
        event_name="get_hours_report"
        @get_hours_report="custom_emit('get_hours_report')"
    ></basic_text_button>

<!--       <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="HOURS TEMPLATE"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="get_hours_standard_template"-->
<!--        @get_hours_standard_template="custom_emit('get_hours_standard_template')"-->
<!--    ></basic_text_button>-->

<!--    <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="YOUR PROFILE"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="back_to_your_profile_clicked"-->
<!--        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"-->
<!--    ></basic_text_button>-->
<!--    <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="AGENCY PROFILE"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="back_to_profile_clicked"-->
<!--        @back_to_profile_clicked="dual_route_wrapper(pda_profile_route, '')"-->
<!--    ></basic_text_button>-->

<!--      <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="HELP"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="personal_info_button_clicked"-->
<!--        @personal_info_button_clicked="non_contextual_route('admin_help')"-->
<!--    ></basic_text_button>-->

<!--      <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        :button_color_not_ready="button_color"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="LOG OUT"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="log_out_button_clicked"-->
<!--        @log_out_button_clicked="log_out_user()"-->
<!--    ></basic_text_button>-->

  </basic_slide_over_menu>

    <!--  spyglass_agency Hamburger Menu Items-->
   <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='spyglass_agency'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_profile_clicked"
        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_profile_clicked"
        @back_to_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>

      <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="HELP"
        :text_size=button_text_size
        :bold="true"
        event_name="personal_info_button_clicked"
        @personal_info_button_clicked="non_contextual_route('admin_help')"
    ></basic_text_button>

      <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

  </basic_slide_over_menu>

     <!--  speedydelivery_user Hamburger Menu Items-->
   <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='speedydelivery_user'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="YOUR PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_profile_clicked"
        @back_to_your_profile_clicked="dual_route_wrapper(profile_route, '')"
    ></basic_text_button>


  </basic_slide_over_menu>

   <!--  speedydelivery_agency Hamburger Menu Items-->
   <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='speedydelivery_agency'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_agency_profile_clicked"
        @back_to_your_agency_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>

      <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="UPLOAD DOCUMENTS"
        :text_size=button_text_size
        :bold="true"
        event_name="go_to_speedy_agency_uploads_page"
        @go_to_speedy_agency_uploads_page="dual_route_wrapper(pda_speedy_uploads_route, '')"
    ></basic_text_button>


  </basic_slide_over_menu>

  <!--  interested_caregiver_plus_ad-->
   <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu && display_mode==='spyglass_interested_caregiver_plus_ad'"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCY PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="back_to_your_agency_profile_clicked"
        @back_to_your_agency_profile_clicked="dual_route_wrapper(pda_profile_route, '')"
    ></basic_text_button>

  </basic_slide_over_menu>


  <div class="flex_space_between_row  flex_align_items_center wid_100vw" id="banner">

    <div class="flex_centered_column cursor_ptr banner_logo">
      <img src="@/assets/rabbit_1_line_edits.svg" id="top_rabbit_logo" @click="dual_route_wrapper(profile_route,'page_top')"
           alt="Tiny Magic Hat Main Logo">
    </div>


    <!--Hamburger Menu Button-->
    <basic_icon_button
        v-if="mobile_mode && display_mode !== 'None'"
        class="cursor_ptr"
        :button_color="colors.transparent"
        :button_color_not_ready="colors.pennie_gray"
        :ready="true"
        button_height="40px"
        button_width="40px"
        border_rad_pct="50%"
        image_path='hamburger-menu-dark1.svg'
        image_width="25px"
        alt_text="Hamburger button"
        event_name="hamburger_clicked"
        @hamburger_clicked="hamburger_menu_toggle"
    ></basic_icon_button>

    <!--Desktop Mode Modes-->
    <div v-if="!mobile_mode" class="desktop_links">
      <div v-if="!mobile_mode && display_mode==='profileyou_home'" class="flex_centered_column">
        <div class="flex_centered_end_row " id="desktop_lnk">

<!--          <div @click="custom_emit('promote_button_clicked')" class="links cursor_ptr text_primary_purple border_rt">Promote User-->
<!--          </div>-->

<!--           <div v-if="good_token" @click="custom_emit('meet_button_clicked')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Meet Agency-->
<!--          </div>-->

          <div v-if="good_token" @click="non_contextual_route('info')"
               class="links cursor_ptr text_primary_purple border_rt">Personal Info
          </div>
           <div v-if="good_token" @click="dual_route_wrapper('main-forum')"
               class="links cursor_ptr text_primary_purple border_rt">Forum
          </div>
           <div v-if="good_token" @click="dual_route_wrapper('spyglass')"
               class="links cursor_ptr text_primary_purple border_rt">SpyGlass
          </div>

          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='profileyou_info'" class="flex_centered_column">
        <div class="flex_centered_end_row">
          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Profile
          </div>
          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='profileother_home'" class="flex_centered_column">
        <div class="flex_centered_end_row">

           <div @click="dual_route_wrapper(pda_speedy_admin_page_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">SpeedyDelivery
          </div>

          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Agency Profile
          </div>
          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='profileagency_home'" class="flex_centered_column">
        <div class="flex_centered_end_row">

          <div v-if="owner_perm" @click="non_contextual_route('info')"
               class="links cursor_ptr text_primary_purple border_rt">Agency Info
          </div>
          <div v-if="owner_perm" @click="custom_emit('invite_clicked')"
               class="links cursor_ptr text_primary_purple border_rt">Invite
          </div>
          <div v-if="owner_perm" @click="custom_emit('promote_clicked')"
               class="links cursor_ptr text_primary_purple border_rt">Promote
          </div>
          <div v-if="owner_perm" @click="custom_emit('get_assistant')"
               class="links cursor_ptr text_primary_purple border_rt">Get Assistant
          </div>

          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='profileagency_info'" class="flex_centered_column">
        <div class="flex_centered_end_row">
          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Agency Profile
          </div>
          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='freetime_cg_help'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

          <div @click="go_back"
               class="links cursor_ptr text_primary_purple border_rt">FreeTime
          </div>
          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple ">Agency Profile
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='freetime_cg'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

          <div @click="non_contextual_route('help')"
               class="links cursor_ptr text_primary_purple border_rt">Help
          </div>

          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Agency Profile
          </div>
          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='freetime_agency'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

<!--           <div  @click="custom_emit('baseline_button_clicked')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Baseline Menu-->
<!--          </div>-->

<!--            <div @click="custom_emit('get_matches_template')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Get Matches-->
<!--          </div>-->

<!--           <div  @click="custom_emit('match_template_button_clicked')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Upload Matches-->
<!--          </div>-->


           <div @click="custom_emit('get_hours_report')"
               class="links cursor_ptr text_primary_purple border_rt">Get Hours
          </div>

          <div @click="custom_emit('get_eligibility')"
               class="links cursor_ptr text_primary_purple ">Eligibility
          </div>

<!--           <div @click="custom_emit('get_hours_standard_template')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Hours Template-->
<!--          </div>-->


<!--          <div @click="dual_route_wrapper(profile_route, '')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Your Profile-->
<!--          </div>-->
<!--          -->
<!--          <div @click="dual_route_wrapper(pda_profile_route, '')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Agency Profile-->
<!--          </div>-->

<!--           <div @click="non_contextual_route('admin_help')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Help Page-->
<!--          </div>-->

<!--          <div @click="log_out_user()"-->
<!--               class="links cursor_ptr text_primary_purple ">Log Out-->
<!--          </div>-->

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='spyglass_agency'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Your Profile
          </div>
          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple border_rt">Agency Profile
          </div>

           <div @click="non_contextual_route('admin_help')"
               class="links cursor_ptr text_primary_purple border_rt">Help
          </div>

          <div @click="log_out_user()"
               class="links cursor_ptr text_primary_purple ">Log Out
          </div>

        </div>
      </div>
       <div v-if="!mobile_mode && display_mode==='speedydelivery_user'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

          <div @click="dual_route_wrapper(profile_route, '')"
               class="links cursor_ptr text_primary_purple ">Your Profile
          </div>
<!--          <div @click="dual_route_wrapper(pda_profile_route, '')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Agency Profile-->
<!--          </div>-->

<!--           <div @click="non_contextual_route('admin_help')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Help-->
<!--          </div>-->

<!--          <div @click="log_out_user()"-->
<!--               class="links cursor_ptr text_primary_purple ">Log Out-->
<!--          </div>-->

        </div>
      </div>
      <div v-if="!mobile_mode && display_mode==='spyglass_interested_caregiver_plus_ad'" class="flex_centered_column">
        <div class="flex_centered_end_row ">

<!--          <div @click="dual_route_wrapper(profile_route, '')"-->
<!--               class="links cursor_ptr text_primary_purple ">Your Profile-->
<!--          </div>-->
          <div @click="dual_route_wrapper(pda_profile_route, '')"
               class="links cursor_ptr text_primary_purple">Agency Profile
          </div>

<!--           <div @click="non_contextual_route('admin_help')"-->
<!--               class="links cursor_ptr text_primary_purple border_rt">Help-->
<!--          </div>-->

<!--          <div @click="log_out_user()"-->
<!--               class="links cursor_ptr text_primary_purple ">Log Out-->
<!--          </div>-->

        </div>
      </div>
    </div>

  </div>
</template>


<script>
/* eslint-disable */
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  num_is_between_inclusive, colors, scroll_to_element, dual_route, reentry_route,
  good_token_now, analytic, get_profile_route, get_pda_home_route
} from "@/library";
import store from "@/store";

export default {
  name: "inside_banner",
  props: [
    'mode',
    'show_hamburger',
    'owner_perm',
    'desktop_size'
  ],
  components: {
    universal_invisible_backer,
    basic_slide_over_menu,
    basic_icon_button,
    basic_text_button
  },
  emits: [
      "meet_button_clicked",
      "call_user",
      "sms_user",
      'agency_info_clicked',
      'invite_clicked',
      'promote_clicked',
      'match_template_button_clicked',
      'baseline_button_clicked',
      'get_matches_template',
      'get_eligibility',
      'get_hours_report',
      'get_hours_standard_template',
      'get_assistant'
  ],
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  data() {
    return {
      owner_perm_: this.owner_perm,
      colors: colors,
      show_hamburger_menu_: this.show_hamburger,
      mobile_mode_x: true,
      desktop_cutoff: this.desktop_size ? this.desktop_size : 800,
      button_color: colors.new_primary_purple_50,
      button_text_size_: "15px"
    }
  },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    custom_emit(custom) {
      this.hamburger_menu_false()
      this.$emit(custom, true)
    },
    async log_out_user() {
      this.hamburger_menu_false()
      // await this.$router.push({path: ''})
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      await analytic("01:01:01:01")
    },
    async go_back() {
      await this.$router.go(-1)
    },
    dual_route_wrapper(route, inner = null) {
      if(this.display_mode==='None'){
        return null
      }
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    async non_contextual_route(route = null) {
      this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      // console.log(`The Desktop cutoff is ${this.desktop_cutoff} and size is ${new_w}`)
      if (new_w >= this.desktop_cutoff) {
        // console.log('Setting mobile to False')
        this.mobile_mode_x = false
      } else {
        // console.log('Setting mobile to True')
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')

        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // console.log("size is greater that 500")
        // let e = document.getElementById('desktop_lnk')
        // console.log(e)
        // add_class_to_id('desktop_lnk', 'links')
      }


    },


  },
  computed: {
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    owner_permission() {
      // console.log(`In owner perm with ${this.owner_perm_}`)
      return this.owner_perm_
    },

    mobile_mode() {
      return this.mobile_mode_x
    },
    display_mode() {
      if (this.mode) {
        return this.mode
      } else {
        return "default"
      }
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_text_size() {
      return this.button_text_size_
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    profile_route() {
      return get_profile_route()
    },
    pda_profile_route() {
      return get_pda_home_route()
    },

    pda_speedy_admin_page_route(){
      let org = store.getters.org_context
      let r = `/pda/${org}/speedydelivery`
      return r
    },

    pda_speedy_uploads_route(){
      return get_speedy_agency_upload_route
    },


  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  #banner {
    height: 75px;
  }

  #about_link {
    margin-right: 20px;
  }

  #top_rabbit_logo {
    width: 150px;
    padding-left: 15px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
  }

  #about_link {
    margin-right: 20px;
  }


}

@media (min-width: 400px) {
}

@media (min-width: 700px) {

}

@media (min-width: 1200px) {
  .desktop_links {
    margin-right: 30px;
  }

  .banner_logo {
    margin-left: 30px;
  }
}

@media (min-width: 2000px) {
  #banner {
    height: 150px;
  }

  #top_rabbit_logo {
    width: 250px;
    padding-left: 20px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 30px;
    text-decoration: none;
  }

  #about_link {
    margin-right: 40px;
  }
}

</style>