<template>
  <module_main_page
      module_download_name="92785aff-b96c-4a49-9afc-f1b1b228d4f9"
      module_logo_name="sd_diabetes.svg"
      :module_desc_text=module_text
      module_headline="Understanding Diabetes"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "diabetes",
  data() {
    return {
      module_text: "Diabetes is a chronic medical condition characterized by high levels of glucose (sugar) in the blood. Type 2 diabetes, often diagnosed in adulthood, is a condition where the body either does not use insulin effectively or does not produce enough of it. Caregivers must understand that diabetes management involves careful monitoring of blood sugar levels, medication administration (often including insulin), dietary control, and regular physical activity. Caregivers need to be knowledgeable about the client's specific type of diabetes, prescribed medications, and insulin administration techniques if applicable. They should alse be aware of the signs of high or low blood sugar and how to respond to these situations. Monitoring the client's diet and encouraging a balanced, consistent meal plan is crucial.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



