<template>
  <section class="core_c">
    <inside_banner
        mode="freetime_cg_help"
    ></inside_banner>
        <div class="bg_primary_purple_deep flex_centered_column ft_info b_rad_10px">
      <ul class="text_white font_raleway text_25"><u class="font_weight_600">FreeTime Rules</u>
        <li class="text_white text_20 margin_t_10">Bonuses begin to accumulate when your weekly hours exceed 32.</li>
        <li class="text_white text_20">Bonuses do not increase past 38 hours.</li>
        <li class="text_white text_20">Bonuses are calculated weekly, regardless of when they are paid.</li>
        <li class="text_white text_20">Bonus amounts for a given number of hours may change from week to week.</li>
      </ul>
    </div>

    <div class="bg_cyanic flex_centered_column ft_info margin_t_10 b_rad_10px">
      <ul class="text_black font_raleway text_25"><u class="font_weight_600">Explanations</u>
        <li class="text_black text_20 margin_t_10"><strong>Hourly Plus</strong>: The amount of the effective hourly pay
          rate increase resulting from your FreeTime Bonus this week.
        </li>
        <li class="text_black text_20"><strong>Top Bonus</strong>: The highest bonus earned by any caregiver at the
          agency this week.
        </li>
        <li class="text_black text_20"><strong>Top YTD</strong>: The sum of all weekly Top Bonuses this calendar year.</li>
        <li class="text_black text_20"><strong>Bonus YTD</strong>: Sum of all Bonuses earned by you this calendar year.
        </li>
      </ul>
    </div>

  </section>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import store from "@/store";

export default {
  name: "freetime_cg_help",
  components: {
    inside_banner,
    outside_footer
  },
  methods: {
     async non_contextual_route(route = null) {
      // this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
  },
}
</script>

<style scoped>

</style>