<template>

  <universal_pop_up_slot
      :show_popup="error_length > 0"
      @universal_popup_bg_clicked="local_dequeue_error_message"
      :backer_color="colors.transparent"
  >

    <section>
      <div class="flex_start_row error_box font_raleway font_weight_800 z_500 cursor_ptr"
           @click="local_dequeue_error_message()">
        <div class="flex_centered_column">
          <img class="material_icon"
               :src="image_name('report.svg')"
               alt="Error Icon">
        </div>
        <div class="flex_fully_centered_column">
          <!--          <div class="flex_centered_row">Oh no!</div>-->
          <div class="flex_centered_row">
            <span id="error_html" v-html="error_peek"></span>
          </div>

        </div>
        <div class="flex_right_aligned_column flex_grow">
          <img class="cancel_x"
               :src="image_name('red_not_x.svg')"
               alt="Notification Icon">
        </div>
      </div>
    </section>
  </universal_pop_up_slot>


</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {Database} from "@/client_db";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import {colors, image_name} from "@/library";

export default {
  name: "error_popup_queue",
  beforeMount() {
    // console.log("Running beforeMount an notification popup component")
    this.db = new Database();
    store.dispatch('error_restart')
  },
  components: {
    universal_pop_up_slot
  },
  data() {
    return {
      colors: colors,
    }
  },
  methods: {
    image_name(name) {
      return image_name(name)
    },
    local_dequeue_error_message() {
      store.dispatch('error_dequeue')
    },
  },
  computed: {
    ...mapGetters([
      'error_peek',
      'error_length'
    ]),
    mobile_mode() {
      return store.getters.get_mobile_state
    }
  }
}
</script>

<style scoped>


.error_text_sizing {
  width: 75%;
}

@media (min-width: 100px) {
   .cancel_x {
    width: 25px;
    position: relative;
    top: -18px;
    left: 17px;
  }

  .error_box {
    background-color: var(--error-color);
    border: 3px solid black;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 300px;
    box-sizing: border-box;
    max-width: 99vw;
  }

  .material_icon {
    /*font-size: 50px;*/
    top: -3%;
    border-radius: 50%;
    margin-right: 20px;
  }


}

@media (min-width: 500px) {
  .error_box {
    width: 300px;
  }



}


@media (min-width: 1200px) {
  .error_box {
    font-size: 20px;
    background-color: var(--error-color);
    border: 3px solid black;
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    width: 500px;
  }

  .material_icon {
    /*font-size: 80px;*/
    top: -3%;
    border-radius: 50%;
    /*padding: 3%;*/
    padding-right: 40px;

    /*background-color: var(--primary-purple);*/
  }
   .cancel_x {
    width: 30px;
    position: relative;
    top: -35px;
    left: 35px;
  }

}


</style>