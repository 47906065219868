<template>
    <div></div>
</template>

<script>
import {prime_socket, receive_over_socket} from "@/library";
import store from "@/store";
// import {Database} from "@/client_db";

export default {
  name: "socket_handler",
  emits: ['local_page_update', ],
   async mounted() {
    // this.db = new Database();
    await prime_socket(this.local_message_handler)
  },
  data(){
    return {

    }
  },
  methods: {
     async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      if (update) {
        store.commit('increment_universal_page_update', true)
      }
    },
  },
  computed: {
     update_page() {
      // console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
  },
  watch: {
      async update_page() {
      // console.log("update_page watcher is triggered")
      // await this.local_page_update()
      console.log("The page needs to be updated.")
      this.$emit('local_page_update', true)
    },
  },
}
</script>

<style scoped>

</style>