<script>
import honored_discount_widget from "@/components/Vortex/discount_pages/honored_discount_widget.vue";

export default {
  name: "honored_main",
  components: {honored_discount_widget},
  methods: {
    do_nothing() {
    },
  },
}
</script>

<template>
  <honored_discount_widget
  ></honored_discount_widget>
</template>

<style scoped>

</style>