<template>
  <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column">

     <div class="flex_centered_row">
      <h3 >Basic Agency Info</h3>

      <div class="flex_centered_column">
<!--         <span v-if="owners_perm" @click="non_contextual_route('info')" class="material-symbols-outlined material_icon cursor_ptr">edit_square</span>-->
          <img class="material_icon" v-if="owners_perm" @click="non_contextual_route('info')"
                         :src="image_name('edit_square.svg')"
                         alt="Edit icon">
      </div>

    </div>


<!--    <h3 class="  ">Basic Agency Info</h3>-->
    <div v-if="agency_name" class="non_clickable_context_item    ">{{ agency_name }}</div>
    <div v-if="full_street_address" class="non_clickable_context_item    ">
      {{ full_street_address }}
    </div>
    <div v-if="city_state_zip" class="non_clickable_context_item ">
      {{ city_state_zip }}
    </div>
    <div v-if="phone_number" class="non_clickable_context_item ">{{formatPhoneNumber}}
    </div>
    <div v-if="email" class="non_clickable_context_item ">{{ email }}</div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {colors, image_name} from "@/library";
import store from "@/store";
export default {
  name: "basic_agency_info_box",
  props: ['agency_name', 'full_street_address', 'phone_number', 'email', 'city_state_zip', 'owners_perm'],
  async mounted() {
    this.id = uuidv4();
  },
  data() {
    return {
      colors: colors,
      id: "",
    }
  },
  methods: {
     image_name(name){
      return image_name(name)
    },
    async non_contextual_route(route = null) {
      // this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
  },

  computed: {
    formatPhoneNumber() {
      let phoneNumberString = this.phone_number
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    }
  },
}
</script>

<style scoped>
@media (min-width: 100px) {

  .material_icon{
     margin-left: 10px;
    height: 30px;
  }
}


@media (min-width: 1200px) {


   .material_icon{
     margin-left: 10px;
      height: 30px;
     margin-bottom: 10px;
  }

}
</style>