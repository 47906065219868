<template>
  <div class="ok_show_me_col" :id="id">
    <!--FREETIME              -->
    <h3 :id="h_id" class="">{{ h_text }}</h3>
    <p class="">{{ p_text }}</p>
    <!--    <p>If you would like for your agency to join the Tiny Magic Hat Network, please drop us a line.</p>-->


    <input_with_label
        class="sg_input_form"
        ref="input_agency_name"
        label="Agency Name"
        @emit_state="catch_agency"
    ></input_with_label>


    <input_with_label
        class="sg_input_form"
        ref="input_first_name"
        label="First Name"
        @emit_state="catch_first"
    ></input_with_label>

    <input_with_label
        class="sg_input_form"
        ref="input_last_name"
        label="Last Name"
        @emit_state="catch_last"
    ></input_with_label>

    <input_with_label
        class="sg_input_form"
        ref="input_city"
        label="City"
        @emit_state="catch_city"
    ></input_with_label>

    <dropdown_datalist
        class="sg_input_form"
        label="State"
        ref="input_state"
        :data_list=states
        @value-selected="catch_state"
    ></dropdown_datalist>

    <input_with_label
        class="sg_input_form"
        ref="input_email"
        label="Email"
        @emit_state="catch_email"
    ></input_with_label>

    <input_with_label
        class="sg_input_form"
        ref="input_phone"
        label="Phone"
        @emit_state="catch_phone"
    ></input_with_label>


    <basic_text_button
        class="margin_t_25"
        :button_state="true"
        :button_color="color_of_button"
        :text_color="colors.white"
        :button_color_not_ready="colors.gray"
        :button_not_ready_text_color="colors.morgie_gray"
        button_height="64px"
        button_width="162px"
        border_rad_pct="0"
        text="GET STARTED"
        button_not_ready_text="Retire Ad"
        text_size="14px"
        :bold="true"
        event_name="agency_more_info_request"
        @agency_more_info_request="request_more_info"
    ></basic_text_button>

  </div>
</template>

<script>
/* eslint-disable */
import input_with_label from "@/components/parts/input_with_label";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import basic_text_button from "@/components/parts/basic_text_button";
import {form_to_email_info_account, get_uuid, states, colors, validate_email, validate_phonenumber} from "@/library";
import store from "@/store";

export default {
  name: "agency_cta_form",
  components: {
    input_with_label,
    dropdown_datalist,
    basic_text_button
  },
  props: [
    "feature",
    "p_text",
    "h_text",
    "splash_color",
    "button_color"
  ],
  async mounted() {
    this.id = get_uuid()
    this.h_id = get_uuid()

    try {
      await this.$nextTick(function () {
        let main_form = document.getElementById(this.id)
        let h_text = document.getElementById(this.h_id)
        main_form.style.color = this.splash_color
        h_text.style.color = this.splash_color
      })
    } catch (error) {
      console.error(error)
    }

  },
  data() {
    return {
      states: states,
      colors: colors,
      id: "",
      h_id: "",
      data: {
        first: "",
        last: "",
        city: "",
        state: "",
        email: "",
        phone: "",
        agency: "",
        bad_email: true,
        bad_phone: true,
      },
    }
  },
  methods: {
    request_more_info() {

      this.$refs.input_agency_name.emit_state()
      this.$refs.input_first_name.emit_state()
      this.$refs.input_last_name.emit_state()
      this.$refs.input_email.emit_state()
      this.$refs.input_city.emit_state()
      this.$refs.input_phone.emit_state()

      if (this.data.bad_email) {
        store.commit('error_enqueue', `${this.data.email} is not a valid email.`)
        return null
      }

       if (this.data.bad_phone) {
        store.commit('error_enqueue', `${this.data.phone} is not a valid phone number.`)
        return null
      }

      if (this.data.first === "") {
        store.commit('error_enqueue', `A first name is required.`)
        return null
      }
      if (this.data.last === "") {
        store.commit('error_enqueue', `A last name is required.`)
        return null
      }
      if (this.data.state === "") {
        store.commit('error_enqueue', `A state is required.`)
        return null
      }
      if (this.data.city === "") {
        store.commit('error_enqueue', `A city is required.`)
        return null
      }
      if (this.data.email === "") {
        store.commit('error_enqueue', `An email address is required.`)
        return null
      }

      if (this.data.agency === "") {
        store.commit('error_enqueue', `An agency name is required.`)
        return null
      }


      let data = {
        first: this.data.first,
        last: this.data.last,
        state: this.data.state,
        city: this.data.city,
        email: this.data.email,
        phone: this.data.phone.replace(/\D/g, ''),
        agency: this.data.agency,
        feature: this.feature
      }
      form_to_email_info_account(data)
      store.commit('notification_enqueue', `Thank you for your interest. Your information has been submitted.`)

      this.data.first = ""
      this.data.last = ""
      this.data.state = ""
      this.data.city = ""
      this.data.email = ""
      this.data.phone = ""
      this.data.agency = ""
      this.data.bad_email = true

      this.$refs.input_agency_name.reset_state()
      this.$refs.input_first_name.reset_state()
      this.$refs.input_last_name.reset_state()
      this.$refs.input_email.reset_state()
      this.$refs.input_phone.reset_state()
      this.$refs.input_city.reset_state()
      this.$refs.input_state.reset_state()
    },
    catch_first(content) {
      this.data.first = content.trim().slice(0, 50)
    },
    catch_last(content) {
      this.data.last = content.trim().slice(0, 50)
    },
    catch_city(content) {
      this.data.city = content.trim().slice(0, 50)
    },
    catch_agency(content) {
      this.data.agency = content.trim().slice(0, 50)
    },
    catch_state(content) {
      this.data.state = content.trim().slice(0, 50)
    },
    catch_email(content) {
      if (!validate_email(content)) {
        this.data.bad_email = true
        this.data.email = content
        return null
      }
      this.data.email = content.trim().slice(0, 100)
      this.data.bad_email = false

    },
    catch_phone(content) {
      this.data.phone = content
      if (!validate_phonenumber(content)) {
        this.data.bad_phone = true
        return null
      }
      this.data.bad_phone = false

    },
  },
  computed: {
    color_of_button() {
      if (this.button_color) {
        return this.button_color
      } else if (this.splash_color) {
        return this.splash_color
      } else {
        return colors.new_primary_purple
      }
    },
  },

}
</script>

<style scoped>
.ok_show_me_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin-bottom: 40px;
  max-width: 650px;
  margin-left: 10px;
  margin-right: 10px;

}

.ok_show_me_col h3 {
  font-size: 26px;
  text-align: center;
  margin-top: 20px;
  max-width: 95%;
  /*color: var(--new-orange-main);*/
}


@media (min-width: 100px) {
  .sg_input_form {
    width: 270px;
  }


  .ok_show_me_col h3 {
    margin-bottom: 20px;
    margin-top: 60px;
  }

  .ok_show_me_col p {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 40px;
    color: var(--new-primary-purple-very-deep);
    max-width: 350px;
  }


}

@media (min-width: 680px) {
  .sg_input_form {
    width: 600px;
  }

  .ok_show_me_col p {
    max-width: 500px;
  }

}
</style>