<template>
  <div @click="emit_state" class="flex_centered_column whole_text_button" @mouseenter="to_hover_color"
       @mouseleave="to_std_color" :id="id">
    <div class="flex_centered_row">
      <h5 class="button_text text_align_center padding_lr_6" :id="id_text">{{ text_to_show }}</h5>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {colors} from "@/library";

export default {
  name: "basic_text_button",
  props: [
    'hover_color',
    'hover_text_color',
    'button_color',
    'button_color_not_ready',
    'button_height',
    'button_width',
    'border_rad_pct',
    'text',
    'text_color',
    'text_size',
    'button_not_ready_text_color',
    'button_not_ready_text',
    'bold',
    'event_name',
    'button_state'],

  async updated() {
    await this.$nextTick(function () {
          try {
            // console.log("this button")
            let whole_button = document.getElementById(this.id)
            let button_text = document.getElementById(this.id_text)

            if (this.button_state === true || this.button_state === undefined) {
              // console.log('button_state is true')
              // console.log("whole button is")
              // console.log(whole_button)
              // console.log("button text")
              // console.log(button_text)
              whole_button.style.background = this.button_color
              button_text.style.color = this.text_color
              this.text_to_show = this.text
            } else if(this.button_state === false){
              whole_button.style.background = this.button_color_not_ready
              button_text.style.color = this.button_not_ready_text_color
              // console.log(`Not ready text is: ${this.button_not_ready_text}`)

              if (this.button_not_ready_text === undefined) {
                this.text_to_show = this.text
              } else {
                this.text_to_show = this.button_not_ready_text
              }


            }
            whole_button.style.height = this.button_height
            whole_button.style.width = this.button_width
            whole_button.style.borderRadius = this.border_rad_pct
            if (this.bold === true) {
              button_text.style.fontWeight = 800
            }
            button_text.style.fontSize = this.text_size
          } catch (error) {
            //pass
          }
        }
    )
  },
  async mounted() {
    try {

      // console.log("running text button adjustments")
      this.id = uuidv4();
      this.id_text = uuidv4();
      // console.log(`Id is: ${this.id}`)

    } catch (error) {
      console.log("inside try error catcher")
      console.log(error)
    }
  },
  data() {
    return {
      id: "",
      id_text: "",
      text_to_show: "",
      colors: colors,
    }
  },
  methods: {
    emit_state() {
      this.$emit(this.event_name, true)
    },
    to_hover_color() {

      if (this.hover_color && this.button_state) {
        // console.log("in hover over button")
        let whole_button = document.getElementById(this.id)
        let button_text = document.getElementById(this.id_text)
        whole_button.style.backgroundColor = this.hover_color
        button_text.style.color = this.hover_text_color
        // console.log("Stop hovering!")
      }
      // console.log("do nothing.")
    },
    to_std_color() {
      console.log("to standard color")
      console.log(`Button state is ${this.button_state}`)
      console.log(`Hover color is ${this.hover_color}`)
      console.log(`Button color is ${this.button_color}`)
      let whole_button = document.getElementById(this.id)
      let button_text = document.getElementById(this.id_text)
      if (this.hover_color && this.button_state) {
        // let whole_button = document.getElementById(this.id)
        // let button_text = document.getElementById(this.id_text)
        whole_button.style.backgroundColor = this.button_color
        button_text.style.color = this.text_color
        // console.log("Stop hovering!")
      } else {
        whole_button.style.background = this.button_color_not_ready
        button_text.style.color = this.button_not_ready_text_color
      }
      console.log("do nothing.")
    },
  },
}
</script>

<style scoped>

.whole_text_button {
  cursor: pointer;
  transition: .5s;
}


.button_text {
  letter-spacing: 2px;
  text-decoration: none;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  transition: all 0.2s;
}

</style>