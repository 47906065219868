<template>
  <div :id="bg_id" class="indicator">
    <div :id="message_id">{{ message_text }}</div>
  </div>
</template>

<script>

import {
  colors
} from "@/library";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "indicator_label",
  props: [
    'state',

    'unready_message_text',
    'unready_message_text_color',
    'unready_message_bg',
    'unready_width',
    'unready_font_size',

    'ready_message_text',
    'ready_message_text_color',
    'ready_message_bg',
    'ready_width',
    'ready_font_size',

    'loaded_message_text',
    'loaded_message_text_color',
    'loaded_message_bg',
    'loaded_width',
    'loaded_font_size',

  ],
  async mounted() {
    this.bg_id = uuidv4();
    this.message_id = uuidv4();
    this.message_text = ""
  },
  async updated() {
    await this.$nextTick(function () {
      let bg = document.getElementById(this.bg_id)
      let text = document.getElementById(this.message_id)
      if (this.state === 'loaded') {
        bg.style.background = this.loaded_message_bg
        text.style.color = this.loaded_message_text_color
        this.message_text = this.loaded_message_text
        bg.style.width = this.loaded_width
        text.style.fontSize = this.loaded_font_size
      } else if (this.state === 'ready') {
        bg.style.background = this.ready_message_bg
        text.style.color = this.ready_message_text_color
        this.message_text = this.ready_message_text
        bg.style.width = this.ready_width
        text.style.fontSize = this.ready_font_size
      } else {
        bg.style.background = this.unready_message_bg
        text.style.color = this.unready_message_text_color
        this.message_text = this.unready_message_text
        bg.style.width = this.unready_width
        text.style.fontSize = this.unready_font_size
      }
    })
  },
  data() {
    return {
      colors: colors,
      message_text: "",
      bg_id: "",
      message_id: "",


    }
  },

}
</script>

<style scoped>
.indicator {
  font-weight: bold;
  /*font-size: .8rem;*/
  padding: .5rem;
  text-align: center;
  /*color: var(--light-text);*/
  margin: .5rem;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
}

</style>