<template>
  <section class="doc_upload_widget flex_centered_column ">
    <div class="doc_title_box flex_centered_row flex_centered_column">
      <h1> Get Coversheet Set</h1>
      <div class="data_entry_top"><h3 class="text_align_center">Driver' License</h3>
        <div class="date_picker">
          <flat-pickr
              class="text_align_center"
              id="fp_field"
              ref="flat_pickr"
              v-model="date_dl_"
              model-value
              :config="config"
              @on-change="dl_date_picker_changed"
          />
        </div>
      </div>
      <div class="data_entry"><h3 class="text_align_center">Car Insurance</h3>
        <div class="date_picker">
          <flat-pickr
              id="fp_field2"
              class="text_align_center date_picker"
              ref="flat_pickr2"
              v-model="date_ci_"
              model-value
              :config="config"
              @on-change="ci_date_picker_changed"
          />
        </div>
      </div>


      <basic_text_button
          :v-if=true
          class="margin_tb_20"
          :button_state="true"
          :button_color="colors.cyanic_blue"
          :hover_color="colors.new_orange_main"
          :text_color="colors.black"
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="60px"
          :button_width=action_button_width
          border_rad_pct="0%"
          text="Get Cover Sheets"
          text_size="14px"
          :bold="true"
          event_name="emit_baseline_input_values"
          @emit_baseline_input_values="call_for_coversheets"
      ></basic_text_button>
    </div>


    <section class="uniques flex_centered_column ">
      <h1 class="text_align_center"> Create Unique Coversheet</h1>
      <div class="data_entry_top text_align_center"><h3>Expiration Date</h3>
        <div class="date_picker">
          <flat-pickr
              class="text_align_center"
              id="unique_data_field"
              ref="flat_pickr"
              v-model="date_unique_"
              model-value
              :config="config"
              @on-change="unique_picker_changed"
          />
        </div>
      </div>
      <div class="data_entry flex_centered_column"><h3>Document Name</h3>
        <input_with_label
            ref="input_doc_title"
            class="info_input "
            label="Elder Institute Certificate"
            max_char="30"
            start_value=""
            @emit_state="do_nothing"
        ></input_with_label>
      </div>
      <basic_text_button
          :v-if=true
          class="margin_b_20 margin_t_30"
          :button_state="true"
          :button_color="colors.cyanic_blue"
          :hover_color="colors.new_orange_main"
          :text_color="colors.black"
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="60px"
          :button_width=action_button_width
          border_rad_pct="0%"
          text="Get Unique Coversheet"
          text_size="14px"
          :bold="true"
          event_name="emit_baseline_input_values"
          @emit_baseline_input_values="call_for_unique_coversheet()"
      ></basic_text_button>
    </section>
  </section>


</template>

<script>
import axios from "axios";
import store from "@/store";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import {
  be_main_address,
  colors,
  parse_internal_json,
  stripNonAlphanumeric,
  translate_dateobj_to_datestring
} from "@/library";
import {formatTimestamp, getFutureUTCTimestamp, parseDateStringToTimestamp} from "@/dates_library";
import flatPickr from "vue-flatpickr-component";
import input_with_label from "@/components/parts/input_with_label.vue";


export default {
  name: "coversheets_widget",
  props: [
    "selected_caregiver",
  ],
  components: {input_with_label, flatPickr, basic_text_button},
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },

  data() {
    return {
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      admins_perm_: false,
      button_height_: "80px",
      button_width_: "100px",
      button_img_size_: "80px",
      date_dl_: "",
      date_ci_: "",
      date_unique_: "",
      config: {
        dateFormat: 'm/d/Y',
        defaultDate: this.get_default_expiration_date(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(),
        allowInput: false,
      },
    }
  },
  methods: {
    do_nothing() {
      //pass
    },
    async call_for_coversheets() {
      store.commit('set_spinner_state', true)
      let target_url = be_main_address('sm') + 'download_email_upload_cover_sheets'

      let the_agency = store.getters.org_context
      console.log(`The agency is ${the_agency}.`)

      // let speedy_user_ = this.selected_caregiver
      // console.log(`The selected Speedy user is ${this.selected_caregiver}`)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: the_agency,
        speedy_user: localStorage.getItem('SD_selected_cg_username'),
        drivers_license_expiration: this.date_dl_,
        car_insurance_expiration: this.date_ci_,

      }
      console.log("Make a call for coversheets...")
      console.log(data1)
      await axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
                console.log("Attempt to print headers")
                console.log(response.headers['content-disposition'].split("=")[1])
                console.log(response.headers)

                console.log(response.headers['filename'])

                if (response.data['error'] === true) {
                  let message = parse_internal_json(response.data)['payload']['content']['message']
                  store.commit('error_enqueue', message)
                } else {
                  let filename = response.headers['content-disposition'].split("=")[1]
                  var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                  var fileLink = document.createElement('a');

                  fileLink.href = fileURL;
                  fileLink.setAttribute('download', filename);
                  document.body.appendChild(fileLink);

                  fileLink.click();
                }
              }
          )
      store.commit('set_spinner_state', false)
    },


    async call_for_unique_coversheet() {
      store.commit('set_spinner_state', true)
      let target_url = be_main_address('sm') + 'download_email_upload_unique_cover_sheet'

      let the_agency = store.getters.org_context
      console.log(`The agency is ${the_agency}.`)

      // let speedy_user_ = localStorage.getItem('SD_selected_cg_username')
      // console.log(`The selected Speedy user is ${this.selected_caregiver}`)


      let doc_title = stripNonAlphanumeric(this.$refs.input_doc_title.get_value())
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: the_agency,
        speedy_user: localStorage.getItem('SD_selected_cg_username'),
        document_expiration: this.date_unique_,
        document_title: doc_title

      }
      console.log("Make a call for unique coversheet...")
      console.log(data1)
      await axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
                console.log("Attempt to print headers")
                console.log(response.headers['content-disposition'].split("=")[1])
                console.log(response.headers)

                console.log(response.headers['filename'])

                if (response.data['error'] === true) {
                  let message = parse_internal_json(response.data)['payload']['content']['message']
                  store.commit('error_enqueue', message)
                } else {
                  let filename = response.headers['content-disposition'].split("=")[1]
                  var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                  var fileLink = document.createElement('a');

                  fileLink.href = fileURL;
                  fileLink.setAttribute('download', filename);
                  document.body.appendChild(fileLink);

                  fileLink.click();
                }
              }
          )
      store.commit('set_spinner_state', false)
    },


    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        console.log(`New size is ${new_w}`)
        this.button_img_size_ = "80px"
        this.button_height_ = "60px"
        this.button_width_ = "250px"

      }
      if (new_w >= 280) {
        console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w >= 400) {
        console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w > 500) {
        console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w > 1000) {
        console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "325px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        // this.button_height = "80px"
        // this.button_width_ = "100px"
      }

    },
    get_min_expiration_date() {
      let ts = getFutureUTCTimestamp(null, 1, 0, 0)
      return formatTimestamp(ts, 1)
    },
    get_max_expiration_date() {
      let ts

      ts = getFutureUTCTimestamp(null, 0, 0, 10)
      return formatTimestamp(ts, 1)
    },
    reset_date_picker() {
      const my_fp = document.querySelector("#fp_field")._flatpickr;
      my_fp.clear();
      my_fp.setDate(this.get_default_expiration_date())
    },
    change_date_format(old_date_str) {
      let ts = parseDateStringToTimestamp(old_date_str)
      return formatTimestamp(ts)
    },
    // get_processed_expiration_date() {
    //   let old_str = this.date_ || this.get_default_expiration_date()
    //   return this.change_date_format(old_str)
    //
    // },
    get_default_expiration_date() {
      let m, d, y, the_past, show_tz, tz_type
      m = 0
      d = 0
      y = 1
      the_past = false
      show_tz = false
      tz_type = 'short'

      let future_ts = getFutureUTCTimestamp(null, d, m, y, the_past)
      // console.log(`The future timestamp I have come up with is ${future_ts}`)
      let res = formatTimestamp(future_ts, 1, show_tz, tz_type)
      // console.log(`The resulting string I have come up with is ${res}`)
      return res

    },
    dl_date_picker_changed(e) {
      // console.log('Drivers licenseDate picker changed')
      // console.log(e)
      let obj = e[0]
      if (!obj) {
        let str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        this.date_ = str
      } else {
        let str = translate_dateobj_to_datestring(obj, 4)
        this.date_dl_ = str
      }

    },
    ci_date_picker_changed(e) {
      // console.log('Car insurance Date picker changed')
      // console.log(e)
      let obj = e[0]
      if (!obj) {
        let str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        this.date_ = str
      } else {
        let str = translate_dateobj_to_datestring(obj, 4)
        this.date_ci_ = str
      }

    },

    unique_picker_changed(e) {
      // console.log('Car insurance Date picker changed')
      // console.log(e)
      let obj = e[0]
      if (!obj) {
        let str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        this.date_ = str
      } else {
        let str = translate_dateobj_to_datestring(obj, 4)
        this.date_unique_ = str
      }

    },
  },
  computed: {
    action_button_width() {
      return this.button_width_
    },
    action_button_height() {
      return this.button_height_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>


<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  p {
    font-size: 22px;
  }

  .material_icon {
    height: 30px;

  }

  .doc_selected_indicator {
    width: 270px;
  }

  .saved_indicator p {
    font-weight: 800;
    font-size: 16px;
    line-height: 1.6;
  }

  .saved_indicator .material_icon {
    /*margin-left: 15px;*/
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  .uniques {
    margin-top: 60px;
  }

  .data_entry {
    margin-top: 40px;
  }

  .data_entry_top {
    margin-top: 30px;
  }

  .doc_upload_widget {
    background-color: var(--cyanic-blue-light);
    height: 1000px;
    width: 280px;
    padding: 30px;
    margin-bottom: 20px;
    /*box-sizing: border-box;*/
  }

  .doc_title_box {

  }

  .doc_expiration_picker {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {
  //margin-top: 20px;
  }

}

@media (min-width: 1000px) {
  .doc_upload_widget {

    height: 1000px;
    width: 500px;
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    /*box-sizing: border-box;*/
  }

  h1 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {

  .material_icon {
    height: 36px;
  }

  .date_picker {
    margin-bottom: 10px;
  }

  p {
    font-weight: 800;
    font-size: 20px;
  }

  h1 {
    font-size: 34px;
    margin-bottom: 20px;
  }

  h2 {

  }

  h3 {
  }

  .data_entry_top {
    margin-top: 10px;
  }

  .data_entry {
    margin-top: 20px;
  }

  .data_section {
    margin-top: 80px;
  }

  .uniques {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .doc_upload_widget {
    margin-top: 40px;

    height: 1000px;
    width: 500px;
    padding: 30px;
    /*box-sizing: border-box;*/
  }

  .doc_title_box {

  }

  .doc_expiration_picker {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>