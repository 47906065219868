<template>
  <section class="screen_block bg_whitesmoke margin_b_20">
    <div class="flex_centered_column">
      <div class="flex_centered_row padding_b_10 font_raleway font_weight_600 margin_t_20">
        {{ label }}
      </div>


         <!--      If you want to create a state, you must select a Country-->
      <drop_down_select
          v-if="purpose === 'state' || purpose === 'market' || purpose === 'neighborhood'"
          class="margin_b_10"
          ref="drop_down_child_country"
          title="Select Country"
          :values=country_listing
          @value-selected="country_selected"
      ></drop_down_select>

       <!--      If you want to create a market, you must select a Country and a state-->
      <drop_down_select
          v-if="purpose === 'market' || purpose === 'neighborhood'"
          class="margin_b_10"
          ref="drop_down_child"
          title="Select State"
          :values=state_listing
          @value-selected="state_selected"
      ></drop_down_select>


       <!--      If you want to create a neighborhood, you must select a Country and a state and a market-->
      <drop_down_select
          v-if="purpose === 'neighborhood'"
          class="margin_b_10"
          ref="drop_down_child"
          title="Select Market"
          :values=market_listing
          @value-selected="market_selected"
      ></drop_down_select>


      <div class="flex_centered_column res_width margin_lr_10 ">
        <input class="margin_b_10 b_rad_10 text_align_center" v-model="text1_" :placeholder="placeholder1">
      </div>

      <div class="flex_space_around_row flex_wrap margin_tb_10">
        <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :uuid="child_toggle_uuid"
                      :state_disabled="!(text1)"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        ></basic_toggle>

        <launch_button
            ref="button_child"
            :uuid="child_button_uuid"
            :button_state_start=button_state_start
            :button_text=button_text
            @launch="launch"
        >
        </launch_button>


    </div>
      </div>
  </section>
</template>

<script>
import basic_toggle from "@/components/parts/basic_toggle";
import launch_button from "@/components/parts/launch_button";
import drop_down_select from "@/components/parts/drop_down_select";
import {v4 as uuidv4} from "uuid";
// import store from "@/store";

export default {
  name: "geography_creator",
  props: ["label",'toggle_true_message','button_text', 'button_state_start', 'placeholder1', 'purpose', 'event_name',
    'precheck_event_name', 'country_listing','state_listing','market_listing','country_selection_event',
    'state_selection_event', 'market_selection_event'],
  components: {
    basic_toggle,
    launch_button,
    drop_down_select
  },
  // async updated() {
  //   try {
  //     await this.$nextTick(function () {
  //       console.log("inside nextTick updater")
  //       console.log(this.button_state_start)
  //       // if(this.original_image_available){
  //       //   this.advance_state("original_image_available")
  //       // }else{
  //       // }
  //     })
  //   } catch (error) {
  //     //pass
  //   }
  // },
  data() {
    return {
      text1_: "",
      child_button_uuid: uuidv4(),
      child_toggle_uuid: uuidv4()
    }
  },
  methods: {
    do_nothing(){
      console.log("Busy doing something next to nothing.")
    },
    toggle_is_true() {
      console.log("Child toggle reports true, emitting precheck.")
      // store.commit('notification_enqueue', this.toggle_true_message)
      this.$emit(this.precheck_event_name, this.text1, this.uuid)

    },
    toggle_is_false() {
      console.log("Child toggle reports false")
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_state_to_false();
    },
    launch() {
      this.$emit(this.event_name, this.text1, this.uuid)
    },
    country_selected(state){
      this.$emit(this.country_selection_event, state, this.uuid)
    },
    state_selected(state){
      this.$emit(this.state_selection_event, state, this.uuid)
    },
    market_selected(state){
      this.$emit(this.market_selection_event, state, this.uuid)
    },

    reset() {
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_state_to_false();
      this.reset_text1()
      // this.reset_text2()
      // this.reset_text3()
      // this.reset_text4()
    },
    reset_text1() {
      this.text1_ = ""
    },

    toggle_state_to_false() {
      this.$refs.toggle_child.toggle_state_to_false();
    },
    toggle_state_to_true() {
      this.$refs.toggle_child.toggle_state_to_true();
    },
    button_state_to_false() {
      this.$refs.button_child.button_state_to_false();
    },
    button_state_to_true() {
      this.$refs.button_child.button_state_to_true();
    },
  },
  computed: {
    text1() {
      return this.text1_
    },
    purpose_1(){
      return this.purpose
    },

  },

}
</script>

<style scoped>

</style>