<template>

  <div class="placement  p_fx z_400">
    <div class="flex_centered_row">
      <div class="screen_block p_fx z_401 main_form " v-if="show_form"
           @click="emit_state">
        <div class="flex_fully_centered_column">
          <div :id="heading_text" class="font_raleway text_align_center margin_tb_10 margin_b_30 pop_up_heading">{{ heading }}</div>
          <div id="svg_image_string" class="svg_sizing"></div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {colors} from "@/library";

export default {
  name: "solo_image",
  props: ['show_form', 'mobile_mode', 'event_name', 'heading', 'form_color', 'heading_color',
    'heading_size_px', 'heading_bold', 'svg_str'],
  components: {},
  async mounted() {
    this.heading_text = uuidv4();
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // console.log("inside pop up form updated")
        // console.log(`show_form currently equals ${this.show_form}`)

        let header_part = document.getElementById(this.heading_text);
        // let header_part = document.querySelector(".pop_up_heading");
        let form = document.querySelector(".main_form");
        // console.log(`form result is: ${form}`)
        // console.log(`header_part result is: ${header_part}`)
        // console.log(`header_part heading color  is: ${this.heading_color}`)
        // console.log(`svg-string passed in is: ${this.svg_str}`)

        form.style.background = this.form_color
        header_part.style.color = this.heading_color
        header_part.style.fontSize = this.heading_size_px
        if (this.heading_bold) {
          header_part.style.fontWeight = '800'
        }
        let container = document.getElementById("svg_image_string");
        console.log(`svg container result is: ${container}`)
        container.innerHTML = this.svg_str;
      })
    } catch (error) {
      // console.error("Expected error on premature pop up form mount attempt")
      return null
    }


    try {
      await this.$nextTick(function () {
        // console.log("inside pop up form updated")
        // console.log(`show_form currently equals ${this.show_form}`)

        let header_part = document.getElementById("heading_text");
        // let header_part = document.querySelector(".pop_up_heading");
        let form = document.querySelector(".main_form");
        // console.log(`form result is: ${form}`)
        // console.log(`header_part result is: ${header_part}`)
        // console.log(`header_part heading color  is: ${this.heading_color}`)

        form.style.background = this.form_color
        header_part.style.color = this.heading_color
        header_part.style.fontSize = this.heading_size_px
        if (this.heading_bold) {
          header_part.style.fontWeight = '800'
        }
        let container = document.getElementById("svg_image_string");
        container.innerHTML = this.svg_str;
      })
      // let bg = this.$el
      // const form = bg.querySelector(".main_form");
      // form.style.background = this.form_color

      // let whole = document.querySelector('.placement');
      //
      // whole.addEventListener('scroll', function (e) {
      //
      //   e.preventDefault();
      //
      // }, false);

    } catch (error) {
      // console.error("Expected error on premature pop up form mount attempt")
      return null
    }
    // let bg = this.$el
    // const form = bg.querySelector(".main_form");
    // form.style.background = this.form_color


    // const video = document.querySelector("video");
    // const still = document.getElementById("still_image");
  },
  data() {
    return {
      colors: colors,
      heading_text: '',
    }
  },
  methods: {
    emit_state() {
      this.$emit(this.event_name, this.toggle_state, this.mobile_state)
    },
    // emit_baseline_input_values(){
    //   this.$refs.std_bill_rate_input.emit_state();
    // },

  },
  computed: {},
}
</script>

<style scoped>

#svg_image_string {
}

/*.svg_sizing{*/
/*  width: 50%;*/
/*}*/

.screen_block_for_image {
  border-radius: .5rem;
  box-shadow: 2px 2px 2px var(--eli_gray);
  width: 100vw;
}


.main_form {
  width: 100%;

  top: 10%;
  margin-left: 100vw;

  bottom: 15%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: scroll;
}

/*height: 50%;*/
/*background-color: var(--new-primary-purple-deep);*/
/*background-color: transparent;*/

.pop_up_heading {

}


.placement {

  /*margin-top: 10vh;*/
  /*margin-left: 50vw;*/

  /*overflow-y: scroll;*/
  /*overflow-x: hidden;*/
}

</style>