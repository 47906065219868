<template>
  <notification_popup></notification_popup>
  <error_popup_queue></error_popup_queue>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto  ">
    <div class="flex_centered_column ">
      <img class="speedydelivery_img padding_tb_30"
           src="@/assets/speedydelivery_logo.svg"
           alt="SpeedyDelivery Logo">
<!--      <div class="font_raleway font_weight_800 margin_b_20 text_25">SpeedyDelivery</div>-->
    </div>
  </div>
   <spinner
        :spinner_up="spinner_up"
     ></spinner>

  <div class="screen_block margin_tb_10 flex_centered_column bg_whitesmoke">
    <select_and_launch_2
      class="margin_auto bg_whitesmoke font_raleway font_weight_800"
      label='Subscribe a PDA to SpeedyDelivery '
      :label_color=colors.black
      selection_title="PDAs"
      button_text="Subscribe"
      :values="non_subscribing_pdas"
      event_name="create_pda_subscription"
      @create_pda_subscription="create_pda_subscription"
  ></select_and_launch_2>
  </div>

  <div class="screen_block margin_t_20 flex_centered_column bg_whitesmoke margin_b_20">
  <select_and_launch_2
      class="margin_auto bg_whitesmoke font_raleway font_weight_800"
      label='Suspend a PDA from SpeedyDelivery'
      :label_color=colors.black
      selection_title="PDAs"
      button_text="Suspend"
      :values="good_standing_subs"
      event_name="suspend_pda_subscription"
      @suspend_pda_subscription="do_nothing"
  ></select_and_launch_2>
  </div>

   <div class="screen_block bg_lauren_gray padding_tb_30 margin_b_30 flex_centered_column">
    <h3 class="text_align_center flex_centered_column">Force SD Document Build</h3>
        <input_with_label
          ref="user_name_for_sd_doc"
          class="form_input  margin_t_40"
          label="User Name"
          @emit_state="catch_sd_doc_username"
      ></input_with_label>

      <input_with_label
          ref="agency_for_sd_doc"
          class="form_input margin_b_40 "
          label="Agency"
          @emit_state="catch_sd_doc_agency"
      ></input_with_label>

       <basic_text_button
          class="padding_lr_6 padding_tb_6 bottom_button margin_b_40"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.cyanic_blue"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="125px"
          border_rad_pct="0"
          text="Build"
          text_size="14px"
          :bold="true"
          event_name="launch_doc_rebuild"
          @launch_doc_rebuild=queue_forced_sd_doc_rebuild
      ></basic_text_button>

  </div>

</template>

<script>
/* eslint-disable */
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import {be_main_address, colors, parse_internal_json} from "@/library";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import axios from "axios";
import {mapGetters} from "vuex";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import input_with_label from "@/components/parts/input_with_label.vue";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";

export default {
  name: "in_this_space_controls",
  components: {
    notification_popup, error_popup_queue,
    input_with_label, basic_text_button,
    select_and_launch_2,
    spinner
  },
  async mounted() {
    // console.log("tmh_freetime_controls running mounted hook")
    await this.local_page_update()
  },
  data() {
    return {
      colors: colors,
      rebuild_docs_caregiver: "",
      rebuild_docs_agency: "",
      non_subscribing_pdas_: "",
      good_standing_subs_: "",
      selected_terminatee_: "",
      selected_suspendee_: '',
      selected_subscriber_: '',

    }
  },
  methods: {
    do_nothing(){
      console.log("You clicked a button.")
    },

     async local_page_update() {
      // await this.get_list_of_good_subscribers()
      await this.do_nothing()
    },
    catch_sd_doc_agency(e){
      console.log("Inside catch sd doc agency")
      console.log(e)
      this.rebuild_docs_agency = e
    },
    catch_sd_doc_username(e){
      console.log("Inside catch sd doc username")
      console.log(e)
      this.rebuild_docs_caregiver = e
    },


    async queue_forced_sd_doc_rebuild() {
      let target_url = be_main_address('main') + 'force_speedydelivery_document_build'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null,
        caregiver: this.rebuild_docs_caregiver,
        agency: this.rebuild_docs_agency,
      }

      console.log(data1)

      console.log("Calling backend to force SD doc build.")

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response from queing the document build.")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              }else{
                 let message = parse_internal_json(response.data)['data']['message']
                console.log("Here is the response from queuing the document rebuild.")
                console.log(response.data)
                // this.non_subscribing_pdas_ = response.data
                 store.commit('notification_enqueue', message)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

          this.$refs.user_name_for_sd_doc.reset_state()
          this.$refs.agency_for_sd_doc.reset_state()


    },

    // async get_list_of_good_subscribers() {
    //   let target_url = be_main_address('ft') + 'get_good_subscribers'
    //   // let target_url = 'http://192.168.1.10:8087/ft/get_good_subscribers'
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     subscriber: null
    //   }
    //
    //   axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" | response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             this.good_standing_subs_ = response.data
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },

    async subscribe(subscribee) {
      store.commit('set_spinner_state', true)
      let target_url = be_main_address('sd') + 'create_subscription'
      // let target_url = 'http://192.168.1.10:8087/ft/create_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        // subscriber: "",
        subscribing_agency: subscribee

      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
              let message = `${subscribee} has been subscribed to SpeedyDelivery.`
              store.commit('notification_enqueue', message)
            } else {
               console.log("SERVER ERROR")
              console.log(response)
              store.commit('set_spinner_state', false)
              return "this is an error"
            }
            store.commit('set_spinner_state', false)
          })
    },

    async suspend(subscriber) {
      // let target_url = 'http://192.168.1.10:8087/ft/suspend_subscription'
      let target_url = be_main_address('sh') + 'suspend_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: subscriber
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios suspend...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
            } else {
             console.log("SERVER ERROR")
              console.log(response)
              return "this is an error"
            }
          })
    },

    async create_pda_subscription(state){
      console.log(`Caught the create subscription event for ${state}.`)
      this.selected_subscriber_ = state
      await this.subscribe(state)
      await this.local_page_update()
    },

    async suspend_pda_subscription(state){
      console.log(`Caught the suspend subscription event for ${state}.`)
      this.selected_suspendee_ = state
      await this.suspend(state)
      await this.local_page_update()
    },
  },
  computed: {
    ...mapGetters([
      'current_error_message'
    ]),
    spinner_up(){
      return store.getters.get_spinner_state
    },
    selected_subscriber() {
      return this.selected_subscriber_
    },
    selected_suspendee() {
      return this.selected_suspendee_
    },
    selected_terminatee() {
      return this.selected_terminatee_
    },
    good_standing_subs() {
      return this.good_standing_subs_
    },
    non_subscribing_pdas() {
      return this.non_subscribing_pdas_
    },
  },

}
</script>

<style scoped>

.speedydelivery_img{
  width: 200px;
}

</style>