<template>
  <div
      class="agency_message_display flex_centered_row margin_auto_lr  padding_tb_5
                padding_lr_6  margin_t_20 font_raleway" :id="message_id">
    {{ message }}
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "agency_message",
  props: ['message', 'bg_color', 'text_color', 'text_size',],
  components: {},
  data() {
    return {
      message_id: ""
    }
  },
  async mounted() {
    this.message_id = uuidv4();

    await this.$nextTick(function () {
      try {
        let the_message = document.getElementById(this.message_id)
        the_message.style.background = this.bg_color
        the_message.style.color = this.text_color
        the_message.style.fontSize = this.text_size + "px"
      } catch (error) {
        // console.error(error)
      }
    })

  },
}
</script>

<style scoped>
.agency_message_display {
  background-color: var(--pennie_gray);
  color: black;
  max-width: 90vw;
  white-space: pre-line;
  overflow-wrap: break-word;
}
@media (min-width: 100px) {
  .agency_message_display{
    font-size: 20px;
  }
}

</style>