<template>
  <section class="core_c flex_centered_start_column">

    <inside_banner
        mode="spyglass_agency"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="local_page_update"
    ></basic_page_infrastructure>

    <img @click="profile_button_clicked" class="cursor_ptr spyglass_icon margin_tb_10"
         :src="require('@/assets/' + 'spyglass2_color.svg')" alt="Center icon">

    <div class="screen_block flex_centered_column  bg_whitesmoke margin_t_25">
      <div class="font_raleway font_weight_600 text_20 margin_lr_10 margin_t_20 text_align_center">Preview Agency
        Landing Page
      </div>
      <basic_icon_button
          class="cursor_ptr margin_tb_20 shadow_3d"
          :button_color="colors.new_cyanic_main"
          :button_color_not_ready="colors.pennie_gray"
          :ready="landing_preview_available"
          button_height="40px"
          button_width="80px"
          border_rad_pct="0%"
          image_path='rt_arrow.svg'
          image_width="20px"
          alt_text="Preview Agency Landing Page button"
          event_name="preview_agency_landing"
          @preview_agency_landing="go_to_agency_landing_preview"
      ></basic_icon_button>
    </div>

    <div class="">
      <app-tabs :tabList="tabList">
        <template v-slot:tabPanel-1>
          <div class="flex_centered_row">
            <create_ad></create_ad>
          </div>

        </template>
        <template v-slot:tabPanel-2>
          <div class="flex_centered_row">
            <update_agency_landing
                :landing_headline=landing_headline
                :landing_body=landing_body
                @landing_content_updated="go_to_agency_landing_preview"
            ></update_agency_landing>
          </div>

        </template>
        <template v-slot:tabPanel-3>
          <div class="flex_centered_row">
            <click_stats
                :click_data="clicks"
            ></click_stats>
          </div>

        </template>
      </app-tabs>
    </div>

    <div class="flex_start_row flex_wrap ad_handles_list">
      <div class="" v-for="item in plus_ad_handles" :key="item.message">
        <plus_ad_handle
            class="margin_tb_10 margin_lr_10"
            :body="item.body"
            :headline="item.headline"
            :expires="item.expires"
            :neighborhood="item.neighborhood"
            :user_publisher="item.published_by"
            :ad_id="item.uuid"
            :ad_handle_values=item
            @retire_ad="retire_plus_ad"
            @extend_ad="extend_plus_ad"
        ></plus_ad_handle>
      </div>
    </div>

    <div class="flex_start_row flex_wrap ad_handles_list">
      <div class="" v-for="item in ad_handles" :key="item.message">
        <ad_handle
            class="margin_tb_10 margin_lr_10"
            :body="item.body"
            :headline="item.headline"
            :expires="item.expires"
            :neighborhood="item.neighborhood"
            :user_publisher="item.published_by"
            :ad_id="item.ad_id"
            :ad_handle_values=item
            @retire_ad="retire_ad"
            @extend_ad="extend_ad"
        ></ad_handle>
      </div>
    </div>


    <!--    <error_popup_queue></error_popup_queue>-->
    <!--    <notification_popup></notification_popup>-->

  </section>

  <outside_footer
      class="margin_t_25 footer_c"
      @link_clicked=do_nothing()
  >
  </outside_footer>

</template>
<script>
/* eslint-disable */
import {
  analytic,
  colors,
  get_pda_home_route,
  prime_socket,
  receive_over_socket,
  agency_landing_content_get_from_localdatabase,
  get_logo_image_from_local_db,
  be_main_address,
  parse_internal_json, load_plus_ad_handles_from_local_db,
  sync_check,
  get_ads_from_local_database, get_all_trad_ad_views_and_clicks, load_trad_ad_handles_from_local_db
} from "@/library";

import basic_banner_new from "@/components/parts/basic_banner_new";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_text_button from "@/components/parts/basic_text_button";
import label_with_input_text_area from "@/components/parts/label_with_input_text_area";
import basic_toggle from "@/components/parts/basic_toggle";
import ad_handle from "@/components/SpyGlass/ad_handle";
import plus_ad_handle from "@/components/SpyGlass/SpyGlassPlus/plus_ad_handle.vue";
import AppTabs from "@/components/parts/AppTabs";
import create_ad from "@/components/SpyGlass/create_ad";
import update_agency_landing from "@/components/SpyGlass/update_agency_landing";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import click_stats from "@/components/SpyGlass/click_stats";
import store from "@/store";
import {Database} from "@/client_db";
import axios from "axios";
import {mapGetters} from "vuex";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";


export default {
  name: "spyglass_agency_interface",
  components: {
    basic_page_infrastructure,
    basic_banner_new,
    basic_icon_button,
    basic_text_button,
    label_with_input_text_area,
    basic_toggle,
    ad_handle,
    plus_ad_handle,
    AppTabs,
    create_ad,
    update_agency_landing,
    error_popup_queue,
    notification_popup,
    inside_banner,
    outside_footer,
    click_stats
  },

  async beforeMount() {
    // await prime_socket(this.local_message_handler)
  },
  async mounted() {
    console.log("Running mounted")
    console.log(`Current org context is ${store.getters.org_context}`)


    // let trad_ad_views = await get_all_trad_ad_views_and_clicks()
    // console.log(trad_ad_views)

    // console.log(`ON MOUNT the proposed preamble is ${store.getters.proposed_preamble}`)
    // console.log(`ON MOUNT the dev proposed preamble is ${localStorage.getItem('dev_proposed_preamble')}`)
    this.db = new Database();
    await this.get_agency_ad_clicks()

    // This is a library function
    // this.ad_data_from_database = await get_ads_from_local_database(this.db)

    // console.log("PRINTING THE AD DATA FROM THE DATABASE................................")
    console.log(this.ad_data_from_database)


    // THis deals with ad clicks on traditional spyglass ads on the internet
    for (const property in this.ad_data_from_database) {
      let ad_id = this.ad_data_from_database[property]['ad_id']
      let headline = this.ad_data_from_database[property]['headline']
      // console.log(`${this.ad_data_from_database[property]['ad_id']}: ${this.ad_data_from_database[property]['headline']}`);
      // console.log("Ad id and headline")
      // console.log(`${ad_id}: ${headline}`);
      this.ad_data_dict[ad_id] = [headline, this.clicks[ad_id]]
    }
    console.log(this.ad_data_dict)

    await this.get_agency_phone_number()
    // await this.load_ad_handles_from_local_db()


    // this.ad_handles_ = await load_trad_ad_handles_from_local_db(this.db)
    // this.plus_ad_handles_ =  await load_plus_ad_handles_from_local_db(this.db)


    let ret = await agency_landing_content_get_from_localdatabase(this.db)
    // await this.get_agency_landing_text(store.getters.org_context)
    console.log('Here are the plus ad handles.')
    console.log(this.plus_ad_handles_)


    //This deals with making changes to the Ad Model for Traditional SpyGlass ads on the internet.
    if (ret['body'] !== undefined && ret['headline'] !== undefined) {
      if (ret['body'].length >= 10 && ret['headline'].length >= 10) {
        console.log("Landing body and headline are both least 10 characters long.")
        store.commit('spyglass_set_agency_landing_headline', ret['headline'])
        store.commit('spyglass_set_agency_landing_body', ret['body'])
      }
    } else {
      console.log("there is no landing body saved in the local database.")
    }


    // await prime_socket(this.local_message_handler)
    await this.local_page_update()
    // this.run_window_width_adjustments(this.windowWidth)
    // add_class_to_body('content_no_radial')
    await get_logo_image_from_local_db(this.db)


    // add_class_to_body('content')
    await store.dispatch('notification_restart')
  },
  data() {
    return {
      db: "",
      colors: colors,
      publish_ad_ready_: false,
      tabList: ["Create Ad", "Agency Landing", "Click Stats"],
      agency_phone_: "",
      clicks_: "",
      ad_data_from_database: "",
      ad_data_dict: {},
      ad_handles_: [],
      plus_ad_handles_: [],
      ad_ready: {
        headline: false,
        body: false
      },
      landing_text_: "",
      landing_text_content: {
        hl: "",
        body: ""
      },
    }
  },
  methods: {
    do_nothing() {
      console.log('doing nothing')
    },
    async local_page_update() {
      console.log("Firing up local_page_update and load_ad_handles_from_local_db")
      this.ad_handles_ = await load_trad_ad_handles_from_local_db(this.db)
      this.plus_ad_handles_ = await load_plus_ad_handles_from_local_db(this.db)
    },
    async get_agency_landing_text(agency = null) {
      if (agency) {
        //pass
      } else {
        agency = store.getters.spyglass_agency_landing_public_get_agency_name
      }

      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_landing_text'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.landing_text_ = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
        // console.log('HERE IS IS.............................')
        // console.log(this.landing_text_)
      })
    },

    async get_agency_phone_number() {
      console.log("Calling to get the agency phone number.")
      // let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let data1 = {
        agency: this.agency_name
      }
      console.log(`AGency: ${this.agency_name}`)
      let endpoint = be_main_address('spyglass') + 'get_agency_phone_number'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        this.agency_phone_ = all['data']['data']
        console.log("Back with the phone number")
        console.log(`Phone: ${this.agency_phone_}`)
        store.commit("spyglass_agency_landing_set_phone", this.agency_phone_)
      })
    },

    async get_agency_ad_clicks() {
      console.log("Calling to get the agency ad clicks for 10 days of clicks.")
      // let agency = store.getters.spyglass_agency_landing_public_get_agency_name
      let data1 = {
        caller: store.getters.my_name,
        subscriber: this.agency_name,
        limit: 1,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }
      // console.log(`AGency: ${this.agency_name}`)
      let endpoint = be_main_address('spyglass') + 'get_agency_clicks'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log("Back from call to get agency clicks")
        console.log(response)
        console.log(parse_internal_json(response))
        let all = parse_internal_json(response)
        this.clicks_ = all['data']['data']
        console.log("Back with the clicks")
        console.log(this.clicks_)


        // console.log(`Phone: ${this.clicks_}`)
        // store.commit("spyglass_agency_landing_set_phone", this.agency_phone_)
      })
    },

    // async load_ad_handles_from_local_db() {
    //   //  Populate local variable with ad assets from IndexedDB.
    //   //[  ['people', '{me}'],['org', '{pda_name}'],['div', 'profile'], ['div', 'images']]
    //   // let me = store.getters.my_name
    //   // let pda = store.getters.org_context
    //
    //   let search = {
    //     key: '',
    //     additional_context: [['div', store.getters.org_context], ['div', 'ads']],
    //     base_context: store.getters.find_current_preamble
    //   }
    //
    //   // console.log("Here is what I am going to search......")
    //   // console.log(search)
    //
    //   // let db_target_item = await this.db.db_search(search)
    //   this.ad_handles_ = await this.db.db_search(search)
    //
    //   // console.log("here is what came back looking for ad handles.")
    //   // console.log(JSON.stringify(this.ad_handles_))
    //
    // },


    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)

      let update = await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      if (update) {
        store.commit('increment_universal_page_update', true)
      } else {
        console.log("The socket message may have called for the update directly.")
      }
    },

    reset_ad() {
      this.ad_ready.body = false
      this.ad_ready.headline = false
      this.$refs.ad_body_text.reset()
      this.$refs.ad_headline_text.reset()
      this.$refs.ad_launch_toggle.toggle_state_to_false()
    },
    publish_new_ad() {
      let headline = this.$refs.ad_headline_text.text_value_.substring(0, 49)
      let body_text = this.$refs.ad_body_text.text_value_.substring(0, 499)
      //send this new ad to backend, get back ad in local database.
      //Delete the current ad text
      // this.$refs.ad_headline_text.

      this.reset_ad()
    },
    toggle_is_true() {
      this.publish_ad_ready_ = true
    },
    toggle_is_false() {
      this.publish_ad_ready_ = false
    },
    async profile_button_clicked() {
      analytic("01:01:16:211")
      // console.log("inside profile_button_clicked")
      try {
        // this.hamburger_backer_clicked()
        // this.$emit('hamburger_backer_clicked', true)
        // await this.$router.push({path: `${this.get_profile_route_()}`})
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        // console.log(error)
      }

    },
    async go_to_agency_landing_preview() {
      let available = this.landing_preview_available
      // store.commit('spyglass_agency_landing_set_ready_to_save', false)
      console.log(`Preview available? ${available}`)
      if (!available) {
        let m = "To preview the Agency Landing page, you must first upload your agency logo " +
            "on the agency profile page and publish a landing preview on this page."
        store.commit('notification_enqueue', m)
        return null
      }
      let org = store.getters.org_context
      store.commit('update_context_changing_route', false)
      let new_url = `/pda/${org}/spyglass/agency_preview`
      await this.$router.push({path: new_url})
    },

    async retire_plus_ad(ad_id) {
      // console.log("in retire ad")
      // if(ad_id){
      //   console.log(`Ad id was ${ad_id}}`)
      // }
      // if(ad_id === undefined){
      //   console.log("There was no id, using the test id.")
      //   ad_id = '0474ac3ce74642d488c84bceb2ce56a7'
      // }
      console.log("you just clicked to retire a plus ad.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: {
          'ad_id': ad_id,
        }
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'retire_plus_ad'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {

        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          let message = "Ad has been deleted."
          store.commit('notification_enqueue', message)
          sync_check()
        }
      }, (error) => {
        console.log('Error on retiring ad.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to retire an ad.")
      });

    },

    async extend_plus_ad(ad_id) {
      // console.log("in retire ad")
      // if(ad_id){
      //   console.log(`Ad id was ${ad_id}}`)
      // }
      // if(ad_id === undefined){
      //   console.log("There was no id, using the test id.")
      //   ad_id = '0474ac3ce74642d488c84bceb2ce56a7'
      // }
      console.log("you just clicked to extend a plus ad.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: {
          'ad_id': ad_id,
        }
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'extend_plus_ad'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {

        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          let message = "Ad has been extended."
          store.commit('notification_enqueue', message)
          sync_check()
        }
      }, (error) => {
        console.log('Error on ad extension.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to extend the ad.")
      });

    },


    async retire_ad(ad_id) {
      // console.log("in retire ad")
      // if(ad_id){
      //   console.log(`Ad id was ${ad_id}}`)
      // }
      // if(ad_id === undefined){
      //   console.log("There was no id, using the test id.")
      //   ad_id = '0474ac3ce74642d488c84bceb2ce56a7'
      // }
      console.log("you just clicked to retire an ad.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: {
          'ad_id': ad_id,
        }
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'retire_ad'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {

        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          let message = "Ad has been deleted."
          store.commit('notification_enqueue', message)
          sync_check()
        }
      }, (error) => {
        console.log('Error on retiring ad.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to retire an ad.")
      });

    },

    async extend_ad(ad_id) {
      // console.log("in retire ad")
      // if(ad_id){
      //   console.log(`Ad id was ${ad_id}}`)
      // }
      // if(ad_id === undefined){
      //   console.log("There was no id, using the test id.")
      //   ad_id = '0474ac3ce74642d488c84bceb2ce56a7'
      // }
      console.log("you just clicked to extend an ad.")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: {
          'ad_id': ad_id,
        }
      }
      // await this.get_new_route()
      let endpoint = be_main_address('spyglass') + 'extend_ad'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {

        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          let message = "Ad has been extended."
          store.commit('notification_enqueue', message)
          sync_check()
        }
      }, (error) => {
        console.log('Error on ad extension.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong trying to extend the ad.")
      });

    },


  },
  computed: {
    ...mapGetters([
      'socket_connection_counter',
      'window_width'

    ]),
    update_page() {
      console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    processed_ad_data() {
      return this.ad_data_dict
    },
    clicks() {
      return this.clicks_
    },
    agency_phone() {
      return this.agency_phone_
    },
    agency_name() {
      return store.getters.org_context
    },

    ad_handles() {
      return this.ad_handles_
    },
    plus_ad_handles() {
      return this.plus_ad_handles_
    },
    landing_text() {
      return this.landing_text_
    },

    landing_headline() {
      return store.getters.spyglass_get_agency_landing_headline
      // return this.landing_text_['headline']
    },
    landing_body() {
      return store.getters.spyglass_get_agency_landing_body
      // return this.landing_text_['body']
    },
    landing_preview_available() {
      return store.getters.spyglass_preview_is_available
    },
    publish_ad_ready() {
      return this.publish_ad_ready_
    },
    ad_ready_to_publish() {
      return this.ad_ready.headline && this.ad_ready.body
    },
  },
  watch: {
    async update_page() {
      // console.log("update_page watcher is triggered")
      await this.local_page_update()
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },

  },
}
</script>

<style scoped>
.banner_margin_top {
  margin-top: var(--banner_offset3);
}


@media (min-width: 100px) {
  .ad_handles_list {
    max-width: 520px;
  }

  .spyglass_icon {
    width: 150px;
  }
}


@media (min-width: 1050px) {
  .spyglass_icon {
    width: 250px;
  }

  .ad_handles_list {
    max-width: 1050px;
  }
}
</style>