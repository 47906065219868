<template>
  <module_main_page
      module_download_name="295f6e6a-ca20-4cc0-8cfc-9c1f99bf93a3"
      module_logo_name="sd_bill_of_rights.svg"
      :module_desc_text=module_text
      module_headline="Patient Bill of Rights"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "bill_of_rights",
  data() {
    return {
      module_text: "The Patient's Bill of Rights is a list of guarantees for individuals receiving homecare services that outlines their entitlement to quality care. It ensures that patients receiving care at home are aware of their rights and are empowered to exercise them. The Bill of Rights serves as a guide for caregivers, outlining their responsibilities and ethical obligations to their patients. It emphasizes the importance of respecting the patient's dignity, privacy, and autonomy, and promotes open communication and transparency between the caregiver and patient.",
    }
  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>




