<template>
  <div class="core_c">
    <spinner
        :spinner_up="spinner_up"
    ></spinner>
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <continue_enqueue
        @continue_clicked="continue_clicked"
    ></continue_enqueue>
    <landing_banner></landing_banner>
    <!--    <meta name="og:image" property="og:image" content="https://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"/>-->
    <!--     og_image="http://localhost:8089/img/old_hands.de2922bb.jpg"-->
    <headers
        title="Register Discount"
        meta_description="Tiny Magic Hat makes it easy to find a quality home care agency in your neighborhood.."
        og_title="Find Home Care"
        og_url="https://tinymagichat.com/register_discount"
        og_description="Tiny Magic Hat takes the work out of finding a quality home care agency in your neighborhood."
        og_image="http://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"
    ></headers>

    <!--    <basic_page_infrastructure-->
    <!--        @local_page_update="do_nothing"-->
    <!--        @continue_clicked="do_nothing"-->
    <!--    ></basic_page_infrastructure>-->

    <section class="top_section wid_100vw  ">
      <div class="top_section_content ">

        <div v-if="!mobile_mode" class="flex_centered_column">
          <div>
            <img class="padding_tb_20" id="cg_topper_img"
                 src="../../../assets/istock/two_smiles.jpg"
                 alt="A blue easy button.">
          </div>
        </div>

        <div class="flex_centered_column right_side">
          <h1 class="text_align_center">Register your New Client Discount.</h1>
          <p class="text_align_center top_text">
            <!--                Finding the right home care agency for your needs doesn't have to been complicated or time consuming.-->

            <!--                Find the best home care agencies in {{ market }} and their current hourly rates in just a few minutes-->
            <!--                before talking with anyone on the phone.-->
            Fill out the simple form below and register your New Client Discount. All information provided must be
            accurate for a valid discount.

          </p>
          <div class="flex_centered_row flex_wrap neighborhood_list margin_t_20">

            <!--                <p class="text_align_center top_text">Tiny Magic Hat member agencies in {{ market }} represent the-->
            <!--                  top agencies in their local market. They are frequently part of national franchise operations with-->
            <!--                  years of industry experience and are ready to deliver the same excellent care to your or-->
            <!--                  your loved one.</p>-->


          </div>


        </div>
      </div>
    </section>
    <section class="flex_centered_column">
      <div class="main_details_form">
        <h3 class="text_align_center margin_t_40">Provide Complete Details</h3>
        <input_with_label
            ref="searcher_name_box"
            :focus="true"
            :start_value=searcher_name_from_state
            class="login_input"
            label="Your Full Name"
            tag="searcher_full_name"
            @emit_state="add_to_form('searcher_full_name', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_phone_box"
            :focus="true"
            :start_value=formatPhoneNumber(searcher_phone_from_state)
            class="login_input"
            label="Your Phone Number"
            @emit_state="add_to_form('searcher_phone', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_email_box"
            :focus="true"
            class="login_input"
            label="Your Email"
            @emit_state="add_to_form('searcher_email', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_client_name_box"
            :focus="true"
            class="login_input"
            :start_value=client_name_from_state
            label="Client's Full Name"
            @emit_state="add_to_form('client_full_name', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_client_phone_box"
            :focus="true"
            :start_value=formatPhoneNumber(client_phone_from_state)
            class="login_input"
            label="Client's Phone Number"
            @emit_state="add_to_form('client_phone', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_client_address1"
            :focus="true"
            class="login_input"
            label="Client's Street Address"
            @emit_state="add_to_form('client_address_1', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_client_address2"
            :focus="true"
            class="login_input"
            label="Client Apt or Unit # if Applicable"
            @emit_state="add_to_form('client_address_2', $event)"
        ></input_with_label>
        <input_with_label
            ref="searcher_client_city"
            :focus="true"
            class="login_input"
            label="Client City"
            @emit_state="add_to_form('client_city', $event)"
        ></input_with_label>
        <dropdown_datalist
            ref="sd_cg_selection"
            label="State"
            :data_list=the_states
            @value-selected="add_to_form('client_state', $event)"
        ></dropdown_datalist>

        <input_with_label
            ref="searcher_client_zip"
            :focus="true"
            class="login_input"
            label="Client Zip Code"
            @emit_state="add_to_form('client_zip', $event)"
        ></input_with_label>


        <div class="flex_centered_column">

          <div class="text_25 font_raleway  margin_t_40 font_weight_800 text_align_center">Do your require couples
            care?
          </div>
          <Dropdown ref="dropdownManual2" @visible-change="do_nothing" :border="true" :width="300" align="center"
                    class="text_align_center">
            <!-- trigger element -->
            <template #trigger>
              <!--        <button type="button">Click me</button>-->

              <basic_text_button
                  class="margin_t_40"
                  :button_state="true"
                  :button_color="colors.whitesmoke"
                  :text_color="colors.black"
                  :button_color_not_ready="colors.gray"
                  :button_not_ready_text_color="colors.morgie_gray"
                  :hover_color="colors.whitesmoke"
                  :hover_text_color="colors.black"
                  button_height="52px"
                  button_width="300px"
                  border_rad_pct="0"
                  :text=couples_care
                  button_not_ready_text="Nothing"
                  text_size="14px"
                  :bold="true"
                  event_name="no_event"
                  @no_event="catch_couple"
              ></basic_text_button>


            </template>

            <!-- contents display in dropdown -->

            <ul>
              <li v-for="(item, index) of couples_care_status" :key="`item-${index}`"
                  class="cursor_ptr dropdown-item text_align_center font_raleway font_weight_800"
                  @click="change_couple_status(item)">{{ item }}
              </li>
            </ul>


          </Dropdown>

          <div class="text_25 font_raleway  margin_t_60 font_weight_800 text_align_center">What is your relationship to
            the client?
          </div>
          <Dropdown ref="dropdownManual" @visible-change="do_nothing" :border="true" :width="300" align="center"
                    class="text_align_center">
            <!-- trigger element -->
            <template #trigger>
              <!--        <button type="button">Click me</button>-->

              <basic_text_button
                  class="margin_t_40"
                  :button_state="true"
                  :button_color="colors.whitesmoke"
                  :text_color="colors.black"
                  :button_color_not_ready="colors.gray"
                  :button_not_ready_text_color="colors.morgie_gray"
                  :hover_color="colors.whitesmoke"
                  :hover_text_color="colors.black"
                  button_height="52px"
                  button_width="300px"
                  border_rad_pct="0"
                  :text=relationship_to_client
                  button_not_ready_text="Nothing"
                  text_size="14px"
                  :bold="true"
                  event_name="no_event"
                  @no_event="catch_relationship"
              ></basic_text_button>


            </template>

            <!-- contents display in dropdown -->

            <ul>
              <li v-for="(item, index) of relationships" :key="`item-${index}`"
                  class="cursor_ptr dropdown-item text_align_center font_raleway font_weight_800"
                  @click="change_relationship(item)">{{ item }}
              </li>
            </ul>


          </Dropdown>


          <basic_text_button
              class="padding_lr_6 padding_tb_6 bottom_button mailing_list_button"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.black
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.black
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              :button_height=button_height
              :button_width=button_wid
              border_rad_pct="0"
              text="Activate Discount"
              text_size="20px"
              :bold="true"
              event_name="reveal_agency_data"
              @reveal_agency_data=create_discount_certificate()
          ></basic_text_button>
        </div>

      </div>
    </section>

  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  states,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now,
  receive_over_socket,
  be_main_address,
  prime_socket,
  formatPhoneNumber,
  isEmpty,
  parse_internal_json,
  open_spinner, close_spinner, handleDiscountCertificate, get_ip_from_header
} from "@/library";
import {Database} from "@/client_db";
import headers from "@/components/parts/headers.vue";
import landing_banner from "@/components/parts/landing_banner.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import input_with_label from "@/components/parts/input_with_label.vue";
import Dropdown from "v-dropdown";
import dropdown_datalist from "@/components/parts/dropdown_datalist.vue";
import Label_with_input_text_area from "@/components/parts/label_with_input_text_area.vue";
import Contact_form from "@/components/basics/contact_form.vue";
import Continue_enqueue from "@/components/basics/continue_enqueue.vue";
import axios from "axios";
import spinner from "@/components/parts/spinner.vue";

export default {
  name: "register_discount",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    // await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()
    await this.send_discount_registration_page_click_stat()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
  ],
  emits: ['name_of_event'],
  components: {
    spinner,
    Continue_enqueue,
    Contact_form,
    Label_with_input_text_area,
    dropdown_datalist,
    Dropdown,
    input_with_label, basic_text_button,
    landing_banner, headers,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "60px",
      button_width_: "220px",
      columns_: 30,
      data1: null,
      data2: null,
      relationships: ['I am the client', 'Spouse', 'Child', 'Relative', 'Other'],
      relationship_: "",
      couples_care_status: ['No', 'Yes'],
      couples_care_: "No",
      form_data: {
        searcher_full_name: "",
        searcher_phone: "",
        searcher_email: "",
        client_full_name: "",
        client_phone: "",
        client_address_1: "",
        client_address_2: "",
        client_city: "",
        client_state: "",
        client_zip: "",
        couples: false,
        searcher_to_client_relationship: ""
      },
    }
  },
  methods: {
    formatPhoneNumber,
    do_nothing() {
      console.log("do nothing")
    },
    async send_discount_registration_page_click_stat() {
      // Pull market data for each agency.
      console.log(`Sending click data for ${this.market}`);
      let target_url = be_main_address('vortex') + 'record_clicks_market_page';

      get_ip_from_header()
          .then((ip_string) => {
            console.log("Here is the IP I found...");
            console.log(ip_string);

            if (ip_string === undefined) {
              ip_string = 'unknown'
            }

            let res = JSON.parse(localStorage.getItem('vortex_visitor_data'))
            let data1 = {
              market: res['market'],
              state: res['state'],
              ip: ip_string,
              vortex_id: localStorage.getItem('vortex_visitor')
            };


            let prelim_data_dict = store.getters.get_current_vortex_collected_data
            console.log("I just got the preliminary form data from vuex....")
            console.log(prelim_data_dict)

            if (prelim_data_dict['searcher_name']) {
              data1['searcher_full_name'] = prelim_data_dict['searcher_name']
            }
            if (prelim_data_dict['searcher_phone']) {
              data1['searcher_phone'] = prelim_data_dict['searcher_phone']
            }
            if (prelim_data_dict['client_name']) {
              data1['client_full_name'] = prelim_data_dict['client_name']
            }
            if (prelim_data_dict['client_phone']) {
              data1['client_phone'] = prelim_data_dict['client_phone']
            }
            if (prelim_data_dict['agency']) {
              data1['agency'] = prelim_data_dict['agency']
            }
            if (prelim_data_dict['discount']) {
              data1['discount_amount_front_end'] = prelim_data_dict['discount']
            }
            console.log('Here is what I am going to send to the backend....')


            return axios.post(target_url, data1);
          })
          .then((response) => {
            console.log("Just got a response on market click data submission from server.");
            console.log(response);
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message'];
                store.commit('error_enqueue', message);
              } else {
                console.log("Click data has been posted");
                console.log(response.data);
              }
            } else {
              console.log("SERVER ERROR");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log("Error occurred during click data submission:");
            console.log(error);
          });
    },

    catch_couple(e) {
      console.log("Inside catch couple")
      console.log(e)
      this.form_data['couples'] = e
      // this.form_data.searcher_full_name = e
    },

    catch_relationship(e) {
      console.log("Inside catch relationship")
      console.log(e)
      this.form_data.searcher_to_client_relationship = e
      // this.form_data.searcher_full_name = e
    },

    add_to_form(n, e) {
      console.log('Inside add to form.')
      console.log(e)
      console.log(n)
      this.form_data[n] = e
      console.log(this.form_data)


    },
    validateObject(obj, ignore_keys = []) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value === '' || value === null || value === undefined) {

            if (ignore_keys.includes(key)) {
              //pass
            } else {
              return false;
            }

          }
        }
      }
      return true;
    },

    async continue_clicked() {

      console.log("You have agreed to the terms and are going to create a discount.")
      console.log("Send this to the back end")
      // console.log(this.form_data)
      let target_url = be_main_address('vortex') + 'register_discount'
      // let get_current_vortex_collected_data = {
      //   'searcher_name': this.input['searcher_name'],
      //   'searcher_phone': this.input['searcher_phone'],
      //   'client_name': this.input['client_name'],
      //   'client_phone': this.input['client_phone'],
      //   'agency': "",
      //   'discount': ""
      // }

      // form_data: {
      //   searcher_full_name: "",
      //   searcher_phone: "",
      //   searcher_email: "",
      //   client_full_name: "",
      //   client_phone: "",
      //   client_address_1: "",
      //   client_address_2: "",
      //   client_city: "",
      //   client_state: "",
      //   client_zip: "",
      //   couples: false,
      //   searcher_to_client_relationship: ""
      // }
      let prelim_data_dict = store.getters.get_current_vortex_collected_data
      console.log("I just got the preliminary form data from vuex....")
      console.log(prelim_data_dict)

      if (prelim_data_dict['searcher_name']) {
        this.form_data['searcher_full_name'] = prelim_data_dict['searcher_name']
      }
      if (prelim_data_dict['searcher_phone']) {
        this.form_data['searcher_phone'] = prelim_data_dict['searcher_phone']
      }
      if (prelim_data_dict['client_name']) {
        this.form_data['client_full_name'] = prelim_data_dict['client_name']
      }
      if (prelim_data_dict['client_phone']) {
        this.form_data['client_phone'] = prelim_data_dict['client_phone']
      }
      if (prelim_data_dict['agency']) {
        this.form_data['agency'] = prelim_data_dict['agency']
      }
      if (prelim_data_dict['discount']) {
        this.form_data['discount_amount_front_end'] = prelim_data_dict['discount']
      }

      let data1 = {
        form_data: this.form_data,
      }

      console.log("Form data just prior to validation...")
      console.log(this.form_data)
      let form_data_is_valid = this.validateObject(this.form_data, ['client_address_2'])

      if (!form_data_is_valid) {
        store.commit('error_enqueue', 'You must complete all form fields before you submit your discount.')
        return null
      } else {
        //pass
      }

      open_spinner()

      await axios.post(target_url, data1)
          .then((response) => {

                let rrr = JSON.parse(response['data'])

                if (rrr['error'] === false) {
                  console.log("Success on discount registration attempt.")
                  console.log(rrr['message'])
                  store.commit('notification_enqueue', rrr['message'])
                  close_spinner()
                  console.log(`Here is the returned build id and agency phone: ${rrr['data']}`)
                  console.log(rrr['data'])
                  // ref_node_id, agency_phone
                  let build_id = rrr['data']['ref_node_id']
                  let agency_phone = rrr['data']['agency_phone']
                  console.log("build id and phone...")
                  console.log(build_id)
                  console.log(agency_phone)
                  let c = {
                    'key': `new_client_discount_id`,
                    'value': build_id,
                    'cache_time': 3600
                  }
                  store.commit("set_cache_value", c)
                  let d = {
                    'key': `new_client_discount_agency_phone`,
                    'value': agency_phone,
                    'cache_time': 3600
                  }
                  store.commit("set_cache_value", d)

                  return build_id

                } else {
                  console.log("Something went wrong with the discount registration attempt.")
                  console.log(rrr['message'])
                  store.commit('error_enqueue', rrr['message'])
                  return null
                }
              }
          ).then((data) => {
            if (data) {
              // console.log("Made it to part 2")
              // console.log(data)
              handleDiscountCertificate(data, false)
              this.$router.push({path: `/discount_created`})

              // let target_url = be_main_address('vortex') + 'get_discount_certificate'
              // let data2 = {
              //   build_id: data,
              // }
              // axios.post(target_url, data2, {responseType: "blob"})
              //     .then((response) => {
              //       if (response.data['error'] === true) {
              //         let message = parse_internal_json(response.data)['payload']['content']['message']
              //         close_spinner()
              //         store.commit('error_enqueue', message)
              //       } else {
              //         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              //         var fileLink = document.createElement('a');
              //
              //         fileLink.href = fileURL;
              //         fileLink.setAttribute('download', 'Discount Certificate' + '.pdf');
              //         document.body.appendChild(fileLink);
              //         close_spinner()
              //         fileLink.click();
              //         this.$router.push({path: `/hello_landing`})
              //
              //       }
              //     });

            } else {
              return null
            }
          })
      close_spinner()
    },


    create_discount_certificate() {
      //ensure form is complete
      //  this.$refs.searcher_name_box.emit_state()
      // console.log("Insade create the discount certificate")
      // console.log(this.form_data)
      let m = `You understand the information you provide must be complete and correct including full names and phone numbers for your discount to be honored.`
      store.commit('continue_enqueue', [m, true])

      //print form contents
      //send to the backend
    },
    change_relationship(val) {
      console.log("Relationship change event has occured.")
      console.log(val)
      this.relationship_ = val
      let dropdownManual = this.$refs.dropdownManual.close()
      this.catch_relationship(val)

    },

    change_couple_status(val) {
      console.log("Couple change event has occured.")
      console.log(val)
      this.couples_care_ = val
      let dropdownManual = this.$refs.dropdownManual2.close()
      this.catch_couple(val)

    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
        this.columns_ = 30

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
        this.columns_ = 30
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.columns_ = 50
      }
      if (new_w > 600) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.columns_ = 60
      }
      if (new_w > 800) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.columns_ = 80
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.columns_ = 100
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.columns_ = 100
      }
    },
    catch_or_not(key) {
      try {
        let ddd = store.getters.get_current_vortex_collected_data
        return ddd[key]
      } catch (error) {
        return null
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    the_states() {
      return states
    },
    columns() {
      return this.columns_
    },

    searcher_name_from_state() {
      let key = 'searcher_name'
      return this.catch_or_not(key)

    },
    searcher_phone_from_state() {
      let key = 'searcher_phone'
      return this.catch_or_not(key)

    },


    client_name_from_state() {
      let key = 'client_name'
      return this.catch_or_not(key)

    },


    client_phone_from_state() {
      let key = 'client_phone'
      return this.catch_or_not(key)
    },

    couples_care() {
      return this.couples_care_ || 'No'
    },

    relationship_to_client() {
      return this.relationship_ || 'Relationship'
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      console.log(`Current mobile Mode: ${this.mobile_mode_x}`)
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_wid() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {

  .main_details_form {
    width: 75%;
    max-width: 500px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 8px;
    cursor: pointer;
  }

  .dropdown-item {
    //background-color: var(--off-white); background-color: #e0e0e0; /* Change this to your desired hover color */ width: 300px; height: 30px; padding-top: 20px;

  }

  /* Define the hover effect */
  .dropdown-item:hover {
    background-color: #e0e0e0; /* Change this to your desired hover color */
    color: #333; /* Change this to your desired text color on hover */

  }

  .share_row_wrap {
    width: 100vw;
    background: var(--lauren_gray);
    margin-bottom: 60px;
  }

  .share_row {
    width: 90vw;
    max-width: 450px;


  }

  .fb_share {
    width: 80px;
  }

  .mail_share {
    width: 40px
  }

  .mailing_list_text {
    max-width: 800px;
    line-height: 2rem;
    margin-bottom: 30px;
    margin-top: -30px;
  }

  .mailing_list_button {
    margin-top: 80px;
  }

  .learn_more_heading {
    /*margin-top: -50px;*/
    max-width: 500px;
    text-align: center;
  }

  .learn_more_text {
    text-align: center;
    max-width: 500px;
    margin-bottom: 20px;
    margin-top: -30px;
    line-height: 2rem;
  }

  h1 {
    font-size: 40px;
    color: white;
    width: 75%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 32px;
    color: var(--primary-purple);
    width: 75%;
    margin-bottom: 30px;
  }

  .top_explainer p {
    text-align: center;
  }

  .top_explainer {
    max-width: 850px;
    margin-top: 10px;
  }

  .get_notified_text {
    text-align: center;
  }

  .talking {
    text-align: center;
  }

  .neighborhood_list {
    max-width: 90%;
  }

  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    margin-top: -50px;
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    max-width: 90%;
    padding-bottom: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #cg_topper_img {
    width: 350px;
  }
}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {

  /*.top_explainer {*/
  /*  margin-top: 80px;*/
  /*}*/
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .learn_more {
    margin-top: unset;
    /*max-width: ;*/
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 450px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {


  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 390px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 700px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0px;
  }

  .top_text {
    max-width: 800px;
    margin-left: 20px;
    line-height: 1.4;

  }


}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 150px;
  }

  .fb_share {
    width: 100px;
  }

  .mail_share {
    width: 50px
  }


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>