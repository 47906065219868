<template>

  <section class="input_group">
      <textarea :id=id name="the_form_name" v-model="text_value" :rows=rows_count :cols=cols_count maxlength="1000"
                :placeholder=this.ph class="font_raleway the_text_area "  @keyup.enter="enter_pressed">

      </textarea>
  </section>
</template>

<script>
import {get_uuid} from "@/library";

export default {
  name: "input_with_label_text_area",
  props: [
    "label",
    "columns",
    "rows",
      "place_holder_text"

  ],
  async mounted() {
    this.id = get_uuid()


  },
   async updated() {
    // this.id = get_uuid()
    await this.$nextTick(function () {
      try {

        // console.log("PROP ARE*************************************************************************")
        // console.log(this.columns)
        // console.log(this.rows)
        if (this.columns) {
          this.cols_count = this.columns
        }
        if (this.rows) {
          this.rows_count = this.rows
        }
      } catch (error) {
        // console.error(error)
      }

    })
  },
  data() {
    return {
      // label_: "",
      // value_: "",
      id: "",
      cols_count: 26,
      rows_count: 5,
      text_value: "",


    }
  },
  methods: {

    onChange(e) {
      // console.log("in drop down on change")
      this.$emit('value-selected', e.target.value)
    },
    emit_state() {
      console.log(`Emitting ${this.text_value}`)
      this.$emit('emit_state', this.text_value)
    },
     enter_pressed() {
      console.log(`Emitting ${this.text_value}`)
      this.$emit('enter_pressed')
    },
    reset_state() {
      this.text_value = ""
    }
  },
  computed: {
    ph(){
      if(this.place_holder_text){
        return this.place_holder_text
      }else{
        return "What's on your mind?"
      }
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {


  textarea {
     width: calc(100% - 4px);
     padding: 26px;
    border: unset;
    outline: 1px solid var(--morgie_gray);
    box-sizing: border-box;
    font-size: 1rem;
  }
   textarea:focus {
    border: 1px solid var(--primary-purple) !important;
    box-shadow: 0 0 3px var(--primary-purple) !important;
    -moz-box-shadow: 0 0 3px var(--primary-purple) !important;
    -webkit-box-shadow: 0 0 3px var(--primary-purple) !important;
     outline-offset: 0 !important;
    outline: none !important;
  }

}

@media (min-width: 2000px) {
  textarea{
    font-size: 25px;
  }
}


</style>