<template>
  <section v-if="(show_backer) " @click="emit_state"
  >
    <div class="base_backer p_fx z_400 cursor_ptr" :id="id"></div>
  </section>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {add_class_to_body, remove_class_to_body, add_class_to_id} from "@/library";
import store from "@/store";

export default {
  name: "invisible_backer_universal",
  props: ['show_backer', 'backer_color', 'event_name', 'caller_id', 'no_blur'],

  async mounted() {
    this.id = uuidv4();
  },
  async unmounted(){
  //  If unmounting, then scroll lock should always be deactivated.
    store.commit('decrement_no_scroll', this.id)
  },
  async updated() {
    if (this.show_backer === true) {
      // console.log(`UIB-->Backer state is currently ${this.show_backer}. Add noscroll to body.`)
      store.commit('increment_no_scroll', this.id)
      // add_class_to_body("noscroll")
    } else if (this.show_backer === false) {
       // console.log(`UIB-->Backer state is currently ${this.show_backer}. Remove noscroll to body.`)
      store.commit('decrement_no_scroll', this.id)
      // let no_scroll_state = store.getters.no_scroll_state
      // if (no_scroll_state === 0) {
      //   remove_class_to_body('noscroll')
      // }
    }
    let no_scroll_on = store.getters.no_scroll_state
    console.log(`Current scroll lock state is ${no_scroll_on}.`)
    if(!no_scroll_on){
      remove_class_to_body('noscroll')
    }
    if(no_scroll_on){
      add_class_to_body("noscroll")
    }

    try {
      await this.$nextTick(function () {
        let base = document.getElementById(this.id)
        base.style.background = this.backer_color
        // console.log("in blur try")
        // console.log(`No blur is: ${this.no_blur}`)
        if(!this.no_blur){
          add_class_to_id(this.id, "blur")
        }
      })
    } catch (error) {
      // console.log("error in backer color/blur block")
    }

  },

  data() {
    return {
      id: "",
    }
  },
  methods: {
    emit_state() {
      this.$emit(this.event_name)
    },
  },
  computed: {},

}
</script>

<style scoped>


.base_backer {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

</style>