<template>
  <div class="core_c">

    <section>

      <h5 class="padding_t_20 margin_l_10">Edit Ad Text</h5>
      <headline_and_body_widget
          @text_update="text_update"
          :current_data=current_data
      ></headline_and_body_widget>


      <h5 class="padding_t_20 margin_l_10">Add Agency Photo and Caption</h5>


      <photo_upload_widget
          button_text="Update Photo"
          @photo_update="photo_update"
      ></photo_upload_widget>

      <label_with_input_text_area
          class="margin_tb_10"
          ref="ad_headline_text"
          :ro_status="false"
          :show_edit_button="false"
          label="Write a photo caption"
          :label_color="colors.black"
          label_text_size="20px"
          :value_bg_color_ready="colors.morgie_gray"
          :value_bg_color_not_ready="colors.eli_gray"
          :value_text_color_ready="colors.black"
          :value_text_color_not_ready="colors.black"
          value_text_size_ready="15px"
          value_text_size_not_ready="15px"
          char_length_event="headline_length"
          @headline_length="check_caption_length"
          value=""
          rows="2"
          columns="30"
          placeholder_text="Photo captions can be up to 210 characters."
          placeholder_text_not_ready="Photo captions can be up to 210 characters."
          @edit_button_clicked="do_nothing"
      ></label_with_input_text_area>

      <div class="flex_centered_row">
        <basic_text_button
            class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
            :hover_color=colors.primary_purple
            :hover_text_color=colors.white
            :button_state=caption_update_ready
            :button_color="colors.new_cyanic_mid"
            :text_color=colors.darker_gray
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="190px"
            border_rad_pct="0"
            text="Update Caption"
            text_size="17px"
            :bold="true"
            event_name="update_caption"
            @update_caption="caption_update"
        ></basic_text_button>
      </div>

      <h5 class="padding_t_20 margin_l_10 margin_t_40">Update New Client Discount</h5>
      <selection_widget
          @update_discount_hours="update_discount_hours"
      ></selection_widget>
    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket, isEmpty
} from "@/library";
import {Database} from "@/client_db";
import Headline_and_body_widget from "@/components/Vortex/editing_pages/editing_controls/headline_and_body_widget.vue";
import Photo_upload_widget from "@/components/Vortex/editing_pages/editing_controls/photo_upload_widget.vue";
import Selection_widget from "@/components/Vortex/editing_pages/editing_controls/selection_widget.vue";
import label_with_input_text_area from "@/components/parts/label_with_input_text_area.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import image_upload_widgit from "@/components/parts/image_upload_widgit.vue";

export default {
  name: "editing_controls_main",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    this.owner_perm_ = true
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text", "current_data"
  ],
  emits: ['caption_update', 'text_update', 'logo_update', 'photo_update', 'hours_update'],
  components: {
    image_upload_widgit,
    basic_text_button,
    label_with_input_text_area,
    Selection_widget,
    Photo_upload_widget,
    Headline_and_body_widget,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      form_state: {},
      // ad_parts: {},
      caption: "",
      owner_perm_: false,
      logo_image_: ""
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    upload_logo_image() {
      this.do_nothing()
    },
    update_discount_hours(e) {
      console.log("editing controls main...")
      console.log(e)
      this.$emit("hours_update", e)
    },


    get_old_or_new_text() {
      let c = null
      if (this.caption.length >= 5) {
        c = this.caption
      }
      if (!c) {
        if (!isEmpty(this.current_data.caption)) {
          if (this.current_data.caption.length >= 5) {
            c = this.current_data.caption
          } else {
            return null
          }
        }
      }
      return {'caption': c}
    },

    caption_update(e) {
      console.log("caption update clicked")
      console.log(e)
      if (this.caption_update_ready) {
        this.$emit("caption_update", this.caption)
      } else {
        return null
      }
    },

    text_update(e) {
      console.log("text  update clicked")
      console.log("Updating the text....")
      console.log("Emiting text from editing controls for caption.")
      this.$emit("text_update", e)

    },

    check_caption_length(state) {
      // console.log(`Caption Length: ${state.length}`)
      // this.ad_ready.caption = state.length >= 5;
      this.caption = state
      if (state.length >= 5) {
        this.form_state.caption = true
        // console.log("caption is true")
      } else {
        // console.log('caption is false')
        this.form_state.caption = false
      }
    },

    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    photo_update(e) {
      this.$emit("photo_update", e)
    },
    logo_update(e) {
      this.$emit("logo_update", e)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    // async load_current_ad() {
    //
    //
    //   console.log("Inside load current ad.")
    //   let org = store.getters.org_context
    //   let search = {
    //     key: "",
    //     additional_context: [['div', 'ads'], ['div', org]],
    //     base_context: store.getters.find_current_preamble
    //   }
    //
    //   // console.log(JSON.stringify(search))
    //
    //   let all_docs = await this.db.db_search(search)
    //
    //   console.log(all_docs)
    //   const knownKeys = ['ad_body', 'ad_discount_hours', 'ad_headline', 'ad_photo_caption', 'base_rate', 'weighted_avg'];
    //
    //   for (const obj of all_docs) {
    //
    //     for (const key in obj) {
    //       if (obj.hasOwnProperty(key) && knownKeys.includes(key)) {
    //         // console.log(`Key: ${key}, Value: ${obj[key]}`);
    //         this.ad_parts[key] = obj[key]
    //       }
    //     }
    //   }
    //   console.log("here are the ad parts")
    //     console.log(this.ad_parts)
    //
    // },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")
      // await this.load_current_ad()
    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    logo_image() {
      // console.log("inside the profile-image computed prop bc of image chang.")
      return this.logo_image_
    },
    owners_perm() {
      return this.owner_perm_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    caption_update_ready() {
      if (this.form_state.caption) {
        return true
      } else {
        return false
      }

    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>