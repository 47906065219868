<template>
<div>

    <select :id=random_id @change="onChange($event)" >
        <option>---- {{title}} ----</option>
        <option v-for="v in values" :key="v" :value="v">{{v}}</option>
    </select>

  </div>
</template>

<script>
import {getRandomInt} from "@/library";

export default {
name: "drop_down_select",
props: ["title","values"],
  data() {
    return {
      random_id: this.get_random(1000000)
    };
  },
methods: {

  get_random(max){
    return getRandomInt(max).toString()
  },
  onChange(e) {
     // console.log("in drop down on change")
    this.$emit('value-selected', e.target.value)
  },
  reset(){
     // console.log("drop down reset launched.")
    document.getElementById(this.random_id).selectedIndex = 0;
  }
},
computed: {

},
watch: {},

}
</script>

<style scoped>

</style>