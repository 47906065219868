<template>
  <div class="">


    <!--    <basic_page_infrastructure-->
    <!--        @local_page_update="do_nothing"-->
    <!--        @continue_clicked="do_nothing"-->
    <!--    ></basic_page_infrastructure>-->


    <section class="">
      <!--       <div>Selected Caregiver Summary is</div>-->
      <!--          <div>{{selected_caregiver_summary()}}</div>-->
      <div v-if="!mobile_mode" class=" flex_centered_row font_raleway desktop_cg_summary">
        <!--          <div>Not Mobile Mode</div>-->

        <table v-if="selected_caregiver_summary()"
               class="margin_tb_15  bg_lauren_gray text_align_center  padding_tb_10 main_summary_table">

          <thead>
          <tr>
            <!-- loop through each value of the fields to get the table header -->
            <th v-for="field in selected_caregiver_summary()[0]" :key="field" class="margin_b_20 table_alignment">
              {{ field }}
            </th>
          </tr>
          </thead>
          <tbody>
          <!-- Loop through the list get the each student data -->
          <tr v-for="item in selected_caregiver_summary_without_first()" :key='item' class="">
            <td v-for="thing in item" :key='thing' class="table_alignment padding_tb_5">{{ td_converter(thing) }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="mobile_mode && selected_caregiver_summary()"
           class="mobile_cg_summary flex_centered_column bg_lauren_gray">
        <!--        <div>{{ selected_caregiver_summary_data_pairs()}}</div>-->
        <div class="font_raleway font_weight_800 margin_tb_10 mobile_cg_summary_heading">Summary</div>
        <div v-for="item in selected_caregiver_summary_data_pairs()"
             class="flex_space_between_row  font_raleway mobile_summary_item_row"
             :key='item'>

          <div v-for="thing in item" :key='thing' class="mobile_cg_summary_data">{{ thing }}</div>
        </div>
      </div>
    </section>


    <div class="flex_centered_row" v-if="show_report_button">
      <basic_text_button
          class="margin_tb_20"
          :button_state="show_sd_report_dl_button"
          :button_color="colors.new_orange_main"
          :hover_color="colors.cyanic_blue"
          :text_color="colors.black"
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="60px"
          :button_width=button_width
          border_rad_pct="0%"
          text="Get SpeedyDelivery Report"
          text_size="14px"
          :bold="true"
          event_name="emit_baseline_input_values"
          @emit_baseline_input_values="get_speedy_report"
      ></basic_text_button>
    </div>


  </div>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size,
  good_token_now, receive_over_socket, containsSubstring,
} from "@/library";
import {be_main_address} from "@/library";
import axios from "axios";
import {parse_internal_json} from "@/library";
import {get_date_string} from "@/library";
import basic_text_button from "@/components/parts/basic_text_button.vue";

export default {
  name: "sd_summary_table",

  async mounted() {
    // console.log("(((((((((((((((((((((((((((((((((((((((Running mounted is sd_summary Table")
    // scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.window_width)
    // await this.pull_speedy_data()

    // await prime_socket(this.local_message_handler)
    // this.db = new Database();
    // await this.local_page_update()

    try {
      await this.$nextTick(function () {

        this.pull_speedy_data()

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        //pass
      })
    } catch (error) {
      console.log(error)
    }
  },
  emits: ["activate_spinner"],
  props: [
    "cg_name",
    'starting_width',
    'org_rank'
  ],
  components: {
    basic_text_button,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: false,
      show_report_button_: this.window_width > 1000,
      desktop_cutoff: 1000,
      max_mobile_size: 10,
      admins_perm_: false,
      action_button_width_: 220,
      button_width_: "",
      button_height_: "",

      selected_caregiver_pname_: "",
      selected_caregiver_user_name_: "",
      caregiver_defined_: false,

      mod_to_download: "",

      selected_caregiver_module_summary_table_: [],
      selected_caregiver_module_data_: [],
      no_doc_caregivers: [],
      profile_image_: "",
      my_people_: [],
      cg_module_array: [],
      button_size_: "100px",
      button_img_size_: "80px",
      unique_preferred_names: [],
      p_name_to_usename_map: {},
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },

    activate_spinner(a_bool) {
      this.$emit('activate_spinner', a_bool)
    },

    td_converter(text) {
      if (typeof text !== "string") {
        return text
      }

      if (text === "True" || text === "False") {
        return text
      }

      if (this.show_report_button) {
        if (text === "Criminal Background Check") {
          return "Crim. BG Check"
        } else {
          return text
        }

      } else {
        if (text.includes('/')) {
          //this is a date like 10/06/2023
          let parts = text.split('/')
          // let day = parts[1]
          let month = parts[0]
          let year = parts[2]
          let short_year = year.slice(2, 4)
          return month + "/" + short_year
        } else if (text === "Module Credits") {
          return "Crds"
        } else if (text === "Driver's License") {
          return "DL"
        } else if (text === "Car Insurance") {
          return "CI"
        } else if (text === "Driving Record") {
          return "DR"
        } else if (text === "Drug Test") {
          return "DT"
        } else if (text === "Crim. BG Check") {
          return "CBC"
        } else if (text === "Covid Test Record") {
          return "CTR"
        }
      }
    },
    async get_speedy_report() {
      // await analytic("01:01:16:908")
      // console.log("in get speedy report")

      this.activate_spinner(true)
      if (this.cg_name === "") {
        // console.log("in get speedy report...No one was selected.")
        return null
      }
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        speedy_user: this.cg_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url1 = be_main_address('sm') + 'check_speedydelivery_report_availability'

      // let available = 0
      let available = axios.post(target_url1, data1)
          .then((response) => {
            console.log("RESPONSE FROM check_speedydelivery_report_availability")
            console.log(response.data)
            // console.log(parse_internal_json(response))
            available = response.data['data']
            return available
          }).then((response) => {
            console.log("Response from availablity check was....")
            console.log(response)
            if (response) {
              let target_url2 = be_main_address('sm') + 'download_speedydelivery_report'
              if (this.caregiver_has_no_docs) {
                // console.log("This caregiver has no docs")
                let message = `${this.selected_caregiver_pname} has no SpeedyDelivery documents.`
                store.commit('notification_enqueue', message)
                return null
              } else {
                let errored_out = false
                let got_response = false
                return axios.post(target_url2, data1, {responseType: "blob"})
                    .then((response) => {
                      errored_out = false
                      got_response = true
                      console.log("Just got back with a response")
                      if (response.data['error'] === true) {
                        let message = parse_internal_json(response.data)['payload']['content']['message']
                        store.commit('error_enqueue', message)
                        this.activate_spinner(false)
                      } else {
                        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        let fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
                        fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${this.cg_name}.pdf`);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                        this.activate_spinner(false)

                        store.commit('notification_enqueue', `The SpeedyDelivery Report for ${this.cg_name} was delivered.`)
                      }
                    });
              }
            } else {
              store.commit('notification_enqueue', `The SpeedyDelivery Report for
              ${this.selected_caregiver_pname} is currently being updated. Check back in a few minutes.`)
            }
          });


      let target_url2 = be_main_address('sm') + 'download_speedydelivery_report'

      //
      // if (this.caregiver_has_no_docs) {
      //   // console.log("This caregiver has no docs")
      //   let message = `${this.selected_caregiver_pname} has no SpeedyDelivery documents.`
      //   store.commit('error_enqueue', message)
      // }
      // let errored_out = false
      // let got_response = false
      // console.log("in get speedy report....making the call.")
      // return axios.post(target_url2, data1, {responseType: "blob"})
      //     .then((response) => {
      //       errored_out = false
      //       got_response = true
      //       console.log("Just got back with a response")
      //       if (response.data['error'] === true) {
      //         let message = parse_internal_json(response.data)['payload']['content']['message']
      //         store.commit('error_enqueue', message)
      //         this.activate_spinner(false)
      //       } else {
      //         let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //         let fileLink = document.createElement('a');
      //
      //         fileLink.href = fileURL;
      //         fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
      //         fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${this.cg_name}.pdf`);
      //         document.body.appendChild(fileLink);
      //
      //         fileLink.click();
      //         this.activate_spinner(false)
      //
      //         store.commit('notification_enqueue', `The SpeedyDelivery Report for ${this.cg_name} was delivered.`)
      //       }
      //     });
      // if(!got_response){
      //   errored_out = true
      // }
      // if(errored_out){
      //    this.activate_spinner(false)
      // }

    },


    async pull_speedy_data() {
      // await analytic("01:01:16:908")


      this.selected_caregiver_module_summary_table_ = []
      this.selected_caregiver_module_data_ = []
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        speedy_user: this.cg_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/generate_hours_report'
      let endpoint = ""
      if (store.getters.org_context === "") {
        endpoint = 'download_your_module_summary_table'
      } else {
        endpoint = 'download_module_summary_table'
      }
      // let target_url = be_main_address('sd') + 'download_module_summary_table'
      // let target_url = be_main_address('sd') + 'download_your_module_summary_table'
      let target_url = be_main_address('sd') + endpoint

      axios.post(target_url, data1)
          .then((response) => {
            // console.log(response)
            if (response.data['error'] === true) {

              // let message = parse_internal_json(response.data)['message']
              // store.commit('error_enqueue', message)
              if (response.data['data'] === 'no_docs') {
                this.no_doc_caregivers.push(this.cg_name)
                store.commit('speedydelivery_add_caregiver_to_no_docs_list', this.cg_name)
              }


            } else {
              // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$Here is what I got calling for a data summary.......")
              let message = parse_internal_json(response.data)['data']
              let summary_table = message[0]
              let modules_data = message[1]
              // console.log(summary_table)
              // console.log(modules_data)
              this.selected_caregiver_module_summary_table_ = summary_table
              this.selected_caregiver_module_data_ = modules_data
              // console.log("Just got back the selected caregiver module data")
              // console.log(this.selected_caregiver_module_data)

            }
          }).catch((err) => {
        // this.reset_selected_caregiver()
      });
    },

    selected_caregiver_pname() {
      if (!this.selected_caregiver_pname_) {
        return this.default_no_selection_text
      } else {
        return this.selected_caregiver_pname_
      }
    },
    selected_caregiver_user_name() {
      if (this.selected_caregiver_user_name_ === "") {

        this.selected_caregiver_module_data_ = []
        return this.default_no_selection_text
      } else {
        return this.selected_caregiver_user_name_
      }
    },

    selected_caregiver_summary() {
      // console.log(`&&&&&&&&&&OK Hey....${this.selected_caregiver_module_summary_table_} with length: ${Object.keys(this.selected_caregiver_module_summary_table_).length}`)
      // console.log(this.selected_caregiver_module_summary_table_)
      if (!this.selected_caregiver_module_summary_table_) {
        return null
      }
      if (Object.keys(this.selected_caregiver_module_summary_table_).length === 0) {
        // console.log("Setting teh summary table to null")
        this.selected_caregiver_module_summary_table_ = null
        return this.selected_caregiver_module_summary_table_
      } else {
        // console.log("Setting teh summary table to a value")
        return this.selected_caregiver_module_summary_table_
      }
    },

    selected_caregiver_module_data() {
      let nopes = ["", undefined, [], null, {}]

      if (nopes.includes(this.selected_caregiver_module_data_) || this.selected_caregiver_module_data_.length === 0) {
        return []
      } else {
        return this.selected_caregiver_module_data_
      }
    },

    selected_caregiver_summary_data_pairs() {
      //  Create a list of lists like:
      //  [
      //    ['Credits', "10"],
      //    ['Exp. Date', "10/06/2023"],
      //    ['Exp. Days', "246"],
      //  ]
      let data_without_headers = this.selected_caregiver_summary_without_first()[1]
      // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!here is the data without headers")
      // console.log(data_without_headers)


      let credit_value = data_without_headers[1]
      let exp_date = data_without_headers[2]
      let exp_days = data_without_headers[3]

      // console.log(`Here are the values I got back...`)
      // console.log(`Credit value ${credit_value}`)
      // console.log(`Expiration date ${exp_date}`)
      // console.log(`Expiration Days ${exp_days}`)
      return [
        ['Credits', credit_value],
        ['Exp. Date', exp_date],
        ['Exp. Days', exp_days],
      ]

    },

    selected_caregiver_summary_without_first() {

      // console.log("Inside selected_caregiver_summary_without_first")
      // console.log(
      //     this.selected_caregiver_module_summary_table_
      // )

      //Arbitrarily large document-type number that will never be reached.
      let aldtntwnbr = 100

      //Thanks to Amerzilla @ https://stackoverflow.com/questions/39336556/how-can-i-slice-an-object-in-javascript
      let sliced = Object.keys(this.selected_caregiver_module_summary_table_).slice(1, aldtntwnbr).reduce((result, key) => {
        result[key] = this.selected_caregiver_module_summary_table_[key];
        return result;
      }, {});
      return sliced
    },


    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },

    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      return false
      // if (new_w > this.max_mobile_size) {
      //   return false
      // } else {
      //   return true
      // }
    },
    run_window_width_adjustments(new_w) {
      console.log(`Window width is ${new_w}`)

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
        this.show_report_button_ = false
        // console.log(`Show button is FALSE`)

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
        this.show_report_button_ = false
        // console.log(`Show button is FALSE`)
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.show_report_button_ = false
        // console.log(`Show button is FALSE`)
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.show_report_button_ = false
        // console.log(`Show button is FALSE`)
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.show_report_button_ = true
        // console.log(`Show button is TRUE`)
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
        this.show_report_button_ = true
        // console.log(`Show button is TRUE`)
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),

    show_report_button() {
      // console.log("Inside show report button")

      if (this.window_width > 1000) {
        if (this.org_rank !== null) {
          if (containsSubstring(['admin', 'owner'], this.org_rank)) {
            return true
          } else {
            return false
          }
        }

      } else {
        return false
      }

      return this.show_report_button_
    },
    action_button_width() {
      return this.action_button_width_ + "px"
    },

    caregiver_has_no_docs() {
      return this.no_doc_caregivers.includes(this.cg_name)
    },
    show_sd_report_dl_button() {

      if (this.caregiver_has_no_docs) {
        return false
      } else {
        return true
      }
    },
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    // async socket_connection_counter(old_val, new_val) {
    //   await prime_socket(this.local_message_handler)
    // },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  .mobile_cg_summary {
    padding: 30px;
  }

  .table_alignment {
    text-align: center;
  }

  .mobile_summary_item_row {
    width: 200px;
  }

  .main_summary_table {
    width: 98vw;
    max-width: 500px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
  table {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  .main_summary_table {
    width: 98vw;
    max-width: 550px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1000px) {
  .table_alignment {
    text-align: left;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>