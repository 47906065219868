<template>
  <div class="core_c">
    <inside_banner
        mode="spyglass_agency"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
        @continue_clicked="save_and_publish"
    ></basic_page_infrastructure>

    <section class="flex_centered_start_column">

      <img @click="profile_button_clicked" class="cursor_ptr vortex_logo margin_tb_10"
           :src="require('@/assets/' + 'vortex.svg')" alt="Center icon">

      <section id="main_tabs">
        <app-tabs :tabList="tabList">
          <template v-slot:tabPanel-1>
            <active_main
             :discount_values=this.discount_values
            ></active_main>
          </template>
          <template v-slot:tabPanel-2>
            <potential_main
             :discount_values=this.discount_values>
            </potential_main>
          </template>
          <template v-slot:tabPanel-3>
            <honored_main
             :discount_values=this.retired_discount_values
            ></honored_main>
          </template>
          <template v-slot:tabPanel-4>
            <editing_main
                @logo_update="update_logo"
                @text_update="text_update"
                @photo_update="photo_update"
                @caption_update="caption_update"
                @discount_update="discount_update"
                @hours_update="hours_update"
                @save_and_publish="save_and_publish_confirm"
                :current_data=this.data_down
            ></editing_main>
          </template>
          <template v-slot:tabPanel-5>
            <click_stats_main
                :clicks_data=this.click_values
            ></click_stats_main>
          </template>
        </app-tabs>
      </section>
    </section>
  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import active_main from "@/components/Vortex/active/active_main.vue";
import potential_main from "@/components/Vortex/potential/potential_main.vue";
import honored_main from "@/components/Vortex/honored/honored_main.vue";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now,
  receive_over_socket,
  be_main_address,
  prime_socket,
  base64_to_string,
  parse_internal_json,
  analytic,
  get_pda_home_route
} from "@/library";
import {Database} from "@/client_db";
import update_agency_landing from "@/components/SpyGlass/update_agency_landing.vue";
import AppTabs from "@/components/parts/AppTabs.vue";
import create_ad from "@/components/SpyGlass/create_ad.vue";
import click_stats from "@/components/SpyGlass/click_stats.vue";
import image_upload_widgit from "@/components/parts/image_upload_widgit.vue";
import Editing_main from "@/components/Vortex/editing_pages/editing_main.vue";
import Click_stats_main from "@/components/Vortex/click_stats_pages/click_stats_main.vue";
import axios from "axios";

export default {
  name: "vortex_admin_main",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    await this.get_agency_vortex_data()
    await this.get_agency_vortex_data(true)
    await this.get_agency_click_data()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
  ],
  components: {
    Click_stats_main,
    Editing_main,
    image_upload_widgit,
    // Discounts_manager,
    click_stats, create_ad, AppTabs, update_agency_landing,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure,
    active_main,
    potential_main,
    honored_main
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      the_file: "",
      tabList: ["Active","Potential", "Honored", "Edit Ad", "Click Stats"],
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      data:{
        'weighted_avg': null,
        'base_rate': null,
        'agency_photo': null,
        'caption': null,
        'body': null,
        'headline': null,
        'hours': null,
        'since': "",
      },
       discount_values: undefined,
       retired_discount_values: undefined,
       click_values: undefined

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async profile_button_clicked() {
      analytic("01:01:16:211")
      // console.log("inside profile_button_clicked")
      try {
        // this.hamburger_backer_clicked()
        // this.$emit('hamburger_backer_clicked', true)
        // await this.$router.push({path: `${this.get_profile_route_()}`})
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        // console.log(error)
      }

    },

    processAgencyVortexData(inputData) {
      const resultData = {};

      Object.keys(inputData).forEach(clientName => {
        console.log(`Starting client data work with ${clientName}`)
        const clientData = inputData[clientName];
        const invoices = clientData.invoices || [];
        const transactions = clientData.transactions || [];

        const totalDiscountAmtHonored = transactions.reduce((sum, txn) => sum + txn.discount_amount, 0);
        const currentInvoiceHours = invoices.reduce((sum, inv) => sum + inv.inv_hours, 0);
        const minHoursThreshold = clientData.discount.discount_hours * 3;

        resultData[clientName] = {
          client_name: clientName,
          client_phone: clientData.discount.client_phone,
          creation_date: clientData.discount.creation_date,
          expiration_date: clientData.discount.expiration_date,
          discount_amount: clientData.discount.discount_amount,
          total_discount_amt_honored: totalDiscountAmtHonored,
          current_invoice_hours: currentInvoiceHours,
          min_hours_threshold: minHoursThreshold,
          invoices: invoices.map(inv => ({
            invoice_number: inv.inv_num,
            invoice_amount: inv.inv_amt,
            invoice_hours: inv.inv_hours,
            invoice_all_hours: inv.all_hours,
            min_hours_threshold_met: inv.min_met,
            invoice_credit_applied: inv.credited_amt,
            uid: inv.uid
          })),
          transactions: transactions.map(txn => ({
            invoice_number: txn.invoice_number,
            discount_amt_honored: txn.discount_amount,
            transaction_date: txn.transaction_date
          }))
        };
      });

      return resultData;
    },

    async get_agency_click_data(){
      let endpoint = 'get_agency_vortex_clicks_data'

      let target_url = be_main_address('vortex') + endpoint

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
      }

      console.log(data1)

      console.log("Calling backend to get vortex click data.")

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response from vortex.")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                let message = parse_internal_json(response.data)['data']['message']
                console.log(typeof response.data.data)
                console.log(response.data.data)
                console.log("OK here is what came back out the other side.")
                this.click_values = response.data.data
                // if(retired){
                //   this.retired_discount_values = this.processAgencyVortexData(response.data.data)
                //   console.log(this.retired_discount_values)
                // }else{
                //   this.discount_values = this.processAgencyVortexData(response.data.data)
                //   console.log(this.discount_values)
                // }

              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async get_agency_vortex_data(retired=false) {


      let endpoint
      if(retired){
        endpoint = 'get_retired_agency_vortex_data'
      }else{
         endpoint = 'get_agency_vortex_data'
      }
      let target_url = be_main_address('vortex') + endpoint

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
      }

      console.log(data1)

      console.log("Calling backend to get vortex data.")

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response from vortex.")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                let message = parse_internal_json(response.data)['data']['message']
                console.log(`Here is the response from vortex---RETIRED?: ${retired}.`)
                console.log(typeof response.data.data)
                console.log(response.data.data)
                console.log("OK here is what came back out the other side.")
                if(retired){
                  this.retired_discount_values = this.processAgencyVortexData(response.data.data)
                  console.log(this.retired_discount_values)
                }else{
                  this.discount_values = this.processAgencyVortexData(response.data.data)
                  console.log("These are the local in memory saved discount data....")
                  console.log(this.discount_values)
                }

              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },

    update_logo(e){
      console.log('vortex main level event capture--logo')
      console.log(e)
      this.data['logo'] = e.value
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Agency logo updated on model.")
    },
    text_update(e){
      console.log('vortex main level event capture--headline and body')
      console.log(e)
      this.data['headline'] = e['headline']
      this.data['body'] = e['body']
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Text updated on model.")
    },
    photo_update(e){
      console.log('vortex main level photo update event capture--photo')
      console.log(e)
      this.data['photo'] = e
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Agency photo updated on model.")
    },
    hours_update(e){
      console.log('vortex main level event capture--hours')
      console.log(e)
      this.data['hours'] = parseFloat(e)
      console.log(this.data['hours'])
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Agency hours updated on model.")
    },
    caption_update(e){
      console.log('vortex main level event capture--caption')
      this.data['caption'] = e
      console.log("Printing the captured caption.")
      console.log(this.data['caption'])
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Photo caption updated on model.")
    },
    discount_update(e){
      console.log('vortex main level event capture--discount')
      console.log(e)
      this.data['discount'] = e
      console.log("Print the data")
      console.log(this.data)
      store.commit('notification_enqueue', "Agency discount updated on model.")
    },

    async save_and_publish(){
      console.log("Getting ready to make the axios call....")
      let target = be_main_address('vortex') + "publish_ad"
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        agency: store.getters.org_context,
        agency_photo: this.data['photo'],
        ad_photo_caption: this.data['caption'],
        ad_body: this.data['body'],
        ad_headline: this.data['headline'],
        ad_discount_hours: this.data['hours'],
        weighted_avg: this.data['weighted_avg'],
        base_rate: this.data['base_rate'],

      }

      console.log("Here is what I am going to send...")
      console.log(data1)
      await axios.post(target, data1).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad confirmation. Check the confirmation code.")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          return null
        }
      }).then((response) => {
           console.log("this is the data test")
            console.log(response)


            if (response.data.error === false) {
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )
      store.commit('notification_enqueue', "Your ad changes have been queue for publishing and will appear soon.")
    },

    save_and_publish_confirm(e){
      console.log('vortex main level event capture')
      console.log(e)
      store.commit("continue_enqueue", ["Do you want to save and publish you changes?", null])
      // store.commit('notification_enqueue', "Your Vortex ad has been saved and published.")
       // send data to backend for front end update
    },
    open_spinner() {
      store.commit('set_spinner_state', true)

    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    // emit_state() {
    //   this.$emit(this.event_name, this.data1, this.data2)
    // },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async onImageFileChosen(event) {
      console.log("in onImageFileChosen")
      const files = event.target.files
      this.the_file = files[0]
      let img_file_name = this.the_file['name']
      const myArray = img_file_name.split(".");
      let file_ext = myArray[1];
      let exts = ["jpeg", "gif", "png", "svg", "bmp", "jpg"]
      if (exts.includes(file_ext)) {
        // console.log("ok file")
      } else {
        let m = `Image file must be of one of the following types:
        "jpeg", "gif", "png", "svg", "bmp", "jpg". Your file was a "${file_ext}".`
        store.commit('error_enqueue', m)
        // console.log("NOT OK")
        return null
      }
      // console.log("start fileToDataUri")
      // let orig_src = await this.fileToDataUri(this.the_file)
      this.fileToDataUri(this.the_file).then(orig_src => {
        // console.log("orig scr from filetoDataurs is")
        // console.log(orig_src)
        // console.log("start resizeImage.  ")

        this.resizeImage(orig_src)
        // console.log("Back from resizeImage")
        this.$refs.image_to_upload.value = ''
        // console.log("about to call emit state")
        this.emit_state()
      })
    },

    async local_page_update() {
      // console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    data_down(){
      console.log("Inside data down returning this.data")
      return this.data
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  .vortex_logo {
    width: 140px;
    margin-bottom: 20px;
  }

  #main_tabs{
    max-width: 720px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>