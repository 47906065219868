<template>
  <div class="core_c">

    <inside_banner
        mode="speedydelivery_agency"
        :owner_perm="admins_perm"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>


    <section class="flex_centered_start_column">
      <!--     <h2 class="text_eli_gray">Tiny Magic Hat Terms of Service</h2>-->
      <div class="flex_centered_row">
        <img @click="go_to_profile_home_route" class="padding_tb_20 sd_logo cursor_ptr"
             src="@/assets/speedydelivery_logo.svg"
             alt="SpeedyDelivery Feature Logo">
      </div>
    </section>

    <section class="flex_centered_row">
      <div class="flex_centered_column wid_75">
        <!--        <div class="margin_tb_15 wid_75 bg_orange_light text_align_center">Caregiver Search</div>-->

        <dropdown_datalist
            ref="sd_cg_selection"
            label="Select User"
            :data_list=my_people
            @value-selected="set_selected_caregiver_to_store"
        ></dropdown_datalist>
        <h2 v-if="caregiver_uname_selected !== 'No User Selected'"
            class="margin_tb_15 wid_75  text_align_center font_weight_800 fon">{{ get_current_cg_pname() }}</h2>

        <camera_widget
            v-if="show_caregiver_image"
            ref="camera_widgit"
            :personal_gear_available="false"
            :btn1_c="colors.new_orange_main"
            :btn2_c="colors.new_orange_main"
            :btn3_c="colors.new_cyanic_main"
            :btn4_c="colors.new_cyanic_main"
            btn1_icon='camera_on.svg'
            btn2_icon='delete.svg'
            btn3_icon='camera_snap.svg'
            btn4_icon='cloud-upload.svg'
            image1_w="50px"
            image2_w="40px"
            image3_w="55px"
            image4_w="55px"
            button_border_rad="50%"
            button_h="80px"
            button_w="80px"
            :frame_text_color="colors.pennie_gray"
            :frame_color="colors.eli_gray"
            :old_pic="profile_image"
            :disable_controls="true"
            :my_profile_page="false"
            event_name="save_pic"
            @save_pic="do_nothing"
        ></camera_widget>

        <!--        <h3>Summary table here</h3>-->


        <div v-if="caregiver_pname_selected !== ''"
             class="screen_block bg_lauren_gray padding_tb_40 margin_tb_30 manage_box">

          <div class="flex_centered_column">
            <h2 v-if="show_sd_report_dl_button">Get Report</h2>
            <basic_text_button
                v-if="show_sd_report_dl_button"
                class="get_report_button"
                :button_state="true"
                :button_color="colors.new_orange_main"
                :hover_color="colors.cyanic_blue"
                :text_color="colors.black"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="60px"
                :button_width=action_button_width
                border_rad_pct="0%"
                text="Get SpeedyDelivery Report"
                text_size="14px"
                :bold="true"
                event_name="get_speedy_report"
                @get_speedy_report="get_speedy_report"
            ></basic_text_button>
            <h2>Upload Documents</h2>
            <basic_text_button
                v-if="caregiver_pname_selected !== ''"
                class="margin_tb_20"
                :button_state="true"
                :button_color="colors.new_orange_main"
                :hover_color="colors.cyanic_blue"
                :text_color="colors.black"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="60px"
                :button_width=action_button_width
                border_rad_pct="0%"
                text="Upload Caregiver Document"
                text_size="14px"
                :bold="true"
                event_name="go_to_upload_cg_docs_page"
                @go_to_upload_cg_docs_page="go_to_caregiver_upload_docs_page"
            ></basic_text_button>
          </div>
        </div>


        <!--        <basic_text_button-->
        <!--            v-if="show_sd_report_dl_button"-->
        <!--            class="get_report_button"-->
        <!--            :button_state="true"-->
        <!--            :button_color="colors.new_orange_main"-->
        <!--            :hover_color="colors.cyanic_blue"-->
        <!--            :text_color="colors.black"-->
        <!--            :button_color_not_ready="colors.eli_gray"-->
        <!--            :button_not_ready_text_color="colors.pennie_gray"-->
        <!--            button_height="60px"-->
        <!--            :button_width=action_button_width-->
        <!--            border_rad_pct="0%"-->
        <!--            text="Get SpeedyDelivery Report"-->
        <!--            text_size="14px"-->
        <!--            :bold="true"-->
        <!--            event_name="emit_baseline_input_values"-->
        <!--            @emit_baseline_input_values="get_speedy_report"-->
        <!--        ></basic_text_button>-->

        <div v-if="!mobile_mode" class="wid_75 flex_centered_row font_raleway desktop_cg_summary">
          <!--          <div>Not Mobile Mode</div>-->
          <table v-if="selected_caregiver_summary"
                 class="margin_tb_15 wid_75 bg_lauren_gray text_align_center padding_lr_30 padding_tb_10">
            <thead>
            <tr>
              <!-- loop through each value of the fields to get the table header -->
              <th v-for="field in selected_caregiver_summary[0]" :key="field" class="margin_b_20">
                {{ field }}
              </th>
            </tr>
            </thead>
            <tbody>
            <!-- Loop through the list get the each student data -->
            <tr v-for="item in selected_caregiver_summary_without_first" :key='item'>
              <td v-for="thing in item" :key='thing'>{{ thing }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="mobile_mode && selected_caregiver_summary" class="mobile_cg_summary flex_centered_column">
          <div class="font_raleway font_weight_800 margin_tb_10 mobile_cg_summary_heading">Summary</div>
          <div v-for="item in selected_caregiver_summary_data_pairs" class="flex_space_between_row wid_95 font_raleway"
               :key='item'>
            <div v-for="thing in item" :key='thing' class="mobile_cg_summary_data">{{ thing }}</div>
          </div>
        </div>


        <h3 v-if="selected_caregiver_module_data.length > 0" class="margin_tb_15 wid_75  text_align_center q_summary">
          Current Modules</h3>

        <div v-for="module in selected_caregiver_module_data" :key="module">
          <module_display_unit
              :module_data="module"
              :stats="true"
          >
          </module_display_unit>
        </div>


        <!--        <basic_text_button-->
        <!--            v-if="caregiver_pname_selected !== ''"-->
        <!--            class="margin_tb_20"-->
        <!--            :button_state="true"-->
        <!--            :button_color="colors.new_cyanic_main"-->
        <!--            :text_color="colors.black"-->
        <!--            :button_color_not_ready="colors.eli_gray"-->
        <!--            :button_not_ready_text_color="colors.pennie_gray"-->
        <!--            button_height="60px"-->
        <!--            :button_width=action_button_width-->
        <!--            border_rad_pct="0%"-->
        <!--            text="Upload Caregiver Document"-->
        <!--            text_size="14px"-->
        <!--            :bold="true"-->
        <!--            event_name="go_to_upload_cg_docs_page"-->
        <!--            @go_to_upload_cg_docs_page="go_to_caregiver_upload_docs_page"-->
        <!--        ></basic_text_button>-->

        <!--        <div class="screen_block bg_lauren_gray padding_tb_40 margin_tb_30 manage_box">-->
        <!--          -->
        <!--          <div class="flex_centered_column">-->
        <!--            <h2>Reports</h2>-->
        <!--            <p>content</p>-->
        <!--            <h2>Upload</h2>-->
        <!--            <p>content</p>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="screen_block bg_lauren_gray padding_tb_40 margin_tb_30 manage_box">
          <h2 class="text_align_center flex_centered_column  ">Custom In-Services</h2>
          <div class="flex_centered_column">
            <basic_text_button

                class="margin_tb_20"
                :button_state="true"
                :button_color="colors.new_orange_main"
                :hover_color="colors.new_orange_main"
                :text_color="colors.black"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="60px"
                :button_width=action_button_width
                border_rad_pct="0%"
                text="Manage"
                text_size="14px"
                :bold="true"
                event_name="go_to_upload_cg_docs_page"
                @go_to_upload_cg_docs_page="go_to_in_service_creation_page"
            ></basic_text_button>
          </div>

          <h2 class="text_align_center flex_centered_column  margin_t_40">Agency Local Documents</h2>
          <div class="flex_centered_column">
            <basic_text_button

                class="margin_tb_20"
                :button_state="true"
                :button_color="colors.new_orange_main"
                :hover_color="colors.new_orange_main"
                :text_color="colors.black"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="60px"
                :button_width=action_button_width
                border_rad_pct="0%"
                text="Manage"
                text_size="14px"
                :bold="true"
                event_name="go_to_upload_cg_docs_page"
                @go_to_upload_cg_docs_page="go_to_agency_local_doc_creation_page"
            ></basic_text_button>
          </div>

          <section>
            <div class="flex_centered_column">
              <div class="flex_centered_row text_align_center margin_t_40">
                <h2>Get Modules</h2>
              </div>
              <dropdown_datalist
                  ref="sd_module_selection"
                  class="margin_t_30 mod_drop_down"
                  label="Select Module"
                  :data_list=list_of_training_modules
                  @value-selected="set_module_download_target"
              ></dropdown_datalist>

              <basic_text_button
                  :v-if="true"
                  class="margin_tb_20"
                  :button_state="true"
                  :button_color="colors.cyanic_blue"
                  :hover_color="colors.new_orange_main"
                  :text_color="colors.black"
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="60px"
                  :button_width=action_button_width
                  border_rad_pct="0%"
                  text="Download"
                  text_size="14px"
                  :bold="true"
                  event_name="download_module"
                  @download_module="download_module_from_sd"
              ></basic_text_button>
            </div>
          </section>


        </div>

      </div>

    </section>


  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import outside_banner_std from "@/components/parts/outside_banner_std";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import basic_icon_button from "@/components/parts/basic_icon_button";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import camera_widget from "@/components/parts/camera_widget";
import module_display_unit from "@/components/SpeedyDelivery/module_display_unit";
import sd_summary_table from "@/components/SpeedyDelivery/sd_summary_table.vue";
import store from "@/store";
import {
  get_uuid,
  speedy_icon_data,
  colors,
  desktop_size,
  scroll_to_top,
  get_pda_home_route,
  make_hash_dict,
  parse_internal_json,
  get_date_string,
  get_associated_org_context,
  calculateDaysUntilFutureDate,
  analytic,
  be_main_address,
  find_and_save_profile_image,
  clone_object,
  download_speedydelivery_module,
  get_sd_module_meta,
  cleanString,
  translate_tmh_to_pname,
  create_list_of_pnames,
  create_tmh_user_to_pname_map,
  open_spinner, close_spinner, isEmpty
} from "@/library";
import {Database} from "@/client_db";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "speedy_delivery_agency_interface",
  async mounted() {
    this.db = new Database();
    this.id_ = get_uuid()
    scroll_to_top()

    let the_map = store.getters.get_pname_to_tmhname_map
    if (!the_map) {
      the_map = await create_tmh_user_to_pname_map(this.db)
    }
    this.u_name_to_pname_map = the_map

    // console.log("Here is the generation test......................")
    await this.generate_list_of_unique_preferred_names()
    store.commit("speedydelivery_reset_no_docs_list")

    await this.check_for_general_admin_ui_perm()
    this.my_people_ = await get_associated_org_context(this.db, 'people')
    this.run_window_width_adjustments(this.window_width)

    let selected_cg = localStorage.getItem('SD_selected_cg_username')


    if (selected_cg) {
      console.log(`The selected caregiver was ${selected_cg}`)
      store.commit('set_speedydelivery_agency_selected_caregiver_pname', the_map[selected_cg])
      await this.set_selected_caregiver_to_store(the_map[selected_cg])
    } else {
      console.log("There was no selected caregiver in vuex")
    }


    try {
      await this.$nextTick(function () {

        // if (this.bg_color) {
        //   let box = document.getElementById(this.id)
        //   box.style.background = this.bg_color
        // }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async unmounted() {
    console.log("Inside unmounted")
    // this.reset_selected_caregiver()
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // if (this.bg_color) {
        //   let box = document.getElementById(this.id)
        //   box.style.background = this.bg_color
        // }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    outside_footer,
    outside_banner_std,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    dropdown_datalist,
    camera_widget,
    basic_icon_button,
    module_display_unit,
    sd_summary_table
  },
  data() {
    return {
      id_: "",
      default_no_selection_text_: "No User Selected",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 1000,
      admins_perm_: false,
      action_button_width_: 220,

      selected_caregiver_pname_: "",
      selected_caregiver_user_name_: this.default_no_selection_text,
      caregiver_defined_: false,

      mod_to_download: "",

      selected_caregiver_module_summary_table_: [],
      selected_caregiver_module_data_: [],
      no_doc_caregivers: [],
      profile_image_: "",
      my_people_: [],
      speedy_icon_data: speedy_icon_data,
      cg_module_array: [],
      button_size_: "100px",
      button_img_size_: "80px",
      unique_preferred_names: [],
      p_name_to_usename_map: {},
      u_name_to_pname_map: {},
      expires: {
        'modules': 12,
        'drug_test': 12,

      },
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
    },
    get_current_cg_pname() {

      let map = this.u_name_to_pname_map
      let tmh_name = localStorage.getItem('SD_selected_cg_username')
      console.log(`The currently selected tmh caregiver name is ${tmh_name}`)
      if (tmh_name !== null) {
        return map[tmh_name]
      }

    },
    handle_summary_table_spinner(the_bool) {
      console.log(`Inside handle spinner with ${the_bool}`)
      if (the_bool) {
        this.open_spinner()
      } else {
        this.close_spinner()
      }
    },
    open_spinner() {
      console.log("Opening the spinner")
      store.commit('set_spinner_state', true)
    },

    async download_module_from_sd() {

      if (this.mod_to_download === "") {
        let message = "No module has been selected."
        store.commit('error_enqueue', message)
        return null
      }

      let meta = get_sd_module_meta(this.mod_to_download)
      if (!meta) {
        let message = `${this.mod_to_download} in not a known module.`
        store.commit('error_enqueue', message)
        return null
      }


      let _ = await download_speedydelivery_module(this.mod_to_download)
      this.mod_to_download = ""
      this.$refs.sd_module_selection.reset_state()

    },


    set_module_download_target(target) {
      this.mod_to_download = target
    },

    close_spinner() {
      console.log("Closing the spinner")
      store.commit('set_spinner_state', false)
    },
    print_what_you_get(e) {
      console.log(`Here is what I got: ${e}`)
    },

    does_caregiver_exist(cg_name) {
      console.log("Does Caregiver exist?")
      console.log(cg_name)
      console.log(`What name are we looking for? ${cg_name}`)
      let map = store.getters.get_pname_to_tmhname_map
      if (map === null) {
        return null
      } else {
        console.log(cg_name in map)
        return cg_name in map;
      }
    },

    reset_selected_caregiver() {
      console.log("INside reset selected caregiver.")
      this.selected_caregiver_pname_ = ""
      this.selected_caregiver_user_name_ = ""
      this.caregiver_defined_ = false
      store.commit('set_speedydelivery_agency_selected_caregiver', "")
      store.commit('set_speedydelivery_agency_selected_caregiver_pname', "")
      store.commit('set_speedydelivery_current_caregiver_profile_image', "")
    },

    // async get_speedy_report() {
    //   // await analytic("01:01:16:908")
    //   console.log("in get speedy report")
    //
    //   this.open_spinner()
    //   if (this.selected_caregiver_user_name_ === "" || this.selected_caregiver_user_name_ === this.default_no_selection_text_) {
    //     // console.log("in get speedy report...No one was selected.")
    //     return null
    //   }
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     subscriber: store.getters.org_context,
    //     speedy_user: this.caregiver_uname_selected,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     report_type: "extended_report"
    //   }
    //   let target_url = be_main_address('sm') + 'download_speedydelivery_report'
    //
    //   if (this.caregiver_has_no_docs) {
    //     // console.log("This caregiver has no docs")
    //     let message = `${this.selected_caregiver_pname} has no SpeedyDelivery documents.`
    //     store.commit('error_enqueue', message)
    //   }
    //
    //   // console.log("in get speedy report....making the call.")
    //   return axios.post(target_url, data1, {responseType: "blob"})
    //       .then((response) => {
    //         if (response.data['success'] === false) {
    //           let message = parse_internal_json(response.data)['payload']['content']['message']
    //           store.commit('notification_enqueue', message)
    //            this.close_spinner()
    //         } else {
    //           let fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //           let fileLink = document.createElement('a');
    //
    //           fileLink.href = fileURL;
    //           fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
    //           fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${this.caregiver_uname_selected}.pdf`);
    //           document.body.appendChild(fileLink);
    //
    //           fileLink.click();
    //           this.close_spinner()
    //           store.commit('notification_enqueue', `The SpeedyDelivery Report for ${this.caregiver_pname_selected} was delivered.`)
    //         }
    //       });
    //
    //
    // },


    async get_speedy_report() {
      // This version has the prelim call for availability...
      // await analytic("01:01:16:908")
      // console.log("in get speedy report")

      // this.activate_spinner(true)
      open_spinner()
      if (this.selected_caregiver_user_name_ === "" || this.selected_caregiver_user_name_ === this.default_no_selection_text_) {
        // console.log("in get speedy report...No one was selected.")
        return null
      }
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        speedy_user: this.caregiver_uname_selected,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        report_type: 'extended_report'
      }

      let target_url1 = be_main_address('sm') + 'check_speedydelivery_report_availability'

      // let available = 0
      let available = axios.post(target_url1, data1)
          .then((response) => {
            console.log("RESPONSE FROM check_speedydelivery_report_availability")
            console.log(response.data)
            // console.log(parse_internal_json(response))
            available = response.data['data']
            return available
          }).then((response) => {
            console.log("Response from availablity check was....")
            console.log(response)
            if (response) {
              let target_url2 = be_main_address('sm') + 'download_speedydelivery_report'
              if (this.caregiver_has_no_docs) {
                // console.log("This caregiver has no docs")
                let message = `${this.selected_caregiver_pname} has no SpeedyDelivery documents.`
                store.commit('notification_enqueue', message)
                return null
              } else {
                let errored_out = false
                let got_response = false
                return axios.post(target_url2, data1, {responseType: "blob"})
                    .then((response) => {
                      errored_out = false
                      got_response = true
                      console.log("Just got back with a response")
                      if (response.data['error'] === true) {
                        let message = parse_internal_json(response.data)['payload']['content']['message']
                        store.commit('error_enqueue', message)
                        close_spinner()
                      } else {
                        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        let fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
                        fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${this.caregiver_uname_selected}.pdf`);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                        close_spinner()

                        store.commit('notification_enqueue', `The SpeedyDelivery Report for ${this.caregiver_uname_selected} was delivered.`)
                      }
                    });
              }
            } else {
              store.commit('notification_enqueue', `The SpeedyDelivery Report for
              ${this.selected_caregiver_pname} is currently being updated. Check back in a few minutes.`)
            }
          });


      let target_url2 = be_main_address('sm') + 'download_speedydelivery_report'

      //
      // if (this.caregiver_has_no_docs) {
      //   // console.log("This caregiver has no docs")
      //   let message = `${this.selected_caregiver_pname} has no SpeedyDelivery documents.`
      //   store.commit('error_enqueue', message)
      // }
      // let errored_out = false
      // let got_response = false
      // console.log("in get speedy report....making the call.")
      // return axios.post(target_url2, data1, {responseType: "blob"})
      //     .then((response) => {
      //       errored_out = false
      //       got_response = true
      //       console.log("Just got back with a response")
      //       if (response.data['error'] === true) {
      //         let message = parse_internal_json(response.data)['payload']['content']['message']
      //         store.commit('error_enqueue', message)
      //         this.activate_spinner(false)
      //       } else {
      //         let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //         let fileLink = document.createElement('a');
      //
      //         fileLink.href = fileURL;
      //         fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
      //         fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${this.cg_name}.pdf`);
      //         document.body.appendChild(fileLink);
      //
      //         fileLink.click();
      //         this.activate_spinner(false)
      //
      //         store.commit('notification_enqueue', `The SpeedyDelivery Report for ${this.cg_name} was delivered.`)
      //       }
      //     });
      // if(!got_response){
      //   errored_out = true
      // }
      // if(errored_out){
      //    this.activate_spinner(false)
      // }

    },


    async pull_speedy_data() {
      // await analytic("01:01:16:908")

      if (this.selected_caregiver_user_name === this.default_no_selection_text) {
        return null

      }

      this.open_spinner()
      this.selected_caregiver_module_summary_table_ = []
      this.selected_caregiver_module_data_ = []
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        speedy_user: localStorage.getItem('SD_selected_cg_username'),
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/generate_hours_report'
      let target_url = be_main_address('sm') + 'download_module_summary_table'

      axios.post(target_url, data1)
          .then((response) => {
            console.log("*****Just got the summary table and other data.")
            console.log(response)
            if (response.data['error'] === true) {

              let message = parse_internal_json(response.data)['message']
              store.commit('error_enqueue', message)
              this.close_spinner()
              if (response.data['data'] === 'no_docs') {
                this.no_doc_caregivers.push(this.selected_caregiver_user_name_)
                store.commit('speedydelivery_add_caregiver_to_no_docs_list', this.get_speedydelivery_agency_selected_caregiver)
              }


            } else {
              console.log("Here is what I got calling for a data summary.......")
              this.close_spinner()
              let message = parse_internal_json(response.data)['data']
              let summary_table = message[0]
              let modules_data = message[1]
              console.log(summary_table)
              console.log(modules_data)
              this.selected_caregiver_module_summary_table_ = summary_table
              this.selected_caregiver_module_data_ = modules_data
              console.log("Just got back the selected caregiver module data")
              console.log(this.selected_caregiver_module_data)

            }
          }).catch((err) => {
        this.close_spinner()
        // this.reset_selected_caregiver()
      });
    },


    async go_to_in_service_creation_page() {
      let o = store.getters.org_context
      // console.log('here is the o')
      console.log(o)

      let r = `/pda/${o}/speedydelivery/custom_in_service`
      // console.log(r)
      await this.$router.push({path: `/pda/${store.getters.org_context}/speedydelivery/custom_in_service`})
    },

    async go_to_agency_local_doc_creation_page() {
      // let o = store.getters.org_context
      // console.log('here is the o')
      // console.log(o)

      // let r = `/pda/${o}/speedydelivery/agency_local_docs`
      // console.log(r)
      await this.$router.push({path: `/pda/${store.getters.org_context}/speedydelivery/agency_local_docs`})
    },


    async go_to_module_page(module_name) {
      await this.non_contextual_route(module_name)
    },

    async generate_list_of_unique_preferred_names() {
      let list_of_associated_users = await get_associated_org_context(this.db, 'people')
      console.log('Here is the list_of_associated_users...')
      console.log(list_of_associated_users)
      let pname
      this.unique_preferred_names = await create_list_of_pnames(list_of_associated_users)
      console.log('Here is the unique_preferred_names...')
      console.log(this.unique_preferred_names)

      // for (const user_name of list_of_associated_users) {
      //   console.log(`ATtempting to generate a preferred name with ${user_name}`)
      //   // await this.generate_user_preferred_name(user_name)
      //   // pname = translate_tmh_to_pname(user_name)
      //   this.unique_preferred_names = create_list_of_pnames()
      //
      // }

      // console.log("List of u names")
      // console.log(this.unique_preferred_names)

    },

    _create_preferred_name(user_data) {
      let start_name = ""
      if (user_data['nick_name'] !== "") {
        start_name = user_data['nick_name']
      } else {
        start_name = user_data['first_name']
      }
      return start_name + " " + user_data['last_name']

    },

    name_is_unique(name) {
      console.log(`Checking ${name} for uniqueness against...`)
      console.log(this.unique_preferred_names)
      return !this.unique_preferred_names.includes(name)
    },

    salt_name(name, value) {
      return name + "(" + value + ")"
    },

    // async generate_user_preferred_name(user) {
    //   // A Preferred name is just (NickName or First Name) Last where Nick is preferred oven First if available.
    //   let search = {
    //     key: '',
    //     additional_context: [['people', user], ['div', 'profile'], ['div', 'text_data']],
    //     base_context: store.getters.find_current_preamble_sans_service
    //   }
    //
    //   let db_target_item = await this.db.db_search(search)
    //
    //
    //   for (const element of db_target_item) {
    //     console.log('Here is the user preferred name data.')
    //     console.log(element);
    //     let profile_data = element['private_profile_data']
    //     let pname = this._create_preferred_name(profile_data)
    //     let salt = 0
    //     let sname
    //     if (!this.name_is_unique(pname)) {
    //       do {
    //         salt += 1
    //         sname = this.salt_name(pname, salt.toString())
    //       } while (!this.name_is_unique(sname));
    //       pname = sname
    //     }
    //     // This is so if the preferred name, generated from Profile data, which may have come from CC or may have been
    //     // entered by the user, has extra white space, it will be removed. This is needed because JS removes whitespace
    //     // automatically from the drop down menu of caregiver names that you are picking from. But, the list of p_names
    //     // that was generated and saved does not have the white space removed. This causes a mismatch between the names
    //     // available in the dropdown and the names in the list that is used to translate pnames to user names so data
    //     // can be collected from the backend. The solution is to just clean the string of whitespace.
    //     pname = cleanString(pname)
    //     this.p_name_to_usename_map[pname] = user
    //     this.unique_preferred_names.push(pname)
    //   }
    // },

    async get_selected_user_sd_data(user) {
      let search = {
        key: '',
        additional_context: [['people', user], ['service', 'speedydelivery']],
        base_context: store.getters.find_current_preamble_sans_service
      }

      let db_target_item = await this.db.db_search(search)

      for (const element of db_target_item) {
        console.log('Here is a module item.')
        console.log(element);
      }
      this.cg_module_array = db_target_item

      // console.log(JSON.parse(db_target_item))
      console.log(db_target_item)
    },


    async get_profile_image_from_db(user) {
      let res = await find_and_save_profile_image(this.db, user)
      console.log("Back from making the get image call and I got .....")
      console.log(res)
      return res
    },


    async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },
    async go_back() {
      await this.$router.back()
    },


    async go_to_caregiver_upload_docs_page() {
      let current = localStorage.getItem('dev_latest_route')
      console.log("The latest dev route was...")
      console.log(current)


      console.log(`THE SELECTED CAREGIVER IS ${this.selected_caregiver_user_name_}`)
      console.log(`THE SELECTED CAREGIVER PNAME IS ${this.selected_caregiver_pname_}`)

      console.log(`THE USED SELECTED CAREGIVER IS ${this.caregiver_uname_selected}`)
      console.log(`THE USED SELECTED CAREGIVER PNAME IS ${this.selected_caregiver_pname_}`)

      store.commit('set_speedydelivery_agency_selected_caregiver', this.caregiver_uname_selected)
      store.commit('set_speedydelivery_agency_selected_caregiver_pname', this.selected_caregiver_pname_)

      // console.log("The store before changing pages...")
      // console.log(JSON.stringify(store.state))


      let agency = store.getters.org_context
      console.log(`The agency from org context is ....${agency}`)
      let path = `/pda/${agency}/speedydelivery/uploads/${this.caregiver_uname_selected}`
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
      // console.log("Just before updating to non-context changing route.")
      // console.log(JSON.stringify(store.getters.find_current_preamble))
      store.commit('update_context_changing_route', false)
      // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
      // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
      // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
      //  console.log("Just after updating to non-context changing route.")
      // console.log(JSON.stringify(store.getters.find_current_preamble))
      await this.$router.push({path: path})


    },
    async set_selected_caregiver_to_store(cg_pname) {
      console.log(`What in the cg name getting passed in ${cg_pname}`)
      console.log("What is the current pname map????")
      console.log(this.p_name_to_usename_map)
      //
      if (cg_pname === "" || cg_pname === undefined || !this.does_caregiver_exist(cg_pname)) {
        console.log("It looks like this caregiver is undefinee.")
        this.selected_caregiver_pname_ = ""
        this.selected_caregiver_user_name_ = ""
        this.selected_caregiver_module_data_ = []
        this.selected_caregiver_module_summary_table_ = []
        this.caregiver_defined_ = false
        store.commit('set_speedydelivery_agency_selected_caregiver', this.selected_caregiver_user_name_)
        store.commit('set_speedydelivery_agency_selected_caregiver_pname', this.selected_caregiver_pname_)
        store.commit('set_speedydelivery_current_caregiver_profile_image', "")
        return null
        //
      }
      let the_map = store.getters.get_pname_to_tmhname_map
      this.selected_caregiver_pname_ = cg_pname
      this.selected_caregiver_user_name_ = the_map[cg_pname]

      localStorage.setItem('SD_selected_cg_username', the_map[cg_pname])

      // console.log(`The PName: ${this.selected_caregiver_pname} ---- The UName ${this.selected_caregiver_user_name}`)

      store.commit('set_speedydelivery_agency_selected_caregiver', this.selected_caregiver_user_name_)
      store.commit('set_speedydelivery_agency_selected_caregiver_pname', this.selected_caregiver_pname_)
      this.profile_image_ = await this.get_profile_image_from_db(this.selected_caregiver_user_name_)
      store.commit('set_speedydelivery_current_caregiver_profile_image', this.profile_image_)

      await this.get_selected_user_sd_data(this.selected_caregiver_user_name_)
      await this.pull_speedy_data()
      this.caregiver_defined_ = true

      let r = `/pda/${store.getters.org_context}/speedydelivery/${this.selected_caregiver_user_name_}`
      store.commit('save_latest_route', r)
      store.commit('update_context_changing_route', false)
      await this.$router.push({path: r})

    },

    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w >= 280) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.action_button_width_ = 220
      }
      if (new_w >= 400) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.action_button_width_ = 350
      }
      if (new_w > 500) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 700) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
      }
      if (new_w > 1000) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = new_w < this.desktop_cutoff;
        console.log(`New size is ${new_w} and mobile mode is ${this.mobile_mode_x}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },


    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async check_for_general_admin_ui_perm() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'route',
        additional_context: [['div', 'o&a'], ['div', 'perms']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      // console.log(profile_data)
      let target = "speedydelivery:agency"
      if (profile_data.includes(target)) {
        this.admins_perm_ = true
        console.log("You have the speedydelivery general admins perm")
        return true
      } else {
        this.admins_perm_ = false
        console.log("You don't have the speedydelivery general admins perm")
        return false
      }
    },
  },
  computed: {
    ...mapGetters([
      'get_speedydelivery_agency_selected_caregiver',
      "window_width"
    ]),

    caregiver_defined() {
      return this.caregiver_defined_
    },
    show_sd_report_dl_button() {
      console.log("in show_sd_report_dl_button")
      // console.log(this.caregiver_name_selected)
      // console.log(this.caregiver_has_docs)
      let cg_sel = this.selected_caregiver_user_name_
      let docs = this.caregiver_has_docs
      let exists = this.does_caregiver_exist(cg_sel)
      console.log(`Three Truths: ${cg_sel}:${docs}:${exists}`)

      if (cg_sel && docs && exists) {
        return true
      } else {
        return false
      }
    },
    show_caregiver_image() {
      console.log("Inside show image")
      // console.log(this.caregiver_name_selected)
      // console.log(this.caregiver_exists)
      let cg_sel = this.selected_caregiver_user_name_
      if (cg_sel && this.does_caregiver_exist(cg_sel)) {
        return true
      } else {
        return false
      }
    },

    show_caregiver_modules() {
      console.log("Inside show modules")
      console.log(this.caregiver_pname_selected)
      console.log(this.does_caregiver_exist(this.caregiver_pname_selected))
      let cg_sel = this.selected_caregiver_user_name_
      if (this.does_caregiver_exist(cg_sel)) {
        console.log("RETURN TRUE FROM SHOW CAREGIVER MODULES.")
        return true
      } else {
        console.log("RETURN FALSE FROM SHOW CAREGIVER MODULES.")
        return false
      }
    },

    module_data_exists() {
      console.log("INSIDE MODULE DATA EXITSTS AHSNOETUSANOTEHUASNOTEHUSANTOEHUSANTOEHUSANTOEHUSANTOEHUSANOTEUSANTOEU")
      if (this.selected_caregiver_module_data.length > 0) {
        console.log("RETURN TRUE FROM ASOTEHUSANTOEHUSANOTEHUSANTOHEUSAONTEU BECAUSE THE LENGTH WAS......")
        console.log(this.selected_caregiver_module_data.length)

        return true
      } else {
        console.log("RETURN FALSE FROM SAONTEHUSANOTEHUSANTOHEUSANTOHEUSANTOHEUSANTOHEUSANTOHEUSANTOHU.")
        return false
      }
    },


    caregiver_pname_selected() {
      return this.selected_caregiver_pname_
    },

    caregiver_uname_selected() {
      return this.selected_caregiver_user_name_

    },
    caregiver_has_no_docs() {
      return this.no_doc_caregivers.includes(this.selected_caregiver_user_name_)
    },
    caregiver_has_docs() {
      return !this.no_doc_caregivers.includes(this.selected_caregiver_user_name_)
    },

    my_people() {
      // return this.my_people_
      return this.unique_preferred_names
    },
    list_of_caregiver_user_names() {
      return this.my_people_
    },

    list_of_caregiver_pnames_names() {
      return this.unique_preferred_names
    },

    profile_image() {
      // console.log("inside the profile-image computed prop bc of image chang.")
      return this.profile_image_
    },
    module_button_size() {
      console.log("Running the module size computed property.")
      return this.button_size_
    },
    module_image_size() {
      return this.button_img_size_
    },
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    admins_perm() {
      // console.log("in owner perm")
      // console.log(this.owners_perm_)
      return this.admins_perm_
    },
    action_button_width() {
      return this.action_button_width_ + "px"
    },

    default_no_selection_text() {
      return this.default_no_selection_text_
    },

    selected_caregiver_pname() {
      if (!this.selected_caregiver_pname_) {
        return this.default_no_selection_text
      } else {
        return this.selected_caregiver_pname_
      }
    },
    selected_caregiver_user_name() {
      if (this.selected_caregiver_user_name_ === "") {

        this.selected_caregiver_module_data_ = []
        return this.default_no_selection_text
      } else {
        return this.selected_caregiver_user_name_
      }
    },

    selected_caregiver_summary() {

      // console.log(`&&&&&&&&&&OK Hey....${this.selected_caregiver_module_summary_table_} with length: ${Object.keys(this.selected_caregiver_module_summary_table_).length}`)
      // console.log(this.selected_caregiver_module_summary_table_)
      if(isEmpty(this.selected_caregiver_module_summary_table_)){
        console.log("Setting teh summary table to null")
        this.selected_caregiver_module_summary_table_ = null
        return this.selected_caregiver_module_summary_table_
      }else{
        console.log("Setting teh summary table to a value")
        return this.selected_caregiver_module_summary_table_
      }


      // if (Object.keys(this.selected_caregiver_module_summary_table_).length === 0) {
      //   console.log("Setting teh summary table to null")
      //   this.selected_caregiver_module_summary_table_ = null
      //   return this.selected_caregiver_module_summary_table_
      // } else {
      //   console.log("Setting teh summary table to a value")
      //   return this.selected_caregiver_module_summary_table_
      // }
    },

    selected_caregiver_module_data() {
      let nopes = ["", undefined, [], null, {}]

      if (nopes.includes(this.selected_caregiver_module_data_) || this.selected_caregiver_module_data_.length === 0) {
        return []
      } else {
        return this.selected_caregiver_module_data_
      }
    },

    selected_caregiver_summary_data_pairs() {
      //  Create a list of lists like:
      //  [
      //    ['Credits', "10"],
      //    ['Exp. Date', "10/06/2023"],
      //    ['Exp. Days', "246"],
      //  ]
      let data_without_headers = this.selected_caregiver_summary_without_first[1]
      console.log("here is the data without headers")
      // console.log(data_without_headers)


      let credit_value = data_without_headers[1]
      let exp_date = data_without_headers[2]
      let exp_days = data_without_headers[3]

      // console.log(`Here are the values I got back...`)
      // console.log(`Credit value ${credit_value}`)
      // console.log(`Expiration date ${exp_date}`)
      // console.log(`Expiration Days ${exp_days}`)
      return [
        ['Credits', credit_value],
        ['Exp. Date', exp_date],
        ['Exp. Days', exp_days],
      ]

    },

    selected_caregiver_summary_without_first() {

      console.log("Inside selected_caregiver_summary_without_first")
      console.log(
          this.selected_caregiver_module_summary_table_
      )

      //Arbitrarily large document-type number that will never be reached.
      let aldtntwnbr = 100

      //Thanks to Amerzilla @ https://stackoverflow.com/questions/39336556/how-can-i-slice-an-object-in-javascript
      let sliced = Object.keys(this.selected_caregiver_module_summary_table_).slice(1, aldtntwnbr).reduce((result, key) => {
        result[key] = this.selected_caregiver_module_summary_table_[key];
        return result;
      }, {});
      return sliced
    },

    list_of_associated_users() {
      return this.list_of_associated_users
    },
    list_of_training_modules() {
      let out = []
      for (const element of speedy_icon_data) {
        out.push(element.name)
      }

      return out
    },

  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .get_report_button {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  h1 {
    color: white;
  }

  .sd_logo {
    width: 150px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 20px;
  }

  .tooltip_container:hover .tooltiptext {
    visibility: visible;
  }

  .mod_drop_down {
    width: 275px;
  }

  .mobile_cg_summary {
    width: 190px;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--pennie_gray);
    margin-bottom: 20px;
    /*box-sizing: border-box;*/
  }

  .desktop_cg_summary {
    max-width: 700px;
  }

  .mobile_cg_summary_heading {
    font-size: 25px;
  }

  .mobile_cg_summary_data {
    margin-top: 3px;
  }


  .tooltip_container .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 1000%;
    /*left: 50%;*/
    margin-left: 10px; /* Use half of the width (120/2 = 60), to center the tooltip */
    margin-bottom: -20px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }


  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {

}

@media (min-width: 500px) {

  .mod_drop_down {
    width: 310px;
  }

  .sd_logo {
    width: 200px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 28px;
  }
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .ft_logo {
    width: 400px
  }

  .manage_box {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .module_text_portion {
    min-width: 170px;
  }

  .q_summary {
    /*font-size: 28px;*/
    /*color: var(--wyatt_gray);*/
    margin-top: 20px;
    /*margin-bottom: ;*/
  }

}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
  }


  .mfa_method p {
  }


}
</style>