<template>
  <div class="core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>

    <div class="flex_centered_column">
      <section class="terms_wrapper ">
        <h5><strong>Privacy Policy</strong></h5>
        <p>Our Privacy Policy was last updated on <strong>{{ last_updated_str }}</strong>.</p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy.</p>
        <h5><strong>Interpretation and Definitions</strong></h5>
        <h4><strong>Interpretation</strong></h4>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular or in
          plural.</p>
        <h4><strong>Definitions</strong></h4>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p><strong>"Account"</strong> means a unique account created for You to access our Service or parts of our
              Service.</p>
          </li>
          <li>
            <p><strong>"Company"</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
              refers to <strong>{{ official_company_name }}</strong></p>
          </li>
          <li>
            <p><strong>"Country"</strong> refers to <strong>{{ country }}</strong>.</p>
          </li>
          <li>
            <p><strong>"Cookies"</strong> are small files that are placed on Your computer, mobile device or any other
              device by a website, containing the details of Your browsing history on that website among its many uses.
            </p>
          </li>
          <li>
            <p><strong>"Data Controller"</strong>, for the purposes of the GDPR (General Data Protection Regulation),
              refers to the Company as the legal person which alone or jointly with others determines the purposes and
              means of the processing of Personal Data.</p>
          </li>
          <li>
            <p><strong>"Device"</strong> means any device that can access the Service such as a computer, a cellphone or
              a digital tablet.</p>
          </li>
          <li>
            <p><strong>"Do Not Track"</strong> (DNT) is a concept that has been promoted by US regulatory authorities,
              in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement
              a mechanism for allowing internet users to control the tracking of their online activities across
              websites.</p>
          </li>
          <li>
            <p><strong>"Personal Data"</strong> is any information that relates to an identified or identifiable
              individual.</p>
          </li>
          <li>
            <p><strong>"Service"</strong> refers to the Website.</p>
          </li>
          <li>
            <p><strong>"Service Provider"</strong> means any natural or legal person who processes the data on behalf of
              the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to perform services related to the Service or to
              assist the Company in analyzing how the Service is used.</p>
          </li>
          <li>
            <p><strong>"Usage Data"</strong> refers to data collected automatically, either generated by the use of the
              Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          </li>
          <li>
            <p><strong>"Website"</strong> refers to <strong>{{ website_name }}</strong>, accessible from
              <strong>{{ website_url }}</strong></p>
          </li>
          <li>
            <p><strong>"You"</strong> means the individual accessing or using the Service, or the company, or other
              legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
          </li>
        </ul>
        <h5><strong>Collecting and Using Your Personal Data</strong></h5>
        <h4><strong>Types of Data Collected</strong></h4>
        <h6><strong>Personal Data</strong></h6>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
          can be used to contact or identify You. Personally identifiable information may include, but is not limited
          to:</p>
        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>First, Middle, Last and Nick name</p>
          </li>
          <li>
            <p>Phone number</p>
          </li>
          <li>
            <p>Address, State, Province, ZIP/Postal code, City</p>
          </li>
          <li>
            <p>Usage Data</p>
          </li>
        </ul>
        <h4><strong>Usage Data</strong></h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time
          spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
          including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
          of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
          device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the
          Service by or through a mobile device.</p>
        <h4><strong>Tracking Technologies</strong></h4>
        <p>We store certain information in your browser track the activity on Our Service. The technologies
          We use may include:</p>
        <ul>
          <li><strong>LocalStorage</strong> LocalStorage provides a way to persistently store data in your browser.
            Our service will not function without the use of LocalStorage.
          </li>
          <li><strong>IndexedDB</strong> IndexedDB provides a way to persistently store data in your browser.
            Our service will not function without the use of IndexedDB.
          </li>
        </ul>
        <p>These technologies are "Persistent" in that the saved data remain on Your personal computer or
          mobile device when You go offline.</p>
        <p>We may use Persistent storage for the purposes set out below:</p>
        <ul>
          <li>
            <p><strong>Necessary / Essential Storage</strong></p>
            <p>Type: Persistent</p>
            <p>Administered by: Us</p>
            <p>Purpose: This storage is essential to provide You with services available through the Website and to
              enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user
              accounts. Without this storage, the services that You have asked for cannot be provided, and We only use
              this storage to provide You with those services.</p>
          </li>
          <!--          <li>-->
          <!--            <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>-->
          <!--            <p>Type: Persistent Cookies</p>-->
          <!--            <p>Administered by: Us</p>-->
          <!--            <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <p><strong>Functionality Cookies</strong></p>-->
          <!--            <p>Type: Persistent</p>-->
          <!--            <p>Administered by: Us</p>-->
          <!--            <p>Purpose: This storage allows us to remember choices You make when You use the Website, such as-->
          <!--              remembering your login details or language preference. The purpose of this storage is to provide You with-->
          <!--              a more personal experience and to avoid You having to re-enter your preferences every time You use the-->
          <!--              Website.</p>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <p><strong>Tracking and Performance Cookies</strong></p>-->
          <!--            <p>Type: Persistent Cookies</p>-->
          <!--            <p>Administered by: Third-Parties</p>-->
          <!--            <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the-->
          <!--              Website. The information gathered via these Cookies may directly or indirectly identify you as an-->
          <!--              individual visitor. This is because the information collected is typically linked to a pseudonymous-->
          <!--              identifier associated with the device you use to access the Website. We may also use these Cookies to test-->
          <!--              new pages, features or new functionality of the Website to see how our users react to them.</p>-->
          <!--          </li>-->
        </ul>
        <!--        <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies-->
        <!--          Policy or the Cookies section of our Privacy Policy.</p>-->
        <h4><strong>Use of Your Personal Data</strong></h4>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
          </li>
          <li>
            <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
              Personal Data You provide can give You access to different functionalities of the Service that are
              available to You as a registered user.</p>
          </li>
          <li>
            <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the
              purchase contract for the products, items or services You have purchased or of any other contract with Us
              through the Service.</p>
          </li>
          <li>
            <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms
              of electronic communication, such as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their implementation.</p>
          </li>
          <li>
            <p><strong>To provide You</strong> with news, special offers and general information about other goods,
              services and events which we offer that are similar to those that you have already purchased or enquired
              about unless You have opted not to receive such information.</p>
          </li>
          <li>
            <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
          </li>
          <li>
            <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
              divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
              Personal Data held by Us about our Service users is among the assets transferred.</p>
          </li>
          <li>
            <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data
              analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing and your experience.</p>
          </li>
        </ul>
        <p>We may share Your personal information in the following situations:</p>
        <ul>
          <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, for payment processing, to contact You.
          </li>
          <li><strong>With home care agency customers of the Company:</strong> We may share Your personal information
            with subscribing agencies to facilitate the core services of the Company. Accepting the
            'meet' code provided to you by a home care agency customer on the {{ website_name }} application represents
            your consent to share personal information with home care agency customers.
          </li>
          <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection
            with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a
            portion of Our business to another company.
          </li>
          <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will
            require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We control or that are under common control
            with Us.
          </li>
          <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer
            You certain products, services or promotions.
          </li>
          <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public
            areas with other users, such information may be viewed by all users and may be publicly distributed outside.
          </li>
          <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with
            Your consent.
          </li>
        </ul>
        <h4><strong>Retention of Your Personal Data</strong></h4>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
          a shorter period of time, except when this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <h4><strong>Transfer of Your Personal Data</strong></h4>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
          places where the parties involved in the processing are located. It means that this information may be
          transferred to — and maintained on — computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
          to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
          or a country unless there are adequate controls in place including the security of Your data and other
          personal information.</p>
        <h5><strong>Disclosure of Your Personal Data</strong></h5>
        <h4><strong>Business Transactions</strong></h4>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
          will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
          Policy.</p>
        <h4><strong>Law enforcement</strong></h4>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
          by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <h4><strong>Other legal requirements</strong></h4>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
        </ul>
        <h4><strong>Security of Your Personal Data</strong></h4>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
          to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        <h4><strong>Detailed Information on the Processing of Your Personal Data</strong></h4>
        <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store,
          use, process and transfer information about Your activity on Our Service in accordance with their Privacy
          Policies.</p>
        <h5><strong>Analytics</strong></h5>
        <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
        <!--        <p>[___LIST___]</p>-->
        <h5><strong>Children's Privacy</strong></h5>
        <p>Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable
          information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child
          has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
          from anyone under the age of 18 without verification of parental consent, We take steps to remove that
          information from Our servers.</p>
        <h5><strong>Links to Other Websites</strong></h5>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
          link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
          every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.</p>
        <h5><strong>Changes to this Privacy Policy</strong></h5>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
          Privacy Policy on this page.</p>

        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.</p>
        <h5><strong>Contact Us</strong></h5>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul>
          <li>By visiting this page on our website: <strong>{{ website_url }}</strong></li>
          <li>By sending us an email: <strong>{{ contact_email }}</strong></li>
        </ul>
        <p></p>
      </section>
    </div>


  </div>

  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now
} from "@/library";

export default {
  name: "privacy",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      last_updated_str: "January 17, 2023",
      official_company_name: "Tiny Magic Hat, Inc.",
      country: "the United States of America",
      website_name: "Tiny Magic Hat",
      website_url: "https://TinyMagicHat.com",
      contact_email: "info@TinyMagicHat.com",
      contact_page_url: "https://TinyMagicHat.com/contact"

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    run_window_width_adjustments(new_w) {

      if (num_is_between_inclusive(100, 280, new_w)) {
        //do something
      }
      if (num_is_between_inclusive(281, 500, new_w)) {
        //do something
      }
      if (num_is_between_inclusive(501, 1200, new_w)) {
        this.mobile_mode_x = false
      }
      if (num_is_between_inclusive(1201, 10000, new_w)) {
        //do something
      }
    },

  },
  computed: {
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  .terms_wrapper {
    width: 100vw;
  }

  .terms_wrapper p {
    font-size: 15px;
  }


  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .terms_wrapper {
    width: 80vw;
  }

  .terms_wrapper p {
    font-size: 15px;
  }

  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 2000px) {
  .terms_wrapper {
    width: 70vw;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

}
</style>