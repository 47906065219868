<template>
  <div class="core_c">
 <inside_banner
        mode="speedydelivery_user"
    ></inside_banner>
     <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>

    <div class="exam_form_wrapper">
      <exam_with_slots_form
          :questions_texts="questions_texts"
          header_text="Infection Control Module Test"
          module_name="26c0534f-9cdb-4ca1-8c66-ca80be52b78c"
      >

        <true_false_and_question2
            class="a_question"
            ref="q_one"
            question_num="one"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.one
        ></true_false_and_question2>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_two"
            question_num="two"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.two
        ></true_false_and_question2>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_three"
            question_num="three"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.three
        ></true_false_and_question2>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_four"
            question_num="four"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.four
        ></true_false_and_question2>
        <div class="spacer"></div>

        <multiple_choice_and_question
            ref="q_five"
            number_of_choices=4
            question_num="five"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.five
        ></multiple_choice_and_question>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_six"
            question_num="six"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.six
        ></true_false_and_question2>
        <div class="spacer"></div>

        <multiple_choice_and_question
            ref="q_seven"
            number_of_choices=4
            question_num="seven"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.seven
        ></multiple_choice_and_question>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_eight"
            question_num="eight"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.eight
        ></true_false_and_question2>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_nine"
            question_num="nine"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.nine
         ></true_false_and_question2>
        <div class="spacer"></div>

        <true_false_and_question2
            class="a_question"
            ref="q_ten"
            question_num="ten"
            @true_value_update="update_answer_value"
            :question_text=this.questions_texts.ten
        ></true_false_and_question2>

      </exam_with_slots_form>
    </div>


    <div class="flex_centered_row">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 submit_ans_button"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="all_questions_answered"
          :button_color="colors.primary_purple"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="200px"
          border_rad_pct="0"
          text="Submit Your Answers"
          text_size="17px"
          :bold="true"
          event_name="submit_answers_button_clicked"
          @submit_answers_button_clicked="post_test_answers"
      ></basic_text_button>

    </div>

  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import multiple_choice_and_question from "@/components/parts/multiple_choice_and_question";
import exam_with_slots_form from "@/components/basics/exam_with_slots_form";
import true_false_and_question2 from "@/components/parts/true_false_and_question2";

import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  be_main_address,
  parse_internal_json,
  receive_over_socket, get_speedy_user_interface_route
} from "@/library";
import axios from "axios";
import inside_banner from "@/components/parts/inside_banner.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";

export default {
  name: "infection_control",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    basic_page_infrastructure, inside_banner,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner,
    exam_with_slots_form,
    multiple_choice_and_question,
    basic_text_button,
    true_false_and_question2
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      module_name: "26c0534f-9cdb-4ca1-8c66-ca80be52b78c",
      answers_value: {
        'one': null,
        'two': null,
        'three': null,
        'four': null,
        'five': null,
        'six': null,
        'seven': null,
        'eight': null,
        'nine': null,
        'ten': null,
      },
      questions_texts: {
        one: "To rid your hands of germs, you should use the hottest water you can stand.",
        two: "When using an antibacterial household cleaner, it is important to follow the specific directions on the container.",
        three: "Putting laundry in a dryer for ten minutes kills any germs that may be present.",
        four: "If needles are recapped, they may be tossed into the household trash.",
        five: {
          'question': "If you notice rodents or insects in a client’s home, you should:",
          'answers': {
            a: "Leave the home immediately.",
            b: "Report your client to the Department of Health.",
            c: "Spray the pests with a can of Raid.",
            d: "Tell your supervisor and/or the social worker about the problem.",
          },
        },
        six: "The kitchen sponge is one of the dirtiest items in a house.",
        seven: {
          'question': "To keep your client safe from food-borne bacteria, you should:",
          'answers': {
            a: "Refrigerate food and leftovers within two hours.",
            b: "Keep raw meat cold by storing it on the top shelf of the refrigerator.",
            c: "Thaw foods by sitting them out on the kitchen counter.",
            d: "Wash raw meat before cooking it.",
          },
        },
        eight: "People with weakened immune systems are at risk for catching an infection from their pets.",
        nine: "Most infections are caught by touching germs on door knobs and other dirty objects.",
        ten: "The outside of your purse or client care bag is probably home to as many germs as the bottom of your shoes.",
      },

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async local_page_update() {
      // await this.load_history_reports_modern()
      console.log("Running the code that runs when the page gets an update oven the socket server.")
    },
    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      if (update) {
        store.commit('increment_universal_page_update', true)
      }
    },
    update_answer_value(values) {
      let value = values[0]
      let question = values[1]
      this.answers_value[question] = value
      // console.log("current values are.....")
      // console.log(this.answers_value)

    },
    async post_test_answers() {
      store.commit('set_spinner_state', true)
      let module_name = this.module_name
      let answers = this.answers_value
      console.log(`Answers.....`)
      console.log(answers)
      console.log("you just clicked to save to the back end.")
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_id: module_name,
        answers: answers
      }
      let endpoint = be_main_address('speedydelivery') + 'submit_test_results'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        let message = response.data['message']
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('set_spinner_state', false)
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          // let message = parse_internal_json(response.data)['message']
          store.commit('set_spinner_state', false)
          store.commit('notification_enqueue', message)

          console.log(message)
          if(message.includes('Congratulations')){
            console.log("The message contained congratulations.")
            store.commit('set_spinner_state', false)
            this.$router.push({path: `${get_speedy_user_interface_route()}`})
          }else{
            store.commit('set_spinner_state', false)
             console.log("The message did not contain congratulations.")
          }
        }
      }, (error) => {
        console.log('Error on submission of test results.')
        console.log(error);
        store.commit('set_spinner_state', false)
        store.commit('error_enqueue', "Something went wrong trying to submit your test results.")
      });

    },
    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

  },
  computed: {
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    update_page() {
      console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    all_questions_answered() {
      console.log('in all questions answered.')
      if (
          this.answers_value['one'] !== null &&
          this.answers_value['two'] !== null &&
          this.answers_value['three'] !== null &&
          this.answers_value['four'] !== null &&
          this.answers_value['five'] !== null &&
          this.answers_value['six'] !== null &&
          this.answers_value['seven'] !== null &&
          this.answers_value['eight'] !== null &&
          this.answers_value['nine'] !== null &&
          this.answers_value['ten'] !== null
      ) {
        console.log("all are")
        return true
      } else {
        return false
      }
    },
    ans_one() {
      return this.answers_value['one']
    },
    ans_two() {
      return this.answers_value['two']
    },
    ans_three() {
      return this.answers_value['three']
    },
    ans_four() {
      return this.answers_value['four']
    },
    ans_five() {
      return this.answers_value['five']
    },
    ans_six() {
      return this.answers_value['six']
    },
    ans_seven() {
      return this.answers_value['seven']
    },
    ans_eight() {
      return this.answers_value['eight']
    },
    ans_nine() {
      return this.answers_value['nine']
    },
    ans_ten() {
      return this.answers_value['ten']
    },
  },
  watch: {

    async update_page() {
      console.log("update_page watcher is triggered")
      await this.local_page_update()
    },

    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },

  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  .exam_form_wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
  }

  .spacer {
    height: 50px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {
}

@media (min-width: 1200px) {

  .spacer {
    height: 50px;
    /*background-color: green;*/
  }

  .submit_ans_button {
    margin-bottom: 100px;
  }

}

@media (min-width: 2000px) {
}
</style>