import Dexie from 'dexie';
import store from '../store'
import {MD5, parse_internal_json} from "@/library";

// See https://github.com/dfahlander/Dexie.js/blob/master/samples/vue/src/database.js
export class Database extends Dexie {
    constructor() {
        super('database');

        this.version(1).stores({
            tmh_client_store: 'key'
        });

        this.tmh_client_store = this.table('tmh_client_store');
    }

    async deleteKeyandValue(k) {
        return this.tmh_client_store.delete(k)
    }

    async addKeyValue(k, v) {
        let v_parsed = null
        try {
            if (typeof v === "string") {
                v_parsed = JSON.parse(v)
            } else {
                v_parsed = v
            }
        } catch (error) {
            // console.log("json error with " + v)
            console.error(error);
        }

        if (v_parsed['db_action'] === "DELETE_KEY") {
            // console.log("in delete key if...")
            return this.deleteKeyandValue(k)
        } else {
            // console.log(`db putting ${k} in database.`)
            try {
                return await this.tmh_client_store.put({key: k, value: v})
            } catch (error) {
                console.log(`Problem putting ${k} in the database.`)
                console.log(error)
            }
            // return this.tmh_client_store.put({key: k, value: v})
        }
    }

    async getKeyValue(k) {
        return this.tmh_client_store.get(k);
    }

    async get_all_keys() {
        //  Returns array of all keys in the database.
        //   return this.tmh_client_store.orderBy('key').primaryKeys()
        return this.tmh_client_store.where('key').startsWith('/').primaryKeys()
    }

    arrayEquals(a, b) {

        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    arrayofarrayEquals(a, b) {


        let res = false
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length === b.length) {
                let i = 0
                while (i < a.length) {
                    let tester = this.arrayEquals(a[i], b[i])
                    if (tester === false) {

                        return res
                    } else {
                        //pass
                    }
                    i++
                }
                return true
            } else {

                return res
            }
        } else {

            return res
        }
    }

    _key_to_stack(key) {
        // Converts a key to an array of arrays, throwing out the UUID. This list is called a 'stack'.
        let parts = key.split('/')
        parts.splice(-1)
        parts = parts.filter(e => e !== '');

        let i = 0;
        let res = []
        while (i < parts.length) {
            try {
                let t = parts.slice(i, i + 2);
                res.push([t[0], t[1]]);
                i += 2;
            } catch (error) {
                console.log(error);
                break;
            }
        }
        return res
    }


    _keys_to_stacks(keys) {
        // Covert a list of keys to a list of stacks.

        let res = []
        for (let k of keys) {
            res.push(this._key_to_stack(k))
        }
        return res
    }

    // async _filter_by_context(context, stacks) {
    //     // Filter a list of stacks by the context, which is a list of two-tuples.
    //     // Filter the keys based on the context
    //
    //     analytic({
    //         source: "_filter_by_context",
    //         event: "WaypointFilterContext1: context",
    //         message: context
    //     })
    //
    //     analytic({
    //         source: "_filter_by_context",
    //         event: "WaypointFilterContext1.1: stacks",
    //         message: stacks
    //     })
    //
    //
    //     let c_len = context.length
    //     let filtered_res = []
    //
    //     // Appears to be an error here
    //
    //     for (let s of stacks) {
    //         let sample = s.slice(0, c_len)
    //         if (await this.arrayofarrayEquals(sample, context)) {
    //             filtered_res.push(s)
    //         } else {
    //             //pass
    //         }
    //     }
    //
    //     analytic({
    //         source: "_filter_by_context",
    //         event: "WaypointFilterContext2: on return",
    //         message: filtered_res
    //     })
    //
    //     return filtered_res
    // }

    _filter_stacks(context3, stacks) {
        let c_len = context3.length
        let filtered_res = []
        for (let s of stacks) {
            let sample = s.slice(0, c_len)
            if (this.arrayofarrayEquals(sample, context3)) {
                filtered_res.push(s)
            } else {
                //pass
            }
        }
        return filtered_res
    }

    _filter_by_context(context4, stacks) {
        // Filter a list of stacks by the context, which is a list of two-tuples.
        // Filter the keys based on the context

        // analytic({
        //     source: "_filter_by_context",
        //     event: "WaypointFilterContext1: context",
        //     message: context4
        // })
        //
        // analytic({
        //     source: "_filter_by_context",
        //     event: "WaypointFilterContext1.1: stacks",
        //     message: stacks
        // })


        // let c_len = context.length
        // let filtered_res = []
        //
        // // Appears to be an error here
        //
        // for (let s of stacks) {
        //     let sample = s.slice(0, c_len)
        //     if (this.arrayofarrayEquals(sample, context)) {
        //         filtered_res.push(s)
        //     } else {
        //         //pass
        //     }
        // }

        let filtered_res = this._filter_stacks(context4, stacks)

        // analytic({
        //     source: "_filter_by_context",
        //     event: "WaypointFilterContext2: on return",
        //     message: filtered_res
        // })

        return filtered_res
    }

    get_context_stacks(context5, keys) {
        // #  Filter the keys based on the context
        // analytic({
        //     source: "get_context_stacks",
        //     event: "WaypointStacks1: context",
        //     message: context5
        // })

        // analytic({
        //     source: "get_context_stacks",
        //     event: "WaypointStacks2: keys",
        //     message: keys
        // })
        // Returns a set of stacks that match the context.
        // # Break down key strings into a list o lists of two-tuples ('people','wyatt')
        let res = this._keys_to_stacks(keys)
        // analytic({
        //     source: "get_context_stacks",
        //     event: "WaypointStacks3: post keys to stacks",
        //     message: res
        // })
        let fbc = this._filter_by_context(context5, res)
        // analytic({
        //     source: "get_context_stacks",
        //     event: "WaypointStacks4: filtered",
        //     message: fbc
        // })
        return fbc

    }

    _stack_to_key(stack) {
        //Covert a single stack to a single key."""

        let slash = '/'
        let res = slash
        for (let item of stack) {
            for (let i of item) {
                res += i
                res += slash
            }
        }
        return res
    }

    _get_context_keys(stacks) {
        let res = []
        for (let stack of stacks) {
            res.push(this._stack_to_key(stack))
        }
        return res
    }

    hello_test() {
        let m = "hello"
        let hash = MD5(m)
        return "hello " + hash
    }

    async get_keys_by_substring(substring) {
        // Give a substring (partial or whole key path), return array of all matching keys.
        // console.log(`Substring is ${substring}`)
        let keys = await this.get_all_keys()
        // console.log(`Here are all the keys I found: ${keys}`)
        let out = []
        for (let q of keys) {
            // console.log(`Key is ${q}`)
            if (q.includes(substring)) {
                // console.log(`The substring ${substring} is in ${q}.`)
                out.push(q)
            } else {
                // console.log(`The substring ${substring} is NOT in ${q}.`)
            }
        }
        // console.log(`Returned keys is ${out}`)
        return out
    }

    async db_substring_search(substring, key = "", verbose = false) {
        let context_keys = await this.get_keys_by_substring(substring)
        console.log("Found these substring keys")
        console.log(context_keys)

        let out = []
        for (let k of context_keys) {
            let db_return = await this.getKeyValue(k)

            if (verbose) {
                console.log(`THe key value is ${k}`)
                console.log(`THe value under the key is is:`)
                console.log(JSON.stringify(db_return))
                console.log(`THe "key" is ${key}`)
                console.log(`Does the key include "profile"?`)
                console.log(k.includes('profile'))
            }

            try {
                let v1
                v1 = parse_internal_json(db_return['value']['value'])
                console.log("just parsed the internal json.... Got back")
                console.log(v1)
                if (key) {
                    // let v = JSON.parse(db_return['value']['value'])[key]
                    // console.log("run parse_internal_json on ")
                    // console.log(db_return['value']['value'])
                    // console.log(`which is a/an ${typeof db_return['value']['value']}`)
                    // v1 = parse_internal_json(db_return['value']['value'])
                    // console.log(v1)
                    let v = v1[key]
                    // console.log(v)
                    if (v !== undefined) {
                        // out.push( JSON.parse(db_return['value']['value'])[key])
                        out.push(v)
                    }
                } else {
                    // out.push(JSON.parse(db_return['value']['value']))
                    out.push(v1)
                }

            } catch (error) {
                // console.log("parsing error caught")
                console.log(error)
            }
        }
        console.log("Here is what I found in the data base......")
        console.log(out)
        return out

    }


    async get_context_keys(context1) {
        // Returns a list of all the keys in keys that contain the context.

        // console.log("Inside db with get_context_keys Current preamble is:")
        // console.log(context)
        if (!context1) {
            context1 = store.getters.get_safari_store
            // analytic({
            //     source: "get_context_keys",
            //     event: "WaypointKeys0: Context Missing. Using value from Vuex.",
            //     message: context1
            // })
        } else {
            // analytic({
            //     source: "get_context_keys",
            //     event: "WaypointKeys1: context param",
            //     message: context1
            // })
        }


        let keys = await this.get_all_keys()

        // analytic({
        //     source: "get_context_keys",
        //     event: "WaypointKeys2: keys from db",
        //     message: keys
        // })
        // console.log("all keys are from db pull are")
        // console.log(keys)
        // console.log('Launching get context stacks')
        let c_stacks = this.get_context_stacks(context1, keys)
        // console.log('Back from get context stacks with')
        // console.log(c_stacks)

        // analytic({
        //     source: "get_context_keys",
        //     event: "WaypointKeys3",
        //     message: c_stacks
        // })
        // console.log("get context stacks result is...")
        // console.log(c_stacks)
        // console.log('Launching ____get context stacks')
        let c_keys = this._get_context_keys(c_stacks)
        // console.log('Back from _____get context stacks with')
        // console.log(c_keys)

        // analytic({
        //     source: "get_context_keys",
        //     event: "WaypointKeys4",
        //     message: c_keys
        // })
        // console.log("get context keys result is...")
        // console.log(c_keys)
        // let key_preamble = c_keys[0]

        let u_keys = [...new Set(c_keys)];

        // analytic({
        //     source: "get_context_keys",
        //     event: "WaypointKeys5",
        //     message: ""
        // })
        // console.log('Here are the unique keys...3.')
        // console.log(u_keys)


        // console.log('in get_context_keys 147')
        // console.log('length of keys returned under this context is:')
        // console.log(c_keys.length)
        //  console.log('c_keys are:')
        //  console.log(c_keys)

        let out = []

        // console.log('what is the key preamble?')
        // console.log(key_preamble)
        // for (let q of keys) {
        //     if (q.includes(key_preamble)) {
        //         out.push(q)
        //     }
        // }

        for (let q of keys) {
            // console.log(`Key is ${q}`)
            for (let u of u_keys) {
                // console.log(`U-Key is ${u}`)
                // console.log(`Does ${q} contain ${u}?`)
                if (q.includes(u)) {
                     // console.log(`U-Key is ${u}`)
                // console.log(`Does ${q} contain ${u}?`)
                //     console.log('yes..........')
                    out.push(q)
                } else {
                    // console.log("no_________________________")
                }
            }
        }

        // analytic({
        //     source: "get_context_keys",
        //     event: "WaypointKeys6: out",
        //     message: out
        // })

        // console.log('here are the keys that I filtered down')
        // console.log(out)


        return out
    }

    async get_key_request(req, context2, keys) {
        // Generates a tuple of the names of the entities that match the requests and context within the keys.
        // For example, what services is 'wyatt' associated to via rah?"""

        // console.log(`What is the context we are searching: ${JSON.stringify(context2)}`)
        // console.log(`What are the keys: ${JSON.stringify(keys)}`)
        // console.log(`What is the request: ${JSON.stringify(req)}`)

        let filtered_res = this.get_context_stacks(context2, keys)

        // generate a set of req items from the context filtered keys.
        let out = new Set()
        for (let fr of filtered_res) {
            // let without_context = fr.splice(context2.length)
            let without_context = fr.slice(context2.length)

            let next_item = without_context[0]
            // console.log(`Without context item: ${next_item}`)
            if (next_item[0] === req) {
                out.add(next_item[1])
            } else {
                //pass
            }
        }
        return Array.from(out)
    }

    async db_search(search_object, v) {
        // console.log("v is ...")
        // console.log(v)
        // console.log("search object is....")
        // console.log(search_object)
        //if you want verbose logging for a certain key, pass it in as 'v'
        //Example parameter values
        // additional_context: ['div', 'misc']
        //key : 'org_type'

        // analytic({
        //     source: "Inside DB Search",
        //     event: "WaypointDB1",
        //     message: ""
        // })

        let key, additional_context, base_context, current_preamble_array
        let verbose = false
        if (v) {
            console.log(`You are running verbose search on ${v}`)
        }

        // console.log("inside db_search. Base context is......")
        // console.log(JSON.stringify(search_object.base_context))
        // console.log(JSON.stringify(search_object.additional_context))
        // console.log(search_object.key)


        if (search_object['key'] === '') {
            key = null
        } else {
            key = search_object['key']
        }

        if (key === v) {
            verbose = true
        }

        if (search_object['base_context'] === '') {
            base_context = null
        } else {
            // base_context = search_object['base_context']
            base_context = JSON.parse(JSON.stringify(search_object['base_context']))
        }

        if (!base_context) {
            current_preamble_array = JSON.parse(JSON.stringify(store.getters.find_current_preamble))
        } else {
            current_preamble_array = base_context
        }

        // analytic({
        //     source: "Inside DB Search",
        //     event: "WaypointDB2: initial current preamble array",
        //     message: current_preamble_array
        // })

        additional_context = search_object['additional_context']

        for (const item of additional_context) {
            current_preamble_array.push(item)
        }
        // console.log("Just loaded additional context and current preamble is now.")
        // console.log(JSON.stringify(current_preamble_array))
        store.commit("set_safari_store", current_preamble_array)

        if (verbose) {
            console.log("database to look for data under this key")
            console.log(JSON.stringify(current_preamble_array))
        }

        // analytic({
        //     source: "Inside DB Search",
        //     event: "WaypointDB2: useable current preamble array",
        //     message: current_preamble_array
        // })
        //
        // analytic({
        //     source: "Inside DB Search",
        //     event: "WaypointDB3 - Preamble array Jsonified",
        //     message: JSON.stringify(current_preamble_array)
        // })


        // console.log("Launching get_context_keys")
        let context_keys = await this.get_context_keys(current_preamble_array)
        // console.log("Back form get_context_keys with...")
        // console.log(context_keys)

        // console.log('get_context_keys, db file line 531')
        // console.log(context_keys)


        // analytic({
        //     source: "Inside DB Search",
        //     event: "WaypointDB4 - Context Keys Length",
        //     message: context_keys.length
        // })


        let out = []
        // if (verbose) {
        // console.log("found these context keys...")
        // console.log(context_keys)
        // }


        for (let k of context_keys) {

            // analytic({
            //     source: "Inside DB Search",
            //     event: `WaypointDB5 -key: ${k}`,
            //     message: ""
            // })

            let db_return = await this.getKeyValue(k)

            // analytic({
            //     source: "Inside DB Search",
            //     event: `WaypointDB6 -value for ${k}`,
            //     message: db_return
            // })

            if (verbose) {
                console.log(`THe key value is ${k}`)
                console.log(`THe value under the key is is:`)
                console.log(JSON.stringify(db_return))
                console.log(`THe "key" is ${key}`)
                console.log(`Does the key include "profile"?`)
                console.log(k.includes('profile'))
            }

            try {
                let v1
                v1 = parse_internal_json(db_return['value']['value'])
                // console.log("just parsed the internal json.... Got back")
                // console.log(v1)
                if (key) {
                    // let v = JSON.parse(db_return['value']['value'])[key]
                    // console.log("run parse_internal_json on ")
                    // console.log(db_return['value']['value'])
                    // console.log(`which is a/an ${typeof db_return['value']['value']}`)
                    // v1 = parse_internal_json(db_return['value']['value'])
                    // console.log(v1)
                    let v = v1[key]
                    // console.log(v)
                    if (v !== undefined) {
                        // out.push( JSON.parse(db_return['value']['value'])[key])
                        // analytic({
                        //     source: "Inside DB Search",
                        //     event: `WaypointDB7 - value pulled for ${k}`,
                        //     message: v
                        // })
                        out.push(v)
                    }
                } else {
                    // out.push(JSON.parse(db_return['value']['value']))
                    // analytic({
                    //     source: "Inside DB Search",
                    //     event: `WaypointDB7 - value pulled for ${k}, but no key used`,
                    //     message: v1
                    // })
                    out.push(v1)
                }

            } catch (error) {
                // console.log("parsing error caught")
                // analytic({
                //     source: "Inside DB Search",
                //     event: `WaypointDB8 - parsing error`,
                //     message: error.toString()
                // })
                console.log(error)
            }
        }
        return out
    }


    async get_current_entity_type(search) {
        search.additional_context = [['div', 'misc']]

        // console.log("base context is")
        // console.log(search.base_context)
        let route_perms_array = await this.db_search(search)
        // console.log("inside the new get_current_entity_type")
        // console.log("returned route_perms_array is")
        // console.log(route_perms_array)
        // console.log(route_perms_array[0])

        if (route_perms_array.length === 0) {
            // console.log("Current route_perms_array is an empty array. Returning 'person'.")
            return 'person'
        } else {
            return route_perms_array[0]
        }

    }


     async get_current_entity_type_by_name(org_name) {
        let my_name = store.getters.my_name

        // console.log(`Find current preamble`)
        // console.log(store.getters.find_current_preamble)

        // let current_org_portion_of_preamble = store.getters.preamble_org_portion

        let search = {
            key: 'entity_type',
            additional_context: [['div', 'misc']],
            base_context: [['people', my_name],['org', org_name]]
        }
        // console.log('search query is')

        // console.log( typeof search)
        // console.log(search)

        let db_res_array = await this.db_search(search)
        // console.log("db_res_array")
        // console.log(db_res_array)

        if (db_res_array.length === 0) {
            return 'person'
        } else {
            return db_res_array[0]
        }
    }


    async get_current_entity_type_modern() {

        // console.log(`Find current preamble`)
        // console.log(store.getters.find_current_preamble)

        let current_org_portion_of_preamble = store.getters.preamble_org_portion

        let search = {
            key: 'entity_type',
            additional_context: [['div', 'misc']],
            base_context: current_org_portion_of_preamble
        }
        // console.log('search query is')

        // console.log( typeof search)
        // console.log(search)

        let db_res_array = await this.db_search(search)
        // console.log("db_res_array")
        // console.log(db_res_array)

        if (db_res_array.length === 0) {
            return 'person'
        } else {
            return db_res_array[0]
        }
    }


}

// module.exports = Database
// window.module.exports = Database