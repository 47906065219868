<template>
  <div class="core_c">

    <section>
      <div class="outline margin_lr_5 flex_centered_column">
        <div class="flex_centered_row">
          <!--          <img class="agency_logo cursor_ptr "-->
          <!--               src="@/assets/rah_teal.jpg"-->
          <!--               alt="Agency Logo Image">-->
          <image_upload_widgit
              ref="upload_widgit"
              class=" margin_t_40 margin_b_20"
              :image_value=null
              image_width="260"
              image_height="145"
              frame_text="You need to upload an agency logo on your profile page."
              event_name="save_pic"
              :owners_perm="false"
              @save_pic="do_nothing"
          ></image_upload_widgit>
        </div>
        <h5 class="margin_b_20">{{ headline_text || ad_parts['ad_headline'] }}</h5>
        <p class="ad_body_text">{{ body_text || ad_parts['ad_body'] }}</p>

        <img class="staff_photo cursor_ptr"
             :src=current_photo_found
             alt="Agency Staff Photo">

        <p class="photo_caption">{{ caption_text || ad_parts['ad_photo_caption'] }}</p>
        <div v-if="founding_year" class="since outline">
          <h5 class="text_align_center">Providing Care Since</h5>
          <h5 class="text_align_center">{{founding_year}}</h5>
        </div>
        <div class="rate_box outline ">
          <h5 class="margin_b_20">Hourly Rates as of {{ad_parts['pricing_week']}}</h5>
          <table class="font_raleway rate_text">
            <tr>
              <td class="font_weight_800">Base Rate:</td>
              <td>${{ base_rate_calc }}</td>
            </tr>
            <tr>
              <td class="font_weight_800">Weighted Avg:</td>
              <td>${{ weighted_avg_calc }}</td>
            </tr>
          </table>
        </div>
        <div class="discount_box outline">
          <h3 class="margin_t_30">New Client Discount</h3>
          <h3>${{ discount_amount }}</h3>
          <p v-if="!isEmpty(hours_calc)" class="margin_t_30">Right at home is currently offering a new client discount
            worth
            ${{ discount_amount }}. Register your
            discount and apply it against your invoices.</p>
          <p v-else class="margin_t_30">There is no new client discount being offered at this time.</p>
          <div class="flex_centered_row">
            <basic_text_button
                class="padding_lr_6 padding_tb_6  margin_t_50 margin_b_40"
                :hover_color=colors.cyanic_blue
                :hover_text_color=colors.white
                :button_state=!isEmpty(hours)
                :button_color="colors.new_orange_main"
                :text_color=colors.white
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="70px"
                button_width="180px"
                border_rad_pct="0"
                text="Register Discount"
                text_size="14px"
                :bold="true"
                event_name="confirm_button_clicked"
                @confirm_button_clicked="register_discount"
                v-on:keyup.enter="register_discount"
            ></basic_text_button>
          </div>


        </div>
        <div class="contact_info_box flex_centered_row">
          <div class="flex_centered_column">
            <call_us_box
                class="margin_lr_10 margin_t_20"
                :click_to_call_mode=mobile_mode
                phone_number="(843) 651-4848"
                @call_clicked=do_nothing
            ></call_us_box>
          </div>

        </div>

      </div>


    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket, isEmpty
} from "@/library";
import {Database} from "@/client_db";
import Ad_model_main from "@/components/Vortex/editing_pages/editing_viewer/ad_model_main.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import call_us_box from "@/components/SpyGlass/call_us_box.vue";
import image_upload_widgit from "@/components/parts/image_upload_widgit.vue";
import {hours_and_minutes_until} from "@/dates_library";
import axios from "axios";

export default {
  name: "ad_model_main",
  async beforeMount() {
    console.log("In before mount")
    console.log(this.founding_year)
    this.db = new Database();
    this.current_photo = await this.load_agency_photo()
    this.ft_stats = await this.get_ft_stats()
    console.log("Here are the pulled FreeTime stats.")
    console.log(this.ft_stats)
  },
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    // this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text", "body_text", "headline_text", "caption_text", "hours", "weighted_avg", "base_rate", "agency_photo", "agency_since"
  ],
  emits: ['name_of_event'],
  components: {
    image_upload_widgit,
    call_us_box,
    basic_text_button,
    Ad_model_main,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      ad_parts: {},
      current_photo: "photo stand in",
      ft_stats: "",
    }
  },
  methods: {
    isEmpty,
    do_nothing() {
      console.log("do nothing")
    },

    async load_agency_photo() {
      //   Attempt to find a photo in the passed down data first
      console.log("Inside load agency photo")

      if (!isEmpty(this.agency_photo)) {
        return this.agency_photo
      } else {
        console.log("No photo image was passed down in teh data.")
      }
      //   If not, attempt to pull a photo from indexed db.
      //   let org = store.getters.org_context
      let search = {
        key: "",
        additional_context: [['div', 'o&a']],
        base_context: store.getters.find_current_preamble
      }

      // console.log(JSON.stringify(search))

      let all_docs = await this.db.db_search(search)
      // console.log("All docs")
      // console.log(all_docs)

      const knownKeys = ['agency_photo'];

      all_docs.forEach(obj => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && knownKeys.includes(key)) {
            console.log("Found a matching doc that should have a photo")
            console.log(obj)
            this.ad_parts[key] = obj.file;
            // console.log(this.ad_parts[key])
            return this.ad_parts[key]
          }
        }
      })
    },

    async get_ft_stats() {
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context
      }

      console.log("Making the call to FreeTime")
      let target_url = be_main_address("ft") + 'admin_get_pricing_stats'
      return await axios.post(target_url, data1)

          .then(
              response => {
                console.log("Admin pricing stats check")
                console.log(response)
                if (response['data']['success']) {
                  this.ad_parts['weighted_avg'] = response['data']['data']['weighted_avg']
                  this.ad_parts['base_rate'] = response['data']['data']['base_rate']
                  this.ad_parts['pricing_week'] = response['data']['data']['week']
                  this.ad_parts['founding_year'] = response['data']['data']['founding_year']
                  console.log("Just got the pricing data....")
                  console.log(this.ad_parts)
                } else {
                  //if there are any problems with network call, do not attempt to update
                  return true
                }
              }
          ).catch(error => {
            console.log("Error on getting pricing stats check.")
            console.log(error)
            return true
          })
    },

    async load_current_ad() {


      console.log("Inside load current ad.")
      let org = store.getters.org_context
      let search = {
        key: "",
        additional_context: [['div', 'o&a']],
        base_context: store.getters.find_current_preamble
      }

      // console.log(JSON.stringify(search))

      let all_docs = await this.db.db_search(search)
      console.log("All docs")
      console.log(all_docs)

      const knownKeys = ['ad_body', 'ad_discount_hours', 'ad_headline', 'ad_photo_caption', 'base_rate', 'weighted_avg'];

      all_docs.forEach(obj => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && knownKeys.includes(key)) {
            this.ad_parts[key] = obj[key];
            break; // Exit the loop if a matching key is found
          }
        }
      });

      console.log("here are the ad parts")
      console.log(this.ad_parts)

    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    register_discount() {
      if (!isEmpty(this.hours)) {
        store.commit('notification_enqueue', "In a real ad, this button opens the discount registration page.")
      } else {
        store.commit('notification_enqueue', "Button will be inoperable if there is no discount offered.")
      }

    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")
      await this.load_current_ad()
    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },

    button_width() {
      return this.button_width_
    },
    current_photo_found() {
      console.log("INside current photo found...")
      // console.log(this.ad_parts['agency_photo'])
      if(this.agency_photo){
        return this.agency_photo
      }else{
        return this.ad_parts['agency_photo']
      }

    },
    weighted_avg_calc() {
      let weighted_avg_ = this.weighted_avg || this.ad_parts['weighted_avg'] || 0
      console.log("Weighted average calced...")
      weighted_avg_ = parseFloat(weighted_avg_)
      console.log(weighted_avg_)
      return weighted_avg_.toFixed(2)
    },
    base_rate_calc() {
      let base_rate_ = this.base_rate || this.ad_parts['base_rate'] || 0
      console.log("base_rate calced...")
      console.log(base_rate_)
      base_rate_ = parseFloat(base_rate_)
      return base_rate_.toFixed(2)
    },
    hours_calc() {
      // let hours_ = this.hours || this.ad_parts['ad_discount_hours'] || 0
      // return parseFloat(hours_)
      if (this.hours === 0) {
        return 0;
      } else if (this.hours === null) {
        let hours_ = this.ad_parts['ad_discount_hours'] || 0;
        return parseFloat(hours_);
      } else {
        let hours_ = this.hours || this.ad_parts['ad_discount_hours'] || 0;
        return parseFloat(hours_);
      }
    },
    discount_amount() {
      // let aa = ${{ (parseFloat(hours || 0) * parseFloat(weighted_avg)).toFixed(2) }}
      let hours_ = this.hours || this.ad_parts['ad_discount_hours'] || 0

      console.log("In discount hours... ")
      console.log(hours_)
      // let float_hours = parseFloat(hours_)
      let res = (this.hours_calc * this.weighted_avg_calc).toFixed(2)
      console.log(res)
      return res
    },
    founding_year(){
     return this.agency_since || this.ad_parts['founding_year']
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {

  .contact_info_box {
    background-color: var(--cyanic-blue-light);
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .outline {
    border: 1px solid var(--black);
    padding: 10px;

  }

  .ad_body_text {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
  }

  .staff_photo {
    width: 99%;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .photo_caption {
    font-size: 15px;
    margin-bottom: 30px;
  }

  .agency_logo {
    width: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .discount_box {
    background-color: var(--lauren_gray);
    padding: 20px;
    min-width: 236px;
    max-width: 98%;
  }

  .rate_box {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    min-width: 95%;
  }

  .rate_text {
    font-size: 22px;
    width: 90%;
  }

  .since {
    min-width: 95%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
  .rate_text {
    font-size: 22px;
    width: 70%;
  }
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>