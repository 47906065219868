<script>
import active_discount_widget from "@/components/Vortex/discount_pages/active_discount_widget.vue";

export default {
  name: "active_main",
  components: {active_discount_widget},
   props: [
    "discount_values"
  ],
  methods: {
    do_nothing() {
    },
  },
}
</script>

<template>
  <active_discount_widget
      :discount_values=discount_values
  ></active_discount_widget>
</template>

<style scoped>

</style>