<template>

  <div class="login_wrapper core_c">
    <section class="white_content flex_centered_start_column height_100p">
      <notification_popup></notification_popup>
      <error_popup_queue></error_popup_queue>
      <outside_banner_std></outside_banner_std>
      <spinner
          :spinner_up="spinner_up"
          @network_problem="close_spinner_wrapper"
      ></spinner>

      <universal_pop_up_slot
          :show_popup="tos_modal"
          @universal_popup_bg_clicked="tos_modal_false"
          :backer_color="colors.white"
      >
        <section class="tos_main flex_centered_column">
          <div class="bg_morgie_gray wid_100vw flex_centered_row logo_backing">
            <img v-if="!show_color_logo" src="@/assets/rabbit_2_line_white.svg" alt="Tiny Magic Hat Logo" class="tmh_logo">
<!--            <img v-if="show_color_logo" src="@/assets/rabbit_2_line.svg" alt="Tiny Magic Hat Logo" class="tmh_logo">-->
            <img v-if="show_color_logo" src="@/assets/rabbit_1_line_edits.svg" alt="Tiny Magic Hat Logo" class="tmh_logo">
          </div>
<!--          <h1>Tiny Magic Hat <br> Terms of Service</h1>-->
          <h1>Terms of Service</h1>
          <p>Tiny Magic Hat has updated its Terms of Service.</p>
          <p>You will need to agree to the updated Terms of Service before logging in.</p>
          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_tos_agree">
              <div v-if="!tos_agree" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="tos_agree">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer"></div>
            <p v-if="tos_agree" class="opt_in_text_yes">I agree to Tiny Magic Hat's updated
              <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple">Terms of Service</span>.
            </p>
            <p v-if="!tos_agree" class="opt_in_text_no">I agree to Tiny Magic Hat's updated
              <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple font_weight_600">Terms of Service</span>.
            </p>

          </div>
          <div class="spacer"></div>
          <basic_text_button
              class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state=tos_agree
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="LOGIN"
              text_size="14px"
              :bold="true"
              event_name="login_button_clicked"
              @login_button_clicked="log_in"
              tabindex="0"
              v-on:keyup.enter="log_in"
          ></basic_text_button>
        </section>
      </universal_pop_up_slot>


      <div class="flex_centered_row">
        <div class="flex_fully_centered_column margin_t_40 main_column">
          <h4 class="margin_b_20 login">Login</h4>

          <input_with_label
              ref="login_name_box"
              class="login_input"
              label="Your User Name"
              @emit_state="catch_username"
          ></input_with_label>

          <input_with_label
              ref="login_password_box"
              class="login_input"
              label="Your Password"
              type="password"
              @emit_state="catch_password"
              @enter_pressed="log_in"
          ></input_with_label>


          <basic_text_button
              class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.new_orange_main"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="150px"
              border_rad_pct="0"
              text="LOGIN"
              text_size="14px"
              :bold="true"
              event_name="login_button_clicked"
              @login_button_clicked="log_in"
              tabindex="0"
              v-on:keyup.enter="log_in"
          ></basic_text_button>

        </div>
      </div>

      <div class="flex_space_between_row  " id="links_row">
        <!--        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("register")'>Sign Up</div>-->
        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("forgot_pw")'>Reset Password</div>
        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_login")'>Secure Login</div>
      </div>

    </section>
  </div>
  <outside_footer class="footer_c" id="footer">
  </outside_footer>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import {Database} from '@/client_db';
import {
  make_hash_dict,
  parse_internal_json,
  be_main_address, colors, close_spinner_slow_connection,
  add_class_to_body,
  route_and_log, scroll_to_top,
  reentry_route, good_token_now, detect_connection_speed,
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import label_with_input_text from "@/components/parts/label_with_input_text";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import input_with_label from "@/components/parts/input_with_label";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
// import {get_uuid} from "@/library"
import spinner from "@/components/parts/spinner";

export default {
  components: {
    basic_text_button,
    label_with_input_text,
    error_popup_queue,
    notification_popup,
    basic_icon_button,
    basic_slide_over_menu,
    outside_banner_std,
    outside_footer,
    input_with_label,
    spinner,
    universal_pop_up_slot
  },
  data() {
    return {
      colors: colors,
      name: '',
      password: '',
      next_route: '',
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 700,
      show_tos_modal_: false,
      tos_agree_: false,
      show_color_logo_: true,
      db: null,
    }
  },
  async mounted() {
    scroll_to_top()
    detect_connection_speed()
    this.run_window_width_adjustments(this.windowWidth)
  },
  created() {
    add_class_to_body('bg_white')
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  methods: {
    tester() {
      // console.log("button was pressed.")
    },
    toggle_tos_agree() {
      this.tos_agree_ = !this.tos_agree_
    },
    tos_agree_true() {
      this.tos_agree_ = true
    },
    tos_agree_false() {
      this.tos_agree_ = false
    },

    tos_modal_true() {
      this.show_tos_modal_ = true
    },
    tos_modal_false() {
      this.show_tos_modal_ = false
    },
    close_spinner_wrapper() {
      close_spinner_slow_connection()
    },
    // close_spinner_slow_connection(){
    //   let slow_msg = "The network connection is really slow or Tiny Magic Hat may be down."
    //   store.commit('set_spinner_state', false)
    //   store.commit('notification_enqueue', slow_msg)
    // },
    //
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },

    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },

    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 369, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(370, 399, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(400, 800, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(501, 1499, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(1500, 6000, new_w)) {
        this.show_color_logo_ = true
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    catch_password(pw) {
      this.password = pw.trim()
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
    },
    simple_route_prep(r, analytic_log = null) {
      // console.log("in simple route prep")
      this.hamburger_menu_false()
      this.tos_modal_false()
      store.commit('set_spinner_state', false)
      this.tos_agree_false()
      route_and_log(this.$router, r, analytic_log)
    },
    async log_in() {
      this.tos_modal_false()
      store.commit('set_spinner_state', true)
      let the_router = this.$router
      let fhd = await make_hash_dict(this.db)
      let the_res

      this.$refs.login_name_box.emit_state()
      this.$refs.login_password_box.emit_state()

      let error_out
      if (this.name.length === 0) {
        store.commit('error_enqueue', "You must provide a user name to login.")
        error_out = true
      }
      if (this.password.length === 0) {
        store.commit('error_enqueue', "You must provide a password to login.")
        error_out = true
      }
      if (error_out === true) {
        store.commit('set_spinner_state', false)
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "log_in"
      // console.log("Log in address")
      // console.log(target)
      await axios.post(target, {
        name: this.name,
        password: this.password,
        flat_hash_assets: fhd,
        agree_new_terms: this.tos_agree,
      }).catch(function (error) {
        store.commit('set_spinner_state', false)
        this.tos_agree_false()
        if (error.response) {
          store.commit('error_enqueue', "Bad login. Check name and password.")
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          let message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          store.commit('error_enqueue', message)
          error_out = true
          // return null
        }
      }).then((response) => {
        console.log(response)
        this.tos_agree_false()
        if (response.data.error === false) {
          console.log("the_data_only.error must be FALSE")
          the_res = response
        } else {
          console.log("the_data_only.error must be TRUE")
          if (response.data.error === true) {
            console.log(response.data.data)
            if (response.data.data === 'throw_terms_modal') {
              //  This user's TOS agreement in out of date. We need to re-up
              store.commit('set_spinner_state', false)
              console.log("Need to show the agree to tos modal")
              this.tos_modal_true()
              error_out = true
              return null

            } else {
              console.log("the_data_only.error must be TRUE")
              store.commit('error_enqueue', response.data.message)
              error_out = true
              store.commit('set_spinner_state', false)
              return null
            }


          } else {
            // console.log("the_data_only.error must be false")
            store.commit('set_spinner_state', false)
          }
        }

      }).catch(function (error) {
        this.tos_agree_false()
        store.commit('set_spinner_state', false)
        //pass


      })

      if (error_out === true) {
        store.commit('set_spinner_state', false)
        return null
      }

      store.commit("clear_route_history")
      await this.process_asset_package(the_res.data)
      const preamble = ['people', this.name]
      this.next_route = `/${this.name}/profile`
      await store.dispatch('stack_proposed', preamble)
      store.commit('set_spinner_state', false)
      await the_router.push({path: this.next_route})
      store.commit('save_name', this.name)
      this.name = ''
      this.password = ''
    },

    async process_asset_package(asset_package) {

      //save jwt authentication token in vuex
      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])
      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      // console.log("done with processing the asset package")
    },
  },
  computed: {
    show_color_logo() {
      return this.show_color_logo_
    },
    tos_modal() {
      return this.show_tos_modal_
    },
    tos_agree() {
      return this.tos_agree_
    },
    reentry_route() {
      return reentry_route()
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>
@import '../../common.css';


.main_column {
  max-width: 75%;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .tmh_logo {
    width: 85px;
  }

  .logo_backing {
    width: 100vw;
    background-color: var(--eli_gray);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 225px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }


  .main_column {
    max-width: 75%;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);

  }

  .spacer {
    width: 10px;
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 200px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }

}


@media (min-width: 400px) {

}

@media (min-width: 500px) {

  .main_column {
    max-width: 75%;
  }

}

@media (min-width: 770px) {

  .main_column {
    max-width: 70%;
  }

}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }
}

@media (min-width: 1200px) {
  .login_wrapper {
    height: 913px;
  }

  .spacer {
    width: 30px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;

  }

  .opt_in_row {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 500px;
  }

  .opt_in_text_yes {
    width: 350px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 350px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }
}


@media (min-width: 1500px) {
    .tmh_logo {
    width: 350px;
  }

  .logo_backing {
    width: 100vw;
    background-color: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 350px;
  }

}

@media (min-width: 2000px) {

  .login_wrapper {
    height: 1200px;
  }

  /*.login_input {*/
  /*  width: 85vw;*/
  /*  max-width: 600px;*/
  /*}*/
  .main_column {
    max-width: 65%;
  }


  .tmh_logo {
    width: 350px;
  }

  .logo_backing {
    width: 100vw;
    background-color: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 350px;
  }


}

@media (min-width: 2500px) {

  .login_wrapper {
    height: 1200px;
  }

  /*.login_input {*/
  /*  width: 85vw;*/
  /*  max-width: 600px;*/
  /*}*/
  .main_column {
    max-width: 65%;
  }


  .tmh_logo {
    width: 400px;
  }

  .logo_backing {
    width: 100vw;
    background-color: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 350px;
  }


}
</style>