<template>

  <section class="">
    <input :type=input_type :id=id_num v-model="value_" :placeholder=value @keyup.enter="enter_emit"  :maxlength=max_characters
           @change="onChange($event)">
  </section>
</template>

<script>
import {get_uuid} from "@/library";

export default {
  name: "input_with_label",
  props: [
    "label",
    "start_value",
    "focus",
    "type",
    "max_char",
  ],

  mounted() {
    // console.log("mounting input with label")
    // console.log(`Start value is: ${this.start_value}`)

    this.id_ = get_uuid()
    if (this.start_value) {
      this.value_ = this.start_value
    }
    if (this.type) {
      this.type_ = this.type
    }


  },
  async updated() {
    try {
      await this.$nextTick(function () {

        if (this.focus) {
          let i = document.getElementById(this.id_)
          i.focus()
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  data() {
    return {
      label_: "",
      value_: "",
      seed_: "",
      type_: "text",
      id_: ""

    }
  },
  methods: {
    onChange(e) {
      console.log(`in drop down on change of input box value of  ${e.target.value}`)
      this.$emit('emit_state', e.target.value.toString())
    },
    emit_state() {
      // console.log(`Emitting ${this.value_}`)
       console.log("Inside input with label emit_state")
      console.log(`The value is.....${this.value_.toString()}`)
      this.$emit('emit_state', this.value_.toString())
    },

    enter_emit() {
      // console.log(`Emitting ${this.value_}`)
      this.$emit('enter_pressed', true)
    },
    reset_state() {
      console.log("Resetting the state in input with label.")
      this.value_ = ""
    },
    get_value() {
      // console.log("Inside input with label get value")
      // console.log(`The value is.....${this.value_.toString()}`)
      return this.value_.toString().trim()
    },
  },
  computed: {
    value() {
      if (this.value_ !== "") {
        return this.value_
      } else if (this.seed_ !== "") {
        return this.seed_
      } else {
        return this.label
      }
    },
    input_type() {
      return this.type_
    },
    id_num() {
      return this.id_
    },
    max_characters(){
      if(this.max_char){
        return this.max_char
      }else{
        return "100"
      }
    },

  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  input {
    margin-bottom: 10px;
    width: calc(100% - 4px);
    height: 30px;
    padding: 26px;
    box-sizing: border-box;
    border: 1px solid var(--morgie_gray) !important;
  }


  input:focus {
    border: 1px solid var(--primary-purple) !important;
    box-shadow: 0 0 3px var(--primary-purple) !important;
    -moz-box-shadow: 0 0 3px var(--primary-purple) !important;
    -webkit-box-shadow: 0 0 3px var(--primary-purple) !important;
    outline-offset: 0 !important;
    outline: none !important;
  }

}


</style>