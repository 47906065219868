<template>
  <section class="core_c" >
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>

    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column">

        <h4 class="margin_b_20 login text_align_center">Verify TOTP Setup</h4>
        <input_with_label
            ref="mfa_verification_code_box"
            class="login_input"
            label="Enter 6 digit code"
            @emit_state="catch_mfa_verification_code"
        ></input_with_label>


        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="VERIFY"
            text_size="14px"
            :bold="true"
            event_name="verify_button_clicked"
            @verify_button_clicked="verify_totp"
            v-on:keyup.enter="verify_totp"
            tabindex="0"
        ></basic_text_button>

      </div>
    </div>
  </section>
  <outside_footer class="footer_c">
  </outside_footer>

</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {
  be_main_address,
  validate_confirmation_code,

} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";
import {
  colors
} from "@/library";

export default {
  components: {
    notification_popup,
    basic_text_button,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label

  },
  data() {
    return {
      colors: colors,
      name: store.getters.my_name,
      password: store.getters.my_password,
      code: '',
      next_route: '',
      qr_str: "",
      show_qr_code_backer_x: false,
      mobile_mode: true
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  methods: {
    tester() {
      console.log("QR CODE WAS CLICKED.")
    },
    catch_mfa_verification_code(verification_totp) {
      let totp_code = verification_totp.trim()
      // validate
      if (validate_confirmation_code(totp_code)) {
        this.code = totp_code
      } else {
        let m = "The verification code you entered is not valid. The proper format is 123456."
        store.commit('error_enqueue', m)
      }
    },
    simple_route_prep(r) {
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async verify_totp() {
      console.log("Getting ready to verify new totp code...")
      // console.log(`Current password from state is: ${this.password}`)

      // let the_router = this.$router

      this.$refs.mfa_verification_code_box.emit_state()

      // let error_out
      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "mfa_verify_totp"
      await axios.post(target, {
        name: this.name,
        totp: this.code,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad verification. Try again.")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // error_out = true
          return null
        }
      }).then((response) => {
            // console.log("Got back verification response of")
            // console.log(response.data)

            if (response.data.error === false) {
              console.log("Got good response to mfa verification.");
              store.commit('notification_enqueue', response.data['message'])
              this.$router.push('/mfa_login');
              // this.$store.commit('save_name', this.name)
              // this.$store.commit('save_email', this.email)
              // this.$store.commit('save_phone', this.phone_number)
              // this.name = ''
              // this.phone_number = ''
              // this.password = ''
              // this.$router.push('/mfa_register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },


  },
  computed: {
    show_qr_code_backer() {
      return this.show_qr_code_backer_x
    },
  }
}
</script>

<style scoped>
@import '../../common.css';




.main_column {
  max-width: 200px;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}

.hat_logo {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  max-width: 700px;
  margin: 0;
}

.hat_size {
  width: 65vw;
  padding-top: 5vh;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}

form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}

@media (min-width: 100px) {
 .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  .hat_size {
    width: 182px;
    margin-top: 20px;
  }



}

@media (min-width: 500px) {
  .links {
    color: var(--black);
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 18px;
    margin-top: 4vh;
    margin-bottom: 3vh;
    text-decoration: none;
    align-self: flex-start;
    cursor: pointer;
  }

  .hat_size {
    width: 182px;
    margin-top: 20px;

  }


}

@media (min-width: 770px) {


  .hat_size {
    width: 182px;
    margin-top: 150px;

  }


}

@media (min-width: 770px) {


  .hat_size {
    width: 182px;
    margin-top: 150px;

  }





}


</style>