<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <headers
        title="Home Care and Caregiving: Things You Should Know before you hire a caregiver"
        meta_description="Things you should know about the home care industry and how to better understand the person you would like to hire."
        og_title="Things You Should Know before you hire a caregiver."
        og_url="https://tinymagichat.com/blog/home-care-and-caregiving-things-you-should-know"
        og_description="Things you should know about the home care industry and how to better understand the person you would like to hire."
        og_image=""
        twitter_title="Things You Should Know before you hire a caregiver."
        twitter_description="Things you should know about the home care industry and how to better understand the person you would like to hire."
        twitter_image=""
        twitter_card=""
    ></headers>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <section class="flex_centered_row margin_b_40">
      <div class="flex_centered_start_column">
        <div class="top_section  ">
          <div class="top_section_content ">

            <div class="flex_centered_column right_side">
              <h1 class="text_align_center">Home Care and Caregiving: <br> Things You Should Know Before You Hire a
                Caregiver</h1>

            </div>

            <div v-if="!mobile_mode" class="flex_centered_column">
              <figure>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/istock/consultation-min.jpg"
                     alt="Elderly Asian man sitting on couch smiling at young woman with brown hair and glass whose back is turned to the camera. She is writing notes with a pen on a clipboard collecting information so that she can help provide him with the best elder care services he can use at home.">

              </figure>
            </div>


          </div>
        </div>


        <!--        <basic_text_button-->
        <!--            class="margin_t_40"-->
        <!--            :button_state="true"-->
        <!--            :button_color="colors.primary_purple"-->
        <!--            :text_color="colors.white"-->
        <!--            :button_color_not_ready="colors.gray"-->
        <!--            :button_not_ready_text_color="colors.morgie_gray"-->
        <!--            :hover_color="colors.cyanic_blue"-->
        <!--            :hover_text_color="colors.white"-->
        <!--            button_height="52px"-->
        <!--            button_width="150px"-->
        <!--            border_rad_pct="0"-->
        <!--            text="SUBMIT"-->
        <!--            button_not_ready_text="Retire Ad"-->
        <!--            text_size="14px"-->
        <!--            :bold="true"-->
        <!--            event_name="save_job_request"-->
        <!--            @save_job_request="submit_prompt"-->
        <!--        ></basic_text_button>-->

      </div>


    </section>
    <article class="flex_centered_row">
      <section class="main_article_body flex_start_column">
        <h3 id="article_title">The Advantage of Home Care Services</h3>


<!--        <a class="fb-xfbml-parse-ignore" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://tinymagichat.com/blog/home-care-and-caregiving-things-you-should-know">Facebook</a>-->
        <p id="p1">If you’re like most people, you want to stay right where you are as you age, in the community you
          already
          know, close to your family and friends and familiar conveniences.</p>
        <br>
        <p id="p2">According to a
          <a href="https://www.aarp.org/research/topics/community/info-2018/2018-home-community-preference.html">2018
            survey commissioned by the AARP</a>,
          nearly 80% of adults aged 50 or older want to <a href="https://www.seniorliving.org/aging-in-place/">‘age in
            place’</a>.
          A later <a
              hre="https://www.aarp.org/research/topics/community/info-2021/2021-home-community-preferences.html">study
            from 2021</a>
          came to the same finding.</p>
        <br>
        <p id="p3">Making it easier, or in some cases, feasible, for people to age in place is one of the primary
          benefits of
          home care services.</p>
        <br>
        <p id="p4">Often, simple things we’ve always taken for granted become more difficult as we age.
          Climbing stairs, stepping in and out of the bathtub, opening jars or even taking off your shoes can begin to
          present challenges that previously didn't exist.</p>
        <br>

        <figure class="flex_centered_column">
          <img class="padding_tb_20"
               src="@/assets/istock/jar-min.jpg"
               alt="Elderly woman standing in her kitchen struggling to open a jar. Assistance from a personal care aide would be a lot of help.">
          <figcaption>Even simple tasks aren't always that simple.</figcaption>
        </figure>

        <br>
        <p id="p5">Having a caregiver in the home can help smooth some of these inevitable bumps. </p>
        <br>
        <p id="p6">But exactly what type of services can a private caregiver provide? While every individual needing
          non-medical
          elder care is unique, there are two basic service categories:</p>
        <ul>
          <li>
            <p><strong>Companion Care or Homemaker Services</strong> - This type of care is for those who are for the
              most part healthy, but could use a bit of help with things like meal preparation and light housekeeping,
              laundry and dishes, errands and appointments or who are socially isolated and need some regular human
              interaction.</p>
          </li>
          <br>
          <li>
            <p>
              <strong>Personal Care</strong> - This category includes things like help with bathing, dressing,
              toileting and ambulation. Individuals providing this type of care are sometimes referred to as home
              health aides.</p>
          </li>
        </ul>
        <br>
        <p id="p7">Depending on your schedule and requirements, you may only need a few hours of care per week, while
          there
          are others who need 24 hour home care.</p>
        <br>
        <p id="p8">But when you are considering your needs, you would be wise to consider
          the situation from the perspective of the caregiver whom you would hire. </p>
        <br>
        <h3>Home Care: The View from the Other Side</h3>
        <p id="p9">The type of person drawn to work as a caregiver is typically kind and empathetic and truly cares
          about the
          well being their client.
        </p>
        <br>
        <p id="p10">Most caregivers work by the hour, and most home care clients need less that 40
          hours of service per week. Often, caregivers must work with multiple clients to maintain enough hours to
          meet their own financial obligations. </p>
        <br>
        <p id="p11">Caregivers may also have a lengthy commute to and from the client’s
          home and time spent driving back and forth to work is typically uncompensated.</p>
        <br>
        <figure class="flex_centered_column">
          <img class="padding_tb_20"
               src="@/assets/istock/drive-min.jpg"
               alt="A part-time caregiver driving to the home of a client needing elder care.">
          <figcaption>Caregivers can spend a lot of time driving to and from your home.</figcaption>
        </figure>

        <br>
        <p id="p12">Currently, there is a <a
            href="https://www.aarp.org/caregiving/basics/info-2022/in-home-caregiver-shortage.html">severe shortage of
          caregivers</a>
          nationally while the demand for caregiving services
          <a href="https://homehealthcarenews.com/2020/01/demand-for-home-caregiver-jobs-continues-to-surge/">continues
            to increase</a>.
          As a result, the price of hiring a caregiver has tended to rise.
        </p>
        <br>
        <p id="p13">The <a href="https://www.genworth.com/aging-and-you/finances/cost-of-care.html">2021 Cost of Care
          Survey from
          Genworth Financial</a>
          indicates that the year-over-year national prices of
          Personal Care and Companion Care increased by 12.5% and 10.64% respectively.</p>
        <br>
        <p id="p14">The high demand for home care service coupled with the <a
            href="https://www.asbestos.com/support/caregivers/shortage-by-state/">caregiver shortage</a>
          means agencies experience more difficulty staffing cases, and naturally, caregivers are more selective about
          which clients they are willing to accept.
        </p>
        <br>
        <figure class="flex_centered_column">
          <img class="padding_tb_20"
               src="@/assets/istock/cg_pills-min.jpg"
               alt="A caregiver helping an elderly client with companion services at home.">
          <figcaption>A caregiver assisting with medication reminders.</figcaption>
        </figure>

        <br>
        <p id="p15">For this reason, many home care agencies institute minimum hourly requirements
          (typically 3 to 4 hours) as the opportunity cost and out-of-pocket costs to caregivers taking on cases of
          shorter durations is too high.</p>
        <br>
        <p id="p16">
          As a practical matter, you may find it difficult to hire a caregiver if, for example, you only need someone
          to arrive in the morning for a couple of hours to help you get out of bed, make breakfast and clean up the
          kitchen. In such cases you may have to lean on family and friends to offer you a hand.
        </p>
        <h3>The Cost of Home Care Services</h3>
        <p id="p17">So what does home care cost today? Like most things, costs vary depending on the market.</p>
        <br>
        <p id="p18"> According to the <a href="https://www.genworth.com/aging-and-you/finances/cost-of-care.html">Genworth
          study</a>
          cited above, the median hourly rate for home care service nationally in 2021
          was $26.50 per hour. Some more expensive markets exceed $32 per hour even in 2021 and costs have continued to
          increase.
        </p>
        <br>
        <p id="p19">Unfortunately, non-medical home care, unlike <a
            href="https://www.medicare.gov/coverage/home-health-services">skilled home health care</a>,
          is not covered by Medicare Part A or B, although some home care agencies will accept
          Medicaid clients. Medicaid eligibility <a
              href="https://www.medicaidplanningassistance.org/state-specific-medicaid-eligibility/">varies by state</a>.
          There is also a veteran’s benefit for <a
              href="https://www.va.gov/geriatrics/pages/Homemaker_and_Home_Health_Aide_Care.asp">Homemaker and Home
            Health Aide Care</a>
          that covers home care services and some individuals may have
          <a href="https://www.aarp.org/caregiving/financial-legal/info-2021/understanding-long-term-care-insurance.html">long-term
            care insurance</a> or coverage under
          <a href="https://www.medicare.gov/sign-upchange-plans/types-of-medicare-health-plans/medicare-advantage-plans/how-to-join-a-medicare-advantage-plan">Medicare
            Advantage (Part C)</a>,
          which generally covers the service as well.</p>
        <br>
        <p id="p20">
          Home care services can provide individuals with the assistance they need to age in place and while not
          inexpensive, are frequently cheaper than some alternatives like assisted living or nursing homes.

        </p>
        <br>
        <p id="p21">Managing costs and weighing alternatives is something you will have to carefully consider.</p>
        <br>
        <p id="p22">
          If you would like more information, please reach out to us through our <a
            href="https://tinymagichat.com/contact">contact</a> page or the
          <a href="https://tinymagichat.com/help">help chat</a>.
        </p>


      </section>

    </article>

    <section class="flex_centered_row bg_lauren_gray padding_tb_30 back_to_cg_search_wrapper">
      <div class="flex_centered_column what_does_it_cost">
        <h5 class="text_align_center">Find a Caregiver Near You</h5>


        <basic_text_button
            class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
            :hover_color=colors.primary_purple
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.darker_gray
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="190px"
            border_rad_pct="0"
            text="Search"
            text_size="17px"
            :bold="true"
            event_name="go_to_jobs_clicked"
            @go_to_jobs_clicked="go_to_find_caregiver_page"
        ></basic_text_button>

      </div>

    </section>
  </div>

  <outside_footer class="footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import local_pda_featured from "@/components/orgs/pda/local_pda_featured";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import spinner from "@/components/parts/spinner";
import headers from "@/components/parts/headers";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  scroll_to_top,
  validate_phonenumber,
  dual_route,
  validate_email,
  be_main_address,
  scroll_to_element,
  get_ip_from_header,
  isEqual,
  num_is_between_inclusive,
  play_sound,
  analytic,
  detect_connection_speed
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "what_you_need_to_know",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    // console.log("Current directory:", __dirname);
    // store.commit('notification_enqueue', `This is a test.`)
    this.id_ = get_uuid()
    this.scroll_to_top()

    if (!store.getters.universal_user_session_id) {
      store.commit('universal_user_session_id', get_uuid())
    }

    const options = {
      root: null, // it is the viewport
      threshold: .2, //0 to 1 scale, 0 default
      // rootMargin: ""

    }
    const sections = document.querySelectorAll('p')
    let observer = new IntersectionObserver(entries => {
      // console.log(entries);
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        if (entry.target.id.length > 0) {
          // console.log(`This sections id is ${entry.target.id}`)
          analytic({
            'event': 'Scroll Landmark',
            'message': `User scrolled to section id: ${entry.target.id} on ${localStorage.getItem('dev_latest_route')}`,
            'uuid': store.getters.universal_user_session_id
          })
        }
        observer.unobserve(entry.target)
      })
    }, options);
    sections.forEach(section => {
      observer.observe(section);
    })

    this.run_window_width_adjustments(this.windowWidth)

    await this.log_time()
    this.repeat_on_interval()

    let ip_string = false
    ip_string = await get_ip_from_header()
    // localStorage.setItem('chat_id_cg', get_uuid())

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
    await detect_connection_speed()
  },
  unmounted() {
    console.log("running unmounted")

    this.clearAlert()

    // window.removeEventListener('popstate')
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    local_pda_featured,
    dropdown_datalist,
    input_with_label_text_area,
    spinner,
    headers

  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      chat_messages_: [],
      // spinner_up_ = false,
      time_log_interval: 3000,
      intervalID: "",
      counter: -1,
      last_prompt: "",
      max_msg_len: 500,
      name: '',
      password: '',
      email: '',
      job_request: {
        type: "",
        neighborhood: "",
        days: "",
        times: "",
        details: "",
        email: ""
      },
      columns_num_: 15,
      rows_num_: 5,
      job_email_request: "",
      approved_agencies_: [],
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 700,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },

    go_to_find_caregiver_page() {
      // this.hamburger_menu_false()
      this.simple_route_prep('find-a-caregiver')
    },

    go_to_contacts_page(msg = true) {
      // this.hamburger_menu_false()
      this.simple_route_prep('contact')
    },
    clearAlert() {
      console.log("Turning off the timer.")
      clearTimeout(this.intervalID);
    },
    repeat_on_interval() {
      this.intervalID = setInterval(this.log_time, 3000);
    },
    async log_time() {
      this.counter += 1
      let seconds = this.time_log_interval / 1000
      let duration = seconds * this.counter
      if (this.counter <= 40) {
        analytic({
          'event': 'what you need to know log time',
          'message': `What you need to know blog visit duration seconds: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      } else if (this.counter === 41) {
        analytic({
          'event': 'What you need to know log time',
          'message': `Last log for this user and uuid: ${duration}`,
          'uuid': store.getters.universal_user_session_id
        })
      }

    },
    add_to_chat_user(msg) {
      this.chat_messages_.push(["You", msg])
    },
    add_to_chat_bot(msg) {
      this.chat_messages_.push(["Shiloh", msg])
    },

    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },


    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log(`Running window adjustments on ${new_w}`)
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
        console.log("Mobile Mode FALSE")
      } else {
        this.mobile_mode_x = true
        console.log("Mobile Mode TRUE")

      }
      if (num_is_between_inclusive(100, 679, new_w)) {
        console.log("inside the 100 to 679 set")

        // this.columns_num_ = 15
        // this.rows_num_ = 5
      }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        // console.log("setting to large")
        // this.columns_num_ = 60
        // this.rows_num_ = 10
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

    catch_job_type(name) {
      this.job_request.type = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },

    simple_route_prep(r) {
      // this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },


  },
  computed: {

    spinner_up() {
      return store.getters.get_spinner_state
    },
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },

    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      console.log("INSIDE MOBILE MODE COMPUTED PROPERTY")
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../common.css';


@media (min-width: 100px) {
  figure {
    max-width: 100vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  #article_title{
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .icons_row{
    width: 200px;
    margin-bottom: 30px;
  }

  .social_icon{
    width: 30px;
  }

  figcaption {
    font-size: 15px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  img {
    max-width: 100vw;
  }


  header {
    grid-area: top;
    display: flex;
    justify-content: center;
  }

  #cg_topper_img {
    width: 98vw;
  }

  .footer_c {
    /*margin-top: 40px;*/
  }

  .right_side h1 {
    color: white;
    font-size: 30px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 98vw;
  }

  .what_does_it_cost h3 {
    padding-top: 20px;
  }

  .what_does_it_cost p {
    padding-bottom: 30px;
  }

  .main_article_body {
    max-width: 1200px;
    width: 90%;
  }


  h3 {
    font-size: 27px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }



  h5 {
    font-size: 20px;
  }


  p {
    font-size: 20px;
    line-height: 2.2rem;
  }

  a {
    font-size: 20px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100vw;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .back_to_cg_search_wrapper {
    margin-top: 40px;
  }



}


@media (min-width: 360px) {

  figcaption {
    font-size: 17px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

}

@media (min-width: 400px) {

  .right_side h1 {
    color: white;
    font-size: 35px;
    margin-left: 15px;
    margin-right: 15px;
  }

  h3 {
    font-size: 30px;
  }

  p {
    line-height: 1.7rem;
  }
}

@media (min-width: 500px) {
  h3 {
    font-size: 32px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  figcaption {
    font-size: 19px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1.8rem;
  }

  a {
    font-size: 25px;
  }

}


@media (min-width: 700px) {

   #article_title{
    margin-bottom: 30px;
  }

  #cg_topper_img {
    width: 500px;
  }

  h3 {
    font-size: 38px;
  }

  .right_side h1 {
    color: white;
    font-size: 50px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 683px;
  }

  .footer_c {
    /*margin-top: 60px;*/
  }
}


@media (min-width: 750px) {

}

@media (min-width: 1000px) {

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .top_section h1 {
    max-width: 500px;
  }

  .second_card h3 {
    max-width: 250px;
  }

  #cg_topper_img {
    width: 450px;
  }

  h5 {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {


   #article_title{
    margin-bottom: 50px;
  }

  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h1 {
    color: white;
    font-size: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }


  .right_side h3 {
    width: 650px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 550px;
    margin-right: 10px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .top_section h1 {
    max-width: 720px;
  }
}

@media (min-width: 2000px) {

  a {
    font-size: 25px;
  }

  figcaption {
    font-size: 22px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1600px;
  }

  #cg_topper_img {
    width: 800px;
  }

  .right_side h1 {
    color: white;
    font-size: 60px;
    margin-left: 15px;
    margin-right: 15px;
  }

  h3 {
    font-size: 46px;
  }

  h5 {
    font-size: 30px;
  }

  .back_to_cg_search_wrapper {
    margin-top: 100px;
  }

  p {
    font-size: 28px;
  }

}

</style>