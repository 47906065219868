<template>
  <module_main_page
      module_download_name="5dccdd76-fb38-42bb-8fe5-a5f33c159551"
      module_logo_name="sd_hoarding.svg"
      :module_desc_text=module_text
      module_headline="Understanding Hoarding Disorders"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "hoarding",
  data() {
    return {
      module_text: "Hoarding disorder is a mental health condition characterized by the persistent difficulty parting with possessions, leading to an excessive accumulation of items that often clutter living spaces to the point of distress or impairment. Caregivers need to be aware of hoarding disorder as it can significantly impact the health and safety of the individual they are caring for. It's essential for caregivers to recognize the signs and symptoms of hoarding and prioritize the client's safety by ensuring that pathways are clear, and there are no fire hazards or unsanitary conditions. Caregivers should also approach the situation with empathy and sensitivity, as hoarding can be linked to underlying emotional issues, such as anxiety or depression.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



