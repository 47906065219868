<template>
  <div class="core_c">

    <section class="flex_centered_row">
      <input type="file" style="display: none" ref="image_to_upload"
             @change="onImageFileChosen($event)">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
          :hover_color=colors.primary_purple
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.new_cyanic_mid"
          :text_color=colors.darker_gray
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="190px"
          border_rad_pct="0"
          :text=button_text
          text_size="17px"
          :bold="true"
          event_name="open_file_selector"
          @open_file_selector="open_file_selector"
      ></basic_text_button>
    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";
import basic_text_button from "@/components/parts/basic_text_button.vue";

export default {
  name: "photo_upload_widget",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
  ],
  emits: ['photo_update'],
  components: {
    basic_text_button,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      the_file: "",
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_file_selector(){
      console.log("you clicked it")
      // this.$refs.image_to_upload.value = ''
      this.$refs.image_to_upload.click()
      // if(this.owners_perm){
      //   this.$refs.image_to_upload.click()
      // }else{
      //   console.log("no owners perm")
      //   return null
      // }
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state(image_str) {
      this.$emit("photo_update", image_str)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },

    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },
     resizeImage(base64Str) {
      console.log("starting resizeImage")
      try {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = this.image_width; // target width
        canvas.height = this.image_height; // target height

        // canvas.width = 260; // target width
        // canvas.height = 145; // target height
        let image = new Image();
        // document.getElementById("original_image").appendChild(image);
        image.onload = function () {
          ctx.drawImage(image,
              0, 0, image.width, image.height,
              0, 0, canvas.width, canvas.height
          );
          // create a new base64 encoding
          // let resampledImage = new Image();
          let resampledImage = document.getElementById("resizedImage")
          // let org = store.getters.org_context
          // console.log(`The org is ${org}`)
          resampledImage.src = canvas.toDataURL();
          // store.commit('set_agency_logo', {'agency': org, 'image': resampledImage.src})
          // console.log("Here is the new image.")
          // console.log(resampledImage.src)
          this.image_saved_ = true
        }
        console.log("gettintg ready to load the image")
        image.src = base64Str
        // console.log("resizeImage is done")
        // console.log(image.src)
        // return
      } catch (error) {
        // console.log("in the error catch")
        // console.log(error)
      }
    },
    fileToDataUri(field) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(field);
      });
    },
    async onImageFileChosen(event) {
      // console.log("in onImageFileChosen")
      const files = event.target.files
      this.the_file = files[0]
      let img_file_name = this.the_file['name']
      const myArray = img_file_name.split(".");
      let file_ext = myArray[1];
      let exts = ["jpeg", "gif", "png", "svg", "bmp", "jpg"]
      if (exts.includes(file_ext)) {
        // console.log("ok file")
      } else {
        let m = `Image file must be of one of the following types:
        "jpeg", "gif", "png", "svg", "bmp", "jpg". Your file was a "${file_ext}".`
        store.commit('error_enqueue', m)
        // console.log("NOT OK")
        return null
      }
      // console.log("start fileToDataUri")
      // let orig_src = await this.fileToDataUri(this.the_file)
      this.fileToDataUri(this.the_file).then(orig_src => {
        // console.log("orig scr from filetoDataurs is")
        // console.log(orig_src)
        // console.log("start resizeImage.  ")
        // this.resizeImage(orig_src)
        // console.log("Back from resizeImage")
        this.$refs.image_to_upload.value = ''
        // console.log("about to call emit state")
        this.emit_state(orig_src)
      })
      // console.log("orig scr from filetoDataurs is")
      // console.log(orig_src)
      // console.log("start resizeImage.  ")
      // this.resizeImage(orig_src)
      //  console.log("Back from resizeImage")
      // this.$refs.image_to_upload.value = ''
      //
      // console.log("about to call emit state")
      // this.emit_state()
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>