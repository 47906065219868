<template>
  <section class="core_c" >
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>

    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column">
        <h4 class="margin_b_20 login text_align_center">Confirm Secure <br> Sign Up</h4>
        <input_with_label
            ref="mfa_confirmation_code_box"
            class="login_input"
            label="Enter 6 digit code"
            @emit_state="catch_mfa_confirmation_code"
        ></input_with_label>

         <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="CONFIRM"
            text_size="14px"
            :bold="true"
            event_name="confirm_button_clicked"
            @confirm_button_clicked="confirm"
            v-on:keyup.enter="confirm"
            tabindex="0"
        ></basic_text_button>


      </div>
    </div>

    <universal_invisible_backer
        :show_backer="show_qr_code_backer"
        :backer_color="colors.dark_glass"
        event_name="backer_clicked"
        @backer_clicked="qr_code_backer_false_and_goto_verify"
    ></universal_invisible_backer>
    <solo_image
        :show_form="show_qr_code_backer"
        :mobile_mode="mobile_mode"
        :form_color=colors.new_orange_main
        :heading_color=colors.white
        heading_size_px="30px"
        :heading_bold=true
        event_name="qr_code_clicked"
        :svg_str=qr_str
        @qr_code_clicked="tester"
        heading="Scan to your authenticator app."
    >
      <slot>
        <div id="qr_code_container"></div>
      </slot>
    </solo_image>

    <!--    <outside_footer>=</outside_footer>-->
    <div class="flex_centered_row">
      <div class="flex_centered_row  " id="links_row">
        <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_verify_totp")'>TOTP Setup</div>
      </div>
    </div>
  </section>
  <outside_footer id="footer" class="footer_c">
  </outside_footer>
</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {
  be_main_address,
  validate_confirmation_code,
  base64_to_string,
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import solo_image from "@/components/parts/solo_image";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";

import {
  colors
} from "@/library";

export default {
  components: {
    notification_popup,
    basic_text_button,
    error_popup_queue,
    solo_image,
    universal_invisible_backer,
    outside_banner_std,
    outside_footer,
    input_with_label

  },
  data() {
    return {
      colors: colors,
      name: store.getters.my_name,
      password: store.getters.my_password,
      code: '',
      next_route: '',
      qr_str: "",
      show_qr_code_backer_x: false,
      mobile_mode: true
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')

  },
  mounted() {
  },
  methods: {
    tester() {
      console.log("QR CODE WAS CLICKED.")
    },
    qr_code_backer_false_and_goto_verify() {
      this.qr_code_backer_false()
      let route = 'mfa_verify_totp'
      this.simple_route_prep(route)
    },
    qr_code_backer_false() {
      // this.show_hamburger_menu_x = false
      this.show_qr_code_backer_x = false
    },
    catch_mfa_confirmation_code(mfa_c_code) {
      let mfa_code = mfa_c_code.trim()
      // validate
      if (validate_confirmation_code(mfa_code)) {
        this.code = mfa_code
      } else {
        let m = "The confirmation code you entered is not valid. The proper format is 123456."
        store.commit('error_enqueue', m)
      }
    },
    simple_route_prep(r) {
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async confirm() {
      // console.log("Getting ready to confirm new MFA user...")
      // console.log(`Current password from state is: ${this.password}`)

      // let the_router = this.$router

      this.$refs.mfa_confirmation_code_box.emit_state()

      // let error_out
      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "mfa_confirm_user"
      await axios.post(target, {
        name: this.name,
        password: this.password,
        code: this.code,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad confirmation. Check the confirmation code.")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // error_out = true
          return null
        }
      }).then((response) => {
            // console.log("Got good response to mfa confirmation. Should get back qr code string.");
            // console.log(response.data);
            let qr_base64_string = JSON.parse(response.data['data'])['qr_code'];
            console.log('qr base 64 string')
            //convert base 64 to regular svg string
            // display svg image in widow with background
            console.log(qr_base64_string)

            this.qr_str = base64_to_string(qr_base64_string)
            console.log("here is the base64 coverted back to svg for display")
            console.log(this.qr_str)

            this.show_qr_code_backer_x = true

            // let container = document.getElementById("qr_code_container");
            // container.innerHTML = svg_string;

            if (response.data.error === false) {

              // this.$store.commit('save_name', this.name)
              // this.$store.commit('save_email', this.email)
              // this.$store.commit('save_phone', this.phone_number)
              // this.name = ''
              // this.phone_number = ''
              // this.password = ''
              // this.$router.push('/mfa_register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },


  },
  computed: {
    show_qr_code_backer() {
      return this.show_qr_code_backer_x
    },
  }
}
</script>

<style scoped>
@import '../../common.css';


.main_column {
  max-width: 200px;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}

@media (min-width: 100px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }
}


@media (min-width: 500px) {

  /*.links {*/
  /*  font-family: 'Raleway', sans-serif;*/
  /*  font-size: 15px;*/
  /*  margin-left: 1px;*/
  /*  margin-right: 1px;*/
  /*  text-decoration: none;*/
  /*}*/

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }

  form label {
    align-self: center;
    margin-bottom: .75vh;
    font-size: 25px
  }

  form input {
    margin-bottom: 2.25vh;
    align-self: center;
    font-size: 18px
  }


  form button {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 1vh 0.1em 0.1em 0;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    /*color: var(--light-text);*/
    color: yellow;

    /*font-family:'Lobster', cursive;*/
    /*font-weight:300;*/
    /*color: var(--light-text);*/
    background-color: var(--new-primary-purple);
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    font-size: 20px;
  }



}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}


</style>