<template>
  <module_main_page
      module_download_name="dd7a984c-4cc7-47c6-9272-776d2d6cf0a2"
      module_logo_name="sd_hepatitis.svg"
      :module_desc_text=module_text
      module_headline="Understanding Hepatitis A & B"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "hepatitis",
  data() {
    return {
      module_text: "Hepatitis is a contagious disease caused by one of the various hepatitis viruses.  Knowledge about the specific type of hepatitis, its transmission routes, and the client's medical history is crucial. Caregivers must understand the importance of standard precautions and the use of personal protective equipment when handling bodily fluids to prevent the spread of the virus.  Knowledge about the specific type of hepatitis and its transmission routes is crucial. Emotional support and education about hepatitis, including prevention and management, are also part of the caregiver's role. By staying well-informed and providing appropriate care, caregivers can help clients with hepatitis manage their condition effectively in the homecare setting while minimizing the risk of transmission to others.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



