<template>
  <section class="flex_centered_row main_wrapper " :id="this.main_wrapper_id"
           @click="main_button_clicked" @mouseenter="to_hover_color" @mouseleave="to_std_color">

    <!--    <div class="main_wrapper_for_border" :id="this.main_wrapper_for_border_id">-->
    <div class="flex_centered_column">

      <div class=" primary_content_container">

        <div class=" flex_fully_centered_column icon_wrapper">


<!--          <div class="b_rad_50 icon_bg_circle flex_centered_row" :id="this.icon_bg_id">-->
<!--            <div class="flex_centered_column">-->
<!--              <span class="material-symbols-outlined material_icon p_abs" :id="this.icon_id"></span>-->
<!--            </div>-->
<!--          </div> -->

          <div class="flex_centered_start_column ">
          <div class="material_icon_bg flex_centered_column" :id="this.icon_id">
            <div class="flex_centered_row">
              <img class="material_icon"
                   :src="image_name"
                   :alt=desc>
            </div>
          </div>
        </div>

        </div>

        <div class="flex_centered_column" id="central_card_column">
          <div class="flex_centered_column">
            <img class="text_only_logo" :id="this.text_logo_id"
                 :src="require(`@/assets/${text_logo}`)"
                 alt="Card logo">
          </div>
          <div class="wid_85 lineh_35 text_align_center flex_centered_column p_text_wrapper" :id="body_text_id">
            <slot :id="slotted_p" name="body"></slot>
          </div>
          <!--          </div>-->
          <div class="flex_centered_row" v-if="arrow">
            <div class="arrow_wrapper_col">
              <div class="arrow_wrapper">

                <img v-if="arrow_color === 'blue'" class="arrow_img"
                     src="@/assets/arrow_down_blue.svg"
                     alt="Arrows to content">
                <img v-else class="arrow_img"
                     src="@/assets/arrow_down.svg"
                     alt="Arrows to content">
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <!--    </div>-->

  </section>
</template>

<script>
/* eslint-disable */
import {colors, add_class_to_id, remove_class_from_id} from "@/library";
import {v4 as uuidv4} from "uuid";
import store from "@/store";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";

export default {
  name: "feature_card5",
  props: [
    "bg_color",
    "icon",
    "desc",
    "icon_color",
    "icon_bg_color",
    "icon_width",
    "arrow_size",
    "arrow",
    "arrow_color",
    "text_logo",
    "text_logo_width",
    "active",
    "underline_color",
     "hover_color"
  ],
  async updated() {
    try {
      await this.$nextTick(function () {
        console.log(`Active is: ${this.active}`)


        let icon
        icon = document.getElementById(this.icon_id)
        console.log('check 1')
        // icon.textContent = this.material_icon_name

        let icon_bg = document.getElementById(this.icon_bg_id)
        console.log('check 2')
        let text_logo = document.getElementById(this.text_logo_id)
        console.log('check 3')
        let wrapper = document.getElementById(this.main_wrapper_id)
        console.log('check 4')

        // icon.style.color = this.icon_color
        // icon_bg.style.backgroundColor = this.icon_bg_color
        console.log('check 5')
        console.log(`What is the background color? ${this.bg_color}`)
        wrapper.style.backgroundColor = this.bg_color
        text_logo.style.width = this.text_logo_width + "px"


        if (this.active) {
          // wrapper.style.borderBottom = '3px solid var(--new-primary-purple-light)';
          wrapper.style.borderBottomWidth = "3px"
          wrapper.style.borderBottomStyle = "solid"
          if (this.underline_color) {
            wrapper.style.borderBottomColor = this.underline_color
          } else {
            wrapper.style.borderBottomColor = "var(--new-primary-purple-light)"
          }

        } else {
          wrapper.style.borderBottom = '0 solid var(--lauren_gray)';
        }
      })
    } catch (error) {
      console.log("Caught error on update.")
    }
  },
  async mounted() {

    this.icon_id = uuidv4();
    this.material_icon_name = this.icon
    this.icon_bg_id = uuidv4()
    this.main_wrapper_id = uuidv4()
    this.text_logo_id = uuidv4()
    this.body_text_id = uuidv4()
    this.slotted_p = uuidv4()
    // console.log(`Making slotted p id: ${this.slotted_p}`)
    console.log(`Making body text id: ${this.body_text_id}`)

    this.run_window_width_adjustments(this.windowWidth)
  },
  data() {
    return {
      material_icon_name: "",
      // text_logo_name_: "speedy_delivery_purple_text_only.svg",
      // text_logo_name_: "spyglass_text_only.svg",
      icon_id: "",
      icon_bg_id: "",
      text_logo_id: "",
      main_wrapper_id: "",
      main_wrapper_for_border_id: "",
      body_text_id: "",
      slotted_p: "",

    }
  },
  methods: {
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 369, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "16px"
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(370, 399, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(400, 800, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(501, 1499, new_w)) {
        this.button_wid_ = "180px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(1500, 6000, new_w)) {
        this.button_wid_ = "190px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    main_button_clicked() {
      this.$emit('main_btn_clicked', true)
    },
    to_hover_color() {
      let wrapper = document.getElementById(this.main_wrapper_id)
      let body_text_id_element_parent =  document.getElementById(this.body_text_id)
      body_text_id_element_parent.children[0].style.fontWeight = 800

      let icon = document.getElementById(this.icon_id)
      icon.style.color = colors.primary_purple

      if(this.hover_color){
        wrapper.style.backgroundColor = this.hover_color
      }else{
        wrapper.style.backgroundColor = colors.lauren_gray
      }
      // wrapper.style.backgroundColor = colors.lauren_gray
      if (!this.active) {
        wrapper.style.borderBottomWidth = "3px"
        wrapper.style.borderBottomStyle = "solid"
        if (this.underline_color) {
            wrapper.style.borderBottomColor = this.underline_color
        } else {
           wrapper.style.borderBottomColor = "var(--new-primary-purple-light)"
        }
      }

    },
    to_std_color() {
      let wrapper = document.getElementById(this.main_wrapper_id)
      wrapper.style.backgroundColor = this.bg_color

      let body_text_id_element_parent =  document.getElementById(this.body_text_id)
      body_text_id_element_parent.children[0].style.fontWeight = 300

       let icon = document.getElementById(this.icon_id)
      icon.style.color = colors.black

      if (!this.active) {
        wrapper.style.borderBottom = '0 solid var(--lauren_gray)';
      }
    },
  },
  computed: {
     image_name() {
      if (this.material_icon_name !== '') {
        return require(`@/assets/${this.material_icon_name}`);
      } else {
        return ""
      }
    },
    windowWidth() {
      return store.state.windowWidth;
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {

  .arrow_wrapper_col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  .main_wrapper {
    transition: .3s;
    box-sizing: border-box;
    margin: 10px;
  }

  .primary_content_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .text_only_logo {
    width: 150px;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .main_wrapper {
    flex-wrap: unset;
    max-height: 250px;
    max-width: 485px;
    width: 90vw;
    margin: 10px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }


  .icon_wrapper {
    height: 100%;
  }

  .p_text_wrapper {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }

  .arrow_wrapper {
    padding-top: 12px;
    padding-right: 20px;
  }

  .arrow_img {
    width: 20px;
  }

 .material_icon_bg {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .material_icon {
    height: 30px;
    width: 30px;
  }
}

@media (min-width: 360px) {
  .arrow_wrapper {
    padding-top: 33px;
    padding-right: 20px;
  }
}

@media (min-width: 600px) {
  .main_wrapper {
    padding-bottom: 20px;
    padding-top: 0;
    max-height: 225px;
  }


  .text_only_logo {
    width: 150px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .p_text_wrapper {
    max-width: 300px;
  }
}

@media (min-width: 1000px) {
  .main_wrapper {
    flex-wrap: unset;
    height: 55vh;
    min-height: 225px;
    max-height: 225px;
    max-width: 485px;
    width: 90vw;
    margin: 10px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
}

@media (min-width: 1200px) {
  #central_card_column {
    height: 100%;
  }

  .main_wrapper {
    height: 200px;
    width: 485px;
    margin: 10px;
  }

  .p_text_wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .arrow_wrapper {
    margin-bottom: 0;
  }

  .arrow_img {
    width: 20px;
    /*margin-top: -20px;*/
  }

  .material_icon {
    height: 40px;
    width: 40px;
  }
}

</style>