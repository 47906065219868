<template>
  <section class="flex_centered_row carousel_container">
    <my_carousel
        :slides="my_slides"
        :interval=interval
        :body_color="body_color"
    ></my_carousel>

  </section>
</template>

<script>
import my_carousel from "@/components/parts/my_carousel/my_carousel";


export default {
  name: "carousel_container",
  components: {
    my_carousel,

  },
   props: {
    my_slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 8000,
    },
     body_color: {
      type: String,

     },
  },
}
</script>

<style scoped>
@media (min-width: 100px) {
  .carousel_container{
    margin-top: 30px;
  }


}

</style>