<template>
  <section class="entire_ad">
    <div class="screen_block_wide bg_pennie_gray border_2 main_ad_body ">
      <div class="flex_centered_column ">
        <div class="ad_top_content">

          <div class="text_30 font_weight_600 font_raleway bg_eli_gray b_rad_10px padding_tb_10 padding_lr_10
        margin_lr_10 flex_centered_column market call_us_box">
            {{ neighborhood }}
          </div>
          <div class="text_30 font_weight_600 font_raleway padding_lr_15 headline  call_us_box">
            <div>{{ headline_truncated }}</div>
<!--            <div class="text_30 font_weight_600 font_raleway padding_lr_15 flex_end_column flex_grow start_stamp">-->
<!--              Starting ASAP-->
<!--            </div>-->
          </div>
          <basic_icon_button
              v-if="click_to_call_mode && phone_number_available"
              class="cursor_ptr   call_us_box"
              :button_color="colors.primary_purple"
              :button_color_not_ready="colors.pennie_gray"
              :ready="true"
              button_height="75px"
              button_width="75px"
              border_rad_pct="50%"
              image_path='phone-call.svg'
              image_width="40px"
              alt_text="Phone Call"
              event_name="phone_call_clicked"
              @phone_call_clicked="call_agency"
          ></basic_icon_button>
          <div v-if="!click_to_call_mode && phone_number_available" class="text_25 font_weight_600 font_raleway bg_eli_gray b_rad_10px padding_tb_10 padding_lr_10
        margin_lr_10 flex_centered_column text_align_center text_white call_us_box">
            <div>Call us at</div>
            <div>{{ formatted_phone_number }}</div>
          </div>

        </div>
      </div>

      <div class=" font_weight_400 font_raleway  margin_t_40 margin_lr_20 ad_body">{{ body_truncated }}
      </div>
      <div class="margin_lr_20 margin_tb_15  bg_eli_gray  stamps_content b_rad_5px">
        <FreeTime_stamp
            class="margin_lr_10 "
            :base="freetime_stats['base_pay']"
            :fulltime_avg="freetime_stats['fulltime_avg']"
            :fulltime_high="freetime_stats['fulltime_high']"
        ></FreeTime_stamp>
        <div
            class="bg_pennie_gray padding_tb_5  text_15 b_rad_10px  posted_stamp">
          <div>Posted:</div>
          <div>{{ posted }}</div>

        </div>
      </div>
    </div>
  </section>
</template>


<script>
import {colors, formatPhoneNumber} from "@/library";
import FreeTime_stamp from "@/components/SpyGlass/FreeTime_stamp";
import basic_icon_button from "@/components/parts/basic_icon_button";
import store from "@/store";
// import {v4 as uuidv4} from "uuid";

export default {
  name: "schedule_ad",
  props: [
    "logo",
    "neighborhood",
    "headline",
    "body_text",
    "posted",
    "phone_number",
    "freetime_stats",
    "bg_highlight_color",
    "bg_color",
    "mobile",
     "ad_id"
  ],
  async mounted() {
    // this.id_button_body = uuidv4();
    // this.id_button_icon = uuidv4();
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // let button_body = document.getElementById(this.id_button_body)
        // let button_icon = document.getElementById(this.id_button_icon)
        // // console.log(`button_icon is ${button_icon}`)
        // // console.log(`button_body is ${button_body}`)
        // button_body.style.height = this.button_height
        // button_body.style.minHeight = this.button_height
        // button_body.style.width = this.button_width
        // button_body.style.minWidth = this.button_width
        //
        // button_body.style.borderRadius = this.border_rad_pct
        //
        // button_icon.style.width = this.image_width
        // console.log("Getting ready to set button body color.")
        if (this.ready === true) {
          // console.log("set body to ready color")
          // button_body.style.background = this.button_color
        } else {
          // console.log("set body to NOT ready color")
          // button_body.style.background = this.button_color_not_ready
        }
      })
    } catch (error) {
      console.error(error)
    }

  },

  components: {
    FreeTime_stamp,
    basic_icon_button
  },

  data() {
    return {
      colors: colors,
    }
  },

  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    async call_agency() {
      // console.log("in caller")

      this.$emit('click_to_call', this.ad_id)
      let num = this.phone_number
      let full_num = 'tel:' + num
      window.open(full_num);
    }
  },
  computed: {
    headline_truncated(){
      return this.headline.substring(0,49)
    },
    body_truncated(){
      return this.body_text.substring(0,499)
    },

    phone_number_available(){
      return this.phone_number !== "";
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    click_to_call_mode() {
      if (this.windowWidth <= 400) {
        return true
      } else {
        return false
      }
    },
    formatted_phone_number() {
      return formatPhoneNumber(this.phone_number)
    }
  }

}

</script>

<style scoped>


@media (min-width: 100px) {

  .entire_ad {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main_ad_body {
    width: 95vw;
     max-width: 720px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ad_top_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 70%;
    /*max-width: 80%;*/
    /*flex-wrap: wrap;*/
  }

  .call_us_box {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .agency_ad_logo {
    height: 75px;
    margin-left: 00px;
  }

  .call_button {
    margin-top: 10px;
  }

  .start_stamp {
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 10px;
    text-align: center;
    background-color: whitesmoke;
    padding-top: 5px;
  }

  .market {
    font-size: 18px;
    /*margin-bottom: 10px;*/
    /*margin-top: 10px;*/
  }

  .headline {
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 10px;
    background-color: whitesmoke;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
  }

  .ad_body {
    font-size: 18px;
  }

  .stamps_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .posted_stamp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 85%;
    padding-left: 10px;
    max-width: 215px;
  }

}

@media (min-width: 300px) {

}

@media (min-width: 600px) {

  .market {
    font-size: 22px;
  }

  .ad_top_content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /*flex-wrap: wrap;*/
  }

  .call_us_box {
    margin-left: 10px;
    margin-right: 10px;
  }

  .headline {
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 10px;
    background-color: whitesmoke;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }


}

@media (min-width: 1100px) {

  .entire_ad {

  }

  .main_ad_body {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .stamps_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 0px;
  }
}
</style>