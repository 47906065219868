<template>
  <section class="main">
    <div class="flex_centered_row">
      <h2 class="text_align_center">10 days of Clicks</h2>
    </div>
    <div class="flex_centered_column">
      <div v-for="(data, headline) in click_data" :key="`item-${headline}`" class="flex_centered_column click_announcement">
        <p>{{headline}}</p>
        <p>Clicks: {{ data }}</p>
      </div>
      </div>
  </section>




</template>

<script>
/* eslint-disable */

export default {
  name: "click_stats",
  props: ['click_data'],
  methods: {},

}
</script>

<style scoped>

@media (min-width: 100px) {
  .main{
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .click_announcement {
    background-color: lightgrey;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  /*.login_input {*/
  /*  width: 85vw;*/
  /*  max-width: 600px;*/
  /*}*/
  .main_column {
    max-width: 65%;
  }


}


</style>