<template>
  <div class="core_c">

    <inside_banner
        mode="speedydelivery_agency"
        :owner_perm="admins_perm"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>


    <section class="flex_centered_start_column ">
      <div class="flex_centered_row ">
        <img @click="go_back" class="padding_tb_20 sd_logo cursor_ptr"
             src="@/assets/speedydelivery_logo.svg"
             alt="SpeedyDelivery Feature Logo">
      </div>

      <h1 class="text_align_center">Register In-Service</h1>
      <basic_icon_button
          ref="module_icon_image"
          class=" margin_lr_10 cursor_ptr module_icon_image"
          :ready="true"
          :button_color=colors.transparent
          :button_color_not_ready="colors.pennie_gray"
          button_height="50px"
          button_width="50px"
          border_rad_pct="0"
          :image_path=module_icon_file
          :image_width=module_button_size
          alt_text="In-Service Image"
          @emit_state = do_nothing()

      ></basic_icon_button>

      <!--       <h2> module_name </h2>-->
      <!--      <p> module_desc </p>-->
      <h2>{{ module_name }}</h2>
      <p>{{ module_desc }}</p>


    </section>


    <div class="flex_centered_row the_form_wrapper">
      <section class="flex_centered_column margin_t_50 the_form">
        <h3>Set Expiration Date</h3>
        <div class=" flex_centered_column">
          <div class="font_raleway font_weight_800 margin_b_5 margin_t_15">Expiration: {{ expires_in_days }}</div>
          <flat-pickr
              id="fp_field"
              ref="flat_pickr"
              class="doc_expiration_picker"
              v-model="date_"
              model-value
              :config="config"
              @on-change="date_picker_changed"
          />
        </div>
        <h3>Set Credit Hours</h3>
        <input_with_label
            ref="hours_input"
            class="form_input"
            label="Credit Hours"
            @emit_state="credit_hours_entered"
        ></input_with_label>
        <h3>Select Caregivers</h3>


        <div class="flex_left_aligned_centered_column pick_a_cg">
          <!--          <div class="flex_space_between_row wide_button" v-for="person in Object.keys(this.name_map)"-->
          <div class="flex_space_between_row wide_button" v-for="(person, index) in my_people_p_names"
               :key="index">
            <div class="flex_centered_row opt_in_row">
              <div class="flex_centered_column" @click="select_cg(person)">
                <div v-if="!cg_selected(person)" class="check_box shadow_inner cursor_ptr"></div>
                <div v-if="cg_selected(person)">
                  <img class="check_box_icon cursor_ptr"
                       src="@/assets/check-mark.svg"
                       alt="check box image">
                </div>
              </div>
            </div>
            <div>
              <p>{{ person }}</p>
            </div>
          </div>
        </div>


        <basic_text_button
            class="register_button"
            text="Register"
            :text_size=button_text_size
            :button_color="colors.new_orange_main"
            :text_color="colors.white"
            button_width="200px"
            button_height="60px"
            event_name="easy_button_clicked"
            @easy_button_clicked="register_in_service"
        ></basic_text_button>

      </section>
    </div>


  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import inside_banner from "@/components/parts/inside_banner";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import module_display_unit from "@/components/SpeedyDelivery/module_display_unit";
import input_with_label from "@/components/parts/input_with_label";
import store from "@/store";
import image_upload_widget_general from "@/components/parts/image_upload_widget_general";
import camera_widget from "@/components/parts/camera_widget";
import outside_footer from "@/components/parts/outside_footer";

import {
  get_uuid,
  colors,
  scroll_to_top,
  get_pda_home_route,
  be_main_address,
  num_is_between_inclusive,
  prime_socket,
  receive_over_socket,
  translate_dateobj_to_datestring,
  get_associated,
  generate_user_preferred_name,
  get_key_from_value,
  generate_tmh_to_pname_map,
  translate_tmh_to_pname,
  create_tmh_user_to_pname_map, create_list_of_pnames, startsWithCapital, hasSpaces
} from "@/library";
import {
  daysUntilTimestamp,
  formatTimestamp,
  get_default_expiration_date,
  getFutureUTCTimestamp,
  parseDateStringToTimestamp
} from "@/dates_library";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import basic_icon_button from "@/components/parts/basic_icon_button";
import {Database} from "@/client_db";
import {mapGetters} from "vuex";
// import Image_upload_widgit_general from "@/components/parts/image_upload_widget_general";
import axios from "axios";

export default {
  name: "in_service_registration",
  async mounted() {
    console.log('IN mounted')
    this.db = new Database();
    this.id_ = get_uuid()
    this.my_people_ = await get_associated(this.db, 'people')
    console.log('IN mounted2')
    let the_map = store.getters.get_pname_to_tmhname_map
    console.log('IN mounted3')
    if(!the_map){
      let the_map = await create_tmh_user_to_pname_map(this.db)
      console.log("MAP is....")
      console.log(the_map)
    }else{
      console.log("The map is ....")
      console.log(the_map)
    }
    this.my_people_p_names_ = this.create_p_name_list(the_map)
    this.name_map = the_map

    // this.my_people_tmh_to_pname_dict = await generate_tmh_to_pname_map(store.getters.org_context)
    this.my_people_tmh_to_pname_dict =  store.getters.get_pname_to_tmhname_map
    // console.log(this.my_people_tmh_to_pname_dict)
    // this.my_people_p_names_ = this.create_p_names_list()
    // console.log(this.my_people_p_names_)
    // this.in_service_selection_ = await this.create_people_dict_for_in_service_selection(this.db)
    this.in_service_selection_ = await this.create_people_dict_for_in_service_selection(this.db)
    scroll_to_top()
    await this.check_for_general_admin_ui_perm()
    this.run_window_width_adjustments(this.window_width)
    await prime_socket(this.local_message_handler)
    await this.load_in_services_from_local_db()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    camera_widget,
    image_upload_widget_general,
    module_display_unit,
    input_with_label,
    basic_icon_button,
    outside_footer,
    flatPickr
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      admins_perm_: false,
      module_name: "Tester",
      module_desc: "Test Description",
      module_id: "",
      module_credits: "1",
      module_expiration: get_default_expiration_date(),
      module_icon_: "",
      button_size_: "100px",
      button_text_size_: '20px',
      my_people_: [],
      in_service_selection_: {},
      name_map: {},
      my_people_tmh_to_pname_dict: [],
      my_people_p_names_: [],
      input: {
        title: "",
        desc: "",
      },
      date_: "",
      config: {
        dateFormat: 'm/d/Y',
        defaultDate: get_default_expiration_date(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(),
        allowInput: false,
      },
      // mod_data: {
      //   "module_name": "How to Use a Hoyer Lift",
      //   "module_desc": "Learn about Hoyer lifts, how to use them, and when it is appropriate.",
      //   // "date": "2023-10-06",
      //   // "expires": "2024-10-06",
      //   // "score": "0",
      //   // "credit": "1",
      //   "icon_file": "abuse",
      // },
      mod_data: [],

    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
      console.log(something)
    },
    async create_p_name(user, map, the_db) {
      let res = await generate_user_preferred_name(user, map, the_db)
      map = res[1]

      // This is a mirror dict, not just a p_name dict
      this.name_map = map
      return res[0]
    },

    // create_p_names_list() {
    //   let the_list = []
    //   let res
    //   for (const tmh_name in this.my_people) {
    //     res = translate_tmh_to_pname(tmh_name)
    //
    //     the_list.push(res)
    //   }
    //   return the_list
    // },

    // async create_list_of_p_names(the_db) {
    //   console.log("Startingt create_list_of_p_names")
    //   let res = await generate_user_preferred_name(user, this.name_map, the_db, p_name_list = true)
    //   console.log("CReated the list of my people p names...")
    //   console.log(res)
    //
    //   this.my_people_tmh_to_pname_dict = res
    //   return res
    // },

    create_p_name_list(the_map) {
      //the map is a mirror dict of pnames and tmh_names
      let the_out_list = []
      for (const p_or_tmh_name in the_map) {
        let first_is_capital = startsWithCapital(p_or_tmh_name)
        let has_spaces = hasSpaces(p_or_tmh_name)
        if(first_is_capital || has_spaces){
        // We have a p_name
          the_out_list.push(p_or_tmh_name)
        }
      }
      return the_out_list
    },
    get_pname_from_uname(uname) {
      return get_key_from_value(uname, this.name_map)
    },

    async register_in_service() {

      // console.log("Register an In Service")
      // console.log(`Module Name: ${this.module_name}`)
      // console.log(`Module ID: ${this.module_id}`)
      // console.log(`Expiration: ${this.module_expiration}`)
      // console.log(`Credits: ${this.module_credits}`)
      // console.log(`Caregivers List:`)
      //
      // console.log(this.list_of_selected_caregivers)


      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_name: this.module_name,
        module_desc: this.module_desc,
        module_id: this.module_id,
        expires: this.get_processed_expiration_date(),
        credit: this.module_credits,
        icon_file: "in_service",
        score: 10,
        registrants: this.list_of_selected_caregivers,
      }
      // console.log("This is data1")
      // console.log(data1)
      // this.mod_data.push(data1)
      // return null

      let target = be_main_address('sd') + "register_in_service"

      await axios.post(target, {
        method: 'post',
        url: target,
        data: data1,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          console.log("Something went wrong with your login.")
          console.log(error.response)
          store.commit('error_enqueue', "Something went wrong with in-service registration.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              this.module_desc = ""
              this.module_name = ""
              this.$refs.hours_input.reset_state()
              // this.$refs.inservice_title.reset_state()
            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )

      store.commit('notification_enqueue', `${this.module_name} has been registered for ${this.list_of_selected_caregivers.length} caregivers.`)
      this.in_service_selection_ = {}
    },

    select_cg(cg_name) {
      //cg_name should be a p_name
      console.log("IN select caregiver....")
      console.log(`${cg_name} has been selected.`)

      let u_name = this.name_map[cg_name]
      console.log(`From map translated ${cg_name} to ${u_name}.`)

      console.log(`in_service_selection_ with ${u_name} selected is`)
      console.log(this.in_service_selection_)
      console.log(this.in_service_selection_[u_name])
      // console.log(this.in_service_selection_[u_name]['selected'])
      this.in_service_selection_[u_name]['selected'] = !this.in_service_selection_[u_name]['selected']
      console.log(`${cg_name} has been selected.`)
    },
    cg_selected(cg_name) {
      //cg_name is a p_name
      let u_name = this.name_map[cg_name]
      // Check if the name exists in the keys of the main object
      if (u_name in this.in_service_selection_) {
        // Check if the minor object exists and has a 'selected' key
        if (this.in_service_selection_[u_name].hasOwnProperty('selected')) {
          return this.in_service_selection_[u_name].selected;
        }
      }
      // If the name is not found or 'selected' key doesn't exist, return false
      return false;
    },


    async create_people_dict_for_in_service_selection(the_db) {
      let res = {}

      console.log('INside create people dict for in services ....')
      for (const person of this.my_people) {
        console.log(`Working with ${person}`)
        res[person] = {
          'u_name': person,
          'selected': false
        }
      }

      // let n_map = this.create_p_name_list(the_db)
      console.log("Getting ready to launch create list of pnames")
      // let p_names = await this.create_list_of_p_names(the_db)
      // console.log("Just created the p names list....")
      // console.log(p_names)
      let p_name
      for (const uname in res) {
         console.log(`Now Working with ${uname}`)
        // p_name = this.get_pname_from_uname(uname)
        p_name = this.name_map[uname]
        console.log(`Ok, found ${p_name}`)
        if(!p_name === undefined){
           res[uname]['p_name'] = p_name
        }

      }
      return res
    },


    credit_hours_entered(hours) {
      if (num_is_between_inclusive(.5, 99, hours)) {
        this.module_credits = hours
      } else {
        this.$refs.hours_input.reset_state()
        store.commit('error_enqueue', "In-Service credit hours awarded must be from .5 to 5 hours.")
      }
    },
    get_min_expiration_date() {
      let ts = getFutureUTCTimestamp(null, 1, 0, 0)
      return formatTimestamp(ts, 1)
    },
    get_max_expiration_date() {
      let ts = getFutureUTCTimestamp(null, 0, 0, 3)
      // if(this.document_type === 'drivers_license'){
      //   console.log('the doc type was drivers license.')
      //    ts = getFutureUTCTimestamp(null, 0, 0, 10)
      // }else{
      //   console.log(`the doc type was NOT drivers license it was ${this.doc_type}`)
      //    ts = getFutureUTCTimestamp(null,0 , 0, 3)
      // }
      return formatTimestamp(ts, 1)
    },
    date_picker_changed(e) {
      console.log('Date picker changed')
      console.log(e)
      let obj = e[0]
      if (!obj) {
        let str = get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        this.date_ = str
      } else {
        let str = translate_dateobj_to_datestring(obj, 4)
        this.date_ = str
        console.log(`Got a tranlated date of ${str}`)
      }

    },
    change_date_format(old_date_str) {
      let ts = parseDateStringToTimestamp(old_date_str)
      return formatTimestamp(ts)
    },
    get_processed_expiration_date() {
      let old_str = this.date_ || get_default_expiration_date()
      return this.change_date_format(old_str)

    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },
    async local_page_update() {
      console.log("Running caregiver FreeTime Interface local page update.")
      await this.load_in_services_from_local_db()
    },

    async load_in_services_from_local_db() {
      console.log("inside MODERN load_in_services_from_local_db")
      // console.log(store.getters.find_current_preamble)
      // console.log(store.getters.latest_route)
      let full_res = store.getters.latest_route.split('/')
      let target_module_id = full_res.slice(-1)[0]
      console.log(target_module_id)

      let search = {
        key: "",
        additional_context: [['div', 'o&a'], ['div', 'in_services']],
        base_context: store.getters.find_current_preamble
      }

      console.log(JSON.stringify(search))

      let all_docs = await this.db.db_search(search)
      console.log("Found the following In Service Documents")
      console.log(JSON.stringify(all_docs))
      // console.log(`Found ${all_docs.length} docs.`)

      if (all_docs.length === 0) {
        return null
      }

      console.log("Got inservice docs from the local database...")
      for (const c of all_docs) {
        console.log(c)

        // console.log(c['module_name'])
        // console.log(c['module_desc'])
        // console.log(c['module_id'])
        if (c['module_id'] === target_module_id) {
          // console.log("Found the modules you were looking for in the database.")
          // console.log(c)
          // console.log(typeof c)
          // console.log(c.module_name)
          // console.log(c.module_desc)
          // console.log(c.module_id)
          // console.log(c.icon_file)
          // console.log(c['icon_file'])


          this.module_name = c['module_name']
          this.module_desc = c['module_desc']
          this.module_id = c['module_id']
          this.module_icon_ = c['icon_file']

          // console.log("Afetr the nameing")
          // console.log(this.module_name)
          // console.log(this.module_desc)
          // console.log(this.module_id)
          // console.log(this.module_icon_)


          break
        }

        // let weekly_bonus = {
        //   'start_date': c.start_date,
        //   'bonus': c.bonus,
        //   'hours': c.hours,
        //   'ehr': c.ehr,
        //   'agency_message': ""
        // }

        // this.history[c.start_date] = weekly_bonus
      }
      // console.log(JSON.stringify(this.history))


      // this.current_agency_message_x = this.history.agency_messages[this.current_history_report_date_x]
    },


    async create_new_inservice() {


      if (!this.input.desc || !this.input.title) {
        return null
      }

      let body = {
        body: this.input.desc,
        title: this.input.title
      }
      let body_json = JSON.stringify(body)
      console.log(body_json)


      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_name: this.input.title,
        module_desc: this.input.desc,
        icon_file: "abuse",
      }
      console.log("This is data1")
      console.log(data1)
      // this.mod_data.push(data1)

      // this.input.desc = ""
      // this.input.title = ""
      // this.$refs.inservice_desc.reset_state()
      // this.$refs.inservice_title.reset_state()


      let target = be_main_address('sd') + "create_custom_in_service"

      await axios.post(target, {
        method: 'post',
        url: target,
        data: data1,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          console.log("Something went wrong with your login.")
          console.log(error.response)
          store.commit('error_enqueue', "Something went wrong with in-service creation.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              this.input.desc = ""
              this.input.title = ""
              this.$refs.inservice_desc.reset_state()
              this.$refs.inservice_title.reset_state()
            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )

    },

    catch_body(content) {
      this.input.desc = content.trim().slice(0, 200)
    },
    catch_title(content) {
      this.input.title = content.trim().slice(0, 50)
    },
    async go_back() {
      await this.$router.back()
    },
    async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },

    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // console.log(`New size is ${new_w}`)
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "100px"
        this.button_img_size_ = "50px"
      }
      if (new_w > 500) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async check_for_general_admin_ui_perm() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'route',
        additional_context: [['div', 'o&a'], ['div', 'perms']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      // console.log(profile_data)
      let target = "speedydelivery:agency"
      if (profile_data.includes(target)) {
        this.admins_perm_ = true
        // console.log("You have the speedydelivery general admins perm")
        return true
      } else {
        this.admins_perm_ = false
        // console.log("You don't have the speedydelivery general admins perm")
        return false
      }
    },
  },
  computed: {
    ...mapGetters(["window_width"]),
    id() {
      return this.id_
    },
    list_of_selected_caregivers() {
      let out_list = []
      for (const [name, value] of Object.entries(this.in_service_selection_)) {
        if (value['selected']) {
          out_list.push(name)
        }
      }
      return out_list
    },
    my_people() {
      return this.my_people_
    },
    my_people_p_names() {
      return this.my_people_p_names_
    },
    expires_in_days() {
      //Take a datestring like 2024-10-06 and calculate how many days it is from now.
      let d = ""
      // console.log(`Inside expires_in_days and the date is set to....`)
      // console.log(this.date_)
      if (!this.date_) {
        d = get_default_expiration_date()
      } else {
        d = this.date_
      }
      // console.log(`The date in expires_in_days is ${d}`)
      let ts = parseDateStringToTimestamp(d)
      let days = daysUntilTimestamp(ts)
      if (days === 1) {
        return `${days.toString()} Day`
      } else {
        return `${days.toString()} Days`
      }
    },
    module_icon_file() {
      if (!this.module_icon_) {
        return `sd_in_service.svg`
      } else {
        return this.module_icon_
      }

    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    // windowWidth() {
    //   return store.state.windowWidth;
    // },
    mobile_mode() {
      return this.mobile_mode_x
    },
    admins_perm() {
      return this.admins_perm_
    },
    module_button_size() {
      // console.log("Running the module size computed property.")
      return this.button_size_
      // return "100px"
    },
    button_text_size() {
      return this.button_text_size_
    },
    module_image_size() {
      return this.button_img_size_
    },

  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .sd_logo {
    width: 100px
  }

  .doc_expiration_picker {
    width: 180px;
  }

  p {
    max-width: 450px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .pick_a_cg p {
    max-width: 100%;
  }


  .the_form {
    background-color: var(--lauren_gray);
    padding-top: 40px;
    padding-bottom: 40px;
    width: 600px;
    max-width: 100%;

  }

  .the_form_wrapper {
    /*background-color: var(--lauren_gray);*/
    width: 100%;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
    margin-right: 18px;
  }

  .check_box_icon {
    width: 40px;
    margin-right: 10px;
  }

  .pick_a_cg {
    margin-top: 20px;
  }

  h1 {
    color: var(--wyatt_gray);
    font-size: 27px;
    margin-bottom: 20px;
  }

  h2 {
    color: var(--wyatt_gray);
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    margin-top: 20px;
  }

  .register_button {
    margin-top: 40px;
  }

  .module_icon_image {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px;
    margin-top: 10px;

  }

  h5 {
    font-size: 12px;
    width: 90%;
    margin-top: 10px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {
  .sd_logo {
    width: 125px
  }
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .sd_logo {
    width: 200px
  }
}

@media (min-width: 2000px) {
  /*.methods_intro h6 {*/
  /*  font-family: 'Akshar', sans-serif;*/
  /*  font-size: 32px;*/
  /*}*/


}
</style>