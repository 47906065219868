<template>
  <section @click="emit_state" class="flex_left_aligned_column cursor_ptr new_ad">


    <div class="flex_centered_column">

      <img class="agency_landing_logo " :src="agency_logo">

      <div
          class="flex_centered_row text_align_center ad_row_item ad_headline font_akshar font_weight_800 text_primary_purple">
        <div class="ad_body_header">{{ headline_truncated }}</div>
      </div>
    </div>


    <div class="flex_start_row ad_row_item">
      <img class="material_icon" :src="image_name('location_on_purple.svg')" alt="Icon">
      <div class="ad_neighborhood font_raleway font_weight_800">{{ neighborhood }}</div>
    </div>

    <div class="flex_start_row ad_row_item">

      <img class="material_icon" :src="image_name('description_purple.svg')" alt="Icon">
      <p class="ad_body_text">{{ body_truncated }}</p>
    </div>


    <div v-if="phone_number" class="flex_start_row ad_row_item">
      <img class="material_icon" :src="image_name('call.svg')" alt="Icon">

      <call_us_box
          class="margin_lr_10 "
          :click_to_call_mode=click_to_call_mode
          :phone_number="phone_number"
          @call_clicked=call_agency
      ></call_us_box>
    </div>
    <div v-else>
      <h2>No phone number available</h2>
    </div>
  </section>
</template>


<script>
import {
  colors,
  formatPhoneNumber, image_name,
  to_hours_until,
  translate_utc_std_timestring_to_local_pretty_timestring
} from "@/library";
// import FreeTime_stamp from "@/components/SpyGlass/FreeTime_stamp";
import call_us_box from "@/components/SpyGlass/call_us_box";
// import basic_icon_button from "@/components/parts/basic_icon_button";
import store from "@/store";
// import {v4 as uuidv4} from "uuid";

export default {
  name: "local_pda_featured",
  props: [
    "neighborhood",
    "headline",
    "body_text",
    "posted",
    "ad_id",
    "freetime_stats",
    "bg_highlight_color",
    "bg_color",
    "phone_number",
    "event_name",
    "expires",
    "agency_logo"


  ],
  async mounted() {
    // this.id_button_body = uuidv4();
    // this.id_button_icon = uuidv4();
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // console.log("Getting ready to set button body color.")
        if (this.ready === true) {
          // console.log("set body to ready color")
          // button_body.style.background = this.button_color
        } else {
          // console.log("set body to NOT ready color")
          // button_body.style.background = this.button_color_not_ready
        }
      })
    } catch (error) {
      console.error(error)
    }

  },

  components: {
    call_us_box
  },

  data() {
    return {
      colors: colors,
    }
  },

  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    image_name(name) {
      return image_name(name)
    },
    emit_state() {
      this.$emit(this.event_name, this.ad_id)
    },
    call_agency() {
      // console.log("in caller")
      let num = this.phone_number
      let full_num = 'tel:' + num
      window.open(full_num);
    }
  },
  computed: {


    expires_when() {
      return to_hours_until(this.expires)
    },
    posted_pretty() {
      return translate_utc_std_timestring_to_local_pretty_timestring(this.posted)
    },
    headline_truncated() {
      return this.headline.substring(0, 49)
    },
    body_truncated() {
      return this.body_text.substring(0, 499)
    },

    phone_number_available() {
      return this.phone_number !== "";
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    click_to_call_mode() {
      if (this.windowWidth <= 420) {
        return true
      } else {
        return false
      }
    },
    formatted_phone_number() {
      console.log("in formatted phone")
      console.log(formatPhoneNumber(this.phone_number))
      return formatPhoneNumber(this.phone_number)
    }
  }

}

</script>

<style scoped>

.new_ad:hover {
  background-color: var(--lauren_gray);
}

@media (min-width: 100px) {

 .material_icon{
     height: 28px;
     width: 23px;
     margin-right: 10px;
   margin-left: 5px;
  }

  .agency_landing_logo {
    height: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .new_ad {
    width: 95vw;
    max-width: 560px;
    /*border-color: var(--pennie_gray);*/
    border: 2px solid var(--wyatt_gray);
    padding-bottom: 10px;
  }

  .call_us_box h5 {
    color: var(--primary-purple);
  }

  .ad_body_text {
    font-size: 12px;
    max-width: 85vw;
  }

  .ad_body_header {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .ad_headline {
    font-size: 20px;
    max-width: 95vw;
  }

  .ad_row_item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ad_row_item span {
    margin-right: 10px;
    margin-left: 10px;
  }

  .posted_stamp p {
    font-size: 15px;
  }

}

@media (min-width: 360px) {
  .ad_body_header {
    text-align: center;
  }

  .ad_body_text {
    font-size: 15px;
    max-width: 75vw;
  }
}


@media (min-width: 400px) {
  .material_icon{
     height: 35px;
     width: 29px;
     margin-right: 15px;
   margin-left: 10px;
  }
}


@media (min-width: 600px) {

  .agency_landing_logo {
    height: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
  }


  .ad_headline {
    font-size: 25px;
  }

  .ad_neighborhood {
    font-size: 24px;
  }

  .posted_stamp {
    color: var(--new-primary-purple-deep);
    width: 350px;
  }

  /*.material_icon {*/
  /*  color: var(--primary-purple);*/
  /*  font-size: 40px;*/
  /*  margin-right: 25px;*/
  /*}*/

  /* .material_icon{*/
  /*   height: 77px;*/
  /*   width: 77px;*/
  /*}*/

  .ad_row_item {
    margin: 20px 20px;
  }

  @media (min-width: 1200px) {
    .new_ad {
      width: 560px;
      border-color: var(--pennie_gray);
      padding: 25px;
    }

    .new_ad p {
      font-size: 22px;
    }

    .agency_landing_logo {
      height: 120px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

}


</style>







