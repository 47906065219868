<template>
  <section class="screen_block flex_centered_column  bg_whitesmoke">
    <spinner
        :spinner_up="spinner_up"
    ></spinner>
    <div class="label_bolder margin_t_20 text_align_center">Create a Schedule Ad</div>
    <p class="margin_t_30">Is this a SpyGlass Plus Ad?</p>
    <div class="flex_centered_row opt_in_row ">
      <div class="flex_centered_column" @click="toggle_spyglass_plus_opt_in">
        <div v-if="!this.ad_handle.is_plus_ad" class="check_box shadow_inner cursor_ptr"></div>
        <div v-if="this.ad_handle.is_plus_ad">
          <img class="check_box_icon cursor_ptr"
               src="@/assets/check-mark.svg"
               alt="check box image">
        </div>
      </div>
      <div class="spacer"></div>
      <p v-if="true" class="opt_in_text_no text_align_center  font_weight_800">SpyGlass Plus</p>
    </div>

    <div v-if="this.ad_handle.is_plus_ad" class="flex_centered_column">
      <div class="text_15 font_raleway margin_tb_10 font_weight_800 wid_75">SpyGlass+ Ads are seen by most
        caregivers in your market and pay premium rates which appear on the FreeTime Report.
      </div>
    </div>


    <label_with_input_text_area
        class="margin_tb_10 margin_t_30"
        ref="ad_headline_text"
        :ro_status="false"
        :show_edit_button="false"
        label="Write your ad headline."
        :label_color="colors.black"
        label_text_size="20px"
        :value_bg_color_ready="colors.morgie_gray"
        :value_bg_color_not_ready="colors.eli_gray"
        :value_text_color_ready="colors.black"
        :value_text_color_not_ready="colors.black"
        value_text_size_ready="15px"
        value_text_size_not_ready="15px"
        char_length_event="headline_length"
        @headline_length="check_headline_length"
        value=""
        rows="2"
        columns="30"
        placeholder_text="Ad Headlines can be up to 50 characters."
        placeholder_text_not_ready="Headlines can be up to 50 characters."
        @edit_button_clicked="do_nothing"
    ></label_with_input_text_area>


    <div v-if="!this.ad_handle.is_plus_ad" class="date_picker">
      <div class="text_20 font_raleway margin_tb_10 font_weight_800 text_align_center">Estimated Start Date</div>
      <div class="flex_centered_row">
         <flat-pickr
          class="text_align_center"
          id="schedule_start_time_picker_trad"
          ref="flat_pickr_trad"
          v-model="date_unique_"
          model-value
          :config="trad_config"
          @on-change="unique_picker_changed_trad"
      />
      </div>

    </div>


    <div class="text_15 font_raleway margin_tb_10 font_weight_800">Choose a Neighborhood</div>
    <drop_down_select ref="neighborhood_drop_down"
                      title="Neighborhood"
                      :values="neighborhoods"
                      @value-selected="neighborhood_chosen"
    ></drop_down_select>


    <div class="text_15 font_raleway margin_tb_10 margin_t_30 font_weight_800">Choose a Client</div>
    <drop_down_select ref="client_drop_down"
                      class="client_drop_down"
                      title="Client"
                      :values="clients_for_dropdown"
                      @value-selected="client_chosen"
    ></drop_down_select>

    <div v-if="show_phone_number_field" class="text_15 font_raleway margin_tb_10 font_weight_800">Enter Client Name
    </div>
    <input_with_label
        v-if="show_phone_number_field"
        ref="client_name_box"
        class="client_phone"
        label="Client Name"
        @emit_state="catch_client_name"
    ></input_with_label>

    <div v-if="show_phone_number_field" class="text_15 font_raleway margin_tb_10 font_weight_800">Enter Client Phone
      Number
    </div>
    <input_with_label
        v-if="show_phone_number_field"
        ref="phone_number_box"
        class="client_phone"
        label="Client Phone Number"
        @emit_state="catch_phone_number"
    ></input_with_label>


    <label_with_input_text_area
        class=""
        ref="ad_body_text"
        :ro_status="false"
        :show_edit_button="false"
        label="Write the body text of your ad."
        :label_color="colors.black"
        label_text_size="20px"
        :value_bg_color_ready="colors.morgie_gray"
        :value_bg_color_not_ready="colors.eli_gray"
        :value_text_color_ready="colors.black"
        :value_text_color_not_ready="colors.black"
        value_text_size_ready="15px"
        value_text_size_not_ready="15px"
        char_length_event="ad_body_length"
        @ad_body_length="check_body_length"
        value=""
        rows="10"
        columns="30"
        placeholder_text="Ad body can be up to 500 characters."
        placeholder_text_not_ready="Ad body can be up to 500 characters."
        @edit_button_clicked="do_nothing"
    ></label_with_input_text_area>

    <div v-if="this.ad_handle.is_plus_ad" class="date_picker">
      <div class="text_20 font_raleway margin_tb_10 font_weight_800 text_align_center">Schedule Start</div>
      <flat-pickr
          class="text_align_center"
          id="schedule_start_time_picker"
          ref="flat_pickr"
          v-model="date_unique_"
          model-value
          :config="plus_config"
          @on-change="unique_picker_changed"
      />
    </div>
    <div v-if="this.ad_handle.is_plus_ad">
      <div class="text_20 font_raleway margin_tb_10 font_weight_800 text_align_center">Hours</div>
      <input_with_label
          ref="hours_in_schedule"
          class="login_input margin_b_20"
          label="Hours in Schedule"
          @emit_state="catch_schedule_hours"
      ></input_with_label>
    </div>


    <!--    $20 pre hour check box-->
    <div v-if="this.ad_handle.is_plus_ad" class="flex_centered_row opt_in_row margin_tb_15">
      <div class="flex_centered_column" @click="set_plus_premium_to_20">
        <div v-if="this.ad_handle.plus_rate !== 20" class="check_box shadow_inner cursor_ptr"></div>
        <div v-else>
          <img class="check_box_icon cursor_ptr"
               src="@/assets/check-mark.svg"
               alt="check box image">
        </div>
      </div>
      <div class="spacer"></div>
      <p v-if="true" class="opt_in_text_no text_align_center  font_weight_800">$20 per Hour</p>
    </div>

    <!--    $25 pre hour check box-->
    <div v-if="this.ad_handle.is_plus_ad" class="flex_centered_row opt_in_row margin_tb_15">
      <div class="flex_centered_column" @click="set_plus_premium_to_25">
        <div v-if="this.ad_handle.plus_rate !== 25" class="check_box shadow_inner cursor_ptr"></div>
        <div v-else>
          <img class="check_box_icon cursor_ptr"
               src="@/assets/check-mark.svg"
               alt="check box image">
        </div>
      </div>
      <div class="spacer"></div>
      <p v-if="true" class="opt_in_text_no text_align_center  font_weight_800">$25 per Hour</p>
    </div>

    <!--    $30 pre hour check box-->
    <div v-if="this.ad_handle.is_plus_ad" class="flex_centered_row opt_in_row margin_tb_15">
      <div class="flex_centered_column" @click="set_plus_premium_to_30">
        <div v-if="this.ad_handle.plus_rate !== 30" class="check_box shadow_inner cursor_ptr"></div>
        <div v-else>
          <img class="check_box_icon cursor_ptr"
               src="@/assets/check-mark.svg"
               alt="check box image">
        </div>
      </div>
      <div class="spacer"></div>
      <p v-if="true" class="opt_in_text_no text_align_center  font_weight_800">$30 per Hour</p>
    </div>

    <div class="flex_centered_row flex_align_items_center">
      <basic_toggle ref="ad_launch_toggle"
                    class="margin_lr_10  "
                    uuid=""
                    :state_disabled="!publish_ad_ready"
                    @toggle-state_true="toggle_is_true"
                    @toggle-state_false="toggle_is_false"
      ></basic_toggle>
      <basic_text_button
          class="margin_b_20 margin_t_10"
          :button_state="publish_ad_ready && this.toggle_state"
          :button_color="colors.new_cyanic_main"
          :text_color="colors.black"
          :button_color_not_ready="colors.gray"
          :button_not_ready_text_color="colors.morgie_gray"
          :hover_color="colors.new_orange_main"
          button_height="80px"
          button_width="80px"
          border_rad_pct="50%"
          text="Publish Ad"
          button_not_ready_text="Publish Ad"
          text_size="14px"
          :bold="true"
          event_name="save_agency_message"
          @save_agency_message="publish_new_ad"
      ></basic_text_button>
    </div>
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
  </section>
</template>

<script>
/* eslint-disable */
import {
  analytic,
  isIntOrFloat,
  convertNumericValue,
  be_main_address,
  isStringPresentInObject,
  colors,
  parse_internal_json,
  translate_dateobj_to_datestring,
  validate_confirmation_code,
  validate_phonenumber,
  isEmpty
} from "@/library";
import flatPickr from "vue-flatpickr-component";
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import label_with_input_text_area from "@/components/parts/label_with_input_text_area.vue";
import input_with_label from "@/components/parts/input_with_label.vue";
import drop_down_select from "@/components/parts/drop_down_select";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import axios from "axios";
import {formatTimestamp, getFutureUTCTimestamp, parseDateStringToTimestamp} from "@/dates_library";

export default {
  name: "Create_ad",
  async mounted() {
    this.neighborhood_list_ = await this.get_agency_neighborhoods()
    this.form_state = this.form_state_default
    await this.get_agency_clients_listing_from_be()
  },
  data() {
    return {
      colors: colors,
      publish_ad_ready_: false,
      // publish_plus_ad_ready: false,
      ad_ready: {
        headline: false,
        body: false,
        neighborhood: false,
        start_time: false,
        plus_rate: 0,
        hours: false
      },

      neighborhood_list_: "",
      clients_list_: "",
      clients_names_: "",
      no_client_message_: "Client Not on List",

      date_unique_: "",
      config: {
        enableTime: true,
        dateFormat: 'm/d/Y',
        defaultDate: this.get_default_expiration_date(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(),
        allowInput: false,
      },
      plus_config: {
        enableTime: true,
        dateFormat: 'm/d/Y, G:i K',
        defaultDate: this.get_default_plus_date_time_start(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(),
        allowInput: false,
      },
       trad_config: {
        enableTime: false,
        dateFormat: 'm/d/Y',
        defaultDate: this.get_default_plus_date_time_start(),
        // defaultDate: '9/22/2023',
        minDate: this.get_min_expiration_date(),
        maxDate: this.get_max_expiration_date(30),
        allowInput: false,
      },

      show_phone_number_field_: false,
      spyglass_plus_opt_in_: false,
      toggle_state_: false,
      form_state_default: {
        'headline': false,
        'body': false,
        'neighborhood': false,
        'client_name': false,
        'client_phone': false,
        'spyglass_plus': false,
        'schedule_start': true,
        'hours': false,
        'per_hour': true
      },
      form_state: {},
      ad_handle: {
        neighborhood: "",
        headline: "",
        body: "",
        start_time: "",
        plus_rate: 20,
        is_plus_ad: false,
        client_name: "",
        client_phone: "",
        ad_hours: 0,
        client_phone_required: false
      },
    }
  },
  components: {
    basic_toggle,
    basic_text_button,
    label_with_input_text_area,
    drop_down_select,
    error_popup_queue,
    notification_popup,
    spinner,
    input_with_label,
    flatPickr
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    current_publish_state() {
      if (!this.form_state.headline) {
        //If there is no headline, then this ad in not ready
        return "not_ready"
      } else if (!this.form_state.neighborhood) {
        return "not_ready"
      } else if (
          this.form_state.headline &&
          this.form_state.body &&
          this.form_state.neighborhood &&
          (this.form_state.client_name || this.form_state.client_phone) &&
          !this.form_state.spyglass_plus
      ) {
        //You are ready to publish trad ad
        return "trad_ad_ready"
      } else if (
          this.form_state.headline &&
          this.form_state.body &&
          this.form_state.neighborhood &&
          (this.form_state.client_name || this.form_state.client_phone) &&
          this.form_state.spyglass_plus &&
          this.form_state.schedule_start &&
          this.form_state.hours &&
          this.form_state.per_hour
      ) {
        console.log('PLUS AD IS SHOWING READY')
        return "plus_ad_ready"
      } else {
        console.log('NOT READY TO PUBLISH ANY AD')
        return "not_ready"
      }

    },

    catch_schedule_hours(hours) {
      let hours_str = convertNumericValue(hours, 'string')
      // hours = hours.trim()
      // validate
      let error_out = false
      if (hours_str > 24) {
        let m = "The schedule may not exceed 24 hours.."
        this.error_out = true
        this.form_state.hours = false
        // let x = this.publish_ad_ready
        console.log("setting hours state to false")
        store.commit('error_enqueue', m)
        error_out = true
      } else if (hours_str === undefined) {
        let m = "Hours has to be a number."
        error_out = true
        // let x = this.publish_ad_ready
        this.form_state.hours = false
        console.log("setting hours state to false")
        store.commit('error_enqueue', m)
      }else if(hours===""){
        // let x = this.publish_ad_ready
        this.form_state.hours = false
        console.log("setting hours state to false")
      }
      else {
        console.log("setting hours state to true")
        // let x = this.publish_ad_ready
        this.form_state.hours = true
        console.log(this.form_state)
      }

      if (error_out) {
        this.ad_handle.ad_hours = ""
        // this.ad_ready.hours = false
      } else {
        this.ad_handle.ad_hours = hours_str
        // this.ad_ready.hours = true

      }
    },

     catch_client_name(client_name) {
      client_name = client_name.trim()
      client_name = client_name.slice(0, 35);

      this.ad_handle.client_name = client_name
      //  remove all non-digits
      // phone = phone.replace(/\D/g, '');
      // validate
      // if (validate_phonenumber(phone)) {
      //   this.ad_handle.client_phone = phone
      //   this.form_state.client_phone = true
      // } else {
      //   let m = phone_number + " is not a valid phone number."
      //   store.commit('error_enqueue', m)
      //   return null
      // }
    },

    catch_phone_number(phone_number) {
      let phone = phone_number.trim()
      //  remove all non-digits
      phone = phone.replace(/\D/g, '');
      // validate
      if (validate_phonenumber(phone)) {
        this.ad_handle.client_phone = phone
        this.form_state.client_phone = true
      } else {
        let m = phone_number + " is not a valid phone number."
        store.commit('error_enqueue', m)
        return null
      }
    },
    get_min_expiration_date() {
      let ts = getFutureUTCTimestamp(null, 0, 0, 0)
      return formatTimestamp(ts, 1)
    },
    get_max_expiration_date(days=10) {
      let ts

      ts = getFutureUTCTimestamp(null, days, 0, 0)
      return formatTimestamp(ts, 1)
    },
    reset_date_picker() {
      const my_fp = document.querySelector("#schedule_start_time_picker")._flatpickr;
      my_fp.clear();
      my_fp.setDate(this.get_default_expiration_date())
    },
    change_date_format(old_date_str) {
      let ts = parseDateStringToTimestamp(old_date_str)
      return formatTimestamp(ts)
    },
    // get_processed_expiration_date() {
    //   let old_str = this.date_ || this.get_default_expiration_date()
    //   return this.change_date_format(old_str)
    //
    // },
    get_default_expiration_date() {
      let m, d, y, the_past, show_tz, tz_type
      m = 0
      d = 1
      y = 0
      the_past = false
      show_tz = false
      tz_type = 'short'

      let future_ts = getFutureUTCTimestamp(null, d, m, y, the_past)
      // console.log(`The future timestamp I have come up with is ${future_ts}`)
      let res = formatTimestamp(future_ts, 1, show_tz, tz_type)
      // console.log(`The resulting string I have come up with is ${res}`)
      return res

    },
    unique_picker_changed(e) {
      // console.log('Car insurance Date picker changed')
      // console.log(e)
      let obj = e[0]
      let str
      if (!obj) {
        str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        // this.date_unique_ = str
      } else {
        console.log('Here is the datetime object i got....')
        console.log(obj)
        str = translate_dateobj_to_datestring(obj, 3)
        console.log(str)
        // this.date_unique_ = str
      }
      this.ad_handle.start_time = str
      // this.ad_ready.start_time = true

    },

    unique_picker_changed_trad(e) {
      // console.log('Car insurance Date picker changed')
      // console.log(e)
      let obj = e[0]
      let str
      if (!obj) {
        str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        // this.date_unique_ = str
      } else {
        console.log('Here is the datetime object i got....')
        console.log(obj)
        str = translate_dateobj_to_datestring(obj, 3)
        console.log(str)
        // this.date_unique_ = str
      }
      this.ad_handle.start_time = str
      // this.ad_ready.start_time = true

    },

    async get_agency_neighborhoods() {
      let pda = store.getters.org_context
      await this.get_agency_neighborhoods_from_be(pda)

    },
    neighborhood_chosen(state) {
      console.log(`You chose the ${state} neighborhood.`)
      console.log("Here are the neighborhoods available...")
      console.log(this.neighborhood_list_)
      this.ad_handle.neighborhood = state
      // this.ad_ready.neighborhood = true
      if(isStringPresentInObject(this.neighborhood_list_, state)){
        this.form_state.neighborhood = true
      }else{
        this.form_state.neighborhood=false
      }



    },
    client_chosen(state) {
      console.log(`You chose the ${state} client.`)
      if (state === this.no_client_message_) {
        this.show_phone_number_field_ = true
        this.ad_handle.client_phone_required = true
        console.log("Choosing the ANONYMOUS client")
      } else {
        this.show_phone_number_field_ = false
        this.ad_handle.client_name = state
        this.ad_handle.client_phone_required = false
        this.ad_handle.client_phone = this.clients_data[state]
        this.form_state.client_name = true
        console.log(`Choosing ${state} for the client.`)
        console.log(`using phone number ${this.ad_handle.client_phone} for ${state}.`)
      }


      },

    async get_agency_clients_listing_from_be() {

      console.log("Getting agency clients listing")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('user_profile') + 'get_agency_client_listing'
      axios.post(target_url, data1).then((response) => {
        console.log("here is the response.")
        this.clients_list_ = parse_internal_json(response).data.data
        this.clients_names_ = Object.keys(this.clients_list_).sort();
        console.log("Here is the list of agency clients.")
        console.log(this.clients_list_)
        console.log("Here are the client names...")
        console.log(this.clients)
      })

    },

    async get_agency_neighborhoods_from_be(pda) {

      console.log("Getting agencg neighborhoods")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: pda,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('sg') + 'get_agency_neighborhoods'
      axios.post(target_url, data1).then((response) => {
        console.log("here is the response.")
        this.neighborhood_list_ = parse_internal_json(response).data.data
        // console.log("Here is the list of agency neighborhoods.")
        // console.log(this.neighborhood_list_)
      })
    },
    check_headline_length(state) {

      // console.log(`Headline Length: ${state.length}`)
      // this.ad_ready.headline = state.length >= 5;
      if (state.length >= 5) {
        this.form_state.headline = true
      } else {
        this.form_state.headline = false
      }
    },
    check_body_length(state) {
      // console.log(`Body Length: ${state}`)
      // this.ad_ready.body = state.length >= 10;
      if (state.length >= 10) {
        this.form_state.body = true
      } else {
        this.form_state.body = false
      }
    },
    reset_ad() {
      console.log("INside reset ad....")
      // this.ad_ready.body = false
      // this.ad_ready.headline = false
      // this.ad_ready.neighborhood = false
      // this.ad_ready.start_time = ""
      // this.ad_ready.plus_rate = 20
      // this.ad_ready.hours = false
      this.form_state = this.form_state_default


      this.ad_handle.is_plus_ad = false
      this.ad_handle.client_phone_required = false


      try {
        this.$refs.phone_number_box.reset_state()
      } catch (error) {
        //pass
      }

      this.$refs.ad_body_text.reset()
      this.$refs.ad_headline_text.reset()
      this.$refs.ad_launch_toggle.toggle_state_to_false()
      this.$refs.neighborhood_drop_down.reset()
      this.$refs.client_drop_down.reset()
      try{
        this.$refs.hours_in_schedule.reset_state()
      }catch(err){
        //pass
      }
      this.toggle_is_false()
    },

    reset_publish_ready() {
      this.$refs.ad_launch_toggle.toggle_state_to_false()
      this.toggle_is_false()
    },

    get_default_plus_date_time_start() {
      const now = new Date();
      const tzOffsetMs = now.getTimezoneOffset() * 60 * 1000;
      const localDate = new Date(now.getTime() - tzOffsetMs);
      localDate.setHours(18, 0, 0, 0);

      const dateString = localDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });

      return dateString;
    },

    async publish_new_ad() {
      if(!this.toggle_state){
         store.commit('error_enqueue', "You must first activate the toggle before publishing your ad.")
        return null
      }
      if (!this.publish_ad_ready) {
        console.log("Not ready")
        store.commit('error_enqueue', "Publish new ad not ready")
        return null
      }
      let default_plus_start_date
      default_plus_start_date = this.get_default_plus_date_time_start()
      if (this.ad_handle.is_plus_ad) {
        // default_plus_start_date = this.get_default_plus_date_time_start()
        if (this.ad_handle.client_phone_required) {
          if (!this.ad_handle.client_phone) {
            console.log("Not ready")
            store.commit('notification_enqueue', "Required client phone not present.")
            return null
          }
        } else {
          if (!this.ad_handle.client_name) {
            console.log("Not ready")
            store.commit('notification_enqueue', "Required client name not present.")
            return null
          }
        }
      }
      // else{
      //   this.ad_handle.start_time = this.$refs.ad_headline_text.text_value_.substring(0, 50)
      // }

      store.commit('set_spinner_state', true)
      this.ad_handle.headline = this.$refs.ad_headline_text.text_value_.substring(0, 50)
      this.ad_handle.body = this.$refs.ad_body_text.text_value_.substring(0, 500)

      if ((!isEmpty(this.ad_handle.start_time))) {
        console.log("Found a value is start time...")
        console.log(this.ad_handle.start_time)
        // let str = this.get_default_expiration_date()
        // console.log(`The date for the picker was empty. Usingt default of ${str}`)
        // this.date_unique_ = str
      } else {
        console.log("Did not Find a value is start time...use the default....")
        console.log(default_plus_start_date)
        this.ad_handle.start_time = default_plus_start_date
        console.log(this.ad_handle.start_time)
        // console.log('Here is the datetime object i got....')
        // console.log(s_date)
        // let str = translate_dateobj_to_datestring(s_date, 3)
        // console.log(str)
        // this.date_unique_ = str
      }

      // this.flatpickr = this.flatpickr(this.$refs.flatpickr, {});
      // this.ad_handle.start_time = this.flatpickr.selectedDates[0];

      console.log("Here is the ad data to be published...")
      console.log(this.ad_handle)


      // if (this.ad_handle.is_plus_ad) {
      //   analytic({
      //     event: "spyglass ad plus publish",
      //     agency: store.getters.org_context,
      //     caller: store.getters.my_name,
      //     neighborhood: this.ad_handle.neighborhood,
      //     // market: this.ad_handle.market,
      //     headline: this.ad_handle.headline,
      //     body: this.ad_handle.body,
      //     start_time: this.ad_ready.start_time,
      //     plus_rate: this.ad_handle.plus_rate
      //
      //   })
      // } else {
      //   analytic({
      //     event: "spyglass ad publish",
      //     agency: store.getters.org_context,
      //     caller: store.getters.my_name,
      //     neighborhood: this.ad_handle.neighborhood,
      //     // market: this.ad_handle.market,
      //     headline: this.ad_handle.headline,
      //     body: this.ad_handle.body,
      //   })
      // }

      this.save_ad_to_backend()
      //send this new ad to backend, get back ad in local database.
    },

    save_ad_to_backend() {
      console.log("Savintg ad to backend")

      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        // values: JSON.stringify(this.ad_handle)
        values: JSON.stringify(this.ad_handle)
      }


      let target_url = be_main_address('sg') + 'save_schedule_ad'
      axios.post(target_url, data1).then((response) => {

        console.log("here is the save_schedule_ad response.......................")
        console.log(JSON.stringify(response.data))
        store.commit('notification_enqueue', response.data.message)
        if (response.data['success'] === true) {
          this.reset_ad()
        } else {
          this.reset_publish_ready()
        }
        // this.neighborhood_list_ = parse_internal_json(response).data.data
      })
      store.commit('set_spinner_state', false)

    },
    toggle_is_true() {
      // this.publish_ad_ready_ = true
      this.toggle_state_ = true
    },
    toggle_is_false() {
      // this.publish_ad_ready_ = false
      this.toggle_state_ = false
    },

    toggle_spyglass_plus_opt_in() {
      this.ad_handle.is_plus_ad = !this.ad_handle.is_plus_ad
      if (this.ad_handle.is_plus_ad) {
        this.form_state.spyglass_plus = true
      }
    },

    set_plus_premium_to_20() {
      this.ad_handle.plus_rate = 20
    },

    set_plus_premium_to_25() {
      this.ad_handle.plus_rate = 25
    },
    set_plus_premium_to_30() {
      this.ad_handle.plus_rate = 30
    },

  },
  computed: {
    spinner_up() {
      return store.getters.get_spinner_state
    },
    plus_premium_rate() {
      return this.ad_handle.plus_rate

    },
    show_phone_number_field() {
      return this.show_phone_number_field_
    },

    toggle_state() {
      return this.toggle_state_
    },

    // publish_ad_armed() {
    //   if (this.publish_ad_ready && this.toggle_state) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },

    publish_ad_ready() {
      if (this.current_publish_state() === 'trad_ad_ready') {
        console.log(`Publish ad state is trad_ad_ready`)
        return true
      } else if (this.current_publish_state() === 'plus_ad_ready') {
        console.log(`Publish ad state is plus_ad_ready`)
        return true
      } else {
        console.log(`Publish ad state is not_ready`)
        return false
      }
      // return this.publish_ad_ready_
    },
    // ad_ready_to_publish() {
    //   return this.publish_ad_ready
    //   // let first = this.ad_ready.headline && this.ad_ready.body && this.ad_ready.neighborhood
    //   // return first
    // },
    // plus_ad_ready_to_publish() {
    //   // return this.ad_ready.headline && this.ad_ready.body && this.ad_ready.neighborhood && this.plus_ad_ready.start_time
    //   return this.ad_ready_to_publish && this.ad_ready.start_time
    // },
    neighborhoods() {
      return this.neighborhood_list_

    },
    clients_for_dropdown(){
      let aaa = this.clients_names_
      try {
        aaa.unshift(this.no_client_message_)
        return aaa
      }catch(error){
        return []
      }
    },
    clients() {
      // this.clients_list_.unshift(this.no_client_message_)
      return this.clients_names_
    },
    clients_data() {
      // this.clients_list_.unshift(this.no_client_message_)
      return this.clients_list_
    },


    // neighborhood_is_chosen() {
    //   if (this.ad_handle.neighborhood) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
  },
}
</script>

<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .date_picker {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .client_phone {
  //margin-top: 30px;
  }

  .client_drop_down {
    margin-bottom: 30px;
  }

  .write_the_body {
    margin-top: 30px;
  }

  .spacer {
    width: 10px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);

  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 160px;
    color: var(--wyatt_gray);
  }


  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>