<template>

  <section class="flex_centered_column">
    <div id="new_thread" class="">
      <h3>Start a new conversation</h3>

      <section class="grid_wrapper font_raleway font_weight_800">

        <input_with_label
            ref="title_input"
            class="form_input"
            label="New Thread Title"
            @emit_state="catch_title"
        ></input_with_label>

        <input_with_label_text_area
            ref="comment_text_input"
            class="form_input"
            :columns="columns_num"
            :rows="rows_num"
            place_holder_text="Type your comment."
            @emit_state="catch_comment_text"
        >
        </input_with_label_text_area>

        <div class="flex_end_row" id="post_button_container">
          <basic_text_button
              class=""
              :button_state="true"
              :button_color="colors.new_primary_purple"
              :text_color="colors.white"
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="50px"
              button_width="125px"
              border_rad_pct="5px"
              text="Post"
              text_size="17px"
              :bold="true"
              event_name="create_thread_clicked"
              @create_thread_clicked="create_new_thread"
          ></basic_text_button>
        </div>

      </section>

    </div>
  </section>
</template>

<script>
import basic_text_button from "@/components/parts/basic_text_button";
import input_with_label from "@/components/parts/input_with_label";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import store from "@/store";
import {
  reentry_route, good_token_now, dual_route, scroll_to_element, analytic,
  num_is_between_inclusive,
  colors
} from "@/library";

export default {
  name: "new_conversation",
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  unmounted() {
  },
  data() {
    return {
      colors: colors,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      columns_num_: "",
      rows_num_: "",
      max_title_len: 500,
      max_comment_len: 10000,
      new_thread: {
        title: null,
        timestamp: null,
        creator: null,
        comment: null,
        admin: null,
        forum: null
      },

    }
  },
  components: {
    basic_text_button,
    input_with_label,
    input_with_label_text_area
  },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },

    create_new_thread() {
      this.$refs.title_input.emit_state()
      this.$refs.comment_text_input.emit_state()
      // let utc_ts = timestamp()

      this.$emit("create_new_thread", this.new_thread.title, this.new_thread.comment)

      this.$refs.title_input.reset_state()
      this.$refs.comment_text_input.reset_state()


    },
    catch_title(content) {
      this.new_thread.title = content.trim().slice(0, this.max_title_len)
    },
    catch_comment_text(content) {
      this.new_thread.comment = content.trim().slice(0, this.max_comment_len)
    },

    dual_route_wrapper(route, inner = null) {
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      analytic("01:01:01:01")
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {

      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 679, new_w)) {
        // console.log("inside the 100 to 679 set")

        this.columns_num_ = 15
        this.rows_num_ = 5
      }
      // if (num_is_between_inclusive(301, 500, new_w)) {
      //   this.columns_num_ = 26
      //   this.rows_num_ = 5
      // }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        // console.log("setting to large")
        this.columns_num_ = 60
        this.rows_num_ = 10
        // this.v_scale = 1.00
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
      }


    },
  },
  computed: {
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>


.red {
  /*background-color: red;*/
}

.yellow {
  /*background-color: yellow;*/
}


@media (min-width: 100px) {

  .form_input {
    width: 273px;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #new_thread {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .grid_wrapper {
    /*background-color: yellow;*/
    display: grid;
    width: 95%;
    grid-gap: 1px;
    grid-template-columns: 1fr;
    column-gap: 1px;
    padding-top: 30px;
    padding-bottom: 30px;
    grid-template-areas:
              "title_name"
              "title_input"
              "comment_name"
              "comment_input"
              "button_left"
              "button_right";
    /*border: 1px solid #444;*/
    /*border-radius: 5px;*/
  }


  #post_button_container {
    grid-area: button_right;
    width: 95%;
    margin-top: 30px;
  }

  #thread_title_label {
    padding: 0;
    margin: 0;
    grid-area: title_name;
    height: 100%;
    width: 100px;
    font-size: 17px;
  }

  #thread_title_input_container {
    /*background-color: #00c7ff;*/
    height: 60px
  }

  #thread_title_input {
    margin: 0;
    padding: 0;
    grid-area: title_input;
    border-radius: 5px;
    height: 65%;
    width: 95%;
    box-sizing: border-box;
    border: 1px solid #444;
  }

  #thread_comment_label_container {
    height: 100%;
  }

  #thread_comment_label {
    grid-area: comment_name;
    width: 100px;
    font-size: 17px;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  #thread_comment_input_container {
    /*background-color: #3D0091;*/
  }

  #thread_comment_input {
    grid-area: comment_input;
    /*background-color: lightgrey;*/
    /*margin: 20px;*/
    border-radius: 5px;
    width: 95%;
    font-size: 20px;
    padding: 10px;
    box-sizing: border-box;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;

  }
}

@media (min-width: 400px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
  }
}

@media (min-width: 680px) {
  .form_input {
    width: 600px;
  }
}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }
}

@media (min-width: 1200px) {

  #new_thread {
  }

  h3 {
    font-size: 35px;
  }

  .grid_wrapper {
    /*background-color: yellow;*/
    /*display: grid;*/
    width: 600px;
    /*grid-gap: 1px;*/
    /*grid-template-columns: 1fr;*/
    /*column-gap: 1px;*/
    padding-top: 10px;
    /*padding-bottom: 30px;*/
    /*row-gap: 20px;*/
    /*grid-template-areas:*/
    /*          "title_name"*/
    /*          "title_input"*/
    /*          "comment_name"*/
    /*          "comment_input"*/
    /*          "button_left"*/
    /*          "button_right";*/
    /*background-color: #fff;*/
    /*border: 1px solid #444;*/
    /*border-radius: 5px;*/
  }


  #post_button_container {
    grid-area: button_right;
    width: 95%;
    margin-top: 30px;
  }

  #thread_title_label {
    grid-area: title_name;
    height: 100%;
    width: 100px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 25px;
  }

  #thread_title_input_container {
    /*background-color: #00c7ff;*/
    height: 80px
  }

  #thread_title_input {
    grid-area: title_input;
    border-radius: 5px;
    height: 65%;
    /*width: 600px;*/
    font-size: 20px;
    box-sizing: border-box;
    /*margin: 20px;*/
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #444;
    padding: 10px;
  }

  #thread_comment_label_container {
    height: 100%;
  }

  #thread_comment_label {
    grid-area: comment_name;
    width: 100px;
    font-size: 25px;
  }

  #thread_comment_input_container {
    /*background-color: #3D0091;*/
  }

  #thread_comment_input {
    grid-area: comment_input;
    /*background-color: lightgrey;*/
    /*margin: 20px;*/
    border-radius: 5px;
    /*width: 600px;*/
    font-size: 20px;
    padding: 10px;
    box-sizing: border-box;
  }
}

@media (min-width: 2000px) {

}
</style>