<template>
  <h6 id="v_tag" class="v_tag top_corner text_white ">UI-{{ current_v }}</h6>
</template>

<script>
import {current_vtag} from "@/library";
export default {
  name: "version_tag",

  async mounted() {
    // this.message_id = uuidv4();

    await this.$nextTick(function () {
      try {
        let v_tag = document.getElementById("v_tag")
        v_tag.style.color = this.text_color
      } catch (error) {
        // console.error(error)
      }
    })

  },

  props: ['text_color'],
  computed: {
     current_v(){
      return current_vtag()
    },
  },

}
</script>

<style scoped>

.top_corner {
  top: 0;
  left: 0;
}


</style>