<template>
  <module_main_page
      module_download_name="3ec69e16-6c4f-4a2f-b4a6-41180366c919"
      module_logo_name="sd_neglect.svg"
      :module_desc_text=module_text
      module_headline="Understanding Neglect"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "neglect",
  data() {
    return {
      module_text: "Caregiver neglect occurs when a caregiver fails to provide the necessary care, assistance, and supervision required to meet the needs of the client they are responsible for. Neglect can take many forms, including physical, emotional, social, and financial neglect. It is essential for caregivers to provide the necessary care and support required to meet the needs of their clients and report any concerns or suspicions of neglect to the appropriate authorities.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>
