<template>
  <module_main_page
      module_download_name="202079ab-ff23-4661-ae9e-9f4a1984fd9c"
      module_logo_name="sd_documentation.svg"
      :module_desc_text=module_text
      module_headline="Reporting & Documenting Client Care"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "documentation",
  data() {
    return {
      module_text: "Proper documentation is crucial in the home care context as it serves as a record of the care provided to the client and helps to ensure that care is delivered consistently and safely. Accurate documentation provides a clear picture of the client's health status, treatment plan, and progress over time, which is essential for coordinating care among different providers and ensuring continuity of care. Documentation can also serve as legal evidence in the event of disputes or claims of negligence. Proper documentation should include the patient's medical history, medications, vital signs, assessments, and any changes in the patient's condition. Additionally, documentation must adhere to all applicable laws and regulations, including HIPAA privacy rules.",
    }
  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>


