<template>
  <section @click="emit_state" class="flex_left_aligned_column cursor_ptr new_ad">


    <div class="flex_centered_row">
      <div class="flex_start_row ad_row_item ad_headline font_akshar font_weight_800 text_primary_purple_deep">
        <div class="ad_body_header">{{ headline_truncated }}</div>
      </div>
    </div>


    <div class="flex_start_row ad_row_item">
      <img class="material_icon"
           :src="image_name('location_on_purple.svg')"
           alt="Icon">
      <div class="ad_neighborhood font_raleway font_weight_800">{{ neighborhood }}</div>
    </div>

    <div class="flex_start_row ad_row_item">
      <!--      <span class="material-symbols-outlined material_icon">description</span>-->
      <img class="material_icon"
           :src="image_name('description_purple.svg')"
           alt="Icon">
      <p class="ad_body_text">{{ body_truncated }}</p>
    </div>

    <div class="flex_start_row ad_row_item">
      <!--      <span class="material-symbols-outlined material_icon">payments</span>-->
      <!--      <img class="material_icon"-->
      <!--                   :src="image_name('icon_cash_black.svg')"-->
      <!--                   alt="Icon">-->
      <div class="flex_centered_column">
        <img class="material_icon_cash"
             :src="image_name('icon_cash_purple.svg')"
             alt="Icon">
      </div>


      <FreeTime_stamp
          class="margin_lr_10 "
          :base="round_to_two(freetime_stats['base_pay'])"
          :fulltime_avg="freetime_stats['fulltime_avg']"
          :fulltime_high="freetime_stats['fulltime_high']"
      ></FreeTime_stamp>
    </div>

    <div v-if="phone_number" class="flex_start_row ad_row_item">
      <!--      <span class="material-symbols-outlined material_icon">call</span>-->
      <div class="flex_centered_column">
        <img class="material_icon_phone"
             :src="image_name('call.svg')"
             alt="Icon">
      </div>

      <call_us_box
          class=" "
          :click_to_call_mode=click_to_call_mode
          :phone_number="phone_number"
          @call_clicked=call_agency
      ></call_us_box>


    </div>

    <div v-if="phone_number" class="flex_start_row ad_row_item">
      <!--      <span class="material-symbols-outlined material_icon">call</span>-->
      <div class="flex_centered_column">
        <img class="material_icon_phone"
             :src="image_name('send.svg')"
             alt="Send Icon">
      </div>

      <message_us_box
          class="cursor_ptr"
          :click_to_call_mode=click_to_call_mode
          :phone_number="phone_number"
          :email_address="email_address"
          @message_clicked=message_agency
      ></message_us_box>

      <universal_pop_up_slot
          :show_popup="show_job_modal"
          @universal_popup_bg_clicked="bg_clicked"
          :backer_color="colors.dark_glass"
      >
        <div class="msg_to_agency bg_lauren_gray ">
          <div class="flex_centered_row">
            <h3 class="margin_b_20 text_primary_purple" @click="job_modal_false">Have the agency call you!</h3>
          </div>
          <div class="flex_centered_row">
            <h4 class="margin_b_20">{{ headline_truncated_more }}</h4>
          </div>

          <input_with_label
              ref="contact_first"
              class="form_input"
              label="First Name"
              @emit_state="catch_first"
          ></input_with_label>

          <input_with_label
              ref="contact_last"
              class="form_input"
              label="Last Name"
              @emit_state="catch_last"
          ></input_with_label>

          <input_with_label
              ref="contact_phone"
              class="form_input"
              label="Phone Number"
              @emit_state="catch_phone"
          ></input_with_label>
          <div class="flex_centered_row">
            <basic_text_button
                class="padding_lr_6 padding_tb_6  margin_t_40 "
                :hover_color=colors.cyanic_blue
                :hover_text_color=colors.white
                :button_state="true"
                :button_color="colors.new_orange_main"
                :text_color=colors.white
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="52px"
                button_width="150px"
                border_rad_pct="0"
                text="SEND"
                text_size="14px"
                :bold="true"
                event_name="send_button_clicked"
                @send_button_clicked="send_message_to_info"
                v-on:keyup.enter="send_message_to_info"
            ></basic_text_button>

          </div>
          <div class="close_window">
            <p class=" text_primary_purple_deep" @click="job_modal_false">Close Window</p>
          </div>
        </div>


      </universal_pop_up_slot>

    </div>


    <div class="flex_centered_row">
      <div class="flex_space_between_row ad_row_item posted_stamp">
        <p class="posted">Posted</p>
        <p>{{ posted_pretty }}</p>
      </div>
    </div>
    <div class="flex_centered_row">
      <div class="flex_space_between_row ad_row_item posted_stamp">
        <p class="posted">Expires</p>
        <p>{{ expires_when }}</p>
      </div>
    </div>

  </section>
</template>


<script>
/* eslint-disable */
import {
  colors, form_to_email_info_account,
  formatPhoneNumber, image_name,
  to_hours_until, round,
  translate_utc_std_timestring_to_local_pretty_timestring, validate_email, validate_phonenumber
} from "@/library";
import FreeTime_stamp from "@/components/SpyGlass/FreeTime_stamp";
import call_us_box from "@/components/SpyGlass/call_us_box";
import message_us_box from "@/components/SpyGlass/message_us_box";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import input_with_label from "@/components/parts/input_with_label";
// import {v4 as uuidv4} from "uuid";

export default {
  name: "schedule_ad",
  emits: ['ad_clicked'],
  props: [
    "neighborhood",
    "headline",
    "body_text",
    "posted",
    "ad_id",
    "freetime_stats",
    "bg_highlight_color",
    "bg_color",
    "phone_number",
    "email_address",
    "agency_name",
    "event_name",
    "expires"


  ],
  async mounted() {
    // this.id_button_body = uuidv4();
    // this.id_button_icon = uuidv4();
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        // console.log("Getting ready to set button body color.")
        if (this.ready === true) {
          // console.log("set body to ready color")
          // button_body.style.background = this.button_color
        } else {
          // console.log("set body to NOT ready color")
          // button_body.style.background = this.button_color_not_ready
        }
      })
    } catch (error) {
      console.error(error)
    }

  },

  components: {
    FreeTime_stamp,
    call_us_box,
    message_us_box,
    universal_pop_up_slot,
    input_with_label,
    basic_text_button
  },

  data() {
    return {
      colors: colors,
      job_modal_: false,
      button_wid_: "",
      bad_phone: true,
      input: {
        first: "",
        last: "",
        city: "",
        state: "",
        email: "",
        phone: "",
        text_content: "",
      }
    }
  },

  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    round_to_two(a_num){
      return round(a_num, 2)

    },

    catch_first(content) {
      this.input.first = content.trim().slice(0, 50)
    },
    catch_last(content) {
      this.input.last = content.trim().slice(0, 50)
    },
    catch_phone(content) {
      let t_content = content.trim().slice(0, 50)
      if (validate_phonenumber(t_content)) {
        this.input.phone = t_content
        this.bad_phone = false
      }
    },
    catch_text_content(content) {
      this.input.text_content = content.trim().slice(0, 1010)
    },

     send_message_to_info() {
      this.$refs.contact_first.emit_state()
      this.$refs.contact_last.emit_state()
      // this.$refs.contact_city.emit_state()
      // this.$refs.contact_state.emit_state()
      // this.$refs.contact_email.emit_state()
      this.$refs.contact_phone.emit_state()
      // this.$refs.contact_text_area.emit_state()


      if (this.bad_phone) {
        store.commit('error_enqueue', `Please provide a valid phone number.`)
        return null
      }

       if (!this.input.first) {
        store.commit('error_enqueue', `First Name requires a value.`)
        return null
      }
      if (!this.input.last) {
        store.commit('error_enqueue', `Last name requires a value.`)
        return null
      }



      form_to_email_info_account(
          {
            first: this.input.first,
            last: this.input.last,
            phone: formatPhoneNumber(this.input.phone),
            subject: `User Job Post Contact Request`,
            agency: this.agency_name,
            text_content: this.formatted_phone_number,
            feature: this.headline
          }
      )
      this.input.first = ""
      this.input.last = ""
      this.input.phone = ""
      this.$refs.contact_first.reset_state()
      this.$refs.contact_last.reset_state()
      this.$refs.contact_phone.reset_state()
      this.bad_phone = true
       this.job_modal_false()
    },

    // catch_city(content) {
    //   this.input.city = content.trim().slice(0, 50)
    // },
    //
    // catch_state(the_state) {
    //   console.log(`${the_state} was selected as the state.`)
    //   this.input.state = the_state.trim().slice(0, 50)
    // },
    // catch_email(content) {
    //   if (!validate_email(content)) {
    //     this.bad_email = true
    //   }
    //   this.input.email = content.trim().slice(0, 100)
    //   this.bad_email = false
    //
    // },

    image_name(name) {
      return image_name(name)
    },
    job_modal_true() {
      this.job_modal_ = true
    },
    job_modal_false() {
      this.job_modal_ = false
    },
    bg_clicked() {
      console.log("The messageing modal bg was clicked")
      this.job_modal_false()
      // this.$emit('bg_clicked', true)
    },
    emit_state() {
      this.$emit(this.event_name, this.ad_id)
    },
    call_agency() {
      // console.log("in caller")
      let num = this.phone_number
      let full_num = 'tel:' + num
      window.open(full_num);
    },
    message_agency() {
      console.log("You just clicked the jobs message modal.")
      this.job_modal_true()

      //pass
    },
     run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.button_wid_ = 250
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
          //pass
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        //pass
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.button_wid_ = 300
      }

    },
  },
  computed: {
     button_wid() {
      return `${this.button_wid_}px`
    },
    show_job_modal() {
      return this.job_modal_
    },
    the_agency_name() {
      return this.agency_name
    },

    expires_when() {
      return to_hours_until(this.expires)
    },
    posted_pretty() {
      return translate_utc_std_timestring_to_local_pretty_timestring(this.posted)
    },
    headline_truncated() {
      return this.headline.substring(0, 49)
    },
    headline_truncated_more() {
      return this.headline.substring(0, 15) + "..."
    },
    body_truncated() {
      return this.body_text.substring(0, 499)
    },

    phone_number_available() {
      return this.phone_number !== "";
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    click_to_call_mode() {
      if (this.windowWidth <= 420) {
        return true
      } else {
        return false
      }
    },
    formatted_phone_number() {
      console.log("in formatted phone")
      console.log(formatPhoneNumber(this.phone_number))
      return formatPhoneNumber(this.phone_number)
    }
  }

}

</script>

<style scoped>

.new_ad:hover {
  background-color: var(--lauren_gray);
}

@media (min-width: 100px) {



  .close_window p {
    font-size: 15px;
    margin-top: 20px;
  }

  h4 {
    font-size: 20px;
  }

  .msg_to_agency h3 {
    font-size: 24px;
    max-width: 75%;
  }

  .msg_to_agency {
    max-width: 85%;
    text-align: center;
    padding: 20px;
    max-height: 90vh;
  }


  .material_icon {
    width: 20px;
    height: 29px;
    margin-right: 25px;
    margin-left: 10px;
  }

  .material_icon_cash {
    width: 27px;
    margin-left: 10px;
  }

  .material_icon_phone {
    height: 34px;
    margin-left: 10px;
  }

  .new_ad {
    width: 95vw;
    max-width: 560px;
    /*border-color: var(--pennie_gray);*/
    border: 2px solid var(--wyatt_gray);
  }


  .call_us_box {
    margin-left: 10px;
    margin-right: 10px;
  }

  .call_us_box h5 {
    color: var(--primary-purple);
  }

  .ad_body_text {
    font-size: 15px;
    max-width: 85vw;
  }

  .ad_body_header {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .ad_headline {
    font-size: 20px;
    max-width: 95vw;
    /*background-color: #00c7ff;*/
  }

  .ad_row_item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ad_row_item span {
    margin-right: 10px;
    margin-left: 10px;
  }

  .posted_stamp {
    color: var(--new-primary-purple-deep);
  }

  .posted_stamp p {
    font-size: 15px;
  }

  .posted {
    margin-right: 10px;
  }

}

@media (min-width: 360px) {
  .ad_body_header {
    text-align: center;
  }

  .ad_body_text {
    font-size: 15px;
    max-width: 75vw;
  }

  h4 {
    font-size: 25px;
    max-width: 80%;
  }

  .msg_to_agency h3 {
    font-size: 35px;
    max-width: unset;
  }

  .close_window p {
    margin-top: 40px;
  }
}

@media (min-width: 600px) {

  /*.market {*/
  /*  font-size: 22px;*/
  /*}*/
  /*.ad_top_content {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  justify-content: space-evenly;*/
  /*  align-items: center;*/
  /*  !*flex-wrap: wrap;*!*/
  /*}*/
  /*.headline {*/
  /*  font-size: 17px;*/
  /*  margin-bottom: 5px;*/
  /*  margin-top: 10px;*/
  /*  background-color: whitesmoke;*/
  /*  padding: 10px;*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  justify-content: center;*/
  /*  border-radius: 10px;*/
  /*}*/
  .ad_headline {
    font-size: 25px;
  }

  .ad_neighborhood {
    font-size: 24px;
  }

  .posted_stamp {
    color: var(--new-primary-purple-deep);
    width: 350px;
  }

  .material_icon {
    width: 35px;
    height: 50px;
    margin-right: 25px;
  }

  .material_icon_cash {
    width: 45px;
    /*height: 50px;*/
    margin-right: 25px;
  }

  .material_icon_phone {
    height: 45px;
    margin-left: 10px;
    margin-right: 25px;
  }

  .ad_row_item {
    margin: 20px 20px;
  }

  @media (min-width: 1200px) {
    .new_ad {
      width: 560px;
      border-color: var(--pennie_gray);
      padding: 25px;
    }

    .new_ad p {
      font-size: 22px;
    }
  }

}


</style>







