<template>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white ">
    <div class="flex_centered_column ">
      <img class="tmh_color_gradient padding_tb_30 logo"
           src="@/assets/vortex.svg"
           alt="Vortex Logo">
<!--      <div class="font_raleway font_weight_800 margin_b_20 text_25">SpyGlass Services</div>-->
    </div>

  </div>

  <div class=" margin_tb_20 flex_centered_column ">

    <select_and_launch_2
      class="margin_auto bg_whitesmoke font_raleway font_weight_800"
      label='Subscribe a PDA to Vortex'
      :label_color=colors.black
      selection_title="PDAs"
      button_text="Subscribe"
      :values="non_subscribing_pdas"
      event_name="create_pda_spyglass_subscription"
      @create_pda_spyglass_subscription="create_pda_spyglass_subscription"
  ></select_and_launch_2>

<!--  <select_and_launch_2-->
<!--      class="margin_auto bg_whitesmoke  font_raleway font_weight_800 margin_t_20"-->
<!--      label='Suspend a PDA from Vortex'-->
<!--      :label_color=colors.black-->
<!--      selection_title="PDAs"-->
<!--      button_text="Suspend"-->
<!--      :values="[]"-->
<!--      event_name="suspend_pda_spyglass_subscription"-->
<!--      @suspend_pda_spyglass_subscription="suspend_pda_spyglass_subscription"-->
<!--  ></select_and_launch_2>-->

<!--    <dual_select_and_launch class="margin_auto bg_whitesmoke font_raleway font_weight_800  margin_t_20 b_rad_10px"-->
<!--                          label="Permanently Suspend Ad"-->
<!--                          selection_title1="Select PDA"-->
<!--                          selection_title2="Select Ad Id"-->
<!--                          button_text="Suspend"-->
<!--                          :values1="[]"-->
<!--                          :values2="[]"-->
<!--                          event_name="suspend_ad"-->
<!--                          @suspend_ad="do_nothing"-->
<!--  >-->
<!--  </dual_select_and_launch>-->


  </div>
</template>
<script>
/* eslint-disable */
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import dual_select_and_launch from "@/components/parts/dual_select_and_launch";
import {be_main_address, colors, parse_internal_json} from "@/library";
import store from "@/store";
import axios from "axios";
// import {be_main_address, parse_internal_json, validate_place_name} from "@/library";
// import store from "@/store";
// import axios from "axios";

export default {
  name: "tmh_vortex_controls",
  components: {select_and_launch_2, dual_select_and_launch},
  async mounted() {
    this.non_subscribing_pdas_ = await this.get_list_of_non_subscribers()
    // this.get_countries()
    // this.get_states()
    // this.get_markets()
    // console.log("here are the markets...")
    // console.log(this.markets_list)
  },
  data() {
    return {
      colors: colors,
      non_subscribing_pdas_: "",
      selected_subscriber_: "",
      // create_country_button_status_: false,
      // countries_list_: [],
      // states_list_: [],
      // markets_list_: [],
      // neighborhood_list_: [],
      // place: {
      //   country: "",
      //   state: "",
      //   market: "",
      //   neighborhood: "",
      // },
    }
  },
  computed: {
    non_subscribing_pdas(){
      return this.non_subscribing_pdas_
    },
    selected_subscriber(){
      return this.selected_subscriber_
    },

    // remembered_country() {
    //   return this.place.country
    // },
    // remembered_state() {
    //   return this.place.state
    // },
    // remembered_market() {
    //   return this.place.market
    // },
    // countries_list() {
    //   return this.countries_list_
    // },
    // states_list() {
    //   return this.states_list_
    // },
    // markets_list() {
    //   return this.markets_list_
    // },
    // create_country_button_status(){
    //   console.log(`in computed create_country_button_status ${this.create_country_button_status_}`)
    //
    //   return this.create_country_button_status_
    // },
  },
  methods: {

    do_nothing(){
      console.log("doing nothing.")
    },
    async local_page_update() {
      // await this.get_list_of_good_subscribers()
      await this.get_list_of_non_subscribers()
    },
    async create_pda_spyglass_subscription(state){
       console.log(`Caught the create subscription event for ${state}.`)
      this.selected_subscriber_ = state
      await this.subscribe(state)
      await this.local_page_update()
    },
    suspend_pda_spyglass_subscription(){
      this.do_nothing()
    },

    async get_list_of_non_subscribers() {
      let target_url = be_main_address('vortex') + 'get_non_subscribers'
      // let target_url = 'http://192.168.1.10:8087/ft/get_non_subscribers'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              }else{
                this.non_subscribing_pdas_ = response.data
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async subscribe(subscribee) {
      let target_url = be_main_address('vortex') + 'create_subscription'
      // let target_url = 'http://192.168.1.10:8087/ft/create_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        // subscriber: "",
        subscribing_agency: subscribee

      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
              let message = `${subscribee} has been subscribed to Vortex.`
              store.commit('notification_enqueue', message)
            } else {
               console.log("SERVER ERROR")
              console.log(response)
              return "this is an error"
            }
          })
    },
    // forget_place() {
    //   this.place.neighborhood = ""
    //   this.place.market = ""
    //   this.place.state = ""
    //   this.place.country = ""
    // },
    // refresh_country_list(){
    //   this.countries_list_ = this.get_countries()
    // },
    // reset_all_lists(){
    //   //except for the country list
    //   this.neighborhood_list_ = []
    //   this.markets_list_ = []
    //   this.states_list_ = []
    // },
    // remember_country(state) {
    //   console.log("inside remember country")
    //   console.log(state)
    //   this.place.country = state
    //   this.states_list_ = this.get_states(this.place.country)
    // },
    // remember_state(state) {
    //   console.log("inside remember state")
    //   console.log(state)
    //   this.place.state = state
    //   this.markets_list_ = this.get_markets(this.place.state)
    // },
    //
    // remember_market(state) {
    //   console.log("inside remember market")
    //   console.log(state)
    //   this.place.market = state
    //   this.neighborhood_list_ = this.get_neighborhoods(this.place.market)
    // },
    // async get_countries() {
    //   let target_url = be_main_address('geography') + 'country_listing'
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             //got good response
    //             this.countries_list_ = response.data.data.sort()
    //             console.log("Here is the list of countries")
    //             console.log(response.data.data)
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    //
    // },
    //
    // async get_states(filter) {
    //   let target_url = be_main_address('geography') + 'state_listing'
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     filter: filter
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             //got good response
    //             this.states_list_ = response.data.data.sort()
    //             console.log("Here is the list of states")
    //             console.log(response.data.data)
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    //
    // },
    //
    // async get_markets(filter){
    //   let target_url = be_main_address('geography') + 'market_listing'
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     filter: filter
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             //got good response
    //             this.markets_list_ = response.data.data.sort()
    //             console.log("Here is the list of markets")
    //             console.log(response.data.data)
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },
    //
    // async get_neighborhoods(filter){
    //   let target_url = be_main_address('geography') + 'neighborhood_listing'
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     filter: filter
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             //got good response
    //             this.neighborhood_list_ = response.data.data.sort()
    //             console.log("Here is the list of markets")
    //             console.log(response.data.data)
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },
    //
    // async create_country(state) {
    //   let target_url = be_main_address('geography') + 'create_country'
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     country: state
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             store.commit('notification_enqueue', `${state} has been created.`)
    //             this.$refs.country_create.reset()
    //             this.forget_place()
    //             this.reset_all_lists()
    //             this.refresh_country_list()
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    //
    // },
    //
    // async create_state(state) {
    //   let target_url = be_main_address('geography') + 'create_state'
    //
    //   console.log("in create state")
    //   console.log(state)
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     country: this.remembered_country,
    //     state: state
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             store.commit('notification_enqueue', `${state} has been created.`)
    //             this.$refs.state_create.reset()
    //             this.forget_place()
    //             this.reset_all_lists()
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },
    //
    // async create_market(state) {
    //   let target_url = be_main_address('geography') + 'create_market'
    //
    //   console.log("in create market")
    //   console.log(state)
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     country: this.remembered_country,
    //     state: this.remembered_state,
    //     market: state
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             store.commit('notification_enqueue', `${state} has been created.`)
    //             this.$refs.market_create.reset()
    //             this.forget_place()
    //             this.reset_all_lists()
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },
    //
    // async create_neighborhood(state) {
    //   let target_url = be_main_address('geography') + 'create_neighborhood'
    //
    //   console.log("in create neighborhood")
    //   console.log(state)
    //
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     country: this.remembered_country,
    //     state: this.remembered_state,
    //     market: this.remembered_market,
    //     neighborhood: state
    //   }
    //
    //   await axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK" || response.status === 200) {
    //           if (response.data['error'] === true) {
    //             let message = parse_internal_json(response.data)['payload']['content']['message']
    //             store.commit('error_enqueue', message)
    //           } else {
    //             store.commit('notification_enqueue', `${state} has been created.`)
    //             this.$refs.neighborhood_create.reset()
    //             this.forget_place()
    //             this.reset_all_lists()
    //           }
    //         } else {
    //           console.log("SERVER ERROR")
    //           console.log(response)
    //         }
    //       })
    // },
    //
    // create_neighborhood_button_status_true(state) {
    //   console.log(`Setting the launch button status to true: state is ${state}`)
    //   if (!validate_place_name(state)) {
    //     let message = `${state} is not a valid name.`
    //     store.commit('error_enqueue', message)
    //     this.$refs.neighborhood_create.reset()
    //
    //   } else {
    //     this.$refs.neighborhood_create.button_state_to_true();
    //     // this.create_market_button_status_ = true
    //   }
    // },
    //
    // create_market_button_status_true(state) {
    //   console.log(`Setting the launch button status to true: state is ${state}`)
    //   if (!validate_place_name(state)) {
    //     let message = `${state} is not a valid name.`
    //     store.commit('error_enqueue', message)
    //     this.$refs.market_create.reset()
    //
    //   } else {
    //     this.$refs.market_create.button_state_to_true();
    //     // this.create_market_button_status_ = true
    //   }
    // },
    //
    // create_state_button_status_true(state) {
    //   console.log(`Inside STATE BUTTON STATUS TO TRUE: ${state}`)
    //   console.log(`Setting the launch button status to true: state is ${state}`)
    //   if (!validate_place_name(state)) {
    //     let message = `${state} is not a valid name.`
    //     store.commit('error_enqueue', message)
    //     this.$refs.state_create.reset()
    //
    //   } else {
    //     this.$refs.state_create.button_state_to_true();
    //     this.create_state_button_status_ = true
    //   }
    // },
    //
    // create_country_button_status_true(state) {
    //   console.log(`Setting the launch button status to true: state is ${state}`)
    //   if (!validate_place_name(state)) {
    //     let message = `${state} is not a valid name.`
    //     store.commit('error_enqueue', message)
    //     this.$refs.country_create.reset()
    //
    //   } else {
    //     this.$refs.country_create.button_state_to_true();
    //     this.create_country_button_status_ = true
    //   }
    // },


  },
}
</script>

<style scoped>

.logo{
  width: 200px;
}
</style>