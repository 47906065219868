<template>
  <footer class="text_primary_purple_deep " id="footer_wrapper">
    <div class="" id="outside_footer">

<!--      <div class="spacer"></div>-->

      <div class="flex_centered_row">
        <div id="footer_line"></div>
      </div>

      <div class="flex_centered_row copywrite " id="footer_copyright">
        <p>Tiny Magic Hat &copy; 2022-2024</p>
      </div>
<!--      <div class="flex_centered_row version_line " id="footer_version_tag">-->
<!--        <version_tag class=""></version_tag>-->
<!--      </div>-->
    </div>
  </footer>

</template>

<script>
/* eslint-disable */
import version_tag from "@/components/basics/version_tag";
import store from "@/store";
import {
  colors, dual_route, scroll_to_element, scroll_to_top,
} from "@/library";

export default {
  name: "outside_footer_landing",
  emits: ["link_clicked"],
  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 700,
    }
  },
  components: {
    version_tag
  },
  props: [
  ],
  async mounted() {

  },
  updated() {
  },
  unmounted() {
  },


  methods: {

    dual_route_wrapper(route, inner = null) {
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      this.emit_state()
      dual_route(this.$router, route, inner)

    },
    scroll_to_top() {
      scroll_to_top()
    },
    emit_state() {
      this.$emit("link_clicked", true)
    },

    run_window_width_adjustments(new_w) {

      // console.log("Running window adjustments")

      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      // let contact = document.getElementById('footer_contact')
      // let links = document.getElementById('footer_links')
      if (this.num_is_between_inclusive(100, 541, new_w)) {
        // contact.style.textAlign = 'center'
        // links.style.textAlign = 'center'
      }
      if (this.num_is_between_inclusive(542, 1000, new_w)) {
        // contact.style.textAlign = 'left'
        // links.style.textAlign = 'left'
      }
      if (this.num_is_between_inclusive(1001, 5000, new_w)) {
        // contact.style.textAlign = 'left'
        // links.style.textAlign = 'left'
      }


    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>


<style scoped>


#outside_footer {
  width: 100vw;
}

@media (min-width: 100px) {

  .spacer{
     height: 75%
  }


  #outside_footer {
    height: 70px;
    width: 100vw;
  }


  #footer_links {
    color: white;
    padding: 30px;
    line-height: 20px;
    box-sizing: border-box
  }

  #footer_links p {
    font-size: 15px;
  }

  #footer_copyright p {
    font-size: 15px;
    /*margin-bottom: 20px;*/

  }

  #footer_wrapper{
    /*padding-top: 20px;*/
  }

  #footer_contact {
    color: white;
    padding: 30px;
    line-height: 20px;
    box-sizing: border-box
  }

  #footer_contact p {
    font-size: 15px;
  }

  #footer_line {
    border-style: solid;
    border-color: var(--new-primary-purple_10);
    border-width: 1px;
    border-radius: 10px;
    width: 85%;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: var(--new-primary-purple);
  }

  #footer_links {
    text-align: center;
  }

  #footer_contact {
    text-align: center;
  }
}


@media (min-width: 352px) {
  /*#outside_footer {*/
  /*  height: 650px;*/
  /*  width: 100vw;*/
  /*}*/
}

@media (min-width: 500px) {

}


@media (min-width: 541px) {

}

@media (min-width: 1200px) {

  #footer_links {
    text-align: left;
    margin-right: 100px;
    margin-left: 47px;
  }

  #footer_contact {
    text-align: left;
  }

  /*#outside_footer {*/
  /*  height: 650px;*/
  /*  width: 100vw;*/
  /*}*/

  #footer_copyright {
    padding-top: 30px;
  }

}

@media (min-width: 2000px) {


  /*#outside_footer {*/
  /*  height: 480px;*/
  /*  width: 100vw;*/
  /*}*/

  #footer_links {
    text-align: left;
    margin-right: 30px;
    margin-left: 47px;
  }

  #footer_contact {
    padding-left: 28px;
  }

  #footer_contact p:first-child {
    margin-bottom: 10px;
  }

  #footer_links p {
    font-size: 16px;
  }

  #footer_contact p {
    font-size: 16px;
  }

  #footer_links p:first-child {
    margin-bottom: 10px;
  }

}

@media (min-width: 3000px) {
  #outside_footer {
  }


}
</style>