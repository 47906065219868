<template>
  <div class="">

<!--    <basic_page_infrastructure-->
<!--        @local_page_update="do_nothing"-->
<!--        @continue_clicked="do_nothing"-->
<!--    ></basic_page_infrastructure>-->

    <section>
       <h5 class="padding_t_20 margin_l_10">Potential Discounts</h5>
          <potential_discount_item
              :discount_values=discount_values
          ></potential_discount_item>

    </section>

  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import outside_banner_std from "@/components/parts/outside_banner_std.vue";
import outside_footer from "@/components/parts/outside_footer.vue";
import inside_banner from "@/components/parts/inside_banner.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket
} from "@/library";
import {Database} from "@/client_db";
import Potential_discount_item from "@/components/Vortex/discount_pages/potential_discount_item.vue";

export default {
  name: "potential_discount_widget",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
      "button_text", "discount_values"
  ],
  components: {
    Potential_discount_item,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      // potential_discount_values: [
      //   {
      //     id: "CCC353531",
      //     min_hours: 30,
      //     amount: "326.36",
      //     creation: '2024-05-08',
      //     expires: '2024-11-08',
      //   },
      //     {
      //     id: "DDD658465",
      //     min_hours: 30,
      //     amount: "306.36",
      //     creation: '2024-05-07',
      //     expires: '2024-11-07',
      //   },
      // ],
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w){
        if(new_w > this.max_mobile_size){
            return false
        }else{
            return true
        }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
computed: {
...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
      id() {
        return this.id_
      },
      spinner_up() {
        return store.getters.get_spinner_state
      },
      good_token() {
        return good_token_now()
      },

      mobile_mode() {
        return this.mobile_mode_x
      },

      button_height(){
        return this.button_height_
      },
      button_width(){
        return this.button_width_
      },
    },
watch: {
      window_width(new_w) {
        this.run_window_width_adjustments(new_w)
      },
      async socket_connection_counter(old_val, new_val) {
         await prime_socket(this.local_message_handler)
    },
    },
}
</script>
<style scoped>
@import '../../../common.css';
@media (min-width: 100px) {
  h1 {

  }
  h2 {

  }
  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }
  h2 {

  }
  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }
  h2 {

  }
  h3 {

  }
}

@media (min-width: 2000px) {
}
h1 {

  }
  h2 {

  }
  h3 {

  }
</style>