<template>
  <iframe :src=video_src :width=v_width :height=v_height
          webkitallowfullscreen
          mozallowfullscreen allowfullscreen></iframe>
</template>

<script>
import store from "@/store";

export default {
  name: "video_dynamic",
  emits: ["bg_clicked"],
  props: ['vimeo_id', 'base_height', 'base_width'],
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  unmounted() {
  },
  data() {
    return {
      desktop_cutoff: 700,
      mobile_mode_x: true,
      v_def_height: this.base_height,
      v_def_width: this.base_width,
      v_scale: 1,
      video: {
        height: "",
        width: ""
      },
    }
  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
    windowHeight() {
      // return store.state.windowHeight;
      return window.innerHeight
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
    video_src() {
      return `https://player.vimeo.com/video/${this.vimeo_id}`
    },
  },
  methods: {
    get_height(wid, base_h, base_w){
      return wid * (base_h/base_w)
    },
    get_width(hei, base_h, base_w){
      return hei * (base_w/base_h)
    },
    scale_video(current_h, current_w, base_h, base_w, max_on_critical = .8) {
      //return h, w
      // let critical
      let mode
      let calc_h
      let calc_w
      if (current_w < current_h) {
        //if width is less than height, then this is portrait mode
        mode = 'portrait'
        // console.log("mode is portrait")
        // critical = current_w
      } else {
        mode = 'landscape'
        // console.log("mode is landscape")
        // critical = current_h
      }

      if(mode === 'portrait'){
         calc_w = current_w * max_on_critical
         calc_h = this.get_height(calc_w,base_h,base_w)

        if(calc_h > current_h){
          calc_h = current_h * max_on_critical
          calc_w = this.get_width(calc_h, base_h, base_w)
        }

      }else{
        //landscape mode
        calc_h = current_h * max_on_critical
        calc_w = this.get_width(calc_h, base_h, base_w)
        if(calc_w > current_w){
          calc_w = current_w * max_on_critical
          calc_h = this.get_height(calc_w, base_h, base_w)
        }
      }
      return {
        height: calc_h,
        width: calc_w
      }
    },

    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")

      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

       console.log(`Results before scaling`)
       console.log(`WindowHeight: ${this.windowHeight}`)
       console.log(`windowWidth: ${this.windowWidth}`)
       console.log(`v_def_height: ${this.v_def_height}`)
       console.log(`v_def_width: ${this.v_def_width}`)



      let res = this.scale_video(this.windowHeight, this.windowWidth,this.v_def_height,this.v_def_width)

       console.log(`Results after scaling`)
       console.log(`res.height: ${res.height}`)
       console.log(`res.width: ${res.width}`)

      this.video.height = res.height
      this.video.width = res.width
    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
  },
  watch: {
    windowWidth(new_w) {
      console.log("Width just changed.")
      this.run_window_width_adjustments(new_w)
    },
    windowHeight(new_x) {
      console.log("Height just changed.")
      this.run_window_width_adjustments(new_x)
    },

  },
}
</script>

<style scoped>

</style>