<template>

  <universal_pop_up_slot
      :show_popup="spinner_up"
      @universal_popup_bg_clicked="do_nothing"
      :backer_color="colors.transparent"
      :no_blur="false"
  >

    <div class="promotes_popup_sizing">
      <div class=" flex_centered_column b_rad_25px">

        <div class="flex_centered_column">
          <div class="flex_centered_row" @click="do_nothing">
            <beat-loader
                :loading="true"
                :color="colors.primary_purple"
                :size=spinner_size
            >
            </beat-loader>
          </div>

        </div>
      </div>
    </div>


  </universal_pop_up_slot>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import {colors, sleep, close_spinner, spinner_state} from "@/library";
import store from "@/store";

export default {
  name: "spinner",
  props: [
    "spinner_up",
    "width"
  ],
  emits: ["network_problem"],
  mounted() {

  },
  updated() {
    // console.log("spinner has updated........................................")
  },
  data() {
    //this spinner does not re-render, so these size changes do not seem to have any effect.
    return {
      colors: colors,
      loading_: false,
      spinner_size_: 25,
    }
  },
  methods: {
    do_nothing() {
      console.log("You can't close the spinner manually.")
    },


    async click_timer() {
      // console.log("Inside click timer function.")
      let how_many_seconds_to_wait = 5
      for (let i = 0; i < how_many_seconds_to_wait; i++) {
        await sleep(i * 1000);
        console.log(`Just finished waiting on second number ${i + 1}`)
        if(!spinner_state()){
          break
        }
      }
      if (spinner_state()) {
        close_spinner()
        store.commit('error_enqueue', "Looks like a bad network connection or Tiny Magic Hat is down. Sorry.")
      }
    },
    run_window_width_adjustments(new_w) {
      if (new_w >= 280) {
        this.spinner_size_ = 25
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        // this.mobile_mode_x = false
      }
      if (new_w >= 1200) {
        this.spinner_size_ = 50
      }
      if (new_w >= 2000) {
        //do something
      }
    },
  },
  components: {
    universal_pop_up_slot,
    BeatLoader
  },
  computed: {

    loading() {
      return this.loading_
    },
    spinner_size() {
      return this.spinner_size_ + "px"
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    spinner_up(value) {
      // console.log(value)
      console.log(`Spinner up has changed to ${value}`)
      if (value === true) {
        this.click_timer()
      } else {
        //pass
      }
    },
  },
}
</script>

<style scoped>

</style>