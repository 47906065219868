<template>
  <div class="core_c">

    <section>
      <label_with_input_text_area
          class="margin_tb_10"
          ref="ad_headline_text"
          :ro_status="false"
          :show_edit_button="false"
          label="Write your ad headline."
          :label_color="colors.black"
          label_text_size="20px"
          :value_bg_color_ready="colors.morgie_gray"
          :value_bg_color_not_ready="colors.eli_gray"
          :value_text_color_ready="colors.black"
          :value_text_color_not_ready="colors.black"
          value_text_size_ready="15px"
          value_text_size_not_ready="15px"
          char_length_event="headline_length"
          @headline_length="check_headline_length"
          value=""
          rows="2"
          columns="30"
          placeholder_text="Ad Headlines can be up to 50 characters."
          placeholder_text_not_ready="Headlines can be up to 50 characters."
          @edit_button_clicked="do_nothing"
      ></label_with_input_text_area>
    </section>

    <section>
      <label_with_input_text_area
          class="write_the_body"
          ref="ad_body_text"
          :ro_status="false"
          :show_edit_button="false"
          label="Write the body text of your ad."
          :label_color="colors.black"
          label_text_size="20px"
          :value_bg_color_ready="colors.morgie_gray"
          :value_bg_color_not_ready="colors.eli_gray"
          :value_text_color_ready="colors.black"
          :value_text_color_not_ready="colors.black"
          value_text_size_ready="15px"
          value_text_size_not_ready="15px"
          char_length_event="ad_body_length"
          @ad_body_length="check_body_length"
          value=""
          rows="10"
          columns="30"
          placeholder_text="Ad body can be up to 500 characters."
          placeholder_text_not_ready="Ad body can be up to 500 characters."
          @edit_button_clicked="do_nothing"
      ></label_with_input_text_area>


    </section>

    <div class="flex_centered_row">
      <basic_text_button
          class="padding_lr_6 padding_tb_6 margin_t_20 what_is_video_button"
          :hover_color=colors.primary_purple
          :hover_text_color=colors.white
          :button_state=update_ready
          :button_color="colors.new_cyanic_mid"
          :text_color=colors.darker_gray
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="190px"
          border_rad_pct="0"
          text="Update Text"
          text_size="17px"
          :bold="true"
          event_name="text_update"
          @text_update="text_update"
      ></basic_text_button>
    </div>


  </div>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket, isEmpty
} from "@/library";
import {Database} from "@/client_db";
import label_with_input_text_area from "@/components/parts/label_with_input_text_area.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";

export default {
  name: "headline_and_body_widget",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text", "current_data"
  ],
  emits: ['text_update'],
  components: {
    basic_text_button,
    label_with_input_text_area,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      form_state: {},
      headline: "",
      body: "",
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },

    get_old_or_new_text(){
      let h = null
      let b = null
      if(this.headline.length >= 5){
        h = this.headline
      }
      if(!h){
        if(!isEmpty(this.current_data.headline)){
          if(this.current_data.headline.length >= 5){
            h = this.current_data.headline
          }else{
            return null
          }
        }
      }

      if(this.body.length >= 10){
        b = this.body
      }
      if(!b){
        if(!isEmpty(this.current_data.body)){
          if(this.current_data.body.length >= 10){
            h = this.current_data.body
          }else{
            return null
          }
        }
      }

      return {'headline': h, 'body': b}
    },


    text_update(e) {
      if(!this.update_ready){
        return null
      }
      console.log(e)
      if(this.update_ready){
        let update_res = this.get_old_or_new_text()
         console.log("Emiting text update from headline and body widget.")
        console.log(update_res)
        this.$emit("text_update", update_res)
      }else {
        store.commit("notification_enqueue", "Minimum text length requirements have not been met.")
      }

    },

    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },


    check_headline_length(state) {

      // console.log(`Headline Text: ${state}`)
      this.headline = state.substring(0, 50)
      // this.ad_ready.headline = state.length >= 5;
      if (state.length >= 5) {
        this.form_state.headline = true
      } else {
        this.form_state.headline = false
      }

      // console.log(`Headline State is ${this.form_state.headline}`)

    },
    check_body_length(state) {
      this.body = state.substring(0, 500)
      // console.log(`Body Text: ${state}`)
      if (state.length >= 10) {
        this.form_state.body = true
      } else {
        this.form_state.body = false
      }
      // console.log(`Body State is ${this.form_state.body}`)

    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    update_ready(){
      if(this.form_state.body || this.form_state.headline){
        return true
      }else{
        return false
      }
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>