<template>

  <section class="screen_block bg_whitesmoke flex_centered_column">
    <div class="label_bolder   margin_t_20">Update Agency Landing</div>
    <label_with_input_text_area
            class="margin_tb_10"
            ref="landing_headline_text"
            :ro_status="false"
            label="Write your landing page headline."
            :label_color="colors.black"
            label_text_size="20px"
            :value_bg_color_ready="colors.morgie_gray"
            :value_bg_color_not_ready="colors.eli_gray"
            :value_text_color_ready="colors.black"
            :value_text_color_not_ready="colors.black"
            value_text_size_ready="15px"
            value_text_size_not_ready="15px"
            char_length_event="headline_length"
            @headline_length="save_landing_headline"
            :value=landing_headline
            rows="2"
            columns="30"
            placeholder_text="Sunshine Homecare Anytown"
            placeholder_text_not_ready="Headlines can be up to some number characters."
            @edit_button_clicked="do_nothing"
        ></label_with_input_text_area>

         <label_with_input_text_area
            ref="landing_body_text"
            class=""
            :ro_status="false"
            label="Write the body text of your landing page."
            :label_color="colors.black"
            label_text_size="20px"
            :value_bg_color_ready="colors.morgie_gray"
            :value_bg_color_not_ready="colors.eli_gray"
            :value_text_color_ready="colors.black"
            :value_text_color_not_ready="colors.black"
            value_text_size_ready="15px"
            value_text_size_not_ready="15px"
            char_length_event="ad_body_length"
            @ad_body_length="save_landing_body"
            :value=landing_body
            rows="10"
            columns="30"
            placeholder_text="Tell your agency story with up to 5000 characters."
            placeholder_text_not_ready="A landing page body can be up to 5000 characters."
            @edit_button_clicked="do_nothing"
        ></label_with_input_text_area>

         <div class="flex_centered_row flex_align_items_center ">
          <basic_toggle ref="ad_launch_toggle"
                        class="margin_lr_10"
                        uuid=""
                        :state_disabled="!landing_ready_to_update"
                        @toggle-state_true="toggle_is_true"
                        @toggle-state_false="toggle_is_false"
          ></basic_toggle>
          <basic_text_button
              class="margin_b_20 margin_t_10"
              :button_state="landing_ready_to_update && toggle_state"
              :hover_color="colors.new_cyanic_main"
              :button_color="colors.new_cyanic_main"
              :text_color="colors.black"
              :button_color_not_ready="colors.gray"
              :button_not_ready_text_color="colors.morgie_gray"
              button_height="80px"
              button_width="80px"
              border_rad_pct="50%"
              text="Publish Preview"
              button_not_ready_text="Publish Preview"
              text_size="14px"
              :bold="true"
              event_name="save_agency_landing"
              @save_agency_landing="save_landing_content"
          ></basic_text_button>
        </div>

<!--    <div class="text_20 font_raleway font_weight_800 margin_tb_20">Create Landing Body</div>-->

  </section>


</template>

<script>
import label_with_input_text_area from "@/components/parts/label_with_input_text_area";
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import {colors} from "@/library";
import store from "@/store";
export default {
  name: "update_agency_landing",
  components: {
    label_with_input_text_area,
    basic_toggle,
    basic_text_button
  },
  props: [],
  data(){
    return {
      colors: colors,
      // publish_landing_ready_: false,
      landing_ready: {
        headline: false,
        body: false,
        hl_len: 0,
        body_len: 0
      },
      local_toggle_state: false
    }
  },
  methods: {
    do_nothing(){
      console.log("doing nothing.")
    },
    save_landing_content(){
      // let hl = this.landing_headline
      // let body = this.landing_body

      // this.save_landing_headline(hl)
      // this.save_landing_body(body)
      // console.log("what is the saved content?")
      // console.log()
      store.commit("spyglass_set_published_to_true", true)
      store.commit("spyglass_agency_landing_set_ready_to_save", true)
      this.toggle_is_false()
      this.$emit('landing_content_updated', true)
    },
    save_landing_headline(content){
      let shorter = content.substring(0, 75)
      store.commit('spyglass_set_agency_landing_headline', shorter)
      store.commit('spyglass_set_published_to_false', false)
    },

    save_landing_body(content){
      let shorter = content.substring(0,5000)
      store.commit('spyglass_set_agency_landing_body', shorter)
      store.commit('spyglass_set_published_to_false', false)
    },
     check_headline_length(state) {
      // console.log(`Headline Length: ${state}`)
      this.landing_ready.headline = state >= 10;
    },

    check_body_length(state) {
      // console.log(`Body Length: ${state}`)
      this.landing_ready.body = state >= 10;
    },
     toggle_is_true() {
      this.local_toggle_state = true
       this.$refs.ad_launch_toggle.toggle_state_to_true()
    },
    toggle_is_false() {
      this.local_toggle_state = false
      this.$refs.ad_launch_toggle.toggle_state_to_false()
    },

  },
  computed: {

    toggle_state(){
       return this.local_toggle_state
      // try{
        //   console.log("Check toggle state.")
        //   console.log(this.$refs.ad_launch_toggle.toggle_state)
        //   return this.$refs.ad_launch_toggle.toggle_state
        // }catch(error){
        //   return false
        // }

    },
     headline_is_ready(){
       try{
         // let len = this.$refs.landing_headline_text.text_value().length
         let len = store.getters.spyglass_get_agency_landing_headline.length
         return len >= 10;
       }catch (error){
         return false
       }
     },
    body_is_ready(){
      try{
         // let len = this.$refs.landing_body_text.text_value().length
         let len = store.getters.spyglass_get_agency_landing_body.length
         return len >= 10;
       }catch (error){
         return false
       }

     },
    //  publish_landing_ready() {
    //   return this.publish_landing_ready_
    // },
    // landing_ready_to_publish() {
    //   return this.landing_ready.headline && this.landing_ready.body
    // },
     landing_ready_to_update() {
      return this.body_is_ready && this.headline_is_ready
    },
    landing_body(){
       return store.getters.spyglass_get_agency_landing_body
    },
    landing_headline(){
       return store.getters.spyglass_get_agency_landing_headline
    },
    // toggle_is_disabled(){
    //    if(!this.landing_ready_to_publish()){}else{}
    // },

  },
}
</script>

<style scoped>

</style>