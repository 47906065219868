<template>
  <div class="flex_centered_row main_wrapper">
    <div class="flex_centered_column">

      <div class="flex_centered_row core_wrapper">
        <!--        <div class="flex_centered_start_column left_side_col_wrapper">-->
        <!--          <div class="b_rad_50 icon_bg_circle flex_centered_row" :id="this.icon_bg_id">-->
        <!--            <div class="flex_centered_column">-->
        <!--              <span class="material-symbols-outlined material_icon p_abs" :id="this.icon_id"></span>-->
        <!--            </div>-->
        <!--          </div>-->

        <!--        </div>-->

        <div class="flex_centered_start_column" >
          <div class="material_icon_bg flex_centered_column" :id="this.icon_bg_id" >
            <div class="flex_centered_row">
              <img class="material_icon" :id="this.icon_id"
                   :src="image_name"
                   :alt=desc>
            </div>
          </div>

        </div>

        <div class="flex_left_aligned_column right_side_col_wrapper">
          <slot name="body"></slot>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import store from "@/store";

export default {
  name: "feature_card4",
  props: [
    "bg_color",
    "icon",
    "desc",
    "icon_color",
    "icon_bg_color",
    "icon_scale"
  ],
  async mounted() {
    this.icon_id = uuidv4();
    this.material_icon_name = this.icon
    this.icon_bg_id = uuidv4()
    this.main_wrapper_id = uuidv4()


    this.run_window_width_adjustments(this.windowWidth)
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        let icon
        icon = document.getElementById(this.icon_id)
        // icon.textContent = this.material_icon_name

        let icon_bg = document.getElementById(this.icon_bg_id)
        // let wrapper = document.getElementById(this.main_wrapper_id)

        // icon.style.color = this.icon_color
        icon_bg.style.backgroundColor = this.icon_bg_color
        if (this.icon_scale) {
          console.log("Icon height is")
          console.log( icon.style.height)
          icon.style.height = (this.icon_std_height * this.icon_scale) + "px"
          icon.style.width =  (this.icon_std_width * this.icon_scale) + "px"
        } else {
          icon.style.height = (this.icon_std_height) + "px"
          icon.style.width =  (this.icon_std_width) + "px"
        }


        // icon_bg.style.backgroundColor = this.icon_bg_color
        // wrapper.style.backgroundColor = this.bg_color
      })
    } catch (error) {
      //pass
    }
  },
  data() {
    return {
      material_icon_name: "",
      icon_id: "",
      icon_bg_id: "",
      main_wrapper_id: "",
      scale_icon: 1,
      icon_std_width: 30,
      icon_std_height: 30
    }
  },
  methods: {
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 369, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "16px"
        // this.v_scale = .65
        // this.video.height = this.v_def_height * this.v_scale
        // this.video.width = this.v_def_width * this.v_scale
        //  if (this.icon_scale) {
        //   let icon = document.getElementById(this.icon_id)
        //   console.log("Icon height is")
        //   console.log( icon.style.height)
        //   icon.style.height = ( icon.style.height * this.icon_scale) + "px"
        // } else {
        //   icon.style.width =  ( icon.style.widt * this.icon_scale) + "px"
        // }

      }
      if (this.num_is_between_inclusive(370, 399, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(400, 800, new_w)) {
        this.button_wid_ = "175px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(501, 1499, new_w)) {
        this.button_wid_ = "180px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }
      if (this.num_is_between_inclusive(1500, 6000, new_w)) {
        this.button_wid_ = "190px"
        this.button_height_ = "40px"
        this.button_text_size_ = "17px"

      }


    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
  },
  computed: {
    image_name() {
      if (this.material_icon_name !== '') {
        return require(`@/assets/${this.material_icon_name}`);
      } else {
        return ""
      }
    },
    windowWidth() {
      return store.state.windowWidth;
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {

  .main_wrapper {
    margin-bottom: 5px;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
  }

  .right_side_col_wrapper {
    margin-left: 20px;
  }

  .core_wrapper {
    width: 485px;
    max-width: 93vw;
  }

  .material_icon_bg {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .material_icon {
    height: 30px;
    width: 30px;
  }
}

@media (min-width: 400px) {
  .main_wrapper {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

@media (min-width: 1400px) {
  .material_icon {
    /*height: 55px;*/
    width: 44px;
  }
}

</style>