<template>
  <div class="carousel">
    <div class="carousel-inner b_rad_20px font_weight_800 font_raleway" :id="carousel_inner_body">
      <carousel_item
          v-for="(c_item, index) in slides" :ref="setItemRef" :key="c_item.id"
          :item=c_item
          :current_slide="current_slide"
          :index=index
          :direction=direction
      ></carousel_item>
      <CarouselIndicators
          v-if="desktop_mode"
          class="carousel-indicators"
          :total=slides.length
          :current-index=current_slide
          @switch="switchSlide"
      ></CarouselIndicators>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import selection_indicator from "@/components/parts/selection_indicator";
import carousel_item from "@/components/parts/my_carousel/carousel_item";
import CarouselIndicators from "@/components/parts/my_carousel/CarouselIndicators";
import {colors, get_uuid, num_is_between_inclusive} from "@/library";
import store from "@/store";

export default {
  name: "my_carousel",
  props: {
    slides: {
      type: Array,
      required: true,
    },

    interval: {
      type: Number,
      default: 5000,
    },
    body_color: {
      type: String,
      default: colors.cyanic_light
    }
  },

  async mounted() {
    // console.log("in my carousel mounted")
    // console.log(this.slides)
    this.carousel_inner_body = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
     // console.log(`Body color is ${this.body_color} before the tick`)

    await this.$nextTick(function () {
      try {

        // console.log(`Body color is ${this.body_color} after the tick`)
        if (this.body_color) {
          let b = document.getElementById(this.carousel_inner_body)
          b.style.backgroundColor = this.body_color
        }

      } catch (error) {
        console.error(error)
      }

    })





    //This has to be an ES6 function. Old-style does not work.
    this.slide_interval = setInterval(() => {
      const index = this.current_slide < this.slides.length - 1 ? this.current_slide + 1 : 0;
      this.set_current_slide(index);
    }, this.interval)
  },


  beforeUnmount() {
    clearInterval(this.slide_interval)
  },
  beforeUpdate() {
    this.itemRefs = []
  },
  components: {
    selection_indicator,
    carousel_item,
    CarouselIndicators
  },
  data() {
    return {
      carousel_inner_body: "",
      itemRefs: [],
      current_slide: 0,
      slide_interval: null,
      direction: "right",
      desktop_cutoff: 700,
      mobile_mode_x: true,
    }
  },
  methods: {
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // this.feature_card_logo_width.freetime = "125px"
        // this.feature_card_logo_width.spyglass = "125px"
        // this.feature_card_logo_width.speedydelivery = "125px"
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // this.feature_card_logo_width.freetime = "150px"
        // this.feature_card_logo_width.spyglass = "150px"
        // this.feature_card_logo_width.speedydelivery = "150px"
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // this.feature_card_logo_width.freetime = "210px"
        // this.feature_card_logo_width.spyglass = "210px"
        // this.feature_card_logo_width.speedydelivery = "210px"
      }


    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },
    set_current_slide(index) {
      this.current_slide = index
    },
    prev(step = -1) {
      const index =
          this.current_slide > 0
              ? this.current_slide + step
              : this.slides.length - 1;
      this.set_current_slide(index);
      this.direction = "left";
      this.startSlideTimer();
    },
    _next(step = 1) {
      const index =
          this.current_slide < this.slides.length - 1
              ? this.current_slide + step
              : 0;
      this.set_current_slide(index);
      this.direction = "right";
    },
    next(step = 1) {
      this._next(step);
      this.startSlideTimer();
    },
    startSlideTimer() {
      this.stopSlideTimer();
      this.slide_interval = setInterval(() => {
        this._next();
      }, this.interval);
    },
    stopSlideTimer() {
      clearInterval(this.slide_interval);
    },
    switchSlide(index) {
      console.log(`in switch slide with ${index} and current slide of ${this.current_slide}`)
      const step = index - this.current_slide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
  },
  computed: {
     windowWidth() {
      return store.state.windowWidth;
    },
    desktop_mode(){
       return !this.mobile_mode_x
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>
@media (min-width: 100px) {
  .carousel-inner {
    font-size: 20px;
    position: relative;
    max-width: 800px;
    width: 95vw;
    height: 250px;
    overflow: hidden;
    background-color: var(--cyanic-blue);
    /*background-color: var(--cyanic-blue);*/
  }



}


@media (min-width: 800px) {
  .carousel {
    display: flex;
    justify-content: center;
  }

  .carousel-inner {

    font-size: 30px;
    position: relative;
    overflow: hidden;
     height: 300px;
    /*background-color: var(--cyanic-blue);*/
  }

  .carousel-indicators {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 7%;
    z-index: 2;
  }


}
@media (min-width: 2000px) {
  .carousel-inner{
    max-width: 1200px;
  }
}


</style>