<template>
  <module_main_page
      module_download_name="b381d25d-ccaf-41c8-8774-65d9acdacf65"
      module_logo_name="sd_fall_risk.svg"
      :module_desc_text=module_text
      module_headline="Understanding Fall Risk Factors"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "fall_risk",
  data() {
    return {
      module_text: "Caregivers need to be acutely aware of their clients' fall risk and take proactive measures to prevent accidents. A fall can have severe consequences, especially for older individuals or those with certain medical conditions. Caregivers should assess the client's individual risk factors, which can include mobility issues, muscle weakness, balance problems, medication side effects, vision impairment, and environmental hazards. Understanding these factors allows caregivers to tailor care plans accordingly. Overall, a caregiver's vigilance and knowledge of client fall risk are vital for maintaining a safe and secure home environment.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



