<template>

  <div class="screen_block">
    <div class="flex_centered_row">
      <div class="flex_centered_row padding_t_10 label_regular">
        {{ label }}
      </div>
    </div>

    <div class="flex_space_around_row flex_wrap padding_tb_5">

      <div class="flex_left_aligned_column">
        <input class="margin_tb_10 b_rad_10" v-model="candidate1_" :placeholder=selection_title1>


        <drop_down_select class="padding_tb_10" ref="drop_down_child"
                          :title="selection_title2"
                          :values="values"
                          @value-selected="candidate2_selected"
        >
        </drop_down_select>

      </div>

      <div class="flex_space_around_row">

        <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :toggle_set_value="toggle_start_value"
                      :state_disabled="!(candidate1 && candidate2)"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        >
        </basic_toggle>

        <launch_button
            ref="button_child"
            :uuid="child_button_uuid"
            :button_state_start=button_state_start
            :button_text=button_text
            @launch="launch"
        >
        </launch_button>
      </div>
    </div>
  </div>


</template>

<script>
import drop_down_select from "@/components/parts/drop_down_select";
import basic_toggle from "@/components/parts/basic_toggle";
import launch_button from "@/components/parts/launch_button";
import {v4 as uuidv4} from "uuid";

export default {
  name: "type_select_and_launch",
  props: ['label','label_color', 'event_name', 'selection_title1', 'selection_title2',
    'button_text', 'values', 'text_entry', 'precheck_event_name', 'toggle_start_value'],
  components: {
    basic_toggle,
    drop_down_select,
    launch_button
  },
   async mounted() {
    // console.log("running mounted")
    // let bg = this.$el
    let label = this.$el.querySelector('.label_regular')
    label.style.color = this.label_color
  },
  data() {
    return {
      // launcher_armed_: false,
      candidate1_: this.text_entry,
      candidate2_: "",
      button_state_start: false,
      child_button_uuid: uuidv4(),
      child_toggle_uuid: uuidv4()
    }
  },
  methods: {
    toggle_is_true() {
      console.log("Child toggle reports true, emitting precheck.")
      this.$emit('precheck', this.candidate1, this.candidate2, this.uuid)
    },
    toggle_is_false() {
      console.log("Child toggle reports false")
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_state_to_false();
    },
    launch() {
      // console.log("in Launch")
      this.$emit(this.event_name, this.candidate1, this.candidate2)
    },
    reset() {
      // console.log("inside select and launch reset")
      this.candidate1_ = ""
      this.candidate2_ = ""
      this.$refs.drop_down_child.reset();
      this.$refs.toggle_child.toggle_toggle();

    },


    toggle_state_to_false() {
      this.$refs.toggle_child.toggle_state_to_false();
    },
    toggle_state_to_true() {
      this.$refs.toggle_child.toggle_state_to_true();
    },
    button_state_to_false() {
      this.$refs.button_child.button_state_to_false();
    },
    button_state_to_true() {
      this.$refs.button_child.button_state_to_true();
    },

    toggle_state_of_toggle() {
      this.$refs.toggle_child.toggle_state_of_toggle()
    },
    toggle_state_of_button() {
      this.button_state_ = !this.button_state_
    },

    candidate1_selected(state) {
      this.candidate1_ = state
      if (state === `---- ${this.selection_title1} ----`) {
        this.candidate1_ = ""
      }
    },
    candidate2_selected(state) {
      this.candidate2_ = state
      if (state === `---- ${this.selection_title2} ----`) {
        this.candidate2_ = ""
      }
    },


  },
  computed: {


    button_state() {
      return this.button_state_
    },
    candidate1() {
      return this.candidate1_
    },
    candidate2() {
      return this.candidate2_
    },

  },
  watch: {

    armed(new_value) {
      //if launcher is armed and both candidates have been selected...
      if (new_value && this.candidate1 && this.candidate2) {
        this.$emit("precheck_values", this.candidate1, this.candidate2)

      }
    }
  }
}
</script>

<style scoped>

</style>