<template>
  <div class="flex_centered_column padding_b_5" :id="the_whole">
    <div class="font_weight_800 font_raleway " :id="the_label">{{ label }}</div>
    <div v-if="value" class="padding_t_10 margin_b_10 value font_raleway" :id="the_value">{{ value }}</div>
  </div>

</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "top_label_value_non_editable",
  props: [
    'label',
    'label_color',
    'label_size',
    'value',
    'bg_color',
    'value_color',
    'value_size',
    'value_bg_color',
    'value_width',
    'value_font_size',
  ],
  data() {
    return {
      the_label: "",
      the_value: "",
      the_whole: "",
    }
  },
  async mounted() {
    // console.log("inside top label mounted")
    this.the_label = uuidv4();
    this.the_value = uuidv4();
    this.the_whole = uuidv4();
    // console.log(`Bg color is ${this.bg_color}`)
    // console.log(`The whole uuid is ${this.the_whole}`)


     try {

      await this.$nextTick(function () {
        // console.log("inside top label mounted next tick")
        let the_label = document.getElementById(this.the_label)
        let the_value = document.getElementById(this.the_value)
        // let the_whole = document.getElementById(this.the_whole)
        // console.log(the_label)
        // console.log(the_value)
        // console.log(the_whole)

        // console.log("inside updated nextTick for top label value, and the color is...")
        // console.log(this.label_color)
        // console.log(this.the_label)
        the_label.style.color = this.label_color
        the_label.style.fontSize = this.label_size
        the_value.style.color = this.value_color
        the_value.style.fontSize = this.value_font_size
        the_value.style.background = this.bg_color
      })


    } catch (error) {
      // console.error("expected premature loading error.")
      // console.log(error)
    }



  },
  async updated() {
    try {

      await this.$nextTick(function () {
        // console.log("inside top label mounted next tick")
        let the_label = document.getElementById(this.the_label)
        let the_value = document.getElementById(this.the_value)
        // let the_whole = document.getElementById(this.the_whole)
        // console.log(the_label)
        // console.log(the_value)
        // console.log(the_whole)

        // console.log("inside updated nextTick for top label value, and the color is...")
        // console.log(this.label_color)
        // console.log(this.the_label)
        the_label.style.color = this.label_color
        the_label.style.fontSize = this.label_size
        the_value.style.color = this.value_color
        the_value.style.fontSize = this.value_font_size
        the_value.style.background = this.bg_color
      })


    } catch (error) {
      // console.error("expected premature loading error.")
      // console.log(error)
    }

  },

}
</script>

<style scoped>


.wide {
  /*min-width: 25%;*/
  max-width: 100%;
  /*min-width: 240px;*/

}

.backdrop {
}


.value {
  min-width: 3rem;
  font-weight: bold;
  font-size: .8rem;
  padding: .5rem;
  text-align: center;
  /*color: var(--light-text);*/
  margin-top: .5rem;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  /*background-color: gray;*/
  color: lightgray;
}
#the_label{
   white-space: nowrap;
}

</style>