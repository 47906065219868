<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>


    <div class="flex_centered_row margin_b_40">
      <div class="flex_centered_start_column">


        <div class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/istock/coffee_couch.jpg"
                     alt="Picture of a young brown-haired female sitting on a light gray couch wearing teal colored scrubs
                   and smiling while looking down at a notebook that she is holding. Beside her sits an elderly woman
                   with a light colored dress holding a cup and saucer, smiling and also looking at the notebook.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h3 class="text_align_center">So what sort of caregiving job are you looking for?</h3>
              <!--              <h3 class="text_align_center">$18+ per hour for Full Time Caregivers</h3>-->
              <p class="text_align_center top_text">

                Describe the job you want. If we come across a match, we'll contact you.
              </p>
            </div>
          </div>
        </div>

        <!--        <div class="below_carousel">-->
        <!--          <h3 class="text_align_center">Our local home care agencies:</h3>-->
        <!--        </div>-->

        <!--        <div class="market_box border_1">-->
        <!--          <div class="market">Myrtle Beach</div>-->
        <!--        </div>-->

        <!--        <h3>Coming Soon...</h3>-->
        <h3 class="text_align_center">Complete this form and Submit</h3>
        <!--        <p><strong>Complete this form and Submit.</strong></p>-->
        <div class="market_box border_1">
          <div class="market form_input">Market: Myrtle Beach</div>
        </div>

        <dropdown_datalist
            class="form_input"
            ref="job_type"
            label="Job Type"
            :data_list=p_or_f
            @value-selected="do_nothing"
            @emit_state="catch_job_type"
        ></dropdown_datalist>

        <dropdown_datalist
            class="form_input"
            ref="job_neighborhood"
            label="Closest Neighborhood"
            :data_list=neighborhoods_in_market
            @value-selected="do_nothing"
            @emit_state="catch_job_neighborhood"
        ></dropdown_datalist>

        <dropdown_datalist
            class="form_input"
            ref="job_days"
            label="Days"
            :data_list=days
            @value-selected="do_nothing"
            @emit_state="catch_job_days"
        ></dropdown_datalist>

        <dropdown_datalist
            class="form_input"
            ref="job_times"
            label="Times"
            :data_list=times
            @value-selected="do_nothing"
            @emit_state="catch_job_time"
        ></dropdown_datalist>

        <input_with_label
            class="form_input"
            ref="job_email"
            label="Email"
            @emit_state="catch_email"
        ></input_with_label>


        <input_with_label_text_area
            ref="job_details"
            class="form_input"
            place_holder_text="Are there any more specific details or restrictions that you may have?"
            :columns="columns_num"
            :rows="rows_num"
            @emit_state="catch_job_details"
        >
        </input_with_label_text_area>


        <basic_text_button
            class="margin_t_40"
            :button_state="true"
            :button_color="colors.primary_purple"
            :text_color="colors.white"
            :button_color_not_ready="colors.gray"
            :button_not_ready_text_color="colors.morgie_gray"
            :hover_color="colors.cyanic_blue"
            :hover_text_color="colors.white"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="SUBMIT"
            button_not_ready_text="Retire Ad"
            text_size="14px"
            :bold="true"
            event_name="save_job_request"
            @save_job_request="register"
        ></basic_text_button>

      </div>


    </div>

  </div>
  <outside_footer class="footer_c"></outside_footer>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import local_pda_featured from "@/components/orgs/pda/local_pda_featured";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import input_with_label_text_area from "@/components/parts/input_with_label_text_area";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  validate_phonenumber, dual_route,
  validate_email,
  be_main_address, parse_internal_json, get_ip_from_header, isEqual, num_is_between_inclusive, play_sound
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";
import {scroll_to_element} from "@/library";

export default {
  name: "job_request",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    // console.log("Current directory:", __dirname);
    // store.commit('notification_enqueue', `This is a test.`)
    this.id_ = get_uuid()
    this.scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)

    let ip_string = false
    ip_string = await get_ip_from_header()

    let default_market = 'Myrtle Beach'
    // this.update_approved_agency_names_in_market()
    // await this.populate_local_agency_data(default_market)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    local_pda_featured,
    dropdown_datalist,
    input_with_label_text_area

  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      name: '',
      password: '',
      email: '',
      job_request: {
        type: "",
        neighborhood: "",
        days: "",
        times: "",
        details: "",
        email: ""
      },
      p_or_f: ['Part-Time', 'Full-Time'],
      days: ['Weekdays', 'Weekends', 'Either'],
      times: ['Mornings', 'Afternoons', 'Evenings', 'Late Nights', 'Over Night'],

      neighborhoods_in_market: ['Myrtle Beach', 'Surfside', 'Murrells Inlet', 'Garden City',
        'Pawleys Island', 'Georgetown', 'Longs', 'North Myrtle Beach', 'Little River', 'Conway', 'Aynor', 'Other'],

      agencies_in_market: {
        'Myrtle Beach': ['rah1185']
      },
      columns_num_: 15,
      rows_num_: 5,
      job_email_request: "",
      approved_agencies_: [],
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 700,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
      // my_slides: [
      //   {text1: 'FreeTime bonuses have really make a big difference on my paycheck.', text2: 'Darcie P.', id: 1},
      //   {text1: 'Who\'s there?', text2: 'More text', id: 2},
      //   {text1: 'Tank.', text2: 'More text', id: 3},
      //   {text1: 'Tank who?', text2: 'More text', id: 4},
      //   {text1: 'You\'re welcome', text2: 'More text', id: 5},
      //   {text1: 'Oh, stop it, you\'re just killing me!', text2: '--Wyatt', id: 6},
      // ],

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    job_email_request_entered(email) {
      // console.log("caught email")
      // console.log(email)
      if (validate_email(email)) {
        // console.log("email is valid")
        return email

      } else if (email !== "") {
        // console.log("in the else if")
        store.commit('error_enqueue', `${email} does not appear to be a valid email address.`)
        return ""
      } else {
        console.log("in the else")
        return ""
      }
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    save_job_request() {
      let m = "Your request is almost ready. Now just create an account and log in and we'll look for a " +
          "matching caregiver job for you."
      store.commit('notification_enqueue', m)
    },

    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (num_is_between_inclusive(100, 679, new_w)) {
        console.log("inside the 100 to 679 set")

        this.columns_num_ = 15
        this.rows_num_ = 5
      }
      if (num_is_between_inclusive(680, 5000, new_w)) {
        // console.log("in the 500 set")
        console.log("setting to large")
        this.columns_num_ = 60
        this.rows_num_ = 10
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

    catch_job_type(name) {
      this.job_request.type = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },
    catch_job_neighborhood(name) {
      this.job_request.neighborhood = name.trim().slice(0, 100)
      // store.commit('save_name', this.name)
    },
    catch_job_days(name) {
      this.job_request.days = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },
    catch_job_time(name) {
      this.job_request.times = name.trim().slice(0, 25)
      // store.commit('save_name', this.name)
    },

    catch_email(name) {
      let email = this.job_email_request_entered(name)
      this.job_request.email = email.trim().slice(0, 100)
      // store.commit('save_name', this.name)
    },

    catch_job_details(name) {
      this.job_request.details = name.trim().slice(0, 500)
      // store.commit('save_name', this.name)
    },

    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async register() {
      console.log("Getting ready to launch register...")
      // let the_router = this.$router

      this.$refs.job_type.emit_state()
      this.$refs.job_neighborhood.emit_state()
      this.$refs.job_days.emit_state()
      this.$refs.job_times.emit_state()
      this.$refs.job_email.emit_state()
      this.$refs.job_details.emit_state()

      console.log(this.job_request.type)
      console.log(this.job_request.neighborhood)
      console.log(this.job_request.days)
      console.log(this.job_request.times)
      console.log(this.job_request.email)
      console.log(this.job_request.details)

      // this.$refs.register_email.emit_state()

      let error_out
      console.log("Start of the investigations")
      console.log(this.p_or_f)
      console.log(this.job_request.type)
      if (!this.p_or_f.includes(this.job_request.type)) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "Please select items from the drop down lists for job type.")
        error_out = true
      }
      if (!this.neighborhoods_in_market.includes(this.job_request.neighborhood)) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "Please select items from the drop down lists for the neighborhood.")
        error_out = true
      }
      if (!this.days.includes(this.job_request.days)) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "Please select items from the drop down lists for the days.")
        error_out = true
      }
      if (!this.times.includes(this.job_request.times)) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "Please select items from the drop down lists for the times.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        return null
      }

      console.log("Getting ready to make axios call.")
      let target = be_main_address('spyglass') + "job_request_email"
      console.log("create new user address")
      console.log(target)

      // console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, {
        job_type: this.job_request.type,
        neighborhood: this.job_request.neighborhood,
        days: this.job_request.days,
        times: this.job_request.times,
        details: this.job_request.details,
        email: this.job_request.email
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', " It looks like something went wrong with the chat message or the server is very busy.")
          console.log(error)
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          error_out = true
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])

            this.$refs.job_type.reset_state()
            this.$refs.job_neighborhood.reset_state()
            this.$refs.job_days.reset_state()
            this.$refs.job_times.reset_state()
            this.$refs.job_email.reset_state()
            this.$refs.job_details.reset_state()

            // if (response.data.error === false) {
            //
            //   this.$store.commit('save_name', this.name)
            //   this.$store.commit('save_phone', this.phone_number)
            //   this.name = ''
            //   this.phone_number = ''
            //   this.password = ''
            //   this.$router.push('/register_confirm');
            // } else {
            //   console.log("Something went wrong with register method.")
            //   console.log(response.data)
            // }
          }
      )

    },

    // async process_asset_package(asset_package) {
    //   // console.log("inside processing asset package.")
    //
    //   //save jwt authentication token in vuex
    //   this.$store.commit('save_id_token', asset_package['id_token'])
    //   this.$store.commit('save_refresh_token', asset_package['refresh_token'])
    //   // console.log('Printing token from store: ' + this.$store.getters.my_token);
    //
    //   // console.log("getting ready to parse the assets")
    //   let the_assets = parse_internal_json(asset_package['assets'])
    //   // console.log(JSON.stringify(the_assets))
    //   for (let [key, value] of Object.entries(the_assets)) {
    //     // console.log(`Adding ${key} to the database.`)
    //     await this.db.addKeyValue(key, value);
    //   }
    //
    //   // console.log("done with processing the asset package")
    // },

  },
  computed: {
    columns_num() {
      return this.columns_num_
    },
    rows_num() {
      return this.rows_num_
    },
    // approved_agencies() {
    //   return this.approved_agencies_
    // },
    // all_local_agency_data() {
    //   // return a nested dict like:
    //   //  {'rah': {'logo': 'aoeu','headline': 'aoeu','body': 'aoeu',}}
    //   return store.getters.get_spyglass_agency_landing_public_data_all
    // },
    // agency_names_in_market() {
    //   let market = 'Myrtle Beach'
    //   let res = []
    //   let required = ['logo', 'headline', 'body']
    //   let temp
    //   // let all_agencies_data = this.all_local_agency_data
    //   let agencies = this.agencies_in_market[market]
    //   console.log("Here are my agencies in market.")
    //   console.log(agencies)
    //
    //   for (const agency in agencies) {
    //     // console.log(`Looking at this agency: ${agency}`)
    //     console.log(`Looking at this agency: ${agencies[agency]}`)
    //     //Redefining agency because for some reason agencies is an object not an array.
    //     let agency = agencies[agency]
    //
    //     let agency_data = this.all_local_agency_data[agency]
    //     console.log(`Here is the agency data I have now.....`)
    //     console.log(agency)
    //     console.log(agency_data)
    //
    //     for (const key of required) {
    //       console.log(key);
    //       try {
    //           temp = agency_data[key]
    //       } catch (error) {
    //         console.log("Breaking on error")
    //          break
    //       }
    //     }
    //     if(agency !== undefined){
    //       res.push(agency)
    //     }
    //
    //   }
    //   console.log("So what did I end up with?")
    //   console.log(res)
    //   return res
    //
    // },
    // agency_text_sample() {
    //   return "Right at Home is a award-winning, family-owned homecare agency that has proudly served both Horry and Georgetown counties since 2008. We recently received the 2022 Best of Home Care® Employer of Choice Award from Home Care Pulse®. Our caregivers are the heart of our business and we are so grateful to be recognized for providing a first-rate employee experience to caregivers."
    // },
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },

    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../common.css';


header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .form_input {
    width: 273px;
  }


  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    /*margin-top: -30px;*/
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .market_box {
    font-size: 20px;
    border-color: var(--eli_gray);
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 30px;

  }

  .market {
    font-size: 22px;
    font-weight: 800;
    padding: 10px;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}

@media (min-width: 680px) {
  .form_input {
    width: 600px;
  }

  #cg_topper_img {
    width: 350px;
  }
}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 350px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {

  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 650px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 500px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0;
  }

  .top_text {
    max-width: 850px;
  }


}

@media (min-width: 2000px) {


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;
  }

  #cg_topper_img {
    width: 800px;
  }

}

</style>