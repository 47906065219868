<template>

  <universal_pop_up_slot
      :show_popup="notifications_available"
      @universal_popup_bg_clicked="local_dequeue_notification_message"
      :backer_color="colors.near_transparent"
  >

    <section>
      <div class="flex_start_row notification_box font_raleway font_weight_800 z_500 cursor_ptr " @click="local_dequeue_notification_message()">
        <div class="flex_centered_column">
           <img class="material_icon"
                         :src="image_name('tips_and_updates.svg')"
                         alt="Notification Icon">
        </div>
        <div class="flex_centered_column scrollable_div_with_scrollbar">
          <div class="flex_centered_row"><span v-html="notification_peek"></span></div>

        </div>
        <div class="flex_right_aligned_column flex_grow" >
          <img class="cancel_x"
                         :src="image_name('red_not_x.svg')"
                         alt="Notification Icon">
        </div>
      </div>
    </section>
  </universal_pop_up_slot>


</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {Database} from "@/client_db";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import {colors, image_name} from "@/library";
export default {
  name: "notification_popup",
  beforeMount() {
    // console.log("Running beforeMount an notification popup component")
    this.db = new Database();
    store.dispatch('notification_restart')
  },
  components: {
    universal_pop_up_slot
  },
  data() {
    return {
      colors: colors,
    }
  },
  methods: {
     image_name(name){
      return image_name(name)
    },
    local_dequeue_notification_message() {
      store.dispatch('notification_dequeue')
    },
  },
  computed: {
    ...mapGetters([
      'notification_peek',
      'notification_length'
    ]),
    notifications_available() {
      if (this.notification_length) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped>

.notification_box{
  /*max-height: 30vh;*/
  /*height: 50vh;*/
}



@media (min-width: 100px) {

  .cancel_x {
    width: 25px;
    position: relative;
    top: -18px;
    left: 17px;
  }

  .notification_box {
    background-color: var(--notification-color);
    /*border: 3px solid black;*/
    /*padding-bottom: 20px;*/
    /*padding-top: 20px;*/
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    padding: 20px;
    width: 300px;
    box-sizing: border-box;
    max-width: 99vw;
    /*height: 300px;*/
  }

  .material_icon {
    /*font-size: 50px;*/
    top: -3%;
    //border-radius: 50%;
    margin-right: 20px;
    /*background-color: var(--primary-purple);*/
  }
}


@media (min-width: 500px) {
    .notification_box {
    width: 300px;
  }

}
@media (min-width: 700px) {

  .notification_box{
    padding: 30px;
  }


}
@media (min-width: 1200px) {
  .notification_box {
    width: 500px;
    font-size: 20px;
    /*padding-top: 40px;*/
    /*padding-bottom: 40px;*/
    padding: 50px;
  }

  .material_icon {
    font-size: 80px;
    top: -3%;
    //border-radius: 50%;
    padding-right: 20px;
  }
   .cancel_x {
    width: 30px;
    position: relative;
    top: -35px;
    left: 35px;
  }

}
</style>