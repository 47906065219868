<template>
  <universal_invisible_backer
      :show_backer="show_hamburger_menu"
      :backer_color="colors.whitesmoke_glass"
      event_name="backer_clicked"
      @backer_clicked="hamburger_menu_toggle"
  ></universal_invisible_backer>
  <!--  Basic Hamburger slide menu-->

  <basic_slide_over_menu
      :show_hamburger_menu="show_hamburger_menu"
      :mobile_mode="false"
      :left="false"
      :bg_color="colors.eli_gray"
      :bg_color2="colors.dark_glass"
  >

    <basic_text_button
        class=""
        v-if="!reentry_route"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SIGN UP"
        :text_size=button_text_size
        :bold="true"
        event_name="register_button_clicked"
        @register_button_clicked="dual_route_wrapper('register')"
    ></basic_text_button>
    <basic_text_button
        class=""
        v-if="!(reentry_route && good_token)"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG IN"
        :text_size=button_text_size
        :bold="true"
        event_name="log_in_button_clicked"
        @log_in_button_clicked="dual_route_wrapper('login')"
    ></basic_text_button>

     <basic_text_button
        class=""

        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="LOG OUT"
        :text_size=button_text_size
        :bold="true"
        event_name="log_out_button_clicked"
        @log_out_button_clicked="log_out_user()"
    ></basic_text_button>

    <basic_text_button
        class=""
        v-if="reentry_route && good_token"
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="PROFILE"
        :text_size=button_text_size
        :bold="true"
        event_name="log_in_button_clicked"
        @log_in_button_clicked="dual_route_wrapper(reentry_route)"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="CAREGIVERS"
        :text_size=button_text_size
        :bold="true"
        event_name="caregivers_button_clicked"
        @caregivers_button_clicked="dual_route_wrapper('for-caregivers','')"
    ></basic_text_button>
    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="AGENCIES"
        :text_size=button_text_size
        :bold="true"
        event_name="agencies_button_clicked"
        @agencies_button_clicked="dual_route_wrapper('for-agencies', '')"
    ></basic_text_button>

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="FORUM"
        :text_size=button_text_size
        :bold="true"
        event_name="main_forum_button_clicked"
        @main_forum_button_clicked="dual_route_wrapper('main-forum')"
    ></basic_text_button>

    <basic_text_button
        class=""
        :button_state="true"
        :button_color="button_color"
        :text_color="colors.white"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="HELP"
        :text_size=button_text_size
        :bold="true"
        event_name="main_forum_button_clicked"
        @main_forum_button_clicked="dual_route_wrapper('help')"
    ></basic_text_button>

     <basic_text_button
        class=""
        :button_state="true"
        :button_color="colors.new_orange_main"
        :text_color="colors.darker_gray"
        button_color_not_ready="gray"
        button_height="75px"
        button_width="100%"
        border_rad_pct="0%"
        text="SPYGLASS"
        :text_size=button_text_size
        :bold="true"
        event_name="schedules_button_clicked"
        @schedules_button_clicked="dual_route_wrapper('spyglass')"
    ></basic_text_button>

<!--    <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        button_color_not_ready="gray"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="CONTACT"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="contact_button_clicked"-->
<!--        @contact_button_clicked="dual_route_wrapper('contact')"-->
<!--    ></basic_text_button>-->
<!--    <basic_text_button-->
<!--        class=""-->
<!--        :button_state="true"-->
<!--        :button_color="button_color"-->
<!--        :text_color="colors.white"-->
<!--        button_color_not_ready="gray"-->
<!--        button_height="75px"-->
<!--        button_width="100%"-->
<!--        border_rad_pct="0%"-->
<!--        text="ABOUT"-->
<!--        :text_size=button_text_size-->
<!--        :bold="true"-->
<!--        event_name="about_button_clicked"-->
<!--        @about_button_clicked="dual_route_wrapper('about', '')"-->
<!--    ></basic_text_button>-->

  </basic_slide_over_menu>

  <div class="flex_space_between_row  flex_align_items_center wid_100vw " id="banner">

    <div class="flex_centered_column cursor_ptr banner_logo">
      <img src="@/assets/rabbit_1_line_edits.svg" id="top_rabbit_logo" @click="dual_route_wrapper('','page_top')"
           alt="Tiny Magic Hat Main Logo">
    </div>

    <basic_icon_button
        v-if="mobile_mode"
        class="cursor_ptr"
        :button_color="colors.transparent"
        :button_color_not_ready="colors.pennie_gray"
        :ready="true"
        button_height="40px"
        button_width="40px"
        border_rad_pct="50%"
        image_path='hamburger-menu-dark1.svg'
        image_width="25px"
        alt_text="Hamburger button"
        event_name="hamburger_clicked"
        @hamburger_clicked="hamburger_menu_toggle"
    ></basic_icon_button>

    <nav v-if="!mobile_mode" class="flex_centered_column">
      <div class="flex_centered_end_row " id="desktop_lnk">
        <div v-if="reentry_route && good_token" @click="dual_route_wrapper(reentry_route, '')"
             class="links cursor_ptr text_primary_purple border_rt">Profile
        </div>
<!--        <div v-if="!reentry_route" @click="dual_route_wrapper('sms_opt_in', '')"-->
<!--             class="links cursor_ptr text_primary_purple border_rt">Sign Up-->
<!--        </div>-->
          <div v-if="!reentry_route" @click="dual_route_wrapper('register', '')"
             class="links cursor_ptr text_primary_purple border_rt">Sign Up
        </div>
         <div v-if="!(reentry_route && good_token)" @click="dual_route_wrapper('login', '')"
             class="links   cursor_ptr text_primary_purple border_rt">Log In
        </div>

        <div  @click="log_out_user()"
             class="links cursor_ptr text_primary_purple border_rt">Log Out
        </div>

        <div class="links cursor_ptr text_primary_purple border_rt"
             @click="dual_route_wrapper('for-caregivers', '')">
          Caregivers
        </div>
        <div class="links cursor_ptr text_primary_purple border_rt"
             @click="dual_route_wrapper('for-agencies', '')">
          Agencies
        </div>

        <div class="links cursor_ptr text_primary_purple border_rt"
             @click="dual_route_wrapper('main-forum', '')">
          Forum
        </div>
         <div class="links cursor_ptr text_primary_purple border_rt"
             @click="dual_route_wrapper('help', '')">
          Help
        </div>
         <div class="links cursor_ptr text_primary_purple hot_link"
             @click="dual_route_wrapper('spyglass', '')">
          SpyGlass
        </div>
<!--        <div class="links cursor_ptr text_primary_purple border_rt"-->
<!--             @click="dual_route_wrapper('contact', '')">-->
<!--          Contact-->
<!--        </div>-->
<!--        <div class="links cursor_ptr text_primary_purple " id="about_link"-->
<!--             @click="dual_route_wrapper('about', '')">-->
<!--          About-->
<!--        </div>-->


      </div>
    </nav>
  </div>
</template>


<script>
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  num_is_between_inclusive, colors, scroll_to_element, dual_route, reentry_route,
  good_token_now, analytic
} from "@/library";
import store from "@/store";

export default {
  name: "outside_banner_std",
  components: {
    universal_invisible_backer,
    basic_slide_over_menu,
    basic_icon_button,
    basic_text_button
  },
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  data() {
    return {
      colors: colors,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 1000,
      button_color: colors.new_primary_purple_50,
      button_text_size_: "15px"
    }
  },
  methods: {
    do_nothing() {
      console.log("doing nothing")
    },
    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      analytic("01:01:01:01")
    },
    dual_route_wrapper(route, inner = null) {
      console.log(`Inside outside banner dual route wrapper going to ${route}`)
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      console.log("You clicked the hamburger menu on the home page.")
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')

        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        // add_class_to_id('desktop_lnk', 'links')
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        // console.log("size is greater that 500")
        // let e = document.getElementById('desktop_lnk')
        // console.log(e)
        // add_class_to_id('desktop_lnk', 'links')
      }


    },


  },
  computed: {
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_text_size() {
      return this.button_text_size_
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {

  #banner {
    height: 75px;
  }


  #top_rabbit_logo {
    width: 150px;
    padding-left: 15px;
  }

   .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
  }

  #about_link {
    margin-right: 20px;
  }


}

@media (min-width: 400px) {
}

@media (min-width: 1000px) {
 .hot_link{
    background-color: var(--new-orange-main);
    padding-top: 15px;
    padding-bottom: 15px;
    color: var(--darker_gray);
    font-weight: 800;
    width: 64px;
    margin-right: 20px !important;
    margin-left: 10px !important;
    border-radius: 2px;
  }
}

@media (min-width: 1200px) {
  .banner_logo{
    margin-left: 30px;
  }
}


@media (min-width: 2000px) {
  #banner {
    height: 150px;
  }

  .hot_link{
    width: 90px;
    margin-right: 60px !important;
  }

  #top_rabbit_logo {
    width: 250px;
    padding-left: 20px;
  }

 .links {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 30px;
    text-decoration: none;
  }

}

@media (min-width: 3000px) {
    .hot_link{
    width: 90px;
  }
}

</style>