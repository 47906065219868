<template>
  <div class="core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <div class="opt_in_sizing ">
      <div class="msg_box  flex_centered_column  ">
        <div class="methods_intro">
          <h6>Ready to create your new account? </h6>
          <h6>Great! There are two ways to sign up.
            Pick the one that's best for you.</h6>
          <br><br>
          <div class="standard_method">
            <p>The 'basic' method is the easiest and fastest way to sign up, but we will need to send you
              a single text message that contains your account verification code. If that sounds ok
              to you, just click the 'Easy' button.</p>
            <div class="flex_centered_row margin_t_25">
              <basic_text_button
                  text="Easy"
                  :button_color="colors.new_orange_main"
                  :text_color="colors.white"
                  button_width="200px"
                  button_height="60px"
                  event_name="easy_button_clicked"
                  @easy_button_clicked="simple_route_prep('register')"
              ></basic_text_button>
            </div>
          </div>
        </div>
        <br><br>
        <div class="mfa_method ">
          <p>The other sign up option is the Multi-Factor Authentication Method. It does
            not require text messaging, but it does require you to first install
            software like Google Authenticator on your phone and enter a one-time password
            each time you log in to Tiny Magic Hat. This method is more complex, but more secure.</p>
          <div class="flex_centered_row margin_t_25">
            <basic_text_button
                text="Multi-Factor"
                :button_color="colors.cyanic_blue"
                :text_color="colors.white"
                button_width="250px"
                button_height="60px"
                event_name="mfa_registration_button_clicked"
                @mfa_registration_button_clicked="simple_route_prep('mfa_register')"
            ></basic_text_button>
          </div>
        </div>
      </div>
    </div>


  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import {get_uuid, round, colors, desktop_size, scroll_to_top} from "@/library";

export default {
  name: "SMS_confirmation",
  async mounted() {
    this.id_ = get_uuid()
    scroll_to_top()
    // this.top_scroll()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner,
    basic_text_button
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
    },
    async post_test_answers() {
      console.log(this.answers_value)
    },
    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
  },
  computed: {
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  h1 {
    color: white;
  }

  .methods_intro {
    margin-top: 50px;
  }

  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }

  .opt_in_sizing {
    /*width: 9%;*/
    margin: auto;
    /*text-align: center;*/

  }

  .msg_box {
    /*padding: 80px;*/
  }
}

@media (min-width: 400px) {
   .methods_intro {
    margin-top: 50px;
  }

  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }

  .opt_in_sizing {
    width: 90%;
    margin: auto;
    /*text-align: center;*/

  }

  .msg_box {
    /*padding: 80px;*/
  }
}

@media (min-width: 600px) {
   .methods_intro {
    margin-top: 50px;
  }

  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }

  .opt_in_sizing {
    width: 75%;
    margin: auto;
    /*text-align: center;*/
  }

  .msg_box {
    /*padding: 80px;*/
  }
}

@media (min-width: 800px) {
  .opt_in_sizing {
    width: 60%;
    margin: auto;
    /*text-align: center;*/
  }
}

@media (min-width: 800px) {
  .opt_in_sizing {
    width: 650px;
    margin: auto;
    /*text-align: center;*/
  }
}

@media (min-width: 1200px) {

}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
  }

  .mfa_method {
    margin-top: 40px;
  }

  .mfa_method p {
  }

  .opt_in_sizing {
    width: 40%;
    margin: auto;

  }

  .msg_box {
    padding: 80px;
  }
}
</style>