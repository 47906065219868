<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <landing_banner></landing_banner>


    <div class="flex_centered_row ">
      <div class="flex_centered_start_column">


        <div class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/stairs2.svg"
                     alt="Four people holding hands on stairs.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h3 class="text_align_center">We Are Proud to Work with the Best Home Care Agencies in the Local Area</h3>
              <!--              <h3 class="text_align_center">$18+ per hour for Full Time Caregivers</h3>-->
              <p class="text_align_center top_text">

                Tiny Magic Hat only works with high quality agencies that treat their caregivers right. Find a great
                agency here.
              </p>
            </div>
          </div>
        </div>

        <div class="below_carousel">
          <h3 class="text_align_center">Our local home care agency partners:</h3>
        </div>

        <div class="market_box border_1">
          <div class="market">Myrtle Beach</div>
        </div>

        <div class="" v-for="agency in approved_agencies" :key="agency">
          <local_pda_featured
              class="margin_b_20"
              neighborhood="Myrtle Beach"
              :agency_logo="all_local_agency_data[agency]['logo']"
              :headline="all_local_agency_data[agency]['headline']"
              :body_text="all_local_agency_data[agency]['body']"
              :phone_number="all_local_agency_data[agency]['phone_number']"

              posted="item.posted"
              freetime_stats="item.freetime_stats"
              expires="None"
              ad_id="123455"
              event_name="ad_clicked"
              @ad_clicked="do_nothing"
          ></local_pda_featured>

        </div>

      </div>


    </div>
    <outside_footer_landing class="footer_c"></outside_footer_landing>
  </div>
</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import local_pda_featured from "@/components/orgs/pda/local_pda_featured";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  validate_phonenumber,
  validate_email,
  be_main_address, parse_internal_json, get_ip_from_header, isEqual
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "local_agencies",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    this.id_ = get_uuid()
    this.scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)

    let ip_string = false
    ip_string = await get_ip_from_header()

    let default_market = 'Myrtle Beach'
    this.update_approved_agency_names_in_market()
    await this.populate_local_agency_data(default_market)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
        // let agencies_list = this.agencies_in_market[market]
        // let all_agency_data = this.all_local_agency_data
        // for (const agency of agencies_list) {
        //   console.log(`${agency}`);
        //   this.get_and_cache_agency_logo(agency)
        //   this.get_and_cache_agency_ad_texts(agency)
        // }


      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    local_pda_featured
  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      name: '',
      password: '',
      email: '',
      agencies_in_market: {
        'Myrtle Beach': ['rah1185']
      },
      approved_agencies_: [],
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 1000,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
      // my_slides: [
      //   {text1: 'FreeTime bonuses have really make a big difference on my paycheck.', text2: 'Darcie P.', id: 1},
      //   {text1: 'Who\'s there?', text2: 'More text', id: 2},
      //   {text1: 'Tank.', text2: 'More text', id: 3},
      //   {text1: 'Tank who?', text2: 'More text', id: 4},
      //   {text1: 'You\'re welcome', text2: 'More text', id: 5},
      //   {text1: 'Oh, stop it, you\'re just killing me!', text2: '--Wyatt', id: 6},
      // ],

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    tester() {
      // console.log("button was pressed.")
    },
    update_approved_agency_names_in_market() {
      console.log("Ok, let's update approved markets for the changes.........")
      // Market will ultimately be selected by user from a dropdown, with values supplied by api call.
      let market = 'Myrtle Beach'
      // let res = []
      let required = ['logo', 'headline', 'body', 'phone_number']
      let temp
      // Agencies in market will ultimately be supplied by api call.
      let agencies = this.agencies_in_market[market]
      console.log("Here are my agencies in market.")
      console.log(agencies)

      for (const agency of agencies) {
        console.log(`Looking at this agency: ${agency}`)
        console.log(`Is ${agency} already on the approved list?`)
        if (this.approved_agencies.includes(agency)) {
          console.log(`${agency} is already on the approved list.`)
          continue
        } else {
          console.log(`${agency} is not on the approved list yet.`)
        }

        let agency_data = this.all_local_agency_data[agency]
        console.log(`Here is the agency data I have now.....`)
        console.log(agency)
        console.log(agency_data)
        // console.log(agency_data['body'])

        let matched_set = []
        for (const key of required) {
          console.log(key);
          try {
            temp = agency_data[key]
            console.log(`Here is temp value for ${key}....${temp}`)
            // console.log(temp)
            if (temp) {
              console.log(`Matched ${key} for ${agency}`)
              matched_set.push(key)
            } else {
              console.log(`${key} was not matching.`)
            }

          } catch (error) {
            console.log("Breaking on error")
            break
          }
        }
        matched_set.sort()
        required.sort()

        if (agency !== undefined) {
          console.log(`Testing for equality for ${agency}`)
          console.log("Matched and required")
          console.log(matched_set)
          console.log(required)
          if (isEqual(matched_set, required) === 'true') {
            console.log(`Sets are equal for ${agency}`)
            this.approved_agencies_.push(agency)
          } else {
            console.log("THe sets were not equal")
          }

        }

      }
      console.log("So what did I end up with?")
      // console.log(res)
      this.approved_agencies_ = [...new Set(this.approved_agencies_)];
      // this.approved_agencies_ = res

      console.log("Currently approved agencies are...")
      console.log(this.approved_agencies)
      return this.approved_agencies_

    },
    async get_and_cache_agency_logo(agency) {
      let local_logo
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_logo'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        local_logo = all['data']['data']
        // let cached_logo = {'agency': agency, 'logo': local_logo}
        // store.dispatch('set_spyglass_agency_landing_public_logo', cached_logo)
        console.log(`Here is the logo from ${agency}`)
        console.log(local_logo)

        let store_keys = {
          'agency': agency,
          'key': 'logo',
          'value': local_logo
        }
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_data_key', store_keys)

        let store_keys2 = {
          'agency': agency,
          'key': 'name',
          'value': agency
        }
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_data_key', store_keys2)

        // console.log(this.logo_)
      }).then(() => {
        this.update_approved_agency_names_in_market()
      })
    },

    async get_and_cache_agency_ad_texts(agency) {
      let local_text
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_landing_text'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        local_text = all['data']['data']
        console.log(`Here is the data from ${agency}`)
        console.log(local_text)

        let store_keys = {
          'agency': agency,
          'key': 'headline',
          'value': local_text['headline']
        }
        // data1['headline'] = local_text['headline']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_data_key', store_keys)

        let store_keys2 = {
          'agency': agency,
          'key': 'body',
          'value': local_text['body']
        }
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_data_key', store_keys2)

        // console.log('HERE IS IS.......................................................')
        // console.log(local_text)
        // this.update_approved_agency_names_in_market()

      }).then(() => {
        console.log(`Did we vuex the data for ${agency}?`)
        console.log(store.getters.get_spyglass_agency_landing_public_data_all)
        this.update_approved_agency_names_in_market()
      })
    },

    async get_and_cache_agency_phone_number(agency) {
      let local_text
      let data1 = {
        agency: agency
      }
      let endpoint = be_main_address('spyglass') + 'get_agency_phone_number'
      //
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        let all = parse_internal_json(response)
        local_text = all['data']['data']
        console.log(`Here is the data from ${agency}`)
        console.log(local_text)
        let store_keys = {
          'agency': agency,
          'key': 'phone_number',
          'value': local_text
        }
        // data1['headline'] = local_text['headline']
        // let cached_logo = {'agency': agency, 'logo': this.logo_}
        store.dispatch('set_spyglass_agency_landing_public_data_key', store_keys)
      }).then(() => {
        this.update_approved_agency_names_in_market()
      })
    },

    async populate_local_agency_data(market = 'Myrtle Beach') {

      //get a list of agencies in this market
      let agencies_list = this.agencies_in_market[market]
      console.log("What is in the agencies list here?")
      console.log(agencies_list)
      this.update_approved_agency_names_in_market()

      for (const agency of agencies_list) {
        if (!this.approved_agencies.includes(agency)) {
          console.log(`${agency}`);
          await this.get_and_cache_agency_logo(agency)
          await this.get_and_cache_agency_ad_texts(agency)
          await this.get_and_cache_agency_phone_number(agency)
        }
      }
    },


    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_home_carousel_quotes
    },

    video_modal_false() {
      this.video_modal.state = false
    },

    video_modal_true() {
      console.log("changing video modal to true")
      this.video_modal.state = true
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.v_scale = 1.2
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        this.button_wid_ = 250
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        this.v_scale = 1.5
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        this.v_scale = 1.75
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        this.v_scale = 2.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        this.v_scale = 3.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        console.log(`Height: ${this.video.height}`)
        console.log(`Width: ${this.video.width}`)
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.v_scale = 3.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        this.button_wid_ = 300
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },

    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },

    catch_password(pw) {
      this.password = pw.trim()
      store.commit('save_password', this.password)
    },

    catch_phone_number(phone_number) {
      let phone = phone_number.trim()
      //  remove all non-digits
      phone = phone.replace(/\D/g, '');
      // validate
      if (validate_phonenumber(phone)) {
        this.phone_number = phone
      } else {
        // let m = phone_number + "is not valid."
        // store.commit('error_enqueue', m)
        return null
      }
    },

    catch_email(content) {

      if (validate_email(content)) {
        this.email = content.trim().slice(0, 100)
      } else {
        // let m = content + "is not a valid email address."
        // store.commit('error_enqueue', m)
        return null
      }
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
      store.commit('save_name', this.name)
    },
    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async register() {
      // console.log("Getting ready to launch register...")
      // let the_router = this.$router

      this.$refs.register_name_box.emit_state()
      this.$refs.register_password_box.emit_state()
      this.$refs.register_phone_number_box.emit_state()
      this.$refs.register_email_box.emit_state()

      // this.$refs.register_email.emit_state()

      let error_out
      if (this.name.length === 0) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must provide a user name to register.")
        error_out = true
      }
      if (this.password.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a password to register.")
        error_out = true
      }
      if (this.email.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a valid email to register.")
        error_out = true
      }
      if (this.phone_number.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a phone number to register.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "create_new_user"
      console.log("create new user address")
      console.log(target)

      console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, {
        name: this.name,
        password: this.password,
        phone_number: this.phone_number,
        email: this.email
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad registration. Check name, password and phone.")
          console.log(error)
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          error_out = true
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              this.$store.commit('save_name', this.name)
              this.$store.commit('save_phone', this.phone_number)
              this.name = ''
              this.phone_number = ''
              this.password = ''
              this.$router.push('/register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },

    async process_asset_package(asset_package) {
      // console.log("inside processing asset package.")

      //save jwt authentication token in vuex
      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])
      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      // console.log("done with processing the asset package")
    },

  },
  computed: {
    approved_agencies() {
      return this.approved_agencies_
    },
    all_local_agency_data() {
      // return a nested dict like:
      //  {'rah': {'logo': 'aoeu','headline': 'aoeu','body': 'aoeu',}}
      return store.getters.get_spyglass_agency_landing_public_data_all
    },
    // agency_names_in_market() {
    //   let market = 'Myrtle Beach'
    //   let res = []
    //   let required = ['logo', 'headline', 'body']
    //   let temp
    //   // let all_agencies_data = this.all_local_agency_data
    //   let agencies = this.agencies_in_market[market]
    //   console.log("Here are my agencies in market.")
    //   console.log(agencies)
    //
    //   for (const agency in agencies) {
    //     // console.log(`Looking at this agency: ${agency}`)
    //     console.log(`Looking at this agency: ${agencies[agency]}`)
    //     //Redefining agency because for some reason agencies is an object not an array.
    //     let agency = agencies[agency]
    //
    //     let agency_data = this.all_local_agency_data[agency]
    //     console.log(`Here is the agency data I have now.....`)
    //     console.log(agency)
    //     console.log(agency_data)
    //
    //     for (const key of required) {
    //       console.log(key);
    //       try {
    //           temp = agency_data[key]
    //       } catch (error) {
    //         console.log("Breaking on error")
    //          break
    //       }
    //     }
    //     if(agency !== undefined){
    //       res.push(agency)
    //     }
    //
    //   }
    //   console.log("So what did I end up with?")
    //   console.log(res)
    //   return res
    //
    // },
    agency_text_sample() {
      return "Right at Home is a award-winning, family-owned homecare agency that has proudly served both Horry and Georgetown counties since 2008. We recently received the 2022 Best of Home Care® Employer of Choice Award from Home Care Pulse®. Our caregivers are the heart of our business and we are so grateful to be recognized for providing a first-rate employee experience to caregivers."
    },
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },

    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../common.css';


header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    /*margin-top: -30px;*/
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .market_box {
    font-size: 20px;
    border-color: var(--eli_gray);
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 30px;

  }

  .market {
    font-size: 22px;
    font-weight: 800;
    padding: 10px;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 450px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {

  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 650px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 500px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0;
  }

  .top_text {
    max-width: 850px;
  }


}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 150px;
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>