<template>
  <div class="core_c">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>

    <div class="flex_centered_column">
      <section class="terms_wrapper ">
        <h5><strong>New Client Discount Terms</strong></h5>
        <p>Our Discount Terms were last updated on <strong>{{ last_updated_str }}</strong>.</p>
        <p>Please read these discount terms carefully so you can understand the limitations placed on new client discounts.</p>
        <h5><strong>Definitions</strong></h5>
        <ul>
          <li>
            <p><strong>Discount(s)</strong> is a reference to the new client discount you properly registered against your selected agency.</p>
          </li>
          <li>
            <p><strong>Selected Agency</strong> is the particular agency that has agreed to a provide You a new client discount.
              The name of the Selected Agency appears on the face of your New Client Discount Certificate.</p>
          </li>
          <li>
            <p><strong>New Client Discount Certificate</strong> The document (delivered in a PDF format) that was created for you when your registered your discount against your Selected Agency
            that details the nature and amount of your discount.</p>
          </li>
          <li>
            <p><strong>You </strong>means the person responsible for the payment of home care invoices from the Selected Agency. You may or may not be the actual home care client.</p>
          </li>
          <li>
            <p><strong>Client </strong>means the person referenced by You or your representative as the 'client' during the Discount Registration Process.</p>
          </li>
          <li>
            <p><strong>Expiration Date</strong> The date by which any unused portion of your Discount becomes forfeit.</p>
          </li>
          <li>
            <p><strong>Creation Date</strong> The date you registered your discount against your Selected Agency and created your New Client Discount Certificate.</p>
          </li>
           <li>
            <p><strong>Required Paid Hours</strong> The hours of service that must have been billed to You with the resulting invoices paid by You to the Selected Agency
              with regards to home care services rendered by the Selected Agency on behalf of You and the Client before the Discount is eligible for Redemption.</p>
          </li>
           <li>
            <p><strong>Redemption</strong> Any process by which the Selected Agency gives You credit against Your unpaid home care invoices from the Selected Agency
              and with regards to the Client with the intention that said credit is given in complete or partial satisfaction of the promised Discount.</p>
          </li>
          <li>
            <p><strong>Discount Registration Process</strong> A process where You (or someone on Your behalf), while using the Tiny Magic Hat website,
              provide required information in order to register a New Client Discount against the Selected Agency.</p>
          </li>
        </ul>
        <h5><strong>Main Concept and Basic Rules</strong></h5>
        <h4><strong>Main Concept</strong></h4>
        <p>A New Client Discount (hereinafter "Discount") offered through Tiny Magic Hat is intended as an inducement to You starting new service with the Selected Agency.</p>
        <p>Discounts represent an agreement between You and the Selected Agency.</p>
        <p>Tiny Magic Hat is not a party to the Discount agreement between You and the Selected Agency and is not responsible for the enforcement of the agreement.</p>
        <h4><strong>Basic Rules</strong></h4>
        <p>A Discount created by You or for You is not valid against the Selected Agency if any of the information provided by You, or on Your behalf,
          during the Discount Registration Process is incomplete or incorrect.</p>
        <p>The Discount agreement is between You and the Selected Agency and no other parties. The Discount cannot be transferred, assigned, sold or redeemed against the Selected Agency
        in any way except by the Redemption process.</p>
        <p>The Discount Agreement is subject to the Expiration Date which is found on the face of the New Client Discount Certificate (Certificate). If any portion of the Discount
          has not been Redeemed by the Selected Agency in favor of You by the Expiration Date, the remaining portion of the unredeemed Discount is forfeit and the
          Selected Agency's Redemption obligation with regards to the Discount is thereby relieved.</p>
        <p>Each Discount becomes eligible for Redemption after You have achieved the Required Paid Hours. The number of hours required to be paid prior to
        Discount Redemption eligibility will be found on the face of the Certificate.</p>
        <p>The number of Required Paid Hours varies depending on the size of the discount offered by the Selected Agency.</p>
        <p>Discounts are offered at the sole discretion of the Selected Agency.</p>
        <p>Tiny Magic Hat becomes aware of Discount Redemption when it is notified of the Redemption by the Selected Agency. At the time of notification,or shortly thereafter,
          You will receive an email message at the email address provided by You during the Discount Registration Process from Tiny Magic Hat informing You that the
          Selected Agency has deemed Your Discount Redeemed in full.</p>
      </section>
    </div>


  </div>

  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import {
  get_uuid,
  num_is_between_inclusive,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  good_token_now
} from "@/library";

export default {
  name: "new_client_discount_terms",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    spinner
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      last_updated_str: "July 24, 2024",
      official_company_name: "Tiny Magic Hat, Inc.",
      country: "the United States of America",
      website_name: "Tiny Magic Hat",
      website_url: "https://TinyMagicHat.com",
      contact_email: "info@TinyMagicHat.com",
      contact_page_url: "https://TinyMagicHat.com/contact"

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    run_window_width_adjustments(new_w) {

      if (num_is_between_inclusive(100, 280, new_w)) {
        //do something
      }
      if (num_is_between_inclusive(281, 500, new_w)) {
        //do something
      }
      if (num_is_between_inclusive(501, 1200, new_w)) {
        this.mobile_mode_x = false
      }
      if (num_is_between_inclusive(1201, 10000, new_w)) {
        //do something
      }
    },

  },
  computed: {
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  .terms_wrapper {
    width: 100vw;
  }

  .terms_wrapper p {
    font-size: 15px;
  }


  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 400px) {
  .terms_wrapper {
    width: 95vw;
  }
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .terms_wrapper {
    width: 80vw;
  }

  .terms_wrapper p {
    font-size: 15px;
  }

  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 2000px) {
  .terms_wrapper {
    width: 70vw;
  }

  .terms_wrapper h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .terms_wrapper h6 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

}
</style>